/**
 * Abstract: A view that  applies uniform padding to its children
 * 
 */
import {  VStack } from '@chakra-ui/react'
import React, {ReactNode} from 'react'
 

interface LayoutProps {
    children?: ReactNode
}
 

const AddASpaceLayout = ({children}: LayoutProps) => {
    return (
        <VStack
         
          padding={{ base: "1.5em", md: "1.5em 2em", lg: "2em" }}
          spacing={{base: 4, md: 6, lg: 8}}
          w="inherit"
        >
          {children}
        </VStack>
    )
}

export default AddASpaceLayout

import axios, { AxiosResponse } from "axios";
 import {
  BOOKINGSTATUS,
  PartnerBooking,
} from "../Components/Screens/WHDashboard/Models";
import {
  ContactForm,
  DashboardSectionName,
  DescForm,
  HopForm,
  LocationForm,
  SizeForm,
} from "../Components/Screens/WHDashboard/Models/Interfaces";
import { UserBooking } from "../Components/Screens/WHDashboard/WHManageRequests/Models/Booking";
import { bookedDatesDefaultValue } from "./DefaultValues";
import {t} from 'i18next';
import {
  AccessToken,
  Amenity,
  AmenityCategory,
  CompanyInfo,
  Country,
  DurationTime,
  EnquiredItem,
  Listing,
  ListingCategory,
  ListResponseData,
  LoginData,
  OtherImages,
  PartnerDetail,
  PublicListing,
  RegistrationResponse,
  RequestsAnalytics,
  Result,
  ResultType,
  UserData,
  WorkHopperCurrency,
  Workspace,
} from "./Interfaces";

enum Endpoint {
  USERS = "users",
  LOGIN = "login",
  COUNTRIES = "countries",
  AMENITIES = "amenities",
  AMENITYCATEGORIES = "amenity_categories",
  LISTINGCATEGORIES = "listings_categories",
  LISTINGSPACES = "listings",
  WORKSPACE = "workspace",
  LISTING = "listings",
  UPLOADLISTINGIMAGES = "listings_docs",
  ENQUIRY = "enquiry",
  REVIEW = "review",
  LOCATION = "locations",
  AVATAR = "user_avatar",
  CHANGEPASSWORD = "change_password",
  PARTNERS = "partners",
  BOOKINGS = "bookings",
  PARTNERBOOKINGS = "partner_bookings",
  BOOKINGPAYMENT = "booking_payment",
  PAYMENTVERIFICATION = "payment_verification",
  PREMIUMLISTING = "premium_listing",
  ADVERT = "advert",
  SLIDESHOW = "slideshow",
  SPOTLIGHT = "spotlight",
  FORGOTPASSWORDCHANGE = "forgot_password_change",
  FORGOTPASSWORD = "forgot_password",
  NEWSLETTER = "newsletter",
  BOOKINGDURATION = "booking_duration",
  BOOKINGENDDATE = "booking_end_date",
  CURRENCYCONVERTER = "currency_converter",
  CURRENCIES = "currencies",
  GENCODE = "generate_listing_code",
  VERIFYCODE = "verify_listing_code",
  CLAIMLISTING = "claim_listing",
  PARTNERBOOKINGANALYTICS = "partner_booking_analytics",
  PARTNERENQUIRIESANALYTICS = "enquiry_analytics",
  COMPANY = "company",
}

export class WorkhopperAPIManager {
  private baseURL = "https://api.workhopper.org";
  //private baseURL = "https://dev.workhopper.org"

  //private baseURL = "https://testing.workhopper.org"

  //private baseURL = "https://dev.workhopper.org"

  protected endpoint: Endpoint = Endpoint.USERS;

  protected whAxios = axios.create({
    baseURL: this.baseURL,
    timeout: 5000,
  });

  static shared = new WorkhopperAPIManager();

  get endpointURL(): string {
    switch (this.endpoint) {
      case Endpoint.USERS:
        return this.baseURL + "/users/";

      case Endpoint.LOGIN:
        return this.baseURL + "/login/";
      case Endpoint.COUNTRIES:
        return this.baseURL + "/countries";
      case Endpoint.AMENITIES:
        return this.baseURL + "/amenities";
      case Endpoint.AMENITYCATEGORIES:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.LISTINGCATEGORIES:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.WORKSPACE:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.LISTING:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.UPLOADLISTINGIMAGES:
        return this.baseURL + `/${this.endpoint}/`;
      case Endpoint.ENQUIRY:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.REVIEW:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.LOCATION:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.AVATAR:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.CHANGEPASSWORD:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.PARTNERS:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.BOOKINGS:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.PARTNERBOOKINGS:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.BOOKINGPAYMENT:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.PAYMENTVERIFICATION:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.PREMIUMLISTING:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.ADVERT:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.SLIDESHOW:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.SPOTLIGHT:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.FORGOTPASSWORDCHANGE:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.FORGOTPASSWORD:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.NEWSLETTER:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.BOOKINGDURATION:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.CURRENCYCONVERTER:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.CURRENCIES:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.GENCODE:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.VERIFYCODE:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.CLAIMLISTING:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.PARTNERBOOKINGANALYTICS:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.PARTNERENQUIRIESANALYTICS:
        return this.baseURL + `/${this.endpoint}`;
      case Endpoint.COMPANY:
        return this.baseURL + `/${this.endpoint}`;
      default:
        return "";
    }
  }

  // async loginUser(userData: LoginData): Promise<Result<AuthResponse>> {

  // }

  getErrorResult<T>(error: unknown, responseErrorMsg: string): Result<T> {
    let msg = "";
    let result: Result<T>;

    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 400) {
          const errorField = Object.keys(error.response.data)[0];
          //  if (!errorField) {
          //     msg = "An unexpected error occurred. Try again"
          //  }
          if (errorField !== "details") {
            msg = `${errorField} - ${error.response.data[errorField]}`;
          } else if (Array.isArray(errorField)) {
            msg = errorField[0];
          } else {
            msg = error.response.data[errorField];
          }
          //  if (errorField !== "details") {
          //     msg = `${errorField} - ${error.response.data[errorField]}`
          //  } else if (error.response.data.details && Array.isArray(error.response.data.details)) {
          //     msg = error.response.data.details[0]
          //  } else if (error.response.data.details) {
          //     msg = error.response.data.details
          //  } else {
          //     msg = "Something went wrong. Try again"
          //  }
        } else if (error.response.status >= 500) {
          msg = "An error occured. Probably from the server. Try again later";
        } else if (error.response.status === 401) {
          msg = "Your session has expired. Please login";
        } else if (error.response.status === 413) {
          msg = "File size too large";
        } else {
          msg = error.message; //responseErrorMsg
        }
        result = { type: ResultType.Failure, error: new Error(msg) };
      } else if (error.request) {
        console.log(error.request);
        msg = "WorkHopper seems to be offline. Try again";
        result = { type: ResultType.Failure, error: new Error(msg) };
      } else {
        // Something happened in setting up the request that triggered an Error
        msg = "Something went wrong";

        // console.log('Error', error.message);
        result = {
          type: ResultType.Failure,
          error: new Error(responseErrorMsg),
        };
      }
    } else {
      result = {
        type: ResultType.Failure,
        error: new Error("An unexpected error occured"),
      };
    }

    return result;
  }

  // User Registration

  async putAvatar(
    userId: string,
    file: File,
    token: string
  ): Promise<Result<unknown>> {
    let result: Result<unknown>;

    this.endpoint = Endpoint.AVATAR;
    let data = new FormData();

    data.append("avatar", file);

    try {
      let response = await axios.put(`${this.endpointURL}/${userId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't save image. Try again");
    }

    return result;
  }

  async changePassword(
    newPassword: string,
    password: string,
    token: string
  ): Promise<Result<unknown>> {
    let result: Result<unknown>;

    this.endpoint = Endpoint.CHANGEPASSWORD;
    let data = {
      password,
      new_password: newPassword,
    };

    try {
      let response = await axios.patch(`${this.endpointURL}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't change password. Try again"
      );
    }

    return result;
  }

  async register(userData: UserData): Promise<Result<RegistrationResponse>> {
    this.endpoint = Endpoint.USERS;
    //let result: Result<RegistrationResponse, Error>
    let result: Result<RegistrationResponse>;

    let data = {};

    let {
      fname: first_name,
      lname: last_name,
      email,
      phone,
      userType: user_type,
      country,
      password,
      partnersEmail: partners_email,
      partnersName: partners_name,
      terms: is_accept_terms,
      isSocial: is_social,
    } = userData;

    if (user_type === "users") {
      data = {
        first_name,
        last_name,
        email,
        phone,
        user_type,
        country,
        password,
        is_accept_terms,
        is_social,
      };
    } else {
      data = {
        first_name,
        last_name,
        email,
        phone,
        user_type,
        country,
        password,
        partners_email,
        partners_name,
        is_accept_terms,
        is_social,
      };
    }

    try {
      let response = await axios.post(this.endpointURL, data);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      // debugger
      if (axios.isAxiosError(error) && error.response) {
        let msg = "";
        //debugger
        if (error.response.status === 400) {
          const errorField = Object.keys(error.response.data)[0];
          //  if (!errorField) {
          //     msg = "An unexpected error occurred. Try again"
          //  }
          if (errorField !== "details") {
            msg = `${errorField} - ${error.response.data[errorField]}`;
          } else if (Array.isArray(errorField)) {
            msg = errorField[0];
          } else if (
            error.response.data[errorField].toLowerCase().includes("duplicate")
          ) {
            msg = `${email} has already been registered. Login or try another email`;
          } else {
            msg = error.response.data[errorField];
          }
          // if (Object.keys(error.response.data).includes('details') &&
          // error.response.data.details.includes("duplicate key")) {
          //     msg = `${email} has already been registered. Login or try another email`
          // } else if (Object.keys(error.response.data).includes('details')) {
          //     //msg = "one or more required fields are missing"
          //     msg = error.response.data.details
          // } else if (Object.keys(error.response.data).includes('details') && Array.isArray(error.response.data.details)) {
          //     msg = error.response.data.details[0]
          // }
        } else if (error.response.status >= 500) {
          msg = "An error occurred. Probably from the server. Try again later";
        } else {
          msg = "Something went wrong."; //error.response.data.error
        }
        result = { type: ResultType.Failure, error: new Error(msg) };
      } else {
        result = {
          type: ResultType.Failure,
          error: new Error("WorkHopper seems to be offline. Try again 1"),
        };
      }
    }

    return result;
  }

  async registerWithFederatedAccount(
    data: any
  ): Promise<Result<RegistrationResponse>> {
    this.endpoint = Endpoint.USERS;
    //let result: Result<RegistrationResponse, Error>
    let result: Result<RegistrationResponse>;

    let dataToSend = {
      first_name: data.fname,
      last_name: data.lname,
      email: data.email,
      is_accept_terms: data.terms,
      user_type: "users",
      country: data.country,
      password: data.email,
      is_social: true,
    };

    try {
      let response = await axios.post(this.endpointURL, dataToSend);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      // debugger
      if (axios.isAxiosError(error) && error.response) {
        let msg = "";

        if (error.response.status === 400) {
          const errorField = Object.keys(error.response.data)[0];
          if (errorField !== "details") {
            msg = `${errorField} - ${error.response.data[errorField]}`;
          } else if (Array.isArray(errorField)) {
            msg = errorField[0];
          } else {
            msg = error.response.data[errorField];
          }
        } else if (error.response.status >= 500) {
          msg = "An error occurred. Probably from the server. Try again later";
        } else {
          msg = "Something went wrong"; //error.response.data.error
        }
        result = { type: ResultType.Failure, error: new Error(msg) };
      } else {
        result = {
          type: ResultType.Failure,
          error: new Error("WorkHopper seems to be offline. Try again 2"),
        };
      }
    }

    return result;
  }

  async listingCategories(): Promise<Result<ListingCategory[]>> {
    let result: Result<ListingCategory[]>;

    // let val = localStorage.getItem("list-cat")
    // if (val) {
    //     result = {type: ResultType.Success, value: JSON.parse(val)}
    //     return result
    // }

    // let temp =  appDataManager.getCategoriesData("list-cat")
    // if (temp.length > 0) {
    //     result = {type: ResultType.Success, value: temp}
    //     return result
    // }
    this.endpoint = Endpoint.LISTINGCATEGORIES;

    try {
      let response = await axios.get(this.endpointURL);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load offices data");
    }

    return result;
  }

  async spaceListing(spaceId: string): Promise<Result<unknown>> {
    let result: Result<unknown>;

    this.endpoint = Endpoint.LISTINGSPACES;
    let url = `${this.endpointURL}/?city=${spaceId}`;

    try {
      let response = await axios.get(url);

      result = { type: ResultType.Success, value: response.data.results };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load data. Try Again");
    }

    return result;
  }

  async postListing(data: Listing, token?: string): Promise<Result<Listing>> {
    let result: Result<Listing>;
    //debugger
    this.endpoint = Endpoint.LISTING;
    let temp = data;

    let response: AxiosResponse<any>;
    //  debugger
    try {
      //console.log(temp)
      if (!temp.id) {
        response = await axios.post(`${this.endpointURL}/`, temp, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.patch(`${this.endpointURL}/${temp.id}/`, temp, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "One or more fields are invalid");
    }

    return result;
  }

  async countries(): Promise<Result<Country[]>> {
    let result: Result<Country[]>;

    this.endpoint = Endpoint.COUNTRIES;

    try {
      let response = await axios.get(`${this.endpointURL}/`);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load countries data");
    }

    return result;
  }

  async getLoginUser(token: string): Promise<Result<RegistrationResponse>> {
    //let result: Result<Country[]>
    let result: Result<RegistrationResponse>;
    this.endpoint = Endpoint.USERS;

    try {
      let response = await axios.get(this.endpointURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = {
        type: ResultType.Success,
        value: {
          ...response.data[0],
          token: token,
          business_name: response.data[0].first_name,
        },
      };
      // console.dir(result)
    } catch (error) {
      result = this.getErrorResult(error, "Something went wrong.");
      // if (axios.isAxiosError(error) && error.response) {
      //     console.dir(error)
      //      let msg = error.message
      //     result =  {type: ResultType.Failure, error: new Error(msg)}
      //  } else {
      //      result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //  }
    }

    return result;
  }

  async login(userData: LoginData): Promise<Result<AccessToken>> {
    let result: Result<AccessToken>;

    this.endpoint = Endpoint.LOGIN;

    try {
      let response = await axios.post(this.endpointURL, userData);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // let msg = ""
        //  msg =  error.response.data.error

        let msg = "";
        if (error.response.status === 400) {
          // user has not registered
          const errorField = Object.keys(error.response.data)[0];

          if (errorField !== "details") {
            msg = `${errorField} - ${error.response.data[errorField]}`;
          } else if (Array.isArray(errorField)) {
            msg = errorField[0];
          } else {
            msg = error.response.data[errorField];
          }
          //  if (error.response.data.details.toLowerCase().includes("incorrect")) {
          //     msg = error.response.data.details
          //  } else {
          //     msg = "Something went wrong. Try again"
          //  }
          //`${email} has already been used. Try another one`
        } else if (error.response.status >= 500) {
          msg = "An error occurred. Probably from the server.";
        } else {
          msg = "Something went wrong"; //error.response.data.error
        }

        result = { type: ResultType.Failure, error: new Error(msg) };
      } else {
        result = {
          type: ResultType.Failure,
          error: new Error("WorkHopper seems to be offline. Try again 3"),
        };
      }
    }

    return result;
  }

  /*
    async postWorkspace(workspaceData: Workspace): Promise<Result<Workspace>> {

        let result: Result<Workspace>
 
        this.endpoint = Endpoint.WORKSPACE

       try {
          
           let response = await axios.post(this.endpointURL, workspaceData)
            console.dir(response) 
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
           if (axios.isAxiosError(error) && error.response) {
               console.dir(error)
                let msg = ""
               if (error.response.data.error) {
                   msg =  error.response.data.error 
               } else {
                 //msg = "An unexpected error occured. Try again"
                 let fields = Object.keys(error.response.data);

                 let temp = fields
                   .map((word) => {
                     word = word.replace("_", "")
                     return word[0].toUpperCase() + word.substring(1);
                   })
                   .join(" , ");
                   msg =`Please enter your ${temp}`
                 //msg =`Please enter your ${fields.join(" , ")}`
               }
               
               result =  {type: ResultType.Failure, error: new Error(msg)} 
            } else {
                result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")} 
            }
           
        }

       return result
    }*/

  async getAmenities(): Promise<Result<Amenity[]>> {
    let result: Result<Amenity[]>;

    this.endpoint = Endpoint.AMENITIES;

    try {
      let response = await axios.get(`${this.endpointURL}/`);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load amenities");
    }

    return result;
  }

  async getAmenitiesCategories(): Promise<Result<AmenityCategory[]>> {
    let result: Result<AmenityCategory[]>;

    this.endpoint = Endpoint.AMENITYCATEGORIES;

    try {
      let response = await axios.get(`${this.endpointURL}/`);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load amenities");

      // if (axios.isAxiosError(error) && error.response) {
      //     console.dir(error)
      //      let msg = error.message
      //     result =  {type: ResultType.Failure, error: new Error(msg)}
      //  } else {
      //      result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //  }
    }

    return result;
  }

  async patchResourceWithImage(
    id: string,
    file: File,
    token?: string
  ): Promise<Result<Workspace>> {
    let result: Result<Workspace>;

    this.endpoint = Endpoint.WORKSPACE;
    let data = new FormData();

    data.append("header", file);

    try {
      let response = await axios.patch(`${this.endpointURL}/${id}/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save the image due to an error"
      );

      //    if (axios.isAxiosError(error) && error.response) {
      //        console.dir(error)
      //         let msg = "Cannot save image. Try again"

      //        result =  {type: ResultType.Failure, error: new Error(msg)}
      //     } else {
      //         result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //     }
    }

    return result;
  }

  async patchPartnerWithHeader(
    partnerId: string,
    file: File,
    token: string
  ): Promise<Result<PartnerDetail>> {
    let result: Result<PartnerDetail>;

    this.endpoint = Endpoint.PARTNERS;
    let data = new FormData();

    data.append("header", file);
    try {
      let response = await axios.patch(
        `${this.endpointURL}/${partnerId}/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save the image due to an error"
      );

      //    if (axios.isAxiosError(error) && error.response) {
      //        console.dir(error)
      //         let msg = "Cannot save image. Try again"

      //        result =  {type: ResultType.Failure, error: new Error(msg)}
      //     } else {
      //         result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //     }
    }

    return result;
  }

  async patchListingWithImages(
    listing: Listing,
    header: File,
    others: OtherImages,
    token?: string
  ): Promise<Result<PublicListing>> {
    let result: Result<PublicListing>;
    this.endpoint = Endpoint.LISTING;
    let data = new FormData();
    if (header.size > 0) {
      data.append("header", header);
      //data.append("_method", "PATCH")
    }

    Object.entries(others).forEach(([key, value]) => {
      //data.append(key, value)
      if (value && (value as File).size > 0) {
        data.append(key, value);
      } else if (value === undefined) {
        data.append(key, "");
      }
    });

    let response: AxiosResponse<any>;
    try {
      if (!listing.id) {
        throw new Error("Couldn't update listing");
      }

      response = await axios.patch(`${this.endpointURL}/${listing.id}/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      // if (!listing.images) {
      //      response = await axios.post(`${this.endpointURL}${listing.id}`, data, {
      //         headers: {
      //       'Content-Type': 'multipart/form-data',
      //       'Authorization': `Bearer ${token}`
      //     }
      //    })
      // } else {
      //     response = await axios.patch(`${this.endpointURL}${listing.id}`, data, {
      //         headers: {
      //       'Content-Type': 'multipart/form-data',
      //       'Authorization': `Bearer ${token}`
      //     }
      //    })
      // }

      result = { type: ResultType.Success, value: response.data };
      //console.dir(result)
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Something went wrong saving 1 or more images. Try again"
      );
      // if (axios.isAxiosError(error) && error.response) {
      //     console.dir(error)
      //      let msg = "Something went wrong saving an image. Try again"

      //     result =  {type: ResultType.Failure, error: new Error(msg)}
      //  } else {
      //       result =  {type: ResultType.Failure, error: error}

      //     }
    }

    return result;
  }

  async patchWorkspace(
    id: string,
    section: DashboardSectionName,
    sectionData: any,
    token?: string
  ): Promise<Result<Workspace>> {
    let result: Result<Workspace>;

    this.endpoint = Endpoint.WORKSPACE;
    let temp = {};

    switch (section) {
      case DashboardSectionName.Desc:
        let data = sectionData as DescForm;
        temp = { title: data.name, description: data.desc };
        break;
      case DashboardSectionName.ContactDetails:
        let c = sectionData as ContactForm;

        temp = { website_url: c.websiteURL, email: c.workspaceEmail };
        break;
      case DashboardSectionName.Amenities:
        let a = sectionData as String[];
        temp = { amenities: { amenities: a } };
        break;
      case DashboardSectionName.Location:
        let l = sectionData as LocationForm;
        //temp = {zip_code: l.zipCode, street: l.street}
        temp = { location: l.city, zip_code: l.zipCode, street: l.street };

        break;
      case DashboardSectionName.HOP:
        let h = sectionData as HopForm;
        let days: string[] = [];

        if (h.satOpened) {
          days.push("Sat");
        }
        if (h.sunOpened) {
          days.push("Sun");
        }
        temp = {
          extra_days: { days },
          available_hours: {
            start_time: h.from,
            end_time: h.to,
            sat_start_time: h.satFrom,
            sat_end_time: h.satTo,
            sun_start_time: h.sunFrom,
            sun_end_time: h.sunTo,
            is_publish: true,
          },
        };
        break;
      case DashboardSectionName.Partner:
        temp = sectionData;
        break;
      case DashboardSectionName.Size: {
        let s = sectionData as SizeForm;
        temp = { capacity: Number(s.capacity), size: "0" };
        break;
      }
    }

    try {
      let response = await axios.patch(`${this.endpointURL}/${id}/`, temp, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "One or more fields is invalid.");
      //    if (axios.isAxiosError(error) && error.response) {
      //        console.dir(error)
      //         let msg = "One or more fields is invalid."

      //        result =  {type: ResultType.Failure, error: new Error(msg)}
      //     } else {
      //         result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //     }
    }

    return result;
  }

  async postDashboardSection(
    id: string | undefined,
    section: DashboardSectionName,
    sectionData: any,
    token?: string
  ): Promise<Result<Workspace>> {
    let result: Result<Workspace>;

    this.endpoint = Endpoint.WORKSPACE;
    let temp = {};

    // if (section === DashboardSectionName.Desc) {
    //     let data = sectionData as DescForm
    //     temp = {title: data.name, description: data.desc}
    // }
    //debugger
    if (section === DashboardSectionName.Desc) {
      let data = sectionData as DescForm;
      temp = { title: data.name, description: data.desc };
    }

    let response: AxiosResponse<any>;
    try {
      if (!id) {
        response = await axios.post(`${this.endpointURL}/`, temp, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.patch(`${this.endpointURL}/${id}/`, temp, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "One or more fields is invalid.");
      // if (axios.isAxiosError(error) && error.response) {
      //    console.dir(error)
      //     let msg = "One or more fields is invalid."

      //    result =  {type: ResultType.Failure, error: new Error(msg)}
      // } else {
      //     result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      // }
    }

    return result;
  }

  /**
   * gets all workspaces of a partner or a workspaces or all workspacess created so far
   * @param isPartner indicates whether to get all partner workspaces
   * @param workspaceId when passed returns workspace with id
   * @param param
   * @param token access token of registered partner
   * @returns Workspaces
   */
  async getWorkspace(
    isPartner: boolean,
    workspaceId: string | undefined,
    token: string,
    nextURL?: string
  ): Promise<Result<ListResponseData<Workspace>>> {
    let result: Result<ListResponseData<Workspace>>;
    let url = "";

    this.endpoint = Endpoint.WORKSPACE;
    if (!nextURL) {
      if (isPartner) {
        url = `${this.endpointURL}/?partner=True`;
      } else if (workspaceId) {
        url = `${this.endpointURL}/${workspaceId}/`;
      } else {
        url = `${this.endpointURL}/`;
      }
    } else {
      url = nextURL;
    }

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //let response = await axios.get(url)

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "One or more fields is invalid.");
    }

    return result;
  }

  async getWorkspaceWithId(
    workspaceId: string | undefined,
    token?: string
  ): Promise<Result<Workspace>> {
    let result: Result<Workspace>;

    this.endpoint = Endpoint.WORKSPACE;
    let url = `${this.endpointURL}/${workspaceId}/`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't get workspace data");
      //    if (axios.isAxiosError(error) && error.response) {
      //        console.dir(error)
      //         let msg = "Something went wrong"

      //        result =  {type: ResultType.Failure, error: new Error(msg)}
      //     } else {
      //         result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //     }
    }

    return result;
  }

  async deleteWorkspaceWithId(
    workspaceId: string | undefined,
    token?: string
  ): Promise<Result<unknown>> {
    let result: Result<unknown>;

    this.endpoint = Endpoint.WORKSPACE;
    let url = `${this.endpointURL}/${workspaceId}/`;

    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't delete workspace.");
      //    if (axios.isAxiosError(error) && error.response) {
      //        console.dir(error)
      //         let msg = "Something went wrong"

      //        result =  {type: ResultType.Failure, error: new Error(msg)}
      //     } else {
      //         result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //     }
    }

    return result;
  }

  async getListingsWithId(
    workspaceId: string | undefined,
    token?: string
  ): Promise<Result<Listing[]>> {
    let result: Result<Listing[]>;

    this.endpoint = Endpoint.LISTING;
    // let url = `${this.endpointURL}/${workspaceId}`
    // console.log(workspaceId)
    let url = `${this.endpointURL}/?workspace=${workspaceId}`;
    //console.log(url)
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Something went wrong");
      /*
           if (axios.isAxiosError(error) && error.response) {
               console.dir(error)
                let msg = "Something went wrong"
                
               
               result =  {type: ResultType.Failure, error: new Error(msg)} 
            } else {
                result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")} 
            }*/
    }

    return result;
  }
  // get listings of a workspace
  async getWorkspaceListingsWithId(
    workspaceId: string,
    token: string,
    nextURL?: string
  ): Promise<Result<ListResponseData<Listing>>> {
    let result: Result<ListResponseData<Listing>>;

    this.endpoint = Endpoint.LISTING;
    // let url = `${this.endpointURL}/${workspaceId}`
    // console.log(workspaceId)
    let url = "";
    if (!nextURL) {
      url = `${this.endpointURL}/?workspace=${workspaceId}`;
    } else {
      url = nextURL;
    }
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load workspace offices");
    }

    return result;
  }

  async getListingsByCity(
    city: string,
    token: string | undefined
  ): Promise<Result<Listing[]>> {
    let result: Result<Listing[]>;

    this.endpoint = Endpoint.LISTING;
    // let url = `${this.endpointURL}/${workspaceId}`
    let url = `${this.endpointURL}/?city=${city}`;
    try {
      let response = await axios.get(url, {
        timeout: 2000,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't get Offices");
      //    if (axios.isAxiosError(error) && error.response) {
      //          let msg = "Cannot Get Offices"

      //        result =  {type: ResultType.Failure, error: new Error(msg)}
      //     } else {
      //         result =  {type: ResultType.Failure, error: new Error("WorkHopper seems to be offline. Try again")}
      //     }
    }

    return result;
  }

  async patchIsPublished(
    workspaceId: string,
    token: string,
    isPublished: boolean
  ): Promise<Result<Workspace>> {
    let result: Result<Workspace> = {
      type: ResultType.Failure,
      error: new Error("Couldn't publish workspace"),
    };

    this.endpoint = Endpoint.WORKSPACE;
    let url = `${this.endpointURL}/${workspaceId}/`;

    let patch = { is_publish: isPublished };

    try {
      let response = await axios.patch(url, patch, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load workspaces");
      // if (axios.isAxiosError(error) ) {
      //     let msg = ""
      //     if (error.response) {
      //        msg = "Couldn't load workspaces"
      //        result =  {type: ResultType.Failure, error: new Error(msg)}

      //     } else if (error.request) {
      //            msg = "WorkHopper seems to be offline."
      //            result =  {type: ResultType.Failure, error: new Error(msg)}

      //     } else {
      //        // Something happened in setting up the request that triggered an Error
      //       msg = "Something went wrong"

      //       // console.log('Error', error.message);
      //        result =  {type: ResultType.Failure, error: new Error(msg)}

      //      }

      //    } else {
      //        result =  {type: ResultType.Failure, error: new Error("An unexpected error occured")}
      //    }
    }

    return result;
  }

  async deleteListingWithId(
    listingId: string,
    token: string
  ): Promise<Result<unknown>> {
    let result: Result<unknown>;

    this.endpoint = Endpoint.LISTING;
    let url = `${this.endpointURL}/${listingId}/`;

    try {
      let response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't delete listing. Try Again");
    }

    return result;
  }

  async fetchPartnerBasicInfo(partnerId: string): Promise<Result<unknown>> {
    let result: Result<unknown>;

    this.endpoint = Endpoint.PARTNERS;
    let url = `${this.endpointURL}/${partnerId}/`;

    try {
      let response = await axios.get(url);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load data. Try Again");
    }

    return result;
  }

  async getEnquiries(
    token: string,
    nextURL?: string
  ): Promise<Result<ListResponseData<EnquiredItem>>> {
    let result: Result<ListResponseData<EnquiredItem>>;

    this.endpoint = Endpoint.ENQUIRY;
    //let url = `${this.endpointURL}/`

    let url = "";
    if (!nextURL) {
      url = `${this.endpointURL}/`;
    } else {
      url = nextURL;
    }

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load enquiries. Try Again");
    }

    return result;
  }

  //async getBookings(token: string): Promise<Result<UserBooking[]>> {
  async getBookings(
    token: string,
    nextURL: string | undefined
  ): Promise<Result<ListResponseData<UserBooking>>> {
    let result: Result<ListResponseData<UserBooking>>;

    this.endpoint = Endpoint.BOOKINGS;

    let url = `${this.endpointURL}/`;
    if (nextURL) {
      url = nextURL;
    }

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //let response = await axios.get(url)

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load bookings. Try Again");
      console.dir(error);
    }

    return result;
  }

  async getUserBooking(
    token: string,
    bookingId: string
  ): Promise<Result<UserBooking>> {
    let result: Result<UserBooking>;

    this.endpoint = Endpoint.BOOKINGS;
    let url = `${this.endpointURL}/${bookingId}/`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load booking. Try Again");
    }

    return result;
  }

  //async getPartnerBookings(token: string): Promise<Result<PartnerBooking[]>> {
  async getPartnerBookings(
    token: string,
    nextURL?: string
  ): Promise<Result<ListResponseData<PartnerBooking>>> {
    // let result: Result<PartnerBooking[]>
    let result: Result<ListResponseData<PartnerBooking>>;

    this.endpoint = Endpoint.PARTNERBOOKINGS;
    //let url = `${this.endpointURL}/`
    let url = "";
    if (!nextURL) {
      url = `${this.endpointURL}/`;
    } else {
      url = nextURL;
    }

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load bookings. Try Again");
    }

    return result;
  }

  async getEnquiry(
    enquiryId: string,
    token: string
  ): Promise<Result<EnquiredItem>> {
    let result: Result<EnquiredItem>;

    this.endpoint = Endpoint.ENQUIRY;
    let url = `${this.endpointURL}/${enquiryId}/`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't load enquiry. Try Again");
    }

    return result;
  }

  async getBooking(
    bookingId: string,
    token: string
  ): Promise<Result<PartnerBooking>> {
    let result: Result<PartnerBooking>;

    this.endpoint = Endpoint.PARTNERBOOKINGS;
    let url = `${this.endpointURL}/${bookingId}/`;

    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't load booking details. Try Again"
      );
    }

    return result;
  }

  async updateBookingStatus(
    bookingId: string,
    token: string,
    newStatus: BOOKINGSTATUS
  ): Promise<Result<PartnerBooking>> {
    let result: Result<PartnerBooking>;

    this.endpoint = Endpoint.PARTNERBOOKINGS;
    let url = `${this.endpointURL}/${bookingId}/`;

    const payload = {
      id: bookingId,
      status: newStatus,
    };

    try {
      let response = await axios.patch(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't update booking details. Try Again"
      );
    }

    return result;
  }

  async updateBookedItem(
    bookingId: string,
    token: string,
    data: any
  ): Promise<Result<PartnerBooking>> {
    let result: Result<PartnerBooking>;

    this.endpoint = Endpoint.PARTNERBOOKINGS;
    let url = `${this.endpointURL}/${bookingId}/`;

    // const payload = {
    //     id: bookingId,
    //     status: newStatus
    // }

    try {
      let response = await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't update booking details. Try Again"
      );
    }

    return result;
  }

  async updateEnquiredItem(
    enquiredItemId: string,
    token: string,
    data: any
  ): Promise<Result<EnquiredItem>> {
    let result: Result<EnquiredItem>;

    this.endpoint = Endpoint.ENQUIRY;
    let url = `${this.endpointURL}/${enquiredItemId}/`;

    try {
      let response = await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't update booking details. Try Again"
      );
    }

    return result;
  }

  async patchListingPublish(
    listingId: string,
    token: string,
    patch: any
  ): Promise<Result<PublicListing>> {
    let result: Result<PublicListing> = {
      type: ResultType.Failure,
      error: new Error("Couldn't publish listing"),
    };

    this.endpoint = Endpoint.LISTING;
    let url = `${this.endpointURL}/${listingId}/`;

    //let patch = {is_publish: isPublished}

    try {
      let response = await axios.patch(url, patch, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't update listing");
    }

    return result;
  }

  async loginWithGoogle(data: any): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    //debugger
    this.endpoint = Endpoint.USERS;
    // let url = `${this.endpointURL}/${listingId}/`

    //let patch = {is_publish: isPublished}

    // try {
    //     let response = await axios.patch(this.endpointURL, data, {
    //         headers: {
    //         'Authorization': `Bearer ${token}`
    //     }
    //    })

    try {
      let response = await axios.post(this.endpointURL, data);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't login");
    }

    return result;
  }

  async saveBookingPayment(data: any, token: string): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    //debugger
    this.endpoint = Endpoint.BOOKINGPAYMENT;

    try {
      let response = await axios.post(`${this.endpointURL}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't complete your booking. Try again"
      );
    }

    return result;
  }

  async verifyPremiumPayment(data: any, token: string): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    this.endpoint = Endpoint.PAYMENTVERIFICATION;

    try {
      let response = await axios.get(
        `${this.endpointURL}/?payment_type=${data.payment_type}&payment_ref=${data.payment_ref}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't verify your payment. Please contact WorkHopper"
      );
    }

    return result;
  }

  async savePremiumPaymentData(data: any, token: string): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    this.endpoint = Endpoint.PREMIUMLISTING;

    try {
      let response = await axios.post(`${this.endpointURL}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save payment details. Try again"
      );
    }

    return result;
  }

  async resetPassword(data: { email: string }): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    // debugger
    this.endpoint = Endpoint.FORGOTPASSWORD;

    try {
      let response = await axios.post(`${this.endpointURL}/`, data);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't reset password. Try again");
    }

    return result;
  }

  async confirmNewPassword(data: {
    email: string;
    password: string;
    otp: string;
  }): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    //debugger
    this.endpoint = Endpoint.FORGOTPASSWORDCHANGE;

    try {
      let response = await axios.post(`${this.endpointURL}/`, data);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't confirm new password. Try again"
      );
      //result = this.getErrorResult(error,  error.)
    }

    return result;
  }

  async subscribeToNewsletter(data: { email: string }): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };

    this.endpoint = Endpoint.NEWSLETTER;

    try {
      let response = await axios.post(`${this.endpointURL}/`, data);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        let msg = "";

        if (error.response.status === 400) {
          if (
            error.response.data &&
            error.response.data.email &&
            error.response.data.email.length > 0
          ) {
            msg = `${error.response.data.email[0]} Try another one`;
          } else {
            msg = "one or more required fields are missing"; //error.message
          }
        } else if (error.response.status >= 500) {
          msg = "An error occurred. Probably from the server.";
        } else {
          msg = "Something went wrong"; //error.response.data.error
        }
        result = { type: ResultType.Failure, error: new Error(msg) };
      } else {
        result = {
          type: ResultType.Failure,
          error: new Error("WorkHopper seems to be offline. Try again 4"),
        };
      }
    }

    return result;
  }

  async getBookedDates(
    listingId: string,
    durationType: string
  ): Promise<Result<any>> {
    //let result: Result<any> = {type: ResultType.Failure, error: new Error("")}
    let result: Result<any> = {
      type: ResultType.Success,
      value: bookedDatesDefaultValue,
    };
    return result;

    this.endpoint = Endpoint.BOOKINGDURATION;

    try {
      //  let response = await axios.get(`${this.endpointURL}/${listingId}/`, {
      //      headers: {
      //              'Authorization': `Bearer ${token}`
      //      }
      //  })

      let response = await axios.get(
        `${this.endpointURL}/${listingId}/?duration_type=${durationType}`
      );

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save payment details. Try again"
      );
    }

    return result;
  }

  async getBookedDatesForDuration(
    listingId: string,
    durationType: string
  ): Promise<Result<[string, DurationTime[]]>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result

    this.endpoint = Endpoint.BOOKINGDURATION;

    try {
      //  let response = await axios.get(`${this.endpointURL}/${listingId}/`, {
      //      headers: {
      //              'Authorization': `Bearer ${token}`
      //      }
      //  })

      let response = await axios.get(
        `${this.endpointURL}/${listingId}/?duration_type=${durationType}`
      );

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save payment details. Try again"
      );
    }

    return result;
  }

  async getEndDate(
    listingId: string,
    durationType: string,
    duration: number,
    startDate: Date
  ): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result

    this.endpoint = Endpoint.BOOKINGENDDATE;

    try {
      let response = await axios.get(
        `${this.endpointURL}/${listingId}/?duration=${durationType}`
      );

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save payment details. Try again"
      );
    }

    return result;
  }

  async getCurrencies(): Promise<Result<WorkHopperCurrency[]>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result

    this.endpoint = Endpoint.CURRENCIES;

    try {
      let response = await axios.get(`${this.endpointURL}/`);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(
        error,
        "Couldn't save payment details. Try again"
      );
    }

    return result;
  }

  async getClaimCode(listingId: string): Promise<Result<{ success: string }>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result

    this.endpoint = Endpoint.GENCODE;
    //  debugger
    try {
      let response = await axios.get(`${this.endpointURL}/${listingId}`);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't generate code. Try again");
    }

    return result;
  }

  async verifyClaimCode(
    listingId: string,
    code: string
  ): Promise<Result<{ success: string }>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result

    this.endpoint = Endpoint.VERIFYCODE;
    // debugger
    try {
      let response = await axios.post(`${this.endpointURL}/`, {
        listing_id: listingId,
        code: code,
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't verify code. Try again");
    }

    return result;
  }

  async claimListing(data: any): Promise<Result<{ success: string }>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result

    this.endpoint = Endpoint.CLAIMLISTING;
    // debugger
    try {
      let response = await axios.post(`${this.endpointURL}/`, data);

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't claim listing. Try again");
    }

    return result;
  }

  async getPartnerAnalytics(token: string): Promise<Result<RequestsAnalytics>> {
    let result: Result<RequestsAnalytics> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result
    //debugger
    this.endpoint = Endpoint.PARTNERBOOKINGANALYTICS;

    try {
      let response = await axios.get(`${this.endpointURL}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't get analytics. Try again");
    }

    return result;
  }

  async getPartnerEnquiryAnalytics(
    token: string,
    id: string
  ): Promise<Result<any>> {
    let result: Result<any> = {
      type: ResultType.Failure,
      error: new Error(""),
    };
    // let result: Result<any> = {type: ResultType.Success, value: bookedDatesDefaultValue}
    // return result
    //debugger
    this.endpoint = Endpoint.PARTNERENQUIRIESANALYTICS;

    try {
      let response = await axios.get(`${this.endpointURL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result = { type: ResultType.Success, value: response.data };
    } catch (error) {
      result = this.getErrorResult(error, "Couldn't get analytics. Try again");
    }

    return result;
  }

  
}

//export const apiManager = WorkhopperAPIManager.shared

import { HStack, VStack, Text, Image, Circle, AspectRatio, Link, GridItem, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

import { MdStar } from 'react-icons/md';
import { PublicListing, ResultType } from '../../../../../Models/Interfaces';
import { OfficeDetailLink } from '../../../../SupportingViews/Links/Links';
import OfficeItemView from '../../../../SupportingViews/OfficeItemView/OfficeItemView';
import SearchResultsItemViews from '../../../../SupportingViews/SearchResultsItemViews/SearchResultsItemViews';
import { appDataManager } from '../../Models/WHAppDataManager';
import { useTranslation  } from "react-i18next";

 interface RelatedOfficesProps {
      categoryId: string
      categoryTitle: string
      listingId: string
 }

const RelatedOffices = ({categoryId, categoryTitle, listingId}: RelatedOfficesProps) => {
     
     const [listings, setListings] = useState<PublicListing[]>([])
     const [error, setError] = useState<Error | null>(null) 
     const {t} = useTranslation()

     let gridline = 4

     const [xsmall, small, medium, large, xl] = useMediaQuery([
          "(max-width: 599px)",
          "(min-width: 600px) and (max-width: 904px)",
          "(min-width: 905px) and (max-width: 1239px)",
          "(min-width: 1240px) and (max-width: 1439px)",
          "(min-width: 1440px)",
        ]);

        if (small) {
             gridline = 8
        } else if (large || xl || medium) {
             gridline = 12
        }

     const loadToplistings = async () => {
           
             // setisLoadinng(true)
             
              let result = await appDataManager.getListingsByCategory(categoryId)
             // setisLoadinng(false)
             
             if (result.type === ResultType.Success) {
                 // setRequestStatus(ResultType.Success)
                  setListings(result.value.filter(item => item.id !== listingId).slice(0, 10))
                  setError(null)
                  //setResult({type: result})
              } else {
                  //setRequestStatus(ResultType.Failure)
                  setError(result.error)
                  setListings([])
              }
          

      }

      useEffect(() => {

          if (categoryId) {
               loadToplistings()
          }
          
          //  return () => {
          //       cleanup
          //  }
      }, [])

    const relatedOfficeItemViews = listings.filter((item)=>item.id).map((item, idx) => (
        <GridItem 
             key={item.id}
             rowSpan={4}
             colSpan={4}
          > 
           <OfficeDetailLink to={`/office/${item.id}/detail`}>
           {/* <OfficeDetailLink to={`/office/${item.id}/detail`}> */}

            <OfficeItemView listing={item} />
            </OfficeDetailLink>
          </GridItem>
    ))

    return (
           <>
           <GridItem
                rowSpan={1}
                colSpan={gridline}
           >
                <Text className="h6">
                {t("Other")}
                     {/* Other  */}
                     {categoryTitle}s 
                     {t("You Might Like")}
                     {/* You Might Like */}
                     </Text>
           </GridItem>
           <SearchResultsItemViews listings={listings} />
          {/* {relatedOfficeItemViews}  */}
          </> 
         // <VStack spacing={4}>
        //   <Text className="h6">Other Private Offices You Might Like</Text>
        //   {relatedOfficeItemViews}  
        // </VStack>
    )
}

export default RelatedOffices

import { VStack, Text, Box, HStack, Circle, Spacer, Button } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ListingCategory, RequestItem, ResultType } from '../../../../Models/Interfaces'
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager'
import routes from '../../../../routes'
import { mapOfficeCategory } from '../../../../utils/utils'
import { CategoriesContext, UserContext } from '../../../AppProvider/AppProvider'
import { ListingLink } from '../../../SupportingViews/Links/Links'
import OfflineView from '../../../SupportingViews/OfflineView'
import WHLoadMore from '../../../SupportingViews/WHLoadMore/WHLoadMore'
import WHSeeMoreList from '../../../SupportingViews/WHSeeMoreList/WHSeeMoreList'
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner'
//import { CompartmentCategory } from '../../Models/Interfaces'
import { BOOKINGSTATUS } from '../../WHDashboard/Models'
import BookingsTable from '../../WHDashboard/WHManageRequests/BookingsTable'
import { Booking, UserBooking } from '../../WHDashboard/WHManageRequests/Models/Booking'
import { useTranslation  } from "react-i18next";

interface UserBookingViewProps {
    bookingData: UserBooking
    officeCategories: ListingCategory[]
}

const UserBookingView = ({bookingData, officeCategories}: UserBookingViewProps)=> {

  const {t} = useTranslation()
   const items: RequestItem[] = bookingData.items.map((item,idx) => (
                {
                    name: item.listing.title,
                    dateCreated: `${format(new Date(item.date_booked), "PP")}`,
                    status: item.status, //bookingData.status, //item.status,
                    userName: `${bookingData.user.first_name} ${bookingData.user.last_name}`,
                    category:  mapOfficeCategory(false, officeCategories, item.listing.category), //mapOfficeCategory(false, categories, item.listing), 
                    id: idx + 1,
                    detailURL: `/office/${item.listing.id}/detail`,
                    is_accept_term: item.is_accept_term,
                    itemId: item.id.toString()
                }
            ))

            return (
              <Box shadow="sm" bg="white" p={4} w="full" rounded="xl">
                <VStack
                  className="body1"
                  fontWeight="400 !important"
                  fontSize="18px !important"
                  alignItems="flex-start"
                >
                  <VStack alignItems="flex-start">
                    <HStack>
                      <Text>
                        {t('BookingID')}
                        {/* BookingID */}
                        </Text>
                      <Circle bg={"black"} w={1} h={1}></Circle>
                      <Text
                      //className="h6" fontSize="18px !important"
                      >
                        {bookingData.booking_id}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text>
                        {t('Date Booked')}
                        {/* Date Booked */}
                        </Text>
                      <Circle bg={"black"} w={1} h={1}></Circle>
                      <Text
                      //fontSize="18px !important"
                      >
                        {format(new Date(bookingData.date_booked), "PP")}
                      </Text>
                    </HStack>
                  </VStack>
                  <Spacer />
                  <ListingLink
                    to={`/${routes.payBooking.name}/${bookingData.id}`}
                  >
                  <Button colorScheme="primary" variant="outline">
                      {
                          bookingData.status === BOOKINGSTATUS.PAID ?
                          "View Details" : "Review and Pay"
                      }
                  </Button>
                  </ListingLink>
                </VStack>
                <VStack>
                  <BookingsTable bookingItems={items} />
                </VStack>
              </Box>
            );
}

const BookingHistory = () => {
     
    const user = useContext(UserContext)
    const [data, setData] = useState<RequestItem[]>([])
    const [isLoading, setIsLoading] = useState(false)
   
    const [error, setError] = useState<Error | null>(null)
    const [userBookingData, setUserBookingData] = useState<UserBooking[]>([])
    const officeCategories = useContext(CategoriesContext)
    const [nextURL, setNextURL] = useState<string | undefined>()
    const {t} = useTranslation()

    const loadBookings = useCallback(async ()=>{

        if (!user?.access_token) {return}


        setIsLoading(true)

        //  debugger
        let result = await WorkhopperAPIManager.shared.getBookings(user.access_token, nextURL)
       
       
        if (result.type === ResultType.Success) {
           //debugger
           //console.dir(result.value.results)
          // console.dir(result.value)
            const nextData = result.value.results
          //   .sort((a, b)=> {
          //     if (a.date_booked < b.date_booked) {
          //         return 1
          //     } else if (a.date_booked > b.date_booked) {
          //         return -1
          //     } else {
          //         return 0
          //     }
          // })
            setUserBookingData([...userBookingData, ...nextData].sort((a, b)=> {
              if (a.date_booked < b.date_booked) {
                  return 1
              } else if (a.date_booked > b.date_booked) {
                  return -1
              } else {
                  return 0
              }
          }))
             setNextURL(result.value.next)
             setError(null)
         
        } else {
           setError(result.error)
        }

        setIsLoading(false)
        

    }, [isLoading])

    useEffect(() => {

        if (!user) return 
   
         loadBookings()
         return () => {
            
        }
    }, [user])

    return (
         <>
         {
             isLoading && userBookingData.length === 0 ? (
                 <WHSpinner pt="64px" />
             ): error ? (
                 <OfflineView msg={error.message} pt="64px" onRetry={()=>{
                     loadBookings()
                 }} />
             ): (
                <VStack bg="#fafafa" padding={4} alignItems="flex-start">
                    <Text className="h5">
                      {t('Your Booking History')}
                      {/* Your Booking History */}
                      </Text>
                    {
                        userBookingData.length === 0 ? (
                            <VStack w="full" h="50vh" alignItems="center" justifyContent="center">
                                 <Text className="h6">
                                   {t('You have no booked offices')}
                                   {/* You have no booked offices */}
                                   </Text>
                            </VStack>
                        ): (
                           
                          <WHSeeMoreList data={userBookingData} 
                                    isLoading={isLoading} 
                                    loadData={ loadBookings }
                                    nextURL={nextURL}
                                    error={error}
                                    spinnerHeight="32px"
                                    >
                            {
                               userBookingData.map((userBookingItem) => (
                                <UserBookingView bookingData={userBookingItem} officeCategories={officeCategories} />
                                ))
                            }
                          </WHSeeMoreList>
                        //   userBookingData.map((userBookingItem) => (
                        //     <UserBookingView bookingData={userBookingItem} officeCategories={officeCategories} />
                        // ))
                        )
                    }
                   
                 </VStack>
             )
               
            
             
         }
        </>

    )
}

export default BookingHistory

import { SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Result, ResultType } from '../../../Models/Interfaces'
import WHLoadMore from '../WHLoadMore/WHLoadMore'

// interface WHSeeMoreListProps<T> {
//     children: ReactNode
//     data: T[]
//     fetchData: ()=>Promise<Result<T[]>>
//     setData: (data: T[])=>void
     
// }

/*
const WHSeeMoreList = <T extends unknown>(props: WHSeeMoreListProps<T>) => {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>() 
    const [count, setCount] = useState(0)
    const [nextURL, setNextURL] = useState<string>("") 

     
    const loadData = useCallback(async ()=>{
        setIsLoading(true)
        let rslt = await props.fetchData()
        if (rslt.type === ResultType.Success) {
            props.setData(rslt.value)
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        
        loadData()

        return () => {
        }
    }, [count])
    
    return (
        <>
            <SimpleGrid columns={1} row="auto" rowGap={4}>
                {props.children}
            </SimpleGrid>
            <WHLoadMore 
                    isLoading={isLoading}
                    w="120px"
                    nextURL={nextURL}
                    counterUpdater={()=>{
                        setCount(prev=> prev + 1)
                    }}
                    colorScheme="primary"
                    error={error} 
                    listings={props.data} />
        </>
    )
}*/

interface WHSeeMoreListProps<T> {
    children: ReactNode
    data: T[]
    isLoading: boolean
    loadData: ()=>void
    error?: Error | null
    nextURL?: string
    spinnerHeight?: string
    useGrid?: boolean
    isWorkspaceGrid?: boolean
    //setData: (data: T[])=>void
     
}

const WHSeeMoreList = <T extends unknown>(props: WHSeeMoreListProps<T>) => {

    //const [isLoading, setIsLoading] = useState(false)
    //const [error, setError] = useState<Error | undefined>() 
    const [count, setCount] = useState(0)

    const [xsmall, small, medium, large, xl] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 1240px) and (max-width: 1439px)",
        "(min-width: 1440px)",
      ]);
        
    let colspan = 1
    if (xsmall) {
        colspan = 1
    }else if (small) {
        colspan = 2
    } else  {
          colspan = 3
    }

     

    useEffect(() => {
        
        if (count > 0) {
            props.loadData()
        }

        return () => {
        }
    }, [count])
    
    return (
             <>
                {
                         props.useGrid ? (
                            
                             <SimpleGrid columns={props.isWorkspaceGrid ? { base: 1, sm: 2, md: 3, lg: 4, xl: 4 } : colspan} mb={8}
                             spacingX="16px"
                             spacingY="16px" 
                             w="full"
                             >
                                 {props.children}
                             </SimpleGrid>
                                 
                            ):(
                              <>
                                 {props.children}
                              </>
                            )
                }
                <WHLoadMore 
                        isLoading={props.isLoading}
                        w="120px"
                        nextURL={props.nextURL}
                        counterUpdater={()=>{
                            setCount(prev=> prev + 1)
                        }}
                        colorScheme="primary"
                        error={props.error} 
                        listings={props.data} 
                        spinnerHeight={props.spinnerHeight}
                        />
             </>
              
           
    )
}

export default WHSeeMoreList

import { Stack,  Image, Box, Link, StackDirection, Text, SimpleGrid, useMediaQuery, Divider, VStack, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@chakra-ui/react';
 import { NavLink as ReactLink, useLocation} from 'react-router-dom'

import { AiFillFacebook, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineTwitter } from "react-icons/ai";
import logo from '../../../Assets/workhopper-logo-white.png'
import styles from './WHFooter.module.css'
import { useCallback, useContext, useEffect, useState } from 'react';
import { WorkhopperAPIManager } from '../../../Models/WorkhopperAPIManager';
import { appDataManager } from '../../Screens/WHApp/Models/WHAppDataManager';
import { Currency, ListingCategory, ResultType } from '../../../Models/Interfaces';
import { HashLink, HashLink as ReactHashLink } from 'react-router-hash-link';
import { CategoriesContext } from '../../AppProvider/AppProvider';
import { getQueryItems } from '../../../utils/utils';
import { Queries } from '../../Screens/WHApp/SearchResuts/SearchResults';
import { WorkhopperPublicAPIManager } from '../../../Models/WorkhopperPublicAPIManager';
import { CurrencyConverterContext, SetCurrencyConverterContext,   } from '../../AppProvider/CurrencyConverterProvider';
import routes from '../../../routes';
import { useTranslation } from 'react-i18next';
 
 

interface Subpage {
  title: string 
  url: string
}

const WHFooter = () => {
  
  const officeCategories = useContext(CategoriesContext)
  const currencyConverter = useContext(CurrencyConverterContext)
  const setCurrencyConverter = useContext(SetCurrencyConverterContext)

  const [categories, setCategories] = useState<ListingCategory[]>([])
  const [allOfficesURL, setAllOfficesURL] = useState("")
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currencyConverter.target) 
  const location = useLocation()
  const {t} = useTranslation()
  
  const categorySubpages = categories.map(item => (
    {
      title: item.title,
      url: appDataManager.searchURL(item.id)

    }
  ))

  const searchSubpages = [
    {
    title: 'All Office',
    url: allOfficesURL
  },
  //  {
  //    title: "Top Cities",
  //    url: ""
  //  }
]
  
  //console.dir(location)
   
   
    
    

    let dir: StackDirection="column"
    let pages = [
        {
            title: t("About"),
            route: "/about"
        },
        {
            title: t("Contact Us"),
            route: routes.contact.path
        },
        {
          title: t("Search"),
          route: "/home",
          subPages: searchSubpages
        },
        {
          title: t("Workspaces"),
          route: "/spaces",
          subPages: categorySubpages //["Private Offices", "Shared Offices", "Meeting Rooms", "Virtual Offices"]
      },
    ]

     

    const socialLinkViewItems = [
      {
          icon: <AiFillFacebook className={styles.icon}/>,
          link: "/"
      },
      {
          icon: <AiOutlineInstagram className={styles.icon}/>,
          link: "/"
      },
      {
          icon: <AiOutlineLinkedin className={styles.icon}/>,
          link: "/"
      },
      {
          icon: <AiOutlineTwitter className={styles.icon} />,
          link: "/"
      }
  ]
  
  //const socialLinkViewItems = []

    const [xsmall, small] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        // "(min-width: 905px) and (max-width: 1239px)",
        // "(min-width: 905px) and (max-width: 1439px)",
        // "(min-width: 1440px)",
      ]);

    
 
      if (small || xsmall) {

      } else {
          dir = "row"
      }

      const fetchExchangeRate = useCallback(async (base: string, target: string) => {
         
        let result = await WorkhopperPublicAPIManager.shared.getExchangeRate(base, target)
           if (result.type === ResultType.Success) {
             const newTarget = currencyConverter.currencies.find(item => item.code === target)
             setCurrencyConverter({...currencyConverter, target: newTarget!, rate: result.value.rate})
           } else {

           }
       },
    [],)

      const onTargetCurrencySelected = useCallback((selected: Currency)=>{
            
           fetchExchangeRate(currencyConverter.base.code, selected.code)
        
      },[])



      useEffect(() => {
         
        let queryItemsObj: Queries = {category: []}
        let temp = ""
        if (officeCategories.length > 0) {
          queryItemsObj.category = officeCategories.map(item => item.id)
         // WorkhopperAPIManager.
          temp = WorkhopperPublicAPIManager.shared.appendQueryitems("/search", getQueryItems(queryItemsObj))  
          
          setAllOfficesURL(temp)
         // const categories = appDataManager.getCategoriesData("list-cat").reverse()
          setCategories(officeCategories)
        }
       
        return () => {
          
        }
      }, [officeCategories])
      
   
    return (
      <Stack style={{
        display: location.pathname.includes("add+a+space") ? "none" : "flex"
      }} className={styles.footer} color="white" direction={"column"}>
        <Stack className={styles.top} direction={dir} alignItems={!small && !xsmall ? "flex-start" : "unset"}>
          <Box className={styles.logoContainer}>
            <Link className={styles.logo} display="block" to="/" as={ReactLink}>
              <Image
                h="inherit"
                objectFit="contain"
                src={logo}
                alt="Workhopper Logo"
              />
            </Link>
          </Box>
          <>
          {dir === "column"   && 
                  <Divider />
            }

          <SimpleGrid 
          minH={"300px"}  
          alignItems={!small && !xsmall ? "flex-start" : "unset"} 
          className={`${styles.footerTopItem} ${styles.pages}`} 
          columns={xsmall ? 1 : 4} row={xsmall ? 4 : 1}  >
            {pages.map((page) => (
              //  <VStack w="full" minH={!small && !xsmall ? "200px" : "unset"} paddingX={8} alignItems="flex-start">
                 <VStack w="full" key={page.title} paddingX={"0px"} alignItems="center">

                 {
                   page.title.toLowerCase().includes("about") ? (
                    <Link
                     //activeClassName={styles.selected}
                     // activeStyle={{
                     //   color: 'white !important'
                     // }} 
                     className="body1" to={"/#about"} as={HashLink}>
                      {(page.title)}
                    </Link>
                   ) : page.title.toLowerCase().includes("contact") ? (
                    <Link
                    className="body1" to={routes.contact.path} as={ReactLink}>
                     {(page.title)}
                   </Link>
                   ) : (
                    <Text 
                    className="body1"
                   >{(page.title)}</Text>
                   )
                 }
                  
                   <VStack  alignItems="flex-start">
                     {page.subPages?.map(subPage => (
                       <VStack w="full" key={subPage.title}>
                          <Link
                          activeClassName={styles.selected}
                           // activeStyle={{
                           //   color: 'white !important'
                           // }} 
                           className="body2" to={subPage.url} as={ReactLink}>
                            {t(subPage.title.toLowerCase().replaceAll(' ',''))}
                          </Link>
                       {/* {
                         page.title.toLowerCase().includes("search") ? (
                           <Text className="body2" >{subPage.title}</Text>
                         ) : (
                          <Link
                          activeClassName={styles.selected}
                           
                           className="body2" to={subPage.url} as={ReactLink}>
                            {subPage.title}s
                          </Link>
                         )
                       } */}
                     
                       </VStack>
                      ))
                       
                     }
                   </VStack>
               </VStack>
                // <Link
                //   key={page.title}
                //   //textDecoration="underline"
                //   to={page.route}
                //   as={ReactLink}
                // >
                //   <Text 
                //    >{page.title}</Text>
                // </Link>
              
            ))}

          </SimpleGrid>

          </>
          
          <>
          {/* {dir === "column" && 
                  <Divider />
                } */}
                <Box w={"128px"} h={6}></Box>
          {/* <SimpleGrid
            alignItems={small && xsmall ? "center" : "flex-start"}
            className={`${styles.socials} ${styles.footerTopItem}`}
            columns={4}
            row={1}
          >
            {socialLinkViewItems.map((item, idx) => (
              <Link
              key={idx}

                boxSize={6}
                color="white !important"
                as={ReactLink}
                to={item.link}
              >
                {item.icon}
              </Link>
            ))}
          </SimpleGrid> */}
          </>
        </Stack>
        <Box>
        {dir === "row"   && 
                  <Divider />
                }
        <Stack className={styles.bottom} direction={dir}>
          <>
           
          {dir === "column"   && 
                  <Divider />
                }
          <Link   className={styles.footerTopItem + " " + "caption"} 
          textDecorationLine="underline"
          
           to={routes.privacyPolicy.path} as={ReactLink}>
            {t("Privacy Policy")}
            {/* <Text >Privacy Policy</Text> */}
          </Link>
          </>
          <>
           
          {dir === "column"   && 
                  <Divider />
                }
          <Link   className={styles.footerTopItem + " " + "caption"} 
          textDecorationLine="underline"
          
           to={routes.termsConditions.path} as={ReactLink}>
            {t("Terms and Conditions")}
            {/* <Text >Privacy Policy</Text> */}
          </Link>
          </>
          <>
          {dir === "column"   && 
                  <Divider />
                }
          <Stack spacing={4} direction={dir}> 
          {/* <Link   className={styles.footerTopItem + " " + "caption"} 
          textDecorationLine="underline"
          
           to="">
            English (NG)
           </Link> */}
          <Popover>
            <PopoverTrigger>
                <Link   className={styles.footerTopItem + " " + "caption"} 
              textDecorationLine="underline"
              
              to="">
                  {currencyConverter.target.code}
              </Link>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
               
              <PopoverBody>
                 <VStack spacing={2} p={2}>
                    {
                      currencyConverter.currencies.map(currencyItem => (
                        <VStack
                          key={currencyItem.code} 
                          onClick={(e)=>{
                            //console.dir(e)
                            onTargetCurrencySelected(currencyItem)
                          }}
                          p={2} border={currencyItem.code === currencyConverter.target.code ? "2px solid #eee" : "unset"} spacing={0} alignItems="flex-start" w="full" borderRadius={"md"}>
                        <Text className="body2" color="black">{currencyItem.title}</Text>
                        <Text className="subtitle1" color="black">{currencyItem.symbol}-{currencyItem.code}</Text>
                       </VStack>
                      ))
                    }
                   
                     
                 </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          
          </Stack>
          </>
          <>
          {dir === "column" && 
                  <Divider />
                }
          <Box className={styles.footerTopItem}   as="section">
            <Text  className="caption">{t("All rights reserved")} {new Date().getFullYear()}</Text>
          </Box>
          </>
        </Stack>
        </Box>
      </Stack>
    );
}

export default WHFooter

import {  HStack, Image, VStack, Text, Spacer, Circle, Skeleton } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdChevronRight, MdSettingsOverscan, MdStar } from 'react-icons/md'

import wkimg from "../../../Assets/workspace4.jpg"
import { CityCount } from '../../../Models/Interfaces'
import {  BreakPoint } from '../../Screens/WHApp/OfficeDetail/Models/Interfaces'
import placeholderImg from "../../../Assets/banner-icon.png"

interface TopCityCardProps {
    breakpoint: BreakPoint
    item: CityCount
}

const TopCityCard = ({breakpoint, item}: TopCityCardProps) => {

    let rating = 4
    const [isHover, setIsHover] = useState(false)

    return (
        <VStack 
                shadow={isHover ? "md" : "unset"}
                onMouseOver={()=>{setIsHover(true)}}
                onMouseOut={()=>{setIsHover(false)}}
                position="relative" 
                spacing="0px" rounded="xl" h="full" w="full">
            
            <Image 
                fallbackSrc={placeholderImg}
            //    fallback={
            //     <Skeleton
            //        rounded="xl"
            //        w="full" 
            //        h="65%"
            //     /> 
            //   }
               borderTopLeftRadius="xl"
               borderTopRightRadius="xl"
               backgroundColor="#eee"
               w="full"
                objectFit="cover" src={item.image} height="65%" />
            <VStack
               p={breakpoint === BreakPoint.S || breakpoint === BreakPoint.XS ? "8px" : "16px"}
               bg={isHover ? "white" : "#FCE7ED"}
               spacing={1}
               borderBottomLeftRadius="xl"
               borderBottomRightRadius="xl"
               h="40%"
              // height="35%"
             w="inherit" >
            <HStack  w="full" justifyContent="flex-start">
                <VStack color="#212121" spacing="0px" alignItems="flex-start">
                    <Text className="subtitle2" fontWeight="10px">{item.count} Spaces</Text>
                    <Text noOfLines={1} className="h6">{item.city}</Text>
                </VStack>
                <Spacer/>
                <Circle 
                 bg={isHover ? "red" : "white"}
                width={breakpoint === BreakPoint.S ? "16px" : "24px"}
                       height={breakpoint === BreakPoint.S ? "16px" : "24px"} >
                   <MdChevronRight color={isHover ? "white" : "red"} />
                </Circle>
            </HStack>
             <HStack color="#212121" className="caption" w="inherit" justifyContent="flex-start" fontSize="8px">
                        <Text noOfLines={1}>Private Offices</Text>
                        <Text noOfLines={1}>Meeting Rooms</Text>
                        <Text noOfLines={1}>Shared Offices</Text>
                        <Text noOfLines={1}>Virtual Offices</Text>
                    </HStack>  
            </VStack>
        </VStack>
    )
}

export default TopCityCard

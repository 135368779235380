import { VStack, Text, HStack, Divider, Spacer } from '@chakra-ui/react'
import format from 'date-fns/format'
import React from 'react'
import { DurationCategory } from '../../../Models/Interfaces'
import { getDurationTitle } from '../../../utils/utils'
import { getEnddate } from '../../Screens/WHApp/ConfirmPay/BookingFormView'
import { getDurationLabel } from '../../Screens/WHApp/ConfirmPay/ConfirmPay'

interface ItemPriceDetailProps {
    date: string 
    duration: number 
    amount: string 
    durationType: DurationCategory
    endDate?: string
    currency?: string
}

const ItemPriceDetail = (props: ItemPriceDetailProps) => {
    
    const formatToken = props.durationType === DurationCategory.HOUR ? "PPpp" : "PP"


    return (
        <VStack alignItems="flex-start" w="full">
            <Text 
                className="body2"
               //className="h6" 
               //fontSize="16px !important"
               >
                   Price Details</Text>
            {/* <VStack alignItems="flex-start" w="inherit">
                <Text className="body1">Guests</Text>
                <Text className="body2">{selected.bookingDetail.guests}</Text>
                <Divider />
            </VStack> */}
            <VStack alignItems="flex-start" w="inherit">
                <Text className="body1" >Duration Type</Text>
                <HStack  w="inherit">
                <Text className="body2">{props.durationType}</Text>
                     <Spacer />
                 </HStack>               
            </VStack>
            <VStack alignItems="flex-start" w="inherit">
                <Text className="body1" >Duration in Office</Text>
                <HStack  w="inherit">
                <Text className="body2">{props.duration}</Text>
                     <Spacer />
                 </HStack>               
            </VStack>
            {
                props.endDate ? (
                    <VStack alignItems="flex-start" w="inherit">
               
                    <Text className="body1">Check-In - Check-Out</Text>
                    <HStack>
                       <Text className="body2">{format(new Date(props.date), formatToken)}</Text>
     
                        <Text className="body2"> - </Text>
                        <Text className="body2">{format(new Date(props.endDate), formatToken)}</Text>
                    </HStack>
                    <Divider />
                </VStack>
                ):null
            }
           
            {/* <VStack alignItems="flex-start" w="inherit">
                <Text className="body1" >Price To Pay</Text>
                <HStack  w="inherit">
                <Text className="body2">&#36; {props.amount} / {getDurationTitle(props.durationType)} x  {props.duration} {getDurationLabel(props.durationType)}</Text>

                     <Spacer />
                 </HStack>
                
            </VStack> */}
         </VStack>
    
    )
}

export default ItemPriceDetail

import {
  HStack,
  Image,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  PopoverCloseButton,
  PopoverHeader,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useState } from "react";
import {
  Currency,
  ResultType,
  WorkhopperSupportedLanguage,
} from "../../../Models/Interfaces";
import { WorkhopperPublicAPIManager } from "../../../Models/WorkhopperPublicAPIManager";
import {
  CurrencyConverterContext,
  SetCurrencyConverterContext,
} from "../../AppProvider/CurrencyConverterProvider";
import i8next from "i18next";
//import { workhopperSupportLngs } from '../../../utils/utils'
import cookies from "js-cookie";
import {
  LanguageObjContext,
  SetLanguageObjContext,
} from "../../AppProvider/LanguageProvider";
import {useTranslation} from 'react-i18next';

const WHSiteActionButtons = () => {
  const currencyConverter = useContext(CurrencyConverterContext);
  const setCurrencyConverter = useContext(SetCurrencyConverterContext);
  const langObj = useContext(LanguageObjContext);
  const setLangObj = useContext(SetLanguageObjContext);
  const {t} = useTranslation();

  //const [selectedLng, setSelectedLng] = useState<string>(workhopperSupportLngs[0].code)

  const fetchExchangeRate = useCallback(
    async (base: string, target: string) => {
      let result = await WorkhopperPublicAPIManager.shared.getExchangeRate(
        base,
        target
      );
      if (result.type === ResultType.Success) {
        const newTarget = currencyConverter.currencies.find(
          (item) => item.code === target
        );
        setCurrencyConverter({
          ...currencyConverter,
          target: newTarget!,
          rate: result.value.rate,
        });
      } else {
      }
    },
    []
  );

  const onTargetCurrencySelected = useCallback((selected: Currency) => {
    fetchExchangeRate(currencyConverter.base.code, selected.code);
  }, []);

  const getImagePath = (code: string): string => {
    return code === "fr"
      ? "../../images/fr-flag.gif"
      : "../../images/us-flag.gif";
  };

  //console.dir(currencyConverter)

  return (
    <HStack h={6}>
      <Popover>
        <PopoverTrigger>
          <IconButton
            w={12}
            h={"full"}
            rounded={4}
            colorScheme="primary"
            variant="outline"
            aria-label="download workhopper"
            icon={
              <HStack
                h={"full"}
                p={2}
                justifyContent="center"
                spacing={"2px"}
                w={"full"}
              >
                <Text className="caption">
                  {currencyConverter.target.symbol}
                </Text>
                <Text className="caption">{currencyConverter.target.code}</Text>
              </HStack>
            }
          />
        </PopoverTrigger>
        <PopoverContent color="#212121">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader color={"primary.500"} className="subtitle1">
            {t("Choose a currency for browsing")}
          </PopoverHeader>
          <PopoverBody>
            <VStack spacing={2} p={2}>
              {currencyConverter.currencies.map((currencyItem) => (
                <VStack
                  key={currencyItem.code}
                  onClick={(e) => {
                    onTargetCurrencySelected(currencyItem);
                  }}
                  p={2}
                  border={
                    currencyItem.code === currencyConverter.target.code
                      ? "2px solid #eee"
                      : "unset"
                  }
                  spacing={0}
                  alignItems="flex-start"
                  w="full"
                  borderRadius={"md"}
                >
                  <Text className="body2" color="black">
                    {currencyItem.title}
                  </Text>
                  <Text className="subtitle1" color="black">
                    {currencyItem.symbol}-{currencyItem.code}
                  </Text>
                </VStack>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger>
          <IconButton
            w={14}
            h={"full"}
            //  w={10}
            colorScheme="primary"
            variant="outline"
            aria-label="select language"
            rounded={4}
            icon={
              <HStack
                h={"full"}
                paddingY={1}
                paddingX={2}
                justifyContent="center"
                spacing={"4px"}
                w={"full"}
              >
                <Text color="primary.500" className="caption">
                  {langObj.selected.toUpperCase()}
                </Text>
                <Image
                  w="60%"
                  h="full"
                  rounded={1}
                  src={getImagePath(langObj.selected)}
                />
                {/* <Text className="caption">{currencyConverter.target.code}</Text> */}
              </HStack>
              // <Image
              //   w="full"
              //   h="full"
              //   rounded={4}
              //   src={getImagePath(langObj.selected)}
              // />
            }
          />
        </PopoverTrigger>
        <PopoverContent color="#212121">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader color={"primary.500"} className="subtitle1">
            {t("Choose a language for browsing")}
          </PopoverHeader>
          <PopoverBody>
            <VStack spacing={2} p={2}>
              {langObj.lngs.map(({ code, name }) => (
                <VStack
                  key={code}
                  onClick={(e) => {
                    setLangObj({ ...langObj, selected: code });
                  }}
                  p={2}
                  border={
                    langObj.selected === code ? "2px solid #eee" : "unset"
                  }
                  spacing={0}
                  alignItems="flex-start"
                  w="full"
                  borderRadius={"md"}
                >
                  <HStack>
                    <Image w={10} h={6} rounded={2} src={getImagePath(code)} />
                    <Text className="subtitle2" color="black">
                      {name[0].toUpperCase() + name.slice(1)}
                    </Text>

                    {/* <Text className="body2" color="black">{code.toUpperCase()}</Text> */}
                  </HStack>
                  {/* <Text className="subtitle1" color="black">{currencyItem.symbol}-{currencyItem.code}</Text> */}
                </VStack>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default WHSiteActionButtons;

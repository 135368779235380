import { VStack, Text, Button, Image, AspectRatio, Spacer } from '@chakra-ui/react'
import React from 'react'
import { MdArrowForward } from 'react-icons/md'
import { BreakPoint } from '../OfficeDetail/Models/Interfaces'

import styles from './WHHome.module.css'
import { useTranslation  } from "react-i18next";


interface WHDownloadAppProps {
    breakpoint: BreakPoint
}

const WHDownloadApp = ({breakpoint}: WHDownloadAppProps) => {
    const {t} = useTranslation()
    let titleFontsize = "20px"
    let descFontsize = "14px"
    let subtitleFontsize = "18px"
    let buttonWidth = "70%"
    let width = "full"

    let padding = 4

    if (breakpoint === BreakPoint.M) {
        width = "50%"
        padding = 8
        buttonWidth = "25%"
    }
    if (breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL) {
        width = "50%"
        titleFontsize = "57px"
        descFontsize = "25px"
        subtitleFontsize = "34px"
        padding = 8
        buttonWidth = "25%"

    }

    return (
        <VStack
           spacing={8}
            
        >
            <VStack textAlign="center" justifyContent="center">
            <Text
                className={styles.title}
            >
                {t("freedom")}
                {/* Freedom At Your Fingertips */}
            </Text>
            <Text
                //fontFamily="Inter" 
                className={styles.title}
                fontWeight="600" fontSize={subtitleFontsize}
            >
                {t("withworkhopper")}
                {/* With WorkHopper, you’ll get the widest choice of workspace in one place */}
            </Text>
            <Text
                 className={styles.subtitle}
            >
             {t("justopen")}
            {/* Just open the app to find the perfect match for how and where you want to work today. */}
            </Text>
            </VStack>
           
            <AspectRatio id="download" w="100%" ratio={16 / 9}>
                 <Image src={"../images/downloadApp.png"}/>
            </AspectRatio>
            <Button 
             as="a"
             href="https://play.google.com/store/apps/details?id=com.workhopper.hopper"
             target="_blank"           
 w={buttonWidth} rounded="3xl" colorScheme="secondary">
                <Text className="body2">
                {t("download")}
                    {/* Download the app  */}
                </Text>
                <Spacer/>
                <MdArrowForward />
            </Button>
        </VStack>

    )
}

export default WHDownloadApp

import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
 import React from 'react'
import { WHAlertProps } from '../../../Models/Interfaces'
//import {Fo} from '@chakra/utils'





const WHAlert = ({title, msg, rightButton, 
                   leftButton, isOpen, onClose}: WHAlertProps) => {

    const cancelRef = React.useRef<HTMLButtonElement>(null)

    return (
        <>
      {/* <Button onClick={onOpen}>Discard</Button> */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           {msg}
          </AlertDialogBody>
          <AlertDialogFooter>
            {
              leftButton ? (
                <Button colorScheme="primary" ref={cancelRef} onClick={()=>{
                  leftButton.action()
                  onClose()
              }
                   
              }>
                {leftButton.label}
              </Button>
              ):null
            }
            {
              rightButton ? (
                <Button colorScheme="secondary"
                onClick={()=>{
                    rightButton.action()
                    onClose()
                }}
                ml={3}
                >
          {rightButton.label}
        </Button>
              ):null
            }
           
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
    )
}

export default WHAlert

import {
  Divider,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import AddASpaceLayout from "../../../SupportingViews/AddASpaceLayout/AddASpaceLayout";
import {
  DashboardSectionName,
  LocationFormViewProps,
} from "../Models/Interfaces";
import countryItems from "../../../../../node_modules/world_countries_lists/data/en/countries.json";

import styles from "../WHWorkspaceDetail/WHWorkspaceDetail.module.css";
import {
  CountriesContext,
  SetErrorContext,
  UserContext,
} from "../../../AppProvider/AppProvider";
import { dashboardManager } from "../Models/WHDashboardDataManager";
import { CityCount, Country, ResultType, StateCount, WorkspaceLocation } from "../../../../Models/Interfaces";
import { SetWorkspaceContext, WorkspaceContext } from "../WHManageWorkspaces/ManageWorkspacesProvider";
import { debug } from "console";
import { yupLocationValidation } from "../Models/Schema/DescriptionValSchema";
import { appDataManager } from "../../WHApp/Models/WHAppDataManager";
import { signUpDataManager } from "../../WHApp/SignUp/SignUpDataManager";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import {t} from 'i18next'



const LocationFormView = ({
  formRef,
  manager,
  updateFormSubmissionStatus,
  actionAfterSubmit,
}: LocationFormViewProps) => {
  const fieldHeight = 10;
  const [countryCode, setCountryCode] = useState("234");
  //const countryItems = useContext(CountriesContext);
  const [values, setValues] = useState({
    street: "",
    zipCode: "",
    city: "",
    countryRegion: "",
    state: ""
  });
  const [countryItems, setCountryItems] = useState<Country[]>([])

  const countryItemViews = countryItems.map((item, idx) => (
    <option key={item.id}>{item.name}</option>
  ));
 // countryItemViews.unshift(<option key={"empty"}>{""}</option>);

  const partner = useContext(UserContext);
  const setErrorContext = useContext(SetErrorContext);
  const setWorkspace = useContext(SetWorkspaceContext);
  const [id, setId] = useState<string>("");
  const workspace = useContext(WorkspaceContext)
  const [country, setCountry] = useState<string>("")
  const [city, setCity] = useState<string>("")
  //const [countryState, setCountryState] = useState<string>("")
  const [states, setStates] = useState<StateCount[]>([])
 
  const [cities, setCities] = useState<CityCount[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)



  const handleCountryChange = async (event: React.ChangeEvent<HTMLSelectElement>)=>{
    if (!event.target.value) { return }
       //console.log(event)
      let country = event.target.value
       let entryIdx = countryItems.findIndex((item, _) =>
      item.name.toLowerCase().includes(country.toLowerCase())
    );
      
     if (entryIdx < 0) return

    loadStates(countryItems[entryIdx].code)

      // let result = await appDataManager.getListingCount<StateCount>({name: "country", value: countryItems[entryIdx].code})
      // if (result.type === ResultType.Success) {
      //   setStates(result.value)
      // }
    
   
  }

 const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>)=>{
  setCity(event.target.value)
}



const handleStateChange = async (event: React.ChangeEvent<HTMLSelectElement>)=>{
 
  if (!event.target.value) { return }

      let state = event.target.value
       let entryIdx = states.findIndex((item, _) =>
      item.state.toLowerCase().includes(state.toLowerCase())
    );
      
     if (entryIdx < 0) return
     
     loadCities(state)

      // let result = await appDataManager.getListingCount<CityCount>({name: "state", value: states[entryIdx].state})
      // if (result.type === ResultType.Success) {
      //   setCities(result.value)
      // }
}

const loadStates = async (countryCode: string)=>{
 // debugger
   let result = await appDataManager.getListingCount<StateCount>({name: "country_state", value: countryCode})
      if (result.type === ResultType.Success) {
        setStates(result.value)
      }

}

const loadCities = async (stateTitle: string) => {
   //debugger
   let result = await appDataManager.getListingCount<CityCount>({name: "state", value: stateTitle})
      if (result.type === ResultType.Success) {
        setCities(result.value)
      }
}

const loadData = async ()=> {
  setLoading(true)
  setError(null)
  let result = await signUpDataManager.fetchCountries()
  
  if (result.type === ResultType.Success) {
      setCountryItems(result.value)
   } else {
     setError(new Error(result.error.message))
     setCountryItems([])
     //setErrorContext({title: result.error.name, msg: result.error.message})
  }
  setLoading(false)
}

  useEffect(() => {
    
     if (!workspace) return 

     if (workspace.id) {
      setId(workspace.id)
    }

     loadData()

    

     let location = workspace.location as WorkspaceLocation

     if (!location || !location.country) return
      
     loadStates(location.country.code)
     loadCities(location.state)

     if (
    
      workspace.street  &&
      (workspace.location as WorkspaceLocation) &&
      (workspace.location as WorkspaceLocation).country
      
    ) {
      
      // let location = workspace.location as WorkspaceLocation
      setValues({
        street: workspace.street,
        zipCode: workspace.zip_code ?? "",
        countryRegion: location.country?.name ?? "",
        state: location.state,
        city: location.city

       
      });
      
 
       
    }

   
    // let id = localStorage.getItem("title");

     
  }, []);

  return (
    <>
      
      <Formik
       
        initialValues={values}
        validationSchema={yupLocationValidation}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialStatus={{ isValidating: false }}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          if (!partner || !partner.access_token || !workspace) {
            return
          }

            let countryIdx = countryItems.findIndex((item, _) =>
              item.name.includes(values.countryRegion)
            );
            
             
            let entryIdx = cities.findIndex((item, _) =>
              item.city.includes(values.city)
            );
             
             if (entryIdx < 0 || countryIdx < 0) return
              let result = await dashboardManager.updateWorkspaceSection(
              id,
              DashboardSectionName.Location,
              { ...values, city: cities[entryIdx].id },
              partner.access_token
            );
            if (result.type === ResultType.Success) {
             // console.dir(result.value)
             // dashboardManager.saveWorkspace(result.value.id!, result.value);
            
              const updatedWorkspace = {...workspace, ...workspace?.country, 
                                         id: result.value.id,
                                         street: result.value.street,
                                         zip_code: result.value.zip_code,
                                         location: {id: (result.value.location as number), city: values.city, country: countryItems[countryIdx], state: values.state}
                                        }
              setWorkspace(updatedWorkspace);
              actionAfterSubmit()
              // setValues(props.values.name)
            } else {
              setErrorContext({
                title: result.error.name,
                msg: result.error.message,
              });
            }
          
        }}
        innerRef={formRef}
      >
        {(props) => {
           return (
            <Form className={styles.form}>
              <VStack alignItems="flex-start">
                <Text
                  padding={{ base: 6, lg: 8 }}
                  // paddingY={{ base: 6, lg: 8 }}
                  w={{ base: "full", md: "75%", lg: "80%" }}
                  className="h6"
                >
                  {t("Where is your space located?")}
                </Text>
                <Divider />
              </VStack>
              <AddASpaceLayout>
              <>
              {loading && <WHSpinner />}
                     {error && (
                       <VStack>
                         <Text className="subtitle2">{t(error.message)}</Text>
                         <Button colorScheme="primary" 
                          onClick={loadData}
                         >
                           {t("Try Again")}
                         </Button>
                       </VStack>
                     )}
                 {!error && countryItems.length > 0 && (
                <Field name="countryRegion">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.countryRegion && form.touched.countryRegion
                      }
                      isRequired
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="countryRegion"
                      >
                        {t("Country/Region")}
                      </FormLabel>
                      <Select
                        {...field}
                        //id="countryRegion"
                        name="countryRegion"
                        //onChange={props.handleChange}
                        onChange={(e) => {
                          //setValues({countryRegion: e.target.value, city: "", state: "", zipCode: "", street: ""})
 
                          props.setValues({countryRegion: e.target.value, city: "", state: "", zipCode: "", street: ""})
                          //props.setFieldValue("countryRegion", e.target.value);
                          handleCountryChange(e);
                        }}
                        placeholder={t("Choose a country")}
                      >
                        {countryItemViews}
                      </Select>
                      <FormErrorMessage>
                        {form.errors.countryRegion}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                 )}
                </>
                <Field name="state">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.state && form.touched.state}
                      isRequired
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="state"
                      >
                        {t("What state is it located?")}
                      </FormLabel>
                      <Select
                        {...field}
                        disabled={props.values.countryRegion ? false : true}
                       // disabled={country ? false : true}
                        //id="countryRegion"
                        name="state"
                        //onChange={props.handleChange}
                        onChange={(e) => {
                          //setValues({countryRegion: props.values.countryRegion, city: "", state: e.target.value, zipCode: "", street: ""})

                          props.setValues({countryRegion: props.values.countryRegion, city: "", state: e.target.value, zipCode: "", street: ""})
                          //props.setFieldValue("state", e.target.value);
                          handleStateChange(e);
                        }}
                        placeholder={t("Choose a State")}
                      >
                        {states.length > 0
                          ? states.map((item) => (
                              <option key={item.state}>{item.state}</option>
                            ))
                          : null}
                      </Select>
                      <FormErrorMessage>
                        {form.errors.state}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="city">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.city && form.touched.city}
                      isRequired
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="city"
                      >
                        {t("What city is it located?")}
                      </FormLabel>
                      <Select
                        {...field}
                        disabled={props.values.state ? false : true}
                        //disabled={countryState ? false : true}
                       // value={city}
                        name="city"
                        onChange={(e) => {
                          props.setValues({countryRegion: props.values.countryRegion, city: e.target.value, state: props.values.state, zipCode: "", street: ""})
                          //props.setFieldValue("city", e.target.value);
                         // handleCityChange(e);
                        }}
                        placeholder={t("Choose a city")}
                      >
                        {cities.length > 0
                          ? cities.map((item) => (
                              item.city ? (
                                <option key={item.id}>{item.city}</option>
                              ):null
                            ))
                          : null}
                      </Select>
                      <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="street">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.street && form.touched.street}
                      isRequired
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="street"
                      >
                        {t("Street")}
                      </FormLabel>
                      <Input
                        onChange={props.handleChange}
                        {...field}
                        name="street"
                        type="text"
                        placeholder=""
                      />
                      <FormErrorMessage>{form.errors.street}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
               
                <Field name="zipCode">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.zipCode && form.touched.zipCode}
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="zipCode"
                      >
                        {t("Zip Code")}
                      </FormLabel>
                      <Input
                        onChange={props.handleChange}
                        {...field}
                        name="zipCode"
                        type="text"
                        placeholder=""
                      />
                      <FormErrorMessage>{form.errors.zipCode}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <HStack justifyContent="flex-end" w="full">
                  <Button
                    isLoading={props.isSubmitting}
                    w={"20%"}
                    minW="96px"
                    className="button"
                    colorScheme="primary"
                    variant="solid"
                    onClick={() => {
                      props.handleSubmit()
                     // props.submitForm();
                    }}
                    //disabled
                  >
                    {t("Next")}
                  </Button>
                </HStack>
              </AddASpaceLayout>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LocationFormView;

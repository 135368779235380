import {
  Box,
  GridItem,
  HStack,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  RiFacebookBoxFill,
  RiInstagramFill,
  RiTwitterFill,
} from "react-icons/ri";
import coworkImg from "../../../../Assets/workspace2.jpg";
import WHLayoutGrid from "../../../SupportingViews/WHLayoutGrid/WHLayoutGrid";
import WorkspaceItemView from "../../../SupportingViews/WorkspaceItemView/WorkspaceItemView";

import meetingRoom1 from "../../../../Assets/meeting1.jpg";
import meetingRoom2 from "../../../../Assets/meeting2.jpg";
import meetingRoom3 from "../../../../Assets/meeting3.jpg";
import { BreakPoint } from "../OfficeDetail/Models/Interfaces";
import { WorkspaceDetailLink } from "../../../SupportingViews/Links/Links";
import { Result, ResultType, Workspace } from "../../../../Models/Interfaces";
import { appDataManager } from "../Models/WHAppDataManager";
import { useParams } from "react-router";
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager";
import { UserContext } from "../../../AppProvider/AppProvider";
import OfflineView from "../../../SupportingViews/OfflineView";
import { SetWorkspacesContext } from "../../../AppProvider/WHAppProvider";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import placeholderBanner from "../../../../Assets/banner-icon.png";
import WHSEO from "../../../SupportingViews/WHSEO/WHSEO";
import AdsView from "../../../SupportingViews/AdsView/AdsView";
import WHSeeMoreList from "../../../SupportingViews/WHSeeMoreList/WHSeeMoreList";
import { useTranslation  } from "react-i18next";

export interface BasicInfo {
  url: string
   socials?: string[]
  header: string
  name: string
}

const profileSummary = (info: BasicInfo) => {
  return (
    <VStack mb={4} alignItems="flex-start" spacing={{ base: "0px" }}>
      <HStack>
        <Text textTransform="capitalize" className="h6">{info.name}</Text>
        {/* {socialMediaLinks()} */}
      </HStack>
      {/* <Text className="subtitle2">
        WorkHopperURL:{" "}
        <Box className="body2">{`www.workhopper.com.ng/${info.name}/profile`}</Box>
      </Text> */}
    </VStack>
  );
};

const socialMediaLinks = () => {
  return (
    <>
      <HStack>
        <RiFacebookBoxFill fontSize="24px" />
        <RiTwitterFill fontSize="24px" />
        <RiInstagramFill fontSize="24px" />
      </HStack>
    </>
  );
};

const colspan = (breakpoint: BreakPoint): number => {
  if (breakpoint === BreakPoint.XS) {
    return 4;
  }

  if (breakpoint === BreakPoint.S) {
    return 8;
  }

  return 12;
};

const itemRowspan = (breakpoint: BreakPoint): number => {
  if (breakpoint === BreakPoint.XS) {
    return 11;
  }

  if (breakpoint === BreakPoint.S) {
    return 9;
  }

  return 12;
};

const BusinessProfile = () => {
  const user = useContext(UserContext)
  const setWorkspaces = useContext(SetWorkspacesContext)
  const partner = useParams<{ partnerId?: string }>();
  const [workspaces, setAvailableWorkspaces] = useState<Workspace[]>([]);
  const [isLoading, setisLoading] = useState(false);
  const [info, setInfo] = useState<BasicInfo | undefined>()
  // const [requestStatus, setRequestStatus] = useState<ResultType>(ResultType.Success)
  const [error, setError] = useState<Error | null>(null);
  let breakpoint: BreakPoint = BreakPoint.XS;
  const [nextURL, setNextURL] = useState<string>()



  const [xsmall, small, medium, large] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  let bizTitle = "Enterprise Hubs";

  let imgHeight = "244px";
  if (small) {
    imgHeight = "320px";
  } else if (medium || large) {
    imgHeight = "320px";
  }

  if (small) {
    breakpoint = BreakPoint.S;
  } else if (medium) {
    breakpoint = BreakPoint.M;
  } else if (large) {
    breakpoint = BreakPoint.L;
  }

  let gridline = 4
  if (small) {
    
    gridline = 8
  } else if (medium || large) {
  
  } 

  if (medium || large ) {
      gridline = 12
  } 

  

  const wkspaceItemViews = workspaces.map((item, idx) => {
    let rowspan = 9;
    let colspan = 4;

    if (breakpoint === BreakPoint.S) {
      rowspan = 7;
    } else if (breakpoint === BreakPoint.M) {
      rowspan = 6;
      colspan = 3;
    } else if (breakpoint === BreakPoint.L) {
      rowspan = 6;
      colspan = 3;
    }

    return item.id && item.header ? (
      <GridItem key={item.id} rowSpan={rowspan} colSpan={colspan}>
        <WorkspaceDetailLink to={`/workspace/${item.id}/detail`}>
          <WorkspaceItemView
            key={item.id}
            imageURL={item.header}
            title={item.title || "Untitled"}
            officeCount={item.listing_count}
          />
        </WorkspaceDetailLink>
      </GridItem>
    ): null
  });

  const loadWorkspaces = useCallback(async () => {
    if (!partner.partnerId) return;
     setisLoading(true);
     
    const temp = await WorkhopperAPIManager.shared.fetchPartnerBasicInfo(partner.partnerId)
    let basicInfoResult  = temp as Result<BasicInfo> 
    let result = await appDataManager.getPartnerWorkspaces(partner.partnerId, nextURL);
        //debugger
        if (result.type === ResultType.Success && basicInfoResult.type === ResultType.Success) {
           // setRequestStatus(ResultType.Success)
           //console.dir(result)
            setAvailableWorkspaces([...workspaces, ...result.value.results])
            setNextURL(result.value.next)
            //setWorkspaces(result.value)
            setInfo(basicInfoResult.value)
            setError(null)
            //setResult({type: result})
        } else if (result.type === ResultType.Failure) {
            //setRequestStatus(ResultType.Failure)
            setError(result.error)
            setAvailableWorkspaces([])
            setNextURL(undefined)
        } else if (basicInfoResult.type === ResultType.Failure)  {
          setError(basicInfoResult.error)
          setInfo(undefined)
          setNextURL(undefined)

        }
        setisLoading(false);

  }, [nextURL])

  useEffect(() => {
     if (partner.partnerId) {
      loadWorkspaces()
    }
  }, []);

  useEffect(() => {
    
    return () => {
      
    }
  }, [])

  const {t} = useTranslation()

  //console.dir(workspaces)

  return info && workspaces.length > 0 ? (
    <>
       <WHSEO 
            title={`${info.name} Workspace Centers`} 
            meta={[
              {
                name: "description",
                content: `${info.name} workspace centers`
              },
              {
                name: "keywords",
                content: `workHopper partner, workHopper, ${info.name}`
              }
            ]}  
          />
      <LayoutView>
      {
         // info.header ? (
            <Image
            fallbackSrc={placeholderBanner}
            // fallback={<Skeleton 
            //   w="full"
            //   height={imgHeight}
            // />}
            bg="gray.300"
            w="full"
            rounded="xl"
            height={imgHeight}
            src={info.header}
            objectFit="cover"
            aria-label={bizTitle}
            mb={{ base: 4 }}
          />
       // ) 
        // : (
        //   <Box width="full" bg="red"
        //   rounded="xl"
        //   bgGradient="linear(to-l,secondary.500, primary.500)"
        //   height={imgHeight}>
                
        //   </Box>
        // )
           
      }
      </LayoutView>
     
      {
        workspaces.length > 0 &&
        <>
        <LayoutView> 
         <VStack w="full" alignItems="flex-start">
           {profileSummary(info)}
          <Text className="h6" fontsize="18px !important">
            {t('Workspace Centers')} {`(${workspaces.length})`}</Text>
         
          <WHSeeMoreList
                        data={workspaces}
                       isLoading={isLoading}
                       loadData={()=>{loadWorkspaces()}}  
                       error={error} 
                       nextURL={nextURL} 
                       spinnerHeight="64px"    
                       useGrid 
                       isWorkspaceGrid    
                   >
                     {
                       workspaces.map(item => {
                       // return item.id && item.header ? (
                          return item.id ? (

                            <WorkspaceDetailLink to={`/workspace/${item.id}/detail`}>
                              <WorkspaceItemView
                                key={item.id}
                                imageURL={item.header ?? placeholderBanner}
                                title={item.title || "Untitled"}
                                officeCount={item.listing_count}
                              />
                            </WorkspaceDetailLink>
                          
                        ): null
                       }
                            
                       )
                     }
                      
                     
                  </WHSeeMoreList>
         </VStack>
        </LayoutView>
     
      </>
      }
       
    </>
  ) : isLoading && !nextURL ? (
     <WHSpinner />
  ) : error && !nextURL ? (
    <OfflineView pt={"64px"} msg="Workhopper seems to be offline.5" onRetry={()=>{
      
        loadWorkspaces()
    }} />
  ): null
};

export default BusinessProfile;

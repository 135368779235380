import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, theme  } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend';



import { BrowserRouter} from "react-router-dom";
import AppProvider from './Components/AppProvider/AppProvider';
import CurrencyConverterProvider from './Components/AppProvider/CurrencyConverterProvider';
import LanguageProvider, { defaultLanguage } from './Components/AppProvider/LanguageProvider';
 
 

const breakpoints = createBreakpoints({
  sm: "37.5em",
  md: "56.56em",
  lg: "77.5em",
  xl: "90em",
})


export const customTheme = extendTheme({
   ...theme,
  //  components: {
  //     Modal: {
  //       baseStyle: {
  //         dialogContainer: {
  //           "@support(height: -webkit-fill-available)" : {}
  //         }
  //       }
  //     }
  //  },
   breakpoints,
  //  breakpoints: {
  //    ...theme,
  //    breakpoints
  //  },
  colors: {
  ...theme.colors,
  primary: {
    50: '#eceffe',
    100: '#cdcfe8',
    200: '#abafd3',
    300: '#8a8ec1',
    400: '#696eae',
    500: '#262949',//'#505595',
    600: '#3d4274',
    700: '#2c2f54',
    800: '#191c34',
    900: '#080817',
  },
 
  secondary: {
    50: '#ffe3ed',
    100: '#ffb5c8',
    200: '#f986a4',
    300: '#f5567f',
    400: '#f1275a',
    500:  '#ec0f47',//'#d80e41',
    600: '#a90732',
    700: '#7a0323',
    800: '#4b0015',
    900: '#1f0006',
  },
  darkPrimary: "#000022",
  lightPrimary: "#505175",
  onPrimary: '#fff',
  darkSecondary: '#b20020',
  lightSecondary: '#ff5b72',
  onSurface: "white",
  onSecondary: '#000',
    brand: {
      primary1: "#3f51b5",
      // primary2: "#303F9F",
      primary2: "#ffc107",

      primary3: "#720D5D",
      secondary: "#ffc107"
    },
    
    shadows: {
      // ...theme.shadows,
      outline: "secondary.200"
    }
  }
})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    
    supportedLngs: defaultLanguage.lngs.map(({code})=> code),
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json"
    },
    react: {
      useSuspense: false
    },
    //lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain', 'querystring'],
      caches: ["cookie"]
    }

    
  });

//const customTheme = extendTheme({breakpoints})

//const AppContext = React.createContext({})

ReactDOM.render(
  <React.StrictMode>
      <ChakraProvider theme={customTheme}>
        <BrowserRouter>
             <AppProvider >
              <CurrencyConverterProvider>
                 <LanguageProvider > 
                  <App />
                 </LanguageProvider>
              </CurrencyConverterProvider>
            </AppProvider>
          {/* </AppContext.Provider> */}
        </BrowserRouter>
      </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

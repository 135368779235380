import { Box, VStack, Text, Button, HStack, Link, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useContext } from 'react'
import { ModalCategory, PrimaryActionsViewProps } from '../Models/Interfaces'
import {  NavLink as ReactLink, useHistory } from "react-router-dom";
import WHAlert from '../../../../SupportingViews/WHAlert/WHAlert';
import routes from '../../../../../routes';
import { CartContext, UserContext } from '../../../../AppProvider/AppProvider';
import { getDurationCategory, getDurationTitle, getLeastPrice } from '../../../../../utils/utils';
import { CartActionType, CartItem, DurationCategory } from '../../../../../Models/Interfaces';
import WHPrice from '../../../../SupportingViews/WHPrice/WHPrice';
import { useTranslation  } from "react-i18next";



const PrimaryActions = ({duration, handleModalOpen, listing}: PrimaryActionsViewProps) => {

    let leastDuration = getLeastPrice(duration)
    let history = useHistory()
    const user = useContext(UserContext) 
    const {state, dispatch} = useContext(CartContext)
    const { onClose: onAlertClose, isOpen, onOpen: onAlertOpen } = useDisclosure()
    const {t} = useTranslation()

    const itemAddedToCart = useCallback(():boolean => {
         let found = state.items.find((item)=> item.id === listing.id)
         return found ? true : false
       },
      [state.items, listing],
    )
   // debugger
    const minDuration = getLeastPrice(duration)
     const addToCart = useCallback(() => {

         
        if (!listing.id) {return }

        if (itemAddedToCart()) {return }

        const item: CartItem = {
          id: listing.id, 
          listing: listing,
          bookingDetail: {
              //durationType: minDuration.minimum_duration[0].toUpperCase() + minDuration.minimum_duration.substr(1) as DurationCategory,
              durationType: getDurationCategory(minDuration.minimum_duration),
              duration: 1,
              guests: 1,
              startDate: undefined,
              endDate: undefined

          }
        }
        
        dispatch({type: CartActionType.ADD, payload: item})
      },
      [listing],
    )

    return (
        <>
            <VStack
              alignItems="flex-start"
              w={{base: "full", md: "45%", lg: "45%", xl: "45%"}}
              paddingX={6}
              paddingY={8}
              shadow="md"
              alignSelf="flex-start"
            >
              <VStack alignItems="flex-start" spacing={"0px"}>
                  <Text className="subtitle2">
                    {t('Ranging from')}
                    {/* Ranging from */}
                    </Text>
                  <HStack>
                       {/* <Text className="h4">$</Text>
                        <Text className="h4">
                            {leastDuration.amount}
                        </Text> */}
                        <Text className="h4">
                          <WHPrice priceText={leastDuration.amount} />
                        </Text>
                         
                        <Text className="subtitle1"><Text className="h5" color="black !important" as={"span"}>/</Text>&nbsp; {getDurationTitle(leastDuration.minimum_duration)}</Text>
                  </HStack>
              </VStack>
                  {/* <Link
                  w="full"
                  as={ReactLink}
                  textDecorationLine="none !important"
                  textDecorationColor="primary.500"
                  //className="overline"
                  to={"/confirm+and+pay"}
                > */}
                  <Button
                    w="full"
                    className="button"
                    colorScheme="secondary"
                    onClick={()=>{
                      history.push("/confirm+and+pay")
                      addToCart()
                    }}
                    //onClick={()=>{handleModalOpen(ModalCategory.CARD)}}
                  >
                        {t('Book Now')}
                    {/* Book Now */}
                    </Button>
                {/* </Link> */}
               
              <Button
                w="full"
                className="button"
                colorScheme="secondary"
                variant="outline"
                onClick={()=>{
                  handleModalOpen(ModalCategory.ENQUIRY)
                  // if (user && user.access_token) {
                  //   handleModalOpen(ModalCategory.ENQUIRY)
                  // } else {
                  //   onAlertOpen()
                  //   //setIsAlertOpen(true)
                  // }
                }}

              >
                 {t('enquire Now')}
                {/* enquire Now */}
                </Button>
               <Button
                   disabled={itemAddedToCart()}
                    w="full"
                    className="button"
                    colorScheme="primary"
                    onClick={()=>{
                      
                      addToCart()
                    }
                       
                    }
                  >{itemAddedToCart() ? t("Added To Cart"): t("Added To Cart")}</Button>
            </VStack>
            <WHAlert 
              title="Make Enquiry"
              msg="Please log in to make enquiry. If you dont have an account, sign up!"
              leftButton={{
                label: "Sign Up",
                action: ()=> {
                  history.push(`/${routes.signup.name}`)
                }
              }}
              rightButton={{
                label: "Log In",
                action: ()=> {
                  history.push(`/${routes.login.name}`)
                }
              }}
              isOpen={isOpen}
              onClose={onAlertClose}
            />
        </>
    )
}

export default PrimaryActions

import React, { useContext, useEffect } from "react";

import {
  Box,
  Divider,
  Flex,
  Spacer,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { Switch, Route, useLocation } from "react-router";
import { Redirect } from "react-router-dom";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import Login from "./Login/Login";
import WHNavbar from "../WHNavbar/WHNavbar";
import WHDrawer from "../WHDrawer/WHDrawer";
import RegisterView from "../../SupportingViews/NewsletterView/NewsletterView";
import SignUp from "./SignUp/SignUp";
//import VirtualRooms from '../VirtualRooms/VirtualRooms';
import AddASpace from "./AddASpace/AddASpace";
//import Dashboard from '../Dashboard/Dashboard';
import routes from "../../../routes";
import ConfirmPayRoute from "./ConfirmPayRoute";
import ConfirmPay from "./ConfirmPay/ConfirmPay";
import OfficeDetail from "./OfficeDetail/OfficeDetail";
import BusinessProfile from "./BusinessProfile/BusinessProfile";
import SearchResults from "./SearchResuts/SearchResults";
import WorkspaceDetail from "./WorkspaceDetail/WorkspaceDetail";
import CityOfficesView from "./CityOfficesView/CityOfficesView";
import { signUpDataManager } from "./SignUp/SignUpDataManager";
import { SetUserContext, UserContext } from "../../AppProvider/AppProvider";
//import NewHome from '../NewHome/NewHome';
import OfficeItemsListUpdated from "../../SupportingViews/OfficeItemsList/OfficeItemsListUpdated";
import { publicListingDefaultValue } from "../../../Models/DefaultValues";
import OfficeCategory from "./OfficeCategory/OfficeCategory";
import { BreakPoint } from "./OfficeDetail/Models/Interfaces";
import GlobalSearch from "../../SupportingViews/GlobalSearch/GlobalSearch";
import Settings from "./Settings/Settings";
import BookingHistory from "./BookingHistory/BookingHistory";
import Cart from "./Cart/OfficesCart";
import OfficesCart from "./Cart/OfficesCart";
import ProtectedRoute from "../../SupportingViews/ProtectedRoute/ProtectedRoute";
import PayBooking from "./PayBooking/PayBooking";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./TermsConditions/TermsConditions";
import Contact from "./Contact/Contact";
import ClaimListing from "./ClaimListing/ClaimListing";
import WHHome from "./WHHome/WHHome";
import WH404 from "../../SupportingViews/WH404/WH404";

const WHApp = () => {
  let registeredUser = useContext(UserContext);
  let setUserContext = useContext(SetUserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [isMediumScrn, isLargeScrn] = useMediaQuery([
    "(min-width: 30em)",
    "(min-width: 62em)",
  ]);
  const [mobile] = useMediaQuery(["(max-width: 599px)"]);

  const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  const location = useLocation();

  let pt = "";
  if (xsmall) {
    pt = "56px";
  } else if (small || medium) {
    pt = "64px";
  } else {
    pt = "72px";
  }

  useEffect(() => {
    if (!registeredUser) {
      let registeredUser = signUpDataManager.getRegisteredUserData();
      setUserContext(registeredUser);
    }
  }, []);
  return (
    <Box pt={pt}>
      <WHNavbar onSearchModalOpen={onModalOpen} onOpen={onOpen} />
      <WHDrawer onClose={onClose} isOpen={isOpen} />
       <Switch>
        <Route
          exact
          render={() => <WHHome onSearchModalOpen={onModalOpen} />}
          path={routes.home.path}
        />

         <ProtectedRoute path={routes.addASpace.path}>
          <AddASpace />
        </ProtectedRoute>
 
        <Box paddingY={xsmall ? 4 : 8}>
          <ProtectedRoute path={routes.signup.path}>
            <SignUp />
          </ProtectedRoute>
          <ProtectedRoute path={routes.login.path}>
            <Login />
          </ProtectedRoute>

          <Route
            exact
            component={OfficeCategory}
            path={routes.officeCategory.path}
          />
          {/* <Route exact component={SearchResults} path={routes.search.path}/> */}
          <Route
            exact
            render={() => <SearchResults key={location.key} />}
            path={routes.search.path}
          />
 
          <Route
            exact
            render={() => <OfficeDetail />}
            path={routes.officeDetail.path}
          />
          <Route
            exact
            component={WorkspaceDetail}
            path={routes.workspaceDetail.path}
          />
          <Route
            exact
            component={BusinessProfile}
            path={routes.bizProfile.path}
          />
          <Route exact component={Settings} path={routes.settings.path} />
          <Route
            exact
            component={CityOfficesView}
            path={`${routes.cityOffices.path}/:city`}
          />
          <Route
            exact
            component={BookingHistory}
            path={`${routes.bookingHistory.path}`}
          />
          <Route
            exact
            render={() => <PayBooking />}
            path={`${routes.payBooking.path}`}
          />
          <Route exact component={OfficesCart} path={routes.confirmPay.path} />
          <Route
            exact
            component={PrivacyPolicy}
            path={routes.privacyPolicy.path}
          />
          <Route
            exact
            component={TermsConditions}
            path={routes.termsConditions.path}
          />
          <Route exact component={Contact} path={routes.contact.path} />
          <Route
            exact
            render={() => <ClaimListing />}
            path={routes.claimListing.path}
          />
           {/* <Route
            path="*"
            render={() => (
              <WH404 />
            )}
          /> */}
        </Box>
       
        {/* <Route path="*">
                <Redirect to={`/`} />
          </Route> */}
      </Switch>
      <Spacer />
       <Modal
        isCentered
        size={mobile ? "md" : "6xl"}
        motionPreset={mobile ? "slideInBottom" : "slideInRight"}
        isOpen={isModalOpen}
        onClose={onModalClose}
      >
        <ModalOverlay />
        <ModalContent
          position={mobile ? "fixed" : "absolute"}
          bottom={mobile ? "0px" : "unset"}
          mb={mobile ? "0" : "unset"}
          borderRadius={mobile ? "1.75rem 1.75rem 0px 0px" : "initial"}
          rounded="20px"
          width={mobile ? "full" : "95%"}
        >
          <ModalCloseButton paddingX={mobile ? 6 : 16} color="primary.500" />
          <GlobalSearch onSearchBegin={onModalClose} openedByHeader={true} />
        </ModalContent>
      </Modal>
       
    </Box>
  );
};

export default WHApp;

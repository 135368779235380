import React, { useEffect, useState, useContext } from 'react';
import { Adpage, Advert, CityCount, CompanyInfo, RecommendedItem, ResultType } from '../../../../Models/Interfaces';
import { WorkhopperPublicAPIManager } from '../../../../Models/WorkhopperPublicAPIManager';
//import { RecommendedItem } from '../../MeetingRooms/Model/RecommendedItem';
import { appDataManager } from '../Models/WHAppDataManager';
import WHHomeMobile from './WHHomeMobile';

import { data, ReasonItem } from './ReasonData'
import comfort1 from "../../../../Assets/comfort1.jpg"
import comfort2 from "../../../../Assets/comfort2.jpg"
import comfort3 from "../../../../Assets/comfort3.jpg"
import comfort4 from "../../../../Assets/comfort4.jpg"
import { BreakPoint } from '../OfficeDetail/Models/Interfaces';
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView';
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner';
import { Box, useMediaQuery, VStack, Text, Button } from '@chakra-ui/react';
import WHOffline from '../../../SupportingViews/WHOffline/WHOffline';
import OfflineView from '../../../SupportingViews/OfflineView';
import WHHomeDesktop from './WHHomeDesktop';
import HeaderCarousel from '../../../SupportingViews/HeaderCarousel/HeaderCarousel';
import { useTranslation  } from "react-i18next";

import styles from "./WHHome.module.css"
import { LanguageObjContext } from '../../../AppProvider/LanguageProvider';
import WhatsAppWidget from 'react-whatsapp-chat-widget';

interface ComfortItem {
  imageURL: string 
  id: string
  adURL?: string
}
export interface WHHomeProps {
   reasonItems: ReasonItem[] 
   comfortItems: ComfortItem[]
   breakpoint: BreakPoint
   homedata: Homedata
} 

export interface WHHomeScreenProps {
  onSearchModalOpen?: ()=>void
}

export interface Homedata {
  topcities: CityCount[]
  slideshows: RecommendedItem[]
  adverts: Advert[]
  partners: CompanyInfo[]
}

const WHHome = (props: WHHomeScreenProps) => {

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [homedata, setHomedata] = useState<Homedata>({topcities: [], slideshows: [], adverts: [], partners: []})
  const {t} = useTranslation()
  const [lookingText, setLookingText] = useState("Looking for a")
  const [workspaceText, setWorkspaceText] = useState("workspace")
  const [counter, setCounter] = useState(0)
  //const languageObj = useContext(LanguageObjContext)
  const translatedText = t("looking")
  const highlightedIndex = 0

  useEffect(()=>{
    
    let workspaceTxt = "espace"
    const indexOfTextToHighlight = translatedText.indexOf(workspaceTxt)
    if (indexOfTextToHighlight > -1) {
        let result = translatedText.split(workspaceTxt)
        //console.log("trans", result)
        setLookingText(result[0])
        setWorkspaceText(workspaceTxt + result[1])

    } else {
      workspaceTxt = "workspace"
      let result = translatedText.split(workspaceTxt)
        //console.dir(result)
      setLookingText(result[0])
      setWorkspaceText(workspaceTxt + result[1])
    }
  }, [translatedText])

  let  items: ComfortItem[] = [
    { imageURL: comfort1, id: "1" },
    { imageURL: comfort2, id: "2" },
    { imageURL: comfort3, id: "3" },
    { imageURL: comfort4, id: "4" }
   ];

   const [comfortableSpacesItems, setComfortableSpacesItems] = useState(items)
    
   const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);
  
  let breakpoint: BreakPoint = BreakPoint.XS;
  let btnWidth = "40%"

  

  if (small) {
    breakpoint = BreakPoint.S;
    btnWidth = "40%"
  } else if (medium) {
    breakpoint = BreakPoint.M;
    btnWidth = "20%"
  } else if (large) {
    breakpoint = BreakPoint.L;
    btnWidth = "200px"
  } else if (xl) {
    btnWidth = "200px"
    breakpoint = BreakPoint.XL;
  }
  
  useEffect(()=>{

      (async ()=> {
       // debugger
         setIsLoading(true)
        let temp1: CityCount[] = []
        let temp2: RecommendedItem[] = []
        let temp3: Advert[] = []
        let temp4: CompanyInfo[] = []

        let topCitiesResult = await appDataManager.getListingCount<CityCount>({
          name: "country",
          value: "NGN"
        });

        if (topCitiesResult.type === ResultType.Failure) {
          setIsLoading(false)
          setError(topCitiesResult.error)
        } else {
          temp1 = topCitiesResult.value.filter(item => item.count > 0).slice(0, 4)
          //setHomedata({...homedata, topcities: topCitiesResult.value.filter(item => item.count > 0).slice(0, 4)});
        }

        let slideshowsResult = await WorkhopperPublicAPIManager.shared.getSlideshows()
        if (slideshowsResult.type === ResultType.Failure) {
          setIsLoading(false)
          setError(slideshowsResult.error)
        } else {
          const items = slideshowsResult.value.map( (item):RecommendedItem => (
            {
              imageURL: item.image,
              id: item.id
            }
          ) ) 
          temp2 = items.slice() 
          //setHomedata({...homedata,  slideshows: items});
        }

        const advertsResult = await WorkhopperPublicAPIManager.shared.getAdverts(Adpage.HOMEPAGE)

        console.log("advertsresult", advertsResult);

        if (advertsResult.type === ResultType.Failure) {
          setIsLoading(false)
          setError(advertsResult.error)
        } else {
            temp3 = advertsResult.value.slice()

           console.log("slice",temp3[0])
        }

        if (temp3.length > 0) {
            setComfortableSpacesItems([...comfortableSpacesItems.slice(0, 4), {imageURL: temp3[0].image, id: temp3[0].id.toString(), adURL: temp3[0].url}])
 
        }

        const companyListResult = await WorkhopperPublicAPIManager.shared.getCompanyList()
        if (companyListResult.type === ResultType.Failure) {
          setIsLoading(false)
          setError(companyListResult.error)
        } else {
            temp4 = companyListResult.value.slice()
           
        }
        
        setHomedata({...homedata, topcities: temp1, slideshows: temp2, adverts: temp3, partners: temp4})
        setIsLoading(false)
        setError(undefined)

        //const topCities = topCitiesResult.value as ResultType.Success

      })()
  }, [counter])

    //console.log(t("looking"))

    return (
     <>
       {isLoading ? (
         <WHSpinner pt="64px" />
       ): error ? (
         <VStack w="full" h="100vh">
              <OfflineView 
          
          onRetry={()=>{
            setCounter((prev)=> prev + 1)
          }} 
            msg={error.message}
            pt="64px"
          />
         </VStack>
         
       ): (
         <VStack w="full">
             {/* <WhatsAppWidget
			phoneNo="2348122244752"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt=""
			iconSize="40"
			iconColor="white"
			iconBgColor="#262949"
      headerIcon=""
      //headerIcon={icon}
			//headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
			headerIconColor="white"
			headerTxtColor="white"
			headerBgColor="#262949"
			headerTitle="WorkHopper"
			headerCaption="Online"
			bodyBgColor="#262949"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#fff"
			btnBgColor="#262949"
			btnTxtColor="white"
			btnTxt="Start Chat"
		/> */}
             <Box position="relative" w="full">
                  <HeaderCarousel breakpoint={breakpoint} items={homedata.slideshows} />
                  <VStack  w="full" justifyContent="center" h="full" position="absolute" top="0px"  left="0px">
                <Text color="white" zIndex={15} className={`${styles.title} ${styles.carouselHeaderText}`}>
                  {/* {t("looking")} */}
                  {lookingText} <Box as="span" color="secondary.500">{workspaceText}</Box>
                </Text>
                <Button 
                zIndex={15}
                onClick={props.onSearchModalOpen}
                 className={`homePage-search ${styles.searchBtn}`} 
                w={btnWidth}
                //w={{base: "40%", md: "40%", lg: "18%"}}
                h={{base: "40px", md: "40px", lg: "60px"}}
                color="secondary.500" bg="white" borderRadius="50px">
                  {t("search")}
                  {/* Search */}
                </Button>
              </VStack>
             </Box>
              {
                 breakpoint === BreakPoint.XS ? (
                  <WHHomeMobile homedata={homedata} breakpoint={breakpoint} 
                    reasonItems={data}
                    comfortItems={comfortableSpacesItems}
                  />
                 ): (
                  <WHHomeDesktop homedata={homedata} breakpoint={breakpoint} 
                  reasonItems={data}
                  comfortItems={comfortableSpacesItems} />
                 )
              }
         </VStack>
       )
       
      
      }
     </>
    
  )
};

export default WHHome;

import {
  useMediaQuery,
  VStack,
  Text,
  Box,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  Input,
  HStack,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { MdLink, MdMail, MdPerson } from "react-icons/md";
import { RiFacebookFill } from "react-icons/ri";
import { ResultType } from "../../../../Models/Interfaces";
import { SetErrorContext, UserContext } from "../../../AppProvider/AppProvider";
import AddASpaceLayout from "../../../SupportingViews/AddASpaceLayout/AddASpaceLayout";

import styles from "../../AddASpace/AddASpace.module.css";
import {
  ContactDetailsProps,
  ContactForm,
  DashboardSectionName,
} from "../Models/Interfaces";
import { yupContactValidation } from "../Models/Schema/DescriptionValSchema";
import { dashboardManager } from "../Models/WHDashboardDataManager";
import { SetWorkspaceContext, WorkspaceContext } from "../WHManageWorkspaces/ManageWorkspacesProvider";
import {t} from 'i18next'

const ContactDetails = ({
  formRef,
  manager,
  updateFormSubmissionStatus,
  actionAfterSubmit,
}: ContactDetailsProps) => {
  const [isMediumScrn, isLargeScrn] = useMediaQuery([
    "(min-width: 30em)",
    "(min-width: 62em)",
  ]);
  let questionFontSize = "h5";
  const [values, setValues] = useState({ websiteURL: "", workspaceEmail: "" });
  const partner = useContext(UserContext);
  const setErrorContext = useContext(SetErrorContext);
  const [id, setId] = useState<string>("");
  const setWorkspaceContext = useContext(SetWorkspaceContext);
  const workspace = useContext(WorkspaceContext)
 
  if (isMediumScrn) {
    questionFontSize = "h4";
  } else if (isLargeScrn) {
    questionFontSize = "h3";
  }
  const fieldHeight = 10;

  useEffect(() => {

    if (workspace && workspace.website_url && workspace.email) {
      setValues({
        websiteURL: workspace.website_url,
        workspaceEmail: workspace.email,
      });
    }

    if (workspace && workspace.id) {
      setId(workspace.id)
    }

    /*
     let id = localStorage.getItem("title");

    if (id) {
      let workspace = manager.getWorkspace(id);

      if (workspace && workspace.website_url && workspace.email) {
        setValues({
          websiteURL: workspace.website_url,
          workspaceEmail: workspace.email,
        });
      }
      setId(id);
    }*/
    
  }, []);

  useEffect(()=>{

    if (workspace?.email) {
      setValues({...values, workspaceEmail: workspace.email})
    }

    if (workspace?.website_url) {
      setValues({...values, workspaceEmail: workspace.website_url})
    }

  }, [workspace?.email, workspace?.website_url])

  return (
    <>
      {/* <Box
            bg="white"
            position="relative"
            w="full"
            h={{ base: "70%", md: "100%" }}
            borderTopRightRadius={{ base: 12, md: "unset" }}
            borderTopLeftRadius={{ base: 12, md: "unset" }}
          > */}
      <Formik
        initialValues={values}
        initialStatus={{ isValidating: false }}
        validationSchema={yupContactValidation}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          if (partner && partner.access_token) {
            let result = await dashboardManager.updateWorkspaceSection(
              id,
              DashboardSectionName.ContactDetails,
              values,
              partner.access_token
            );
            if (result.type === ResultType.Success) {
             // dashboardManager.saveWorkspace(result.value.id!, result.value);
               setWorkspaceContext({...workspace, website_url: result.value.website_url, email: result.value.email});
              actionAfterSubmit();
              // setValues(props.values.name)
            } else {
              setErrorContext({
                title: result.error.name,
                msg: result.error.message,
              });
            }
          }
        }}
        innerRef={formRef}
      >
        {(props) => {
          return (
            <Form
              style={{ width: "100%", height: "100%" }}
              //className={styles.form}
            >
              <VStack alignItems="flex-start">
                <Text
                  padding={{ base: 6, lg: 8 }}
                  // paddingY={{ base: 6, lg: 8 }}
                  w={{ base: "full", md: "75%", lg: "80%" }}
                  className="h6"
                >
                  {t("Official Contact Details")}
                </Text>
                <Divider />
              </VStack>
              <AddASpaceLayout>
                <Field name="bizemail">
                  {({ field, form }: any) => {
                    return (
                      <FormControl
                        className="caption"
                       
                         htmlFor="bizemail"
                      >
                        <FormLabel fontWeight={500}>{t("Business Email")}</FormLabel>
                        <InputGroup>
                          <InputLeftAddon
                            bg="primary.200"
                            children={<MdMail color="#fff" />}
                          />
                          <Input
                            disabled
                            id="bizemail"
                            name="bizemail"
                            type="text"
                            h={fieldHeight}
                            onChange={props.handleChange}
                            value={partner?.partner_details?.email ?? "Untitled"}
                          />
                        </InputGroup>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="workspaceEmail">
                  {({ field, form }: any) => {
                    return (
                      <FormControl 
                      isRequired
                      isInvalid={form.errors.workspaceEmail && form.touched.workspaceEmail}
                        className="caption">
                        <FormLabel fontWeight={500}>
                          {t("Workspace Email")}
                        </FormLabel>
                        <InputGroup>
                          <InputLeftAddon
                            bg="primary.200"
                            children={<MdMail color="#fff" />}
                          />
                          <Input
                            {...field}
                            id="wkemail"
                            name="workspaceEmail"
                            type="text"
                            h={fieldHeight}
                            onChange={props.handleChange}
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.workspaceEmail}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="websiteURL">
                  {({ field, form }: any) => {
                    return (
                      <FormControl 
                       
                       isInvalid={form.errors.websiteURL && form.touched.websiteURL}
                        className="caption" htmlFor="url">
                        <FormLabel fontWeight={500}>{t("Website URL")}</FormLabel>
                        <InputGroup>
                          <InputLeftAddon
                            pointerEvents="none"
                            bg="primary.200"
                            children={<MdLink color="#fff" />}
                          />
                          <Input
                            {...field}
                            id="url"
                            name="websiteURL"
                            type="text"
                            h={fieldHeight}
                            onChange={props.handleChange}
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.websiteURL}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <HStack justifyContent="flex-end" w="100%">
                  <Button
                    w={"20%"}
                    minW="96px"
                    isLoading={props.isSubmitting}
                    className="button"
                    colorScheme="primary"
                    variant="solid"
                    onClick={() => {
                      props.submitForm();
                    }}
                    //disabled
                  >
                    {t("Next")}
                  </Button>
                </HStack>
              </AddASpaceLayout>
            </Form>
          );
        }}
      </Formik>
      {/* </Box> */}
    </>
  );
};

export default ContactDetails;

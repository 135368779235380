import { Box, Button, Circle, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Tooltip } from '@chakra-ui/react'
import React from 'react'

interface WHIncompleteErrorProps {
    text: string
}

const WHIncompleteError = ({text}: WHIncompleteErrorProps) => {
    return (
         <Popover>
            <PopoverTrigger>
                {/* <Button>Trigger</Button> */}
                    <Circle
                    tabIndex={0}
                    role="button"
                    aria-label="Some box"
                    bg="secondary.500"
                    w="40px"
                    h="40px"
                    position="absolute"
                    top="-18px"
                    right="16px"
                >
                <Box 
                    width="70%"
                    height="8px"
                    bg="white">

                </Box>
                </Circle>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Error</PopoverHeader>
                <PopoverBody>{text}</PopoverBody>
            </PopoverContent>
            </Popover>
          
    )
}

export default WHIncompleteError

import React from 'react'
import { Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from '@chakra-ui/react';
import { FilterButtonProps } from '../../Screens/WHApp/Models/Interfaces';


export const FilterButton = ({label, children: filterContent}: FilterButtonProps) => {
    return (
        <>
            <Popover >
            <PopoverTrigger
             >
                <Button
                    paddingX={"16px"}
                    className="subtitle2"
                    paddingY={"4px !important"}
                    height="36px"
                  // minW="64px"
                    variant="outline"
                    colorScheme="primary"
                     rounded="full"
                >{label}</Button>
            </PopoverTrigger>
            <PopoverContent minW="400px">
                <PopoverArrow />
                {/* <PopoverCloseButton /> */}
                 <PopoverBody>
                    {filterContent}
                 </PopoverBody>
            </PopoverContent>
            </Popover>
            
        </>
    )
}

export default FilterButton

import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation  } from "react-i18next";

import OfficeCarousel from "./OfficeCarousel/OfficeCarousel";

import homeImg1 from "../../../Assets/workspace4.jpg";
import homeImg2 from "../../../Assets/workspace2.jpg";
import homeImg3 from "../../../Assets/workspace3.jpg";
import {
  BreakPoint,
  ModalCategory,
  OfficePhotoItem,
} from "./Models/Interfaces";
import Description, { Summary } from "./Description/Description";
import {
  Box,
  Breakpoint,
  Button,
  Divider,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import Amenities from "./Amenities/Amenities";
import Reviews from "./Reviews/Reviews";
import RelatedOffices from "./RelatedOffices/RelatedOffices";
import PostReviewForm from "./PostReviewForm";
import UsersReviews from "./UsersReviews";
import AllAmenities from "./AllAmenities/AllAmenities";
import PrimaryActions from "./PrimaryActions/PrimaryActions";
import EnquiryForm from "./EnquiryForm";
import AddACardForm from "./AddACardForm";
import OfficeCarouselLarge from "./OfficeCarousel/OfficeCarouselLarge";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import WHLayoutGrid from "../../../SupportingViews/WHLayoutGrid/WHLayoutGrid";
import { useHistory, useParams } from "react-router";
import {
  Listing,
  ListingImage1,
  ListingImage2,
  ListingImage3,
  ListingImage4,
  PublicListing,
  ResultType,
  UserReview,
} from "../../../../Models/Interfaces";
import { appDataManager } from "../Models/WHAppDataManager";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import OfflineView from "../../../SupportingViews/OfflineView";
import { SetErrorContext, UserContext } from "../../../AppProvider/AppProvider";
import WHSEO from "../../../SupportingViews/WHSEO/WHSEO";
import AdsView from "../../../SupportingViews/AdsView/AdsView";
import ClaimListingForm from "./ClaimListingForm";
import routes from "../../../../routes";
import { ListingLink } from "../../../SupportingViews/Links/Links";

const OfficeDetail = () => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [isMediumScrn, isLargeScrn] = useMediaQuery([
    "(min-width: 30em)",
    "(min-width: 62em)",
  ]);
  let modalSize = "full";
  const [modalCategory, setModalCategory] = useState(ModalCategory.AMENITIES);
  const params = useParams<{ officeId?: string }>();
  const [listing, setListing] = useState<PublicListing | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const user = useContext(UserContext);
  const [userReviews, setUserReviews] = useState<UserReview[]>([]);
   let userReview = userReviews.find((item) => item.user === Number(user?.id))
  const history = useHistory()
  const {t} = useTranslation()
  
  //console.dir(listing)
   //debugger
  const items: OfficePhotoItem[] = listing
    ? [
        { imageURL: listing.images.header, id: "1" },
        {
          imageURL: (listing.images.others[0] as ListingImage1).image1,
          id: "2",
        },
        {
          imageURL: (listing.images.others[1] as ListingImage2).image2,
          id: "3",
        },
        {
          imageURL: (listing.images.others[2] as ListingImage3).image3,
          id: "4",
        },
        {
          imageURL: (listing.images.others[3] as ListingImage4).image4,
          id: "5",
        },
      ]
    : [];

  const getModal = (): ReactNode => {
    if (!listing) return null;
    let view = <AllAmenities amenities={listing.workspace_details.amenities} />;
    if (modalCategory === ModalCategory.PAR) {
      view = (
        <PostReviewForm
          userReview={userReview}
          onSubmitEnded={onModalClose}
          listing={listing}
        />
      );
    } else if (modalCategory === ModalCategory.REVIEWS) {
      view = <UsersReviews reviews={userReviews} listing={listing} />;
    } else if (modalCategory === ModalCategory.CARD) {
      view = <AddACardForm />;
    } else if (modalCategory === ModalCategory.ENQUIRY) {
      view = <EnquiryForm onSubmitEnded={onModalClose} listing={listing} />;
    } else if (modalCategory === ModalCategory.CLAIMLISTING && listing.id) {
       view =  <ClaimListingForm listingId={listing.id}  onSubmitEnded={(resultMsg: string)=>{
         
          onModalClose()
          history.push(["/" + routes.claimListing.name, listing.workspace, listing.id, listing.title].join("/"))
        
       
       }} email={listing.workspace_details.email} />
    }
    return view;
  };

  const reviews = () => {
    let rowspan: number = 9;
    let colspan = 10;

    if (breakpoint !== BreakPoint.S) {
      colspan = 8;
      rowspan = 12;
    }

    if (breakpoint === BreakPoint.M) {
      colspan = 10;
      rowspan = 12;
    }

    if (breakpoint === BreakPoint.L) {
      colspan = 12;
      rowspan = 10;
    }

    //console.dir(listing)

    return (
      <VStack alignItems="flex-start" spacing={4} w="full">
        <Reviews avgRating={listing?.avg_rating} reviews={userReviews} handleModalOpen={handleModalOpen} />
        <Divider />
      </VStack>
    );
  };

  const getModalHeaderText = (): String => {
    let view = "Amenities Offered";
    if (modalCategory === ModalCategory.PAR) {
      view = "What Do You Think about Beehive?";
    } else if (modalCategory === ModalCategory.REVIEWS) {
      view = "User Reviews";
    } else if (modalCategory === ModalCategory.CARD) {
      view = "Add a Card";
    } else if (modalCategory === ModalCategory.ENQUIRY) {
      view = "Got Any Questions?";
    } else if (modalCategory === ModalCategory.CLAIMLISTING) {
      view = "Confirm Workspace Email";
    }
    return view;
  };

  const handleModalOpen = (category: ModalCategory) => {
    setModalCategory(category);
    onModalOpen();
  };

  let breakpoint: BreakPoint = BreakPoint.XS;

  // const [xsmall, small, medium, large] = useMediaQuery([
  //   "(max-width: 599px)",
  //   "(min-width: 600px) and (max-width: 904px)",
  //   "(min-width: 905px) and (max-width: 1439px)",
  //   "(min-width: 1440px)",
  // ]);

  const [xsmall, small, medium, large, xlarge] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 905px) and (max-width: 1439px)",
    "(min-width: 1440px)"
  ]);

  //console.dir(listing)

  if (small) {
    breakpoint = BreakPoint.S;
  } else if (medium) {
    breakpoint = BreakPoint.M;
  } else if (large || xlarge) {
    breakpoint = BreakPoint.L;
  }

  if (xsmall || small || modalCategory === ModalCategory.REVIEWS) {
    modalSize = "full";
  } else {
    modalSize = "xl";
  }

  const loadListing = async (id: string, token?: string) => {
    setIsLoading(true);
    const result = await appDataManager.getListingById(id);
    const result2 = await appDataManager.getListingReviews(
      { listing: id });

    if (
      result.type === ResultType.Success &&
      result2.type === ResultType.Success
    ) {
      setListing(result.value);
      setUserReviews(result2.value);
     // console.dir(result.value)
      //console.dir(result2.value);
      setError(null);
    } else if (result.type === ResultType.Failure) {
      setListing(null);
      setError(result.error);
    } else if (result2.type === ResultType.Failure) {
      setListing(null);
      setError(result2.error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
   

    if (params.officeId) {
      loadListing(params.officeId);
    }
  }, [params.officeId]);

  const largeDetailView = (listing: PublicListing) => {
    return (
      <>
        <WHLayoutGrid height="100%">
          <OfficeCarouselLarge items={items} />
        </WHLayoutGrid>
        <LayoutView>
         
          <HStack mt={4} w="full">
             
            <VStack alignItems="flex-start" spacing={4} w="full">
              <HStack  w="full" justifyContent="space-between">
                  <VStack alignItems="flex-start" w="80%">
                    <HStack w="full" alignItems="flex-start">
                    <Description
                      listing={listing}
                      />
                       
                       {
                         !listing.is_claimed ? (
                          <Button colorScheme="primary" onClick={()=>{
                            handleModalOpen(ModalCategory.CLAIMLISTING)
                          }}>
                             {t("Claim Listing")}
                            {/* Claim Listing */}
                            </Button>
                         ):null
                       }
                     
                    </HStack>
                  -
                  <Divider />
                  <VStack alignItems="flex-start" spacing={4} w="full">
                    <Summary listing={listing} />
                    <Divider />
                  </VStack>
                </VStack>
                <Spacer />
                 {
                   listing.is_claimed ? (
                    <PrimaryActions
                    duration={listing.booking_duration}
                    handleModalOpen={handleModalOpen}
                    listing={listing}
                  />
                   ):null
                 }
                
              </HStack>
             
             
            <VStack alignItems="flex-start" spacing={4} w="full">
            <Amenities
              amenities={listing.workspace_details.amenities}
              handleModalOpen={handleModalOpen}
            />
            <Divider />
            {reviews()}
          </VStack>
            </VStack>

            {/* <PrimaryActions
              duration={listing.booking_duration}
              handleModalOpen={handleModalOpen}
              listing={listing}
            /> */}
          </HStack>

         
        </LayoutView>
        {
          listing.id ? (
            <WHLayoutGrid height="100%">
           
           <RelatedOffices
            listingId={listing.id}
            categoryId={listing.category}
            categoryTitle={listing.category_details}
          />
          </WHLayoutGrid>
          ): null
        }
         
       </>
    );
  };

  const mobileDetailView = (listing: PublicListing) => {
    return (
      <>
        <OfficeCarousel items={items} />
        
        <VStack spacing={{ base: 4 }} alignItems="flex-start" p={4}>
        {/* <VStack alignItems="flex-start" w="full"> */}
        <HStack justifyContent="space-between" alignItems={"flex-start"} w="full">

                    <Description
                      listing={listing}
                      />
                      {
                        !listing.is_claimed ? (
                          <Button colorScheme="primary" onClick={()=>{
                            handleModalOpen(ModalCategory.CLAIMLISTING)
                          }}>
                            {t("Claim Listing")}
                            {/* Claim Listing */}
                            </Button>
                        ):null
                      }
                     
                    </HStack>
          <Divider />
          {
            listing.is_claimed ? (
              <PrimaryActions
              duration={listing.booking_duration}
              handleModalOpen={handleModalOpen}
              listing={listing}
            />
            ):null
          }
          
          <Summary listing={listing} />
          <Divider />
          <Amenities
            amenities={listing.workspace_details.amenities}
            handleModalOpen={handleModalOpen}
          />
          <Divider />
          <Reviews reviews={userReviews} handleModalOpen={handleModalOpen} />
          <Divider />
         
        </VStack>
        {
          listing.id ? (
            <WHLayoutGrid height="100%">
            <RelatedOffices
              categoryId={listing.category}
              categoryTitle={listing.category_details}
              listingId={listing.id}
            />
           </WHLayoutGrid>
          ): null
        }
       
      </>
    );
  };

  const getDetailView = () => {
    if (!listing) return null;

    if (xsmall || small) {
      return mobileDetailView(listing);
    } else {
      return largeDetailView(listing);
    }
  };

  //console.dir(listing)

  return (
    <div>

      {isLoading && (
        <VStack
          pt={16}
          alignItems="center"
          justifyContent="center"
          w="full"
          h="full"
        >
          <WHSpinner w="100%" />
        </VStack>
      )}
      {error && <OfflineView pt={"64px"}
        onRetry={()=> {
          if (params.officeId) {
            loadListing(params.officeId);
          }
        }}
      msg={error.message} />}
      {listing && 
        <>
           <WHSEO 
            title={`${listing.title} Office`} 
            meta={[
              {
                name: "description",
                content: `${listing.title} detail`
              },
              {
                name: "keywords",
                content: `Office, WorkHopper, ${listing.title}`
              }
            ]}  
          />
          {
            
            getDetailView()
          }
          {/* <LayoutView>
             <AdsView width="100%" height="120px" />
           </LayoutView> */}
         </>
        }
      {/* {getDetailView()} */}
      <Modal
        closeOnOverlayClick={true}
        scrollBehavior="inside"
        isCentered
        //size={modalSize}
        size={xsmall ? "md" : "lg"}
        motionPreset="slideInBottom"
        isOpen={isModalOpen}
        //isOpen
        onClose={onModalClose}
      >
        <ModalOverlay />
        <ModalContent
                       position={xsmall ? "fixed" : undefined}

          // position={xsmall ? "fixed" : "unset"}
           bottom={xsmall ? "0px" : undefined}
           mb={xsmall ? "0" : "unset"}
           borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
        >
          <ModalHeader className="h6">{getModalHeaderText()}</ModalHeader>
          <Divider />
          <ModalCloseButton color="primary.500" />
          <ModalBody paddingX="0px">{getModal()}</ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OfficeDetail;

/**
 * Abstract: A view that collects information to setup a business
 * account tied to cowork spaces
 */


import { useMediaQuery, VStack, Text, Box, Divider,  FormControl, InputGroup, InputLeftAddon, FormLabel, Input, FormErrorMessage, HStack, Spacer, Button, Switch, Modal, ModalFooter, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, useDisclosure } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { BizAccountProps } from '../Models/Interfaces'

//import styles from '../../AddASpace/AddASpace.module.css'
import AddASpaceLayout from '../../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'
import { MdLink, MdMail, MdPerson } from 'react-icons/md'
import { RiFacebookFill, RiInstagramFill, RiLinkedinFill, RiTwitterFill } from 'react-icons/ri'

import styles from "../AddASpace.module.css"
import { yupBizAcctValidation } from '../Schema/YupSpaceValidation'
import { ResultType } from '../../../../../Models/Interfaces'
import {  SetErrorContext, SetUserContext } from '../../../../AppProvider/AppProvider'
import { signUpDataManager } from '../../SignUp/SignUpDataManager'
import { loginDataManager } from '../../Login/LoginDataManager'
import { access } from 'fs'
import { useHistory } from 'react-router'
import { OnBoardSectionId, URLDir } from '../Models/ListSpaceDataManager'
import routes from '../../../../../routes'
import { ListingLink } from '../../../../SupportingViews/Links/Links'
import { BreakPoint } from '../../OfficeDetail/Models/Interfaces'
import PrivacyPolicy from '../../PrivacyPolicy/PrivacyPolicy'
import PolicyView from '../../../../SupportingViews/PolicyView/PolicyView'
import TandC from '../../../../SupportingViews/TandC/TandC'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next';

const BizAccount = ({formRef, formValues, dataManager, updateFormSubmissionStatus, actionAfterSubmit, onLoad, validatePersonalAcct}: BizAccountProps) => {
    
    
    const fieldHeight = 10
    const setErrorContext = useContext(SetErrorContext)
    const setUserContext = useContext(SetUserContext)    
    const history = useHistory()        
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {i18n} = useTranslation()

    const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])
    let questionFontSize =  "h5"
    if (isMediumScrn) {
        questionFontSize = "h4"
    } else if(isLargeScrn) {
        questionFontSize = "h3"
    }

    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 905px) and (max-width: 1439px)",
      "(min-width: 1440px)"
    ]);


    useEffect(()=>{
       
       
        if (validatePersonalAcct && !dataManager.personalAccountDataValid()) {
            history.push(dataManager.getPrevSectionURL("/biz+account"))
        }
    }, [])

   
    
    return (
      <>
        <VStack
          //
          //pb={8}
          bg="primary.500"
          w={"full"}
          minH={{ base: "30%", lg: "100%" }}
          justifyContent={{ base: "center", md: "center" }}
          alignItems={{ md: "flex-start" }}
          h="96px"
        >
          <Text
            pl={{ base: 6, md: 8 }}
            w={{ base: "full", md: "75%", lg: "80%" }}
            color="white !important"
            className={questionFontSize}
          >
            {t("bizPlease")}
          </Text>
        </VStack>
        <Box
          bg="white"
          position="relative"
          w="full"
          h={{ base: "70%", md: "100%" }}
          borderTopRightRadius={{ base: 12, md: "unset" }}
          borderTopLeftRadius={{ base: 12, md: "unset" }}
        >
          <Formik
            innerRef={formRef}
            initialValues={formValues}
            validateOnBlur={false}
            validateOnChange={false}
            //initialValues={{ bemail: "", bname: "", websiteURL: "", fbURL: "", instagramURL: "", twitterURL: "" }}
            validationSchema={yupBizAcctValidation}
             enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              
               // console.log("safari")
                //console.log(values)
                //debugger
                updateFormSubmissionStatus(true)
                 dataManager.setBizAcctData(values)
                 
                let registeredPartnerResult = await dataManager.registerPartner()
                 if (registeredPartnerResult.type === ResultType.Success) {
                    
                  let data = {
                    email: dataManager.acctData.email,
                    password: dataManager.acctData.password
                  }
               
                  let result = await loginDataManager.loginUser(data)
                  if (result.type === ResultType.Success) {

                    registeredPartnerResult.value.access_token = result.value.access
                    // debugger
                    setUserContext(registeredPartnerResult.value)
                    
                    /*
                    let registeredUserResult = await loginDataManager.getLoginUser(result.value.access)
                     
                    
                    if (registeredUserResult.type === ResultType.Success) { 
                      registeredUserResult.value.access_token = result.value.access
                     // debugger
                      setUserContext(registeredUserResult.value)
                     //signUpDataManager.saveRegisteredUserData(registeredUserResult.value)
 
                     } else {
                      setErrorContext({title: "", msg: registeredUserResult.error.message})
                    }*/
                    
                 } else {
                   setErrorContext({title:  "", msg: result.error.message})
                }
                   
                   
                  actionAfterSubmit()
                  //   setTimeout(()=>{
                  //     actionAfterSubmit()
                  //  }, 3000)
                 } else {
                   let msg = registeredPartnerResult.error.message.toLowerCase()
                   if (msg.includes("email") && !msg.includes("partner")) {
                      history.push(dataManager.getPrevSectionURL("/biz+account"))
                   }
                   setErrorContext({title: registeredPartnerResult.error.name, msg: registeredPartnerResult.error.message})
                 }
                updateFormSubmissionStatus(false);

               
            }}
          >
            {(props) => {
             // console.dir(props.values)
              return (
                <Form className={styles.form}>
                  <VStack alignItems="flex-start">
                    <Text
                      padding={{ base: 6, lg: 8 }}
                      // paddingY={{ base: 6, lg: 8 }}
                      w={{ base: "full", md: "75%", lg: "80%" }}
                      className="h6"
                    >
                      {t("bizIntroInfo")}
                    </Text>
                    <Divider />
                  </VStack>
                  <AddASpaceLayout>
                       <Field name="bname">
                        {({ field, form }: any) => {
                          return (
                            <FormControl
                              className="caption"
                              isRequired
                              htmlFor="bname"
                              isInvalid={form.errors.bname}

                              //isInvalid={form.errors.bname && form.touched.bname}

                            >
                              <FormLabel fontWeight={500}>
                                {t("bizname")}
                              </FormLabel>
                              <InputGroup>
                                <InputLeftAddon
                                  bg="primary.200"
                                  children={<MdPerson color="#fff" />}
                                />
                                <Input
                                  {...field}
                                  id="bname"
                                  name="bname"
                                  type="text"
                                  h={fieldHeight}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {i18n.language == 'en' ? form.errors.bname : "Le nom de l'entreprise ne doit pas être vide"}
                              </FormErrorMessage>
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Field name="bemail">
                        {({ field, form }: any) => {
                          return (
                            <FormControl
                              className="caption"
                              isRequired
                              htmlFor="bemail"
                              isInvalid={form.errors.bemail}
                              //isInvalid={form.errors.bemail && form.touched.bemail}
                            >
                              <FormLabel fontWeight={500} fontSize={"15px"}>
                              {t("bizemail")}
                              </FormLabel>
                              <InputGroup>
                                <InputLeftAddon
                                  pointerEvents="none"
                                  bg="primary.200"
                                  children={<MdMail color="#fff" />}
                                />
                                <Input
                                  {...field}
                                  id="bemail"
                                  name="bemail"
                                  type="bemail"
                                  h={fieldHeight}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {form.errors.bemail}
                                </FormErrorMessage>
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Field
               
               name="terms">
               {({field, form}: any) => {
                  return (
                   <FormControl
                   isInvalid={form.errors.terms}
                   //isInvalid={form.errors.terms && form.touched.terms}
                   isRequired
                   display="flex"
                   flexDirection="column"
                   alignItems="flex-start"
                  // justifyContent="center"
                 >
                   <FormLabel htmlFor="terms" mb="0">
                     { i18n.language == 'en' ? 'I agree to the': `J'accepte les `}  &nbsp;
                      <a style={{textDecorationLine: "underline"}} onClick={onOpen}>
                      { i18n.language == 'en' ? 'terms and conditions': `termes et conditions`}
                      </a>
                   </FormLabel>
                   <Switch 
                      onChange={props.handleChange}
                      isChecked={props.values.terms}
                      size="sm" name="terms" 
                     // {...field}   
                      />
                   <FormErrorMessage>{i18n.language == 'en' ? form.errors.terms : 'Ce champ est obligatoire'}</FormErrorMessage>

                 </FormControl>
                  )
               }}
               </Field>
                      <Field name="websiteURL">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                htmlFor="url"
                                isInvalid={form.errors.websiteURL}

                              >
                                <FormLabel fontWeight={500}>{t("webUrl")}</FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdLink color="#fff" />}
                                  />
                                  <Input
                                    //id="url"
                                    {...field}
                                    name="websiteURL"
                                    type="text"
                                    h={fieldHeight}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}

                                  />
                                </InputGroup>
                                <FormErrorMessage>{form.errors.websiteURL}</FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <VStack w="full" alignItems="flex-start">
                      <Text
                        //pl={{ base: 6, md: 8 }}
                        w={{ base: "full", md: "75%", lg: "80%" }}
                        className="h6"
                      >
                        {t("socialMedia")}
                      </Text>
                      <Divider />
                    </VStack>
                  
                    {/* <AddASpaceLayout> */}
                         <Field name="fbURL">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isInvalid={form.errors.fbURL}
                                //htmlFor="fb"
                              >
                                <FormLabel fontWeight={500}>
                                  {t('Facebook')}
                                 {/* Facebook */}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    bg="primary.200"
                                    children={<RiFacebookFill color="#fff" />}
                                  />
                                  <Input
                                    //id="fb"
                                    {...field}

                                    name="fbURL"
                                    type="text"
                                    h={fieldHeight}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                  />
                                </InputGroup>
                                <FormErrorMessage>{form.errors.fbURL}</FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <Field name="twitterURL">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isInvalid={form.errors.twitterURL}
                                //htmlFor="twitter"
                                //isInvalid={form.errors.twitterURL && form.touched.twitterURL}
                              >
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                {t('Twitter')}
                                  {/* Twitter */}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<RiTwitterFill color="#fff" />}
                                  />
                                  <Input
                                    //id="twitter"
                                    {...field}

                                    name="twitterURL"
                                    type="text"
                                    h={fieldHeight}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                  />
                                </InputGroup>
                                <FormErrorMessage>{form.errors.twitterURL}</FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <Field name="instagramURL">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isInvalid={form.errors.instagramURL}
                                //htmlFor="instagram"
                                //isInvalid={form.errors.instagramURL && form.touched.instagramURL}
                              >
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                  {t('Instagram')}
                                  {/* Instagram */}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<RiLinkedinFill color="#fff" />}
                                  />
                                  <Input
                                    {...field}
                                    //id="instagram"
                                    name="instagramURL"
                                    type="text"
                                    h={fieldHeight}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                  />
                                </InputGroup>
                                <FormErrorMessage>{form.errors.instagramURL}</FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <HStack w="full">
                       <Spacer/>
                       {/* <Button
                        onClick={()=>{props.handleSubmit()}}
                           >Looks Good?
                        </Button> */}
                    </HStack>
                    {/* </AddASpaceLayout> */}
                  
                  </AddASpaceLayout>
                </Form>
              );
            }}
          </Formik>
          <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={xsmall ? "md" : "2xl"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            height="100%"
            position={xsmall ? "fixed" : "unset"}
            bottom={xsmall ? "0px" : "unset"}
            mb={xsmall ? "0" : "unset"}
             borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              {t('Privacy Policy')}
              {/* Privacy Policy */}
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody>
              <TandC />
            </ModalBody>
            <Divider />
            {/* <ModalFooter>
              <Spacer />
              <Button 

                    isLoading={isLoading}
                    colorScheme="primary"
                    onClick={()=>{
                      onAcceptTerms()
                      //onApplyFilters()
                      onClose()
                    }}
               >
                        Accept
                </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
        </Box>
         
      </>
    );
}

export default BizAccount

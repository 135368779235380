import { VStack, Text, HStack, Image, Avatar, Button,  useMediaQuery, Modal, ModalOverlay, ModalContent,  ModalCloseButton, useDisclosure, Box, Circle, ModalBody, ModalFooter, ModalHeader, FormControl, FormLabel, InputGroup, Input, InputLeftAddon, Spacer } from '@chakra-ui/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import Dropzone, { FileRejection } from 'react-dropzone';
import { MdChevronRight, MdLock, MdPerson, MdPhotoCamera } from 'react-icons/md'
import { RegistrationResponse, ResultType } from '../../../../Models/Interfaces';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { SetErrorContext, SetSuccessContext, SetUserContext, UserContext } from '../../../AppProvider/AppProvider'
import { signUpDataManager } from '../SignUp/SignUpDataManager';
import { UploadedFile } from '../../WHDashboard/Models/Interfaces';
import { t } from 'i18next';

enum Setting {
    PASSWORD = "password",
    PHOTO = "photo"

}

interface UpdateProfile {
    user: RegistrationResponse
    count: number
    setIsLoading: (isLoading: boolean)=>void
    onClose: ()=>void
}

interface ProfilePhotoViewProps {
    //onClose: ()=>void
    user: RegistrationResponse
    count: number
    setIsLoading: (isLoading: boolean)=>void
    onClose: ()=>void
}

interface EditPasswordPros extends UpdateProfile {}

const officeDropzone = (
    files: UploadedFile[],
    width: string,
    height: string,
    onDrop: (accFiles: File[], rejFiles: FileRejection[]) => void,
    minFileSize?: number,
    maxFileSize?: number
  ) => {
    return (
      <Dropzone
        onDrop={onDrop}
        accept="image/*"
        minSize={minFileSize ?? 1024}
        maxSize={maxFileSize ?? 3072000}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <Circle
            rounded="lg"
            display="grid"
            placeItems="center"
            w={width}
            h={height}
            bg="gray.400"
            {...getRootProps({ className: "dropzone" })}
          >
            {files.length === 0 ? (
              <MdPerson fontSize="64px" color="gray" />
            ) : null}
            <input {...getInputProps()} />
           
            {files.map((uploadedFile) => (
              <>
                
                <Image
                  key={uploadedFile.preview}
                  objectFit="contain"
                  rounded="lg"
                  h="inherit"
                  w="inherit"
                  src={uploadedFile.preview || "" }
                />

                {/* </Box> */}
              </>
            ))}
          </Circle>
        )}
      </Dropzone>
    );
  };

  const ProfilePhotoView = ({count, user, setIsLoading, onClose}: ProfilePhotoViewProps)=> {

    const setErrorContext = useContext(SetErrorContext)
    const setUserContext = useContext(SetUserContext)
    const fileSize = 3072000
    const minFileSize = 1024


    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])

    const handleDrop = (accFiles: File[], rejFiles: FileRejection[]) => {
        console.dir(accFiles)
        console.dir(rejFiles)
        let errorMsg = ""
      
        if (rejFiles.length > 0) {
          if ( rejFiles[0].errors[0].code.includes("too-large") ) {
              errorMsg = `file larger than ${fileSize / 1024000} mb`
              setErrorContext({title: "", msg: errorMsg})
          }
          return
        }
        setUploadedFiles(
         accFiles.map((accFile: File) => {
           return { file: accFile, preview: URL.createObjectURL(accFile) };
         })
       );
     };

     const updateProfilephoto = useCallback(async (token: string)=> {
         
         setIsLoading(true)
        const result = await WorkhopperAPIManager.shared.putAvatar(user.id, uploadedFiles[0].file, token) 
        if (result.type === ResultType.Success) {
            let avatarObj = result.value as {avatar: string}
            //console.log(result.value)
            let temp = {...user, avatar: avatarObj.avatar}
            setUserContext(temp)
            signUpDataManager.saveRegisteredUserData(temp)
            onClose()
        } else {
            setErrorContext({title: "", msg: result.error.message})
        }
        setIsLoading(false)
     }, [uploadedFiles])
    
     useEffect(() => {
         if (count > 0 && user.access_token && uploadedFiles[0].file.size > 0) {
             // send request
            updateProfilephoto(user.access_token)
         }
         return () => {
             
         }
     }, [count])

     return (
         <VStack w="full">
             {officeDropzone(uploadedFiles, "80px", "80px", handleDrop, minFileSize, fileSize)}
             <Text className="caption">{t("File size should greater than")} {minFileSize / 1024}kb {t("but less than ")} {fileSize / 1024000}mb </Text>
             {/* <HStack w="full">
                 <Spacer/>
                 <Button>Update</Button>
             </HStack> */}
         </VStack>
     )
  }

  //const EditPassword = ({setPassword, setNewPassword, password, newPassword}: EditPasswordPros)=> {
    const EditPassword = (props: EditPasswordPros)=> {

    

    const [password, setPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const setErrorContext = useContext(SetErrorContext)
    const setUserContext = useContext(SetUserContext)
    const setSuccessMessage = useContext(SetSuccessContext)

    const changePassword = useCallback(async (token: string)=> {
         
      props.setIsLoading(true)
       const result = await WorkhopperAPIManager.shared.changePassword(newPassword, password, token) 
       if (result.type === ResultType.Success) {
            //console.log(result.value)
           setSuccessMessage({title: "", msg: "Your password was changed successfully"})
           props.onClose()
       } else {
           console.log(result.error)
           setErrorContext({title: "", msg: result.error.message})
       }
       props.setIsLoading(false)
    }, [password, newPassword])

    useEffect(() => {
        
        if (props.user.access_token && password.length > 0 && newPassword.length > 0) {
            changePassword(props.user.access_token)
        }
        return () => {
            
        }
    }, [props.count])


    return (

        <VStack alignItems="flex-start">
        <FormControl 
            className="caption" 
            isRequired id="password"
            //isInvalid={form.errors.password && form.touched.password}
            >
            <FormLabel
              id="password"
              name="password"
              fontWeight={500}
              fontSize={"15px"}
            >
              Password
            </FormLabel>
            <InputGroup>
              <InputLeftAddon
                pointerEvents="none"
                bg="primary.200"
                children={<MdLock color="#fff" />}
              />
              <Input 
                value={password}
                // {...field}
                //onChange={setPassword}
                onChange={(e)=>{setPassword(e.target.value)}}
                type="password" name="password" h={10} />
            </InputGroup>
            {/* <FormErrorMessage>{form.errors.password}</FormErrorMessage> */}
          </FormControl>
          <FormControl 
            className="caption" 
            isRequired id="password"
            //isInvalid={form.errors.password && form.touched.password}
            >
            <FormLabel
              id="password"
              name="password"
              fontWeight={500}
              fontSize={"15px"}
            >
              New Password
            </FormLabel>
            <InputGroup>
              <InputLeftAddon
                pointerEvents="none"
                bg="primary.200"
                children={<MdLock color="#fff" />}
              />
              <Input 
                value={newPassword}
                // {...field}
               // onChange={setNewPassword}
                onChange={(e)=>{setNewPassword(e.target.value)}}
                type="password" name="password" h={10} />
            </InputGroup>
            </FormControl>
           
        </VStack>
    )
   
  }

 
const Settings = () => {
   
    let registeredUser =  useContext(UserContext)
    const [password, setPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState<number>(0) 
      const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure()
     const [mobile] = useMediaQuery([
      "(max-width: 599px)",
    ]);
    
    const [setting, setSetting] = useState<Setting>(Setting.PHOTO)

    useEffect(()=>{
        setCount(0)
    },[isModalOpen])
    

     if (!registeredUser) {return null}

     
    let avatar = registeredUser.avatar ?? ""
    let name = ""
    //let password = registeredUser.partner_details.
    if (registeredUser.user_type === "partners") {
        name = registeredUser.partner_details?.name ?? ""
    } else {
        name = `${registeredUser.first_name}`
    }

    

    return registeredUser ? (
        <VStack paddingY={8} paddingX={4} h="100vh" width={!mobile ? "40%" : "full"} 
        margin="0 auto"
        pt={"72px"}
        //justifyContent="center" 
        alignItems="center"
        >
            <Text>{t("Account Settings")}</Text>
            {/* <Image w="200px" height="200px" src={avatar} /> */}
            <HStack w="full">
                <VStack alignItems="flex-start" w="full">
                  <Text>{t("Name")}</Text>
                  <Text textTransform="capitalize">{name}</Text>
                </VStack>
             </HStack>
            <Button 
             rounded="lg"
             display="flex" 
             justifyContent="space-between" 
             w="full" variant="outline" onClick={()=>{
                setSetting(Setting.PHOTO)
                onModalOpen()
            }} minH={"96px"}>
            {/* <HStack  > */}
                <VStack w="70%" alignItems="flex-start">
                    <Text>{t("Photo")}</Text>
                    <Text whiteSpace="pre-wrap" textAlign="start">{t("Add a photo to personalize your account")}</Text>
                </VStack>
                
                <Avatar 
                display="block"
            size="md"
            name={name} 
            src={avatar}
            />
             <MdChevronRight/>
            {/* </HStack> */}
            </Button>
           
            {/* <HStack w="full"> */}
            <Button w="full" 
                      rounded="lg"
                     onClick={()=>{
                        setSetting(Setting.PASSWORD)
                        onModalOpen()
                     }}
                   variant="outline" h={16}>
                <VStack alignItems="flex-start" w="full">
                  <Text>{t("Password")}</Text>
                  <Text textTransform="capitalize">{t("Update your password")}</Text>
                </VStack>
                <MdChevronRight/>
             </Button>
             <Modal isCentered size={mobile ? "md": "6xl"}  
             
          motionPreset={mobile? "slideInBottom" : "slideInRight"} 
            isOpen={isModalOpen} onClose={onModalClose}>
         <ModalOverlay />
        
         <ModalContent 
            
            //   position={mobile ? "fixed" : "absolute"}
            //  bottom={mobile ? "0px" : "unset"}
            //  mb={mobile  ? "0" : "unset"}
           // borderRadius={mobile ? "1.75rem 1.75rem 0px 0px" : "initial"}
            h={"344px"}
            rounded="20px" width={mobile ? "full" : "50%"}>
                 <ModalHeader>
                <Text className="subtitle1">{setting === Setting.PHOTO ? t("Add new a photo") : t("Update Your Password")}</Text>
            </ModalHeader>
           <ModalCloseButton paddingX={mobile ? 6 : 16} color="primary.500"/>
           <ModalBody>
                {
                    setting === Setting.PHOTO && 
                    <ProfilePhotoView onClose={onModalClose} count={count} 
                    user={registeredUser}
                    setIsLoading={(isLoading: boolean)=> setIsLoading(isLoading)} />
                }
                {
                     setting === Setting.PASSWORD &&
                     <EditPassword 
                     onClose={onModalClose}
                     user={registeredUser}
                    count={count}
                    setIsLoading={(isLoading: boolean)=> setIsLoading(isLoading)}   
                       
                  />
                }
               
               
           </ModalBody>
           <ModalFooter>
           
                 <Button
                   isLoading={isLoading}
                   colorScheme="secondary"
                   onClick={()=>{setCount(prev=> prev + 1)}}
                 >{t("Update")}</Button>
            
           </ModalFooter>
                       {/* <RegisterView isModalOpen rounded="md" /> */}
         </ModalContent>
       </Modal>
            {/* </HStack> */}
        </VStack>
    ):null
}

export default Settings

import React, { ReactNode, useContext, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import routes from '../../../routes'
import { UserContext } from '../../AppProvider/AppProvider'

interface ProtectedRouteProps {
  children: ReactNode
  path: string
}

const ProtectedRoute = ({children, ...rest}: ProtectedRouteProps) => {


    const user = useContext(UserContext)
    useEffect(() => {
        //    if (registeredUser) {
        //     setUserContext(registeredUser)
        //   } 
        
        
       
      }, [])

    return (
        <Route 
            exact
            {...rest}
            render={({location}) => {
                  //console.dir(registeredUser)
                  
                  let index = location.pathname.lastIndexOf('/')
                    let path = location.pathname.substr(index)
                 return !user || (user && user.user_type === "partners" && path === "/biz+account") ? (
                    //return !user ? (
                    children
                ): (
                    <Redirect 
                        to={{
                            pathname: routes.home.path, 
                            state: {from: location}
                        }}
                    />
                )
            }}
        />
    )
}

export default ProtectedRoute

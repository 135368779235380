import { VStack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation  } from "react-i18next";

const TandC = () => {
  const {t} = useTranslation()

  return (
    <VStack alignItems={"flex-start"}>
      {/* <p>
        <strong>TERMS &amp; CONDITIONS:</strong>
      </p> */}
      <p>
      {t("termsandcondtion")}
        {/* Welcome to the WorkHopper website or mobile properties, including
        related applications (collectively referred to as this,
        &ldquo;Website&rdquo;). This Website is provided only to assist people
        to gather information about coworking spaces, posting opinions about
        coworking related locations, products and services, and connecting as a
        remote working community, and for no other purposes.&nbsp; */}
      </p>
      <p>
        <strong>
        {t("termsdef")}
          {/* DEFINITION OF TERMS: */}
          </strong>
      </p>
      <p>
      {t("termsdef1")}
        {/* The terms &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo; and
        &ldquo;WorkHopper&rdquo; refer to WorkHopper a product of Pedestal
        Africa Ltd and/or our subsidiaries. The term &ldquo;you&rdquo; refers to
        the customer and/or partner visiting and making use of the Website, and
        as well as all content contributors on this Website. */}
      </p>
      <p>
        <strong>
        {t("termsdef2")}
          {/* AGREEMENT: */}
          </strong>
      </p>
      <p>{t("termsdef3")}

        {/* The services offered in this Website is offered to you upon the
        condition that your acceptance without modification of any/all the
        terms, conditions, and notices set forth below (collectively, the
        &ldquo;Agreement&rdquo;) shall reminding binding on you while in use of
        this website. By accessing or using this Website in any manner, you
        agree to be bound by the Agreement and represent that you have read and
        understood this terms of use before proceeding to use this website.
        Please, read the Agreement carefully, as it contains information
        concerning your legal rights, liabilities and limitations on these
        rights, as well as a section regarding applicable law and jurisdiction
        of disputes. If you do not accept all of these terms and conditions,
        please do not continue with the use of this Website. */}
      </p>
      <p>
      {t("termsdef4")}
        {/* We may change or otherwise modify the Agreement in the future, and you
        understand and agree that your continued access or use of this Website
        after such change signifies your acceptance of the updated or modified
        Agreement. We will note the date that revisions were last made to the
        Agreement at the bottom of this page, and any revisions will take effect
        upon posting. Be sure to return to this page periodically to review the
        most current version of the Agreement each time you might want to use
        this website. */}
      </p>
      <p>
        <strong>
        {t("termsdef5")}
          {/* USE OF THE WEBSITE: */}
          </strong>
      </p>
      <p>
      {t("termsdef6")}
        {/* As a condition of your use of this Website, you warrant that (i) all
        information supplied by you on this Website is true, accurate, current
        and complete, (ii) if you have a WorkHopper account, you will safeguard
        your account information and will supervise and be completely
        responsible for any use of your account by anyone other than you and
        (iii) you are 13 years of age or older in order to register and create
        for an account and/or contribute to our Website. WorkHopper does not
        knowingly collect the information of anyone under the age of 13. We
        retain the right at our sole discretion to deny access to anyone to this
        Website and the services we offer, at any time and for any reason,
        including, but not limited to, for violation of this Agreement. */}
      </p>
      <p>
        <strong>
        {t("termsdef7")}
          {/* PROHIBITED ACTIVITIES: */}
          </strong>
      </p>
      <p>
      {t("termsdef8")}
        {/* The content and information on this Website (including, but not limited
        to, messages, data, information, text, music, sound, photos, graphics,
        videos, maps, icons, software, code or other material), as well as the
        infrastructure used to provide such content and information, are the
        property of WorkHopper. You hereby agree not to otherwise modify, copy,
        distribute, transmit, display, perform, reproduce, publish, license,
        create derivative works from, transfer, or sell or re-sell any
        information, software, products, or services obtained from or through
        this Website without the written consent of WorkHopper. Additionally,
        you agree not to:&nbsp; */}
      </p>
      <ol>
        <li>
        {t("termsdef9")}
          {/* use this Website or its contents for any commercial purpose; */}
          </li>
        <li>
        {t("termsdef10")}
          {/* access, monitor or copy any content or information of this Website
          using any robot, spider, scraper or other automated means or any
          manual process for any purpose without our express written permission; */}
        </li>
        <li>
        {t("termsdef11")}
          {/* violate the restrictions in any robot exclusion headers on this
          Website or bypass or circumvent other measures employed to prevent or
          limit access to this Website; */}
        </li>
        <li>
        {t("termsdef12")}
          {/* take any action that imposes, or may impose, in our discretion, an
          unreasonable or disproportionately large load on WorkHopper&rsquo;s
          infrastructure. */}
        </li>
        <li>
        {t("termsdef13")}
          {/* deep-link to any portion of this Website for any purpose without our
          express written permission; or */}
        </li>
        <li>
        {t("termsdef14")}
          {/* "frame", "mirror" or otherwise incorporate any part of this Website
          into any other website without our prior written authorization. */}
        </li>
        <li>
        {t("termsdef15")}
          {/* attempt to modify, translate, adapt, edit, decompile, disassemble, or
          reverse engineer any software programs used by WorkHopper in
          connection with the Website or the services. */}
        </li>
        <li>
        {t("termsdef16")}
          {/* spam the comments sections or other spaces with unsolicited or
          unauthorized offers of goods and services, advertising, promotional
          material, junk mail, spam or chain letters, or inappropriate messages
          to other users of this website. */}
        </li>
        <li>
        {t("termsdef17")}
          {/* use the &ldquo;reservations&rdquo; feature for anything that is
          outside of legitimate money transmission for spaces to take
          reservations from customers booking desk and office space */}
        </li>
        <li>
        {t("termsdef18")}
          {/* solicit for spaces/partners listed on our website(s) with offers to
          join similar and/or competing service offering. */}
        </li>
      </ol>
      <p>
      {t("termsdef19")}
        {/* WorkHopper reserves the right to remove space listings, partner account
        or user account and terminate and remove unwarranted content or activity
        from the website without notice. WorkHopper reserves the right and may
        pursue legal action against perpetrators for damages caused by these and
        any other prohibited activities. */}
      </p>
      <p>
        <strong>
        {t("termsdef20")}
          {/* PRIVACY POLICY AND DISCLOSURES: */}
          </strong>
      </p>
      <p>
        WorkHopper believes in protecting your privacy. Please click here &nbsp;
        <a href="https://www.workhopper.co/privacy" style={{textDecoration: "underline"}} target="_blank">www.workhopper.co/privacy</a> to review our current Privacy Policy, which
        also governs your use of WorkHopper, to understand our practices.
      </p>
      <p>
      {t("termsbelieve1")}
        {/* &nbsp;In certain circumstances, we may disclose information about you to
        third parties if we determine, in our sole discretion that such
        disclosure is necessary; (a) to protect WorkHopper&rsquo;s systems,
        assets, employees, members and customers; (b) to ensure the integrity
        and operation of WorkHopper's business and systems; or (c) to comply
        with legitimate and enforceable governmental requests, subpoenas or
        court orders. We may choose to comply with or disregard such requests or
        orders in our sole discretion. WorkHopper&rsquo;s right to disclose
        information, including but not limited to user profile information (i.e.
        name, email address, etc.), IP addressing and traffic information, usage
        history, and posted Content, shall take precedence over any terms of
        WorkHopper&rsquo;s Privacy Policy. */}
      </p>
      <p>
        <strong>
        {t("termsbelieve2")}
          {/* REVIEWS, ENQUIRIES, COMMENTS AND USE OF OTHER INTERACTIVE AREAS IN THE
          WEBSITE: */}
        </strong>
      </p>
      <p>
      {t("termsbelieve3")}
        {/* Please be aware that by submitting content to this Website by email,
        postings on this Website or otherwise, including any coworking listing,
        reviews, questions, photographs or videos, comments, suggestions, ideas
        or the like contained in any submissions (collectively, "Submissions"),
        you grant WorkHopper and its affiliates a nonexclusive, royalty-free,
        perpetual, transferable, irrevocable and fully sub-licensable right to
        (a) use, reproduce, modify, adapt, translate, distribute, publish,
        create derivative works from and publicly display and perform such
        Submissions throughout the world in any media, now known or hereafter
        devised, for any purpose; and (b) use the name that you submit in
        connection with such Submission. You acknowledge that WorkHopper may
        choose to provide attribution of your comments or reviews at our
        discretion. You further grant WorkHopper the right to pursue at law any
        person or entity that violates your or WorkHopper's rights in the
        Submissions by a breach of this Agreement. You acknowledge and agree
        that Submissions are non-confidential and non-proprietary. Spaces whom a
        representative of theirs &ldquo;claim&rdquo; their space in which the
        content was submitted by a 3rd party contributor also grant WorkHopper
        and its affiliates the same rights listed above. */}
      </p>
      <p>
      {t("termsbelieve4")}
        {/* Note that this Website may contain discussion forums, bulletin boards,
        review services or other forums in which you or third parties may post
        reviews of coworking experiences or other content, messages, materials
        or other items on this Website ("Interactive Areas"). If WorkHopper
        provides such Interactive Areas, you are solely responsible for your use
        of such Interactive Areas and use them at your own personal risk. By
        using any Interactive Areas, you expressly agree not to post, upload to,
        transmit, distribute, store, create or otherwise publish through this
        Website any of the following: */}
      </p>
      <ol>
        <li>
        {t("termsbelieve5")}
          {/* Any message, data, information, text, music, sound, photos, graphics,
          code or any other material ("Content") that is unlawful, libelous,
          defamatory, obscene, pornographic, indecent, lewd, suggestive,
          harassing, threatening, invasive of privacy or publicity rights,
          abusive, inflammatory, fraudulent or otherwise objectionable; */}
        </li>
        <li>
        {t("termsbelieve6")}
          {/* Content that would constitute, encourage or provide instructions for a
          criminal offense, violate the rights of any third party, or that would
          otherwise create liability or violate any local, state, national or
          international law, including, without limitation to the regulations of
          the Securities of the Federal Republic of Nigeria and Securities and
          Exchange Commission (SEC) or any rules of a securities exchange etc. */}
        </li>
        <li>
        {t("termsbelieve7")}
          {/* Content that may infringe any patent, trademark, trade secret,
          copyright or other intellectual property or proprietary right of any
          third party; */}
        </li>
        <li>
        {t("termsbelieve8")}
          {/* Content that impersonates any person or entity or otherwise
          misrepresents your affiliation with a person or entity, including
          WorkHopper; */}
        </li>
        <li>
        {t("termsbelieve9")}
          {/* Unsolicited promotions, political campaigning, advertising, contests,
          raffles, or solicitations; */}
        </li>
        <li>
        {t("termsbelieve10")}
          {/* Private information of any third party, including, without limitation,
          surname (family name) addresses, phone numbers, email addresses,
          Social Security numbers and credit card numbers; */}
        </li>
        <li>
        {t("termsbelieve11")}
          {/* Viruses, corrupted data or other harmful, disruptive or destructive
          files; */}
        </li>
        <li>
        {t("termsbelieve12")}
          {/* Content that is unrelated to the topic of the Interactive Area(s) in
          which such Content is posted; or */}
        </li>
        <li>
        {t("termsbelieve13")}
          {/* Content or links to content that, in the sole judgment of WorkHopper,
          (a) violates the previous subsections herein, (b) is objectionable,
          (c) which restricts or inhibits any other person from using or
          enjoying the Interactive Areas or this Website, or (d) which may
          expose WorkHopper or its affiliates or its users to any harm or
          liability of any type. */}
        </li>
      </ol>
      <p>
        <strong>
        {t("termsbelieve14")}
          {/* LIABILITY OF WORKHOPPER: */}
          </strong>
      </p>
      <p>
      {t("termsbelieve15")}
        {/* WorkHopper takes no responsibility and assumes no liability for any
        Content posted, stored or uploaded by you or any third party, or for any
        loss or damage thereto, nor is WorkHopper liable for any mistakes,
        defamation, slander, libel, omissions, falsehoods, obscenity,
        pornography or profanity you may encounter while in use of this website.
        As a provider of interactive services, WorkHopper is not liable for any
        statements, representations or Contents provided by its users in any
        public forum, personal home page or other Interactive Areas. Although
        WorkHopper has no obligation to screen, edit or monitor any of the
        Content posted to or distributed through any Interactive Area,
        WorkHopper reserves the right, and has absolute discretion, to remove,
        screen, translate or edit without notice any content posted or stored on
        this Website at any time and for any reason, or to have such actions
        performed by third parties on its behalf, and you are solely responsible
        for creating backup copies of and replacing any content you post or
        store on this Website at your sole cost and expense. */}
      </p>
      <p>
      {t("termsbelieve16")}
        {/* Also, if it is determined that you retain any moral rights (including
        rights of attribution or integrity) in the content, you hereby declare
        that;&nbsp; */}
      </p>
      <ol>
        <li>
        {t("termsbelieve17")}
          {/* you do not require that any personally identifying information be used
          in connection with the Content, or any derivative works or upgrades or
          updates thereto;&nbsp; */}
        </li>
        <li>
        {t("termsbelieve18")}
          {/* you have no objection to the publication, use, modification, deletion
          and exploitation of the content by WorkHopper or its licensees,
          successors and assigns;&nbsp; */}
        </li>
        <li>
        {t("termsbelieve19")}
          {/* you forever waive and agree not to claim or assert any entitlement to
          any and all moral rights of an author in any of the content; and&nbsp; */}
        </li>
        <li>
        {t("termsbelieve20")}
          {/* you perpetually release WorkHopper, and its licensees, successors,
          agents, privies and assigns, from any claims that you could otherwise
          assert against WorkHopper by virtue of any such moral rights. */}
        </li>
      </ol>
      <p>
      {t("termsbelieve21")}
        {/* Note further that any use of the Interactive Areas or other portions of
        this Website in violation of the foregoing violates the terms of this
        Agreement and may result in, among other things, termination, or
        suspension of your rights to use the Interactive Areas and/or this
        Website. */}
      </p>
      <p>
        <strong>
        {t("termsaccount")}
          {/* MAINTAINING A COWORKING SPACE ACCOUNTS: */}
          </strong>
      </p>
      <p>
      {t("termsaccount1")}
        {/* All information submitted in connection with a maintaining a Coworking
        Space account (each hereinafter referred to as, a &ldquo;Space
        Account&rdquo;) must be accurate and truthful. Users hereby undertake to
        notify WorkHopper immediately if their Space Account has been used
        without authorization or there has been any other breach of security of
        their Space Account. Each User also agrees to provide additional
        information WorkHopper may reasonably request and to answer truthfully
        and completely any questions WorkHopper might ask you in order to verify
        such authenticity of the Space account. Please be aware that by
        submitting content to this Website by email, postings on this Website or
        otherwise, including any coworking reviews, questions, photographs or
        videos, comments, suggestions, ideas or the like contained in any
        submissions (collectively, "Submissions"), you grant WorkHopper and its
        affiliates a nonexclusive, royalty-free, perpetual, transferable,
        irrevocable and fully sub-licensable right to do the following viz; */}
      </p>
      <ol>
        <li>
        {t("termsaccount2")}
          {/* use, reproduce, modify, adapt, translate, distribute, publish, create
          derivative works from and publicly display and perform such
          Submissions throughout the world in any media, now known or hereafter
          devised, for any purpose; and&nbsp; */}
        </li>
        <li>
        {t("termsaccount3")}
          {/* use the name that you submit in connection with such Submission. You
          acknowledge that WorkHopper may choose to provide attribution of your
          comments or reviews at our discretion. You further grant WorkHopper
          the right to pursue at law any person or entity that violates your or
          WorkHopper's rights in the Submissions by a breach of this Agreement.
          You acknowledge and agree that Submissions of space content are
          non-confidential and nonproprietary. Spaces whom a representative of
          theirs &ldquo;claim&rdquo; their space in which the content was
          submitted by a 3rd party contributor also grant WorkHopper and its
          affiliates the same rights listed above. Further Spaces agree and
          understand that: */}
        </li>
      </ol>
      <ol>
        <li>
        {t("termsaccount4")}
          {/* Spaces are only entitled to one (1) space listing (&ldquo;page&rdquo;)
          per location/internet protocol (IP) address. Duplicate space listings
          will be removed without further notice. */}
        </li>
        <li>
        {t("termsaccount5")}
          {/* Spaces may only use the &ldquo;reservations&rdquo; feature for the
          legitimate money transmission of facilitating reservations from
          customers booking desk and office space. Any other purposes are
          strictly prohibited */}
        </li>
        <li>
        {t("termsaccount6")}
          {/* Space submissions to the site using the &ldquo;Add Your Space&rdquo;
          feature will be approved at the sole discretion of WorkHopper. If
          information is missing, WorkHopper will make a reasonable attempt to
          request information for the space. Submissions that are not deemed a
          &ldquo;coworking space&rdquo; may not be approved for publication on
          the site. Coworking spaces are generally defined by WorkHopper to have
          shared workspace for community, shared amenities for customers, and
          paid passes and membership options of less than 6-month terms.
          WorkHopper reserves the right to approve, disapprove, and remove
          listings at their sole discretion notwithstanding if the space meets
          the above criteria. */}
        </li>
        <li>
        {t("termsaccount7")}
          {/* the basic space page listing is free. Spaces may enable bookings and
          subscribe to Marketing Services (&ldquo;Premium Plans&rdquo;) for a
          fee and commission payable only to WorkHopper. The spaces will agree
          to separate and additional terms when enabling these services. */}
        </li>
        <li>
        {t("termsaccount8")}
          {/* WorkHopper employs sales personnel and works with commercial broker
          partners to provide a full range of office search services for
          corporate users. WorkHopper captures leads and in certain instances
          works directly with the customer and coworking space operators to find
          a suitable solution. Space Operators may choose to participate in this
          broker placement program by signing an agreement with us and/or our
          broker partners. */}
        </li>
        <li>
        {t("termsaccount9")}
          {/* WorkHopper is not liable for any damages that may be incurred from
          content on the Website or its affiliates including but not limited to
          reviews, photos, descriptions, and other forms of media which may have
          opinions of third parties. */}
        </li>
        <li>
        {t("termsaccount10")}
          {/* Spaces may not take legal action against WorkHopper, Users or third
          parties for their opinions in the form of media (text, photos,
          reviews, comments) created and published on the site. */}
        </li>
        <li>
        {t("termsaccount11")}
          {/* Spaces that would like their listings permanently removed from the
          site, may request to do so for any administrative fee that may be
          imposed by WorkHopper from time to time. This fee goes to cover the
          labor, hosting, and administrative costs in supporting the profile and
          delisting. */}
        </li>
      </ol>
      <p>
        <strong>
        {t("termsaccount12")}
          {/* GLOBAL PASS MEMBERS: */}
          </strong>
      </p>
      <ol>
        <li>
        {t("termsaccount13")}
          {/* Each member must have their own Global Pass membership. */}
          </li>
        <li>
        {t("termsaccount14")}
          {/* Global Pass credits issued do not have an expiration date. Purchased
          credits are non-refundable and non-transferable to third parties. */}
        </li>
        <li>
        {t("termsaccount15")}
          {/* The maximum number of days you can use a Global Pass credit at a
          single space per month is determined by each individual space, which
          is advertised on the space's WorkHopper listing. */}
        </li>
        <li>
        {t("termsaccount16")}
          {/* Global Pass members will have access to spaces who are a part of the
          Global Pass network during the space's reception hours. Additional
          hours granted outside of reception hours are at the discretion of the
          space operator. */}
        </li>
        <li>
        {t("termsaccount17")}
          {/* Reservations via Global Pass must be made in advance. The specific
          time in advance is set by the space, and ranges from 1 - 24hr in
          advance. We recommend reserving a space at least one day in advance to
          allow the space to prepare your seat and better accommodate you. */}
        </li>
        <li>
        {t("termsaccount18")}
          {/* At a minimum, Global Pass members will receive access to lounge areas
          and basic amenities (Wi-Fi, free tea/coffee, etc.) that the space has
          to offer. Anything in addition (access to meeting rooms, private
          offices, or other add-on services) are to be coordinated with the
          space directly. */}
        </li>
        <li>
        {t("termsaccount19")}
          {/* Global Pass members are to abide by the health, safety, and community
          guidelines/protocols as defined by the space. */}
        </li>
        <li>
        {t("termsaccount20")}
          {/* Certain spaces may enforce a dress code and have etiquette policies,
          so please pay attention to this prior to check-in. */}
        </li>
      </ol>
      <p>
        <strong>
        {t("termsdispute")}
          {/* DISPUTES BETWEEN COWORKING SPACES AND USERS: */}
          </strong>
      </p>
      <p>
      {t("termsdispute1")}
        {/* Coworking Spaces are bound to honor the rates, pricing, tours, days
        passes and bookings listed and facilitated through the Website.
        WorkHopper does not recognize any third party and/or agency affiliated
        with the Spaces as a Space Owner. If a Space is unable to fulfill any
        promise or reservation, the Space will work with the Customers to reach
        a mutually satisfactory resolution, which may include the issuance of a
        refund to Customers by the Space. WorkHopper is under no obligation to
        become involved in disputes between Spaces and Customers, or Users and
        any third party. In the event of any dispute, we may provide the Space
        Owner's contact information to the Customer so that the two parties may
        resolve their dispute. */}
      </p>
      <p>
        <strong>
          THIRD-PARTY SUPPLIERS:
          </strong>
      </p>
      <p>
      {t("termsdispute2")}
        {/* WorkHopper is not a travel agency and does not provide or own coworking
        spaces, locations or events. Although WorkHopper displays information
        about properties owned by third-party suppliers and facilitates leads
        and in some cases reservations with certain suppliers and affiliate
        sites, such actions do not in any way imply, suggest, or constitute
        WorkHopper&rsquo;s sponsorship, endorsement or approval of third-party
        suppliers, or any affiliation between WorkHopper and third-party
        suppliers, partners or holders of Space Accounts. Although WorkHopper
        members may rate and review particular properties based on their own
        personal experiences, WorkHopper does not endorse or recommend the
        products or services of any third-party suppliers. You agree that
        WorkHopper is not responsible for the accuracy or completeness of
        information it obtains from third-party suppliers and displays on its
        sites or apps. You agree that WorkHopper shall deduct 1% of each 
        payment as commission every time
        a partner receives payment for a booking. */}
      </p>
      <p>
      {t("termsdispute3")}
        {/* If you engage with a third-party supplier, you agree to review and be
        bound by the supplier&rsquo;s terms and conditions of purchase and site
        use (the &ldquo;Terms of Use&rdquo;), Privacy Policy, and any other
        rules or policies related to the supplier&rsquo;s site or property. Your
        interactions with third-party suppliers are at your own risk. WorkHopper
        will have no liability with respect to the acts, omissions, errors,
        representations, warranties, breaches or negligence of any third-party
        suppliers or for any personal injuries, death, property damage, or other
        damages or expenses resulting from your interactions with third-party
        suppliers. */}
      </p>
      <p>
      {t("termsdispute4")}
        {/* This Website may link you to supplier sites or other websites that
        WorkHopper does not operate or control. For further information, please
        refer to the &ldquo;Links to Third-Party Sites&rdquo; section below. */}
      </p>
      <p>
        <strong>
        {t("termsdispute5")}
          {/* DISCLAIMER AND LIMITATION OF LIABILITIES: */}
          </strong>
      </p>
      <p>
      {t("termsdispute6")}
        {/* PLEASE READ THIS SECTION CAREFULLY. THIS SECTION LIMITS
        WORKHOPPER&rsquo;S LIABILITY TO YOU FOR ISSUES THAT MAY ARISE IN
        CONNECTION WITH YOUR USE OF THIS WEBSITE. IF YOU DO NOT UNDERSTAND THE
        TERMS IN THIS SECTION OR ELSEWHERE IN THE AGREEMENT, PLEASE CONSULT A
        LAWYER FOR CLARIFICATION BEFORE ACCESSING OR USING THIS WEBSITE. */}
      </p>
      <p>
      {t("termsdispute7")}
        {/* THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES PUBLISHED ON THIS
        WEBSITE MAY INCLUDE INACCURACIES OR ERRORS, INCLUDING SPACE AVAILABILITY
        AND PRICING ERRORS. WORKHOPPER, ITS SUBSIDIARIES AND CORPORATE
        AFFILIATES, DO NOT GUARANTEE THE ACCURACY OF, AND DISCLAIM ALL LIABILITY
        FOR, ANY ERRORS OR OTHER INACCURACIES RELATING TO THE INFORMATION AND
        DESCRIPTION OF THE COWORKING SPACE, OR LOCATIONS DISPLAYED ON THIS
        WEBSITE (INCLUDING, WITHOUT LIMITATION TO THE PRICING, AVAILABILITY,
        PHOTOGRAPHS, LIST OF COWORKING SPACES, REVIEWS AND RATINGS, ETC.). IN
        ADDITION, WORKHOPPER EXPRESSLY RESERVES THE RIGHT TO CORRECT ANY
        AVAILABILITY AND PRICING ERRORS ON OUR WEBSITE AND/OR ON PENDING
        RESERVATIONS MADE UNDER AN INCORRECT PRICE. WORKHOPPER MAKES NO
        REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION, SOFTWARE,
        PRODUCTS, AND SERVICES CONTAINED ON THIS WEBSITE FOR ANY PURPOSE, AND
        THE INCLUSION OR OFFERING OF ANY PRODUCTS OR SERVICES ON THIS WEBSITE
        DOES NOT CONSTITUTE ANY ENDORSEMENT OR RECOMMENDATION OF SUCH PRODUCTS
        OR SERVICES BY WORKHOPPER. ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, AND
        SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. WORKHOPPER
        DISCLAIM ALL WARRANTIES AND CONDITIONS THAT THIS WEBSITE, ITS SERVERS OR
        ANY EMAIL SENT FROM WORKHOPPER, ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS. WORKHOPPER HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS
        WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES,
        INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. THE
        THIRD-PARTY SUPPLIERS PROVIDING COWORKING SPACE OR LOCATION INFORMATION,
        DESK SPACE OR OTHER SERVICES ON THIS WEBSITE ARE INDEPENDENT CONTRACTORS
        AND NOT AGENTS OR EMPLOYEES OF WORKHOPPER COMPANIES. WORKHOPPER IS NOT
        LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES,
        BREACHES OR NEGLIGENCE OF ANY SUCH SUPPLIERS OR FOR ANY PERSONAL
        INJURIES, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES RESULTING
        THEREFROM. WORKHOPPER HAS NO LIABILITY AND WILL MAKE NO REFUND IN THE
        EVENT OF ANY DELAY, CANCELLATION, OVERBOOKING, STRIKE, FORCE MAJEURE OR
        OTHER CAUSES BEYOND THEIR DIRECT CONTROL, AND THEY HAVE NO
        RESPONSIBILITY FOR ANY ADDITIONAL EXPENSE, OMISSIONS, DELAYS, RE-ROUTING
        OR ACTS OF ANY GOVERNMENT OR AUTHORITY. */}
      </p>
      <p>
      {t("termsdispute8")}
        {/* IN NO EVENT SHALL WORKHOPPER (OR THEIR OFFICERS, DIRECTORS AND
        AFFILIATES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
        SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF, OR IN ANY WAY
        CONNECTED WITH, YOUR ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE OR
        WITH THE DELAY OR INABILITY TO ACCESS, DISPLAY OR USE THIS WEBSITE
        (INCLUDING, BUT NOT LIMITED TO, YOUR RELIANCE UPON REVIEWS AND OPINIONS
        APPEARING ON THIS WEBSITE; ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE,
        LINKED SITES, PRODUCTS, AND SERVICES OBTAINED THROUGH THIS WEBSITE; OR
        OTHERWISE ARISING OUT OF THE ACCESS TO THE DISPLAY OF OR USE OF THIS
        WEBSITE) WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT
        LIABILITY, OR OTHERWISE, AND EVEN IF WORKHOPPER HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. */}
      </p>
      <p>
      {t("termsdispute9")}
        {/* If WorkHopper is found liable for any loss or damage that arises out of
        or is in any way connected with your use of our sites or services, then
        WorkHopper&rsquo;s liabilities will in no event exceed, in the
        aggregate, the greater of (a) the transaction fees paid to WorkHopper
        for the transaction(s) on this Website giving rise to the claim, or (b)
        Fifty Dollars (US $50.00) whichever is lesser. */}
      </p>
      <p>
      {t("termsdispute10")}
        {/* Note that the limitation of liability reflects the allocation of risk
        between the parties. The limitations specified in this section will
        survive and apply even if any limited remedy specified in these terms is
        found to have failed of its essential purpose. The limitations of
        liability provided in these terms inure to the benefit of WorkHopper. */}
      </p>
      <p>
        <strong>
        {t("termsdispute11")}
          {/* INDEMNITY: */}
          </strong>
      </p>
      <p>
      {t("termsdispute12")}
        {/* You agree to defend and indemnify WorkHopper and its affiliates and any
        of their officers, directors, employees and agents from and against any
        claims, causes of action, demands, recoveries, losses, damages, fines,
        penalties or other costs or expenses of any kind or nature including but
        not limited to reasonable legal and accounting fees, brought by third
        parties as a result of: */}
      </p>
      <ol>
        <li>
        {t("termsdispute13")}
          {/* your breach of this Agreement or the documents referenced herein; */}
        </li>
        <li>
        {t("termsdispute14")}
          {/* your violation of any law or the rights of a third party; or */}
          </li>
        <li>
        {t("termsdispute15")}
          {/* your general use of this Website. */}
          </li>
      </ol>
      <p>
        <strong>
        {t("termsdispute16")}
          {/* LINKS TO THIRD-PARTY SITES: */}
          </strong>
      </p>
      <p>
      {t("termsdispute17")}
        {/* This Application Website may contain hyperlinks to websites operated by
        parties other than WorkHopper. Such hyperlinks are provided for your
        reference only. We do not control such websites and are not responsible
        for their contents or the privacy or other practices of such websites.
        Further, it is up to you to take precautions to ensure that whatever
        links you select or software you download (whether from this Website or
        other websites) is free of such items as viruses, worms, trojan horses,
        defects and other items of a destructive nature. Our inclusion of
        hyperlinks to such websites does not imply any endorsement of the
        material on such websites or any association with their operators. In
        some cases, you may be asked by a third-party site to link your profile
        on WorkHopper to a profile on another third-party site. Choosing to do
        so is purely optional, personal, and the decision to allow this
        information to be linked can be disabled (with the third-party site) at
        any time. */}
      </p>
      <p>
        <strong>
        {t("termsdispute18")}
          {/* SOFTWARE AVAILABLE ON THIS WEBSITE: */}
          </strong>
      </p>
      <p>
      {t("termsdispute19")}
        {/* Unless otherwise specified, the materials on this and related websites
        are presented solely to provide information regarding and to promote
        WorkHopper&rsquo;s services, websites, partners and other products
        available in Nigeria and within the African market, its territories,
        possessions and protectorates. The WorkHopper websites are controlled
        and operated by WorkHopper from its remote offices. Those who choose to
        access the WorkHopper website from outside of the Nigeria do so on their
        own initiative and are responsible for compliance with local laws, if
        and to the extent that local laws are applicable. Software from the
        WorkHopper websites is further subject to Nigerian export controls. By
        using the WorkHopper websites, you represent and warrant that you are
        not located in, under the control of, or a national or resident of any
        country which is under the Nigeria national security watch list. */}
      </p>
      <p>
      {t("termsdispute20")}
        {/* Any software that is made available to download from the WorkHopper
        websites ("Software") is the copyrighted work of WorkHopper, or
        WorkHopper affiliates, or other third-party software as identified. Your
        use of such Software is governed by the terms of the end user license
        agreement, if any, which accompanies, or is included with, the Software
        ("License Agreement"). You may not install or use any Software that is
        accompanied by or includes a License Agreement unless you first agree to
        the License Agreement terms. For any Software made available for
        download on this Website not accompanied by a License Agreement, we
        hereby grant to you, the user, a limited, personal, non-transferable
        license to use the Software for viewing and otherwise using this Website
        in accordance with these terms and conditions and for no other purpose. */}
      </p>
      <p>
      {t("termsdispute21")}
        {/* Please note that all Software, including, without limitation to all
        HTML, XML, Java code and Active X controls contained on this Website, is
        owned by WorkHopper and/or its affiliates, and is protected by copyright
        laws and international treaty provisions. Any reproduction or
        redistribution of the Software is expressly prohibited, and may result
        in severe civil and criminal penalties. Violators will be prosecuted to
        the maximum extent possible. */}
      </p>
      <p>
      {t("termsdispute22")}
        {/* WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE
        TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR
        REDISTRIBUTION IS EXPRESSLY PROHIBITED. THE SOFTWARE IS WARRANTED, IF AT
        ALL, ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT. */}
      </p>
      <p>
        <strong>
        {t("collections")}
          </strong>
      </p>
      <p>
      {t("collections1")}
        {/* By using WorkHopper, you authorize WorkHopper to access and aggregate
        users and browsing data stored on or transmitted through the website use
        and services to conduct research, improve the services, work with
        commercial partners, and provide anonymous reporting for internal and
        external clients, customers and partners. */}
      </p>
      <p>
        <strong>
        {t("collections2")}
          {/* THIRD PARTY ADVERTISERS: */}
          </strong>
      </p>
      <p>
      {t("collections3")}
        {/* Certain advertising information may be displayed through the services at
        WorkHopper&rsquo;s sole discretion. The display of advertising
        information shall not entitle You to any advertising fees or revenues
        from such advertising unless otherwise explicitly agreed to in writing
        by WorkHopper. Your correspondence or business dealings with, or
        participation in promotions of, advertisers found on or through the
        WorkHopper, including payment and delivery of related goods or services,
        and any other terms, conditions, warranties or representations
        associated with such dealings, are solely between You and such
        advertiser. You agree that WorkHopper shall not be responsible or liable
        for any loss or damage of any sort incurred as the result of any such
        dealings or as the result of the presence of such advertisers on the
        WorkHopper. */}
      </p>
      <p>
        <strong>
        {t("collections4")}
          {/* PAYMENTS &amp; BILLING: */}
          </strong>
      </p>
      <p>
      {t("collections5")}
        {/* THIS APPLIES TO PAYING CUSTOMERS, CLIENTS, AND PARTNERS THAT HAVE
        SERVICES OR PRODUCTS FROM WORKHOPPER INCLUDING REOCCURING SUBSCRIPTIONS.
        You or the person designated as the billing contact ("Billing Contact"),
        for the product/service opted into must provide WorkHopper with a valid
        credit or debit card in order to activate Your account and service.
        Billing Contact authorize WorkHopper to automatically charge a credit
        card for all services that You (and/or Your representatives) have
        request. If your service is setup for a
        re-occurring payment, WorkHopper will automatically charge your card for
        the service within 14 days of the re-occurring billing cycle.
        Alternatively, the Billing Contact can arrange for pre-payment of
        services for a longer duration, and in certain cases approved by
        WorkHopper, alternative forms of payment may be accepted. Prepaid
        amounts of any duration are not refundable for any reason including but
        not limited to closure of business, change or upgrade of service,
        bankruptcy, website down time, or other scenarios in which a cancel of
        service is requested. Billing Contact will receive a receipt for all
        payments processed against the credit card or account by email and/or
        available in their online account profile. If paying by credit card,
        Billing Contact agrees to keep credit card information updated, and to
        provide WorkHopper with a new valid account number if the old card is
        deactivated or does not have sufficient credit available to pay your
        fees. WorkHopper reserves the right to place Your account on hold,
        suspend Your services and/or terminate this agreement if You are overdue
        in paying any amounts due for services you requested. If You wish to
        dispute any fee WorkHopper charged to Billing Contact's credit card, you
        agree to discuss such dispute with WorkHopper and work towards a
        resolution. You expressly agree to not dispute fees with Your issuing
        credit card company, which could result in a "charge back" to
        WorkHopper. Declined, refused and/or returned payments may result in a
        $100.00 service charge payable immediately. In certain cases, WorkHopper
        and clients and/or partners may agree to a separate services agreement
        with additional billing and payment terms that adhere to advertising and
        marketing services. In any scenario of terms in addition to those
        outlines above, an agreement will be provided for dual signing between
        the parties involved and explicitly outline additional terms to adhere
        to. */}
      </p>
      <p>
        <strong>
        {t("collections6")}
          {/* MODIFICATIONS TO THIS AGREEMENT: */}
          </strong>
      </p>
      <p>
      {t("collections7")}
        {/* WorkHopper may modify this agreement at any time. Any modification is
        effective upon the posting of same by WorkHopper on its website.
        WorkHopper may also notify You of any of such modifications by email
        correspondence to You. */}
      </p>
      <p>
        <strong>
        {t("collections8")}
          {/* INTELLECTUAL PROPERTY RIGHTS: COPYRIGHT AND TRADEMARK NOTICES: */}
        </strong>
      </p>
      <p>
      {t("collections9")}
        {/* All contents of this Website are the copyright work of: &copy;2021
        WorkHopper and its parent company. All rights are reserved. WorkHopper
        is not responsible for content on websites operated by parties other
        than WorkHopper. &ldquo;WorkHopper&rdquo;, and all its slogans and
        pay-offs, the graphic logo and logo adaptations, including moving
        images, and all other product or service names or slogans displayed on
        this Website are registered and/or common law trademarks of WorkHopper
        Inc and/or its suppliers or licensors, and may not be copied, imitated
        or used, in whole or in part, without the prior written permission of
        WorkHopper or the applicable trademark holder. In addition, the look and
        feel of this Website, including all page headers, custom graphics,
        button icons and scripts, is the service mark, trademark and/or trade
        dress of WorkHopper and may not be copied, imitated or used either in
        whole or in part, without the prior written permission of WorkHopper.
        All other trademarks, registered trademarks, product names and company
        names or logos mentioned in this Website are the property of their
        respective owners. Reference to any products, services, processes or
        other information, by trade name, trademark, manufacturer, supplier or
        otherwise does not constitute or imply endorsement, sponsorship or
        recommendation thereof by WorkHopper. */}
      </p>
      <p>
      {t("collections10")}
        {/* If you are aware of an infringement of either your brand or our brand,
        please let us know by e-mailing us at info@WorkHopper.com. We only
        address messages concerning brand infringement at this email address. */}
      </p>
      <p>
        <strong>
        {t("collections11")}
          {/* JURISDICTION AND GOVERNING LAW: */}
          </strong>
      </p>
      <p>
      {t("collections12")}
        {/* This Website and related WorkHopper websites and digital manifestations
        are operated by a Nigerian entity entity and this Agreement is governed
        by the laws of the Federal Republic of Nigeria. You hereby consent to
        the exclusive jurisdiction and venue of courts in Nigeria and stipulate
        to the fairness and convenience of proceedings in such courts for all
        disputes arising out of or relating to the use of this Website. You
        agree that all claims you may have against WorkHopper arising from or
        relating to this Website must be heard and resolved in a court of
        competent subject matter jurisdiction Nigeria. Use of this Website is
        unauthorized in any jurisdiction that does not give effect to all
        provisions of these terms and conditions, including, without limitation,
        this paragraph. */}
      </p>
      <p>
        <strong>
        {t("collections13")}
          {/* CURRENCY CONVERTER: */}
          </strong>
      </p>
      <p>
      {t("collections14")}
        {/* Currency rates are based on various publicly available sources and
        should be used as guidelines only. Rates are not verified as accurate,
        and actual rates may vary. Currency quotes may not be updated on a daily
        basis. The information supplied by this application is believed to be
        accurate, but WorkHopper, and/or its affiliates do not warrant or
        guarantee such accuracy. When using this information for any financial
        purpose, we advise you to consult a qualified professional to verify the
        accuracy of the currency rates. We do not authorize the use of this
        information for any purpose other than your personal use and you are
        expressly prohibited from the resale, redistribution, and use of this
        information for commercial purposes.&nbsp; */}
      </p>
      <p>
        <strong>
        {t("collections15")}
          {/* ADDITIONAL MOBILE LICENSES: */}
          </strong>
      </p>
      <p>
      {t("collections16")}
        {/* Note further that portion of WorkHopper mobile software may use
        copyrighted material, the use of which WorkHopper acknowledges. In
        addition, there are specific terms that apply to use of certain
        WorkHopper mobile applications. */}
      </p>
      <p>
        <strong>
        {t("collections17")}
          {/* GENERAL PROVISIONS: */}
          </strong>
      </p>
      <p>
      {t("collections18")}
        {/* You agree that no joint venture, agency, partnership, or employment
        relationship exists between you and WorkHopper and/or affiliates as a
        result of this Agreement or use of this Website. */}
      </p>
      <p>
      {t("collections19")}
        {/* Our performance of this Agreement is subject to existing laws and legal
        process, and nothing contained in this Agreement limits our right to
        comply with law enforcement or other governmental or legal requests or
        requirements relating to your use of this Website or information
        provided to or gathered by us with respect to such use. To the extent
        allowed by applicable law, you agree that you will bring any claim or
        cause of action arising from or relating to your access or use of this
        Website within two (2) years from the date on which such claim or action
        arose or accrued or such claim or cause of action will be irrevocably
        waived. */}
      </p>
      <p>
        <strong>
        {t("collections20")}
          {/* SEVERANCE OF AGREEMENT: */}
          </strong>
      </p>
      <p>
      {t("collections21")}
        {/* If any part of this Agreement is determined/declared by any competent
        tribunal, courts or quasi tribunal having such powers to be invalid or
        unenforceable pursuant to applicable law including, but not limited to,
        the warranty disclaimers and liability limitations set forth above, then
        the invalid or unenforceable provision will be deemed superseded by a
        valid, enforceable provision that most closely matches the intent of the
        original provision and the remaining provisions in the Agreement shall
        continue in effect. */}
      </p>
      <p>
        <strong>
        {t("collections22")}
          {/* THE ENTIRE AGREEMENT:&nbsp; */}
          </strong>
      </p>
      <p>
      {t("collections23")}
        {/* This Agreement (and any other terms and conditions referenced herein)
        constitutes the entire agreement between you and WorkHopper with respect
        to this Website and it supersedes all prior or contemporaneous
        communications and proposals, whether electronic, oral, or written,
        between the customer and WorkHopper with respect to this Website. A
        printed version of this Agreement and of any notice given in electronic
        form shall be admissible in judicial or administrative proceedings based
        upon or relating to this Agreement to the same extent and subject to the
        same conditions as other business documents and records originally
        generated and maintained in printed form. */}
      </p>
      <p>
      {t("collections24")}
        {/* &nbsp;Fictitious names of companies, products, people, characters,
        and/or data mentioned on this Website are not intended to represent any
        real individual, company, product, or event. */}
      </p>
      <p>
      {t("collections25")}
        {/* &nbsp;Any rights not expressly granted herein are reserved. */}
        </p>
    </VStack>
  );
};

export default TandC;

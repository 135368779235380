import * as Yup from 'yup'


export const yupDescriptionValidation = Yup.object().shape({

    name: Yup.string()
            .required("Name must not be empty")
            .min(2, "Name must not be below 2 characters"),
            //.max(50, "Firstname must not exceed 50 characters"),


      desc: Yup.string()
            .required("Description must not be empty")
            .min(20, "Description must not be below 20 characters"),
            //.max(500, "Lastname must not exceed 50 characters"),

})

 

export const yupContactValidation = Yup.object().shape({

    workspaceEmail: Yup
            .string()
            .required("Please enter your email")
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Please enter a valid email"
            ),

        websiteURL: Yup
            .string()
            .matches(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig
            )
            // .matches(
            //     /https?\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}/g,
            //     "Please enter a valid business website url"
            // )

})

export const yupLocationValidation = Yup.object().shape({

    street: Yup.string()
            .required("Street must not be empty"),
             //.max(50, "Firstname must not exceed 50 characters"),

    city: Yup.string()
             .required("City must not be empty"),

    zipCode: Yup.string(),
    
    state: Yup.string().required("Please select a state"),   

    
             //.max(500, "Lastname must not exceed 50 characters"),
    countryRegion: Yup.string().required("Please select a country")   

})

export const yupHOPValidation = Yup.object().shape({

    from: Yup.string()
            .required("Please select an opening time"),
             //.max(50, "Firstname must not exceed 50 characters"),

    to: Yup.string()
             .required("Please select an closing time"),

    satOpened: Yup.boolean(),
            // .required("Name must not be empty"),
    satTo: Yup.string().when('satOpened', {
        is: (value: boolean) => value === true,  
        then: Yup.string().required("Please select an closing time"),
        otherwise: Yup.string().nullable()
    }),
    satFrom: Yup.string().when('satOpened', {
        is: (value: boolean) =>  value === true,  //Boolean(value),
        then: Yup.string().required("Please select an opening time"),
        otherwise: Yup.string().nullable()
    }),
             //.max(500, "Lastname must not exceed 50 characters"),
    sunOpened: Yup.boolean(),
    sunTo: Yup.string().when('sunOpened', {
        is: (value: boolean) => value === true,  
        then: Yup.string().required("Please select an closing time"),
        otherwise: Yup.string().nullable()
    }),
    sunFrom: Yup.string().when('sunOpened', {
        is: (value: boolean) =>  value === true,  //Boolean(value),
        then: Yup.string().required("Please select an opening time"),
        otherwise: Yup.string().nullable()
    }),  

})
import { Center, Circle, IconButton, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { MdShoppingCart } from 'react-icons/md'
import { useHistory } from 'react-router'
import routes from '../../../routes'
import { CartContext } from '../../AppProvider/AppProvider'

const WHShoppingCart = () => {

    const {state: cart} = useContext(CartContext) 
    const history = useHistory()

    return (
        <Center
               position="relative"
               w="48px"
               h="48px"
            >
            <IconButton variant="outline"  bg="white" 
            
            colorScheme="primary" aria-label="Open Menu" 
                  onClick={()=>{
                    history.push(`/${routes.confirmPay.name}`)
                  }} 
                  icon={
                    
                      <MdShoppingCart color="#262949" fontSize="20px"/>
                  
                  }
              />
              <Circle alignItems="center" justifyContent="center" 
                  w={4} h={4} 
                  top="0px" right="0px"  
                  position="absolute" bg="secondary.500">
                  <Text className="caption" fontSize="8px !important" color="white">{cart.items.length}</Text>
              </Circle>
            </Center>
    )
}

export default WHShoppingCart

import React, { useCallback, useContext, useEffect, useState } from "react";
import coworkImg from "../../../../Assets/workspace1.jpg";
import OfficeItemsList from "../../../SupportingViews/OfficeItemsList/OfficeItemsList";
import SearchResultsItemViews from "../../../SupportingViews/SearchResultsItemViews/SearchResultsItemViews";
import WHLayoutGrid from "../../../SupportingViews/WHLayoutGrid/WHLayoutGrid";
import {
  Box,
  GridItem,
  HStack,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { appDataManager } from "../Models/WHAppDataManager";
import { useParams } from "react-router";
import {
  PublicListing,
  ResultType,
  Workspace,
} from "../../../../Models/Interfaces";
import { WorkspacesContext } from "../../../AppProvider/WHAppProvider";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import SearchResults, { Queries } from "../SearchResuts/SearchResults";
import WHSEO from "../../../SupportingViews/WHSEO/WHSEO";
import AdsView from "../../../SupportingViews/AdsView/AdsView";
import WHSeeMoreList from "../../../SupportingViews/WHSeeMoreList/WHSeeMoreList";
import { OfficeDetailLink } from "../../../SupportingViews/Links/Links";
import OfficeItemView from "../../../SupportingViews/OfficeItemView/OfficeItemView";

const WorkspaceDetail = () => {
  const workspaceParam = useParams<{ workspaceId?: string }>();
  const [listings, setListings] = useState<PublicListing[]>([]);
  const workspaces = useContext(WorkspacesContext);
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    Workspace | undefined
  >();
  const [error, setError] = useState<Error | null>(null)    // const [result, setResult] = useState<Result<PublicListing> | null>(null)
  const [nextURL, setNextURL] = useState<string | undefined>()
  const [isLoading, setisLoading] = useState(false)

//   const [xsmall, small, medium, large] = useMediaQuery([
//     "(max-width: 599px)",
//     "(min-width: 600px) and (max-width: 904px)",
//     "(min-width: 905px) and (max-width: 1439px)",
//     "(min-width: 1440px)",
//   ]);

  const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  let workspaceTitle = "Enterprise Hubs Ghana";
  let imgHeight = "244px";
  let gridline = 4
  if (small) {
    imgHeight = "320px";
    gridline = 8
  } else if (medium || large) {
    imgHeight = "320px";
  } 

  if (medium || large || xl) {
      gridline = 12
  } 
  
  

 // console.dir(selectedWorkspace)
 const loadWorkspaceOffices = useCallback(async () => {
  if (!workspaceParam.workspaceId) return;

  //debugger
  setisLoading(true)

  const info: Queries = {
    workspace: workspaceParam.workspaceId
  }
  // let result = await appDataManager.getListingsByWorkspace(
  //   workspaceParam.workspaceId
  // );

  let result = await appDataManager.searchOfficeListings(info, nextURL);


  if (result.type === ResultType.Success) {
    // setRequestStatus(ResultType.Success)
    const temp = result.value.results.slice()
    //setListings(result.value.results);
    setListings((prev) => ([...prev, ...temp]))
    setNextURL(result.value.next)
    setError(null)
    //setResult({type: result})
  } else {
    //setRequestStatus(ResultType.Failure)
     setError(result.error)
    setListings([]);
  }

  setisLoading(false)

}, [nextURL])

  useEffect(() => {
    loadWorkspaceOffices();
  }, []);

  useEffect(() => {
    if (workspaces.length > 0 && listings.length > 0 && !selectedWorkspace) {
      const idx = workspaces.findIndex(
        (item) => item.id === listings[0].workspace
      );
      if (idx > -1) {
        setSelectedWorkspace(workspaces[idx]);
      } else {
        setSelectedWorkspace(undefined);
      }
    }
  }, [workspaces, listings]);

 // console.log(nextURL)

  return (
    <>
      {listings.length > 0 ? (
        <>
          <WHSEO 
            title={`${listings[0].workspace_details.name ?? ""} Workspace Offices`} 
            meta={[
              {
                name: "description",
                content: `${listings[0].workspace_details.name ?? "Workspace"}`
              },
              {
                name: "keywords",
                content: `workHopper partner, workHopper, ${listings[0].workspace_details.name ?? "workspace"}`
              }
            ]}  
          />
          <LayoutView>
            <Image
              w="full"
              rounded="lg"
              height={imgHeight}
              src={listings[0].workspace_details.header}
              objectFit="cover"
              aria-label={listings[0].workspace_details.name}
              mb={4}
             />
          </LayoutView>
          <WHLayoutGrid>
            <>
              <GridItem colSpan={gridline} rowSpan={1}>
                <Text className="h6">
                  Offices available at {listings[0].workspace_details.name }{" "}
                  Workspace
                </Text>
              </GridItem>
              <GridItem colSpan={gridline}></GridItem>
              <GridItem  colSpan={gridline}>
              <WHSeeMoreList
                    useGrid
                    data={listings}
                    isLoading={isLoading}
                    loadData={() => {
                      loadWorkspaceOffices();

                    }}
                    nextURL={nextURL}
                    error={error}
                    spinnerHeight={"32px"}
                    //loadData={onSearchListing}
                  >
                    {/* <SearchResultsItemViews listings={listings} /> */}

                    {listings.map((item) => (
                      <Box  key={item.id}  h="160px">
                      <OfficeDetailLink
                       
                        to={`/office/${item.id}/detail`}
                      >
                        <OfficeItemView listing={item} />
                      </OfficeDetailLink>
                      </Box>
                    ))}
                  </WHSeeMoreList>

              </GridItem>
               {/* <SearchResultsItemViews listings={listings} /> */}
               {/* <GridItem colSpan={gridline} rowSpan={!xsmall ? 1 : 13}>
                  <AdsView width="100%" height="120px" />
                </GridItem> */}
            </>
          </WHLayoutGrid>
          {/* <LayoutView>
              <AdsView width="100%" height="120px" />
          </LayoutView> */}
           
           
        </>
      ) : null}

      {/* <OfficeItemsList title="Enterprise Hubs Ghana" /> */}
    </>
  );
};

export default WorkspaceDetail;

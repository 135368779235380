import { useDisclosure, Text, Box } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import WHDashboardDrawer from "./WHDashboardDrawer/WHDashboardDrawer";
import WHDashboardNavbar from "./WHDashboardNavbar/WHDashboardNavbar";
import {
  Route,
  Switch,
  NavLink as ReactLink,
  useHistory,
  Redirect,
} from "react-router-dom";
import { dashboardManager } from "./Models/WHDashboardDataManager";
import { DashboardSectionName } from "./Models/Interfaces";
import { URLDir } from "../WHApp/AddASpace/Models/ListSpaceDataManager";
import WHManageWorkspaces from "./WHManageWorkspaces/WHManageWorkspaces";
import WHManageBookings from "./WHManageRequests/WHManageRequests";
import WHManageRequests from "./WHManageRequests/WHManageRequests";
import { signUpDataManager } from "../WHApp/SignUp/SignUpDataManager";
import { SetUserContext, UserContext } from "../../AppProvider/AppProvider";
import WHManageRequestsProvider from "./WHManageRequests/WHManageRequestsProvider";

const WHDashboard = () => {
  const disclosureProps = useDisclosure();
  const history = useHistory();

  let registeredUser = useContext(UserContext);
  let setUserContext = useContext(SetUserContext);

   
  const handleLogout = () => {
    signUpDataManager.logUserout();
    setUserContext(null);
    history.push("/");
  };

  return (
    
    <>
    {registeredUser ? (
      <Box paddingTop={{ base: 14, md: 16, lg: "72px" }}>
        <WHDashboardNavbar
          onLogout={handleLogout}
          registeredUser={registeredUser}
          onOpen={disclosureProps.onOpen}
        />
        <WHDashboardDrawer
          onLogout={handleLogout}
          registeredUser={registeredUser}
          {...disclosureProps}
        />
        <Switch>
          
            <>
              <Route
                path="/dashboard/manage+requests/:bizId"
                render={() => {
                  return (
                      <WHManageRequestsProvider>
                        <WHManageRequests />
                      </WHManageRequestsProvider>
                  )
                  
                }}
              />
              <Route
                path="/dashboard/:bizId/manage+workspaces"
                render={() => {
                  return <WHManageWorkspaces partner={registeredUser!} />;
                }}
              />
            </>
          <Route path="*">
              <Redirect
                to={dashboardManager.getSectionURL(
                  DashboardSectionName.WKS,
                  URLDir.curr,
                  registeredUser!.id
                )}
              /> 
          </Route>
        </Switch>
      </Box>
    ) : (
      <Redirect
        to="/"
      />
    )}
  </>
  );
};

export default WHDashboard;

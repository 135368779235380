import { Box, Flex, Text, Image, VStack, Button, SimpleGrid, IconButton, useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, Link, ModalFooter, ButtonGroup, ModalBody, ModalHeader, Divider, useBreakpointValue, Center, Spinner, HStack, Spacer, Skeleton, useMediaQuery } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react'
import { MdAdd, MdStar } from 'react-icons/md';
import {NavLink as ReactLink, useHistory } from 'react-router-dom'


 import banner from "../../../../Assets/banner-icon.png";

import { CompartmentCategory, Listing, ListingCategory, Result, ResultType } from '../../../../Models/Interfaces';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { CurrenciesContext, SetAlertContext, SetErrorContext, SetSuccessContext, UserContext } from '../../../AppProvider/AppProvider';
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout';
import OfflineView from '../../../SupportingViews/OfflineView';
import WHAlert from '../../../SupportingViews/WHAlert/WHAlert';
import WHLoadMore from '../../../SupportingViews/WHLoadMore/WHLoadMore';
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner';
//import { CompartmentCategory } from '../../Models/Interfaces';
import { appDataManager } from '../../WHApp/Models/WHAppDataManager';
import AddOffice from '../AddOffice/AddOffice';
import { AdminStateActions, DashboardSectionName, OfficesViewProps, PrivateOfficeForm, WHDashboardDrawerProps } from '../Models/Interfaces';
import { dashboardManager, WHDashboardDataManager } from '../Models/WHDashboardDataManager';
import { ListingsStateContext, SetWorkspaceContext, WorkspaceContext } from '../WHManageWorkspaces/ManageWorkspacesProvider';
import { PrivateOfficeFormRefContext } from '../WHWorkspaceDetail/OfficesViewProvider';
import placeholderBanner from "../../Assets/banner-icon.png"
import WHPaystackButton from '../../../SupportingViews/WHPaystackButton/WHPaystackButton';
import { add, format } from 'date-fns';
import WHIncompleteError from '../../../SupportingViews/WHIncompleteError/WHIncompleteError';
import { t } from 'i18next';

interface OfficeItemViewProps {
    imageURL: string, 
    id: string,
    category: string,
    title: string,
    onClick: ()=>void
    onDelete: ()=>void
    onPublish: ()=>void
    listingItem: Listing
}

interface ToolbarProps {
  title?: string 
  onAddOffice: ()=>void
  onModalOpen: ()=>void
}

const OfficeItemView = ({onClick, imageURL, id, category, title, onDelete, onPublish, listingItem}: OfficeItemViewProps) => {

    const setAlertContext = useContext(SetAlertContext)
    const [isOpen, setIsOpen] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const user = useContext(UserContext)
    const setError = useContext(SetErrorContext)
    const setSuccess = useContext(SetSuccessContext)
    const [paymentData, setPaymentData] = useState<any | undefined>()
    const {state, dispatch} = useContext(ListingsStateContext)


    const verifyPremiumPayment = useCallback(
      async () => {
       if (!user || !user.access_token) {return}
       if (!paymentData) {return}
      // debugger
       let result = await WorkhopperAPIManager.shared.verifyPremiumPayment({
         payment_type: "premium_listing",
         payment_ref: paymentData.payment_ref
       },
       user.access_token)
       if (result.type === ResultType.Success) {
          onPremiumPaySuccess()
       } else {
         setError({title: "", msg: result.error.message})
       }
      },
      [user, paymentData]
    )

    const getTotal = useCallback((): number =>{
      
      if (!paymentData) {return 0}
      let total = Number(paymentData.amount)
      return isNaN(total) ? 0 : total 

    }, [paymentData])

    const savePremiumData = useCallback(async (): Promise<any>=> {
      if (!user || !user.access_token || !id) {return}
     

     let result1 = await WorkhopperAPIManager.shared.savePremiumPaymentData({start_date: format((new Date()),  "yyyy-MM-dd"), 
                                                                              end_date: format((add(new Date(), {
                                                                                months: 2
                                                                              })),  "yyyy-MM-dd"), 
                                                                              listing: id, amount: "5.00", currency: "USD"}, 
                                                                              user.access_token)
     if (result1.type === ResultType.Success) {
        setPaymentData(result1.value)
       // return result1.value
     } else {
       setError({title: "", msg: result1.error.message})
     }
    },[user, paymentData])

    const onPremiumPaySuccess = async ()=>{
             
            

            if (!user || !user.access_token) {return }
                     let result = await WorkhopperAPIManager.shared.patchListingPublish(id, user.access_token, {is_premium: true})
                     if (result.type === ResultType.Success) {
                      let payload: Listing = {title: result.value.title, 
                        description: result.value.description,
                        capacity: result.value.capacity,
                        images: result.value.images,
                        booking_duration: result.value.booking_duration,
                        is_receive_mails: result.value.is_receive_mails,
                        use_office_address: result.value.use_office_address,
                        is_whiteboard: result.value.is_whiteboard,
                        workspace: result.value.workspace,
                        category: result.value.category,
                        id: result.value.id,
                        is_publish: result.value.is_publish,
                        date_created: result.value.date_created,
                        date_updated: result.value.date_updated,
                        is_premium: result.value.is_premium
                       }

                      dispatch({type: AdminStateActions.UPDATELISTING, payload: payload})
                       setSuccess({title: "", msg: `Congrats. ${result.value.title} is now a Premium listing`})
                     } else {
                        setError({title: "", msg: result.error.message})
                     }
    }

    
    useEffect(()=>{
      setAlertContext({title: isDelete ? "Delete Office" : listingItem.is_publish ? "Unpublish Office" : "Publish Office",
      msg: "Are you sure?",
      leftButton: {label: "No", action: ()=>{
        //console.log("closing")
        setIsOpen(false)
      }},
      rightButton: {label: "Yes", action: ()=>{
        if (isDelete) {
          onDelete()
        } else {
          //setIsOpen(false)
          onPublish()
        }
      }
      },
      isOpen: isOpen, 
      onClose: ()=>{setIsOpen(false)}
      
    
    })
    },[isOpen])

    return (
        <Box shadow="lg" 
            rounded="md" 
            bg="#fff"
            onClick={onClick}
            key={id}
            position="relative"
           
            // bg="#c5cae9"
          >
        <Image 
              // fallback={
              //   <Skeleton 
              //     w="full"
              //     h={"180px"} 
              //   />
              // }
           
              bg="#f7f7f7"
              fallbackSrc={banner}
              objectFit={imageURL ? "contain" : "cover"}
              // rounded="md" 
               borderTopLeftRadius="6px"
               borderTopRightRadius="6px"
               w="full" 
               h={"180px"}  src={imageURL} />
        <Box  padding={4}>
            <VStack spacing="0px" alignItems="flex-start">
                <Text className="1">{title}</Text>
                <Text className="caption">{category}</Text>
             </VStack>
            <Divider/>
            <HStack spacing={2}
              ml="-10px"
            >
              {
                user && user.email && !listingItem.is_premium ? (
                  <WHPaystackButton 
                  isPremium={listingItem.is_premium}
                  premiumAction={savePremiumData}
                  reference={ paymentData ? paymentData.payment_ref : ""}
                  width="40px"
                  variant="ghost"
                  
                  total={getTotal()}
                  hasBookedOffice={true}
                  email={user.email}
                  colorScheme="whatsapp"
                  onClose={()=>{}}
                  onSuccess={(reference: any)=>{
                    verifyPremiumPayment()
                  }}
                  
                />
                ):null
              }
           
              <Button 
                    isLoading={isLoading}
                    onClick={(e)=>{
                       e.stopPropagation()
                       setIsDelete(false)
                       setIsOpen(true)

                     
                    }}
                    padding={2}
                    className="button"
                    rounded="md" 
                    variant="ghost"
                    colorScheme="whatsapp"
                    >
                    {listingItem.is_publish ? t("Unpublish") : t("Publish")}
                 </Button>
              <Button 
                    padding={2}
                    onClick={(e)=>{
                      
                      //e.stopPropagation()
                      //onDelete()
                    }}
                     className="button"
                    rounded="md" 
                    variant="ghost"
                    colorScheme="whatsapp"
                    >
                      {t("Edit")}
                 </Button>
              <Button 
                       padding={2}
                    onClick={(e)=>{
                      e.stopPropagation()
                      //onDelete()
                       setIsDelete(true)
                       setIsOpen(true)
                    //    setAlertContext({title: "Delete Office",
                    //   msg: "Are you sure?",
                    //   leftButton: {label: "No", action: ()=>{setIsOpen(false)}},
                    //   rightButton: {label: "Yes", action: onDelete},
                    //   isOpen: isOpen, 
                    //   onClose: ()=>{setIsOpen(false)}
                      
                    
                    // })

                    }}
                     className="button"
                    rounded="md" 
                    variant="ghost"
                    colorScheme="secondary"
                    >
                      {t("Delete")}
                 </Button>
                 <Spacer/>
            </HStack>
        </Box>
        {
          listingItem.is_premium ? (
            <MdStar fill="gold" 
            fontSize="32px" 
            style={{
              position: "absolute",
              top: "16px",
              left: "16px"
            }}    
        />
          ): null
        }
       
    </Box>
    )
}

const Large = ({title, onAddOffice, onModalOpen}: ToolbarProps)=>{
  return (
    <Flex
      //className="navbar-lg"
     // shadow="md"
      top={0}
      bg="white"
      w="full"
      padding={{ base: "0px 1.5em", md: "0px 1.5em ", lg: "0px 2em" }}
      justifyContent="flex-start"
      alignItems="center"
      h={{ base: "56px", md: "64px", lg: "72px" }}
      color="primary.500"
    >
      {
        title !== null ? (
          <Text mr="auto" className="h6">
            {title}
        </Text>
        ) : (
          <Text mr="auto" className="h6">
            Untitled
        </Text>
        )
      }
     

      <Button
        onClick={()=>{
          onModalOpen()
          onAddOffice()
        }}
        shadow="lg"
        colorScheme="whatsapp"
        aria-label="Add an Office"
        >
          {t("Add Office")}
        </Button>
    </Flex>
  );
}




const OfficesView = ({manager}: OfficesViewProps) => {

    const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure()
     const modalSize = useBreakpointValue({base: "full", sm: "full", md: "lg", lg: "lg", xl: "lg"})
    const tempOfficeId = "officeId"
        const [categories, setCategories] = useState<ListingCategory[]>([])
    const [selectedCategory, setSelectedCategory] = useState<CompartmentCategory>(CompartmentCategory.PO)
    const privateOfficeFormRef = useRef<FormikProps<PrivateOfficeForm>>(null);
    const partner = useContext(UserContext)
    const [workspaceId, setWorkspaceId] = useState("") 
    const setErrorContext = useContext(SetErrorContext)
    const [isLoading, setIsLoading] = useState(false)
    const [listings, setListings] = useState<Listing[]>([])
    const workspace = useContext(WorkspaceContext)
    const setWorkspace = useContext(SetWorkspaceContext)
    const {state, dispatch} = useContext(ListingsStateContext)
    const finalResultRef = useRef<Result<any>>()
    const [error, setError] = useState<Error | null>(null)
    const currencies = useContext(CurrenciesContext)
    const setSuccess = useContext(SetSuccessContext)

    const selectedListing = state.responseData.results.find(item => item.id === state.selectedListingId)

    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)


    const [xsmall] = useMediaQuery([
      "(max-width: 599px)"
    ]);
    
    
  
    const loadListings = async ()=>{
           
        
        if (workspace && workspace.id && partner?.access_token) {
          setIsLoading(true)
          debugger
           let result = await dashboardManager.getListingsWithId(workspace.id, partner.access_token)
           //let result = await dashboardManager.getWorkspaceListingsWithId()
         if (result.type === ResultType.Success) {
             //setListings((result.value as any).results)
             //console.dir((result.value as any).results)
             dispatch({type: AdminStateActions.REPLACELISTINGS, payload: (result.value as any).results})
             setError(null)
         } else {
           setError(result.error)
           
         }
         setIsLoading(false)
      }
     
   }

   async function loadListingCategories() {
     
     let temp = appDataManager.getCategoriesData("list-cat")
     if (temp.length > 0) {
        setCategories(temp)
     }
     const result = await WorkhopperAPIManager.shared.listingCategories()
    if (result.type === ResultType.Success) {
          
          setCategories(result.value)
          
     } else {
        setError(new Error(result.error.message))
      //setErrorContext({title: result.error.name, msg: result.error.message})
   }
  }

  const onDeleteListing = async (id?: string, token?: string)=> {
    if (!id || !token) {return}
      let result = await dashboardManager.deleteListingWithId(id, token)
     if (result.type === ResultType.Success) {
         dispatch({type: AdminStateActions.REMOVELISTING, payload: id})
     } else {
       setErrorContext({title: "", msg: result.error.message})
     }
  }

  const onPublishListing = async (item: Listing, token?: string)=> {
   // debugger
    if (!item.id || !token || item.is_publish === undefined) {return}
      let result = await WorkhopperAPIManager.shared.patchListingPublish(item.id, token, {is_publish: !item.is_publish})
     if (result.type === ResultType.Success) {
       //console.dir((result.value as Listing))
       let payload: Listing = {title: result.value.title, 
                               description: result.value.description,
                               capacity: result.value.capacity,
                               images: result.value.images,
                               booking_duration: result.value.booking_duration,
                               is_receive_mails: result.value.is_receive_mails,
                               use_office_address: result.value.use_office_address,
                               is_whiteboard: result.value.is_whiteboard,
                               workspace: result.value.workspace,
                               category: result.value.category,
                               id: result.value.id,
                               is_publish: result.value.is_publish,
                               date_created: result.value.date_created,
                               date_updated: result.value.date_updated,
                               is_premium: result.value.is_premium
                              }
       //loadListings()
         setSuccess({title: "", msg: `${result.value.title} is now ${result.value.is_publish ? "listed on": "unlisted from"} workhopper.co`})
         //onSuccess(result.value.is_publish)
         dispatch({type: AdminStateActions.UPDATELISTING, payload: payload})
     } else {
       setErrorContext({title: "", msg: result.error.message})
     }
  }

    const onAddOffice = async () => {

      // validate prices 
        let actionType: AdminStateActions
 
        if (!privateOfficeFormRef.current) {return}

        privateOfficeFormRef.current.setSubmitting(true)
        await privateOfficeFormRef.current?.validateForm()
        
       
        


       if (!privateOfficeFormRef.current?.isValid) {
         privateOfficeFormRef.current.setSubmitting(false)
         return
        }

       if (state.selectedListingId) {
          actionType = AdminStateActions.UPDATELISTING
       } else {
          actionType = AdminStateActions.ADDLISTING
       }
     
      if (privateOfficeFormRef.current.values.startingPriceByDay === "" && privateOfficeFormRef.current.values.startingPriceByHour === "" && 
      privateOfficeFormRef.current.values.startingPriceByMonth === "" && privateOfficeFormRef.current.values.startingPriceByHour === "") {
        setErrorContext({title: "", msg: t("Please enter one or more pricing options for this office")})
        
        return
    }
       
        if (privateOfficeFormRef.current && categories.length > 0 &&
               workspace && workspace.id && partner?.access_token) {
        setIsLoading(true)
        // console.dir(dashboardManager.removedFiles)
        
         let result = await dashboardManager.createListing(
          categories,
          {
            ...privateOfficeFormRef.current.values,
            selected: selectedCategory,
            workspace: workspace.id,
            listingId: state.selectedListingId,
            isPublished: false,
            currencyObj: currencies.find(item => item.currency === privateOfficeFormRef.current!.values.currency)!
          },
         // dashboardManager.listingHeader as (UploadedFile | undefined),
         // dashboardManager.getUpdatedListingImages(),
          partner.access_token
          
        );

        
        if (result.type === ResultType.Failure) {
          setErrorContext({
            title: result.error.name,
            msg: result.error.message,
          });
          setIsLoading(false)
          return
        }

        // debugger
        if (!privateOfficeFormRef.current?.values.startingPriceByDay &&
          !privateOfficeFormRef.current?.values.startingPriceByHour &&
          !privateOfficeFormRef.current?.values.startingPriceByMonth &&
          !privateOfficeFormRef.current?.values.startingPriceByYear 
          ) {
            setErrorContext({title: "", msg: t("Please choose at least one Price for this office")})
            setIsLoading(false)

            return
          }
          
          
          if (!dashboardManager.listingHeader) {
            setErrorContext({title: "", msg: t("Please upload a header photo")})
            setIsLoading(false)
            return
          }
          
          /*
          const remainingPhotoCount = 4 - dashboardManager.listingOthers.length 
          if (dashboardManager.listingOthers.length < 4) {
            setErrorContext({title: "", msg: `Please upload ${remainingPhotoCount} other photo`})
            setIsLoading(false)
            return
          } */
          
        // debugger
         let addImagesResult =  await dashboardManager.addImagesToListing(
          result.value,
          dashboardManager.listingHeader,
          dashboardManager.getUpdatedListingImages(),
          partner.access_token
        )
        // let addImagesResult = {type: ResultType.Failure, error: new Error("An error occurred. Try again")} 

        
 
        if (addImagesResult.type === ResultType.Failure) {
          setErrorContext({
            title: addImagesResult.error.name,
            msg: `${addImagesResult.error.message}`,
          });
          if (actionType === AdminStateActions.ADDLISTING) {
            dispatch({type: actionType, payload: [result.value]})
          } else {
            dispatch({type: AdminStateActions.UPDATELISTING, payload: result.value})
          }
        } else    {
          //let listing = addImagesResult.value
          if (actionType === AdminStateActions.ADDLISTING) {
            dispatch({type: actionType, payload: [addImagesResult.value]})
          } else {
            dispatch({type: AdminStateActions.UPDATELISTING, payload: addImagesResult.value})
          }
         /// dispatch({type: AdminStateActions.ADDLISTING, payload: [addImagesResult.value]})
        }


        setIsLoading(false)
       

       // loadListings()
        onModalClose()
          /*
            if (result.type === ResultType.Success) {
              loadListings()
            onModalClose()
            
            
            // setValues(props.values.name)
          } else {
            setErrorContext({
              title: result.error.name,
              msg: result.error.message,
            });
          }*/
      }
        
    }

    const loadMoreOffices = useCallback(
      async () => {
        if (!partner?.access_token) return
                 
        setIsLoading(true)
         let listingsResult = await dashboardManager.getWorkspaceListingsWithId(state.selectedWorkspace, partner.access_token, state.responseData?.next)
        if (listingsResult.type === ResultType.Success) {
            //console.dir(listingsResult.value)
            dispatch({type: AdminStateActions.ADDLISTINGS, payload: {id: workspace?.id, data: listingsResult.value}})
            setError(null)
          } else {
            setError(listingsResult.error)
        }
        setIsLoading(false)
      },
      [state.responseData],
    )

    const getOfficeItemViews = () => {
      
     // let views = state.listings.map((item, _) => {
        //console.dir(state.responseData)
        let views = state.responseData?.results.map((item, _) => {

        let header = banner;
        if (item.images) {
          header = (item.images as any).header;
        }

         let categoryTitle = dashboardManager.mapOfficeCategory(
          false,
          categories,
          item.category
        );

        return item.id ? (
          <Box
            as="section"
            position="relative"
            key={item.id}
          >
          <OfficeItemView
             onClick={() => {
              
              // setSelectedid(item.id!)

              onModalOpen();
              dispatch({
                type: AdminStateActions.SELECTLISTING,
                payload: item.id!,
              });
              
            }}
            onDelete={()=>{
            
              onDeleteListing(item.id, partner?.access_token)
            }}
            onPublish={()=>{
              onPublishListing(item, partner?.access_token)
            }}
            listingItem={item}
            imageURL={`${header}`}
            id={item.id}
            category={categoryTitle}
            title={item.title}
          />
          {
            !dashboardManager.officeImagesAdded(item) ? (
              <WHIncompleteError text={t("One or more image(s) are missing. Edit to complete details")} />
            ):null
          }
          </Box>
        ) : null;
      });

      return views;
    };

 


     

    useEffect(() => {
       
      
        loadListingCategories()
      
     }, [])


     useEffect(()=>{

      if (selectedListing) {
        let categoryTitle = dashboardManager.mapOfficeCategory(
          false,
          categories,
          selectedListing.category
        );
        setSelectedCategory(categoryTitle as CompartmentCategory)
      }
     }, [selectedListing])
       
   
    return (
      <Box as="section" w="full" paddingBottom={8}>
          <VStack width="full" justifyContent="center" bg="red"
         //rounded="xl"
         padding={4}
         bgGradient="linear(to-l, primary.500,#22c35e)"
         height={xsmall ? "unset" : "128px"}
         >
                <Text className={xsmall ? "subtitle" : "h6"} color="white !important">{t("PremiumMsg")}</Text>
           </VStack>
        <Large 
          title={""}
          onModalOpen={onModalOpen}
          onAddOffice={()=>{
            // dispatch({
            //   type: AdminStateActions.SELECTLISTING,
            //   payload: null,
            // });
          }}
        />
        <AddASpaceLayout>
           
          {
            ( state.responseData && isLoading &&  state.responseData.results.length === 0) &&
              // <WHSpinner />
              <SimpleGrid
              w={"100%"}
              // columns={3}
              columns={{ base: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
              gap={6}
              columnGap={6}
            >
              { 
                 [1, 2, 3, 4, 5, 6].map(item=> <Skeleton key={item} h={"200px"}/>)
              }
             
            </SimpleGrid>
            }
            {
           // state.listings.length > 0 && categories.length > 0 &&
            categories.length > 0 &&
            
                <SimpleGrid
                w={"100%"}
                // columns={3}
                columns={{ base: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                gap={6}
                columnGap={6}
              >
                {getOfficeItemViews()}
              </SimpleGrid>
            }
            {
              (!error || isLoading) &&
              <WHLoadMore
              spinnerHeight="64px"
              listings={state.listings}
              nextURL={state.responseData?.next}
              isLoading={isLoading}
              w={xsmall ? "96px" : "20%"}
              colorScheme="whatsapp"
              counterUpdater={ ()=>{
                loadMoreOffices()
                
              }}
          />
            }
             { 
              
              (error && !isLoading) &&
              <OfflineView
              onRetry={() => {
                // loading the first time ?
                if (!state.responseData) {
                  loadListingCategories()
                  loadListings();
                } else if (state.responseData.results.length > 0) {
                  loadMoreOffices()
                }
               
              }}
              msg={error.message}
              pt={"64px"}
            />
            }
            { state.responseData.results.length === 0 && 
              <Center>
              <Text className="h6">{t("There are no offices added yet!")}</Text>
            </Center>
            }
          
           
        </AddASpaceLayout>
        <Modal
          closeOnOverlayClick={false}
          isCentered
          size={modalSize}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          isOpen={isModalOpen}
          onClose={onModalClose}
        >
          <ModalOverlay />
          <ModalContent
            overflowY="scroll"
            // h={"80vh"}
            width={{ base: "100%" }}
          >
            <ModalHeader className="h6">{state.selectedListingId ? t("Edit Office") : t("New Office")}</ModalHeader>
            <Divider />
            <ModalCloseButton color="primary.500" />
            <ModalBody paddingX="0px">
              <AddOffice
                mode="new"
                //selectedCategory={state.selectedListingId ? selectedCategory : undefined}
                selectedCategory={selectedCategory}

                setSelectedCategory={setSelectedCategory}
                categories={categories}
                setCategories={setCategories}
                privateFormRef={privateOfficeFormRef}
              />
              <Divider />
            </ModalBody>
            {/* <ModalCloseButton color="white"/> */}
            {/* <AddOffice mode="new" /> */}
            <ModalFooter justifyContent={"flex-start"}>
              <ButtonGroup  variant="outline" spacing="6">
                {/* <Button colorScheme="red">Delete</Button> */}
                <Button isLoading={isLoading} onClick={onAddOffice}>
                  {t("Add")}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
       </Box>
    );

}

export default OfficesView

 import { count } from "console";
import { title } from "process";
import { Amenity, AmenityCategory, BookingDuration, Country, Listing, ListingCategory, ListingImage1, ListingImage2, ListingImage3, ListingImage4, ListResponseData, OtherImages, PublicListing, Result, ResultSuccess, ResultType, Workspace, WorkspaceLocation } from "../../../../Models/Interfaces";
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager";
import routes from "../../../../routes";
import { URLDir } from "../../WHApp/AddASpace/Models/ListSpaceDataManager";
import { BreakPoint } from "../../WHApp/OfficeDetail/Models/Interfaces";
import { ContactForm, DashboardSection, DashboardSectionName, DescForm, DesksForm, HopForm, LocationForm, SizeForm, UploadedFile } from "./Interfaces";



export class WHDashboardDataManager {

    private sections: Map<DashboardSectionName, DashboardSection> = new Map()
    private apiManager = WorkhopperAPIManager.shared
     workspaceId: string | null = null
    listingHeader: UploadedFile | null = null
    listingOthers: UploadedFile[] = []
    private savedFilesRemoved: UploadedFile[] = []
    private savedUploadedFiles: UploadedFile[] = []
    private listingCategoriesKey = "list-cat"

    constructor() {
        this.sections.set(DashboardSectionName.Desc, {
            name: DashboardSectionName.Desc,
            title: "Description",//"Tell us about Your space",
            next: DashboardSectionName.ContactDetails,
            prev: DashboardSectionName.Desc
        })
        this.sections.set(DashboardSectionName.ContactDetails, {
            name: DashboardSectionName.ContactDetails,
            title: "Contact Details", //"How can coworkers contact You?",
            next: DashboardSectionName.Amenities,
            prev: DashboardSectionName.Desc
        })
        this.sections.set(DashboardSectionName.Amenities, {
            name: DashboardSectionName.Amenities,
            title: "Amenities",
            next: DashboardSectionName.Location,
            prev: DashboardSectionName.ContactDetails
        })
       
        this.sections.set(DashboardSectionName.Location, {
            name: DashboardSectionName.Location,
            title: "Location",
            next: DashboardSectionName.HOP,
            prev: DashboardSectionName.Amenities
        })
        this.sections.set(DashboardSectionName.HOP, {
            name: DashboardSectionName.HOP,
            title: "Hours of Operation",
            next: DashboardSectionName.Size,
            prev: DashboardSectionName.Location
        })
        this.sections.set(DashboardSectionName.Size, {
            name: DashboardSectionName.Size,
            title: "Size",
            next: DashboardSectionName.PM,
            prev: DashboardSectionName.HOP
        })
        // this.sections.set(DashboardSectionName.PM, {
        //     name: DashboardSectionName.PM,
        //     title: "Payment Methods",
        //     next: DashboardSectionName.PM,
        //     prev: DashboardSectionName.Size
        // })
        this.sections.set(DashboardSectionName.WKS, {
            name: DashboardSectionName.WKS,
            title: "Manage Workspaces",
            next: DashboardSectionName.WKS,
            prev: DashboardSectionName.WKS
        })
        this.sections.set(DashboardSectionName.Offices, {
            name: DashboardSectionName.Offices,
            title: "Manage Offices/Desks",
            next: DashboardSectionName.Offices,
            prev: DashboardSectionName.Offices
        })
    }

    get allSections(): Array<DashboardSection> {
        let temp: Array<DashboardSection> = []
        for (const value of this.sections.values()) {
            temp.push(value)
        }
        return temp.filter((item)=> item.name !== DashboardSectionName.WKS && 
                                     item.name !== DashboardSectionName.Offices)
    }

    get removedFiles() {
        return this.savedFilesRemoved
    }

    get savedFiles() {
        return this.savedUploadedFiles
    }

    getSectionURL = (sectionId: DashboardSectionName, urlDir: URLDir, userId: string):string => {

        let section: DashboardSection = this.getSection(sectionId)
         
        if (urlDir === URLDir.next) {
            section = this.getSection(section.next)
        } else if (urlDir === URLDir.prev) {
            section = this.getSection(section.prev)
        }  

        return `/dashboard/${userId}/${section.name}`
    }

    getSection = (name: DashboardSectionName): DashboardSection => {
        return this.sections.get(name || DashboardSectionName.Desc)! 
    }
    /**
     * get url for each section of the dashboard
     * @param name 
     * @param userId 
     * @returns url
     */
    sectionLink = (name: DashboardSectionName, userId: string | undefined ): string  => {
        
        if (!userId) return ""
        return `/dashboard/${userId}/manage+workspaces/workspaceId/detail/${name}`
        //return `/dashboard/${userId}/${name}`
    }

    sectionPath = (name: DashboardSectionName): string => {

        if (name === DashboardSectionName.WKS) {
            return routes.manageWorkspaces.path
        }

        if (name === DashboardSectionName.BKS) {
            return routes.manageWorkspaces.path
        }

        return ""

    }

    officeDetailLink = (
                            name: DashboardSectionName, 
                            bizId: string | undefined, 
                            officeId: string): string  => {
            if (!bizId) return ""
                return `/dashboard/${bizId}/${name}/${officeId}/detail`
        
    }

    saveListingCategoriesData(key: string, categories: ListingCategory[]) {
        localStorage.setItem(key, JSON.stringify(categories))
    }

    getCategoriesData(key: string): ListingCategory[] {
        let val = localStorage.getItem(key)
        if (!val) return []
        return JSON.parse(val)
    }

    fetchListingCategories = async ():Promise<Result<ListingCategory[]>> => {

        //let result: Result<ListingCategory[]> = {type: ResultType.Failure, error: new Error("Couldn't load Categories")}

        //const categories = this.getCategoriesData(this.listingCategoriesKey)
       // if (categories.length > 0) { return categories}
        return await this.apiManager.listingCategories()
    }

    fetchAmenities = async ():Promise<Result<Amenity[]>> => {
       

        return await this.apiManager.getAmenities()
    }

    fetchAmenityCategories = async ():Promise<Result<AmenityCategory[]>> => {


        return await this.apiManager.getAmenitiesCategories()
    }

    async saveWorkspaceData(descValues: DescForm, contactValues: ContactForm,
                            sizeValues: SizeForm, hopValues: HopForm, locationValues: LocationForm 
                            ) {
                                
     console.dir({descValues, contactValues, sizeValues, hopValues, locationValues})
    //    let rslt = await this.apiManager.postWorkspace(workspaceData)
    //    console.dir(rslt)
    }
  
    // workspace operations 
    saveWorkspaceSection(section: string, value: string) {
        localStorage.setItem(section, value)
    }

    saveWorkspace(id: string, value: Workspace) {
        localStorage.setItem(id, JSON.stringify(value))
    }

    getWorkspace(id: string): Workspace | null {
        let value = localStorage.getItem(id)
        return  value ? JSON.parse(value) : null
    }

    getWorkspaceSection(section: string): string | null {
       return localStorage.getItem(section)
    }

    isContactSectionComplete(workspace: Workspace): boolean {

        if (!workspace) return false

        if (workspace.email) {
            return true
          }
          return false
    }

    isLocationSectionComplete(workspace: Workspace): boolean {

        if (!workspace) return false

        let location = workspace.location as WorkspaceLocation

        if (workspace.street && 
            location.city && location.country && location.state
          ) {
            return true
          }
          return false

        //   if (workspace.street && 
        //     workspace.city && workspace.country
        //   ) {
        //     return true
        //   }
        //   return false
    }

    isHOPSectionComplete(workspace: Workspace): boolean { 
        
        if (!workspace) return false

        
    

        let hasDays = false 
              let hasHours = false
            /*
            if (workspace.extra_days) {
              let days = workspace.extra_days.days
              if (days && days.length > 0) {
                hasDays = true
              }  
            }  */
            
            if (workspace.available_hours) {
              let hours = workspace.available_hours
              let s = hours.start_time 
              let e = hours.end_time
              if (s && s.length > 0 && e && e.length > 0) {
                  hasHours = true
              } 
            }  
           

            // if (hasHours && hasDays) {
            //   return true
            // } 
            return hasHours
    }
    
    isDescriptionComplete(workspace: Workspace | null): boolean {

        if (!workspace) return false
        if (workspace.description && workspace.title && workspace.header) {
            return true
          }
          return false
    }

    isSizeSectionComplete(workspace: Workspace): boolean {
        if (!workspace) return false

        return (workspace.size && workspace.capacity && workspace.capacity > 0) ? true : false
     }

     isAmenitiesSectionComplete(workspace: Workspace): boolean {
        if (!workspace) return false

        if (workspace.amenities && workspace.amenities.amenities && workspace.amenities.amenities.length > 0) {
            return true
          }
          return false
     }

     isDetailSectionComplete(workspace: Workspace | null): boolean {
          
          if (!workspace) return false
         
         
          
         if (
             this.isContactSectionComplete(workspace) &&
                 this.isDescriptionComplete(workspace) &&
              this.isHOPSectionComplete(workspace) && this.isLocationSectionComplete(workspace) &&
            
              this.isAmenitiesSectionComplete(workspace) ) {
                  return true
              }
              return false
     }
    

    async postWorkspaceSection(id: string | undefined, section: DashboardSectionName, data: any, file?: File, token?: string | undefined): Promise<Result<Workspace>>  {
        //debugger
        let result = await this.apiManager.postDashboardSection(id, section, data, token)
        if (result.type === ResultType.Success  && file 
            && file.size > 0 && result.value.id) {
            
           result = await this.apiManager.patchResourceWithImage(result.value.id, file, token)
           //console.dir(result)
        }

        return result

        
    }

    async updateWorkspaceSection(id: string, section: DashboardSectionName, data: any, token?: string | undefined): Promise<Result<Workspace>>  {
        
        let result = await this.apiManager.patchWorkspace(id, section, data, token)
        return result
    }

     getCategory(isId: boolean, countryItems: Country[], id?: string, selectedCountry?: Country): Country | string {
        
        let result: Country | string = "" 
        if (isId) {
            let entryIdx = countryItems.findIndex((item, idx)=>(
                item.name.includes(selectedCountry!.name)
              ))
              result = countryItems[entryIdx].id
        } else {
            let entryIdx = countryItems.findIndex((item, idx)=>(
                item.id.includes(id!)
              ))
              result = countryItems[entryIdx]
        }

        return result
       
    }

    mapOfficeCategory(isId: boolean, categoryItems: ListingCategory[], id?: string, category?: string): string {
          
        let result = ""

        if (isId) {
            let entryIdx = categoryItems.findIndex((item, idx)=>(
                item.title.includes(category!)
              ))
              result = categoryItems[entryIdx].id

        } else {
               let entryIdx = categoryItems.findIndex((item, idx)=>(
                item.id.includes(id!)
              ))
              result = categoryItems[entryIdx].title
        }
        
        return result
    }

    // hasListing(workspace: Workspace): boolean {

    //     return workspace.listings && workspace.listings.length > 0

        
    // }
    
  //  async createListing(categories: ListingCategory[], data: any,  header?: UploadedFile, others?: UploadedFile[], token?: string): Promise<Result<Listing>>  {
    async createListing(categories: ListingCategory[], data: any,  token?: string): Promise<Result<Listing>>  {

        // let listings: Listing[] = []
        //debugger
        let result: Result<Listing> = {type: ResultType.Failure, error: new Error("An error occurred. Try again")} 
          if (categories.length > 0 && data.selected && token) {
            let categoryId = this.mapOfficeCategory(true, categories, undefined, data.selected)
            let currency_id = data.currencyObj.currency_id //"92613da4-39cd-4214-a72e-8ada0d218373" //data.currencyObj.currency_id
             let currency =  data.currencyObj.currency // "USD"
            let booking_duration = []
            if (Number(data.startingPriceByDay)) {
                booking_duration.push({minimum_duration: "day", 
                                        amount: data.startingPriceByDay.toString(),
                                        currency_id,
                                        currency
                                    })

           } 
           if (Number(data.startingPriceByHour)) {
            booking_duration.push({minimum_duration: "hourly", 
                                  amount: data.startingPriceByHour.toString(),
                                  currency_id,
                                  currency
                                     })
           } 
           
           if (Number(data.startingPriceByMonth)) {
                    booking_duration.push({minimum_duration: "monthly", 
                    amount: data.startingPriceByMonth.toString(),
                    currency_id,
                    currency
                    })
           } 

           if (Number(data.startingPriceByYear)){
                booking_duration.push({minimum_duration: "yearly", 
                 amount: data.startingPriceByYear.toString(),
                  currency_id,
                  currency
                })
           }

             //if (data)
            let dataToSend = {
                title: data.name,
                description: data.description,
                capacity: data.capacity.toString() === "" ? "1" : data.capacity.toString(),
                booking_duration,
                workspace: data.workspace,
                category: categoryId,
                images: null,
                is_whiteboard: data.hasWhiteboard,
                is_receive_mails: data.isRecieveMails,
                use_office_address: data.useOfficeAddress,
                id: data.listingId,
                is_publish: data.isPublished
                //booking_duration: 
            } 
            
            // let files = others.filter(upfile => 
            //     upfile.file && upfile.file.size > 0
            //  ).map(upfile => upfile.file)

             
             result = await this.apiManager.postListing(dataToSend, token)
             
            
            // if (result.type === ResultType.Success) {
            //     // add images to the workspace
                 
                
            //      if (files.length >= 1 || header.file.size > 0) {
                    
                     
            //          let otherImages: OtherImages = {image_1: others[0].file, 
            //                                         image_2: others[1].file,
            //                                         image_3: others[2].file,
            //                                         image_4: others[3].file
            //                                     }
                     
                    
            //          result = await this.apiManager.patchListingWithImages(result.value, header.file, otherImages, token)
                      
            //     } 
                
            // }

         } 
        
        return result
    }

    /*
    async addImagesToListing(listing: Listing, header: UploadedFile, others: UploadedFile[], token?: string): Promise<Result<Listing>> {
        
        let result: Result<Listing> = {type: ResultType.Failure, error: new Error("An error occurred. Try again")} 
        
        
        //let result: Result<Listing> = {type: ResultType.Failure, error: new Error("An error occurred. Try again")} 
       // debugger
        let files = others.filter(upfile => 
            upfile.file && upfile.file.size > 0
         ).map(upfile => upfile.file)

         // have no updated files
         let othersWithPreviews = others.filter(item => item.preview !== "")
         

         // if nothing was uploaded
         if (files.length === 0 && header.file.size === 0) {
             // but all files have previews (fetch from server)
            if (othersWithPreviews.length === 4 && header.preview !== "") {
                    let r = {...result, type: ResultType.Success, value: listing}
                    return r
            }  
         
            return result
         }
        
                    
                     
            let otherImages: OtherImages = {image_1: others[0].file, 
                                           image_2: others[1].file,
                                           image_3: others[2].file,
                                           image_4: others[3].file
                                       }
            
            
            result = await this.apiManager.patchListingWithImages(listing, header.file, otherImages, token)
            
            return result
      // } 
    }*/

    async addImagesToListing(listing: Listing, header: UploadedFile, others: UploadedFile[], token?: string): Promise<Result<Listing>> {
        
        let result: Result<Listing> = {type: ResultType.Failure, error: new Error("An error occurred. Try again")} 
        
        
        //let result: Result<Listing> = {type: ResultType.Failure, error: new Error("An error occurred. Try again")} 
       // debugger
        let files = others.filter(upfile => 
            upfile.file && upfile.file.size > 0
         ).map(upfile => upfile.file)

         // have no updated files
         let othersWithPreviews = others.filter(item => item.preview !== "")
         

         // if nothing was uploaded
         /*
         if (files.length === 0 && header.file.size === 0) {
             // but all files have previews (fetch from server)
            if (othersWithPreviews.length === 4 && header.preview !== "") {
                    let r = {...result, type: ResultType.Success, value: listing}
                    return r
            }  
         
            return result
         }*/
        
         if (header.file.size === 0 && header.preview === "") {
             return result
         }
                    
                     
            // let otherImages: OtherImages = {image_1: others.length >= 1 ? others[0].file : undefined, 
            //                                image_2: others.length >= 2 ? others[1].file : undefined,
            //                                image_3: others.length >= 3 ? others[2].file : undefined,
            //                                image_4: others.length === 4 ? others[3].file : undefined
            //                            }

                                       let otherImages: OtherImages = {image_1: others[0].preview !== "invalid" ? others[0].file : undefined, 
                                        image_2: others[1].preview !== "invalid" ? others[1].file : undefined,
                                        image_3: others[2].preview !== "invalid" ? others[2].file : undefined,
                                        image_4: others[3].preview !== "invalid" ? others[3].file : undefined
                                    }
            
            
            result = await this.apiManager.patchListingWithImages(listing, header.file, otherImages, token)
            
            return result
      // } 
    }


    async getWorkspaceWithId(workspaceId: string, token: string): Promise<Result<Workspace>> {

        return await this.apiManager.getWorkspaceWithId(workspaceId, token)
         
    }
     
    async getListingsWithId(workspaceId: string, token: string): Promise<Result<Listing[]>> {

        return await this.apiManager.getListingsWithId(workspaceId, token)
         
    }

    async getWorkspaceListingsWithId(workspaceId: string, token: string, nextURL?: string): Promise<Result<ListResponseData<Listing>>> {

        return await this.apiManager.getWorkspaceListingsWithId(workspaceId, token, nextURL)
         
    }

    async deleteAllWorkspaces(ids: string[], token: string) {

        ids.forEach(async (id, idx)=> {
            const r = await this.apiManager.deleteWorkspaceWithId(id, token)
            
        })
    }

    async deleteWorkspace(id: string, token: string): Promise<Result<unknown>> {
        const r = await this.apiManager.deleteWorkspaceWithId(id, token)
        return r
    }

    async togglePublishedMode(workspaceId: string, isPublished: boolean, token: string): Promise<Result<Workspace>> {

        let result: Result<Workspace> = {type: ResultType.Failure, error: new Error("Couldn't publish workspace")}
        if (!workspaceId  || !token) return result
        result = await this.apiManager.patchIsPublished(workspaceId, token, isPublished)
        //result = {type: ResultType.Success, value: {isPublish: true} }

        return result
    }

    addSavedFileRemoved(removedFile: UploadedFile) {
        let fileIdx = this.savedUploadedFiles.findIndex(item=> item.preview === removedFile.preview)
        if (fileIdx > -1) {
            this.savedFilesRemoved.push(removedFile)
        }
     }

     setSavedUploadedFiles(files: UploadedFile[]) {
         this.savedUploadedFiles = files
     }

     getUpdatedListingImages(): UploadedFile[] {

        if (this.savedUploadedFiles.length === 0) {return this.listingOthers}

        let result = this.savedUploadedFiles.slice()
         // get new uploaded files
         let newFiles = this.listingOthers.filter(item=> item.file.size > 0)
         // get position of removed files in the orignail list
         let pos: number[] = []
         this.removedFiles.forEach((removedItem) => {
              let idx = this.savedUploadedFiles.findIndex(item => item.preview === removedItem.preview)
               if (idx > -1) {
                   pos.push(idx)
               }
            })
         // replace removed files with new uploaded files
         if (pos.length !== newFiles.length) 
         { 
             return this.listingOthers
        }

         for (let i = 0; i < pos.length; ++i) {
             result[pos[i]] = newFiles[i]
         }

         return result

     }

     async deleteListingWithId(listingId: string, token: string): Promise<Result<unknown>>  {
         return this.apiManager.deleteListingWithId(listingId, token)
     }

     officeImagesAdded(listing: Listing): boolean {
         if (!listing.images) {return false}
        //  if (listing.title.includes("dance")) {
        //      debugger
        //  }

        let hasOtherImages = false
        let hasHeader = false
        
        /*
         if (listing.images.others.length < 4) {return false} 
         
        
         if ( (listing.images.others[0] as ListingImage1).image1 &&
              (listing.images.others[1] as ListingImage2).image2 &&
              (listing.images.others[2] as ListingImage3).image3 &&
              (listing.images.others[3] as ListingImage4).image4 ) {
                  hasOtherImages = true
              }*/
        if (listing.images.header) {
           hasHeader = true
        }
         //return  hasHeader  && hasOtherImages
         return  hasHeader  

     }


   

    
}

export const dashboardManager = new WHDashboardDataManager()
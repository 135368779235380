import { Divider, VStack, Text, Image, FormLabel, SimpleGrid, FormControl, Button, AspectRatio, useBreakpointValue, Box, HStack, useMediaQuery } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { MdPeople } from 'react-icons/md';
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout';
import { AmenitiesFormViewProps, DashboardSectionName } from '../Models/Interfaces';

import styles from '../../AddASpace/AddASpace.module.css'
import amenitiesStlyes from './Amenities.module.css'
import { SetErrorContext, UserContext } from '../../../AppProvider/AppProvider';
import { AmenityCategory, AmenityDetails, AmenityItem, ResultType } from '../../../../Models/Interfaces';
import { dashboardManager } from '../Models/WHDashboardDataManager';
import { SetWorkspaceContext, WorkspaceContext } from '../WHManageWorkspaces/ManageWorkspacesProvider';
import {t} from 'i18next'


const AmenitiesFormView = ({formRef, manager, 
    updateFormSubmissionStatus, 
    actionAfterSubmit}: AmenitiesFormViewProps) => {

   let gridColspan = useBreakpointValue({base: 4, sm: 4, md: 6, lg: 6, xl: 6})
   
   const [categories, setCategories] = useState<AmenityCategory[]>([]) 
   const [initialValues, setInitialValues] = useState({})

   const setErrorContext = useContext(SetErrorContext)
   const [amenities, setAmenities] = useState<AmenityDetails[]>([])
   const setWorkspace = useContext(SetWorkspaceContext)
   const [isLoading, setIsloading] = useState(false)
   const workspace = useContext(WorkspaceContext)

   const partner = useContext(UserContext)
    const [id, setId] = useState<string>("")

    const [xsmall, small, small2, medium, large] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 1240px) and (max-width: 1439px)",
      "(min-width: 1440px)",
    ]);

    async function loadAmenites() {
       
       
      const result = await manager.fetchAmenityCategories()
      if (result.type === ResultType.Success) {
           let items = result.value.map((item, idx)=>{
               return {
                 id: item.id,
                 title: item.title,
                 amenities: item.amenities
               }
            })

            let temp: any = {}
            for (let item of items) {
              temp[item.id] = ""
            }
           // console.dir(temp)
            setInitialValues(temp)
            setCategories(items)
            
       } else {
        setErrorContext({title: result.error.name, msg: result.error.message})
     }


      //console.dir(result)
   }

   useEffect(() => {
    
 
     if (!workspace || !workspace.id) { return }
      
     setId(workspace.id)

     if (workspace && workspace.amenities &&
      workspace.amenities.amenities.length > 0) {
         setAmenities(workspace.amenities.amenities)
     
      }

     loadAmenites()

   }, [])
   
   
 
   const isIncluded = (amenityId: string, selectedAmenities: AmenityDetails[]): boolean => {
      
    let found = selectedAmenities.find((item, _)=> item.id === amenityId) 
        return found ? true : false
   }

   const amenityCategoryItemsView = (categories: AmenityCategory[]): ReactNode => {
       return  categories.map((category, idx)=> {
           return (
              
                <VStack key={category.id} spacing={8} alignItems="flex-start" w="full">
                    <Text fontWeight="medium" className="subtitle1">{t(category.title)}</Text>
                      <SimpleGrid w="full" rowGap={10}  columns={xsmall ? 2 : 4} columnGap={4}>
                        {
                          
                            category.amenities.map((item, idx)=> (
                              <VStack 
                                      bg="#fafafa"
                                      w="80%" h={"120px"} p={4} 
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                      key={item.id} borderRadius="10px" border={isIncluded(item.id, amenities) ? "2px solid #38a169" : "unset"}   onClick={()=>{
                                       if (isIncluded(item.id, amenities)) {
                                         let filtered = amenities.filter((selectedItem)=> selectedItem.id !== item.id)
                                         setAmenities(filtered)
                                       } else {
                                        setAmenities([...amenities, item])

                                       }
                              }}>
                                 <Image boxSize="32px" src={item.icon} />
                                 <Text noOfLines={2} textTransform="capitalize" textAlign="center" className="subtitle1">{item.title}</Text>
                              </VStack>
                          ))
                        }
                      </SimpleGrid>
                </VStack>
              
           )
        })

   }
    
    return (
        <>
            <VStack w="full" alignItems="flex-start">
                          <Text
                            padding={{ base: 6, lg: 8 }}
                            // paddingY={{ base: 6, lg: 8 }}
                            w={{ base: "full", md: "75%", lg: "80%" }}
                            className="h6"
                          >
                            {t("What amenities make your workspace unique?")}
                          </Text>
                   <Divider />
                   <AddASpaceLayout>
                       {
                         categories.length > 0 ? (
                          amenityCategoryItemsView(categories)
                         ): null
                       }
                      
                       <HStack w="full" justifyContent="flex-end">
                        <Button
                          w={"20%"}
                          minW="96px"
                          className="button"
                          isLoading={isLoading}
                          colorScheme="primary"
                          variant="solid"
                          onClick={async ()=>{
                             
                            if (amenities.length === 0) {
                              setErrorContext({title: "", msg: t("Please select at least one amenity")})
                              return
                            }
                            if (!partner?.access_token || !workspace) return

                            setIsloading(true)
                           
                             // debugger  
                              let amenityIds = amenities.map(item => item.id)
                              let result = await dashboardManager.updateWorkspaceSection(id, DashboardSectionName.Amenities, amenityIds, partner.access_token)
                               if (result.type === ResultType.Success) {
                                  if (result.value.amenities) {
                                   setWorkspace({...workspace, amenities: {...workspace.amenities, amenities: amenities}})
                                 }
                                     actionAfterSubmit()
                                 // setValues(props.values.name)
                               } else {
                                 setErrorContext({title: result.error.name, msg: result.error.message})
                               }
                             
                             setIsloading(false)
                            }}
                          
                          //disabled
                        >
                          {t("Next")}
                        </Button>
                   </HStack>
                   </AddASpaceLayout>
                  
              </VStack>
           
        </>
    )
}

export default AmenitiesFormView

import { Box, Spacer, VStack, Text, Image } from '@chakra-ui/react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import BannerView from '../../../SupportingViews/BannerView/BannerView';
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView';
import { LearnMoreItemView } from '../../../SupportingViews/LearnMoreItemView/LearnMoreItemView';
import SearchMenu from '../../../SupportingViews/SearchMenu/SearchMenu';
import LearnMoreView from '../../../SupportingViews/LearnMoreView/LearnMoreView';
import RegisterView from '../../../SupportingViews/NewsletterView/NewsletterView';
 
import { appInfo } from '../../../../App';
import { appDataManager } from '../Models/WHAppDataManager';
import { CompartmentCategory, ListingCategory, RecommendedItem, ResultType, SpotlightItem } from '../../../../Models/Interfaces';
import categoryOfficeData from './officeCategoryData.json'
import { WorkhopperPublicAPIManager } from '../../../../Models/WorkhopperPublicAPIManager';
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner';
import OfflineView from '../../../SupportingViews/OfflineView';
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO';
import { useTranslation } from 'react-i18next';

interface OfficeCategorySection {
    title: string 
    subtitle: string
}

interface OfficeCategoryItem {
    section: OfficeCategorySection
}

interface TranslationKeys {
    name: string
    find: string
    mainTitle: string
    mainSubtitle: string 
    categoryMainTitle: string 
    categoryMainContent: string
    reason1Title: string 
    reason1Content: string
    reason2Title: string 
    reason2Content: string
    reason3Title: string 
    reason3Content: string
}

const getTranslationKeys = (categoryObj: ListingCategory): TranslationKeys => {

    let keyToPrepend = ""
    switch (categoryObj.title.trim()) {
        case CompartmentCategory.VO:
            keyToPrepend = "vo"
            break;
        case CompartmentCategory.PO:
             keyToPrepend = "po"
             break;
        case CompartmentCategory.MR:
              keyToPrepend = "mr"
              break;
        case CompartmentCategory.Desks:
                keyToPrepend = "so"
                break;
        default:
            break;
    }

    return {
        name: `${keyToPrepend}.name`,
        find:  `${keyToPrepend}.find`,
        mainTitle: `${keyToPrepend}.mainTitle`,
        mainSubtitle: `${keyToPrepend}.mainSubtitle`, 
        categoryMainTitle:`${keyToPrepend}.main.title`,
        categoryMainContent: `${keyToPrepend}.main.content`,
        reason1Title: `${keyToPrepend}.reasons.0.title`,
        reason1Content: `${keyToPrepend}.reasons.0.content`,
        reason2Title: `${keyToPrepend}.reasons.1.title`,
        reason2Content: `${keyToPrepend}.reasons.1.content`,
        reason3Title: `${keyToPrepend}.reasons.2.title`,
        reason3Content: `${keyToPrepend}.reasons.2.title`
    }
     
} 

const OfficeCategory = () => {
     
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
     const [recommendedItems, setRecommendedItems] = useState<RecommendedItem[]>([])
    const [fetchCount, setFetchCount] = useState(0)
    const {t} = useTranslation()
    const [translationKeys, setTranslationKeys] = useState<TranslationKeys>()
    
    const params = useParams<{id?: string}>()
    let categoryObj: ListingCategory | undefined
    if (params.id) {
        let temp = appDataManager.getCategoriesData("list-cat")
        categoryObj = temp.find(item => item.id === params.id)
    }
   

    const categoryObjData = categoryOfficeData.find(item => categoryObj?.title.toLowerCase().includes(item.name.toLowerCase()) )
    

    useEffect(() => {
         if (params.id) {
            loadSpotlights(params.id)
         }

         
         return () => {
         }
     }, [params.id, fetchCount])

     useEffect(()=>{

        if (categoryObj) {
            const translationKeys = getTranslationKeys(categoryObj)
            setTranslationKeys(translationKeys)
           // console.dir(translationKeys)
         }

     }, [categoryObj])

     const loadSpotlights = useCallback(
        async (selectedCategoryId: string) => {
            setIsLoading(true)
           const result = await WorkhopperPublicAPIManager.shared.getSpotlights()
           if (result.type === ResultType.Success) {
               const items = result.value.map((item):RecommendedItem => ({
                imageURL: item.listing.header,
                id: item.listing.id,
                listingTitle: item.listing.title,
                spotlightTitle: item.title,
                categoryId: item.office_category.id
              })).filter((recommendedItem)=> recommendedItem.categoryId === selectedCategoryId)
               setRecommendedItems(items)
              setError(null)
           } else {
              setError(result.error)
              setRecommendedItems([])
           }
           setIsLoading(false)

        },
        [error, isLoading, recommendedItems]
    )

    const getMainHeaderView = useCallback(():ReactNode => {
        return translationKeys ? (
            <LearnMoreItemView 
                    width={{base: "full", md: "full", lg: "full"}} 
                    //label={categoryObjData.main.title}
                    label={t(translationKeys.categoryMainTitle)}

                 >
                    <Text className="body2">
                        {t(translationKeys.categoryMainContent)}
                    </Text>
                    
                </LearnMoreItemView>
        ):null
    }, [translationKeys])

    if (!categoryObjData) { return null}

    //console.dir(translationKeys)

    // const recommendedItems: RecommendedItem[] = [
    //   { imageURL: meetingRoom1, id: "1" },
    //   { imageURL: meetingRoom2, id: "2" },
    //   { imageURL: meetingRoom3, id: "3" },
    // ];



    // const itemViews =  categoryObjData.reasons.filter(item=>!item.title.includes("benefits of a shared")).map((reasonItem, idx) => (
    //   <LearnMoreItemView
    //     key={idx}
    //     width={{ base: "full", md: "full", lg: "full" }}
    //     //label={reasonItem.title}
    //     label={t(`vo.reasons.${idx}.title`)}
    //   >
    //     <Text className="body2">
    //        {reasonItem.content}
    //     </Text>
    //   </LearnMoreItemView>
    // ));

    
     

    const getLearnMoreViewItems = (): ReactNode[] => {

        return translationKeys ? (
            [
                <LearnMoreItemView
                //key={idx}
                width={{ base: "full", md: "full", lg: "full" }}
                //label={reasonItem.title}
                label={t(translationKeys.reason1Title)}
              >
                <Text className="body2">
                 {t(translationKeys.reason1Content)}
                </Text>
              </LearnMoreItemView>,
               <LearnMoreItemView
               //key={idx}
               width={{ base: "full", md: "full", lg: "full" }}
               //label={reasonItem.title}
               label={t(translationKeys.reason2Title)}
             >
               <Text className="body2">
                {t(translationKeys.reason2Content)}
               </Text>
             </LearnMoreItemView>,
              <LearnMoreItemView
              //key={idx}
              width={{ base: "full", md: "full", lg: "full" }}
              //label={reasonItem.title}
              label={t(translationKeys.reason3Title)}
            >
              <Text className="body2">
               {t(translationKeys.reason3Content)}
              </Text>
            </LearnMoreItemView>
              
            ]
        ): []
    }
   

    return (
        <Box>
            
          {
              isLoading ? (
                  <WHSpinner pt="64px" h="40vh" />
              ): error ? (
                  <OfflineView 
                    pt="64px"
                    msg={error.message}
                    onRetry={()=>{
                        setFetchCount(prev => prev + 1)
                        //loadSpotlights()
                    }}
                  />
              ): (
                <BannerView title={""} 
                subtitle={""}
                items={recommendedItems} />
              )
          }
           <Spacer h={{ base: 6, md: 8, lg: 12 }} />
          <LayoutView>
                <WHSEO 
                    title={`${categoryObj?.title ?? "Office categories"}s`} 
                    meta={[
                    {
                        name: "description",
                        content: `${categoryObj?.title ?? "office categories"}s`
                    },
                    {
                        name: "keywords",
                        content: `workHopper,  ${categoryObj?.title ?? "office categories"}s`
                    }
                    ]}  
                />
                {
                    getMainHeaderView()
                }
               {/* <LearnMoreItemView 
                    width={{base: "full", md: "full", lg: "full"}} 
                    //label={categoryObjData.main.title}
                    label={translationKeys?.mainTitle}

                 >
                    <Text className="body2">
                        {categoryObjData.main.content}
                    </Text>
                    
                </LearnMoreItemView> */}
                {
                    categoryObj && translationKeys ? (
                        <VStack w="full" alignItems="flex-start">
                             <Text className="h4" textTransform="capitalize">{t(translationKeys.find)}</Text>

                            {/* <Text className="h4" textTransform="capitalize">{`Find A ${categoryObj.title}  Near You`}</Text> */}
                            <SearchMenu categoryId={categoryObj.id} actionText={t("search")} />
                        </VStack>
                    ): null
                }
                
              <VStack spacing={{base: 4, md: 6, lg: 6}}>
                 <Image
                    src={categoryOfficeData[0].image}
                    objectFit="cover"
                    w="full"
                    h={{base: 80, lg: "80vh"}}
                 />
                  <LearnMoreView>
                      {
                          getLearnMoreViewItems()
                      }
                      {/* {itemViews} */}
                  </LearnMoreView>
              </VStack>
          </LayoutView>
          <Spacer h={{ base: 8, md: 8, lg: 12 }} />
          <RegisterView isModalOpen={false} />
        </Box>
    )
}

export default OfficeCategory

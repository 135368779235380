import { compareDesc, format } from 'date-fns'
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { RequestItem, ResultType } from '../../../../Models/Interfaces'
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager'
import { mapOfficeCategory } from '../../../../utils/utils'
import { CategoriesContext, UserContext } from '../../../AppProvider/AppProvider'
import OfflineView from '../../../SupportingViews/OfflineView'
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner'
import { Booking } from './Models/Booking'

export const RequestsContext  = React.createContext<RequestItem[]>([])
export const SetRequestsContext = React.createContext<Dispatch<SetStateAction<RequestItem[]>>>(()=>{})
export const CounterContext  = React.createContext<number>(0)
//export const SetCounterContext = React.createContext<Dispatch<SetStateAction<number>>>(()=>{})
export const SetCounterContext = React.createContext<{
    setNextURL: Dispatch<SetStateAction<string | undefined>>
                                                        setPartnerBookingCounter: Dispatch<SetStateAction<number>>, 
                                                        setEnquiriesCounter: Dispatch<SetStateAction<number>>
                                                         setLoadCount: Dispatch<SetStateAction<number>>}>({setNextURL: ()=>{}, setPartnerBookingCounter: ()=>{}, setEnquiriesCounter: ()=>{}, setLoadCount: ()=>{}})

export const SetParamsContext = React.createContext<Dispatch<SetStateAction<Params>>>(()=>{})
export const RequestStateContext = React.createContext<{error: Error | null | undefined, isLoading: boolean, nextURL?: string}>({error: null, isLoading: false})

interface WHManageRequestsProviderProps {
    children: ReactNode
}

interface Params {
    bizId: string 
    requestType: string
}

const WHManageRequestsProvider = ({children}: WHManageRequestsProviderProps) => {
    
    const [enquiries, setEnquiries] = useState<RequestItem[]>([])
   // const [bookings, setBookings] = useState<RequestItem[]>([])
    const [loadCount, setLoadCount] = useState(0)
    const [partnerBookingCounter, setPartnerBookingCounter] = useState(0)
    const [enquiriesCounter, setEnquiriesCounter] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>()
    const officeCategories = useContext(CategoriesContext)
    const user = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(false)
   
    const [params, setParams ] = useState({bizId: "", requestType: ""})
    const [nextURL, setNextURL] = useState<string>()

    const loadBookings = useCallback(async ()=>{

        if (!user?.access_token) {return}


        setIsLoading(true)

         let result = await WorkhopperAPIManager.shared.getPartnerBookings(user.access_token, nextURL)
        if (result.type === ResultType.Success) {
                    //console.dir(result)

            const value = result.value.results.map((item, idx)=>{
                return {
                        name: item.extra_details.listing_name,// item.listing.title,
                        //dateCreated: `${format(new Date(item.date_booked), "PP")}`,
                       // dateCreated: `${format(new Date(item.date_booked), "yyyy-MM-dd'T'HH:mm:ss")}`,
                        dateCreated: `${format(new Date(item.date_booked), "PPpp")}`,

                        status: item.status,
                        userName: `${item.extra_details.first_name} ${item.extra_details.last_name}`,
                        category:  item.extra_details.category, //mapOfficeCategory(false, categories, item.listing), 
                        id: idx + 1,
                        detailURL: `/dashboard/manage+requests/${params.bizId}/${params.requestType}/${item.id}/detail`,
                        is_accept_term: item.is_accept_term,
                        itemId: item.id.toString(),
                        payStatus: item.is_paid ? "Paid" : "Not Paid"
                    }
                
            })
         
            /*
          let data = result.value.map((item,idx) => (

              {
                  name: item.listing,
                  dateCreated: `${format(new Date(item.date_created), "PP")}`,
                  status: "Pending",
                  userName: `${item.first_name} ${item.last_name}`,
                  category: "Private Office" , //mapOfficeCategory(false, categories, item.listing), 
      
                  id: idx + 1,
                  detailURL: `/dashboard/manage+requests/${params.bizId}/${params.requestType}/${item.id}/detail`
              }
          ))*/
              
              //console.dir(value)
              //console.dir(value.flat())
            //   let temp = enquiries.slice()
            //   setEnquiries([])

              setNextURL(result.value.next)
              setEnquiries((prev)=>{
                return [...prev, ...value].sort((a, b)=> {
                          return compareDesc(new Date(a.dateCreated), new Date(b.dateCreated))
                      })
             })
            //   setEnquiries([...enquiries, ...value].sort((a, b)=> {
            //       return compareDesc(new Date(a.dateCreated), new Date(b.dateCreated))
            //   }))
            
             setError(null)
         
        } else {
           setError(result.error)
        }

        setIsLoading(false)
        

    }, [isLoading, enquiries, error, params.requestType, params.bizId, nextURL])

    const loadRequest = useCallback(async () => {

        if (!user?.access_token) {return}

        setIsLoading(true)
         let result = await WorkhopperAPIManager.shared.getEnquiries(user.access_token, nextURL)
        if (result.type === ResultType.Success) {
           // console.dir(result.value)

          let data = result.value.results.map((item,idx) => (

              {
                  name: item.extra_details.listing_name,
                  dateCreated: `${format(new Date(item.date_created), "PP")}`,
                  status: "Pending",
                  userName: `${item.first_name} ${item.last_name}`,
                  category: item.extra_details.category , //mapOfficeCategory(false, categories, item.listing), 
                  id: idx + 1,
                  detailURL: `/dashboard/manage+requests/${params.bizId}/${params.requestType}/${item.id}/detail`,
                  is_accept_term: item.is_accept_term,
                  itemId: item.id
              }
          ))
          //debugger
         // setEnquiries([])

          setNextURL(result.value.next)
        //   setEnquiries([...enquiries, ...data].sort((a, b)=> {
        //     return compareDesc(new Date(a.dateCreated), new Date(b.dateCreated))
        // }))
        setEnquiries((prev)=>{
           return [...prev, ...data].sort((a, b)=> {
                     return compareDesc(new Date(a.dateCreated), new Date(b.dateCreated))
                 })
        })
            setError(null)
         
        } else {
           setError(result.error)
        }

        setIsLoading(false)
            
        },
        [isLoading, enquiries, error, params.bizId, params.requestType, nextURL],
    )
    
    useEffect(() => {
        //debugger
        if (params.requestType === "enquiries") {
            
            loadRequest()
        } else if (params.requestType === "bookings") {
            loadBookings()
        }


         
      }, [params.bizId, params.requestType, partnerBookingCounter, enquiriesCounter])

    //   useEffect(() => {
         
    //       setEnquiries([])
    //       setNextURL(undefined)
         
    //   }, [params.requestType])
    
 
    return (
        <>
            {
                
                <RequestStateContext.Provider value={{error, isLoading, nextURL}}> 
                    <RequestsContext.Provider 
                    value={enquiries}
                >
                    <CounterContext.Provider value={loadCount}>
                    <SetRequestsContext.Provider value={setEnquiries}>
                        <SetCounterContext.Provider value={{setLoadCount, setEnquiriesCounter, setPartnerBookingCounter, setNextURL}}>
                            <SetParamsContext.Provider value={setParams}>
                                {
                                     isLoading && !nextURL ? (
                                        <WHSpinner w="full" pt="64px" />
                                      ) : 
                                      error && !nextURL ? (
                                        <OfflineView
                                         onRetry={async () => {
                                             if (params.requestType === "enquiries") {
                                                loadRequest()
                                             } else {
                                                 loadBookings()
                                             }
                                           //setLoadCount((prev) => prev + 1);
                                         }}
                                         pt="unset"
                                         msg={error.message}
                                       />
                                      ): 
                                    (
                                        <>
                                        {children}
                                        </>
                                     )
                                }
                       
                            </SetParamsContext.Provider>
                        </SetCounterContext.Provider>
        
                    </SetRequestsContext.Provider>
                    </CounterContext.Provider>
                </RequestsContext.Provider>
                </RequestStateContext.Provider>
            }
        </>
       
    )
}

export default WHManageRequestsProvider

import { Button, Divider, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Spacer, Stack, Switch, VStack } from '@chakra-ui/react';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { Ref, useEffect, useRef, useState } from 'react'
import { MdFlag, MdPerson } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Country, FederatedForm, ResultType } from '../../../Models/Interfaces';
import routes from '../../../routes';
import { yupGoogleSignUpValidation } from '../../Screens/WHApp/AddASpace/Schema/YupSpaceValidation';
import { signUpDataManager } from '../../Screens/WHApp/SignUp/SignUpDataManager';
import OfflineView from '../OfflineView';
import WHSpinner from '../WHSpinner/WHSpinner';

interface WHGoogleFormProps {
    
    formRef: Ref<FormikProps<FederatedForm>> 
    setData: (country: string, terms: boolean)=>void
    onCountryItemsChange: (items: Country[]) => void
    countryItems: Country[]
    //onFirstnameChange: (event: React.ChangeEvent<HTMLInputElement>)=>void
    //onLastnameChange: (event: React.ChangeEvent<HTMLInputElement>)=>void
    //onCountryChange: (event: React.ChangeEvent<HTMLSelectElement>)=>void
}

const WHGoogleForm = (formProps: WHGoogleFormProps) => {

   // let formRef = useRef<FormikProps<FederatedForm>>(null)
    const [countryItems, setCountryItems] = useState<Country[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)   
    const fieldHeight = 10
    const history = useHistory()

    const countryItemViews = formProps.countryItems.map((item, idx)=> 
  <option key={item.id}>
    {item.name}
  </option>)

  const loadData = async ()=> {
    setLoading(true)
    setError(null)
    let result = await signUpDataManager.fetchCountries()
    
    if (result.type === ResultType.Success) {
        //setCountryItems(result.value)
        formProps.onCountryItemsChange(result.value)
     } else {
       setError(new Error(result.error.message))
       //setCountryItems([])
       //setErrorContext({title: result.error.name, msg: result.error.message})
    }
    setLoading(false)
  }

   useEffect(() => {
       loadData()
       return () => {}
   }, [])

   if (loading) {
       return (
           <WHSpinner pt="64px" h="10vh" />
        ) 
   }

   if (error) {
       return (
            <OfflineView onRetry={loadData} msg={error.message}  pt="64px" />
       )
   }
  
    return (
          <>
            <Formik
            initialValues={{
            //  fname: "",
            //  lname: "",
             country: "",
             terms:  false,
           }}
           // initialStatus={{ isValidating: false }}
           validationSchema={yupGoogleSignUpValidation}
           onSubmit={async (values, { setSubmitting, setStatus }) => {
              formProps.setData(values.country, values.terms)
              
           }}
           // validateOnBlur={false}
           //validateOnChange={false}
           enableReinitialize={true}
           innerRef={formProps.formRef}
            >
              {(props)=> {
                
                return (
                  <Form  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}>
                     <VStack  alignItems={"center"}>
                     {/* <Stack direction={{ base: "column", md: "row" }} w="full">
                               <Field name="fname">
                                 {({ field, form }: any) => {
                                   return (
                                     <FormControl
                                       className="caption"
                                       isRequired
                                       htmlFor="fname"
                                       isInvalid={
                                         form.errors.fname && form.touched.fname
                                       }
                                     >
                                       <FormLabel fontWeight={500}>
                                         First name
                                       </FormLabel>
                                       <InputGroup>
                                         <InputLeftAddon
                                           bg="primary.200"
                                           children={<MdPerson color="#fff" />}
                                         />
                                         <Input
                                           {...field}
                                           id="fname"
                                           name="fname"
                                             onChange={props.handleChange}
                                           type="text"
                                           h={fieldHeight}
                                         />
                                       </InputGroup>
                                       <FormErrorMessage>
                                         {form.errors.fname}
                                       </FormErrorMessage>
                                      </FormControl>
                                   );
                                 }}
                               </Field>
       
                               <Spacer />
                               <Field name="lname">
                                 {({ field, form }: any) => {
                                   return (
                                     <FormControl
                                       className="caption"
                                       isRequired
                                       id="lname"
                                       isInvalid={
                                         form.errors.lname && form.touched.lname
                                       }
                                     >
                                       <FormLabel fontWeight={500}>
                                         Last name
                                       </FormLabel>
                                       <InputGroup>
                                         <InputLeftAddon
                                           pointerEvents="none"
                                           bg="primary.200"
                                           children={<MdPerson color="#fff" />}
                                         />
                                         <Input
                                           {...field}
                                           id="lname"
                                           name="lname"
                                           //onChange={formProps.onLastnameChange}
                                           onChange={props.handleChange}
                                           type="text"
                                           h={fieldHeight}
                                         />
                                       </InputGroup>
                                       <FormErrorMessage>
                                         {form.errors.lname}
                                       </FormErrorMessage>
                                     </FormControl>
                                   );
                                 }}
                               </Field>
                             </Stack> */}
                              

                             <Field name="country">
                                 {({ field, form }: any) => {
                                   return (
                                     <FormControl
                                       className="caption"
                                       isRequired
                                       htmlFor="country"
                                       isInvalid={
                                         form.errors.country && form.touched.country
                                       }
                                     >
                                       <FormLabel fontWeight={500} fontSize={"15px"}>
                                         Country
                                       </FormLabel>
                                       <InputGroup>
                                         <InputLeftAddon
                                           pointerEvents="none"
                                           bg="primary.200"
                                           children={<MdFlag color="#fff" />}
                                         />
       
                                         <Select
                                           {...field}
                                           id="country"
                                           name="country"
                                           //onChange={formProps.onCountryChange}
                                           onChange={props.handleChange}
                                           placeholder="Choose a country"
                                         >
                                           {countryItemViews}
                                         </Select>
                                       </InputGroup>
                                       <FormErrorMessage>
                                         {form.errors.country}
                                       </FormErrorMessage>
                                     </FormControl>
                                   );
                                 }}
                               </Field>
                               <Field name="terms">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                isInvalid={
                                  form.errors.terms && form.touched.terms
                                }
                                isRequired
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                // justifyContent="center"
                              >
                                <FormLabel htmlFor="terms" mb="0">
                                  I agree to the 
                                  &nbsp;
                                  <Button variant="link" 
                                  onClick={()=>{
                                    history.push(routes.termsConditions.path)
                                  }
                                   
                                  }
                                  showLine
                                   >
                                       terms and conditions
                                  </Button>
                                </FormLabel>
                                <Switch
                                  onChange={props.handleChange}
                                  isChecked={props.values.terms}
                                  size="sm"
                                  name="terms"
                                  {...field}
                                />
                                <FormErrorMessage>
                                  {form.errors.terms}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                     </VStack>       
                  </Form>
                )
              }}
            </Formik>
            
              
            </>
         )
    
}

export default WHGoogleForm

/**
 * Abstract:
 * This view shows the first question to a signed in user for the add a listing
 * activity.
 * If the user is adding a new listing, the question is about the listing's identity
 * and the user's role in the listing
 * If the user has a saved progress, it presents options to continue
 * with it or add a new listing
 */

import {
  Box,
  Button,
  Divider,
  Image,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  useMediaQuery,
  VStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import  { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { t } from 'i18next';

//import countryItems from "../../../../../node_modules/world_countries_lists/data/en/countries.json"
import { MdPhotoCamera } from "react-icons/md";
import AddASpaceLayout from "../../../SupportingViews/AddASpaceLayout/AddASpaceLayout";

/** styles */
 import {
  DashboardSectionName,
  DescFormProps,
  SpaceProps,
  yupDescValidation,
} from "../Models/Interfaces";
import { dashboardManager } from "../Models/WHDashboardDataManager";
import { SetErrorContext, UserContext } from "../../../AppProvider/AppProvider";
import Dropzone, { FileRejection } from "react-dropzone";
import { ResultType } from "../../../../Models/Interfaces";
import {
  SetWorkspaceContext,
  WorkspaceContext,
} from "../WHManageWorkspaces/ManageWorkspacesProvider";
import { readlink } from "fs";
import axios from "axios";
import { yupDescriptionValidation } from "../Models/Schema/DescriptionValSchema";
import ErrorFocus from "../../../SupportingViews/ErrorFocus/ErrorFocus";

interface UploadedFile {
  file: File;
  preview: string;
}

const AboutSpace = ({
  formRef,
  updateFormSubmissionStatus,
  manager,
  actionAfterSubmit,
}: DescFormProps) => {
  const [values, setValues] = useState({ name: "", desc: "" });
  const [image, setImage] = useState("");
  const partner = useContext(UserContext);
  const setErrorContext = useContext(SetErrorContext);
  const setWorkspaceContext = useContext(SetWorkspaceContext);
  const [isLoading, setIsLoading] = useState(false);
  const workspace = useContext(WorkspaceContext);

  const loadHeaderFile = async () => {
    if (
      workspace &&
      workspace.title &&
      workspace.description &&
      workspace.header &&
      partner?.access_token
    ) {
      // debugger
      let options = {
        headers: {
          Authorization: partner.access_token,
        },
      };
      let e = await axios.get(workspace.header, options);
      //let blob = await e.blob()
      //new File()
      setValues({ name: workspace.title, desc: workspace.description });
      setUploadedFiles([{ file: new File([], ""), preview: workspace.header }]);
    }
  };

  useEffect(() => {
    if (workspace && workspace.title && workspace.description) {
      setValues({ name: workspace.title, desc: workspace.description });
      if (workspace.header) {
        setUploadedFiles([
          { file: new File([], ""), preview: workspace.header },
        ]);
      }
    }
  }, []);

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const handleDrop = (accFiles: File[], rejFiles: FileRejection[]) => {
    
    if (rejFiles.length > 0) {
      setErrorContext({
        title: "",
        msg: "File seems to exceed 5mb. Try again",
      });
      return;
     }
    setUploadedFiles(
      accFiles.map((accFile: File) => {
        return { file: accFile, preview: URL.createObjectURL(accFile) };
      })
    );
  };

  const [isMediumScrn, isLargeScrn] = useMediaQuery([
    "(min-width: 30em)",
    "(min-width: 62em)",
  ]);
  let questionFontSize = "h5";
  if (isMediumScrn) {
    questionFontSize = "h5";
  } else if (isLargeScrn) {
    questionFontSize = "h5";
  }

  // console.dir(workspace)

  return (
    <>
      <Box
        // bg="blue"
        position="relative"
        w="full"
        // h="full"
        //h={{ base: "70%", md: "100%" }}
        borderTopRightRadius={{ base: 12, md: "unset" }}
        borderTopLeftRadius={{ base: 12, md: "unset" }}
      >
        <Formik
          initialValues={values}
          // initialStatus={{isValidating: false}}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={yupDescriptionValidation}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            let file: File | undefined = undefined;

            if (partner && partner.access_token) {
              if (uploadedFiles.length > 0) {
                file = uploadedFiles[0].file;
              }
              setIsLoading(true);

              let result = await dashboardManager.postWorkspaceSection(
                workspace?.id,
                DashboardSectionName.Desc,
                values,
                file,
                partner.access_token
              );
              setIsLoading(false);
              //debugger
              if (result.type === ResultType.Success) {
                if (
                  result.value.id &&
                  result.value.header &&
                  result.value.title &&
                  result.value.description
                ) {
                  dashboardManager.workspaceId = result.value.id;

                  setWorkspaceContext({
                    ...workspace,
                    id: result.value.id,
                    description: result.value.description,
                    header: result.value.header,
                    title: result.value.title,
                  });

                  actionAfterSubmit();
                }
              } else {
                setErrorContext({
                  title: result.error.name,
                  msg: result.error.message,
                });
              }
            }
          }}
          innerRef={formRef}
        >
          {(props) => {
            return (
              <Form>
                <VStack
                  spacing={{ base: 4, md: 6, lg: 6 }}
                  alignItems="flex-start"
                >
                  <Text
                    padding={{ base: 6, lg: 8 }}
                    // paddingY={{ base: 6, lg: 8 }}
                    w={{ base: "full", md: "75%", lg: "80%" }}
                    className="h6"
                  >
                    {t("Highlight")}
                  </Text>
                  <Divider />
                </VStack>
                <AddASpaceLayout>
                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}

                          //htmlFor="name"
                        >
                          {t('Name your workspace')}
                        </FormLabel>
                        <Input
                          onChange={props.handleChange}
                          {...field}
                          name="name"
                          type="text"
                          placeholder={t('Name your workspace')}
                        ></Input>
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="desc">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.desc && form.touched.desc}
                        isRequired
                      >
                        <FormLabel
                          htmlFor="desc"
                          fontWeight={500}
                          fontSize={"15px"}
                        >
                          {t("Description")}
                        </FormLabel>
                        <Textarea
                          {...field}
                          rounded="lg"
                          name="desc"
                          // value={value}
                          onChange={props.handleChange}
                          placeholder=""
                          size="md"
                        />
                        <FormErrorMessage>{form.errors.desc}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <VStack w="full">
                    <Text w="full" textAlign="left" className="subtitle1">
                      {t("Upload Header Photo (Must not exceed 5mb)")}
                    </Text>

                    <Dropzone
                      onDrop={handleDrop}
                      accept="image/*"
                      minSize={1024}
                      maxSize={1048576 * 5}

                      // maxSize={3072000}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box
                          display="grid"
                          placeItems="center"
                          rounded="lg"
                          w={"full"}
                          h={"344px"}
                          bg="gray.400"
                          {...getRootProps({ className: "dropzone" })}
                        >
                          {uploadedFiles.length === 0 ? (
                            <MdPhotoCamera fontSize="128px" color="gray" />
                          ) : null}
                          <input title="header-photo" {...getInputProps()} />
                          {uploadedFiles.map((uploadedFile) => (
                            <Image
                              key={uploadedFile.preview}
                              objectFit="contain"
                              rounded="lg"
                              h="inherit"
                              w="inherit"
                              src={image === "" ? uploadedFile.preview : image}
                            />
                          ))}
                        </Box>
                      )}
                    </Dropzone>
                  </VStack>

                  <HStack justifyContent="flex-end" w="full">
                    <Button
                      isLoading={props.isSubmitting}
                      w={"20%"}
                      minW="96px"
                      className="button"
                      colorScheme="primary"
                      variant="solid"
                      onClick={() => {
                        if (uploadedFiles.length < 1) {
                          setErrorContext({
                            title: "",
                            msg: t("Please upload a header photo"),
                          });
                          return;
                        }
                        props.submitForm();
                      }}
                    >
                      {t("Next")}
                    </Button>
                  </HStack>
                </AddASpaceLayout>
                <ErrorFocus />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default AboutSpace;

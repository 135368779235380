import { Box, VStack, Text, HStack, Link, Image } from '@chakra-ui/react'
import React from 'react'
import { BreakPoint } from '../OfficeDetail/Models/Interfaces'
 
import styles from './WHHome.module.css'
import { useTranslation  } from "react-i18next";
 

interface WHGetStartedProps  {
    breakpoint: BreakPoint
}

const WHGetStarted = ({breakpoint}: WHGetStartedProps) => {
    const {t} = useTranslation()
    let titleFontsize = "20px"
    let descFontsize = "14px"
    let subtitleFontsize = "18px"
    let buttonWidth = "70%"
    let width = "full"
    let btnHeight = "40px"
    let mainHeight = "50vh"

    let padding = 4

    if (breakpoint === BreakPoint.M) {
        width = "50%"
        padding = 8
        buttonWidth = "20%"
        mainHeight="80vh"
    }
    if (breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL) {
        width = "50%"
        titleFontsize = "57px"
        descFontsize = "25px"
        subtitleFontsize = "34px"
        padding = 8
        buttonWidth = "20%"
        mainHeight="100vh"


    }

    return (
        <Box
           w="full"
             backgroundImage={"../images/getStartedImg.png"}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            h={mainHeight}
        >
         <VStack
          h="full"
          bgGradient="linear(to-b, #212121 15%, transparent 50%)"
          alignItems="center"
          pt="6%"
         >
            <VStack textAlign="center" w="75%" color="white">
                <Text
                     className={styles.title}
                    color="secondary.500"
                >
                     {t("getdownload")}
                    {/* GET STARTED */}
                </Text>
                <Text
                    fontFamily="Inter" fontWeight="600" fontSize={subtitleFontsize}
                >
                    {t("getdownload1")}
                  {/* Get to work and save 10% */}
                </Text>
                <Text
                     className={styles.subtitle}
                >
                    {t("getdownload2")}
                    {/* Download the WorkHopper app today and get a 10% discount on coworking,
                    Office and meeting space for your first month. */}
                </Text>
            </VStack>
            <HStack h={btnHeight}>
                <Link 
                   href="https://apps.apple.com/ng/app/work-hopper/id1606715790"
                  target="_blank"   
                h="full">
                   <Image h="full" src="../images/appStoreBtn.png"/>
                </Link>
                <Link 
                    href="https://play.google.com/store/apps/details?id=com.workhopper.hopper"
                    target="_blank" 
                   h="full">
                   <Image h="full" src="../images/playstoreBtn.png"/>
                </Link>
            </HStack>
           </VStack>
        </Box>
    )
}

export default WHGetStarted

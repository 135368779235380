/**
 * Abstract: A view that collects information to log a user in
 * 
 */

import { AspectRatio, Box, Button, ButtonGroup, Divider, Heading,   HStack,   Icon,   Image, Input, InputGroup, InputLeftAddon,  Modal,  ModalBody,  ModalCloseButton,  ModalContent,  ModalFooter,  ModalHeader,  ModalOverlay,  PinInput,  PinInputField,    Spacer,    Stack,   Text, useDisclosure, useMediaQuery, VStack } from '@chakra-ui/react';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import myImg from "../../../../Assets/workspace4.jpg"

import styles from "../SignUp/SignUp.module.css"
import '../../../../App.css'

import {
  
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from "@chakra-ui/react"
import { MdLock, MdMail } from 'react-icons/md';
import { FcGoogle  } from 'react-icons/fc';

import { loginDataManager } from './LoginDataManager';
import { AccessToken, Country, FedAccountType, FederatedForm, ResultType } from '../../../../Models/Interfaces';
import { SetErrorContext, SetSuccessContext, SetUserContext } from '../../../AppProvider/AppProvider';
import { useHistory, useLocation } from 'react-router';
import { signUpDataManager } from '../SignUp/SignUpDataManager';
import WHGoogleLogin from '../../../SupportingViews/WHGoogleLogin/WHGoogleLogin';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../../../../utils/utils';
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO';
import { roundToNearestMinutes } from 'date-fns';
import routes from "../../../../routes";
import WHGoogleForm from '../../../SupportingViews/WHGoogleForm/WHGoogleForm';
import { FormikProps } from 'formik';
import WHShowPassword from '../../../SupportingViews/WHShowPassword/WHShowPassword';
import { useTranslation  } from "react-i18next";


interface ForgotPasswordFormProps {

  setAccountType: (accountType: FedAccountType)=>void
}


export const LoginWithGoogle = ()=> {

  const [googleData, setGoogleData] = useState<{gmail: string, gname: string, imageUrl: string, fname: string, lname: string}>({gmail: "", gname: "", imageUrl: "", fname: "", lname: ""})
  const setErrorContext = useContext(SetErrorContext)
  const history = useHistory()
  const setUserContext = useContext(SetUserContext)
  const [isLoading, setIsLoading] = useState(false)
  const setSuccess = useContext(SetSuccessContext)
  const [counter, setCounter] = useState(0)
  const [formData, setFormData] = useState<{country: string, terms: boolean}>()
  let formRef = useRef<FormikProps<FederatedForm>>(null)

  const location = useLocation()
 
  const { onClose: onLoginModalClose, isOpen, onOpen: onLoginModalOpen } = useDisclosure()
  const [countryItems, setCountryItems] = useState<Country[]>([])

  const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ((res as GoogleLoginResponse).profileObj) {
        const response = res as GoogleLoginResponse
         refreshTokenSetup(res)
        setGoogleData({gmail: response.profileObj.email, gname: response.profileObj.name, imageUrl: response.profileObj.imageUrl, fname: response.profileObj.givenName, lname: response.profileObj.familyName})
        
    } else {
  
    }
   
    //refreshTokenSetup(res);
  };

  const onFailure = (res: any) => {
    //debugger
    // if (!res.error.includes("popup_closed_by_user")) {
       
    //   setErrorContext({title: "", msg: "Couldn't login. Try again"})
    // }
    //console.dir(res)

  };

  // the clientId should be moved to a .env file
  const clientId = "836248753025-f5co9ukm0v9fuqrs4u0bmvfojt3pp9sj.apps.googleusercontent.com"
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    //isSignedIn: true,
    accessType: 'offline',
   // cookiePolicy: 'single_host_origin',
    cookiePolicy:"http://localhost:3000/"
    //autoLoad: false
    
    
  });

   

  useEffect(() => {

    ;(async ()=>{
      //debugger
      if (googleData.gmail === "" || googleData.gname === "") {return}
      setIsLoading(true);
      // log user in
      // register user with google data
      // log user in
      // 
     // debugger
      const data = { email: googleData.gmail, password: googleData.gmail };
      let result = await loginDataManager.loginUser(data);
      if (result.type === ResultType.Success) {

        
        let registeredUserResult = await loginDataManager.getLoginUser(
          result.value.access
        );

        if (registeredUserResult.type === ResultType.Success) {
          registeredUserResult.value.access_token = result.value.access;
          registeredUserResult.value.avatar = registeredUserResult.value.avatar || googleData.imageUrl
       
          setUserContext(registeredUserResult.value);
          setSuccess({ title: "", msg: "Login was successfull" });
          setIsLoading(false);
          if (location.pathname === routes.login.path) {
            history.push("/");
          }
          
        } else {
       
          setErrorContext({
            title: registeredUserResult.error.name,
            msg: registeredUserResult.error.message,
          });
        }
        
      } else if (result.error.message.toLowerCase().includes("incorrect")) {
        onLoginModalOpen()
        /*
        setErrorContext({
          title: result.error.name,
          msg: result.error.message,
        });*/
      } else {
        
        setErrorContext({
          title: result.error.name,
          msg: result.error.message,
        });
      }
      setIsLoading(false);
    })()
    
    return () => {
      
    }
  }, [googleData, counter])

  useEffect(()=>{

     (async ()=>{

      if (formData === undefined) {return}


      let entryIdx = countryItems.findIndex((item, idx) =>
      item.name.includes(formData.country)
    );
    const data = {
     // ...formRef.current.values,
      country: countryItems[entryIdx].id,
      name: googleData.gname,
      email: googleData.gmail,
      fname: googleData.fname,
      lname: googleData.lname,
      terms: formData.terms
    };
     
    setIsLoading(true);

    let result =
      await WorkhopperAPIManager.shared.registerWithFederatedAccount(data);

    if (result.type === ResultType.Failure) {
      setIsLoading(false);
      setErrorContext({
        title: result.error.name,
        msg: result.error.message,
      });
      return;
    }
    onLoginModalClose();

    setCounter((prev) => prev + 1);

    })()
       
    
    
  }, [formData])

  return (
    <VStack w="full">
         
        <Button w="85%" 
            rounded="3xl"
            shadow="md"
            h={12}
            isLoading={isLoading}
            onClick={()=> {
              signIn()
            }}
            background="#f7f7f7"
             //colorScheme="orange"
             >
               <Icon 
                  fontSize="24px"
                  mr="16px"
                  as={FcGoogle} />
               Login With Google
        </Button>
       <Modal
          isCentered
          onClose={onLoginModalClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={xsmall ? "md" : "2xl"}
           // trapFocus={false}
        >
          <ModalOverlay />
          <ModalContent
            position={xsmall ? "fixed" : undefined}
            bottom={xsmall ? "0px" : "unset"}
            mb={xsmall ? "0" : "unset"}
             borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
               Continue with Google
            </ModalHeader>
            
            <Divider />
            <ModalCloseButton 
              //outline="none" 
              //_focus={{ outline: "none" }} 
              />
            <ModalBody>
              <VStack h="70vh" pt={16} alignItems={"center"}>
                  <WHGoogleForm 
                    countryItems={countryItems}
                    formRef={formRef}
                    setData={(country: string, terms: boolean)=>{
                       setFormData({...formData, country, terms})
                      //setGoogleData()
                    }} 
                    onCountryItemsChange={(items: Country[])=>{
                        setCountryItems(items)
                    }}
                  />
              </VStack>
              </ModalBody>
            <Divider />
            <ModalFooter>
              <Spacer />
              <Button 
                    isLoading={isLoading}
                    colorScheme="primary"
                    onClick={async()=>{ 
                      if (formRef.current) {
                        
                        formRef.current.handleSubmit()
                      }
                      
                    }}
               >
                        Done
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </VStack>
   
  )
}

const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [otp, setOTP] = useState<string>("0")
  const [resendOTP, setResendOTP] = useState(false)
  const onEmailChange = (event: any) => setEmail(event.target.value)
  const onPasswordChange = (event: any) => setPassword(event.target.value)
  const onOTPChange = (value: any) =>  setOTP(value)
  const fieldHeight = 10
  const [resetSuccessful, setResetSuccessful] = useState(false)
  const setErrorContext = useContext(SetErrorContext)
  const setSuccess = useContext(SetSuccessContext)
  const [isLoading, setIsLoading] = useState(false)


  return (
    <VStack w="full">
      {!resetSuccessful ? (
        <>
          <FormControl isRequired id="email">
            <FormLabel className="caption" fontWeight={500} fontSize={"15px"}>
              Email
            </FormLabel>
            <InputGroup>
              <InputLeftAddon
                bg="primary.200"
                children={<MdMail color="#fff" />}
              />
              <Input type="email" onChange={onEmailChange} h={fieldHeight} />
            </InputGroup>
          </FormControl>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              if (!email.match( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                return;
              }

              setIsLoading(true)
              const result = await WorkhopperAPIManager.shared.resetPassword({
                email,
              });
              if (result.type === ResultType.Success) {
                setSuccess({ title: "", msg: result.value.message });
                setPassword("")
                setResetSuccessful(true);
              } else {
                setErrorContext({ title: "", msg: result.error.message });
              }
              setIsLoading(false)

            }}
            w="full"
            colorScheme="primary"
            className="button"
          >
            Reset
          </Button>
        </>
      ) : (
        <>
          <FormControl isRequired id="password">
            <FormLabel className="caption" fontWeight={500} fontSize={"15px"}>
              New Password
            </FormLabel>
            <InputGroup>
              <InputLeftAddon
                bg="primary.200"
                children={<MdLock color="#fff" />}
              />
              <Input
                type="password"
                onChange={onPasswordChange}
                h={fieldHeight}
              />
            </InputGroup>
          </FormControl>
          <Spacer h={8} />
          <HStack w="full" justifyContent="center">
            <PinInput onChange={onOTPChange} otp>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          {
            resendOTP ? (
              <Button
              isLoading={isLoading}
            onClick={async () => {
              if (!email) {
                return;
              }
              setIsLoading(true)
              const result = await WorkhopperAPIManager.shared.resetPassword({
                email,
              });
              if (result.type === ResultType.Success) {
                setOTP("")
                setSuccess({ title: "", msg: `An OTP has been sent to ${email}` });
                setResetSuccessful(true);
              } else {
                setErrorContext({ title: "", msg: result.error.message });
              }
              setIsLoading(false)

            }}
            w="full"
            colorScheme="primary"
            className="button"
          >
            Resend OTP
          </Button>
            ) : (
              <Button
              isLoading={isLoading}
              onClick={async () => {
                if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
                  setErrorContext({ title: "", msg: "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number. Can contain one special case Character" });
                  return
                }
                setIsLoading(true)
                const result =
                  await WorkhopperAPIManager.shared.confirmNewPassword({
                    email,
                    password,
                    otp,
                  });
                if (result.type === ResultType.Success) {
                  setSuccess({ title: "", msg: result.value.message });
                  props.setAccountType(FedAccountType.CUSTOM)
                } else {
                  setErrorContext({ title: "", msg: result.error.message });
                  if (result.error.message.toLowerCase().includes("verified")) {
                    setResendOTP(true)
                  } else {
                    props.setAccountType(FedAccountType.CUSTOM)
                  } 
                }
                setIsLoading(false)

              }}
              w="full"
              colorScheme="primary"
              className="button"
            >
              Confirm Password
            </Button>
            )
          }
         
        </>
      )}
    </VStack>
  );
}

const Login: FC = ()=>{
    const {t} = useTranslation()
    const fieldHeight = 10
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const onEmailChange = (event: any) => setEmail(event.target.value)
    const onPasswordChange = (event: any) => setPassword(event.target.value)
    const history = useHistory()
    const location = useLocation()
    const [showPassword, setShowPassword] = useState(false)

    const setErrorContext = useContext(SetErrorContext)
    const setUserContext = useContext(SetUserContext)
    const [accessToken, setAccessToken] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const setSuccess = useContext(SetSuccessContext)
    const [selectedAccountType, setSelectedAccountType] = useState<FedAccountType>(FedAccountType.CUSTOM)
  
    const loginWithGoogle = useCallback(()=>{
    
      //let result = WorkhopperAPIManager.shared.loginWithGoogle(data)
    }, [])

    const onSelectAccountType = (accountType: FedAccountType) => {
      setSelectedAccountType(accountType)
    }

    return (
      <Box
      className="layout"
      paddingX={{base: "24px", md: 5, lg: "4vw"}} 
       >
         <WHSEO 
            title="Login" 
            meta={[
              {
                name: "description",
                content: "Login with credentials"
              },
              {
                name: "keywords",
                content: "Login, Workhopper"
              }
            ]}  
          />
         <Stack
          
          width="100%"
          margin={"0 auto"}
          direction={{ base: "column", md: "row" }}
          spacing={10}
        >
          <Box
            marginX={{ md: "auto" }}
            w={{base: "full", md: "50.5%", lg: "50%", xl: "50%" }}
            h={{base: "calc(100vh - 112px) !important", md: "calc(100vh - 144px) !important" }}
            // overflowY={{ md: "scroll" }}
            className={`${styles.scrollbar} ${styles.scrollbarFox}`}
            // padding={{ base: "24px", md: 10}}
            pt="0px !important"
          >
            
            <Box alignItems="flex-start" mb={8} 
                // mt={{base: 4, lg: "0px"}}
                >
              <Heading color="brand.secondary" className="h3"  mb={4} fontSize={"34px !important"}>
              {t("welcomeback")}
                {/* Welcome Back */}
              </Heading>
              <Text variant="subtitle" className="subtitle1" fontSize={"18px !important"}>
              {t("pleaselogin")}
                {/* Please login to your account */}
                </Text>
            </Box>
            
            <VStack spacing={6}>
             {
               selectedAccountType === FedAccountType.CUSTOM ? (
                 <>
                     <FormControl isRequired id="email">
                <FormLabel className="caption" fontWeight={500} fontSize={"15px"}>
                {t("emails")}
                </FormLabel>
                <InputGroup>  
                     <InputLeftAddon bg="primary.200" children={<MdMail color="#fff" />} />
                     <Input type="email" 
                             onChange={onEmailChange}
                            // placeholder="jane@yahoomail.com"
                             h={fieldHeight} />
                </InputGroup> 
              </FormControl>

              <FormControl isRequired id="password">
                <FormLabel className="caption" 
                 fontWeight={500} fontSize={"15px"}>
                  {t("password")}
                </FormLabel>
                <VStack w="full">
                <InputGroup>  
                     <InputLeftAddon bg="primary.200" children={<MdLock color="#fff" />} />
                     <Input type={showPassword ? "text" : "password"} onChange={onPasswordChange} h={fieldHeight} />
                </InputGroup>
                <WHShowPassword 
                showPassword={showPassword} 
                onShowPasswordChange={(e: any)=>{setShowPassword(e.target.checked)}}/>
                </VStack>
               
              </FormControl>
              
              <VStack w="full">
              <Button
                height={12}
                color="white"
                width="100%"
                borderRadius="4px"
              colorScheme="secondary"
                isLoading={isLoading}
              onClick={async ()=>{
                let data = {
                  email: email,
                  password: password
                  
                 }
                 //let result: Result<AccessToken>
                 setIsLoading(true)

                 let result = await loginDataManager.loginUser(data)
                 if (result.type === ResultType.Success) {
                 
                   let registeredUserResult = await loginDataManager.getLoginUser(result.value.access)
                
                   if (registeredUserResult.type === ResultType.Success) { 
                     
                    registeredUserResult.value.access_token = result.value.access
                    //signUpDataManager.saveRegisteredUserData(registeredUserResult.value)
                    setUserContext(registeredUserResult.value)
                    setSuccess({title: "", msg: "Login was successfull"})
                    setIsLoading(false)
                    if (registeredUserResult.value.user_type === "partners") {
                      history.push(`/dashboard/${registeredUserResult.value.id}/manage+workspaces`)
                    
                    } else if (location.pathname === routes.login.path) {
                      history.push("/")
                    }  else {} 

                    } else {
                     setErrorContext({title: registeredUserResult.error.name, msg: registeredUserResult.error.message})
                   }
                   //signUpDataManager.saveRegisteredUserData(result.value)
                  //history.push("/")
                } else {
                  setErrorContext({title: result.error.name, msg: result.error.message})
               }
               setIsLoading(false)
              }}
              >
                {t("login")}
                {/* Login */}
              </Button>
              <Spacer h={4} />
              <Button 
                  className="body1"
                  onClick={()=>{
                    setSelectedAccountType(FedAccountType.FORGOTPASSWORD)
                  }}
                  variant="link"
                >{t("forgetpassword")}? {t("changepassword")}</Button>
                <Text className="subtitle1">OR</Text>
              <LoginWithGoogle />
              </VStack>
              
                 </>
               ): selectedAccountType === FedAccountType.FORGOTPASSWORD ? (
                 <ForgotPasswordForm setAccountType={onSelectAccountType} />
               ): null
             }
            </VStack>

            <VStack width="full" mt={8}>
              
               
            </VStack>
          </Box>

          <Box
           
            mt={1.2}
            display={{ base: "none", md: "block", lg: "block", xl: "block" }}
            w={{ base: "50%", md: "57%", lg: "57%", xl: "57%" }}
           
          >
            <AspectRatio ratio={4 / 3}>
                <Image  rounded="lg"   src={myImg} />
            </AspectRatio>
          </Box>
        </Stack>
      </Box>
    );
}

export default Login

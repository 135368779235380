import {
  Box,
  Text,
  Image,
  VStack,
  Button,
  SimpleGrid,
  Link,
  Spacer,
  HStack,
  Divider,
  useMediaQuery,
  IconButton,
  Spinner,
  Center,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import meetingRoom1 from "../../../../Assets/meeting1.jpg";
import meetingRoom2 from "../../../../Assets/meeting2.jpg";
import meetingRoom3 from "../../../../Assets/meeting3.jpg";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import {
  NavLink as ReactLink,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { dashboardManager } from "../Models/WHDashboardDataManager";
import { MdAdd } from "react-icons/md";
import WorkspaceItemView from "../../../SupportingViews/WorkspaceItemView/WorkspaceItemView";
import { SetAlertContext, SetErrorContext, SetSuccessContext, SetUserContext, UserContext } from "../../../AppProvider/AppProvider";
import { dataManager } from "../../WHApp/AddASpace/Models/ListSpaceDataManager";
import { signUpDataManager } from "../../WHApp/SignUp/SignUpDataManager";
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager";
import { PartnerDetail, ResultType, Workspace } from "../../../../Models/Interfaces";
import coworkImg from "../../../../Assets/workspace2.jpg";
import { FileRejection } from "react-dropzone";
import { UploadedFile } from "../Models/Interfaces";
import OfficeDropzone from "../../../SupportingViews/OfficeDropzone";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import OfflineView from "../../../SupportingViews/OfflineView";

import placeholderBanner from "../../../../Assets/banner-icon.png";
import WHIncompleteError from "../../../SupportingViews/WHIncompleteError/WHIncompleteError";
import { convertToObject } from "typescript";
import WHSeeMoreList from "../../../SupportingViews/WHSeeMoreList/WHSeeMoreList";
import { useTranslation } from 'react-i18next';

const Workspaces = () => {
  const { path, url } = useRouteMatch();
  const setErrorContext = useContext(SetErrorContext);
  const setSuccessContext = useContext(SetSuccessContext);
  const setAlertContext = useContext(SetAlertContext) 
  const setUserContext = useContext(SetUserContext);
  const [isPhotoloading, setIsPhotoLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [counter, setCounter] = useState(0)
  const [nextURL, setNextURL] = useState<string>()
  let itemToDeleteId = useRef<string | undefined>()
  const {t} = useTranslation()

  const [isLoading, setIsLoading] = useState(false);
   const partner =  useContext(UserContext) //signUpDataManager.getRegisteredUserData();
  let bizId = "";
   if (partner && partner.partner_details) {
    bizId = partner.partner_details.id
  }

  const [uploadedFile , setUploadedFile] = useState<UploadedFile[]>([])

  
  const [wkspaceItems, setwkspaceItems] = useState<Workspace[]>([]);

   

  const [xsmall, small, small2, medium, large] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  let imgHeight = "244px";
  if (small) {
    imgHeight = "320px";
  } else if (medium || large) {
    imgHeight = "320px";
  }

  const existingWorkspaceURL = (id: string): string => {
    const workspaceURL = `/dashboard/${bizId}/manage+workspaces/${id}/detail/description`
    // const workspaceURL =
    //   xsmall || small 
    //     ? `/dashboard/${bizId}/manage+workspaces/${id}/detail/navlist`
    //     : `/dashboard/${bizId}/manage+workspaces/${id}/detail/description`;
    return workspaceURL;
  };

  const newWorkspaceURL = `/dashboard/${bizId}/manage+workspaces/new/detail/description`;
     

  //  console.log(dashboardManager.isDetailSectionComplete() &&
  //  item.listing_count && item.listing_count > 0)
  

  const wkspaceItemViews = wkspaceItems
    .filter((item, idx) => item.id  && item.title)
    .map((item, idx) => {
      // console.dir(item)
      // console.log(item.listing_count === 0)
      //console.log(dashboardManager.isDetailSectionComplete(item))
      return (
        <Box
        position="relative"
        >
        <Link
          textDecoration="none !important"
          as={ReactLink}
          to={existingWorkspaceURL(item.id!)}
          key={item.id}
        >
          <WorkspaceItemView
            key={item.id}
            imageURL={item.header || placeholderBanner}
            title={item.title || "Untitled"}
            officeCount={item.listing_count}
          >
            <HStack 
              ml={"-24px !important"}
              w="full" paddingY={2} spacing={"0px"} paddingX={4}>
              {/* <Text className="subtitle2">5 Offices</Text> */}
              
              
              <Button
                //textTransform="capitalize"
                paddingX={4}

                //padding="0px"
                className="button"
                rounded="md"
                variant="ghost"
                colorScheme="whatsapp"
                onClick={async (e) => {
                  
                  
                }}
              >
                {t("Edit")}
              </Button>
              <Button
                
                paddingX={4}
                className="button"
                rounded="md"
                variant="ghost"
                colorScheme="secondary"
                onClick={async (e) => {
                  
                  if (!partner?.access_token || !item.id) return;
                  e.stopPropagation();
                  e.preventDefault()
                  itemToDeleteId.current = item.id
                  setIsOpen(true)
                
                  //onDeleteItem(item.id, partner.access_token)
                 
                }}
              >
                {t("Delete")}
              </Button>
              <Spacer/>
            </HStack>
          </WorkspaceItemView>
        </Link>
          {
          
            !dashboardManager.isDetailSectionComplete(item) ||
            
            (item.listing_count === 0) ? 
            (
              <WHIncompleteError 
                text={t("Details provided are incomplete. Edit to complete details")}
                />
            ):null
          }
        </Box>
      );
    });



     useEffect(() => {
       
         setAlertContext({title: t("Delete Office"),
                      msg: t("Are you sure?"),
                      leftButton: {label: t("No"), action: ()=>{
                        //console.log("closing")
                        setIsOpen(false)
                      }},
                      rightButton: {label: t("Yes"), action: async ()=>{
                        if (!partner?.access_token || !itemToDeleteId.current) return;

                        let result = await dashboardManager.deleteWorkspace(
                          itemToDeleteId.current,
                          partner.access_token
                        );
                        if (result.type === ResultType.Success) {
                          let temp = wkspaceItems.filter(someitem => (someitem.id !== itemToDeleteId.current))
                          setwkspaceItems(temp)
                        }
                      }},
                      isOpen: isOpen, 
                      onClose: ()=>{setIsOpen(false)}
                      
                    
                    })
      return () => {
       }
    }, [isOpen])

    const updateHeaderPhoto = useCallback(
      async () => {
         if (!partner?.access_token || uploadedFile.length === 0 || !bizId) return

        if (uploadedFile[0].file.size <= 0) {
          setSuccessContext(undefined)
          setErrorContext({title: "", msg: t("Please upload a banner image")})
          return
        }

         setIsPhotoLoading(true)
        let result = await WorkhopperAPIManager.shared.patchPartnerWithHeader(
                                      bizId, 
                                      uploadedFile[0].file, 
                                      partner.access_token
                                      )
         if (result.type === ResultType.Success) {
            //let value = result.value as PartnerDetail
            const temp: PartnerDetail = {...(partner.partner_details as PartnerDetail), header: result.value.header}
            //setUserContext({...partner, partner_details: {...partner.partner_details, header: result.value.header}})
            setUserContext({...partner, partner_details: temp})
            setErrorContext(null)

            setSuccessContext({title: "Success", msg: "Your banner updated successfully"})
          //  setErrorContext(unde)
        } else {
          setSuccessContext(undefined)
            setErrorContext({title: "Failed", msg: result.error.message})

        }    
        setIsPhotoLoading(false)                     
 
      },
      [uploadedFile],
    )

    const handleDrop = (accFiles: File[], rejFiles: FileRejection[]) => {

      if (rejFiles.length > 0) {
        setErrorContext({title: "", msg: "File seems to exceed 5mb. Try again"})
        return
        //if (rejFiles.errors[0].code.includes("too-large"))
      }
      setUploadedFile(
       accFiles.map((accFile: File) => {
         return { file: accFile, preview: URL.createObjectURL(accFile) };
       })
     );
   };

   const loadWorkspaces = useCallback(async () => {
     
    let token = "";
   if (partner?.access_token) {
     token = partner.access_token;
   }
   
   setIsLoading(true);
   let result = await WorkhopperAPIManager.shared.getWorkspace(
     true,
     undefined,
     token,
     nextURL
   );
   //console.dir(result)
   setIsLoading(false);
   if (result.type === ResultType.Success) {
     setwkspaceItems([...wkspaceItems, ...result.value.results]);
     setError(undefined)
     setNextURL(result.value.next)
     // setValues(props.values.name)
   } else {
     setError(result.error)
     
   }
 }, [isLoading, nextURL])

  useEffect(() => {
    loadWorkspaces();
  }, [counter]);

  useEffect(() => {
      let file: UploadedFile = {file: new File([], ""), preview: partner?.partner_details?.header ?? ""} 
     setUploadedFile([file])
    return () => {
      
    }
  }, [partner])

  return (
  
      <LayoutView>
        <VStack mt={8} w="full" 
        //h="100vh" 
        alignItems="flex-start">
        <Text className="body2">{t("Click/tap the image")}</Text>
        <OfficeDropzone
              files={uploadedFile}
              width="full"
              height={imgHeight}
              onDrop={handleDrop}
              image={partner?.partner_details?.header}
            />
            <Center>
            <Button 
              onClick={()=>{
                 updateHeaderPhoto()
              }}
              isLoading={isPhotoloading}
              colorScheme="primary"
            >
              {t("Update Banner Photo")}
            </Button>
            </Center>
        </VStack>
        <>
          {isLoading && !nextURL? (
           <WHSpinner h={"64px"} />
          ): error && !nextURL ? (
            <OfflineView 
              msg={error.message}
              pt="24px"
              onRetry={()=>{setCounter(prev=> prev + 1)}}
            />
          ) : 
          
          wkspaceItems.length === 0 ? (
            <VStack w="full" h="full" minH="50vh">
              <Text className="h6">{t("You have 0 workspaces")}</Text>
              <Link
                //w="30%"
                textDecoration="none !important"
                as={ReactLink}
                // to={`${url}/new/detail/description`}
                to={newWorkspaceURL}
              >
                <Button
                  variant="outline"
                  shadow="lg"
                  colorScheme="secondary"
                  aria-label={t("Add A Workspace")}
                   
                >{t("Add A Workspace")}</Button>
              </Link>
            </VStack>
          ) : (
            <VStack w="full" spacing={6} paddingY={8}>
            
           
            <HStack w="full">
              <Text style={{ textTransform: "capitalize" }} className="h6">
                {t("Welcome")} {partner?.partner_details?.name}
              </Text>
              <Spacer />
              {wkspaceItems.length > 0 ? (
                <Link
                  //w="30%"
                  textDecoration="none !important"
                  as={ReactLink}
                  // to={`${url}/new/detail/description`}
                  to={newWorkspaceURL}
                >
                  <Button
                    size="md"
                    className="button"
                    leftIcon={<MdAdd />}
                    colorScheme="secondary"
                    variant="outline"
                  >
                    {t("ADD A NEW SPACE")}
                  </Button>
                </Link>
              ) : null}
            </HStack>
            <WHSeeMoreList
                        data={wkspaceItems}
                       isLoading={isLoading}
                       loadData={()=>{setCounter(prev=>prev+1)}}  
                       error={error} 
                       nextURL={nextURL} 
                       spinnerHeight="64px"    
                       useGrid 
                       isWorkspaceGrid    
                   >
                     {wkspaceItemViews}
                  </WHSeeMoreList>
            {/* <SimpleGrid
              pb={16}
              w="full"
              columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
              gap={8}
              columnGap={8}
            >
              {wkspaceItemViews}
            </SimpleGrid> */}
            </VStack>
          )}
        </>
        
      </LayoutView>
    
  );
};

export default Workspaces;

import { Grid, GridItem, Image, Skeleton, useMediaQuery } from '@chakra-ui/react';
import React from 'react'
import LayoutView from '../../../../SupportingViews/LayoutView/LayoutView';
import { OfficeCarouselProps } from '../Models/Interfaces'
import placeholderImg from "../../../../../Assets/banner-icon.png"


const OfficeCarouselLarge = ({items}: OfficeCarouselProps) => {

    // const items: OfficePhotoItem[] = [
    //     { imageURL: "../../../Assets/workspace4.jpg", id: "1" },
    //     { imageURL: "../../../Assets/workspace2.jpg", id: "2" },
    //     { imageURL: "../../../Assets/workspace3.jpg", id: "3" }
    //   ];

      

      

      const [xsmall, small, medium, large] = useMediaQuery(["(max-width: 599px)",        
                                        "(min-width: 600px) and (max-width: 904px)",
                                        "(min-width: 905px) and (max-width: 1439px)",
                                        "(min-width: 1440px)"
                                    ])

       let cols = 4
       let gutterWidth = 4
       let gutterHeight = gutterWidth
       if (small) {
           cols = 8
           gutterWidth = 6
           gutterHeight = gutterWidth
       } else if (medium || large) {
            cols = 12
            gutterWidth = 8
            gutterHeight = gutterWidth
       }

       const skeletonView =  <Skeleton  height="full" width="full" />


 

    return (
        <>
                <GridItem 
                rowSpan={8} colSpan={6}>
                    <Image 
                          fallback={
                            skeletonView
                          }
                          src={items[0].imageURL} 
                           borderTopLeftRadius="xl" 
                           borderBottomLeftRadius="xl"
                            width="full"
                            height="full"
                            objectFit="cover"/>
                </GridItem>
                <GridItem rowSpan={4} colSpan={3}>
                <Image 
                    fallback={skeletonView}
                   src={items[1].imageURL || placeholderImg} 
                           backgroundColor={items[1].imageURL ? "transparent" : "#eee"}
                            width="full"
                            height="full"
                            objectFit="cover"/>
                </GridItem>
                
                <GridItem rowSpan={4} colSpan={3}>
                    <Image src={items[2].imageURL || placeholderImg} 
                              backgroundColor={items[2].imageURL ? "transparent" : "#eee"}
                              fallback={skeletonView}
                                borderTopRightRadius="xl" 
                                width="full"
                                height="full"
                                objectFit="cover"/>
                </GridItem>
                <GridItem rowSpan={4} colSpan={3}>
                  <Image src={items[3].imageURL || placeholderImg}
                            backgroundColor={items[3].imageURL ? "transparent" : "#eee"} 
                             fallback={skeletonView}
                             width="full"
                            height="full"
                            objectFit="cover"/>
                </GridItem>
                <GridItem  rowSpan={4} colSpan={3}>
                  <Image src={items[4].imageURL || placeholderImg} 
                           backgroundColor={items[4].imageURL ? "transparent" : "#eee"}
                           fallback={skeletonView}
                            borderBottomRightRadius="xl"
                            width="full"
                            height="full"
                            objectFit="cover"/>
                </GridItem>
                
          </>
    )
}

export default OfficeCarouselLarge

import { ResponsiveValue, useMediaQuery, VStack } from '@chakra-ui/react'
import React, {ReactNode} from 'react'

interface LayoutProps {
    children?: ReactNode
}


 

const LayoutView = ({children}: LayoutProps) => {

  const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  let spacing = 6
  let paddingX: ResponsiveValue<number> | string = 4
  if (medium) {
    spacing = 8
    paddingX = 5
  } else if (large || xl) {
    spacing = 12
    paddingX = "5vw"
  }
  
    return (
        <VStack
          w="full"
          spacing={spacing}
          paddingX={paddingX}
          //className="layout"
        >
          {children}
        </VStack>
    )
}

export default LayoutView

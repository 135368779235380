import { Box, Image } from "@chakra-ui/react";
import Dropzone, { FileRejection } from "react-dropzone";
import { MdCamera, MdPhotoCamera } from "react-icons/md";
import { UploadedFile } from "../Screens/WHDashboard/Models/Interfaces";
import placeholderBanner from "../../Assets/banner-icon.png"


interface OfficeDropzoneProps {
    files: UploadedFile[],
    width: string,
    height: string,
    onDrop: (accFiles: File[], rejFiles: FileRejection[]) => void
    image: string | undefined
    minSize?: number
    maxSize?: number
}


// const OfficeDropzone = ({files, width, height, onDrop, image}: OfficeDropzoneProps) => {
//     return (
//       <Dropzone
//         onDrop={onDrop}
//         accept="image/*"
//         minSize={1024}
//         maxSize={3072000}
//         maxFiles={1}
//       >
//         {({ getRootProps, getInputProps }) => (
//           <Box
//             rounded="lg"
//             display="grid"
//             placeItems="center"
//             w={width}
//             h={height}
//             bg="gray.400"
//             {...getRootProps({ className: "dropzone" })}
//           >
            
//             {files.length === 0 ? (
//               <MdCamera fontSize="64px" color="gray" />
//             ) : image ? (
//                 <Image
//                   objectFit="contain"
//                   rounded="lg"
//                   h="inherit"
//                   w="inherit"
//                   src={image }
//                 />
//             ): null}
//             <input {...getInputProps()} />
           
//             {files.map((uploadedFile, idx) => (
              
//                 <Image
//                   filter="blur(5px)"
//                   key={uploadedFile.preview + idx}
//                   objectFit="contain"
//                   rounded="lg"
//                   h="inherit"
//                   w="inherit"
//                   src={uploadedFile.preview }
//                 />

               
//             ))}
//           </Box>
//         )}
//       </Dropzone>
//     );
//   };

  const OfficeDropzone = ({files, width, height, onDrop, image, minSize, maxSize}: OfficeDropzoneProps) => {
    return (
      <Dropzone
        onDrop={onDrop}
        accept="image/*"
        minSize={minSize ?? 1024}
        maxSize={maxSize ?? 1048576 * 5}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            rounded="lg"
            display="grid"
            placeItems="center"
            w={width}
            h={height}
            //bg="white"
            bg="gray.300"
            {...getRootProps({ className: "dropzone" })}
          >
            
            {files.length === 0 ? (
              <MdPhotoCamera fontSize="64px"  />
            ) : null}
            <input {...getInputProps()} />
            
            {files.map((uploadedFile, idx) => (
              
                <Image
                  fallbackSrc={placeholderBanner}
                  filter={uploadedFile.file.size > 0 ? "blur(5px)" : "unset"} 
                  key={uploadedFile.preview + idx}
                  objectFit={uploadedFile.file.size > 0 ? "contain" : "cover"}
                  rounded="lg"
                  h="inherit"
                  w="inherit"
                  src={uploadedFile.preview }
                />

               
            ))}
          </Box>
        )}
      </Dropzone>
    );
  };
  
 export default OfficeDropzone
import { Button, HStack } from '@chakra-ui/react'
import OfflineView from '../OfflineView'
import WHSpinner from '../WHSpinner/WHSpinner'

interface WHLoadMoreProps<T> {
    nextURL: string | undefined
    counterUpdater: ()=>void
    error?: Error | null
    isLoading: boolean
    listings: T[]
    w: string 
    colorScheme: string
    spinnerHeight?: string
 }

const WHLoadMore = <T extends unknown>({nextURL, counterUpdater, error, isLoading, listings, w, colorScheme, spinnerHeight}: WHLoadMoreProps<T>) => {
    return (
        <>
         {
          nextURL ? (
            <HStack w="full" justifyContent="center">
              {
                isLoading && listings.length > 0 ? (
                  <WHSpinner h={spinnerHeight} />
                ) : error ? (
                  <OfflineView pt={spinnerHeight ?? "64px"} 
                  onRetry={counterUpdater}
                  msg={error.message} />
                ) : (
                  <Button w={w}  colorScheme={colorScheme}
                    onClick={counterUpdater}
                  >See More</Button>
                )
                
              }
            </HStack>
          ):null
        }
        </>
    )
}

export default WHLoadMore

import { Country, FederatedForm, RegistrationResponse, Result, UserData } from "../../../../Models/Interfaces";
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager";
import { AccountForm } from "../AddASpace/Models/Interfaces";

class SignUpDataManager {

    private apiManager = WorkhopperAPIManager.shared
    private storageKey = "userData"

    async registerNonPartners(data: AccountForm, isSocial: boolean): Promise<Result<RegistrationResponse>> {

        const userData = {
            partnersName: "", 
                            partnersEmail: "",
                            fbURL: "",
                            twitterURL: "",
                            instagramURL: "",
                            websiteURL: "",
                            userType: 'users',
                            terms: (data as any).terms,
                            isSocial,
                            ...data
        }
        return await this.apiManager.register(userData)
    }
    
    /*
    async registerNonPartnerSocial(data: FederatedForm): Promise<Result<RegistrationResponse>> {

        const userData = {
            partnersName: "", 
                            partnersEmail: "",
                            fbURL: "",
                            twitterURL: "",
                            instagramURL: "",
                            websiteURL: "",
                            userType: 'users',
                            ...data
        }
        return await this.apiManager.register(userData)

    }*/
   
    saveRegisteredUserData(data: RegistrationResponse) {
        localStorage.setItem(this.storageKey, JSON.stringify(data))
    }

    getRegisteredUserData(): RegistrationResponse | null {
        const temp = localStorage.getItem(this.storageKey)
        if (temp) {
            return JSON.parse(temp)
        }
        return null
       // localStorage.setItem(data.email, JSON.stringify(data))
    }

    logUserout() {
        localStorage.removeItem("userData")
    }

    async fetchCountries(): Promise<Result<Country[]>> {
        return await this.apiManager.countries()
    }

    navigateToPage(url: string) {

    }
}

export const signUpDataManager = new SignUpDataManager()
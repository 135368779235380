import { Box, VStack, Text } from '@chakra-ui/react'
 import LayoutView from '../../../SupportingViews/LayoutView/LayoutView'
import PolicyView from '../../../SupportingViews/PolicyView/PolicyView'
import PolicyFrView from '../../../SupportingViews/PolicyView/PolicyFrView'
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const {i18n} = useTranslation()

    return (
        <LayoutView>
            <VStack   alignItems="flex-start">
            <Text className="h5">Privacy Policy</Text>
            {i18n.language == 'en' ? (<PolicyView />):(<PolicyFrView />)}
         </VStack>
         </LayoutView>
        
    )
}

export default PrivacyPolicy

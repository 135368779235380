import { Avatar, Button, HStack } from '@chakra-ui/react'
import React from 'react'
import { MdMenu } from 'react-icons/md'
import { RegistrationResponse } from '../../../Models/Interfaces'

interface WHAvatarMenuButtonProps {
    registeredUser: RegistrationResponse
    onOpen: ()=>void
}

const WHAvatarMenuButton = ({onOpen, registeredUser}: WHAvatarMenuButtonProps) => {
    return (
        <Button
        onClick={onOpen} 
        >
        <HStack>
          <MdMenu color="#262949" fontSize="20px"/>
          <Avatar 
            size="sm"
            name={registeredUser.user_type === "partners" ? registeredUser.partner_details?.name ?? "" : registeredUser.first_name} 
            src={registeredUser.avatar ?? ""}
            />
        </HStack>
    </Button>
    )
}

export default WHAvatarMenuButton

import { Divider, HStack, SimpleGrid, Text, VStack, Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react'
import { MdKitchen } from 'react-icons/md';
import { AmenityDetails } from '../../../../../Models/Interfaces';
import AddASpaceLayout from '../../../../SupportingViews/AddASpaceLayout/AddASpaceLayout';
import { AmenityItem } from '../Models/Interfaces';

interface AmenityItemViewProps {
  detail: AmenityDetails
}

interface AllAmenitiesProps {
  amenities: AmenityDetails[]
}

// function groupAmenities(list: AmenityDetails[], key: string): AmenityDetails[][] {
//   return list.reduce(function(rv: any, x: any) {
//     (rv[x[key]] = rv[x[key]] || []).push(x);
//     return rv;
//   }, {});
// };

function groupBy(list: any, keyGetter: { (amenityDetail: AmenityDetails): string } ): any {
  const map = new Map();
  list.forEach((item: any) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}


export const AmenityItemView = ({icon, title}: AmenityDetails) =>{

    const item: AmenityItem = {
      categoryId: "Amenities Category",
      name: "kitchen",
      title: "kitchen",
      id: "person"
    };

    return (
          
              <VStack 
              paddingX={4}
              bg="#fafafa"
              w="144px"
              //w="33%" 
              h={"120px"} 
              //p={4} 
              alignItems={"center"}
              justifyContent={"center"}
               borderRadius="10px"
               //border={isIncluded(item.id, amenities) ? "2px solid #38a169" : "unset"}
               >
        <Image boxSize="32px" src={icon} />
        <Text noOfLines={2} textTransform="capitalize" textAlign="center" className="subtitle1">{title}</Text>
        </VStack>
          
        
        
       
    )
}

const AllAmenities = ({amenities}: AllAmenitiesProps) => {

  const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  let columns = 2
  if (small) {
    columns = 3
  } else if (!xsmall) {
    columns = 3
  }
  // if (!small) {
  //   columns = 4
  // }


  const groupOfCategoryItems = groupBy(amenities, amenityDetail => amenityDetail.category)
  const categoryItems = Array.from(groupOfCategoryItems, ([category, items]) => ({category, items})) 

    //const items = [1, 2, 3, 4, 5, 6]
  //  console.dir(categoryItems)
    //const amenityItemViews = amenities.map((item, idx) => {
      const amenityItemViews =  categoryItems.map((categoryItem: {category: string, items: AmenityDetails[]}, idx: any) => {
     // const amenityItemViews = groupAmenities(amenities, "category").map((categoryItems, idx) => {

        
        return (
            <VStack key={categoryItem.category} alignItems="flex-start" w="full">
                {/* <Text className="subtitle1">{item.description}</Text> */}
                <SimpleGrid
                    w="full"
                    columns={columns}
                    columnGap={4}
                    gap={4}
                >
                  {
                    categoryItem.items.map(item => (
                      <AmenityItemView key={item.id}  {...item} />
                    ))
                  }
                 
                </SimpleGrid>
            </VStack>
        )
    })


    return (
      <>
        {/* <VStack spacing="0px" alignItems="flex-start">
          <Text
            padding={{ base: 6, lg: 8 }}
            // paddingY={{ base: 6, lg: 8 }}
            w={{ base: "full", md: "75%", lg: "80%" }}
            className="h6"
          >
            Amenities Offered
          </Text>
          <Divider />
        </VStack> */}
        <AddASpaceLayout>
             {amenityItemViews}
        </AddASpaceLayout>
      </>
    );
}

export default AllAmenities

 import { ReactNode } from "react";
import { AccountForm } from "../Components/Screens/WHApp/AddASpace/Models/Interfaces";
import { Queries } from "../Components/Screens/WHApp/SearchResuts/SearchResults";
import { ExtraDetails } from "../Components/Screens/WHDashboard/Models";

export enum Adpage {
    SEARCH = "search",
    HOMEPAGE = "homepage"
}

export enum CompartmentCategory {
    PO = "Private Office",
    VO = "Virtual Office",
    MR = "Meeting Room",
    Desks = "Shared Office"
}

export interface RecommendedItem {
    imageURL: string
    id: string
    listingTitle?: string
    listingHeader?: string 
    spotlightTitle?: string 
    categoryId?: string
}

export interface BookingDetail {
    durationType: DurationCategory
    duration: number 
    guests: number
    startDate: Date | undefined
    endDate: Date | undefined

}

export interface Advert {
    id: number 
    image: string
    date_posted: string
    url: string
    ad_type: Adpage
}

export interface RequestItem {
    name: string
    dateCreated: string
    status: string
    userName: string
    category:  string //mapOfficeCategory(false, categories, item.listing), 
    
    id: number
    detailURL: string
    is_accept_term: boolean
    itemId: string
    payStatus?:  string
}
export interface CartItem {
    id: string
    listing?: PublicListing
    bookingDetail: BookingDetail 
}

export interface Bookable {
    id: string
    duration_type: string
    duration: number 
    start_date: string
    end_date?: string
    listing?: PublicListing
}

export interface BookingData {
    id?: number
    booking_id?: string
    amount?: string
    items: Bookable[]
    payment_mode: string 
    user: number
    total_items: number
    currency?: string
}
export interface Cart {
    selectedId: string,
    items: CartItem[]
}
  
export enum DurationCategory {
    HOUR = "Hourly",
    DAY = "Daily",
   // DAY = "Day",

    MONTH = "Monthly",
    YEAR = "Yearly"
}

export enum CartActionType {
    ADD = "addItem",
    UPDATE = "updateItem",
    REMOVE = "removeItem",
    SELECT = "selectItem",
    CLEAR = "clearItems"
}

export enum PaymentVerificationType {
    BOOKING = "booking",
    PREMIUMLISTING = "premium_listing"
}

export enum FedAccountType {
    GOOGLE = "google",
    FB = "fb",
    CUSTOM = "custom",
    FORGOTPASSWORD = "forgot_password"
}

export interface LinkProps {
    children: ReactNode

}
export interface RequestURLProps {
    children: ReactNode
    to: string
    
}

export interface ListingLinkProps {
    to: string
    children: ReactNode
    showLine?: boolean
}



export interface WorkspaceItemViewProps {
    imageURL: string, 
    title: string,
    children?: ReactNode
    officeCount?: number
}

export interface OfficeItemsListProps {
    title: string
    listings: PublicListing[]
    city?: string 
    capacity?:string
    category?: string[]
    setListings: (listings: PublicListing[])=>void
    loadData?: (queriesObj: Queries)=>void
    isLoading?: boolean
    setQueries: (queriesObj: Queries)=>void 
    queryObj: Queries
    //onApplyFilters: ()=>{}
}

export interface UserData extends AccountForm {
    
    userType: string 
    partnersName: string 
    partnersEmail: string
    websiteURL: string
    fbURL: string 
    twitterURL: string 
    instagramURL: string
    terms: boolean
    isSocial: boolean
    isGoogle?: boolean
   
}

export interface FederatedForm {
    // fname: string 
    // lname: string 
    terms: boolean
    country: string
}

export interface RegistrationResponse {

    id:  string
    first_name: string 
    last_name: string 
    email: string 
    username: string 
    last_login: string 
    is_superuser: boolean
    is_staff: boolean
    is_active: boolean 
    date_joined: string 
    avatar?: string
    phone?: string 
    address: string 
    user_type: string 
    country: string
    date: string 
    groups: string[]
    user_permissions: string[] 
    business_name?: string | undefined
    access_token?: string | undefined
    partner_details?: PartnerDetail

}

export enum ResultType {
    Success = "success",
    Failure = "failure"
}

export interface AppProviderProps {
    children: ReactNode
}

export interface WHError {
    title: string 
    msg: string
}

export interface WHSuccess {
    title: string 
    msg: string
}

export interface LoginData {
    email: string
    password: string
    isSocial?: boolean 
    country?: string
}

export interface AuthResponse {
    access: string
}

export interface Country {

    id: string
    name: string
    currency: string
    code: string
}



interface Amenities {
    amenities: AmenityDetails[]
}

interface ExtraDays {
    days?: string[]
}

interface AvailableHours {
    start_time?: string 
    end_time?: string
    sat_start_time?: string
    sat_end_time?: string
    sun_start_time?: string
    sun_end_time?: string

}

export interface WorkspaceLocation {
    id: number
    city: string 
    state: string 
    country?: Country
}
export interface Workspace {

    location?: WorkspaceLocation | number

    id?: string
    partner?: string
    title?: string 
    amenities?: Amenities
    description?: string 
    business_email?: string 
    email?: string 
    website_url?: string 
    street?: string 
    city?: string 
    country?: Country
    capacity?: number
    size?: string
    extra_days?: ExtraDays
    isActive?: boolean
    is_publish?: boolean
    header?: string
    zip_code?: string
    available_hours?: AvailableHours
    listing_count?: number
   // listings: Listing[]
 }



export interface Amenity {
    id: string 
    category: string 
    title: string
    description: string 
    icon: string
}

export interface AmenityItem {
    id: string 
    title: string 
    icon: string
}

export interface AmenityCategory {
    id: string
    title: string
    amenities: AmenityDetails[]
}

export interface AccessToken {
    access: string
}

export interface ListingCategory {
    id: string 
    title: string 
}

export interface BookingDuration {
    minimum_duration: string 
    amount: string
    currency: string
    currency_id: string
}

export interface ListingImage1 {
    image1: string
}

export interface ListingImage2 {
    image2: string
}

export interface ListingImage3 {
    image3: string
}

export interface ListingImage4 {
    image4: string
}

// interface ListingImage {
//     image: string
// }
 

// interface ListingImages {
//     header: string 
//     others: string[]
// }

export interface OtherImages {
    image_1?: File 
    image_2?: File
    image_3?: File 
    image_4?: File

}

export interface Listing {
    
    title: string
    description: string
    capacity: number
    images:  PublicListingImages | null
    booking_duration: BookingDuration[]
    is_receive_mails: boolean,
    use_office_address: boolean,
    is_whiteboard: boolean,
    workspace: string, 
    category: string, 
    id?: string,
    is_publish?: boolean,
    date_created?: string,
    date_updated?: string,
    is_premium?: boolean
    
}

export interface QueryItem {
    name: string
    value: string
}

export interface AmenityDetails {
   id: string
   icon: string 
   title: string
   description: string 
   date_created: string 
   category: string
}

export interface PublicListingImages {
    header: string 
    others:  (ListingImage1 | ListingImage2 | ListingImage3 | ListingImage4)[]
}

export interface WorkspaceDetail {
    amenities: AmenityDetails[]
    city: string
    name: string
    country: string
    street: string
    partner_id: number
    partner_name: string
    header: string
    state: string
    available_hours: AvailableHours
    extra_days: ExtraDays
    email: string

 }

//  export interface PartnerDetail {
//      name: string 
//      email: string 
//      url?: string
//      header?: string
//  }

export interface PartnerDetail {
    name: string 
    email: string 
    url?: string
    header?: string
    id: string
    socials?: unknown
    user: number
}

interface RatingDetail {
    rating_value: number
    total_reviews: number
}

export interface PublicListing  {
    title: string
    description: string
    capacity: number
    booking_duration: BookingDuration[]
    is_receive_mails: boolean
    use_office_address: boolean
    is_whiteboard: boolean
    workspace: string
    category: string
    category_details: string
    id?: string
    workspace_details: WorkspaceDetail
    images: PublicListingImages
    is_hourly: false,
    is_daily: false,
    is_monthly: false,
    is_yearly: false,
    date_created: string
    date_updated: string
    avg_rating: number
    rating?: RatingDetail,
    is_publish: boolean,
    is_advert: boolean,
    is_premium?: boolean
    is_claimed?: boolean
}

export interface ListResponseData<T> {
    count: number 
    next?: string
    previous?: string 
    results: T[]
}

export interface Enquiry {

    first_name: string,
    last_name: string,
    listing: string,
    company_name: string,
    company_email: string,
    phone: string,
    list_category: string,
    total_people: string,
    start_date?: string,
    duration_type: string,
    duration: number,
    description: string
}

export interface EnquiredItem extends Enquiry {
    id: string
    date_created: string
    date_updated: string 
    listing: string 
    extra_details: ExtraDetails
    is_accept_term: boolean
     
}   

export interface Review {
    description: string 
    title: string 
    rating: number 
    listing: string
}

interface UserDetails {
    first_name: string
    image: string | null
    last_name: string
}

export interface UserReview extends Review {
    id: string
    user: number
    //user_image?: string 
    user_details: UserDetails
}

export interface ReviewQueryParams {
    listing?: string
}

export interface StateCount {
    state: string 
    count: number
} 

// export interface StateCount {
//     state: string 
//     count: number
// } 

export interface CityCount {
    
        id: number,
        city: string,
        count: number,
        image: string
    
}

interface WHAlertButton {
    label: string 
    action: ()=>void
}

export interface WHAlertProps {
    rightButton?: WHAlertButton
    leftButton?: WHAlertButton
    title: string 
    msg: string
    isOpen: boolean
    onClose: ()=>void
}

export interface SlideshowItem {
    id: string 
    image: string 
    caption: string 
    date_posted: string
  }

  interface SpotlightListing {
      id: string 
      header: string 
      title: string
  }

  interface SpotlightCategory {
    id: string 
   
    title: string
}
  export interface SpotlightItem {
      id: number 
      title: string 
      listing: SpotlightListing
      office_category: SpotlightCategory
  }

export interface WHAlertDialog extends WHAlertProps {
     

}

export interface DurationTime {

    start_date: string
    end_date: string
}

export interface BookedDates {
    hourly:  DurationTime[]
    daily: DurationTime[]
    monthly:  DurationTime[]
    yearly: DurationTime[]
}

export interface Currency {
    title: string 
    code: string 
    symbol: string
}

export interface CurrencyConverter {
    base: Currency
    target: Currency
    currencies: Currency[]
    rate: number
}

export interface ExchangeRate {
    from: string 
    to: string 
    rate: number
    amount: number
}

export interface WorkHopperCurrency {
    id: string 
    currency: string
}

export interface RequestsAnalytics {
    total_approved_booking: number
    total_booking: number
    total_paid_booking: number
    total_pending_booking: number
}

export interface WorkhopperSupportedLanguage {
    code: string
    name: string
    country: string
}

export interface CompanyInfo {
    id: number
    name: string
    url: string 
    image: string 
    date: string 
}

 

// export interface AmenityCategory {

// }



 


export type ResultSuccess<T> = {type: ResultType.Success, value: T}
export type ResultFailure =  {type: ResultType.Failure, error: Error}
export type Result<T> = ResultSuccess<T> | ResultFailure
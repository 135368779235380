import { VStack, Text, Button } from '@chakra-ui/react';
import  { useState } from 'react';
import { BreakPoint } from '../OfficeDetail/Models/Interfaces';
 
import styles from './WHHome.module.css'

import { useTranslation  } from "react-i18next";


interface WHWiwProps   {
    breakpoint: BreakPoint
}

const WHWiw = ({breakpoint}: WHWiwProps) => {

    const [readMore, setReadMore] = useState(false)
    const { t } = useTranslation()
 
    let introTitle = "h6"
    let introBody = "body2"

    if (breakpoint === BreakPoint.M 
        || breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL ) {
         introTitle = "intro-title"
        introBody = "intro-text"
       }

  return (
    <VStack  alignItems="flex-start">
        <VStack alignItems="flex-start">
        {/* <Text  className={styles.title}>What is WorkHopper</Text> */}
        <Text  className={styles.title}>{t("whatIsWorkHopper")}</Text>

            <Text className={styles.subtitle}>
            {t("whatIsWorkHopperBody")}
            {/* Work is hard. WorkHopper is now the easy marketplace for coworking centers, flexible workspaces and related services. We know that work is now something we take with us wherever we go. But finding a convenient workspace, with the reliable facilities, the right schedule and price can be tough, especially here in Africa. With WorkHopper, you take your gig with you, wherever you go! */}
            </Text>
        </VStack>
        {
            !readMore ? (
                <Button 
              onClick={(e)=>setReadMore(!readMore)}
              colorScheme={"primary"} 
              variant="link">{t("readMore")}</Button>
            ):(
                <VStack alignItems="flex-start">
                <Text  className={styles.title}>{t("whatYouCanDo")}</Text>

                <Text className={styles.subtitle}>
                    {t("whatYouCanDoBody")}
                {/* WorkHopper allows coworking centers to showcase their unique offices and services with ease. Welcome to WorkHopper. Finding a flexible workplace that works for you across Africa is now a breeze. Gig work is now truly possible and fun. You search, book, pay and start work your way.You feel less worried about deadlines and setbacks when you can easily take work along. WorkHopper is available via mobile app, website, desktop, IOS and Android. We believe that a one-stop shop for flexible workplaces and services should be easy and for both the gig-worker and the workspace provider. We are a continent-wide community of thriving flexible workers and flexible workspace providers. So, find your ideal space, list yours and be a part of an exciting new way to take your gig with you. */}
           </Text>
            </VStack>
            )
        }
      
      
  </VStack>
  )
};

export default WHWiw;

import { Grid, useMediaQuery } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import LayoutView from "../LayoutView/LayoutView";

interface WHLayoutGridProps {
    children: ReactNode
    height?: string 
}

const WHLayoutGrid = ({children, height}: WHLayoutGridProps) => {
        const [xsmall, small, medium, large] = useMediaQuery([
            "(max-width: 599px)",
            "(min-width: 600px) and (max-width: 904px)",
            "(min-width: 905px) and (max-width: 1439px)",
            "(min-width: 1440px)",
        ]);

        let cols = 4;
        let gutterWidth = 4;
        let gutterHeight = gutterWidth;
        if (small) {
            cols = 8;
            gutterWidth = 6;
            gutterHeight = gutterWidth;
        } else if (medium || large) {
            cols = 12;
            gutterWidth = 8;
            gutterHeight = gutterWidth;
        }

         return (
            
               <LayoutView>
                <Grid
                    autoRows="16px"
                    minH={height ?? "100vh"}
                    //templateRows="auto"
                    //templateColumns="repeat(5, 1fr)"
                    templateColumns={`repeat(${cols}, 1fr)`}
                    columnGap={gutterWidth}
                    gap={gutterHeight}
                    w="full"
                >
                    {children}
                </Grid>
             </LayoutView>
        );
};

export default WHLayoutGrid;

import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { WorkhopperSupportedLanguage } from "../../Models/Interfaces"
import cookies from "js-cookie"
import i18next from "i18next"

interface LanguagesObj {
    selected: string, 
    lngs: WorkhopperSupportedLanguage[]
}

export const defaultLanguage = {
    selected: "en",
    lngs: [{
        code: "en",
        country: "gb",
        name: "english"
      }, 
      {
        code: "fr",
        country: "fr",
        name: "french"
      }
       
    ] 
}
  
export const LanguageObjContext  = React.createContext<LanguagesObj>(defaultLanguage)
export const SetLanguageObjContext = React.createContext<Dispatch<SetStateAction<LanguagesObj>>>(()=>{})

interface LanguageProviderProps {
    children: ReactNode
}

const LanguageProvider = ({children}: LanguageProviderProps) => {
    const currentLngCode = cookies.get("i18next") || defaultLanguage.selected

      const [languageObj, setLanguageObj] = useState<LanguagesObj>({...defaultLanguage, selected: currentLngCode})

     useEffect(() => {

         i18next.changeLanguage(languageObj.selected)
          
         return () => {
             
         }

     }, [languageObj.selected])

   

     return (
        <LanguageObjContext.Provider value={languageObj}>
                   <SetLanguageObjContext.Provider value={setLanguageObj}>
                     {children}
                   </SetLanguageObjContext.Provider>
                </LanguageObjContext.Provider>
    )
}

export default LanguageProvider
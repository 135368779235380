import {
  Box,
  Divider,
  useDisclosure,
  VStack,
  Link,
  Text,
  HStack,
  Spacer,
  Button,
  Circle,
  Flex,
  ButtonGroup,
  useMediaQuery,
  GridItem,
  Center,
  Spinner,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { FormikProps, useFormikContext } from "formik";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MdArrowForward, MdCheck, MdChevronRight } from "react-icons/md";
import {
  Route,
  Switch,
  NavLink as ReactLink,
  useHistory,
  Redirect,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import DashboardRoute from "../../../../DashboardRoute";
import { RegistrationResponse, ResultType } from "../../../../Models/Interfaces";
import routes from "../../../../routes";
import { SetErrorContext } from "../../../AppProvider/AppProvider";
import OfflineView from "../../../SupportingViews/OfflineView";
import WHLayoutGrid from "../../../SupportingViews/WHLayoutGrid/WHLayoutGrid";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import {
  dataManager,
  URLDir,
} from "../../WHApp/AddASpace/Models/ListSpaceDataManager";
import { BreakPoint } from "../../WHApp/OfficeDetail/Models/Interfaces";
import AboutSpace from "../AboutSpace/AboutSpace";
import AddOffice from "../AddOffice/AddOffice";
import AmenitiesFormView from "../AmenitiesFormView/AmenitiesFormView";
import ContactDetails from "../ContactDetails/ContactDetails";
import HopFormView from "../HopFormView/HopFormView";
import HoursOperation from "../HopFormView/HopFormView";
import LocationFormView from "../LocationFormView/LocationFormView";
import {
  AdminStateActions,
  AmenitiesForm,
  ContactForm,
  DashboardSectionName,
  DescForm,
  DesksForm,
  HopForm,
  LocationForm,
  SizeForm,
  SpaceForm,
} from "../Models/Interfaces";
import { dashboardManager } from "../Models/WHDashboardDataManager";
import OfficesView from "../OfficesView/OfficesView";
import SizeFormView from "../SizeFormView/SizeFormView";
import WHDashboardDrawer from "../WHDashboardDrawer/WHDashboardDrawer";
import WHDashboardNavbar from "../WHDashboardNavbar/WHDashboardNavbar";
import { ListingsStateContext, SetWorkspaceContext, WorkspaceContext } from "../WHManageWorkspaces/ManageWorkspacesProvider";
import Workspaces from "../Workspaces/Workspaces";
import MasterNavlistMobile from "./MasterNavlistMobile";
import OfficesViewProvider from "./OfficesViewProvider";
import WHDesktopNavlist from "./WHDesktopNavlist";
import WHDesktopRailSidebar from "./WHDesktopRailSidebar";

import styles from "./WHWorkspaceDetail.module.css";

interface WHWorkspaceDetailProps {
  partner: RegistrationResponse;
}



const WHWorkspaceDetail = ({ partner }: WHWorkspaceDetailProps) => {
  //const { isOpen, onOpen, onClose } = useDisclosure()
  let spaceFormRef = useRef<FormikProps<DescForm>>(null);
  let pmFormRef = useRef<FormikProps<SpaceForm>>(null);
  let contactFormRef = useRef<FormikProps<ContactForm>>(null);
  let amenitiesFormRef = useRef<FormikProps<AmenitiesForm>>(null);
  let locationFormRef = useRef<FormikProps<LocationForm>>(null);
  let hopFormRef = useRef<FormikProps<HopForm>>(null);
  let sizeFormRef = useRef<FormikProps<SizeForm>>(null);

  const [isLoading, setIsLoading] = useState(false)
  const setWorkspace = useContext(SetWorkspaceContext)
  const workspace = useContext(WorkspaceContext)
  const {dispatch} = useContext(ListingsStateContext)
  const toast = useToast()
  const setErrorContext = useContext(SetErrorContext)
  const {state} = useContext(ListingsStateContext)


  let detailRef = useRef<HTMLDivElement>(null);

  const [xsmall, small, small2, medium, large] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  const [isMedium] = useMediaQuery(["(max-width: 786px)"])



  const breakpoint = useBreakpointValue({base: BreakPoint.XS, sm: BreakPoint.S, 
    md: BreakPoint.M, lg: BreakPoint.L, xl: BreakPoint.XL})

  const [detailPaddingTop, setDetailPaddingTop] = useState(4);

  const { path, url } = useRouteMatch();
  const params = useParams<{bizId?: string, wkspcId?: string}>()
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {

    const loadWorkspace = async ()=> {
      
      let id = params.wkspcId
      //debugger
      if (id && partner.access_token) {
         setIsLoading(true) 
         let result = await dashboardManager.getWorkspaceWithId(id, partner.access_token)
         //debugger
        let listingsResult = await dashboardManager.getListingsWithId(id, partner.access_token)
         setIsLoading(false)
      
        if (result.type === ResultType.Success && 
             listingsResult.type === ResultType.Success) {
              setWorkspace(result.value)
            if (result.value.id) {
              dispatch({type: AdminStateActions.CHANGEWORKSPACE, payload: {id: result.value.id, 
                        listings: (listingsResult.value as any).results,
                        data: listingsResult.value
                      }
                      })
            }
            setError(null)
        } else if (result.type === ResultType.Failure) {

         // setError(new Error("Couldn't load Workspace Details"))
            setError(result.error)
            setWorkspace(null)
        } else if (listingsResult.type === ResultType.Failure) {
          setError(listingsResult.error)
          setWorkspace(null)

        }
       
        //return
      }
    }
     
     if (params.wkspcId !== "new") {
      loadWorkspace()
     }
     // dashboardManager.getWorkspaceWithId(work)
     
  }, [])

   

  

   

  const handleAfterSubmit = () => {};

  // const disableSetupBtn()

  useEffect(() => {
    let height = detailRef.current?.clientHeight;
     if (height) {
      setDetailPaddingTop(height ?? 16);
    }

    return () => {
      //cleanup
    };
  }, []);

  

  const onPublish = async ()=>{
      if (workspace?.id && partner.access_token) {

        if (!dashboardManager.isDetailSectionComplete(workspace) || state.listings.length == 0) {
            setErrorContext({title: "Publish Error", msg: "Complete all sections to publish a workspace"})
            return
          }
        
        let result = await dashboardManager.togglePublishedMode(workspace.id, !workspace.is_publish, partner.access_token)
        if (result.type === ResultType.Success) {
          setWorkspace({...workspace, is_publish: result.value.is_publish})
          toast({
            duration: 3500,
            isClosable: true,
            status: "success",
            position: "bottom-left",
          //  position: toastPos as ToastPosition,
            description: `${result.value.title} is ${result.value.is_publish ? "now" : "not"} available on WorkHopper.com`
            
          })
        } else {
          setErrorContext({title: "Publish Error", msg: result.error.message})
        }
        console.dir(result)
      }
  }
   
   
  let history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentSectionId, setCurrentSectionId] = useState(
    DashboardSectionName.Desc
  );

  const updateFormStatus = (value: boolean) => {
    setIsSubmitting(value);
  };

  const masterDetail = () => {
    return (
      <Switch>
        <Route
          exact
          path={`${path}/navlist`}
          component={() => <MasterNavlistMobile />}
        />
        <Route
          exact
          path={`${path}/contact+details`}
          render={() => {
            

            return (
              <ContactDetails
                formRef={contactFormRef}
                // onLoad={()=>{setCurrentSectionId(OnBoardSectionId.Intro)}}
                actionAfterSubmit={() => {
                  history.push(`${url}/${DashboardSectionName.Amenities}`);
                }}
                manager={dashboardManager}
                updateFormSubmissionStatus={updateFormStatus}
              />
            );
          }}
        />
        <Route
          exact
          path={`${path}/amenities`}
          render={() => (
            <AmenitiesFormView
              formRef={amenitiesFormRef}
              // onLoad={()=>{setCurrentSectionId(OnBoardSectionId.Intro)}}
              actionAfterSubmit={() => {
                history.push(`${url}/${DashboardSectionName.Location}`);
              }}
              manager={dashboardManager}
              updateFormSubmissionStatus={updateFormStatus}
            />
          )}
        />
        <Route
          exact
          path={`${path}/${DashboardSectionName.HOP}`}
          render={() => (
            <HopFormView
              formRef={hopFormRef}
              // onLoad={()=>{setCurrentSectionId(OnBoardSectionId.Intro)}}
              actionAfterSubmit={() => {
                history.push(`${url}/${DashboardSectionName.Offices}`);
               // history.push(`${url}/${DashboardSectionName.Size}`);
              }}
              manager={dashboardManager}
              updateFormSubmissionStatus={updateFormStatus}
            />
          )}
        />
        <Route
          exact
          path={`${path}/location`}
          render={() => (
            <LocationFormView
              formRef={locationFormRef}
              actionAfterSubmit={() => {
                history.push(`${url}/${DashboardSectionName.HOP}`);
              }}
              updateFormSubmissionStatus={() => {}}
              manager={dashboardManager}
            />
          )}
        />
        <Route
          exact
          //path={`${routes.dashboard.path}/manage+offices`}
          path={`${path}/manage+offices`}
          render={() => <OfficesView manager={dashboardManager} />}
        />
        
        <Route
          exact
          path={`${path}/description`}
          render={() => (
            <AboutSpace
              formRef={spaceFormRef}
              // onLoad={()=>{setCurrentSectionId(OnBoardSectionId.Intro)}}
              actionAfterSubmit={() => {
                if (dashboardManager.workspaceId !== "new") {
                 
                  history.push(`/dashboard/${params.bizId!}/manage+workspaces/${dashboardManager.workspaceId}/detail/${DashboardSectionName.ContactDetails}`)

                } else {
                    history.push(`${url}/${DashboardSectionName.ContactDetails}`);

                  // /dashboard/96/manage+workspaces/new/detail
                  // let r = url.toString().replace("new", dashboardManager.workspaceId)
                  // console.log(r)
                }
              }}
              manager={dashboardManager}
              updateFormSubmissionStatus={updateFormStatus}
            />
          )}
        />
        <Route path="*">
          <Redirect
            to={dashboardManager.sectionLink(
              DashboardSectionName.Desc,
              partner.id
            )}
          />
        </Route>
      </Switch>
    );
  };

  const desktopDashboard = () => {
    return (
      <Box  >
        
        <HStack
          position="relative"
          //pt={{ base: "48px", md: "56px", lg: "64px" }}
          display="block"
         
          minH="100vh"
          bg="#eee"
           pl={{md: "25%", lg: "25%" }}
         
          spacing="0px"
        >
          {/* <WHDesktopNavlist url={url} /> */}
          <WHDesktopRailSidebar   url={url} />
          <VStack     
           spacing="0px" bg="white" 
              width={isMedium ? "calc(100% - 72px)" : "full"}
              ml={isMedium ? "auto !important": "initial"}
              minH="inherit" 
               pb={12}
              >
              <Flex
           zIndex="50"
          // display={isMedium ? "none": "initial"}
           display="none"
          //position="fixed"
          className="navbar-lg"
          bg="#f7f7f7"
          w="full"
          pr={20}
          pl={20}
          //justifyContent="space-between"
          alignItems="center"
          h={{ base: "48px", md: "56px", lg: "64px" }}
          color="black"
        >
          <Text mr="auto">{workspace?.title ?? "Untitled"}</Text>
          <ButtonGroup>
            
            <Button
              w={"full"}
              disabled = {!(dashboardManager.isDetailSectionComplete(workspace) && state.listings.length > 0)}
              onClick={onPublish}
              className="button"
              colorScheme="green"
              variant="solid"
            >
              {!workspace?.is_publish ? "Publish" : "Unpublish" }
            </Button>
          </ButtonGroup>
        </Flex>
            {masterDetail()}
          </VStack>
        </HStack>
      </Box>
    );
  };

  const mobileDashboard = () => {
    return <Box paddingY={4}>{masterDetail()}</Box>;
  };

 // debugger
  //  const dashboard = xsmall || small  ? mobileDashboard() : desktopDashboard();
  const dashboard = desktopDashboard();


  return (
    <>
    {
      isLoading ? (
        <VStack w="100vw" justifyContent="center" alignItems="center" h="100vh">
          <WHSpinner pt="64px" />
         
         </VStack>
      ): error ? (
        <OfflineView 
        msg={error.message} pt={"64px"} />
      ):   params.wkspcId === "new" || workspace && workspace.id
      ? (
        dashboard
      ): null
    }
    </>
  )
  
  
 };

export default WHWorkspaceDetail;

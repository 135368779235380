import { Box, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";
import { useTranslation  } from "react-i18next";

const PolicyView = () => {
  const {t} = useTranslation()

  return (
    <>
      {/* <div>
        <div style="width: 100%; text-align: center;">
          <img src="../../../Assets/logo_dark.png" alt="workhopper-logo" />
          <div>Registration Confirmed</div>
        </div>
       <p><strong>Dear ( user)</strong>&nbsp;</p>
       <p>Thank you for signing up with WorkHopper (Name of user)</p>
       <p>We built WorkHopper to enable you to find coworking centres and flexible workspaces in your chosen locations. We understand that finding somewhere convenient to take your work to especially in Africa can be difficult, let us help you.&nbsp;</p>
      </div> */}
      <VStack alignItems={"flex-start"} w="full" h="inherit">
        <p>
          <strong>Introduction:</strong>
        </p>
        <p>
        {t("policyintro1")}
          {/* WorkHopper is committed to protecting the privacy of confidential and
          &ldquo;Personal Information&rdquo; (information that directly or
          indirectly identifies individuals who may be clients, staff, agents,
          spaces or others inside or outside the WorkHopper ). */}
        </p>
        <p>
        {t("policyintro2")}
          {/* This Policy sets out WorkHopper &rsquo;s commitment to ensuring that
          any personal data, including special category personal data as defined
          herein in this Policy, which WorkHopper processes, is carried out in
          compliance with laws which regulate Data Privacy Protection in the
          Federal Republic of Nigeria as well as all other applicable
          international Data Protection Laws and Policies. WorkHopper is
          committed to ensuring that its processing of personal data is carried
          out in accordance with these Laws. */}
        </p>
        <p>
          <strong>
          {t("policypurpose")}:
            {/* Purpose of this Policy: */}
            </strong>
        </p>
        <p>
        {t("policypurpose1")}
          {/* This Privacy Policy (the &ldquo;Policy&rdquo;) is based on the privacy
          and data protection principles applicable in Nigeria where our website
          is operated. It is applied in view of our overarching desire to comply
          with the provisions of the law, to preserve customers and/or users
          confidentiality and to represent our users as effectively as possible
          within the bounds of the law. */}
        </p>
        <p>
        {t("policypurpose2")}
          {/* This Policy applies to all personal data processed by WorkHopper and
          is part of WorkHopper &rsquo;s approach to compliance with Data
          Protection and Privacy Laws and principles. All Workhopers staff are
          expected to comply with this policy and failure to comply may lead to
          disciplinary action for misconduct, including dismissal. */}
        </p>
        <p>
          <strong>
          {t("policyinfo")}
            {/* What information we collect from you */}
            :</strong>
        </p>
        <p>
        {t("policyinfo1")}
          {/* WorkHopper receive and saves the information provided on the website
          or through other means you enter on our website or give us in any
          other way. WorkHopper is the controller of personal data of users. */}
        </p>
        <p>
          <strong>
          {t("dataprotection")}
            {/* Our Data&nbsp;protection principles: */}
            </strong>
        </p>
        <p>
        {t("dataprotection1")}
          {/* WorkHopper complies with the data protection principles set out below.
          When processing personal data, it ensures that: */}
        </p>
        <ol>
          <li>
          {t("datalist")}
            {/* it is processed lawfully, fairly and in a transparent manner. */}
            </li>
          <li>
          {t("datalist1")}
            {/* it is collected for specified, explicit and legitimate purposes. */}
          </li>
          <li>
          {t("datalist2")}
            {/* it is at all times adequate, relevant and limited to what is
            necessary in relation to the purposes for which they are processed. */}
          </li>
          <li>
          {t("datalist3")}
            {/* it is all times accurate and, where necessary, kept up to date and
            that reasonable steps are taken to ensure that personal data that
            are inaccurate, having regard to the purposes for which they are
            processed, are erased or rectified without delay. */}
          </li>
          <li>
          {t("datalist4")}
            {/* it is kept in a form which permits identification of data subjects
            for no longer than is necessary for the purposes for which the
            personal data are processed. */}
          </li>
          <li>
          {t("datalist5")}
            {/* it is processed in a manner that ensures appropriate security of the
            personal data, including protection against unauthorized or unlawful
            processing and against accidental loss, destruction or damage, using
            appropriate technical or organizational measures. */}
          </li>
        </ol>
        <p>
          <strong>
          {t("datacategory")}
            {/* Category of processed personal data: */}
            </strong>
        </p>
        <ul>
          <li>
          {t("datacategory1")}
            {/* Name, e-mail, phone number, place of residence */}
            </li>
          <li>
          {t("datacategory2")}
            {/* Geolocation of workspaces and users */}
            </li>
          <li>
          {t("datacategory3")}
            {/* Information about workspaces */}
            </li>
          <li>
          {t("datacategory4")}
            {/* Workspaces&rsquo; efficiency and ratings */}
            </li>
          <li>
          {t("datacategory5")}
            {/* Data about criminal convictions and offences */}
            </li>
        </ul>
        <p>
        {t("datacategory6")}
          {/* We try to provide you with the right information to improve your
          experience on WorkHopper , therefore we may also request information
          about your work preferences, movement, and your locality. If you
          provide such information it may enable you to access coworking space
          reviews, view feedbacks from other groups on WorkHopper , send
          WorkHopper content to friends, access members-only newsletters and
          workspace content, participate during surveys and promotions. In
          addition, some information is also required to initiate other
          transactions on our site.&nbsp; */}
        </p>
        <p>
          <strong>
          {t("datainfo")}
            {/* Information About Others: */}
            </strong>
        </p>
        <p>
        {t("datainfo1")}
          {/* &nbsp;You will have the opportunity to provide contact and
          coworking-related information for other people through our Website. If
          you make a reservation for someone else through WorkHopper , you will
          need to submit that individual&rsquo;s personal information as any
          attempt to view the person&rsquo;s information will only be available
          through your profile. */}
        </p>
        <p>
        {t("datainfo2")}
          {/* We can also acquire either your personal and business information
          about you from affiliated entities, partners, and other independent
          third-party sources to improve information about you or your service
          experience with us. This means that when you visit WorkHopper through
          a partner or affiliated application and you have registered with that
          partner, then information about you that you have provided to that
          partner may be shared with us, such as contact information and
          demographic information. WorkHopper will continue to work with our
          current partners and potential future partners to improve and
          personalize your use of our Website in accordance with the practices
          outlined in this section and Policy. Every user on WorkHopper will
          receive personalized services whenever they visit the Website, even
          for first-time user of WorkHopper Website. */}
        </p>
        <p>
        {t("dataauto")}
          {/* Automatic Information */}
          </p>
        <p>
        {t("dataauto1")}
          {/* WorkHopper will collect some information from your computer or Device
          when you visit. Such information may be your IP address, Web browser
          software, and referring website. We also may collect information about
          your online activity, such as content viewed, pages visited, and
          searches and/or reservations facilitated or made. The aim is generally
          to allow us know how to collect data and information that can enable
          us to improve your interests and preferences on WorkHopper . */}
        </p>
        <p>
          <strong>
          {t("dataprocess")}
            {/* Process/procedures/guidance: */}
            </strong>
        </p>
        <p>
        {t("dataprocess1")}
          {/* Note that WorkHopper will: */}
          </p>
        <ol>
          <li>
          {t("dataprocess2")}
            {/* ensure that all processing complies with the law; */}
            </li>
          <li>
          {t("dataprocess3")}
            {/* not do anything with your data that you would not expect given the
            content of this Policy and the fair processing or privacy notice; */}
          </li>
          <li>
          {t("dataprocess4")}
            {/* only collect and process the personal data that it needs for
            purposes it has identified in advance; */}
          </li>
          <li>
          {t("dataprocess5")}
            {/* ensure that, as far as possible, the personal data it holds is
            accurate, or a system is in place for ensuring that it is kept up to
            date as far as possible; */}
          </li>
          <li>
          {t("dataprocess6")}
            {/* ensure that appropriate security measures are in place to ensure
            that personal data can only be accessed by those who need to access
            it and that it is held and transferred securely. */}
          </li>
        </ol>
        <p>
        {t("dataprocess7")}
          {/* WorkHopper will ensure that all staff who handle personal data on its
          behalf are aware of their responsibilities under this Policy and other
          relevant data protection and information security policies and that
          they are adequately trained and supervised. */}
        </p>
        <p>
        {t("dataprocess8")}
          {/* Breaching this policy may result in disciplinary action for
          misconduct, including dismissal. Obtaining (including accessing) or
          disclosing personal data in breach of WorkHopper data protection
          policies may also be a criminal offence. */}
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          <strong>
          {t("datapayment")}
            {/* Payment Information: */}
            </strong>
        </p>
        <p>
        {t("datapayment1")}
          {/* WorkHopper is obligated to accept, collect, and store payment
          information including your credit card number, cardholder name,
          expiration date, authentication code, and billing address. This
          information will be conveyed to the provider of the workspace
          reservation to complete the transaction. You hereby agree to review
          and be bound by the provider&rsquo;s policies and procedures before
          making a reservation through WorkHopper . */}
        </p>
        <p>
        {t("datapayment2")}
          {/* Location Information and Other Information From Devices */}
          </p>
        <p>
        {t("datapayment3")}
          {/* You are hereby notified that we will collect and use information
          anytime you use our Application on a Device, we will collect and use
          information about you in generally similar ways and for similar
          purposes as when you use the WorkHopper website. In addition, we may
          collect information about your location if you have instructed your
          Device to send such information to the Application via the privacy
          settings on that Device, or if you have uploaded photos tagged with
          location information. We may use the location information collected
          from your Device or photos to enhance your use of the Application by
          providing you with relevant content and contextual advertising. This
          means that you will be able to find other related services around your
          workspace that can influence your experience with a workspace. */}
        </p>
        <p>
          <strong>
          {t("datacookies")}
            {/* Cookies and Other Web Technologies: */}
            </strong>
        </p>
        <p>
        {t("datacookies1")}
          {/* Cookies are files created by websites you visit for the purpose of
          improving your experience by saving your browsing information.
          WorkHopper use cookies to identify you and your preferences while
          browsing WorkHopper or its affiliate&rsquo;s websites. */}
        </p>
        <p>
        {t("datacookies2")}
          {/* WorkHopper will use this information to remember your registration
          information, so you do not need to log into our site each time you
          visit. Your User IDs, passwords, and any other account-related data
          contained in the cookies are encrypted for security purposes to help
          us customize the content, experience, and advertisements provided to
          you on our websites and Apps and on other websites across the
          Internet. For example, when you access a web page, a cookie is
          automatically set by us, our service providers, or our partners to
          recognize your browser as you navigate on the Internet and to present
          you with information and advertising based on your apparent interests.
          See &ldquo;Display Of Advertising For Your Preferences&rdquo;, for
          more information about our online advertising practices and your
          choices.&nbsp; */}
        </p>
        <p>
        {t("datacookies3")}
          {/* To help measure and research the effectiveness of features and
          offerings, advertisement and email communications (by determining
          which emails you open and act upon). The Help portion of the toolbar
          on most browsers should tell you how to prevent your browser from
          accepting new cookies, how to have the browser notify you when you
          receive a new cookie, or how to disable most types of cookies. Please
          note that if you refuse to accept cookies, you may not be able to
          access many of the coworking tools offered on our sites. Our sites
          also may use Web beacons (also known as clear gifs, pixel tags or Web
          bugs), which are tiny graphics with a unique identifier, similar in
          function to cookies, that are placed in the code of a Web page. We use
          Web beacons to monitor the traffic patterns of users from one page
          within our sites to another, to deliver or communicate with cookies,
          to understand whether you have come to our site from an online
          advertisement displayed on a third-party website, and to improve site
          performance. We also may allow our service providers to use Web
          beacons to help us understand which emails have been opened by
          recipients and to track the visitor traffic and actions on our site.
          This helps us measure the effectiveness of our content and other
          offerings. */}
        </p>
        <p>
          <strong>
          {t("policyadvert")}
            {/* Display Of Advertising for Your Preferences: */}
            </strong>
        </p>
        <p>
        {t("policyadvert1")}
          {/* Data collected to serve you with relevant advertising WorkHopper and
          its affiliated websites are committed to providing you with relevant
          content and information across our sites. To do this, we may collect
          information about your coworking-related searches and other site
          activities, and use this information to serve you with ads on our
          sites or elsewhere online that match your apparent interests. So, for
          example, if you search for a coworking space in Bangkok on WorkHopper
          , you may see an ad for a Bangkok coworking space on WorkHopper or on
          another website you visit. Be assured that we do not share your
          personal information (such as email addresses) with unaffiliated third
          parties so they can serve you with advertisements. */}
        </p>
        <p>
          <strong>
          {t("policyadvert2")}
            {/* Data Collected By Business Partners and Ad Networks To Serve You
            With Relevant Advertising: */}
          </strong>
        </p>
        <p>
        {t("policyadvert3")}
          {/* Many of the advertisements you see on the WorkHopper family of sites
          are served by WorkHopper or its service providers. But we also allow
          third parties to collect information about your online activities
          through cookies and other technologies. These third parties
          include&nbsp; */}
        </p>
        <ol>
          <li>
          {t("policyadvert4")}
            {/* business partners, who collect information when you view or interact
            with one of their advertisements on our sites, and&nbsp; */}
          </li>
          <li>
          {t("policyadvert5")}
            {/* advertising networks, who collect information about your interests
            when you view or interact with one of the advertisements they place
            on many different websites on the Internet.&nbsp; */}
          </li>
        </ol>
        <p>
        {t("policyadvert6")}
          {/* Note that the information gathered by these third parties is used to
          make predictions about your characteristics, interests or preferences
          and to display advertisements on our sites and across the Internet
          tailored to your apparent interests. Please note also that we do not
          have access to or control over cookies or other technologies these
          third parties may use to collect information about your interests, and
          the information practices of these third parties are not covered by
          this Privacy Policy. Some of these companies are members of the
          Network Advertising Initiative, which offers a single location to opt
          out of ad targeting from member companies. To learn more, please click
          here.&nbsp; */}
        </p>
        <p>
        {t("policyadvert7")}
          {/* Response to Do Not Track, signals Your web browser to transmit a
          &ldquo;Do Not Track&rdquo; (&ldquo;DNT&rdquo;) signal to WorkHopper
          and other websites. Because of differences in web browsers&rsquo;
          default settings and the manner in which browsers incorporate DNT
          signals, it is sometimes unclear to site operators whether site
          visitors have selected the DNT setting themselves, or whether they are
          aware that they have a choice in determining the setting. WorkHopper
          does not currently respond to DNT signals. Once the Internet industry
          has settled on standards related to this issue and WorkHopper
          recognizes such standards, we may reevaluate this approach. */}
        </p>
        <p>
          <strong>
          {t("policyuse")}
            {/* How We Use Your Information: */}
            </strong>
        </p>
        <p>
        {t("policyuse1")}
          {/* WorkHopper uses the information we collect about you for the following
          general purposes: for registration and to manage your account,
          including to allow your access to and use of our site; to communicate
          with you in general, including to provide information about WorkHopper
          and invitations from the WorkHopper community; to enable us to publish
          your reviews, forum posts, and other content to the WorkHopper
          community; to facilitate your booking of coworking space reservations;
          to respond to your questions and comments; to measure interest in and
          improve our products, services, and Website; to notify you about
          special offers and products or services available from us or our
          partners that may be of interest to you; to otherwise customize your
          experience with WorkHopper , including customizing the ads shown to
          you; to solicit information from you, including through surveys; to
          resolve disputes or troubleshoot problems; to prevent potentially
          prohibited or illegal activities; to enforce our Terms of Use; and as
          otherwise may be described to you at the point of collection. */}
        </p>
        <p>
        {t("policyuse2")}
          {/* &nbsp;If you have signed up as the owner or manager of a coworking
          space, or listed your coworking space in our Coworking Spaces section,
          we will send you email messages at the email address you provide to
          us. Please review "Your choices with respect to the collection and use
          of your information" below.&nbsp; */}
        </p>
        <p>
          <strong>
          {t("policyshared")}
            {/* How your information is shared: */}
            </strong>
        </p>
        <p>
        {t("policyshared1")}
          {/* WorkHopper may share your information with the following entities:
          Suppliers, including but not limited to coworking space managers or
          owners who fulfill your coworking related reservations; throughout
          WorkHopper and its affiliated sites; all products and services
          provided by third-party suppliers are described as such. */}
        </p>
        <p>
        {t("policyshared2")}
          {/* Information that we share with suppliers to facilitate reservations
          will be treated according to applicable suppliers&rsquo; privacy
          policies, except for payment information. We share payment information
          only when it is necessary for reservations facilitated through or made
          on WorkHopper , and we restrict suppliers&rsquo; use of payment
          information for these purposes. We encourage you to review the privacy
          policies, terms of use, and other policies of any coworking space
          supplier whose products and services you may purchase through
          WorkHopper .&nbsp; */}
        </p>
        <p>
        {t("policyshared3")}
          {/* Please note that these suppliers also may contact you as necessary to
          obtain additional information about you or your coworking plans to
          facilitate your coworking space reservations, or for other purposes
          consistent with the practices described in their privacy policies and
          terms of use. */}
        </p>
        <p>
          <strong>
          {t("policythirdparty")}
            {/* Third-Party Vendors: */}
            </strong>
        </p>
        <p>
        {t("policythirdparty1")}
          {/* We may also authorize third-party vendors who provide services or
          functions on our behalf, including business analytics, payment
          processing, customer service, marketing, public relations,
          distribution of surveys or sweepstakes programs, and fraud prevention
          to collect information on our behalf, including, for example, as
          necessary to operate features of our Website or to facilitate the
          delivery of online advertising tailored to your interests. Third-party
          vendors have access to and may collect information only as needed to
          perform their functions and are not permitted to share or use the
          information for any other purpose. Business partners with whom we may
          offer products or services in conjunction. You can tell when a third
          party is involved in a product or service you have requested because
          their name will appear either with ours or separately. If you choose
          to access these optional services, we may share information about you,
          including your personal information, with those partners. Please note
          that we do not control the privacy practices of these third-party
          business partners. */}
        </p>
        <p>
          <strong>
          {t("policyrefering")}
            {/* Referring Websites: */}
            </strong>
        </p>
        <p>
        {t("policyrefering1")}
          {/* If you were referred to WorkHopper from another website, we may share
          your registration information, such as your name, email address,
          mailing address, telephone number and coworking preferences about you
          with that referring website. We have not placed limitations on the
          referring websites&rsquo; use of personal information and we encourage
          you to review the privacy policies of any website that referred you to
          WorkHopper . */}
        </p>
        <p>
          <strong>
          {t("policysocial")}
            {/* Social Media Services: */}
            </strong>
        </p>
        <p>
        {t("policysocial1")}
          {/* You can choose to access certain third party social media websites and
          services through our site (such as Facebook and the likes). When you
          do so, you are sharing information with those sites, and the
          information you share will be governed by their privacy policies. You
          may also be able to modify your privacy settings with these third
          party social media websites.&nbsp; */}
        </p>
        <p>
          <strong>
          {t("policycompanies")}
            {/* Companies within our corporate family:&nbsp; */}
            </strong>
        </p>
        <p>
        {t("policycompanies1")}
          {/* We may share your personal information within the WorkHopper Group, as
          well as with our parent companies and domestic and international
          corporate affiliate companies and websites. This sharing enables us to
          provide you with information about products and services, both
          coworking-related and others which might interest you. To the extent
          that our parent company and corporate affiliates have access to your
          information, they will follow practices that are at least as
          restrictive as the practices described in this Privacy Policy. They
          also will comply with applicable laws governing the transmission of
          promotional communications and, at a minimum, give you an opportunity
          in any commercial email they send to choose not to receive such email
          messages in the future. We also may share your information if we
          believe, in our sole discretion, that such disclosure is necessary to
          comply with legitimate and enforceable subpoenas, court orders, or
          other legal process; to establish or exercise our legal rights; to
          defend against legal claims; or as otherwise required by law. In such
          cases we reserve the right to raise or waive any legal objection or
          right available to us; to investigate, prevent, or take action
          regarding illegal or suspected illegal activities; to protect and
          defend the rights, property, or safety of WorkHopper , our customers,
          or others, and in connection with our Terms of Use and other
          agreements; In connection with a corporate transaction, such as a
          divestiture, merger, consolidation, or asset sale, or in the unlikely
          event of bankruptcy. */}
        </p>
        <p>
        {t("policycompanies2")}
          {/* If you choose to create a profile on WorkHopper , certain information
          in your profile will be publicly viewable and identifiable via your
          screen name. You will have the ability to view your profile as members
          of the public will see it. Additionally, if you choose to participate
          in our member-to-member communications programs, other WorkHopper
          members may contact you by using WorkHopper as an intermediary;
          however, WorkHopper will not share your email address with any other
          members nor display it in any public manner. All WorkHopper members
          have the option to turn off the member-to-member communication
          features at any time. Other than as set out above, you will be
          notified when personal information about you will be shared with third
          parties, and you will have an opportunity to choose not to have us
          share such information. We also may share aggregate or anonymous
          information with third parties, including advertisers and investors.
          For example, we may tell our advertisers the number of visitors our
          Website receives or the most popular coworking space destinations.
          This information does not contain any personal information and is used
          to develop content and services we hope you will find of interest. */}
        </p>
        <p>
          <strong>
          {t("policyaccess")}
            {/* How You Can Access Your Information: */}
            </strong>
        </p>
        <p>
        {t("policyaccess1")}
          {/* We take reasonable steps to ensure that your information is relevant
          to its intended use, accurate, and complete. You can access and update
          your contact information by visiting the Member Profile page on the
          WorkHopper Website. You can close your WorkHopper account by
          contacting us by email. Please note that after you close an account,
          you will not be able to sign in or access any of your personal
          information. However, you can open a new account at any time. Please
          also note that we may retain certain information associated with your
          account in our archives, including for analytical purposes as well as
          for recordkeeping integrity. */}
        </p>
        <p>
          <strong>
          {t("policyrespect")}
            {/* Your Choices With Respect To The Collection And Use Of Your
            Information: */}
          </strong>
        </p>
        <p>
        {t("policyrespect1")}
          {/* As discussed above, you can choose not to provide us with any
          information, although it may be needed to take advantage of certain
          features offered on WorkHopper . You also can add or update
          information and close your account as described above. If you decide
          to close your WorkHopper account, we will deactivate your account and
          remove your profile information from active view. Please note,
          however, that we may retain in our files some information associated
          with your account and past transactions for purposes including
          backups, fraud prevention, dispute resolution, investigations, and
          compliance with legal requirements.&nbsp; */}
        </p>
        <p>
        {t("policyrespect2")}
          {/* As a registered WorkHopper member, you can modify your email
          subscription choices at any time on the Member Profile page. Whether
          registered as a WorkHopper member or not, you also will be given the
          opportunity to unsubscribe from promotional messages in any such email
          we send. Please note that we reserve the right to send you other
          communications, including service announcements, administrative
          messages, and surveys relating either to your WorkHopper account,
          without offering you the opportunity to opt out of receiving
          them.&nbsp; */}
        </p>
        <p>
          <strong>
          {t("policyemail")}
            {/* Email Communications: */}
            </strong>
        </p>
        <p>
        {t("policyemail1")}
          {/* WorkHopper wants to make it easy for you to take advantage of
          coworking related opportunities on our Website. One way we do this is
          by sending you email messages that contain information about your
          apparent coworking-related interests. For example, if you search for a
          coworking space in Nigeria or reviews on WorkHopper , we may send you
          an email message reminding you about special coworking space offers in
          Nigeria. Similarly, if you receive an email about Nigeria coworking
          and demonstrate an interest in Nigeria coworking spaces or reviews by
          affirmatively clicking on a link about Nigeria coworking spaces in the
          email, you may receive an email about Nigeria coworking space offers.
          We believe these email messages will provide you with useful
          information about coworking-related special offers available through
          our sites. Please note that you will have the opportunity to choose
          not to receive these email messages in any such email we send. The
          Help portion of the toolbar on most browsers will tell you how to
          prevent your browser from accepting new cookies, how to have the
          browser notify you when you receive a new cookie, or how to disable
          cookies altogether. Please note that if you refuse to accept cookies
          from WorkHopper , you will not be able to access portions of our site. */}
        </p>
        <p>
          <strong>
          {t("policyemail2")}
            {/* How We Protect Your Information: */}
            </strong>
        </p>
        <p>
        {t("policyemail3")}
          {/* We want you to feel confident about using WorkHopper , and we are
          committed to protecting the information we collect. While no website
          can guarantee security, we have implemented appropriate
          administrative, technical, and physical security procedures to help
          protect the personal information you provide to us. For example, only
          authorized employees are permitted to access personal information, and
          they only may do so for permitted business functions. In addition, we
          use encryption when transmitting your personal information between
          your system and ours, and between our system and those of the parties
          with whom we share sensitive information, and we employ firewalls and
          intrusion detection systems to help prevent unauthorized persons from
          gaining access to your information. */}
        </p>
        <p>
          <strong>
          {t("policyprivacy")}
            {/* Children's Privacy: */}
            </strong>
        </p>
        <p>
        {t("policyprivacy1")}
          {/* WorkHopper is a general audience site and does not offer services
          directed to children. Should an individual whom we know to be a child
          under age 13 send personal information to us, we will delete or
          destroy such information as soon as reasonably possible. */}
        </p>
        <p>
          <strong>
          {t("policyprivacy2")}
            {/* External Links: */}
            </strong>
        </p>
        <p>
        {t("policyprivacy3")}
          {/* If any part of the WorkHopper Website links you to other websites,
          those external websites do not operate under this Privacy Policy. We
          recommend that you examine the privacy statements posted on those
          other websites to understand their procedures for collecting, using,
          and disclosing personal information. */}
        </p>
        <p>
          <strong>
          {t("policyprivacy4")}
            {/* Visiting our Website from outside Nigeria: */}
            </strong>
        </p>
        <p>
        {t("policyprivacy5")}
          {/* If you are visiting our Website from outside the United States, please
          be aware that your information may be transferred to, stored, and
          processed in the Nigeria where our servers are located and our central
          database is operated. The data protection and other laws of Nigeria
          and other countries might not be as comprehensive as those in your
          country, but please be assured that WorkHopper does take steps
          pursuant to laws in the Federal Republic of Nigeria to ensure that
          your privacy is protected. By using our services, you understand that
          your information may be transferred to our facilities and those third
          parties with whom we share it as described in this Policy. */}
        </p>
        <p>
          <strong>
          {t("policyprivacy6")}
            {/* Changes To This Privacy Policy: */}
            </strong>
        </p>
        <p>
        {t("policyprivacy7")}
          {/* WorkHopper may change or modify this Privacy Policy in the future. We
          will note the date that revisions were last made to this Privacy
          Policy at the bottom of this page, and any revisions will take effect
          upon posting. We will notify our members of material changes to this
          Privacy Policy by either sending a notice to the email address you
          provided to us or by placing a notice on our Website. We encourage you
          to check this Privacy Policy from time to time to review the most
          current version. */}
        </p>
        <p>
          <strong>
          {t("policyprivacy8")}
            {/* Data Subject Rights: */}
            
          </strong>
        </p>
        <p>
        {t("policyprivacy9")}
          {/* WorkHopper has processes in place to ensure that it can facilitate any
          request made by any individual users to exercise their rights under
          Data Protection Law. All staff have received training and are aware of
          the rights of data subjects. Staff can identify such a request and
          know who to send it to.All requests will be considered without undue
          delay and within one month of receipt as far as possible. */}
        </p>
        <p>
          <strong>
          {t("policyprivacy10")}
            {/* How You Can Contact Us: */}
            </strong>
        </p>
        <p>
        {t("policyprivacy11")}
          {/* If you have questions about this Privacy Policy, please contact us at:
          support@WorkHopper .com. Please be aware that calls to and from
          WorkHopper may be recorded. We may use the call recordings to monitor
          our customer service for quality or compliance purposes, to check the
          accuracy of the information you provide to us, for fraud prevention
          purposes, or to provide training to our staff. We will retain the call
          recordings for as long as reasonably necessary to perform such
          activities and then delete them. Any personal information obtained
          from you during the call will be treated in accordance with the
          provisions of this Privacy Policy.&nbsp; */}
        </p>
        <p>
        {t("policyprivacy12")}
          {/* &copy;2021 WorkHopper Inc. All rights reserved. */}
          </p>
      </VStack>
    </>
  );
};

export default PolicyView;

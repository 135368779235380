import { VStack, Text, Divider, FormControl, FormLabel, Input, Textarea, SimpleGrid, Button, IconButton, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { MdStar } from 'react-icons/md'
import { Result, ResultType, Review } from '../../../../Models/Interfaces'
import { SetErrorContext, UserContext } from '../../../AppProvider/AppProvider'
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'
import { appDataManager } from '../Models/WHAppDataManager'
import { ListingDetailFormProps, ReviewItem } from './Models/Interfaces'
import { useTranslation  } from "react-i18next";

const PostReviewForm = ({listing, onSubmitEnded, userReview}: ListingDetailFormProps) => {

  let user = useContext(UserContext)
  let setErrorContext = useContext(SetErrorContext) 
  const toast = useToast()
  const {t} = useTranslation()

   // debugger
    let reviewItem: ReviewItem = {
        isRated: false,
        title: userReview?.title ?? "",
        desc: userReview?.description ?? "", 
        rating: userReview?.rating ?? 0
    } 

    const roles = [1, 2, 3, 4, 5]

    return (
        <>
          <Formik 
             initialValues={reviewItem} 
             initialStatus={{isValidating: false}}
             onSubmit={ async (values, {setSubmitting, setStatus}) => {
                  
                  if (!user || !user.access_token || !listing.id) { return }
                  //let result = Resu
                  let result: Result<Review> = {type: ResultType.Failure, error: new Error("Couldn't load reviews") }
                   
                  if (!userReview) {
                    result = await appDataManager.postReview(values, listing.id, user.access_token)
                  } else {
                     // update review
                     result = await appDataManager.updateReview(values, userReview.id, user.access_token)
                  }
                  if (result.type === ResultType.Success) {
                    onSubmitEnded()
                    toast({
                      title: "Success.",
                      description: `Your review ${userReview ? "updated" : "posted"} successfully.`,
                      status: "success",
                      duration: 3500,
                      isClosable: true,
                    })
                    
                  } else {
                    setErrorContext({title: "", msg: result.error.message})
                  }
            }}
             >
                {(props)=> {
                     return (
                      <Form
                      //className={styles.form}
                      // style={{paddingRight: "2.5em"}}
                      >
                         
                        <AddASpaceLayout>
                        <FormLabel
                            // htmlFor="descriptio"
                            width="full"
                            textAlign="left"
                            fontWeight={500}
                            fontSize={"15px"}
                          >
                            {t('Your Rating')}
                            {/* Your Rating */}
                          </FormLabel>
                          <SimpleGrid
                            mt="1em !important"
                            w="full"
                            columns={5}
                            
                          >
                            {roles.map((item, idx) => (
                             
                              <Field key={item} name="rating">
                                {({ field, form }: any) => (
                                //   <AspectRatio ratio={1 / 1}>
                                  <FormControl
                                    //className={amenitiesStlyes.amenitiesBtn}
                                  // w="full"
                                  // h="full"
                                  >
                                      <MdStar 
                                                //height="24px" 
                                                fontSize="30px"
                                                fill={ 
                                                    props.values.rating >= item
                                                    ? "red"
                                                    : "#eee"
                                                }

                                                onClick={() => {
                                                     props.setValues({
                                                      ...props.values,
                                                      rating: item,
                                                    });
                                                  }}
                                            />
                                  </FormControl>
                                //   </AspectRatio>
                                  
                                )}
                              </Field>
                              
                            ))}
                          </SimpleGrid>
                          <Field name="title">
                            {({ field, form }: any) => (
                              <FormControl>
                                <FormLabel
                                  fontWeight={500}
                                  fontSize={"15px"}
                                  htmlFor="title"
                                >
                                  {t('Title of Your Review')}
                                  {/* Title of Your Review */}
                                </FormLabel>
                                <Textarea
                                  rounded="lg"
                                  name="title"
                                  {...field}
                                  // value={value}
                                  onChange={props.handleChange}
                                  placeholder="Summarize your visit"
                                  size="md"
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name="desc">
                            {({ field, form }: any) => (
                              <FormControl>
                                <FormLabel
                                  htmlFor="desc"
                                  fontWeight={500}
                                  fontSize={"15px"}
                                >
                                  {t('Your Review')}
                                  {/* Your Review */}
                                </FormLabel>
                                <Textarea
                                  rounded="lg"
                                  name="desc"
                                  {...field}
                                  // value={value}
                                  onChange={props.handleChange}
                                  placeholder="Tell people about your experience"
                                  size="md"
                                />
                              </FormControl>
                            )}
                          </Field>
                          
                          <Button 
                            isLoading={props.isSubmitting}
                            onClick={()=> props.submitForm()}
                            mt={{base: "64px !important"}}
                            colorScheme="secondary"
                            w={{base: "80%"}} 
                            className="button">
                              {t('post review')}
                              {/* post review */}
                              </Button>
                        </AddASpaceLayout>
                      </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default PostReviewForm

//Abstract displays price according to the selected currency

import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { isNumericInput } from '../../../utils/utils'
import { CurrencyConverterContext } from '../../AppProvider/CurrencyConverterProvider'

 
interface WHPriceProps {
    priceText: string
}


const WHPrice = ({priceText}: WHPriceProps) => {

    const currencyConverter = useContext(CurrencyConverterContext)
     
    if (!isNumericInput(priceText)) {
        return (
            <Box as="span">
           N/A
        </Box>
        )
    }

    const priceVal = Number(priceText) * currencyConverter.rate 
    const formattedPrice = Intl.NumberFormat(currencyConverter.target.code === "USD" ? "en-US" : "en-NG", {
        style: "currency",
        currency: currencyConverter.target.code,
        maximumFractionDigits: 2
    }).format(priceVal)
    
    return (
        <Box as="span" >
            {formattedPrice}
            {/* {currencyConverter.target.symbol}{priceVal.toString().slice(0, (priceVal.toString().indexOf("."))+3)} */}
        </Box>
    )
}

export default WHPrice

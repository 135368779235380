import { FormikProps } from "formik";
import { Ref, RefObject } from "react";
import { CompartmentCategory, Listing, ListingCategory, RegistrationResponse, Workspace } from "../../../../Models/Interfaces";
import Amenities from "../../WHApp/AddASpace/Amenities";
import { WHDashboardDataManager } from "./WHDashboardDataManager";
import * as Yup from 'yup'
 
export enum AdminStateActions {

    ADDLISTING = "addListing",
    CHANGEWORKSPACE = "changeWorkspace",
    REPLACELISTINGS = "replacelistings",
    SELECTLISTING = "selectListing",
    REMOVELISTING = "removeListing",
    ADDLISTINGS = "addListings",
    UPDATELISTING = "updateListing"
}

export interface AppBarProps {
    onLogout: ()=>void
    registeredUser: RegistrationResponse 
}
export interface WHNavbarProps extends AppBarProps {
    onOpen: ()=> void
}

export interface WHDashboardDrawerProps extends WHNavbarProps {
    isOpen: boolean     
    onClose: () => void
}

export enum DashboardSectionName {
    Desc = "description",
    ContactDetails = "contact+details",
    Amenities = "amenities",
    Location = "location",
    HOP = "hop",
    Size = "size",
    PM = "payment+methods",
    WKS = "manage+workspaces",
    BKS = "manage+bookings",
    Offices = "manage+offices",
    Partner = "partner"


}

export interface WHManageWorkspacesProps {
    partner: RegistrationResponse
}

export interface DashboardSection {
    name: DashboardSectionName 
    title: string 
    next: DashboardSectionName
    prev: DashboardSectionName
}

export interface SpaceForm {
    name: string
    country: string
    role: string
    city: string
}

export interface  ContactForm {
   // email: string 
    websiteURL: string 
    workspaceEmail: string
}

export interface  AmenitiesForm {
    basics: string 
    equipment: string 
    community: string 
    seating: string 
}

export interface LocationForm {
    street: string
    state: string
    city: string 
    zipCode: string
    countryRegion: string
}

export interface HopForm {
    from: string
    to: string 
    satOpened: boolean
    sunOpened: boolean
    satFrom: string
    satTo: string
    sunFrom: string
    sunTo: string
    //isMemberAccessSame: boolean
    //isMemberAccess24: boolean
}

export interface SizeForm {
    
    capacity: string 
    //isSquareFt: boolean
    //isSquareM: boolean
    size: string
}

export interface DescForm {
    name: string
    desc: string
}

export interface DescFormProps {
    formRef: Ref<FormikProps<DescForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface SpaceProps {
    formRef: Ref<FormikProps<SpaceForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface ContactDetailsProps {
    formRef: Ref<FormikProps<ContactForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface SizeFormViewProps {
    formRef: Ref<FormikProps<SizeForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface AddOfficeProps {
    mode: string
    privateFormRef: RefObject<FormikProps<PrivateOfficeForm>> 
    setCategories: (categories: ListingCategory[]) => void
    categories: ListingCategory[]
    setSelectedCategory: (category: CompartmentCategory) => void
    selectedCategory?: CompartmentCategory 
}

// Add Office

export interface MeetingRoomForm {
    name: string 
    description: string
    capacity: string 
}

export interface OfficeAvailability {
    // officeAvailableByHour: boolean
    // officeAvailableByDay: boolean
    // officeAvailableByMonth: boolean
    // officeAvailableByYear: boolean
    startingPriceByHour: string 
    startingPriceByDay: string
    startingPriceByMonth: string
    startingPriceByYear: string
    accessByHour: string 
    accessByDay: string
    accessByMonth: string 

}

export interface PrivateOfficeForm extends OfficeAvailability {
    name: string 
    capacity: string
    price: string 
    hasWhiteboard: boolean
    description: string
    isRecieveMails: boolean 
    useOfficeAddress: boolean
    currency: string
}

export interface VirtualOfficeForm {
     
    price: string 
    virtualOfficesOffered: boolean
    description: string
}

export interface DesksForm {
     
    price: string 
    dedicatedPrice: string
    hours: number
    dedicatedHours: number
    description: string
}

export interface AmenitiesFormViewProps {
    formRef: Ref<FormikProps<AmenitiesForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface LocationFormViewProps {
    formRef: Ref<FormikProps<LocationForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface MeetingRoomFormViewProps {
    formRef: Ref<FormikProps<MeetingRoomForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface PrivateOfficeFormViewProps {
    uploadedFiles: UploadedFile[]
    uploadedOtherFiles: UploadedFile[]
    setUploadedFiles: (file: UploadedFile[]) => void
    setUploadedOtherFiles: (file: UploadedFile[]) => void
    formRef: Ref<FormikProps<PrivateOfficeForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
    selectedCategory?: CompartmentCategory
}

export interface VirtualOfficeFormViewProps {
    formRef: Ref<FormikProps<VirtualOfficeForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface DesksFormViewProps {
    formRef: Ref<FormikProps<DesksForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

export interface HopFormViewProps {
    formRef: Ref<FormikProps<HopForm>> 
    manager: WHDashboardDataManager
    updateFormSubmissionStatus: (value: boolean) => void
    actionAfterSubmit: ()=>void
}

 

export const yupDescValidation = Yup.object().shape({

    desc: Yup.string()
            .required("Description must not be empty")
            .max(350, "Descriptionn must not be below 2 characters"),
             


    name: Yup.string()
            .required("Lastname must not be empty")
            .min(2, "Lastname must not be below 2 characters")
            .max(50, "Lastname must not exceed 50 characters")

})

export interface UploadedFile {
    file: File 
    preview: string
  }

export interface OfficesViewProps {
    manager: WHDashboardDataManager 
}

export interface WorkspaceProgress {
    
    descAdded: boolean
    contactAdded: boolean
    amentiesAdded: boolean
    locationAdded: boolean 
    hopAdded: boolean
    sizeAdded: boolean

}
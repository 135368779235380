import React, { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import {AspectRatio, Box, Circle, HStack, IconButton, Image, Text, useMediaQuery, VStack} from '@chakra-ui/react'
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import "./styles.css";
import styles from "./HeaderCarousel.module.css"


// import Swiper core and required modules
import SwiperCore, {
    Autoplay,
  Pagination, 
  Navigation
} from 'swiper/core';
import { BannerViewProps } from '../BannerView/BannerView';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { BreakPoint } from '../../Screens/WHApp/OfficeDetail/Models/Interfaces';

SwiperCore.use([Navigation]);

interface SlidersViewProps extends BannerViewProps {
  setSwiper: (swiper: SwiperCore | undefined)=>void
}

const SlidersView = ({items, setSwiper}: SlidersViewProps) => {

    //const imgHeight = {base: "35vh", md: "50vh", lg: "67vh"}
    // const imgHeight = {base: "411px", md: "512px", lg: "1072px"}
     
     
    
    const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])
    let ratio =  1 / 1
    
    let slidesPerView = 1.05
    if (isMediumScrn) {
        slidesPerView = 1.1
    } 
    if (isLargeScrn) {
        slidesPerView = 1.85
    }

    return (
      <Box w="100%" h="100%" as="section"  
            // pt={{base: 4, md: 12, lg: 12}}
        >
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          onSlideChange={() => {}}
          loop={true}
         // spaceBetween={isMediumScrn || isLargeScrn ? 32 : 16}
          freeMode
          // centeredSlides
          speed={1000}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        
          navigation={false}
          className={styles.headerSwiper}
        >
          {items.map((item, idx) => (
            <SwiperSlide key={idx}>
                 
                  <Image w="full" h="full"
                     rounded="md" display="block" objectFit="cover" src={item.imageURL} />
                 
            </SwiperSlide>
          ))}

          
        </Swiper>
      </Box>
    );
}

const HeaderCarousel = ({items, breakpoint}: BannerViewProps) => {
  
  const [swiper, setSwiper] = useState<SwiperCore | undefined>()
   let btnSize = 6
  let headerHeight = "100vh"
  if (breakpoint === BreakPoint.XS || 
    breakpoint === BreakPoint.S
    ) {
      headerHeight = "50vh"
      btnSize = 8
    }  
   
    
    return (
        <>
        <VStack position="relative"  w="full"
        h={headerHeight}
        // h={{base: "50vh", md: "50vh", lg: "100vh"}}  
         spacing={"0px"} mb={{base: 4, md: 5, lg: 4}} alignItems="flex-start">
            <SlidersView setSwiper={setSwiper} items={items} />  
            <Box bg="#212121" zIndex={1} opacity={0.54} position="absolute" w="full" top="0px" left="0px" h="full"></Box> 
             
            <HStack h={6} 
             top="50%"
             color="white"
             zIndex={15}
             paddingX={4}
            className="slider-ctrls" position="absolute" 
                  justifyContent="space-between" w="full" >
                
               <Circle 
                   cursor="pointer"
                 size={btnSize} border="2px solid white" 
                onClick={()=>{
                   if (swiper) {
                   
                    swiper.slidePrev()
                  }
                }}
               >
                  <MdChevronLeft/>
               </Circle>
               <Circle 
                 cursor="pointer"
                 size={btnSize}
                 onClick={()=>{
                   if (swiper) {
                    swiper.slideNext()
                  }
                }}
                border="2px solid white">
                  <MdChevronRight/>
               </Circle>
            </HStack>
        </VStack>
   </>
    )
}

export default HeaderCarousel

import { Box, Button } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { usePaystackPayment } from 'react-paystack';
import { WorkhopperAPIManager } from '../../../Models/WorkhopperAPIManager';
import { SetErrorContext, SetSuccessContext, UserContext } from '../../AppProvider/AppProvider';

interface WHPaystackButtonProps {
    total: number 
    email: string
    hasBookedOffice: boolean
    width: string 
    variant: string
    colorScheme?: string
    reference: string
    isLoadingPaymentData?: boolean
    onSuccess: (reference: any)=>void
    onClose: ()=>void
    isPremium?: boolean
    premiumAction?: ()=>void
    onPaymentStarted?: ()=>void
}

interface Config {
    reference: string
     email: string
     amount: number
      publicKey: string
  }

  // // you can call this function anything
  // const onSuccess = (reference: any) => {
  //   // Implementation for whatever you want to do with reference and after success call.
  //  // console.log(reference);
  // };

  // // you can call this function anything
  // const onClose = () => {
  //   // implementation for  whatever you want to do when the Paystack dialog closed.
  //  // console.log('closed')
  // }
 

const WHPaystackButton = ({total, email, hasBookedOffice, width, variant, colorScheme, reference, onSuccess, onClose, premiumAction, isPremium, isLoadingPaymentData, onPaymentStarted}: WHPaystackButtonProps) => {

    //const [config, setConfig] = useState<Config>({reference: "", email: "", amount: 0, publicKey: ""})
    
    //console.log(Math.round(total) * 100)
    const config = {
        reference: reference,
        email: email,
        amount: total * 100,
        publicKey: "pk_test_621a3f422b659e5bd361a8c06b8dfb44d1f042cc",
      };

      const user = useContext(UserContext)


   // console.log(config)
    const [isLoading, setIsLoading] = useState(false)
    const initializePayment = usePaystackPayment(config);
    const setError = useContext(SetErrorContext)

    useEffect(()=>{
      if (premiumAction && config.reference !== "") {
         
        initializePayment(onSuccess, onClose)
      }

    }, [config.reference])

    return (
        <Box as="section" w="full">
            {
                  
                      <Button
                isLoading={isLoadingPaymentData}
               // disabled={total <= 0 || !hasBookedOffice || config.reference === "" || isPremium ? true : false}
                onClick={async (e)=>{
                   if (onPaymentStarted) {
                     onPaymentStarted()
                   }
                    e.stopPropagation()
                    if (email == "") {
                      setError({title: "", msg: "Please login to complete paymennt"})
                      return
                    }
                     
                   // debugger
                    if (!hasBookedOffice || !user || !user.access_token) { return }

                    if (premiumAction && reference === "") {
                      premiumAction()
                      //  let result = await premiumAction()
                      //  console.dir(result)
                        return 
                    }
                   
                    initializePayment(onSuccess, onClose)
                    
                 
                 // setIsOpen(true)
                  //postBookables()
                }}
                w={width}
                variant={variant}
                className="button"
                colorScheme={colorScheme ?? "secondary"}
                 >
                Pay
                </Button> 
                   
                }
        </Box>
    )
}

export default WHPaystackButton

import {  Text, Button, Center, Modal, ModalBody, 
    ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure,  VStack, HStack, SimpleGrid, Checkbox, Input, InputGroup, InputLeftAddon, ModalFooter, Spacer, Divider, GridItem, useMediaQuery, Menu, MenuButton, MenuItem, MenuList, Link, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, FormControl, FormLabel, useBreakpointValue, Box } from '@chakra-ui/react';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, {  ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { MdStar } from 'react-icons/md';
import { Adpage, AmenityCategory, AmenityDetails, DurationCategory, ListingCategory, OfficeItemsListProps, PublicListing, QueryItem, ResultType } from '../../../Models/Interfaces';
 import SearchResultsItemViews from '../../SupportingViews/SearchResultsItemViews/SearchResultsItemViews';
import WHLayoutGrid from '../../SupportingViews/WHLayoutGrid/WHLayoutGrid';
//../../Screens/WHApp/Models/Interfaces'
 import { AmenityItem, BreakPoint } from '../../Screens/WHApp/OfficeDetail/Models/Interfaces';
import RelatedOffices from '../../Screens/WHApp/OfficeDetail/RelatedOffices/RelatedOffices';
import FilterButton from './FilterButton';
import { AmenitiesFilterForm } from '../../Screens/WHApp/Models/Interfaces';
import { appDataManager } from '../../Screens/WHApp/Models/WHAppDataManager';
import { isIncluded, isItemIncluded } from '../../../utils/utils';
import { AmenityItemView } from '../../Screens/WHApp/OfficeDetail/AllAmenities/AllAmenities';
import { useListingCategories } from '../../../utils/Hooks/useListingCategories';
import OfflineView from '../OfflineView';
import WHSpinner from '../WHSpinner/WHSpinner';
import { Queries } from '../../Screens/WHApp/SearchResuts/SearchResults';
import { WorkhopperPublicAPIManager } from '../../../Models/WorkhopperPublicAPIManager';
import { useHistory } from 'react-router';
import AdsView from '../AdsView/AdsView';
import { CurrencyConverterContext } from '../../AppProvider/CurrencyConverterProvider';
 
const starRatingItemView = (rating: string) => {
return (
<HStack className="subtitle1">
  {Array(Number(rating)).fill(1).map((item, idx) => {
    return (
      <MdStar
        key={idx}
        // fontSize="10px"
        //fill={rating >= item ? "red" : "#eee"}
        fill={"red"}
      />
    );
  })}
  <Text>& Up</Text>
</HStack>
);
};

const filterByAmenties = (listings: PublicListing[], selectedAmenities: AmenityDetails[]): PublicListing[] => {

    const hasSelectedAmenities = ((listing: PublicListing) => {
       return listing.workspace_details.amenities.some((amenityItem) => 
                                                    isItemIncluded<AmenityDetails>(amenityItem.id, selectedAmenities))
    })

    return listings.filter(listingItem => hasSelectedAmenities(listingItem))
}

const filterByCategories = (listings: PublicListing[], selectedListingCategories: ListingCategory[]): PublicListing[] => {

    const isInSelectedCategory = ((listing: PublicListing) => {
       return isItemIncluded<ListingCategory>(listing.category, selectedListingCategories)
    })

    return listings.filter(listingItem => isInSelectedCategory(listingItem))
}

const filterByAverageRating = (listings: PublicListing[], selectedRating: number): PublicListing[] => {

    return listings.filter(listingItem => listingItem.avg_rating >= selectedRating)
}

//const getCategories = (cate)

const OfficeItemsListUpdated = ({title, listings, city, capacity, category, setListings, loadData, isLoading, setQueries, queryObj}: OfficeItemsListProps) => {
    
   // let listings: PublicListing[] = [] 
   const [listingCategoriesCount, setListingCategoriesCount] = useState(0)

   const {data:listingCategories, loading, error} = useListingCategories(listingCategoriesCount)

    const { isOpen, onOpen, onClose } = useDisclosure();
    
    const [selectedAmenities, setSelectedAmenities] = useState<AmenityDetails[]>([])

    const [amenityCategories, setAmenityCategories] = useState<AmenityCategory[]>([])
    const [amenityError, setAmenityError] = useState<Error | null>(null)

    const [selectedListingCategories, setSelectedListingCategories] = useState<ListingCategory[]>([])
    const [selectedRating, setSelectedRating] = useState<string | undefined>(queryObj.avg_rating)
    const [lowPrice, setLowprice] = useState<string | undefined>(queryObj.from) 
    const [highPrice, setHighprice] = useState<string | undefined>(queryObj.to) 
    const [numSeats, setNumSeats] = useState<string | undefined>(capacity) 
    const [isShowingAds, setIsShowingAds] = useState(false)
    const history = useHistory()
    const currencyConverter = useContext(CurrencyConverterContext)

    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 905px) and (max-width: 1439px)",
      "(min-width: 1440px)"
    ]);

    // const [listingCategories, setListingCategories] = useState<ListingCategory[]>([])
   // debugger    
   // const {data:listingCategories, loading, error} = useListingCategories(listingCategoriesCount)
        //const filterColspan = useBreakpointValue({base: 7, sm: 7, md: 7, lg: 6, xl: 6})
        const colspan = useBreakpointValue({base: 4, sm: 8, md: 12, lg: 12, xl: 12})
        const breakpoint = useBreakpointValue({base: BreakPoint.XS, sm: BreakPoint.S, 
                                                md: BreakPoint.M, lg: BreakPoint.L, xl: BreakPoint.XL})
     let didCancelRef = useRef<boolean>(false)
      let filterColspan = 4
      if (small) {
        filterColspan = 8
      } else if (medium) {
        filterColspan = 7
      } else if (large) {
        filterColspan  = 6
      } else  {
        filterColspan  = 6
      }

  let gridline = 4
  if (breakpoint === BreakPoint.S) {
    
    gridline = 8
  } 
  if (breakpoint === BreakPoint.M || breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL ) {
      gridline = 12
  } 
    // let someKeys = Object.keys(amenities)
     const durationItemViews = [  
                                DurationCategory.HOUR, 
                                DurationCategory.DAY,
                                DurationCategory.MONTH,
                                DurationCategory.YEAR
                            ].map((item, idx) => (
                                <HStack key={item}>
                                  <Checkbox
                                      onChange={()=>{}}
                                      size="lg"
                                    />
                                    <Text
                                       
                                    >
                                      {item}
                                    </Text>
                                    
                                  </HStack>
                            ))

                const officeCategoryItemViews = listingCategories.map((item, idx) => (
                    <HStack key={item.id}>
                        <Checkbox
                            colorScheme="primary"
                            isChecked={isItemIncluded(item.id, selectedListingCategories)}
                            onChange={(e)=>{
                                if (!e.target.checked) {
                                     
                                    let filtered = selectedListingCategories.filter((selectedItem)=> selectedItem.id !== item.id)
                                    setSelectedListingCategories(filtered)
                                  } else {
                                    setSelectedListingCategories([...selectedListingCategories, item])

                                  }
                            }}
                            size="lg"
                        />
                        <Text
                          textTransform="capitalize"
                        >
                            {item.title}
                        </Text>
                        
                        </HStack>
                ))

    const ratingOptionItemViews = ["1", "2", "3", "4"].map((item, _)=> (
             <Box
                px={4}
                display="grid"
                placeItems="center"
                bg="#fafafa"
                key={item}
                onClick={()=>{
                    setSelectedRating(item)
                }}
                h={12}
                 rounded="lg"
                border={selectedRating === item ? "1px solid #38a169" : "unset"}
               // minW={"120px"}
             >
                 {starRatingItemView(item)}
             </Box>
        )) 
      
    const onLowPriceChange = ( (e:any) => setLowprice(e.target.value) )
    const onHighpriceChange = ( (e:any) => setHighprice(e.target.value) )
   // const onCapacityChange = ( (e:any) => setNumSeats(e.target.value) )
    const onCapacityChange =  (e:any) => { 
       
        setNumSeats(e.target.value) 
      }

 
    const spaceTypeView = ()=>(
        <>
             {
                        loading &&
                        <WHSpinner />
                    }
                    {
                        error &&
                        <OfflineView pt="32px" onRetry={()=>{
                            // use state counter to trigger effect
                            setListingCategoriesCount((prev)=> prev + 1)
                           // useListingCategories()
                      }} msg={error.message} />
                    }
                    {
                        listingCategories.length > 0 &&
                        <VStack w="full" spacing={2}>
                        <SimpleGrid w="full" columns={2} columnGap={4} gap={4}>
                        {officeCategoryItemViews}
                      </SimpleGrid>
                        <Divider />
                        <HStack w="full">
                        <Button
                          variant="outline"

                           // isLoading={isLoading ?? false}
                            onClick={()=>{
                              setSelectedListingCategories([])
                            }}
                            w={"auto"}
                           // ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                          >
                            Clear
                          </Button>
                          <Spacer/>
                          
                        </HStack>
                      </VStack>
                    }
        </>
    )

    const priceRangeView = ()=>(
        <VStack w="full" spacing={2}> 
        <HStack w="full">
                    <Text className="body1">From</Text>
                    <InputGroup>
                      <InputLeftAddon children={currencyConverter.base.code} />
                      <Input
                        value={lowPrice ?? ""}
                        onChange={onLowPriceChange}
                        //name="name"
                        type="number"
                        //placeholder=""
                      />
                    </InputGroup>
                    <Text className="body1">To</Text>
                    <InputGroup>
                      <InputLeftAddon children={currencyConverter.base.code} />
                      <Input
                        value={highPrice ?? ""}
                        onChange={onHighpriceChange}
                        //name="name"
                        type="number"
                        //placeholder=""
                      />
                    </InputGroup>
                  </HStack>
                  <HStack w="full">
                        <Button
                            variant="outline"
                           // isLoading={isLoading ?? false}
                            onClick={()=>{
                               setLowprice(undefined)
                               setHighprice(undefined)
                            }}
                            w={"auto"}
                           // ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                          >
                            Clear
                          </Button>
                          <Spacer/>
                  </HStack>
              </VStack>
    )

    const capacityView = ()=>(
      <VStack spacing={2} w="full"> 
      <HStack w="full">
                   
                  <InputGroup>
                    {/* <InputLeftAddon children="USD" /> */}
                    <Input
                      value={numSeats ?? ""}
                      onChange={onCapacityChange}
                      //name="name"
                      type="text"
                      placeholder="No of Seats"
                    />
                  </InputGroup>
                   
                </HStack>
        <Divider />
        <HStack w="full">
                        <Button
                           // isLoading={isLoading ?? false}
                           variant="outline"

                            onClick={()=>{
                               setNumSeats(undefined)
                            }}
                            w={"auto"}
                           // ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                          >
                            Clear
                          </Button>
                          <Spacer/>
                  </HStack>
      </VStack>
  )

    const amenitiesView = () => (
        <>
            {
                  amenityError ? (
                    <OfflineView pt="32px" onRetry={()=>{
                      console.log("loading amenities")
                     let didCancel = false
                    loadAmenities()
                     }} msg={amenityError.message} />
                  ) : (
                    <VStack>
                    {
                      amenityCategoryItemsView(amenityCategories)
                    }
                  <HStack w="full">
                  <Button
                     // isLoading={isLoading ?? false}
                     variant="outline"

                      onClick={()=>{
                         setSelectedAmenities([])
                      }}
                      w={"auto"}
                     // ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                      colorScheme="primary"
                    >
                      Clear
                    </Button>
                    <Spacer/>
                    </HStack>
                  </VStack>
                  )
                   
                    // <Text className="body2">{amenityError.message}</Text>
                  
              }
              
                 
              
        </>
    )

    const ratingsView = () => (
        <>
            {
              <VStack w="full" alignItems="flex-start"> 
              <SimpleGrid columns={2} columnGap={4} gap={4}>
                 {ratingOptionItemViews}
               </SimpleGrid>
               <HStack w="full">
                        <Button
                             variant="outline"
                             onClick={()=>{
                               setSelectedRating(undefined)
                            }}
                            w={"auto"}
                             colorScheme="primary"
                          >
                            Clear
                          </Button>
                          <Spacer/>
                  </HStack>
               </VStack>
            }
        </>
    )

    const filterViewLarge = () => {

        return (
          <>
            
            <GridItem colSpan={filterColspan} rowSpan={1}>
              <HStack spacing={3} w="full">
                {/* <FilterButton label="Duration">
                  <SimpleGrid columns={2} columnGap={4} gap={4}>
                    {durationItemViews}
                  </SimpleGrid>
                </FilterButton> */}
                
                <FilterButton label="Office Category">
                   {spaceTypeView()}
                
                </FilterButton>
                <FilterButton label="Capacity">
                   {capacityView()}
                
                </FilterButton>
                <FilterButton label="Rating">
                    {ratingsView()}
                </FilterButton>
                <FilterButton label="Duration Price">
                  {priceRangeView()}
                </FilterButton>
                <FilterButton label="Amenities">
                  {amenitiesView()}
                 {/* {amenityCategoryItemsView(amenityCategories)} */}
                </FilterButton>
              </HStack>
            </GridItem>
            <GridItem colSpan={3} rowSpan={1}>
              <Button
                
                isLoading={isLoading ?? false}
                onClick={onApplyFilters}
                w={"50%"}
                ml={breakpoint == BreakPoint.M ? "0px" : "0px"}
                colorScheme="primary"
              >
                Apply
              </Button>
            </GridItem>
            <GridItem colSpan={12}></GridItem>
          </>
        );
    }

    const filterView = ()=> {
        
        return (
          <VStack w="full" spacing={4} alignItems="flex-start">
            <VStack
               w="full"
              alignItems="flex-start"
            >
              <Text className="h6" fontSize="18px !important">Office Category</Text>
              {spaceTypeView()}
            </VStack>
            <VStack
               w="full"
              alignItems="flex-start"
            >
              <Text className="h6" fontSize="18px !important">Capacity</Text>
              {capacityView()}
            </VStack>
            <VStack alignItems="flex-start">
              <Text className="h6" fontSize="18px !important">Customer Ratings</Text>
               {ratingsView()}
              {/* <Select>
                      {ratingOptionItemView}
                  </Select> */}
            </VStack>
            {/* <VStack alignItems="flex-start" w="full">
              <Text className="subtitle1">Duration</Text>
              <SimpleGrid w="full" columns={2} columnGap={4} gap={4}>
                {durationItemViews}
              </SimpleGrid>
            </VStack> */}
            <VStack alignItems="flex-start" w="full">
              <Text className="h6" fontSize="18px !important">Duration Price</Text>
              {
                  priceRangeView()
              }
              
            </VStack> 
            <VStack alignItems="flex-start" w="full">
              <Text className="h6" fontSize="18px !important">Amenities</Text>
              {amenitiesView()}
            </VStack>
          </VStack>
        );
    }

    const amenityCategoryItemsView = (categories: AmenityCategory[]): ReactNode => {
        return  categories.map((category, idx)=> {
            return (
               
                 <VStack key={category.id} spacing={2} alignItems="flex-start" w="full">
                     <Text fontWeight="medium" className="subtitle1">{category.title}</Text>
                       <SimpleGrid w="full" rowGap={4}  
                                   columns={breakpoint == BreakPoint.XS || breakpoint == BreakPoint.S ? 2 : 3} 
                                   columnGap={4}>
                         {
                           
                             category.amenities.map((item, _)=> (
                               <HStack 
                                       bg="#fafafa"
                                       w="full" h={"64px"} p={4} 
                                       alignItems={"center"}
                                       justifyContent={"center"}
                                       key={item.id}  >
                                    <Checkbox 
                                        colorScheme="primary"
                                       isChecked={isIncluded(item.id, selectedAmenities)} onChange={(e)=>{
                                         if (!e.target.checked) {
                                            let filtered = selectedAmenities.filter((selectedItem)=> selectedItem.id !== item.id)
                                            setSelectedAmenities(filtered)
                                          } else {
                                            setSelectedAmenities([...selectedAmenities, item])
   
                                          }
                                    }}>
                                        <Text noOfLines={2} textTransform="capitalize" textAlign="center" className="subtitle1">{item.title}</Text>
                                    </Checkbox>
                               </HStack>
                           ))
                         }
                       </SimpleGrid>
                      
                 </VStack>
               
            )
         })
 
    }

    // const onAmenityChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    //     let itemId = e.target.value
    //     if (isIncluded(itemId, selectedAmenities)) {
    //         let filtered = amenities.filter((selectedItem)=> selectedItem.id !== item.id)
    //         setSelectedAmenities(filtered)
    //       } else {
    //        setSelectedAmenities([...amenities, item])

    //       }
    // }

    // const onApplyFilters = async ()=>{
    //     let q: Queries = {category: selectedListingCategories[0].id}
    //     const listings = await appDataManager.searchOffices(q)
    // }

    async function loadAmenities( ) {
       
        const result = await appDataManager.fetchAmenityCategories()
        if (result.type === ResultType.Success) {
             let items = result.value.map((item, idx)=>{
                 return {
                   id: item.id,
                   title: item.title,
                   amenities: item.amenities
                 }
              })
              //debugger
              if (!didCancelRef.current) {
                setAmenityCategories(items)
                let detailItems: AmenityDetails[] = []
                if (queryObj.amenity && queryObj.amenity.length > 0) {
                  for (let category of items) {
                     for (let amenityId of queryObj.amenity) {
                       let foundDetail = category.amenities.find(amenityDetail => amenityDetail.id === amenityId)
                       if (foundDetail) {
                        // console.dir(foundDetail)
                         detailItems.push(foundDetail)
                         //setSelectedAmenities([...selectedAmenities, foundDetail])
                       }
                      //  if (isIncluded(amenityId, category.amenities)) {
                      //    setSelectedAmenities(...selectedAmenities, )
                      //  }
                     }
                    //category.amenities.some(amenity => queryObj.amenity?.find(amenityId => amenityId === amenity.id) )
                  }
                  setSelectedAmenities(detailItems)
                }
                
              }
              setAmenityError(null)
              
         } else {
            setAmenityCategories([])
          setAmenityError(new Error("Couldn't load amenities filter. Try again"))
       } 
      }

     useEffect(() => {
         didCancelRef.current = false

         if (!didCancelRef.current) {
          loadAmenities()
         }
         return ()=> { 
             //debugger
             didCancelRef.current = true 
            }
     }, [])

     useEffect(()=>{

       if (listingCategories.length > 0) {
        let temp = category ? listingCategories.filter(item => category.includes(item.id)) : []
        setSelectedListingCategories(temp)
       }

     }, [listingCategories])


    //  useEffect(()=>{
    //       setLowprice("")
    //       setHighprice("")

    //  }, [currencyConverter.target.code])

     //console.dir(listings)

     const onApplyFilters = async ()=>{

         
        
        
        let info: Queries = {};
        info.category = selectedListingCategories.map(item => item.id)
        info.amenity = selectedAmenities.map(item => item.id)
        info.city = city;
        info.capacity = numSeats
        info.avg_rating = selectedRating 
        info.from = lowPrice
        info.to = highPrice

         
       // setQueries(info)
       let queryItems: QueryItem[] = [] 
    Object.entries(info).forEach(([key, value]) => {
        if (value) {
           
            if (Array.isArray(value)) {
                (value as string[]).map((item) => queryItems.push({name: key, value: item}))
            } else {
                queryItems.push({name: key, value})
            }
        }
    })
    
    const url = WorkhopperPublicAPIManager.shared.appendQueryitems("/search", queryItems)
    history.push(url)
        // if (loadData) {
        //   setListings([])
        //   loadData(info)
        // }

        // let result = await appDataManager.searchOffices(info);
        // if (result.type === ResultType.Success) {
        //     setListings(result.value)
        // } else {
        //     setListings([])
        // }
     }

     const onClearFilters = ()=> {
      let queryItems: QueryItem[] = [] 
      const url = WorkhopperPublicAPIManager.shared.appendQueryitems("/search", queryItems)
      history.push(url)
     }

     

     return   (
      
       <WHLayoutGrid height="auto">
          <GridItem  colSpan={colspan} 
           >
              <Text 
                className={breakpoint == BreakPoint.XS || breakpoint == BreakPoint.S ? "subtitle1" : "h6"}>
                    {title}
                </Text>
            </GridItem>
        {breakpoint == BreakPoint.M || breakpoint == BreakPoint.L || breakpoint == BreakPoint.XL ? (
         filterViewLarge()
        ) : (
          <GridItem colSpan={colspan} rowSpan={2}>
            <Center>
              <Button
                isLoading={isLoading}
                 className="button"
                 colorScheme="primary"
                rounded="lg"
                onClick={onOpen}
                w={{ base: "50%", md: "40%" }}
              >
                Filter
              </Button>
            </Center>
          </GridItem>
        )}
          <>
          {
            isShowingAds ? (
              <GridItem colSpan={gridline} 
              //rowSpan={!xsmall ? 1 : 13}
                rowSpan={breakpoint !== BreakPoint.XS ? 2 : 4}
              >
                  <AdsView page={Adpage.SEARCH} showAds={(isShowing: boolean)=> setIsShowingAds(isShowing)} numAds={1} adIndex={0} width="100%" height="72px" />
            </GridItem> 
            ):null
          }
         
           </>

        <SearchResultsItemViews listings={listings} />
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={breakpoint == BreakPoint.XS ? "md" : "lg"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            position={breakpoint === BreakPoint.XS ? "fixed" : "unset"}
            bottom={breakpoint === BreakPoint.XS ? "0px" : "unset"}
            mb={breakpoint === BreakPoint.XS ? "0" : "unset"}
             borderRadius={breakpoint === BreakPoint.XS ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              Filters
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody>{filterView()}</ModalBody>
            <Divider />
            <ModalFooter>
            <Spacer />
              <Button 

                    isLoading={isLoading}
                    colorScheme="primary"
                    onClick={()=>{
                      onApplyFilters()
                      onClose()
                    }}
               >
                        Apply
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </WHLayoutGrid>
      //   </HStack>
    )  
    
}

export default OfficeItemsListUpdated

export interface ReasonItem {
    image: string 
    title: string 
    description: string
}
//
export const data: ReasonItem[] = [
    {
        //"image": "../images/collaboration.png",
        "image": "../images/UseWorkhopperIcons/flexible.png",
        "title": "flexibleworkspaces",
        "description": "flexibleworkspacesD"
    },
    {
        "image": "../images/UseWorkhopperIcons/happy.png",
        "title": "happyusers",
        "description": "happyusersD"
    },
    {
        "image": "../images/UseWorkhopperIcons/interface.png",
        "title": "simpleinterface",
        "description": "simpleinterfaceD"
    },
    {
        "image": "../images/UseWorkhopperIcons/cities.png",
        "title": "topcoworkingcities",
        "description": "topcoworkingcitiesD"
    }
]
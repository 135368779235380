import { Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, Stack, VStack, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { MdMail } from 'react-icons/md';
import * as Yup from 'yup'
import { ResultType } from '../../../../Models/Interfaces';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { SetErrorContext, SetSuccessContext } from '../../../AppProvider/AppProvider';
import { useTranslation  } from "react-i18next";

export const yupClaimEmailValidation = Yup.object().shape({

    email: Yup
            .string()
             .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Please enter a valid email"
            ),
})

interface ClaimListingFormProps {
    onSubmitEnded: (resultMsg: string)=>void
    email: string
    listingId: string
}


const ClaimListingForm = ({onSubmitEnded, email, listingId}: ClaimListingFormProps) => {

  const fieldHeight = 10
  const setError = useContext(SetErrorContext)
  const setSuccess = useContext(SetSuccessContext)
  const [resultMsg, setResultMsg] = useState<{success: string}>({success: ""})
  const {t} = useTranslation()

  const getHiddenEmail = ()=>{
      if (email.length === 0) { return ""}
      let indexOfAt = email.indexOf("@")
      if (indexOfAt < 0) {return ""}
      const subStringToHide = email.substring(1, indexOfAt)
      const subStringToShow = email.substring(indexOfAt)

      //const lengthToHide =  email.substring(1).length
      let hidden = ""
      for (let c of subStringToHide) {
          hidden += "*"
      }

      return email[0] + hidden + subStringToShow
  }
 // console.log(email)
  return (
    <Formik
                initialValues={{
                  email: "",
                }}
                
                validationSchema={yupClaimEmailValidation}
                // validateOnBlur={false}
                //validateOnChange={false}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting, setStatus, setValues, resetForm }) => {
                   
                    setSubmitting(true)
                    // if (values.email !== email) {
                    //   setSubmitting(false)
                    //   setError({title: "", msg: "Email entered is invalid. Try again"})
                    //   return
                    // }
                    //onSubmitEnded("success")
                     

                    const result = await WorkhopperAPIManager.shared.getClaimCode(listingId)
                    if (result.type === ResultType.Success) {
                        setSuccess({title: "", msg: result.value.success})
                        onSubmitEnded("success")
                    } else {
                       setError({title: "", msg: result.error.message})
                      
                    }

                    
                   
                  
                }}
              >
                {(props)=>{
                    return (
                        <Form >
                            <Stack paddingX={4} alignItems="center" direction={"column"} w="full" spacing={6}>
                             <FormControl> 
                            <FormLabel fontWeight={500} fontSize={"15px"}>
                              {t('Workspace Email')}
                                 {/* Workspace Email */}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdMail color="#fff" />}
                                  />
                                  <Input
                                    value={getHiddenEmail()}
                                    id="email"
                                   // name="email"
                                    type="email"
                                    disabled
                                    h={fieldHeight}
                                  />
                                </InputGroup>
                               </FormControl>
                            <Field name="email">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isRequired
                                id="email"
                                isInvalid={
                                  form.errors.email && form.touched.email
                                }
                              >
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                  {t('Confirm Email')}
                                  {/* Confirm Email */}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdMail color="#fff" />}
                                  />
                                  <Input
                                    {...field}
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={props.handleChange}
                                    h={fieldHeight}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.email}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        {/* {
                          resultMsg.success !== "" ? (
                            <Text className="subtitle1">{resultMsg.success}</Text>
                          ): null
                        } */}
                        <Button
                           isLoading={props.isSubmitting}
                           colorScheme={"primary"}
                           w="80%"
                           onClick={()=>{
                                 props.submitForm()
                                //  if (!props.isSubmitting) {
                                //     props.resetForm()
                                //  }
                           }}
                          className="button">
                            {t('Submit')}
                            {/* Submit */}
                            </Button>
                         
                       </Stack>

                      
                      </Form>
                    )
                }}
              </Formik>
  )
};

export default ClaimListingForm;

/**
 * Abstract: A view that shows global navigation controls
 *  
 */
import { Button, Center, Flex, HStack, IconButton, Image, Link, useMediaQuery, Text, Circle, MenuButton, MenuList, MenuItem, Menu, Avatar, Box, Skeleton } from '@chakra-ui/react'
import React, {FC, useContext, useEffect} from 'react'
import { MdAdd, MdFileDownload, MdKeyboardArrowDown, MdMenu, MdSearch, MdShoppingCart } from 'react-icons/md'
import workhopperDarkIcon from "../../../Assets/icon_dark.png"
import workhopperLite from "../../../Assets/logo_dark.png"

import routes from '../../../routes'

import { NavLink as ReactLink, useHistory, useRouteMatch } from "react-router-dom";
import "../../../App.css"
import { CartContext, CategoriesContext, SetUserContext, UserContext } from '../../AppProvider/AppProvider'
import { signUpDataManager } from '../WHApp/SignUp/SignUpDataManager'
import { RegistrationResponse } from '../../../Models/Interfaces'
import PartnerMenuContols from '../../SupportingViews/PartnerMenuControls/PartnerMenuContols'
import OfficeCategoryLinks from '../../SupportingViews/OfficeCategoryLinks/OfficeCategoryLinks'

import { HashLink as ReactHashLink } from 'react-router-hash-link';
import WHMenuButton from '../../SupportingViews/WHMenuButton/WHMenuButton'
import WHAvatarMenuButton from '../../SupportingViews/WHAvatarMenuButton/WHAvatarMenuButton'
import LayoutView from '../../SupportingViews/LayoutView/LayoutView'
//import { CartContext } from '../../AppProvider/WHAppProvider'
import styles from "./WHNavbar.module.css"
import WHSiteActionButtons from '../../SupportingViews/WHSiteActionButtons/WHSiteActionButtons'
import CurrencyConverterProvider from '../../AppProvider/CurrencyConverterProvider'
import { useTranslation } from 'react-i18next';


 
interface WHNavbarProps {
    onOpen: ()=> void
    onSearchModalOpen: ()=>void
}


const WHNavbar: FC<WHNavbarProps> = (props: WHNavbarProps)=> {

    const [isLargeScrn] = useMediaQuery(["(min-width: 1024px)"])
    

    return (
        <>
         {isLargeScrn && 
            <LargeNavbar onModalSearchOpen={props.onSearchModalOpen} />
         }
         {!isLargeScrn && 
            <MobileNavbar {...props} />
         }
        </>
      
    );
}

const MobileNavbar = ({onOpen, onSearchModalOpen}: WHNavbarProps)=> {

  let registeredUser =  useContext(UserContext)
    
    return (
        <Flex 
            position="fixed"
            zIndex="104"
            shadow="md"
            w="full"  
            bg="white"
            top="0px"
            //h={{base: "56px", md: "64px"}}
            //bg="primary.500" 
            paddingX={{base: 4, md: 5}} justifyContent="flexStart" alignItems="center" h={{base: "56px"}}>
             
             <HStack spacing={2}>
             <Link 
            as={ReactHashLink}
            to={`/#download`}
          >
           <IconButton
              w="40px"
              h="36px"
              colorScheme="secondary"
              aria-label="download workhopper"
              icon={<MdFileDownload/>}
           />
         </Link>
             <IconButton  variant="outline"   bg="white" 
                     w="40px"
                     h="36px"
                   colorScheme="primary" aria-label="Open search" 
                  onClick={()=>{
                    onSearchModalOpen()
                    //history.push(`/${routes.confirmPay.name}`)
                  }} 
                  icon={
                    
                      <MdSearch color="#262949" fontSize="20px"/>
                  
                  }
              />
             </HStack>
            
             
              
          <Center w="full">
            <Link  
              mr={registeredUser ? "unset" : "40px"}
              //  mx="auto"
              //ml={8}
              as={ReactLink} to={routes.home.path}>
            <Image
                fallback={<Skeleton />}
                  boxSize="48px"
                src={workhopperLite}
                alt="workhopper"
                //h={{base: 12}}
                objectFit="contain"
            />
            </Link>
          </Center>
            
           
            
          <HStack spacing="0px">
          {/* <IconButton  variant="ghost"   bg="white" 
            
            colorScheme="primary" aria-label="Open Menu" 
                  onClick={()=>{
                    onSearchModalOpen()
                    //history.push(`/${routes.confirmPay.name}`)
                  }} 
                  icon={
                    
                      <MdSearch color="#262949" fontSize="20px"/>
                  
                  }
              /> */}
          {
                
                registeredUser ? (
                  <WHAvatarMenuButton 
                    registeredUser={registeredUser}
                    onOpen={onOpen}
                  />
                
                ):
                ( <IconButton bg="white" variant="ghost" 
                colorScheme="primary" aria-label="Open Menu" 
                onClick={onOpen} 
                icon={<MdMenu color="#262949" fontSize="30px"/>} />)
              }
            </HStack>
      </Flex>
    )
}

interface NonPartnerMenuProps {
  user: RegistrationResponse
  onSignOut: () => void
}

/*
const PartnerMenuList = ({user, onSignOut}: NonPartnerMenuProps)=>{
 
    return (
      <MenuList>
      <MenuItem color="primary.500" minH="48px">
        <Link
          minW="8%"
          textDecoration="none !important"
          as={ReactLink}
          to={`/dashboard/${bizId}/manage+workspaces`}
          color="primary.500"
        >
            Your Workspaces
           
        </Link>
      </MenuItem>
      <MenuItem minH="48px">
        <Link
          color="primary.500"
          minW="8%"
          textDecoration="none !important"
          as={ReactLink}
          to={`/dashboard/manage+requests/6/bookings/overview`}
        >
            Your Bookings/Enquiries
        </Link>
      </MenuItem>
    </MenuList>
    )

}*/

interface LargeNavbarProps {
  onModalSearchOpen: ()=>void
}

const LargeNavbar = (props: LargeNavbarProps)=>{

  let registeredUser =  useContext(UserContext)
  let setUserContext = useContext(SetUserContext)
  let officeCategories = useContext(CategoriesContext)
  const {state: cart} = useContext(CartContext) 
  let {path, url}   = useRouteMatch()
  let history = useHistory()
  const {t} = useTranslation()

  const [medium] = useMediaQuery([
    
    // "(max-width: 1239px) and (-webkit-min-device-pixel-ratio: 2)",
    "(min-width: 1240px)"

  ]);

  

  // const [isIpadMedium] = useMediaQuery([
    
  //   "(min-device-width: 905px)",
  // ]);
   
  let officeLinks = officeCategories.map((item, _) => {

      let name = ""
       if (item.title.toLocaleLowerCase().includes("meeting")) {
         name = routes.meetingRooms.name
       } else if (item.title.toLocaleLowerCase().includes("virtual")) {
         name = routes.virtualRooms.name
       } else if (item.title.toLocaleLowerCase().includes("private")) {
         name = routes.privateOffices.name
       } else  {
         name = routes.sharedOffices.name
       }
      return <Link
       key={item.id}
       color="primary.500"
      as={ReactLink}
      textDecoration="none !important"
      display="flex"
      alignItems="center"
      fontWeight="400 !important"
      h={12}
      className="subtitle1"
      to={`/${name}/${item.id}`}
      
    >
      {item.title}
    </Link>
  })

   
  useEffect(() => {
    
    registeredUser = signUpDataManager.getRegisteredUserData()
    if (registeredUser) {
      setUserContext(registeredUser)
     }
   
  }, [])

  const handleLogout = ()=> {
    signUpDataManager.logUserout()
    setUserContext(null)
    //history.push(url)
  }
    
   const navbarControls = ()=> {

 
      return (
        registeredUser ? (
          <>
               <Menu>
                 <WHMenuButton
                    registeredUser={registeredUser}
                 />
      
      <MenuList>
      
      <MenuItem 
          onClick={()=>{
            history.push(`/${routes.settings.name}`)
          }}
          minH="48px">
          <Text color="primary.500" className="body2">Settings</Text>
        </MenuItem>
      <MenuItem 
           onClick={()=>{
            history.push(`/${routes.bookingHistory.name}`)
          }}
      minH="48px">
          <Text color="primary.500" className="body2">{t("Booking History")}</Text>
        </MenuItem>
        <MenuItem onClick={handleLogout} minH="48px">
          <Text color="primary.500" className="body2">{t("Sign Out")}</Text>
        </MenuItem>
       
      </MenuList>
    </Menu>
          </>
        ): (
          <>
             <Link 
              //w="38%"  
                w="auto"
                textDecoration="none !important" 
                as={ReactLink} 
                to={routes.signup.path}
              >
                {/* SignUp */}
              <Button
                width="full"
                colorScheme="primary"
                //colorScheme="white"
                className="button"
                 variant="outline"
              
              >
                {t(routes.signup.title.toLowerCase()+'btn')}
              </Button>
            </Link>
            
            <Link 
             // w="38%" 
             w="auto"

              textDecoration="none !important" as={ReactLink} to={routes.login.path}>
              <Button
                 width="full"
                 className="button"
                 colorScheme="primary"
                 //colorScheme="white"
                // color="white"
                variant="outline"
              >
                 {t(routes.login.title.toLowerCase())}
              </Button>
            </Link>
          </>
        )
      )
    }

 
    return (
      //  <LayoutView> 
      <Flex
        className={styles.navbar}
        position="fixed"
        zIndex="104"
        shadow="lg"
        top="0px"
        //bg="primary.500"
        bg="white"
        w="full"
        //pr={20}
        //pl={20}
        justifyContent="space-between"
        alignItems="center"
        h={{ base: "56px", md: "64px", lg: "72px" }}
        color="white"
      >
        <Link
          textDecoration="none !important"
          display="flex"
           mr="auto"
          alignItems="center"
          //ml={"-24px"}
          as={ReactLink}
          to={routes.home.path}
        >
          <Image
            src={workhopperLite}
            alt="workhopper logo"
            h={12}
            objectFit="contain"
          />
        </Link>
        <HStack w="90%">
        <HStack w="55%"
          margin="0 auto"
        >
          {/* <HStack 
              w={registeredUser ? "45%" : "full"}
              margin={registeredUser ? "0 auto" : "unset"}
              justifyContent="center" spacing="6"> */}
            <OfficeCategoryLinks />
            {/* {officeLinks} */}
            
          </HStack>

          <HStack
          w="auto" 
          spacing={4} 
          //w={registeredUser ? "unset" : "45%"}
          >
              <WHSiteActionButtons />
            {/* {
              !medium ? (
                   <WHSiteActionButtons />
                 
              ) : (
                <Link 
                as={ReactHashLink}
                to={`/#download`}
              >
               <IconButton
                  colorScheme="secondary"
                  aria-label="download workhopper"
                  icon={<MdFileDownload/>}
               />
             </Link>
              )
            } */}
            {
              !registeredUser && 
              <>
                {
                  medium ? (
                    <Link
                    //w="80%"
                    w="auto"
                    textDecoration="none !important"
                    as={ReactLink}
                    to={`${routes.addASpace.path}/intro`}
                  >
                    <Button
                      //size="md"
                      className="button"
                      leftIcon={<MdAdd />}
                      //colorScheme="white"
                      colorScheme="primary"
                      variant="outline"
                    >
                      {t('addaspace')}
                    </Button>
                  </Link>
                  ) : (
                    <IconButton variant="outline"  bg="white" 
            
                    colorScheme="primary" aria-label="Open Menu" 
                          onClick={()=>{
                            history.push(`/${routes.addASpace.path}/intro`)
                          }} 
                          icon={          
                              <MdAdd color="#262949" fontSize="20px"/>
                          }
                      />
                  )
                }
              </>
             
            }
            {registeredUser && registeredUser.user_type === "partners" ? (

              <PartnerMenuContols
                partner={registeredUser}
                onLogout={handleLogout}
              />
            ) : (
              <>
               
                {navbarControls()}
              </>
            )}
          </HStack>
          <Center
               position="relative"
               w="48px"
               h="48px"
            >
            <IconButton variant="outline"  bg="white" 
            
            colorScheme="primary" aria-label="Open Menu" 
                  onClick={()=>{
                    props.onModalSearchOpen()
                    //history.push(`/${routes.confirmPay.name}`)
                  }} 
                  icon={
                    
                      <MdSearch color="#262949" fontSize="20px"/>
                  
                  }
              />
              
            </Center>
          <Center
               position="relative"
               w="48px"
               h="48px"
            >
            <IconButton variant="outline"  bg="white" 
            
            colorScheme="primary" aria-label="Open Menu" 
                  onClick={()=>{
                    history.push(`/${routes.confirmPay.name}`)
                  }} 
                  icon={
                    
                      <MdShoppingCart color="#262949" fontSize="20px"/>
                  
                  }
              />
              <Circle alignItems="center" justifyContent="center" 
                  w={4} h={4} 
                  top="0px" right="0px"  
                  position="absolute" bg="secondary.500">
                  <Text className="caption" fontSize="8px !important" color="white">{cart.items.length}</Text>
              </Circle>
            </Center>
           
        </HStack>

      </Flex>
      // </LayoutView>
      
    );
}

export default WHNavbar
 


import { VStack, Text, FormControl, FormLabel, Input, HStack, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'
import { CardDetailsItem } from './Models/Interfaces'
import { useTranslation  } from "react-i18next";

//import countryItems from "world_countries_lists/data/en/countries"
import { MdFlag } from 'react-icons/md'


const AddACardForm = () => {
  const {t} = useTranslation()
    let cardDetailItem: CardDetailsItem = {
        cardNo: "",
        cvv: "",
        expiration: "",
        address: "",
        suiteNo: "",
        city: "",
        state: "", 
        code: ""
    }

    const [country, setCountry] = useState("Nigeria")


    const countryItemViews = [{id: "", name: ""}].map((item, idx)=> 
                                <option key={item.id}>
                                  {item.name}
                                </option>)
    

    return (
        <>
          <Formik 
             initialValues={cardDetailItem} 
             initialStatus={{isValidating: false}}
             onSubmit={(values, {setSubmitting, setStatus}) => {
                 console.log("is submitting")
                  setTimeout(()=>{                    
                 }, 2000)
               
             }}
             >
                {(props)=> {

                    return (
                      <Form>
                        
                        <AddASpaceLayout>
                          <VStack w="full" alignItems="flex-start">
                            <Text className="subtitle2">
                              {t('Card Details')}
                              {/* Card Details */}
                              </Text>
                            <Field name="cardNo">
                              {({ field, form }: any) => (
                                <FormControl isRequired>
                                  <FormLabel
                                    className="caption"
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="cardNo"
                                  >
                                    {t('Card Number')}
                                    {/* Card Number */}
                                  </FormLabel>
                                  <Input
                                    rounded="lg"
                                    name="cardNo"
                                    type="number"
                                    // value={value}
                                    onChange={props.handleChange}
                                    size="md"
                                  />
                                </FormControl>
                              )}
                            </Field>
                            <HStack w="full">
                              <Field name="expiration">
                                {({ field, form }: any) => (
                                  <FormControl isRequired>
                                    <FormLabel
                                      className="caption"
                                      fontWeight={500}
                                      fontSize={"15px"}
                                      htmlFor="expiration"
                                    >
                                      {t('Expiration')}
                                      {/* Expiration */}
                                    </FormLabel>
                                    <Input
                                      rounded="lg"
                                      name="expiration"
                                      type="number"
                                      // value={value}
                                      onChange={props.handleChange}
                                      size="md"
                                    />
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="cvv">
                                {({ field, form }: any) => (
                                  <FormControl isRequired>
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                      htmlFor="cvv"
                                      className="caption"
                                    >
                                      CVV
                                    </FormLabel>
                                    <Input
                                      rounded="lg"
                                      name="cvv"
                                      type="number"
                                      // value={value}
                                      onChange={props.handleChange}
                                      size="md"
                                    />
                                  </FormControl>
                                )}
                              </Field>
                            </HStack>
                          </VStack>
                          <VStack w="full" alignItems="flex-start">
                              <Text className="subtitle2">
                                {t('Billing Address')}
                                {/* Billing Address */}
                                </Text>
                              <Field name="address">
                              {({ field, form }: any) => (
                                <FormControl isRequired>
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="address"
                                    className="caption"
                                  >
                                    {t('Street Address')}
                                    {/* Street Address */}
                                  </FormLabel>
                                  <Input
                                    rounded="lg"
                                    name="address"
                                    type="text"
                                    // value={value}
                                    onChange={props.handleChange}
                                    size="md"
                                  />
                                </FormControl>
                              )}
                            </Field>
                            <Field name="suiteNo">
                              {({ field, form }: any) => (
                                <FormControl isRequired>
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="suiteNo"
                                    className="caption"
                                  >
                                    Apt No / Suite No
                                  </FormLabel>
                                  <Input
                                    rounded="lg"
                                    name="suiteNo"
                                    type="text"
                                    // value={value}
                                    onChange={props.handleChange}
                                    size="md"
                                  />
                                </FormControl>
                              )}
                            </Field>
                            
                            <Field name="city">
                              {({ field, form }: any) => (
                                <FormControl isRequired>
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="city"
                                    className="caption"
                                  >
                                    {t('City')}
                                    {/* City */}
                                  </FormLabel>
                                  <Input
                                    rounded="lg"
                                    name="city"
                                    type="text"
                                    // value={value}
                                    onChange={props.handleChange}
                                    size="md"
                                  />
                                </FormControl>
                              )}
                            </Field>
                            <HStack w="full">
                                <Field name="state">
                                {({ field, form }: any) => (
                                    <FormControl isRequired>
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize={"15px"}
                                        htmlFor="state"
                                        className="caption"
                                    >
                                      {t('State')}
                                        {/* State */}
                                    </FormLabel>
                                    <Input
                                        rounded="lg"
                                        name="state"
                                        type="text"
                                        // value={value}
                                        onChange={props.handleChange}
                                        size="md"
                                    />
                                    </FormControl>
                                )}
                                </Field>
                                <Field name="code">
                                {({ field, form }: any) => (
                                    <FormControl isRequired>
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize={"15px"}
                                        htmlFor="code"
                                        className="caption"
                                    >
                                      {t('Zip Code')}
                                        {/* Zip Code */}
                                    </FormLabel>
                                    <Input
                                        rounded="lg"
                                        name="code"
                                        type="text"
                                        // value={value}
                                        onChange={props.handleChange}
                                        size="md"
                                    />
                                    </FormControl>
                                )}
                                </Field>
                            </HStack>
                          </VStack>
                          <FormControl className="caption" isRequired id="country">
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                  {t('Country')}
                                {/* Country */}
                                </FormLabel>
                                <InputGroup>
                                <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdFlag color="#fff" />}
                                />
                                <Select
                                    title='country'
                                    id="country"
                                    name="country"
                                    //value={country}
                                    onChange={props.handleChange}
                                    placeholder=""
                                >
                                    {countryItemViews}
                                </Select>
                                </InputGroup>
                            </FormControl>
                        </AddASpaceLayout>
                      </Form>
                    );
                }}
            </Formik>   
        </>
    )
}

export default AddACardForm

import { useMediaQuery, Text, VStack, HStack, Spacer, Button, Link} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import AddASpaceLayout from '../../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'
import { IntroProps } from '../Models/Interfaces'
import { NavLink as ReactLink  } from "react-router-dom";
import { useTranslation } from 'react-i18next'; 

const Intro = ({dataManager, updateFormSubmissionStatus, actionAfterSubmit,onLoad}: IntroProps) => {
    
    //dataManager.setSelectedSectionId(OnBoardSectionId.Intro)
    const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])
    let questionFontSize =  "h5"
    if (isMediumScrn) {
        questionFontSize = "h4"
    } else if(isLargeScrn) {
        questionFontSize = "h3"
    }

    const {t} = useTranslation()

   

    useEffect(() => {
       // onLoad()
    })

    return (
      <>
        <VStack
          //
          pb={8}
          bg={"primary.500"}
          w={"full"}
          minH={{ base: "50%", lg: "100%" }}
          justifyContent={{ base: "flex-end", md: "center" }}
          alignItems={{ md: "flex-start" }}
        >
          <ReactPlayer
            muted
            loop
            playing
            width="100%"
            controls
            height="100%"
           // url={"/videos/office_tour_resized.mp4"}
            url={""}

          />
        </VStack>

        <VStack
          mt={{ base: "-64px !important", lg: "-80px !important" }}
          justifyContent="center"
          alignItems="center"
          bg="white"
          position="relative"
          w="full"
          h={{ base: "50%", md: "100%" }}
          borderTopRightRadius={{ base: 12, md: "unset" }}
          borderTopLeftRadius={{ base: 12, md: "unset" }}
        >
            
          <AddASpaceLayout>
            <Text
              w={{ base: "full", md: "75%", lg: "80%" }}
              className={questionFontSize}
            >
              {t("intro")}
            </Text>
            <Text
              mt={{ base: "16px  !important", md: "24px  !important" }}
              w={{ base: "full", md: "75%", lg: "80%" }}
              className="subtitle1"
            >
              {t("introSub")}
            </Text>
          </AddASpaceLayout>
        </VStack>
      </>
    );
}

export default Intro

import { Button, Divider, HStack, Stack, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import React from 'react'
import { MdKitchen } from 'react-icons/md';
import { AmenityItemView } from '../AllAmenities/AllAmenities';
import { AmenitiesViewProps, AmenityItem, ModalCategory } from '../Models/Interfaces'
import { useTranslation  } from "react-i18next";



const Amenities = ({handleModalOpen, amenities}: AmenitiesViewProps) => {
    
    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 905px) and (max-width: 1439px)",
        "(min-width: 1440px)"
      ]);
      
    const numAmentiesDisplay = medium || large || xlarge ? 8 : 3
    const btnWidth = medium || large || xlarge ? "30%" : "100%"
    const {t} = useTranslation()

    let someAmenityItemView = amenities.slice(0,numAmentiesDisplay).map((item, idx)=>(
            <AmenityItemView key={idx} {...item} />
    ))
    
    // const selectedAmenities = [
    //     {
            
    //     },
    //     {
    //         categoryId: "Amenities Category",
    //         name: "kitchen",
    //         title: "kitchen",
    //         id: "person"
    //     },
    //     {
    //         categoryId: "Amenities Category",
    //         name: "kitchen",
    //         title: "kitchen",
    //         id: "person"
    //     },
    //     {
    //         categoryId: "Amenities Category",
    //         name: "kitchen",
    //         title: "kitchen",
    //         id: "person"
    //     }
    // ]

    return (
        <VStack alignItems="flex-start" w={{base: "full", md: "100%", lg: "100%", xl: "100%"}}   >
            <Text className="h6">
                {t("The Workspace of this office offers")}
                {/* The Workspace of this office offers */}
                 {amenities.length} 
                 {t("Amenities")}
                 {/* Amenities */}
                 </Text>
             <Stack w="full" direction={{base: "row", md: "row", lg: "row"}}>
                 {someAmenityItemView}
             </Stack>
             {
                 amenities.length > numAmentiesDisplay ? (
                    <Button 
                    onClick={()=> {handleModalOpen(ModalCategory.AMENITIES)}}
                    alignSelf="left"
                    variant="outline"
                    colorScheme="secondary"
                    w={btnWidth}
                    className="button">
                        {t("show all")}
                    {/* show all  */}
                  </Button>
                 ):null
             }
           
            {/* <Divider /> */}
         </VStack>
    )
}

export default Amenities

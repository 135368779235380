import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react'
import { CurrencyConverter, ResultType } from '../../Models/Interfaces'
import { WorkhopperPublicAPIManager } from '../../Models/WorkhopperPublicAPIManager'

const defaultCurrencyConverter: CurrencyConverter = {
    base: {
        title: "United States Dollar",
        code: "USD",
        symbol: "$"
    },
    target: {
        title: "United States Dollar",
        code: "USD",
        symbol: "$"
    },
    rate: 1,
    currencies: [{
        title: "United States Dollar",
        code: "USD",
        symbol: "$"
    },
    {
        title: "Nigeria Naira",
        code: "NGN",
        symbol: "₦"
    }
    ]
  }

export const CurrencyConverterContext  = React.createContext<CurrencyConverter>(defaultCurrencyConverter)
export const SetCurrencyConverterContext = React.createContext<Dispatch<SetStateAction<CurrencyConverter>>>(()=>{})

interface CurrencyConverterProviderProps {
    children: ReactNode
}

const CurrencyConverterProvider = ({children}: CurrencyConverterProviderProps) => {

    const [currencyConverter, setCurrencyConverter] = useState<CurrencyConverter>(defaultCurrencyConverter)

     
     useEffect(() => {

         
          
         return () => {
             
         }
     }, [currencyConverter.target])


     return (
        <CurrencyConverterContext.Provider value={currencyConverter}>
                   <SetCurrencyConverterContext.Provider value={setCurrencyConverter}>
                     {children}
                   </SetCurrencyConverterContext.Provider>
                </CurrencyConverterContext.Provider>
    )
}

export default CurrencyConverterProvider

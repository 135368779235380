import {
  useDisclosure,
  VStack,
  Text,
  Center,
  Button,
  ModalOverlay,
  Modal,
   
  ModalHeader,
  ModalContent,
  Divider,
  ModalCloseButton,
  ModalBody,
   
  ModalFooter,
  HStack,
  Spacer,
  useMediaQuery,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import format from "date-fns/format";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Bookable, BookingData, Cart, CartActionType, CartItem, ListingCategory, Result, ResultType } from "../../../../Models/Interfaces";
import { ListingLink  } from "../../../SupportingViews/Links/Links";

import { WorkhopperPublicAPIManager } from "../../../../Models/WorkhopperPublicAPIManager";
import routes from "../../../../routes";
import { getItemTotalPrice, getPrice, getQueryItems, isNumericInput } from "../../../../utils/utils";
import { CartContext, CategoriesContext, SetAlertContext, SetErrorContext, SetLoginModalContext, SetSuccessContext, SetUserContext, UserContext } from "../../../AppProvider/AppProvider";
import CartItemView from "../../../SupportingViews/CartItemView/CartItemView";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
 import AddACardForm from "../OfficeDetail/AddACardForm";
import { signUpDataManager } from "../SignUp/SignUpDataManager";
import PayWithView from "../ConfirmPay/PayWithView";
import { Queries } from "../SearchResuts/SearchResults";
import {PaystackButton} from 'react-paystack'
import WHPaystackButton from "../../../SupportingViews/WHPaystackButton/WHPaystackButton";
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager";
import { BookedItem } from "../../WHDashboard/WHManageRequests/Models/Booking";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import OfflineView from "../../../SupportingViews/OfflineView";
import WHSEO from "../../../SupportingViews/WHSEO/WHSEO";
import WHPrice from "../../../SupportingViews/WHPrice/WHPrice";
import { CurrencyConverterContext } from "../../../AppProvider/CurrencyConverterProvider";
import { ScrollToTop } from "../../../SupportingViews/ScrollToTop/ScrollToTop";
import { useTranslation  } from "react-i18next";

const getCartTotalPrice = (cartItems: CartItem[]): number => {
    
    // const total = cart.items.reduce((value, curr)=>{
    //     return value + getItemTotalPrice(curr)
    // }, 0)

    const total = cartItems.filter((item) => item.bookingDetail.startDate).reduce((value, curr)=>{
      return value + getItemTotalPrice(curr)
  }, 0)
    
    return total
} 

// const itemBooked = (item: Listing): boolean => (

// )

const getCheckoutLink = (id: number | undefined): string=>{

  return id ? `/${routes.payBooking.name}/${id}` : ""
}

const OfficesCart = () => {
  const { state, dispatch } = useContext(CartContext);
  const user = useContext(UserContext)
  const setUser = useContext(SetUserContext)
  const [isLoading, setIsLoading] = useState(false)
  const setSuccessContext = useContext(SetSuccessContext)
  const setErrorContext = useContext(SetErrorContext)
  const history = useHistory()
  const setAlertContext = useContext(SetAlertContext)
  const [isOpen, setIsOpen] = useState(false)

  const officeCategories = useContext(CategoriesContext)

  const [categories, setCategories] = useState<ListingCategory[]>([])
  const [allOfficesURL, setAllOfficesURL] = useState("")
  // const [config, setConfig] = useState<{reference: string, email: string, amount: number, publicKey: string} | null>(null)
  const [total, setTotal] = useState<number>(0)
  const [hasBookedOffice, setHasBookedOffice] = useState(false)
  const [bookedItems, setBookedItems] = useState<BookedItem[]>([])
  //const [isLoadingBookedItems, setIsLoadingBookedItems] = useState(()=> bookedItems.some((bookedItem, idx)=> state.items.find((item)=> item.id)))
  const [isLoadingBookedItems, setIsLoadingBookedItems] = useState(false)

  const [error, setError] = useState<Error | undefined>()
  const [bookingData, setBookingData] = useState<BookingData | undefined>()
  const [paymentData, setPaymentData] = useState<any | undefined>()
  const [bookableItems, setBookableItems] = useState<CartItem[]>([])
  const setShowLoginModal = useContext(SetLoginModalContext)
  const currencyConverter = useContext(CurrencyConverterContext)
  const {t} = useTranslation()

   const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();


  //console.dir(state.items)


  const loadBookings = useCallback(async ()=>{

    if (!user?.access_token) {return}


    setIsLoadingBookedItems(true)

     let result = await WorkhopperAPIManager.shared.getBookings(user.access_token, undefined)
   
    if (result.type === ResultType.Success) {

        // const value = result.value.map((booking, idx)=>{
        //     return booking.items.map(item => (
        //       item
               
        //     ))
        // })

        const value = result.value.results.map((booking, idx)=>{
          return booking.items.map(item => (
            item
             
          ))
      })
      
          setBookedItems(value.flat())
        // setError(null)
     
    } else {
       // setError(result.error)
    }

    setIsLoadingBookedItems(false)
    

}, [isLoading])

  
   useEffect(()=>{
     // loadBookings()
   }, [])

   useEffect(()=> {
        let result = signUpDataManager.getRegisteredUserData()
        if (result) {
           setUser(result)
        }
      }, [])

     

      useEffect(()=>{
          if (!paymentData) {return}

          let total = Number(paymentData.amount)
          if (isNaN(total)) {return}
          setTotal(total)
      }, [paymentData])

      useEffect(() => {
         
        let queryItemsObj: Queries = {category: []}
        let temp = ""
        if (officeCategories.length > 0) {
          queryItemsObj.category = officeCategories.map(item => item.id)
         // WorkhopperAPIManager.
          temp = WorkhopperPublicAPIManager.shared.appendQueryitems("/search", getQueryItems(queryItemsObj))  
          
          setAllOfficesURL(temp)
         // const categories = appDataManager.getCategoriesData("list-cat").reverse()
          setCategories(officeCategories)
        }
       
        return () => {
          
        }
      }, [officeCategories])

      useEffect(()=>{
          
          
          const items = state.items.filter(item => item.bookingDetail.startDate)
          setBookableItems(state.items.filter(item => item.bookingDetail.startDate))
 
      }, [state.items])


      useEffect(() => {
        setAlertContext({title: "Booking Successful",
        msg: "Congrats! Your booking was successful",
        leftButton: {label: "Signup", action: ()=>{
          //console.log("closing")
          setIsOpen(false)
          history.push(`/${routes.signup.name}`)
          
        }},
        rightButton: {label: "LogIn", action: ()=>{
          setIsOpen(false)
          history.push(`/${routes.login.name}`)
        }
        },
        isOpen: isOpen, 
        onClose: ()=>{
          setIsOpen(false)
        }
        
      
      })
        return () => {
          
        }
      }, [isOpen])

      const postBookingPayment = useCallback(
        async() => {
         
          if (!user || !user.access_token || !bookingData || !bookingData.id || !bookingData.amount) {return}
         
          let result =  await WorkhopperAPIManager.shared.saveBookingPayment({
            payment_mode: bookingData.payment_mode,
            booking: bookingData.id, //bookingDataRef.current.,
            amount: Number(bookingData.amount)
          }, 
            user.access_token
            )

            if (result.type === ResultType.Success) {
              setPaymentData(result.value)
            } else {

            }
        },
        [],
      )

      
      
     

  const postBookables = useCallback(async () => {
    
     //let date = format(new Date(), "yyyy-MM-dd")
     
     if (!user || !user.access_token) {
       setShowLoginModal(true)
     // setErrorContext({title: "", msg: "Please login to complete your booking"})

        //   setAlertContext({title: "Complete Your Booking",
        //   msg: "Please login to complete your booking. If you don't have an account, signup!",
        //   leftButton: {label: "Signup", action: ()=>{
        //     //console.log("closing")
        //     setIsOpen(false)
        //     history.push(`/${routes.signup.name}`)
            
        //   }},
        //   rightButton: {label: "LogIn", action: ()=>{
        //     setIsOpen(false)
        //     history.push(`/${routes.login.name}`)
        //   }
        //   },
        //   isOpen: isOpen, 
        //   onClose: ()=>{
        //     setIsOpen(false)
        //   }
          
        
        // })
        return
     }
      
     //console.dir(currencyConverter)
     
    let details = bookableItems.map(item=> {
            let bookable: Bookable
            if (item.bookingDetail.durationType.toLowerCase() === "daily") {
                bookable = {
              
                start_date: format(new Date(item.bookingDetail.startDate!), "yyyy-MM-dd"),//format(item.bookingDetail.startDate!, "yyyy-MM-dd'T'HH:mm:ss"),
                end_date: format(new Date(item.bookingDetail.endDate!), "yyyy-MM-dd"),//format(item.bookingDetail.endDate!, "yyyy-MM-dd'T'HH:mm:ss"), //format(new Date(item.bookingDetail.endDate!), "yyyy-MM-dd"),
                
               duration: item.bookingDetail.duration,
               duration_type: item.bookingDetail.durationType.toLowerCase() === "daily" ? "day" : item.bookingDetail.durationType.toLowerCase(),
               id: item.id
             }
            } else {
              bookable = {
              
               start_date:  format(item.bookingDetail.startDate!, "yyyy-MM-dd'T'HH:mm:ss"),
               // end_date: format(new Date(item.bookingDetail.endDate!), "yyyy-MM-dd"),//format(item.bookingDetail.endDate!, "yyyy-MM-dd'T'HH:mm:ss"), //format(new Date(item.bookingDetail.endDate!), "yyyy-MM-dd"),
                
               duration: item.bookingDetail.duration,
               duration_type: item.bookingDetail.durationType.toLowerCase() === "daily" ? "day" : item.bookingDetail.durationType.toLowerCase(),
               id: item.id
             }
            }
          
            

            return bookable
          })
         // debugger
          setIsLoading(true)
          let result = await WorkhopperPublicAPIManager.shared.postBookables({
            total_items: details.length, //state.items.length,
           items: details,
           payment_mode: "card",
           user: Number(user.id),
           currency: currencyConverter.target.code

           
         }, user.access_token)
      
         if (result.type === ResultType.Failure) {
           setErrorContext({title: "", msg: result.error.message})
           setIsLoading(false)
           return
         }

         
         if(result.value.amount && !isNumericInput(result.value.amount)) {
          setErrorContext({title: "", msg: "Something went wrong processing your booking"})
          setIsLoading(false)
          return
         }

         /*
         let result1 =  await WorkhopperAPIManager.shared.saveBookingPayment({
          payment_mode: result.value.payment_mode,
          booking: result.value.id,  
          amount: Math.round(Number(result.value.amount) * currencyConverter.rate),   
          currency: currencyConverter.target.code
        }, 
          user.access_token
          )

          if (result1.type === ResultType.Failure) {
            setErrorContext({title: "", msg: result1.error.message})
            setIsLoading(false)
            return
          }*/
            //  debugger
              setBookingData(result.value)
              //setPaymentData(result1.value)
 
              dispatch({type: CartActionType.CLEAR, payload: {}})
              //setIsOpen(true)
                
              //setSuccessContext({title: "", msg: "Congrats! Your booking was successful"})
              
              setHasBookedOffice(true)
        
         setIsLoading(false)
        //   console.dir(temp)
        },
    [state, user, isOpen, bookableItems, currencyConverter.rate]
  )
 
  
  const [xsmall, small, medium, lg, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);


 //console.dir(bookingData?.id)

   

  let modalSize = xsmall || small ? "full" : "lg";

  return isLoadingBookedItems ? (
    <WHSpinner w="100%" pt="64px" />
  ): error ? (
    <OfflineView 
      onRetry={loadBookings}
      msg={error.message}
      pt="64px"
    />
  ): state.items.length > 0 ? (
    <>
     <WHSEO 
            title={ `Complete Booking`} 
            meta={[
              {
                name: "description",
                content: `Complete Booking`
              },
              {
                name: "keywords",
                content: `booking, workHopper`
              }
            ]}  
          />
    <LayoutView> 
     <VStack w="full" paddingY={8} alignItems="flex-start">
      <Text className="h6" fontSize="34px">
      {t("Complete Your Booking")}
      </Text>
      
      {state.items.map((item) => (
        <CartItemView key={item.id} item={item} />
      ))}
        <Divider />
         
         <VStack 
         alignItems="flex-start">
             <Text className="h6" fontSize={medium || lg || xl ? "unset" : "18px !important"}>Total</Text>
             <HStack>
                 <Text className="body1">
                 {t("Number of Offices")}
                   {/* Number of Offices */}
                   </Text>
                 <Spacer/>
                 <Text className="body1">{bookableItems.length}</Text>
             </HStack>
             <HStack>
                 <Text className="body1">
                 {t("Price")}
                   {/* Price */}
                   </Text>
                 <Spacer/>
                 <Text className="body1">
                 <WHPrice priceText={getCartTotalPrice(bookableItems).toString()}  />
                   {/* $ {getCartTotalPrice(bookableItems).toFixed(2)} */}
                   </Text>
             </HStack>
          </VStack>
          <Divider />
        <VStack 
         h={"240px"}
          margin={medium || lg || xl || small ? "0 auto" : "unset"}
          minW={medium || lg || xl || small ? "40%" : "full"}>
           <Button
                disabled={getCartTotalPrice(bookableItems) === 0}
                isLoading={isLoading}
                onClick={()=>{
                 // setIsOpen(true)
                
                  postBookables()
                   
                 
                }}
                w="full"
                className="button"
                colorScheme="primary"
                 >
                   {t("Book and Pay on Arrival")}
                {/* Book and Pay on Arrival */}
                </Button>

            <Center w="full" justifyContent={medium || lg || xl ? "start" : "center"}>
               {
                  <ListingLink  to={getCheckoutLink(bookingData?.id)}>
                   <Button disabled={bookingData?.id ? false : true} className="button" 
                          variant="outline"
                          colorScheme="primary" w="160px">
                             {t("Checkout")}
                            {/* Checkout */}
                            </Button>
               </ListingLink>
                
               }
                
                
                <Spacer />
                <Text className="body2">
                {t("OR")}
                  {/* OR */}
                  </Text>
                <Spacer />

                <ListingLink to={allOfficesURL}>
                    <Button className="button" colorScheme="primary" w="160px">
                    {t("Continue Browsing")}
                      {/* Continue Browsing */}
                      </Button>
                </ListingLink>
            </Center>
        </VStack>
       
      
    </VStack>
    </LayoutView>
    </>
  // ): bookableItems.length === 0 &&  bookingData?.id ? (
    ): bookingData?.id ? (
      <LayoutView> 
     <VStack w="full" h="100vh">
     <ScrollToTop />
        <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
            {t("Booking Successfull!")}
              {/* Booking Successfull! */}
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
            {t("Thanks for completing")}
              {/* Thanks for completing your booking. You will find your booked offices in your booking history. Checkout or continue browsing. */}
            </AlertDescription>
          </Alert>
          <Center w={medium || lg || xl || small ? "30%" : "full"}
                   justifyContent={medium || lg || xl ? "space-between" : "space-between"}>
               
                  <ListingLink  to={getCheckoutLink(bookingData?.id)}>
                   <Button className="button" 
                          w="160px"
                          variant="outline"
                          colorScheme="primary">
                            {t("Checkout")}
                            {/* Checkout */}
                            </Button>
               </ListingLink>
                
                {/* <Spacer /> */}
                <Text className="body2">
                {t("OR")}
                  {/* OR */}
                  </Text>
                {/* <Spacer /> */}

                <ListingLink to={allOfficesURL}>
                    <Button className="button" colorScheme="primary"
                          w="160px"
                          >
                             {t("Continue Browsing")}
                            {/* Continue Browsing */}
                            </Button>
                </ListingLink>
            </Center>
     </VStack>
     </LayoutView>
  ) : (
    <LayoutView> 
    <VStack w="full" h="50vh" alignItems="center" justifyContent="center">
        <Text className="h6">
        {t("You have no offices to book")}
          {/* You have no offices to book */}
          </Text>
        <Text className="h6" textAlign="center" fontSize="18px !important">
        {t("Add offices to cart")}
          {/* Add offices to cart for booking or check your booking history for your booked offices */}
          </Text>
    </VStack>
    </LayoutView>
  )
};

export default OfficesCart;

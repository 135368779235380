import { Box, Button, ButtonGroup, Circle, Flex, GridItem, HStack, Link, Spacer, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import React from 'react'
import { MdCheck, MdChevronRight } from 'react-icons/md'
import { DashboardSectionName } from '../Models/Interfaces'
import { dashboardManager } from '../Models/WHDashboardDataManager'
import { NavLink as ReactLink} from 'react-router-dom'

import styles from './WHWorkspaceDetail.module.css'
import WHLayoutGrid from '../../../SupportingViews/WHLayoutGrid/WHLayoutGrid'

const mobileNavItem = (title: string)=> {
       
    return (
     
       <VStack
          paddingY={2}
           paddingX={6}
             justifyContent="center"
             w="inherit" 
             h="inherit"
            >
           
            <HStack w="inherit">
               <Text color="black"  fontWeight="bold" className="subtitle1">{title}</Text>
                <Spacer/>
               <MdChevronRight fontSize="36px" />
            </HStack>
             
       </VStack>
        
     )
  }

const MasterNavlistMobile = () => {
    
    
    const colspan = useBreakpointValue({base: 4, sm: 8, md: 8, lg: 6, xl: 6})

    let userId = "me"

    const masterNavlist = dashboardManager.allSections.map((item, idx)=> (
        <GridItem key={item.name} colSpan={colspan} rowSpan={3}>
            
                <Link
                display="block"
                textDecoration="none !important" 
                border="2px solid #262949"
                 
                as={ReactLink}
                //activeClassName={styles.overlay}
                h="full"
                w="full"
                rounded="xl"
                justifyContent="center"
                color="black"
                position="relative"
                to={dashboardManager.sectionLink(item.name, "me")}
               >
                {mobileNavItem(item.title)}
              </Link>
         </GridItem>
      ))
      
    return (
             
            <Box position="relative">
              <Flex
               
                zIndex="50"
                position="fixed"
            // className="navbar-lg"
                bg="white"
                w="full"
                mt="-80px"
                paddingX={4}
                paddingY={4}
                //justifyContent="space-between"
                alignItems="center"
                h={{ base: "56px", md: "56px", lg: "64px" }}
                color="black"
            >
                <Text className="h6" mr="auto">Workspace Title</Text>
                <ButtonGroup>
                <Button
                    w={"full"}
                    className="button"
                    colorScheme="primary"
                    variant="solid"
                    disabled
                >
                    Save
                </Button>
                <Button
                    w={"full"}
                    disabled
                    className="button"
                    colorScheme="green"
                    variant="solid"
                >
                    Publish
                </Button>
                </ButtonGroup>
            </Flex>
            <Box mt={16}>
            <WHLayoutGrid>
             <GridItem colSpan={colspan} rowSpan={1}>
              <HStack>
                <Circle border="1px solid green" h={4} w={4} bg="green">
                  <MdCheck color="white" />
                </Circle>
                <Text mb={4} fontSize="20px !important" className="button">
                  Details
                </Text>
              </HStack>
            </GridItem>
            {masterNavlist}
            <GridItem colSpan={colspan} rowSpan={1}>
              <HStack>
                <Circle border="1px solid green" h={4} w={4} bg="green">
                  <MdCheck color="white" />
                </Circle>
                <Text mb={4} fontSize="20px !important" className="button">
                  Management
                </Text>
              </HStack>
            </GridItem>
            <GridItem colSpan={colspan} rowSpan={3}>
              <Link
                textDecoration="none !important"
                as={ReactLink}
                border="2px solid #262949"
                display="block"
                activeClassName={styles.overlay}
                h="full"
                w="full"
                rounded="xl"
                justifyContent="center"
                color="black"
                position="relative"
                to={dashboardManager.sectionLink(
                  DashboardSectionName.Offices,
                  userId
                )}
              >
                {mobileNavItem("Offices/Desks")}
              </Link>
            </GridItem>   
        </WHLayoutGrid>
        </Box>
        </Box>
    )
}

export default MasterNavlistMobile

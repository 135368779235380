import {Stack, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, HStack, Spacer, useMediaQuery, VStack, IconButton } from '@chakra-ui/react'
import { t } from 'i18next'
import React, { useCallback, useContext } from 'react'
import { MdDelete } from 'react-icons/md'
import { CartActionType, CartItem } from '../../../Models/Interfaces'
import { CartContext } from '../../AppProvider/AppProvider'
//import { CartContext } from '../../AppProvider/WHAppProvider'
import BookingFormView from '../../Screens/WHApp/ConfirmPay/BookingFormView'
import PriceDetailsView from '../../Screens/WHApp/ConfirmPay/PriceDetailsView'
import { OfficeDetailLink } from '../Links/Links'
import OfficeItemView from '../OfficeItemView/OfficeItemView'
import WHBookingFormView from '../WHBookingFormView/WHBookingFormView'

interface CartItemViewProps {
    item: CartItem
   
}

interface MobileViewProps extends CartItemViewProps {
    onDelete: ()=>void
}

interface DesktopViewProps extends CartItemViewProps {
    onDelete: ()=>void
}

const MobileView = ({item, onDelete}: MobileViewProps)=>{

    return item.listing ? (
        <VStack w="full" shadow="md" spacing="0px">
        <VStack position="relative" w="full" spacing={4} paddingX={4} paddingTop={4}>
        <OfficeDetailLink to={`/office/${item.id}/detail`}>
            <Box w="full"  h="176px">
            <OfficeItemView removeShadow={true} listing={item.listing} />
        </Box>
       </OfficeDetailLink>
       <PriceDetailsView selected={item} />
       <IconButton variant="ghost" aria-label="delete office" 
         onClick={onDelete}
        colorScheme="secondary"
        position="absolute"
        top="0px"
        right="16px"
        size="lg"
       icon={<MdDelete />} />
       </VStack>
       
               <Accordion allowToggle w="full">
               <AccordionItem>
                   <AccordionButton>
                   <Button colorScheme="whatsapp" paddingX="0px" variant="ghost">{t("Edit")}</Button>
                   <Spacer />
                   {/* <HStack w="full" paddingX={4} paddingBottom={2}>
                       <Button colorScheme="whatsapp" variant="ghost">Edit</Button>
                        <Spacer/>
                        </HStack> */}
                    <AccordionIcon />
                   </AccordionButton>
                   <AccordionPanel>
                       <WHBookingFormView item={item} />
                   </AccordionPanel>
               </AccordionItem>
           </Accordion>
      
   </VStack>
    ):null

}

const DesktopView = ({item, onDelete}: DesktopViewProps) => {
    return item.listing ? (
        <HStack w="100%" padding={6} alignItems="flex-start" shadow="md" spacing={14}>
              {/* <Box w="50%"> */}
              <WHBookingFormView item={item} /> 
                {/* </Box> */}
            
             {/* <Spacer w="5%" /> */}
             <VStack w="50%" spacing={4} h="60%">
             <OfficeDetailLink to={`/office/${item.id}/detail`}>
            <Box w="full" h="176px">
                <OfficeItemView removeShadow={true} listing={item.listing} />
            </Box>
            </OfficeDetailLink>
            <PriceDetailsView selected={item} />
            </VStack>
            {/* <Spacer /> */}
            <Button variant="outline" onClick={onDelete} colorScheme="secondary">Remove</Button>          
        </HStack>
    ):null
}

const CartItemView = ({item}: CartItemViewProps) => {

    const [xsmall, small, medium, lg, xl] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 1240px) and (max-width: 1439px)",
        "(min-width: 1440px)",
      ]);

      const {dispatch} = useContext(CartContext)

      const onDeleteItem = useCallback(async () => {
              dispatch({type: CartActionType.REMOVE, payload: item.id})
          },
          [item],
      )
      
      const isSmall = xsmall || small 
    return isSmall ? <MobileView item={item} onDelete={onDeleteItem} /> : <DesktopView onDelete={onDeleteItem} item={item} />
     
}

export default CartItemView

import { VStack, Text, HStack, Spacer, Divider } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { CartItem, DurationCategory } from '../../../../Models/Interfaces'
import { getDurationLabel } from './ConfirmPay'
import { format } from 'date-fns'
import add from 'date-fns/add'
import { getEnddate } from './BookingFormView'
import { getDurationTitle, getItemTotalPrice, getPrice } from '../../../../utils/utils'
import WHPrice from '../../../SupportingViews/WHPrice/WHPrice'
import { useTranslation  } from "react-i18next";



interface PriceDetailsViewProps {
    selected: CartItem
}

const PriceDetailsView = ({selected}: PriceDetailsViewProps) => {
    const {t} = useTranslation()
    const [total, setTotal] = useState<number>(0)
    const formatToken = selected.bookingDetail.durationType === DurationCategory.HOUR ? "PPpp" : "PP"
     /*
    const {state} = useContext(CartContext)
    const [selected, setSelected] = useState<CartItem | undefined>()
    const [total, setTotal] = useState<number>(0)
     useEffect(() => {

        let found = state.items.find(item => item.id === state.selectedId)
        setSelected(found)


        
        return () => {
            
        }
    }, [state])*/

    useEffect(() => {
        let total = 0
        if (selected) {
          total =  getItemTotalPrice(selected) 
        }
        setTotal(total)
         
    }, [selected])

   
    return selected ? (
        <>
         <VStack alignItems="flex-start" w="full">
            <Text className="h6" fontSize="16px !important">
                {t('Price Details')}
                {/* Price Details */}
                </Text>
            {/* <VStack alignItems="flex-start" w="inherit">
                <Text className="body1">Guests</Text>
                <Text className="body2">{selected.bookingDetail.guests}</Text>
                <Divider />
            </VStack> */}
            <VStack alignItems="flex-start" w="inherit">
                <Text className="body1">
                    {t('Check-in Date')}
                    {/* Check-in Date - Check-out Date */}
                    </Text>
                <HStack>
                   {
                       selected.bookingDetail.startDate && selected.bookingDetail.endDate ? (
                           <>
                             <Text className="body2">{format((selected.bookingDetail.startDate ?? new Date()), formatToken)}</Text>
                             <Text className="body2"> - </Text>
                             <Text className="body2">{format((selected.bookingDetail.endDate ?? new Date()), formatToken)}</Text>

                                  {/* <Text className="body2">{format(getEnddate(selected.bookingDetail.durationType, selected.bookingDetail.startDate ?? new Date(), selected.bookingDetail.duration), "PP")}</Text> */}
                           </>
                       ) : (
                        <Text className="body2"> - </Text>
                       )
                   }
                  
                </HStack>
                <Divider />
            </VStack>
            <VStack alignItems="flex-start" w="inherit">
                <Text className="body1" >
                    {t('Price To Pay')}
                    {/* Price To Pay */}
                    </Text>
                <HStack  w="inherit">
                 {
                     selected.bookingDetail.startDate ? (
                         <>
                        {/* <Text className="body2">$ {getPrice(selected)} / {getDurationTitle(selected.bookingDetail.durationType)} x  {selected.bookingDetail.duration} {getDurationLabel(selected.bookingDetail.durationType)}</Text> */}
                        <Text className="body2"><WHPrice priceText={getPrice(selected).toString()}  /> / {getDurationTitle(selected.bookingDetail.durationType)} x  {selected.bookingDetail.duration} {getDurationLabel(selected.bookingDetail.durationType)}</Text>

                        <Spacer />
                      <Text className="body2"><WHPrice priceText={total.toString()}  /></Text>
                      </>
                     ) : (
                        <Text className="body2"> - </Text>
                     )
                 }
               
                </HStack>
                
                <Divider />
            </VStack>
         </VStack>
        </>
    ):null
}

export default PriceDetailsView

import { Box, Button, ButtonGroup, Flex, HStack, Image, Select, Spacer, Text, VStack } from '@chakra-ui/react'
import { Formik, FormikProps } from 'formik';
import { t } from 'i18next';
import React, {FC, ReactNode, useContext, useEffect, useRef, useState} from 'react'
import {  NavLink as ReactLink } from "react-router-dom";
import { CompartmentCategory, ListingCategory, ResultType } from '../../../../Models/Interfaces';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { SetErrorContext, UserContext } from '../../../AppProvider/AppProvider';
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout';
//import { CompartmentCategory } from '../../Models/Interfaces';
import DesksFormView from '../DesksFormView/DesksFormView';
import { AddOfficeProps, DesksForm, MeetingRoomForm, PrivateOfficeForm,  UploadedFile, VirtualOfficeForm } from '../Models/Interfaces';
import { dashboardManager } from '../Models/WHDashboardDataManager';
import VirtualOfficeFormView from '../VirtualOfficeFormView/VirtualOfficeFormView';
import MeetingRoomFormView from './MeetingRoomFormView/MeetingRoomFormView';
import PrivateOfficeFormView from './PrivateOfficeFormView/PrivateOfficeFormView';
 


const AddOffice = ({mode, privateFormRef, setCategories, categories, selectedCategory, setSelectedCategory}: AddOfficeProps) => {

     
    const setErrorContext = useContext(SetErrorContext)
    const [userSelectedCategory, setUserSelectedCategory] = useState<CompartmentCategory | undefined>(selectedCategory)
     
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
    const [uploadedOtherFiles, setUploadedOtherFiles] = useState<UploadedFile[]>(Array(4).fill({ file: new File([], ""), preview: "invalid" }, 0))

    

    useEffect(() => {
        dashboardManager.listingHeader = uploadedFiles[0] 
        dashboardManager.listingOthers = uploadedOtherFiles
        
    }, [uploadedFiles, uploadedOtherFiles])

const officeItemViews =  categories.map((item, idx)=>
<option key={item.id}>
    {t(item.title.replaceAll(' ','').toLowerCase())}
    </option>)
 


    const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedCategory(e.target.value as CompartmentCategory)
        // setUserSelectedCategory(e.target.value as CompartmentCategory)
    }

    useEffect(() => {
        async function loadListingCategories() {
            
           const result = await WorkhopperAPIManager.shared.listingCategories()
           if (result.type === ResultType.Success) {
                 
                 setCategories(result.value)
                 
            } else {
             setErrorContext({title: result.error.name, msg: result.error.message})
          }
         }

        loadListingCategories()
   
      }, [])

    const compartmentView = () => {

        let view: ReactNode = <PrivateOfficeFormView 
                        uploadedFiles={uploadedFiles}
                        uploadedOtherFiles={uploadedOtherFiles}
                        setUploadedFiles={(files: UploadedFile[])=> setUploadedFiles(files)}
                        setUploadedOtherFiles={(files: UploadedFile[])=> setUploadedOtherFiles(files)}
                        formRef={privateFormRef} 
                        manager={dashboardManager}
                        updateFormSubmissionStatus={()=>{}}
                        actionAfterSubmit={()=>{}}
                        selectedCategory={selectedCategory}
                        //selectedCategory={userSelectedCategory}
                    />
       
            
              
         return view
        
    }

     

 
    return (
        <>
            <Box w="full">
                {/* <Large /> */}
                  
                 <VStack
                 
                 mt={"2em"}
                 padding={{ base: "0px 1.5em", md: "0px 1.5em ", lg: "0px 2em" }}
                  w="full" alignItems="flex-start">
                     <Text>{mode === "new" ? t("What do you want to add?") : t("Category")}</Text>
                     <Select value={selectedCategory}  disabled={mode === "new" ? false : true} 
                      onChange={handleCategoryChange} w="full">
                    {officeItemViews}
                </Select>
                 </VStack>
                 {
                     compartmentView()
                 }
                 
            </Box>
        </>
    )
}

export default AddOffice

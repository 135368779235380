import { VStack, Text, HStack, Link, Circle, Box } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink as ReactLink } from "react-router-dom";

import { MdStar } from 'react-icons/md'
import routes from '../../../../../routes';
import { PublicListing, Result, ResultType, WorkspaceDetail } from '../../../../../Models/Interfaces';
import { appDataManager } from '../../Models/WHAppDataManager';
import { WorkhopperAPIManager } from '../../../../../Models/WorkhopperAPIManager';
import { BasicInfo } from '../../BusinessProfile/BusinessProfile';
import { useTranslation  } from "react-i18next";

interface DescriptionProps {
    listing: PublicListing
    // title: string 
    // category: string
    // details: WorkspaceDetail
}

interface SummaryProps {
    listing: PublicListing
}


export const Summary = ({listing}: SummaryProps) => {
    const extraDays = listing.workspace_details.extra_days.days ?? []
    const daysText = extraDays.includes('Sat') ? "saturdays" : ""
    const daysText1 = extraDays.includes('Sun') ? "sundays" : ""
    const {t} = useTranslation()


     return (
         <>
        <VStack alignItems="flex-start">
        <Text className="subtitle1" fontSize="18px !important" fontWeight="bold !important">
          {t("Description")}
          {/* Description */}
          </Text>
            <Text className="body2">
                 {listing.description} 
           </Text>
           
           <Text className="subtitle1" fontSize="18px !important" fontWeight="bold !important">
           {t("Opening Hours (Mondays - Fridays)")}
             {/* Opening Hours (Mondays - Fridays) */}
             </Text>
           <HStack className="body2">
               
               <Text>{listing.workspace_details.available_hours.start_time ??  "Not Available"}</Text> <span>-</span> <Text>{listing.workspace_details.available_hours.end_time ?? "Not Available"}</Text>
            </HStack>
            {
                daysText ? (
                    <>
                    <Text className="subtitle1" fontSize="18px !important" fontWeight="bold !important">
                    {t("Opening Hours")}
                      {/* Opening Hours  */}
                      <Box as="span" textTransform="capitalize">({ `${daysText}` })</Box></Text>
                    <HStack className="body2">
               
                    <Text>{listing.workspace_details.available_hours.sat_start_time ??  "Not Available"}</Text> <span>-</span> <Text>{listing.workspace_details.available_hours.sat_end_time ?? "Not Available"}</Text>
                 </HStack>
                 </>
                ):null
                 
            }
            {
                daysText1 ? (
                    <>
                    <Text className="subtitle1" fontSize="18px !important" fontWeight="bold !important">
                    {t("Opening Hours")}
                      {/* Opening Hours  */}
                      <Box as="span" textTransform="capitalize">({ `${daysText1}` })</Box></Text>
                    <HStack className="body2">
               
                    <Text>{listing.workspace_details.available_hours.sun_start_time ??  "Not Available"}</Text> <span>-</span> <Text>{listing.workspace_details.available_hours.sun_end_time ?? "Not Available"}</Text>
                 </HStack>
                 </>
                ):null
                 
            }
            <Text className="body2">
              <Text className="subtitle1" fontSize="18px !important" fontWeight="bold !important">
              {t("Number of seats available")}
                {/* Number of seats available */}
                </Text>
           </Text>
           <Text className="body2">
                 {listing.capacity} 
           </Text>
        </VStack>
        {/* <VStack alignItems="flex-start">
            <Text className="body2">
              <Text className="subtitle1" fontSize="18px !important" fontWeight="bold !important">Number of seats available</Text>
           </Text>
        </VStack> */}
        </>
        
    )
}

const Description = ({listing}: DescriptionProps) => {

    const [partnerInfo, setPartnerInfo] = useState<BasicInfo>()
    const [error, setError] = useState<Error>()
    const {t} = useTranslation()
    /*
    const getBasicInfo = useCallback(async ()=>{

        const partnerId = listing.workspace_details.partner_id
        
         
        const temp = await WorkhopperAPIManager.shared.fetchPartnerBasicInfo(partnerId.toString())
        const partnerInfoResult = temp as Result<BasicInfo>
        if (partnerInfoResult.type === ResultType.Success) {
           // console.dir(partnerInfoResult.value)
            setPartnerInfo(partnerInfoResult.value)
            setError(undefined)
        } else {
            setError(partnerInfoResult.error)
            setPartnerInfo(undefined)
        } 

    }, [])

    useEffect(()=>{
        //console.dir(listing)
        if (listing.is_claimed) {
            getBasicInfo()
        }
    }, [])*/

    

    return (
      <>
        <VStack spacing={1} alignItems="flex-start">
          {/* <Text className="h6">{listing.title}</Text>
          <Text className="body2">
            {appDataManager.mapOfficeCategory(
              false,
              appDataManager.getCategoriesData("list-cat"),
              listing.category
            )}
          </Text> */}
          {listing.is_claimed ? (
            <>
            <HStack spacing={2}>
              {/* <Text  className="body1">{listing.workspace_details.name}</Text>
              <Text className="subtitle1">by</Text> */}
              <Text  className="h6">{listing.workspace_details.name}</Text>
              <Text className="subtitle1">by</Text>
              <Link
                as={ReactLink}
                textDecorationLine="underline"
                textDecorationColor="primary.500"
                className="overline"
                to={appDataManager.getBusinessProfileURL(
                  listing.workspace_details.partner_id
                )}
              >
                {listing.workspace_details.partner_name}
              </Link>
            </HStack>
             <Text className="body1">{listing.title} 
             {t("Office")}
             {/* Office */}
             </Text>
             <Text className="body2">
               {appDataManager.mapOfficeCategory(
                 false,
                 appDataManager.getCategoriesData("list-cat"),
                 listing.category
               )}
             </Text>
             </>
          ) : null}

          {/* <Text className="overline">Enterprise Hubs</Text> */}
          <HStack flexWrap="wrap" className="subtitle2">
            {/* <MdStar color="secondary.500" /> */}
            <Text>
              {listing.avg_rating > 0 ? (
                <HStack spacing={1}>
                  <MdStar fill={"red"} />
                  <Text>{Number(listing.avg_rating).toFixed(1)}</Text>
                </HStack>
              ) : (
                <HStack spacing={1}>
                  {/* <MdStar fill={"red"} /> */}
                  <Text>
                  {t("No rating yet")}
                    {/* No rating yet */}
                    </Text>
                </HStack>
              )}
            </Text>
            <Circle w="4px" bg="primary.500" h="4px" />
            <Text className="subtitle2">
              {listing.rating?.total_reviews ?? 0} 
              {t("Reviews")}
              {/* Reviews */}
            </Text>
            {/* <Circle w="4px" bg="primary.500" h="4px" /> */}
           
          </HStack>
          <VStack alignItems="flex-start">
              <Text>{listing.workspace_details.street}</Text>
              <HStack>
                <Text>{listing.workspace_details.city},</Text>
                <Text>{listing.workspace_details.state},</Text>
                <Text>{listing.workspace_details.country}.</Text>
              </HStack>
            </VStack>
        </VStack>
      </>
    );
}

export default Description

import { Divider, VStack, Text, FormControl, FormLabel, InputGroup, InputLeftAddon, Select, HStack, Stack, Checkbox, Input, Button, FormErrorMessage } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { ResultType } from '../../../../Models/Interfaces'
import { SetErrorContext, UserContext } from '../../../AppProvider/AppProvider'
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'
import { DashboardSectionName, HopFormViewProps } from '../Models/Interfaces'
import { yupHOPValidation } from '../Models/Schema/DescriptionValSchema'
import { dashboardManager } from '../Models/WHDashboardDataManager'
import { SetWorkspaceContext, WorkspaceContext } from '../WHManageWorkspaces/ManageWorkspacesProvider'

import styles from '../WHWorkspaceDetail/WHWorkspaceDetail.module.css'




const HopFormView = ({formRef, manager, 
         updateFormSubmissionStatus, 
         actionAfterSubmit}: HopFormViewProps) => {

          let hours: string[] = []
          for (let i = 1; i <= 12; ++i) {
            hours.push( `${i.toString()}:00`)
          }
           
          const partner = useContext(UserContext)
          const setErrorContext = useContext(SetErrorContext)
          const setWorkspace = useContext(SetWorkspaceContext)
          const workspace = useContext(WorkspaceContext)  

          const fromItems = hours //["1:00", "2:00", "3:00", "4:00"]
          const toItems = ["1:00", "2:00", "3:00", "4:00"]
          const [formValues, setFormValues] = useState({from: "",  to: "", satOpened: 
          false, satFrom: "", satTo: "", sunOpened: true, sunFrom: "", sunTo: ""})
          const [id, setId] = useState("")
         
            // const fromItemViews = fromItems.map((item, idx)=> 
            // <option key={item}>
            //   {item} A.M
            // </option>)

          const fromItemViews = hours.map((item, idx)=> 
          <option key={item}>
            {`${item} AM`}
          </option>)

            const toItemViews = hours.map((item, idx)=> 
            <option key={item}>
              {`${item} PM`}
            </option>)

           

        useEffect(() => {
         //localStorage.removeItem(DashboardSectionName.HOP)

         
           let s = false 
           let satOpenedTime: string | undefined
           let satClosedTime: string | undefined
          let su = false
           if (workspace) {
             let days = workspace.extra_days?.days
             if (workspace.extra_days?.days) {
                
                s = workspace.extra_days.days[0] ? true : false
                su = workspace.extra_days.days[1] ? true : false
             }
              setFormValues({from: workspace.available_hours?.start_time ?? formValues.from, 
                       to: workspace.available_hours?.end_time ?? formValues.to,
                       satOpened: s,
                       satFrom: workspace.available_hours?.sat_start_time ?? formValues.satFrom,
                       satTo: workspace.available_hours?.sat_end_time ?? formValues.satTo,
                       sunOpened: su,
                       sunFrom: workspace.available_hours?.sun_start_time ?? formValues.sunFrom,
                       sunTo: workspace.available_hours?.sun_end_time ?? formValues.sunTo,
                     })
                     if (workspace.id) {
                      setId(workspace.id)
                    }
            }

            if (workspace &&workspace.id) {
              setId(workspace.id)
            }
          
        }, [])

   //console.dir(workspace)
 
    return (
        <>
          <Formik 
             initialValues={formValues} 
             enableReinitialize={true}
             initialStatus={{isValidating: false}}
             validationSchema={yupHOPValidation}
             validateOnChange={false}
             validateOnBlur={false}
             onSubmit={async (values, {setSubmitting, setStatus}) => {
              if (partner?.access_token) {
                //console.dir(values)
                
                let result = await dashboardManager.updateWorkspaceSection(id, DashboardSectionName.HOP, values, partner.access_token)
                 if (result.type === ResultType.Success) {
                
                     setWorkspace({...workspace, available_hours: result.value.available_hours, extra_days: result.value.extra_days})
                    actionAfterSubmit()
                  } else {
                   setErrorContext({title: result.error.name, msg: result.error.message})
                 }
               }
             }}
             innerRef={formRef}
            >
                {(props) => {
                      return (
                      <Form className={styles.form}>
                        <VStack alignItems="flex-start">
                          <Text
                            padding={{ base: 6, lg: 8 }}
                            w={{ base: "full", md: "75%", lg: "80%" }}
                            className="h6"
                          >
                            {t("What time and days is your workspace open?")}
                          </Text>
                          <Divider />
                        </VStack>
                        <AddASpaceLayout>
                          <VStack alignItems="flex-start" w="full">
                          <Text className="h6">{t("Available Hours")} </Text>
                          <VStack alignItems="flex-start" w="full">
                            <Text className="subtitle2">{t("Monday")} - {t("Friday")}</Text>
                            <Stack
                              w="inherit"
                              direction={{ base: "column", md: "row" }}
                            >
                              <Field name="from">
                                {({ field, form }: any) => (
                                  <FormControl
                                    className="caption"
                                    isRequired
                                    isInvalid={form.errors.from && form.touched.from}

                                  >
                                    <InputGroup>
                                      <InputLeftAddon
                                        pointerEvents="none"
                                        bg="primary.200"
                                        children={<Text>{t("From")}</Text>}
                                      />
                                      <Select
                                         {...field}
                                         
                                         name="from"
                                        //value={country}
                                        onChange={props.handleChange}
                                        placeholder={t("Opening Time")}
                                      >
                                        {fromItemViews}
                                      </Select>
                                    </InputGroup>
                                    <FormErrorMessage>{t(form.errors.from)}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="to">
                                {({ field, form }: any) => (
                                  <FormControl
                                    className="caption"
                                     isRequired
                                    isInvalid={form.errors.to && form.touched.to}
                                   >
                                    <InputGroup>
                                      <InputLeftAddon
                                        w="70px"
                                        pointerEvents="none"
                                        bg="primary.200"
                                        children={<Text>{t("To")}</Text>}
                                      />
                                      <Select
                                        {...field}

                                        name="to"
                                       
                                        onChange={props.handleChange}
                                        placeholder={t("Closing Time")}
                                      >
                                        {toItemViews}
                                      </Select>
                                    </InputGroup>
                                    <FormErrorMessage>{t(form.errors.to)}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </Stack>
                          </VStack>
                          </VStack>
                          <VStack
                            w="full"
                            alignItems="flex-start"
                          >
                            <VStack
                               w="full"
                               alignItems="flex-start"
                            > 
                            <Field  name="satOpened">
                              {({ field, from }: any) => (
                                <FormControl className="caption">
                                  <HStack>
                                  <Checkbox
                                       colorScheme="primary"
                                      isChecked={props.values.satOpened}
                                      name="satOpened"
                                      onChange={(e)=>{
                                        props.handleChange(e)
                                        if (!e.target.checked) {
                                          props.setFieldValue("satFrom", "")
                                          props.setFieldValue("satTo", "")
                                        }
                                      }}
                                      size="lg"
                                    
                                    />
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                       
                                    >
                                      {t("Sat")} - {t("Open")}
                                    </FormLabel>
                                    
                                  </HStack>
                                </FormControl>
                              )}
                            </Field>
                            {
                              props.values.satOpened ? (
                                <Stack
                                w="inherit"
                                direction={{ base: "column", md: "row" }}
                              >
                                <Field name="satFrom">
                                  {({ field, form }: any) => (
                                    <FormControl
                                      className="caption"
                                      isRequired
                                      isInvalid={form.errors.satFrom && form.touched.satFrom}
  
                                    >
                                      <InputGroup>
                                        <InputLeftAddon
                                          pointerEvents="none"
                                          bg="primary.200"
                                          children={<Text>{t("From")}</Text>}
                                        />
                                        <Select
                                           {...field}
                                           
                                           name="satFrom"
                                          //value={country}
                                          onChange={props.handleChange}
                                          placeholder="Opening Time"
                                        >
                                          {fromItemViews}
                                        </Select>
                                      </InputGroup>
                                      <FormErrorMessage>{form.errors.satFrom}</FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field name="satTo">
                                  {({ field, form }: any) => (
                                    <FormControl
                                      className="caption"
                                       isRequired
                                      isInvalid={form.errors.satTo && form.touched.satTo}
                                     >
                                      <InputGroup>
                                        <InputLeftAddon
                                           w="70px"
                                          pointerEvents="none"
                                          bg="primary.200"
                                          children={<Text>{t("To")}</Text>}
                                        />
                                        <Select
                                          {...field}
  
                                          name="satTo"
                                         
                                          onChange={props.handleChange}
                                          placeholder={t("Closing Time")}
                                        >
                                          {toItemViews}
                                        </Select>
                                      </InputGroup>
                                      <FormErrorMessage>{form.errors.satTo}</FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </Stack>
                              ):null
                            }
                           
                            </VStack>
                            <VStack
                              alignItems="flex-start" w="full"
                            >
                            <Field name="sunOpened">
                              {({ field, from }: any) => (
                                <FormControl className="caption" id="sunOpened">
                                  <HStack>
                                  <Checkbox
                                      
                                       colorScheme="primary"
                                       isChecked={props.values.sunOpened}
                                      // checked={props.values.sunOpened}
                                      name="sunOpened"
                                      onChange={(e)=>{
                                        props.handleChange(e)
                                        if (!e.target.checked) {
                                          props.setFieldValue("sunFrom", "")
                                          props.setFieldValue("sunTo", "")
                                        }
                                      }}
                                      size="lg"
                                    />
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                     // htmlFor="openedSun"
                                    >
                                      {t("Sun")} - {t("Open")}
                                    </FormLabel>
                                    
                                  </HStack>
                                </FormControl>
                              )}
                            </Field>
                            {
                              props.values.sunOpened ? (
                                <Stack
                                w="inherit"
                                direction={{ base: "column", md: "row" }}
                              >
                                <Field name="sunFrom">
                                  {({ field, form }: any) => (
                                    <FormControl
                                      className="caption"
                                      isRequired
                                      isInvalid={form.errors.sunFrom && form.touched.sunFrom}
  
                                    >
                                      <InputGroup>
                                        <InputLeftAddon
                                          pointerEvents="none"
                                          bg="primary.200"
                                          children={<Text>{t("From")}</Text>}
                                        />
                                        <Select
                                           {...field}
                                           
                                           name="sunFrom"
                                          //value={country}
                                          onChange={props.handleChange}
                                          placeholder={t("Opening Time")}
                                        >
                                          {fromItemViews}
                                        </Select>
                                      </InputGroup>
                                      <FormErrorMessage>{form.errors.sunFrom}</FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                                <Field name="sunTo">
                                  {({ field, form }: any) => (
                                    <FormControl
                                      className="caption"
                                       isRequired
                                      isInvalid={form.errors.sunTo && form.touched.sunTo}
                                     >
                                      <InputGroup>
                                        <InputLeftAddon
                                          w="70px"
                                          pointerEvents="none"
                                          bg="primary.200"
                                          children={<Text>{t("To")}</Text>}
                                        />
                                        <Select
                                          {...field}
  
                                          name="sunTo"
                                         
                                          onChange={props.handleChange}
                                          placeholder={t("Closing Time")}
                                        >
                                          {toItemViews}
                                        </Select>
                                      </InputGroup>
                                      <FormErrorMessage>{form.errors.sunTo}</FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </Stack>
                              ):null
                            }
                            </VStack>
                           
                           </VStack>
                          <HStack w="full" justifyContent="flex-end">
                    <Button
                          w={"20%"}
                          minW="96px"
                           isLoading={props.isSubmitting}
                          className="button"
                          colorScheme="whatsapp"
                          variant="solid"
                          onClick={()=>{
                              props.submitForm()
                              
                          }}
                          //disabled
                        >
                          {t("Continue")}
                        </Button>
                    </HStack>
                        </AddASpaceLayout>
                      </Form>
                    );
                }}
            </Formik>
        </>
    )
}

export default HopFormView

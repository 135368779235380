import { HStack, Image, Skeleton, VStack, Text } from '@chakra-ui/react'
import React from 'react'
import advert from '../../../Assets/jumia_food.jpeg'
import { PublicListing } from '../../../Models/Interfaces'

interface AdListingViewProps {
  listing: PublicListing
}

const AdListingView = (props: AdListingViewProps) => {
     
    return  (
        <HStack shadow={"md"}  w="full" h="full"  alignItems="flex-start" rounded="md" 
         spacing="0px" 
        // h="180px"
          >
            
            <>
            <Image
               
               fallback={
                 <Skeleton 
                 w="200px"
                 h="full"
                 />
               }
                position="relative"
                w="100%"
               h="full"
               
               objectFit="fill"
               rounded="md"
               src={props.listing.images.header}
               />
  
          {/* <VStack h="full" color="black !important" p={{ base: 4 }} alignItems="flex-start">
             
             <Text className="h6" fontSize="16px !important" noOfLines={1}>Jumia Food</Text>
             <Text className="caption" noOfLines={1}>Advert</Text>
            
          </VStack> */}
            </>
            
       </HStack>
      ) 
}

export default AdListingView

import React from 'react'
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView'
import TandC from '../../../SupportingViews/TandC/TandC'
import {Text, VStack} from '@chakra-ui/react'
import { useTranslation  } from "react-i18next";

const TermsConditions = () => {
  const {t} = useTranslation()
    return (
        <LayoutView>
              <VStack   alignItems="flex-start">
              <Text   className="h5">
                {t('terms and conditions')}
                {/* Terms and Conditions */}
                </Text>
            <TandC />
              </VStack>
        </LayoutView>
    )
}

export default TermsConditions

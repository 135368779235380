import React from 'react'
import { Helmet } from "react-helmet";
 
interface WHSEOProps {
    title: string 
    meta: {
        name: string,
        content: string
    }[]
}

const WHSEO = ({title, meta}: WHSEOProps) => {
    return (
       <Helmet 
            title={title}
            htmlAttributes={{lang: "en"}}
            meta={meta}
       />
    )
    // return (
    //     <Helmet>
    //         <title>{title}</title>
    //     </Helmet>
    // )
}

export default WHSEO

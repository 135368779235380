import React, { useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td,Text, Box, Link, HStack, Button, Spacer, LinkBox, LinkOverlay, useMediaQuery } from "@chakra-ui/react"
import { BookingsTableProps } from './Models/Booking'
import {  NavLink as ReactLink, useHistory, useParams, useRouteMatch} from "react-router-dom";
import { RequestItem } from '../../../../Models/Interfaces';
import { t } from 'i18next';

interface CellLinkProps {
    content: string
    url: string
}

const CellLink = ({content, url}: CellLinkProps)=> {
    return (
        <Link as={ReactLink} to={url}>
          <Td>{content}</Td>
        </Link>
    )
}


const BookingsTable = ({bookingItems}: BookingsTableProps) => {
     
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState<RequestItem | undefined>()

    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 905px) and (max-width: 1439px)",
        "(min-width: 1440px)"
      ]);

    
    const rowItemViews = bookingItems.map((row, idx)=> (
        
               <Tr key={row.category} onClick={()=>{history.push(row.detailURL)}}>
                <Td>{idx + 1}</Td>
                 {/* <Td>{row.id}</Td> */}
                   <Td>{row.name}</Td>
                    <Td style={{textTransform: "capitalize"}}>{row.userName}</Td>
                    <Td>{row.category}</Td>
                    <Td>{row.dateCreated}</Td>
                    <Td textTransform="capitalize">{row.status}</Td>
               </Tr>
    ))

    return (
            
            <Box w="full" overflowX="scroll">
                <Table  variant="simple">
                    <Thead>
                        <Tr>
                            <Th>{t("S/N")}</Th>
                            <Th>{t("Office")}</Th>
                            <Th>{t("User")}</Th>
                            <Th>{t("Category")}</Th>
                            <Th>{t("Date Booked")}</Th>
                            <Th>{t("Status")}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rowItemViews}
                    </Tbody>
                </Table>
            </Box>
     )
}

export default BookingsTable

import { Button, Text, Center, Divider, GridItem, HStack, Icon, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { RefObject, useContext, useEffect, useState } from 'react'
import { MdDateRange } from 'react-icons/md'
import { DayValue, RenderInputProps } from 'react-modern-calendar-datepicker'
import { cartItemDefaultValue, publicListingDefaultValue } from '../../../../Models/DefaultValues'
import { Cart, CartItem, DurationCategory } from '../../../../Models/Interfaces'
import { CartContext } from '../../../AppProvider/AppProvider'
import OfficeItemView from '../../../SupportingViews/OfficeItemView/OfficeItemView'
import WHLayoutGrid from '../../../SupportingViews/WHLayoutGrid/WHLayoutGrid'
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO'
import AddACardForm from '../OfficeDetail/AddACardForm'
import BookingFormView from './BookingFormView'
import PayWithView from './PayWithView'
import PriceDetailsView from './PriceDetailsView'
import { useTranslation  } from "react-i18next";

export const getDurationLabel = (category: DurationCategory): string =>{
  
  if (category === DurationCategory.DAY) {
    return "day(s)"
  }

  if (category === DurationCategory.HOUR) {
    return "hour(s)"
  }

  if (category === DurationCategory.MONTH) {
    return "month(s)"
  }

  if (category === DurationCategory.YEAR) {
    return "year(s)"
  }
  return "day(s)"
}



const ConfirmPay = () => {

    const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure()
    const {t} = useTranslation()
    const {state} = useContext(CartContext)
    const [selected, setSelected] = useState<CartItem | undefined>()
    useEffect(() => {

        let found = state.items.find(item => item.id === state.selectedId)
        setSelected(found)
        
        return () => {
            
        }
    }, [state])

    const [xsmall, small, small2, medium, large] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 1240px) and (max-width: 1439px)",
        "(min-width: 1440px)"
    ]);

    let modalSize = xsmall || small ? "full" : "lg"

    const mobileView = (item: CartItem) => {
        return (
            <>
                <GridItem colSpan={4} rowSpan={2} >
                <Text className="h6">
                  {t("Complete Your Booking")}
                  {/* Complete Your Booking */}
                  </Text>
             </GridItem>
            <GridItem colSpan={4} rowSpan={5} >
                <OfficeItemView listing={publicListingDefaultValue} />
            </GridItem>
            <GridItem colSpan={4} rowSpan={9}>
                <BookingFormView item={item} />
            </GridItem>
            <GridItem colSpan={4} rowSpan={7}>
                <PriceDetailsView selected={item} />
            </GridItem>
            <GridItem colSpan={4} rowSpan={4}>
                <PayWithView handleModalOpen={onModalOpen} />
            </GridItem>
            <GridItem colSpan={4}>
                 <Center>
                 <Button
                        w="80%"
                         className="button"
                        colorScheme="secondary"
                       // onClick={()=>{handleModalOpen()}}
                    >Pay</Button>
                 </Center>
                
            </GridItem>
            </>
        )
    }

    const desktopView = (item: CartItem)=> {


        return (
          <>
            <GridItem colSpan={12} rowSpan={2}>
              <Text className="h5">
              {t("Complete Your Booking")}
                {/* Complete Your Booking */}
                </Text>
            </GridItem>
            <GridItem colSpan={6} rowSpan={5}>
              <BookingFormView item={cartItemDefaultValue}/>
            </GridItem>
            <GridItem colSpan={6} rowSpan={5}>
              <OfficeItemView listing={publicListingDefaultValue} />
            </GridItem>
            <GridItem colSpan={6} rowSpan={6}>
              <PayWithView handleModalOpen={onModalOpen} />
            </GridItem>
            <GridItem colSpan={6} rowSpan={7}>
              <PriceDetailsView selected={item} />
            </GridItem>
           
           
            {/* <GridItem rowSpan={12}>

            </GridItem> */}
            <GridItem colSpan={5} rowSpan={2}>
              <Center justifyContent="start">
                <Button
                  w="35%"
                  className="button"
                  colorScheme="secondary"
                  // onClick={()=>{handleModalOpen()}}
                >
                   {t("Pay")}
                  {/* Pay */}
                </Button>
              </Center>
            </GridItem>
          </>
        );
    }
 
    
    return selected ? (
      <>
         <WHSEO 
            title={ `Complete Payment`} 
            meta={[
              {
                name: "description",
                content: `Complete Payment`
              },
              {
                name: "keywords",
                content: `payment, workHopper`
              }
            ]}  
          />
        <WHLayoutGrid>
            {(xsmall || small) ? mobileView(selected) : desktopView(selected)}
            <Modal
          scrollBehavior="inside"
          isCentered
          size={modalSize}
          motionPreset="slideInBottom"
          isOpen={isModalOpen}
          //isOpen
          onClose={onModalClose}
        >
          <ModalOverlay />
          <ModalContent 
             >
            <ModalHeader className="h6">
            {t("Add a Card")}
              {/* Add a Card */}
              </ModalHeader>
            <Divider/>
            <ModalCloseButton color="primary.500" />
               <ModalBody paddingX="0px">
                     <AddACardForm />
                     <Divider/>
              </ModalBody>
              <ModalFooter className="h6">
                  <HStack w="full">
                      <Spacer/>
                      <Button
                        w="20%"
                         className="button"
                        colorScheme="secondary"
                       // onClick={()=>{handleModalOpen()}}
                    >
                       {t("Done")}
                      Done
                    </Button>
                  </HStack>
              </ModalFooter>
           </ModalContent>
           </Modal>
        </WHLayoutGrid>
       </>
    ):null
}

export default ConfirmPay

import { AccessToken, LoginData, RegistrationResponse, Result } from "../../../../Models/Interfaces"
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager"

class LoginDataManager {

    private apiManager = WorkhopperAPIManager.shared

    async loginUser(data: LoginData): Promise<Result<AccessToken>> {

        return await this.apiManager.login(data)
        
    }

    async getLoginUser(token: string): Promise<Result<RegistrationResponse>> {
       return await this.apiManager.getLoginUser(token)
    }
}

export const loginDataManager = new LoginDataManager()
import axios from "axios";
 import { useLocation } from "react-router-dom";
import { Advert, Bookable, BookingData, BookingDetail, CompanyInfo, Enquiry,  ExchangeRate,  ListResponseData,  PublicListing, QueryItem, Result, ResultType, Review, SlideshowItem, SpotlightItem, UserReview, Workspace } from "./Interfaces";
import { WorkhopperAPIManager } from "./WorkhopperAPIManager";

enum Endpoint {
    USERS = "users",
    LOGIN = "login",
    COUNTRIES = "countries",
    AMENITIES = "amenities",
    AMENITYCATEGORIES = "amenity_categories",
    LISTINGCATEGORIES = "listings_categories",
    WORKSPACE = "workspace",
    LISTING = "listings",
    UPLOADLISTINGIMAGES = "listings_docs",
    ENQUIRY = "enquiry",
    REVIEW = "review",
    LOCATION = "locations",
    BOOKINGS = "bookings",
    ADVERT = "advert",
    SLIDESHOW = "slideshow",
    SPOTLIGHT = "spotlight",
    CURRENCYCONVERTER = "currency_converter",
    COMPANY = "company"


}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export class WorkhopperPublicAPIManager extends WorkhopperAPIManager {

     static shared = new WorkhopperPublicAPIManager()

    async getListings(queryItems?: QueryItem[]): Promise<Result<PublicListing[]>> {

        let result: Result<PublicListing[]> 
 
 
        this.endpoint = Endpoint.LISTING
        //debugger
        let url =  this.endpointURL
        if (queryItems && queryItems.length > 0) {
            let queryItemsStr = ""
            for (let i = 0; i < queryItems.length; ++i) {
                let temp =`${queryItems[i].name}=${queryItems[i].value}`
                if (i > 0) {
                    temp = `&${temp}`
                }
                queryItemsStr += temp
            }
            url = `${url}/?${queryItemsStr}`
        }

 
         try {
            let response = await axios.get(url)
             //debugger
            result = {type: ResultType.Success, value: (response.data as any).results}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load office listings")
            /*
           if (axios.isAxiosError(error) ) {
             if (error.response) {
                msg = "Couldn't load office listings"

             
                result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else if (error.request) {
                    msg = "WorkHopper seems to be offline."

                 
                     result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else {
                // Something happened in setting up the request that triggered an Error
               msg = "Something went wrong"

               // console.log('Error', error.message);
                result =  {type: ResultType.Failure, error: new Error(msg)} 

              }
          
               
            } else {
                result =  {type: ResultType.Failure, error: new Error("An unexpected occured")} 
            }*/
           
        }
          
         

       return result
    }

    async getOfficeListings(queryItems?: QueryItem[], nextPageURL?: string): Promise<Result<ListResponseData<PublicListing>>> {

        let result: Result<ListResponseData<PublicListing>> 
         let url = ""

 
        this.endpoint = Endpoint.LISTING
        if (nextPageURL) {
            url = nextPageURL
        } else {
            url =  this.endpointURL
            if (queryItems && queryItems.length > 0) {
                let queryItemsStr = ""
                for (let i = 0; i < queryItems.length; ++i) {
                    let temp =`${queryItems[i].name}=${queryItems[i].value}`
                    if (i > 0) {
                        temp = `&${temp}`
                    }
                    queryItemsStr += temp
                }
                url = `${url}/?${queryItemsStr}`
            }
        }
        //debugger
 
         try {
            let response = await axios.get(url)
             //debugger
            result = {type: ResultType.Success, value: response.data }
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load office listings")
            
        }
          
         

       return result
    }


    async getListing(id: string): Promise<Result<PublicListing[]>> {

        let result: Result<PublicListing[]> 
        

        this.endpoint = Endpoint.LISTING

        try {
            let response = await axios.get(`${this.endpointURL}${id}/`)
             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load office listing")
        //    if (axios.isAxiosError(error) ) {
        //      if (error.response) {
        //         msg = "Couldn't load office listing"

             
        //         result =  {type: ResultType.Failure, error: new Error(msg)} 

        //      } else if (error.request) {
        //             msg = "WorkHopper seems to be offline."

                 
        //              result =  {type: ResultType.Failure, error: new Error(msg)} 

        //      } else {
        //         // Something happened in setting up the request that triggered an Error
        //        msg = "Something went wrong"

        //        // console.log('Error', error.message);
        //         result =  {type: ResultType.Failure, error: new Error(msg)} 

        //       }
          
               
        //     } else {
        //         result =  {type: ResultType.Failure, error: new Error("An unexpected occured")} 
        //     }
           
        }

        return result
       
    }

     appendQueryitems(url: string, queryItems: QueryItem[]): string {

        if (!url) {
            throw new Error("url not specified")
        }

        if (queryItems.length === 0) return url
         
        let queryItemsStr = ""
        for (let i = 0; i < queryItems.length; ++i) {
            let temp =`${queryItems[i].name}=${queryItems[i].value}`
            if (i > 0) {
                temp = `&${temp}`
            }
            queryItemsStr += temp
        }
        url = `${url}/?${queryItemsStr}`

        return url
    }

    async fetchWorkspaces(queryItems: QueryItem[], nextURL?: string): Promise<Result<ListResponseData<Workspace>>> {

        let result: Result<ListResponseData<Workspace>> 
       // let msg = ""

 
        this.endpoint = Endpoint.WORKSPACE
        
        let url = ""
        if (!nextURL) {
            url = this.appendQueryitems(this.endpointURL, queryItems)
        } else {
            url = nextURL
        }
        
        //let url = this.appendQueryitems(this.endpointURL, queryItems)

        try {
            let response = await axios.get(url)
             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load workspaces")
            /*
           if (axios.isAxiosError(error) ) {
             if (error.response) {
                msg = "Couldn't load workspaces"
                result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else if (error.request) {
                    msg = "WorkHopper seems to be offline."
                    result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else {
                // Something happened in setting up the request that triggered an Error
               msg = "Something went wrong"

               // console.log('Error', error.message);
                result =  {type: ResultType.Failure, error: new Error(msg)} 

              }
          
               
            } else {
                result =  {type: ResultType.Failure, error: new Error("An unexpected error occured")} 
            }*/
           
        }

        return result


     }

     async getListingById(id: string): Promise<Result<PublicListing>> {

        let result: Result<PublicListing> 
 
 
        this.endpoint = Endpoint.LISTING
        let url = `${this.endpointURL}/${id}/`


        try {
            let response = await this.whAxios.get(url)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult<PublicListing>(error, "Couldn't load listing")
        }
           /*
           if (axios.isAxiosError(error) ) {
               if (error.response) {
                if (error.response.status === 401) {
                    msg = "Invalid Token"
                } else {
                    msg = "Couldn't load listing"
                }
                result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else if (error.request) {
                    msg = "WorkHopper seems to be offline."
                    result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else {
                // Something happened in setting up the request that triggered an Error
               msg = "Something went wrong"

               // console.log('Error', error.message);
                result =  {type: ResultType.Failure, error: new Error(msg)} 

              }
          
               
            } else {
                result =  {type: ResultType.Failure, error: new Error("An unexpected error occured")} 
            }
           
        }*/

        return result
     }

     async postEnquiry(enquiryData: Enquiry): Promise<Result<Enquiry>> {

        let result: Result<Enquiry> 
        //let msg = ""

 
        this.endpoint = Endpoint.ENQUIRY


        try {
            let response = await axios.post(`${this.endpointURL}/`, enquiryData)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't post enquiry")
            /*
           if (axios.isAxiosError(error) ) {
               console.log(error.response)
             if (error.response) {
                msg = "Couldn't post enquiry"
                result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else if (error.request) {
                    msg = "WorkHopper seems to be offline."
                    result =  {type: ResultType.Failure, error: new Error(msg)} 

             } else {
                // Something happened in setting up the request that triggered an Error
               msg = "Something went wrong"

               // console.log('Error', error.message);
                result =  {type: ResultType.Failure, error: new Error(msg)} 

              }
          
               
            } else {
                result =  {type: ResultType.Failure, error: new Error("An unexpected error occured")} 
            }*/
           
        }

        return result
     }

     async postReview(data: Review, token: string): Promise<Result<Review>> {

        let result: Result<Review> 
 
         this.endpoint = Endpoint.REVIEW


        try {
            let response = await this.whAxios.post(`${this.endpointURL}/`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
           result = this.getErrorResult(error, "Couldn't post review. Try Again")
        }

        return result
     }

     async updateReview(data: unknown, reviewId: string, token: string): Promise<Result<Review>> {

        let result: Result<Review> 
 
         this.endpoint = Endpoint.REVIEW


        try {
            let response = await this.whAxios.patch(`${this.endpointURL}/${reviewId}/`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
           result = this.getErrorResult(error, "Couldn't update review. Try Again")
        }

        return result
     }

     async getReviews(queryItems: QueryItem[]): Promise<Result<UserReview[]>> {

        let result: Result<UserReview[]> 
       
        this.endpoint = Endpoint.REVIEW

        let url = this.appendQueryitems(`${this.endpointURL}`, queryItems)


        try {
            // let response = await this.whAxios.get(url, {
            //     headers: {
            //         'Authorization': `Bearer ${token}`
            //     }
            // })

            let response = await this.whAxios.get(url)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
           result = this.getErrorResult(error, "Couldn't get reviews. Try Again")
        }

        return result
     }

     async getLocationData<T>(queryItems: QueryItem[]): Promise<Result<T[]>> {

        let result: Result<T[]> 
        
        this.endpoint = Endpoint.LOCATION

        let url = this.appendQueryitems(this.endpointURL, queryItems)

        try {
            let response = await this.whAxios.get(url)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
           result = this.getErrorResult(error, "Couldn't get location data. Try Again")
        }

        return result
     }

     async postBookables(detail: BookingData, token: string): Promise<Result<BookingData>> {

        let result: Result<BookingData> 
       // debugger
        this.endpoint = Endpoint.BOOKINGS
        let data = detail
        try {
           let response = await this.whAxios.post(`${this.endpointURL}/`, data, {
               headers: {
                   'Authorization': `Bearer ${token}`
               }
           })

            result = {type: ResultType.Success, value: response.data}
           console.dir(detail)
       } catch (error) {
            //console.dir(error)
          result = this.getErrorResult(error, "Couldn't complete your booking. Try Again")
       }

       return result
     }

     async getAdverts(page?: string): Promise<Result<Advert[]>> {

        let result: Result<Advert[]> 
 
 
        this.endpoint = Endpoint.ADVERT
        let url: string
        if (page) {
            url = `${this.endpointURL}/?ad_type=${page}`
        } else {
            url = `${this.endpointURL}/`
        }
            

        
        try {
            let response = await this.whAxios.get(url)

             console.log("Advert", response.data)
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load listing")
        }
           

        return result
     }


     // slideshow
     
     async getSlideshows(): Promise<Result<SlideshowItem[]>> {

        let result: Result<SlideshowItem[]> 
 
 
        this.endpoint = Endpoint.SLIDESHOW
        let url = `${this.endpointURL}/`

        try {
            let response = await this.whAxios.get(url)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load slideshow")
        }

        return result
     }

     async getSpotlights(): Promise<Result<SpotlightItem[]>> {

        let result: Result<SpotlightItem[]> 
 
 
        this.endpoint = Endpoint.SPOTLIGHT
        let url = `${this.endpointURL}/`

        try {
            let response = await this.whAxios.get(url)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't load featured offices")
        }

        return result
     }

     async getExchangeRate(base: string, target: string): Promise<Result<ExchangeRate>> {

        let result: Result<ExchangeRate> 
 
 
        this.endpoint = Endpoint.CURRENCYCONVERTER
        let url = `${this.endpointURL}/${base}/${target}`

        try {
            let response = await this.whAxios.get(url)

             
            result = {type: ResultType.Success, value: response.data}
        } catch (error) {
            result = this.getErrorResult(error, "Couldn't get exchange rate")
        }

        return result
     }

     async getCompanyList(): Promise<Result<CompanyInfo[]>> {
        let result: Result<any> = {
          type: ResultType.Failure,
          error: new Error(""),
        };
        
        this.endpoint = Endpoint.COMPANY
    
        try {
          let response = await axios.get(`${this.endpointURL}/`);
    
          result = { type: ResultType.Success, value: response.data };
        } catch (error) {
          result = this.getErrorResult(error, "Couldn't get companies. Try again");
        }
    
        return result;
      }


}
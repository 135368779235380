import { BookedDates, BookingDetail, CartItem, DurationCategory, ListingCategory, PublicListing } from "./Interfaces";

export const publicListingDefaultValue: PublicListing = {
    
    id: "30cec67d-29d8-4051-9ad2-7c565841452e",
    title: "BeeHive",
    description: "One of the best places to be",
    workspace: "fc84b79a-c995-4375-82bc-4cd3789c9788", 
    workspace_details: {
        name: "Enterprise Hub",
        street: "Ola Adigun",
        header: "",
        city: "Lekki",
        country: "United States Of America",
        partner_id: 0,
        partner_name: "Dev",
        state: "Nigeria",
        email: "gamy@gmail.com",
        extra_days: {
          days: ["Sun"]
        },
        amenities: [
          {
            id: "1713a6df-4d27-4939-b720-95784d7d44ef",
            icon: "https://workhopper.org/media/amenities/swimmer.png",
            title: "swimming pool",
            description: "Just a swimming pool",
            date_created: "2021-09-21T14:08:53.783146",
            category: "8956c1a1-5db8-445e-8458-29449cacac58"
          },
          {
            id: "5777e660-5958-4381-ad82-f6a8b29bcb95",
            icon: "https://workhopper.org/media/amenities/kitchen.png",
            title: "Kitchen",
            description: "Just a kitchen",
            date_created: "2021-09-21T14:02:57.037933",
            category: "c319f4c2-1a35-4eab-8aa2-0395c9cd1518"
          }
        ],
        available_hours: {
          start_time: "12:00 AM",
          end_time: "7:00 PM"
        }
    },
    capacity: 5,
    images:  {
        header: "https://workhopperserverstorage.s3.amazonaws.com/media/listings/header/7.PNG",
        others: [
          {
            image1: "https://workhopper.org/media/listings/103_47photo2_0EcknvT.jpeg"
          },
          {
            image2: "https://workhopper.org/media/listings/103_regus_header.jpeg"
          },
          {
            image3: "https://workhopper.org/media/listings/103_regus_header_2Fm5OX9.jpeg"
          },
          {
            image4: "https://workhopper.org/media/listings/103_regus_photo1.jpeg"
          }
        ]
      },
    booking_duration: [
        {
          "minimum_duration": "daily",
          "amount": "6",
          "currency_id": "34557151-303a-43cd-a60c-7675fe559f37",
          "currency": "USD"
        },
        {
          "minimum_duration": "hourly",
          "amount": "67",
          "currency_id": "34557151-303a-43cd-a60c-7675fe559f37",
          "currency": "USD"
        },
        {
          "minimum_duration": "monthly",
          "amount": "8",
          "currency_id": "34557151-303a-43cd-a60c-7675fe559f37",
          "currency": "USD"
        },
        {
          "minimum_duration": "yearly",
          "amount": "9",
          "currency_id": "34557151-303a-43cd-a60c-7675fe559f37",
          "currency": "USD"
        }
    ],
    avg_rating: 3.5,
    is_receive_mails: false,
    use_office_address: false,
    is_whiteboard: false,
    category_details: "Meeting Room",
      is_hourly: false,
      is_daily: false,
      is_monthly: false,
      is_yearly: false,
    category: "6c9636c2-a0ff-4296-9f1a-83aa7ee8c2d2", 
    date_created: "2021-09-28T18:58:31.885254",
    date_updated: "2021-09-28T18:58:35.776731",
    is_publish: false,
    is_advert: true,
    is_premium: false,
    is_claimed: false
    

} 

export const bookingDetailDefaultValue: BookingDetail = {
  durationType: DurationCategory.HOUR,
  duration: 5,
  guests: 10,
  startDate: new Date(),
  endDate: undefined 

}

export const cartItemDefaultValue: CartItem = {
  id: "30cec67d-29d8-4051-9ad2-7c565841452e",
  listing: publicListingDefaultValue,
  bookingDetail: bookingDetailDefaultValue
}

export const listingCategoryDefaultValue: ListingCategory = {
  id: "all",
  title: "All"
}

export const bookedDatesDefaultValue: BookedDates = {
  hourly: [
      {
          start_date: "2022-03-02",
          end_date: "2022-03-02T08:00:00"
      }
  ],
  daily: [{
    start_date: "2021-12-13",
    end_date: "2021-12-23T08:00:00"
}],
  monthly: [{
    start_date: "2022-03-05",
    end_date: "2022-05-05T08:00:00"
},
{
  start_date: "2022-07-02",
  end_date: "2022-08-02T08:00:00"
}
],
  yearly: [{
    start_date: "2022-09-02",
    end_date: "2023-09-02T08:00:00"
},
{
  start_date: "2023-10-02",
  end_date: "2024-10-02T08:00:00"
}
]
}
//import { SimpleGrid, Box, Image } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from "swiper/react";
import {
  AspectRatio,
  Box,
  Circle,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
  Square
} from "@chakra-ui/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// import "./styles.css";
//import styles from "./HeaderCarousel.module.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
//import { BannerViewProps } from "../../BannerView/BannerView";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

 

import { BreakPoint } from "../OfficeDetail/Models/Interfaces";
import { BannerViewProps } from "../../../SupportingViews/BannerView/BannerView";
import { useState } from "react";
import { CompanyInfo, RecommendedItem } from "../../../../Models/Interfaces";
import { useTranslation  } from "react-i18next";
//import { NewHomeProps } from './NewHome';

export interface WHPartnerGridProps {
  breakpoint: BreakPoint;
  partners: CompanyInfo[]
}

interface SlidersViewProps extends BannerViewProps {
  setSwiper: (swiper: SwiperCore | undefined) => void;
  slidesPerView: number;
}

const SlidersView = ({ items, setSwiper, slidesPerView, breakpoint }: SlidersViewProps) => {

  return (
    //  <Box w="full">
    <Swiper
      onSwiper={setSwiper}
      slidesPerView={slidesPerView}
      onSlideChange={() => {}}
      loop={true}
      spaceBetween={16}
      freeMode
      centeredSlides
      speed={1000}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      navigation={false}
      style={{
        width: "100%",
        height: (breakpoint === BreakPoint.XS || breakpoint === BreakPoint.S) ? "72px" : "96px",
        padding: "8px 0px"
      }}
      
    >
      {items.map((item, idx) => (
        <SwiperSlide key={idx}>
          <Box
            p={ (breakpoint === BreakPoint.XS || breakpoint === BreakPoint.S) ? 4 : 6}
            bg="white"
            w="full"
            key={item.id}
            shadow="md"
            className="partner"
            h={"full"}
           // h={breakpoint === BreakPoint.XS || breakpoint === BreakPoint.S ? 16 : 18}
          >
            <Image
              w="full"
              h="full"
              rounded="md"
              display="block"
              objectFit="cover"
              src={item.imageURL}
            />
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
   );
};

SwiperCore.use([Navigation]);

const WHPartnerGrid = ({ breakpoint, partners }: WHPartnerGridProps) => {
  const {t} = useTranslation()
  
  let slidesPerView = 2;
  let padding = 4;
  let headerClass = "h6";

  const [swiper, setSwiper] = useState<SwiperCore | undefined>();
  let btnSize = 6;
//    if (breakpoint === BreakPoint.XS || breakpoint === BreakPoint.S) {
//      btnSize = 8;
//   }
    
  if (
    breakpoint === BreakPoint.M ||
    breakpoint === BreakPoint.L ||
    breakpoint === BreakPoint.XL
  ) {
    slidesPerView = 5;
    padding = 8;
    headerClass = "h4";
    btnSize = 8;
  }
  return (
    //<>
      <VStack
     
        w="full"
        padding={padding}
        alignItems={"flex-start"}
        bg="#eee"
      >
        <HStack w="full" >
          <Text color={"primary.500 !important"} className={headerClass}>
          {t("partner")}
            {/* Our Partners */}
          </Text>
          <Spacer />
          <HStack
            h={6}
            color="primary.500"
            spacing={3}
            zIndex={102}
          >
            <Square
              rounded={4}
              cursor="pointer"
              size={btnSize}
              border={`1.5px solid #262949`}
              onClick={() => {
                if (swiper) {
                  swiper.slidePrev();
                }
              }}
            >
              <MdChevronLeft />
            </Square>
            <Square
                 rounded={4}
              cursor="pointer"
              size={btnSize}
              onClick={() => {
                if (swiper) {
                  swiper.slideNext();
                }
              }}
              border={`1.5px solid #262949`}
            >
              <MdChevronRight />
            </Square>
          </HStack>
        </HStack>
        <SlidersView
          slidesPerView={slidesPerView}
          setSwiper={setSwiper}
          items={partners.map(item => ({
              imageURL: item.image,
              id: item.id.toString()
          }))}
          breakpoint={breakpoint}
        />
      </VStack>
   // </>
  );
};

export default WHPartnerGrid;

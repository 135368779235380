 import { Button, Divider,  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, useDisclosure } from '@chakra-ui/react';
import React, { ReactNode, useContext } from 'react'
import { ModalContext } from '../../AppProvider/AppProvider';
import { BreakPoint } from '../../Screens/WHApp/OfficeDetail/Models/Interfaces';

export interface WHModalProps {
    breakpoint: BreakPoint
    isModalOpen: boolean
    header: string
    children: ReactNode
    isLoading: boolean
    onClick: ()=>void
    onClose: ()=>void
}

//const WHModal = ({breakpoint, isModalOpen, header, children, isLoading, onClick, onClose}: WHModalProps) => {
const WHModal = ({breakpoint, isModalOpen, header, children, isLoading, onClick, onClose}: WHModalProps) => {
   // debugger
    const {modal, setModal} = useContext(ModalContext) 
   // const { isOpen, onOpen, onClose } = useDisclosure();
   // const { onClose } = useDisclosure();


    return (
        <>
          <Modal
          isCentered
          onClose={()=>{
            if (!modal) {return}
            setModal({...modal, isModalOpen: false })
          }}
          isOpen={isModalOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={breakpoint == BreakPoint.XS ? "md" : "lg"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            position={breakpoint === BreakPoint.XS ? "fixed" : "unset"}
            bottom={breakpoint === BreakPoint.XS ? "0px" : "unset"}
            mb={breakpoint === BreakPoint.XS ? "0" : "unset"}
             borderRadius={breakpoint === BreakPoint.XS ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              {header}
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody>{children}</ModalBody>
            <Divider />
            <ModalFooter>
              <Spacer />
              <Button 

                    isLoading={modal?.isLoading}
                    colorScheme="primary"
                    onClick={()=>{
                      modal?.onClick()
                      //onApplyFilters()
                     // onClose()
                    }}
               >
                        Apply
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    )
}

export default WHModal

/**
 * Abstract: A view that shows offline network status
 * whenever online data needed by a view is not available
 */
import { VStack, Text, useBreakpointValue, Button } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCloud, MdCloudOff } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import routes from '../../routes'
import { SetErrorContext } from '../AppProvider/AppProvider'
import { BreakPoint } from '../Screens/WHApp/OfficeDetail/Models/Interfaces'

/**
 * @param msg message displaed to the user
 * @param onRetry calback excecuted when user clicks on button to fetch data
 * @returns 
 */

interface OfflineViewProps {
    msg: string 
    onRetry?: ()=>void
    pt: string
}
const OfflineView = ({msg, onRetry, pt}: OfflineViewProps) => {

    const history = useHistory()
    const {t} = useTranslation()

    const msgSize = useBreakpointValue({base: "h6", sm: "h6", 
       md: "h6", lg: "h5", xl: "h5"})
     const setErrorContext = useContext(SetErrorContext)

    if (msg.includes("session has expired")) {
        //setErrorContext({title: "", msg: msg})
        return null
    }

    if (msg.includes("404")) {
        //history.push(routes.page404.path)
       // history.push(routes.page404.path)
    }

    return (
        <VStack w="full" alignItems="center" h="full" mb={4} pt={pt}>
            <MdCloudOff fontSize="32px"/>
            <Text 
            w="87.5%"
            textAlign="center"
            className={msgSize}
            >
                {t(msg)}</Text>
            {
                onRetry ? (
                    <Button 
                         onClick={onRetry}
                         height={8}
                         colorScheme="primary" 
                         className="button">
                             Try Again</Button>
                ):  null
            }
        </VStack>
    )
}

export default OfflineView

import React, { Dispatch, ReactNode, SetStateAction, useEffect, useReducer, useState } from 'react'
import { cartItemDefaultValue } from '../../Models/DefaultValues'
import { Cart, CartActionType, CartItem, CurrencyConverter, DurationCategory, PublicListing, Workspace } from '../../Models/Interfaces'


/*
interface CartAction {
    type: CartActionType
    payload: CartItem | string
}

const cart: Cart = {
    selectedId: "",
    items: []
    //items: [cartItemDefaultValue, cartItemDefaultValue]
}

let items: CartItem[] = []
    let temp = localStorage.getItem("cart-items")
  
    if (!temp) {
        localStorage.setItem("cart-items", JSON.stringify(cart.items))
    } else {
        //items = JSON.parse(temp)
        items = (JSON.parse(temp) as CartItem[]).map(item => (
            {...item, bookingDetail: {...item.bookingDetail, startDate: new Date(item.bookingDetail.startDate!)}}
        ))
        
        cart.items = items

    }


const cartReducer = (state: Cart, action: CartAction): Cart =>{
     
    switch (action.type) {
        
        case CartActionType.UPDATE:
             const cartItem = action.payload as CartItem
            const itemIdx = state.items.findIndex(item => item.id === cartItem.id)
            
            const updatedItems = [...state.items.slice(0, itemIdx), action.payload as CartItem, ...state.items.slice(itemIdx + 1)]
            let temp0 = localStorage.getItem("cart-items") 
              if (!temp0) {return state}
                try {
                    localStorage.setItem("cart-items", JSON.stringify(updatedItems))
                    return {...state, items: updatedItems}
                } catch {
                     return state
                }
           // return {...state, items: updatedItems }
         case CartActionType.ADD:
             
              let temp = localStorage.getItem("cart-items") 
              if (!temp) {return state}
             
                //let storedItems = JSON.parse(temp)
                const items = [action.payload as CartItem, ...state.items]

                //const items = [action.payload as CartItem, ...storedItems]
                try {
                    localStorage.setItem("cart-items", JSON.stringify(items))
                    return {...state, items}
                } catch {
                     return state
                }
                
            case CartActionType.REMOVE:
                    const id = action.payload as string 
                    const filteredItems = state.items.filter(item => item.id !== id)
                    let temp2 = localStorage.getItem("cart-items") 
                    if (!temp2) {return state}
                      try {
                          localStorage.setItem("cart-items", JSON.stringify(filteredItems))
                          return {...state, items: filteredItems}
                      } catch {
                           return state
                      }
           default:
            return state
    }
}*/



export const WorkspacesContext  = React.createContext<Workspace[]>([])
export const SetWorkspacesContext = React.createContext<Dispatch<SetStateAction<Workspace[]>>>(()=>{})

// export const CurrencyConverterContext = React.createContext<{state: CurrencyConverter, 
//     dispatch: React.Dispatch<any>}>({state: currencyConverter, dispatch: ()=>null})


interface WHAppProviderProps {
    children: ReactNode
}

const WHAppProvider = ({children}: WHAppProviderProps) => {
    
    
    const [workspaces, setWorkspaces] = useState<Workspace[]>([])
   // const [state, dispatch] = useReducer(cartReducer, cart)


    return (
        <WorkspacesContext.Provider value={workspaces}>
            <SetWorkspacesContext.Provider value={setWorkspaces}>
               
                     {children}
                  
            </SetWorkspacesContext.Provider>
        </WorkspacesContext.Provider>
    )
}

export default WHAppProvider

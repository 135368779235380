import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Center, Divider, HStack, SimpleGrid, Spacer, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { cartItemDefaultValue, publicListingDefaultValue } from '../../../../Models/DefaultValues'
import { Bookable, BookingData, BookingDetail, CartItem, DurationCategory, PaymentVerificationType, PublicListing, ResultType } from '../../../../Models/Interfaces'
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager'
import { formatPrice, getDurationCategory, getDurationTitle } from '../../../../utils/utils'
import { SetErrorContext, SetSuccessContext, UserContext } from '../../../AppProvider/AppProvider'
import { CurrencyConverterContext } from '../../../AppProvider/CurrencyConverterProvider'
import ItemPriceDetail from '../../../SupportingViews/ItemPriceDetail/ItemPriceDetail'
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView'
import OfficeItemView from '../../../SupportingViews/OfficeItemView/OfficeItemView'
import OfflineView from '../../../SupportingViews/OfflineView'
import WHPaystackButton from '../../../SupportingViews/WHPaystackButton/WHPaystackButton'
import WHPrice from '../../../SupportingViews/WHPrice/WHPrice'
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO'
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner'
import { getDurationLabel } from '../ConfirmPay/ConfirmPay'
import PriceDetailsView from '../ConfirmPay/PriceDetailsView'
import { BOOKINGSTATUS } from '../../WHDashboard/Models'
import { BookedItem, UserBooking } from '../../WHDashboard/WHManageRequests/Models/Booking'
import { ScrollToTop } from '../../../SupportingViews/ScrollToTop/ScrollToTop'
import { useTranslation  } from "react-i18next";

const getCartItemDetail = (bookedItem: BookedItem): CartItem => {

    //let item: CartItem = cartItemDefaultValue
      const bookingDetail: BookingDetail = {
        duration: bookedItem.duration,
        durationType: getDurationCategory(bookedItem.duration_type),
        guests: 0,
        startDate: new Date(bookedItem.start_date),
        endDate: undefined
     }

     const item: CartItem = {
         id: bookedItem.listing!.id!,
         listing: bookedItem.listing as unknown as PublicListing,
         bookingDetail
     }

    //  item.id = bookedItem.listing!.id!
    //  item.listing = bookedItem.listing as unknown as PublicListing
    //  item.bookingDetail = bookingDetail
       
     //console.dir(item)
    return item
}

const getTotalAmount = (bookingData: UserBooking):number | undefined => {
    const total = Number(bookingData.amount)
    if (isNaN(total)) return undefined
    return total
}

const PayBooking = () => {

  const {t} = useTranslation()
    const setErrorContext = useContext(SetErrorContext)
    const setSuccessContext = useContext(SetSuccessContext)
    const [paymentData, setPaymentData] = useState<any | undefined>()
    const user = useContext(UserContext)
    const[bookingData, setBookingData] = useState<UserBooking>() 
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const params = useParams<{bookingId?: string}>()
    const [paymentVerified, setPaymentVerified] = useState("")
    const currencyConverter = useContext(CurrencyConverterContext)
    const [isLoadingPayment, setIsLoadingPayment] = useState(false)

    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 905px) and (max-width: 1439px)",
        "(min-width: 1440px)"
      ]);

      const verifyBookingPayment = useCallback(
        async () => {
         if (!user || !user.access_token) {return}
         if (!paymentData) {return}
        
         let result = await WorkhopperAPIManager.shared.verifyPremiumPayment({
           payment_type: PaymentVerificationType.BOOKING,
           payment_ref: paymentData.payment_ref
         },
         user.access_token)
         if (result.type === ResultType.Success) {
            setPaymentVerified("Payment successful and verified")
           // setSuccessContext({title: "", msg: "Payment Verified Successfully"})
         } else {
            setPaymentVerified("Payment was successful and but couldn't verified")

           //setErrorContext({title: "", msg: result.error.message})
         }
         setIsLoadingPayment(false)
        },
        [user, paymentData]
      )

    //   const postBookingPayment = useCallback(
    //     async() => {
    //       debugger
    //       if (!user || !user.access_token  || !bookingData || !bookingData.id) {return}
    //       const amount = Number(bookingData.amount ?? "")
    //       if (isNaN(amount)) {return }
         
    //       let result =  await WorkhopperAPIManager.shared.saveBookingPayment({
    //         payment_mode: bookingData.payment_mode,
    //         booking: bookingData.id, //bookingDataRef.current.,
    //         amount: amount
    //       }, 
    //         user.access_token
    //         )

    //         if (result.type === ResultType.Success) {
    //           setPaymentData(result.value)
    //         } else {

    //         }
    //     },
    //     [],
    //   )

      const loadData = useCallback(async ()=>{

        if (!user?.access_token) {return}

        if (!params.bookingId) return
        setIsLoading(true)

        //debugger
        let result = await WorkhopperAPIManager.shared.getUserBooking(user.access_token, params.bookingId)
         //console.dir(result)
        if (result.type === ResultType.Failure) {
            setIsLoading(false) 
            setError(result.error)
            return
        }
        // console.dir(result.value)
        //if it's a paid booking no need to for payment data
        if (bookingData?.status === BOOKINGSTATUS.PAID) {
          setBookingData(result.value)
          setIsLoading(false) 
          return
        }
        // if (result.value.status === BOOKINGSTATUS.PAID) {
        //   setBookingData(result.value)
        //   setIsLoading(false) 
        //   return
        // }
      

        const amount = Number(result.value.amount ?? "")
        if (isNaN(amount)) {
            setIsLoading(false)
            setError(Error("Invalid Amount Found"))
            return 
        }
         
          let result2 =  await WorkhopperAPIManager.shared.saveBookingPayment({
          payment_mode: result.value.payment_mode,
          booking: result.value.id, //bookingDataRef.current.,
          amount:  result.value.amount, //Math.round(Number(result.value.amount)),
          currency: currencyConverter.target.code
        }, 
          user.access_token
          )

          if (result2.type === ResultType.Failure) {
            setIsLoading(false) 
            setError(result2.error)
            return
          }

          setIsLoading(false) 
          setBookingData(result.value)
          setPaymentData(result2.value)

    }, [isLoading])

    useEffect(() => {
        loadData()

        return () => {
            
        }
    }, [])
     
   const loadPaymentData =  useCallback(async () => {
    if (!bookingData) return 
    if (!user || !user.access_token) {return}
   // debugger
    setIsLoadingPayment(true)
    let result2 =  await WorkhopperAPIManager.shared.saveBookingPayment({
      payment_mode: bookingData.payment_mode,
      booking: bookingData.id, //bookingDataRef.current.,
      amount:  Math.round(Number(bookingData.amount)),
      currency: currencyConverter.target.code
    }, 
      user.access_token
      )

      if (result2.type === ResultType.Failure) {
        setIsLoadingPayment(false) 
        setErrorContext({msg: "", title: `Couldn't setup payment for ${currencyConverter.target.code}`})
        //setError(result2.error)
        return
      }

      setIsLoadingPayment(false) 
      
      setPaymentData(result2.value)
      },
      [bookingData, currencyConverter.target.code, currencyConverter.rate],
    )
    
    

    return isLoading ? (
      <WHSpinner pt="64px" />
    ) : error ? (
      <OfflineView msg={error.message} pt="64px" onRetry={() => {
        loadData()
      }} />
    ) : bookingData  ? (
    //   <VStack
    //     w={xsmall || small ? "unset" : "80%"}
    //     margin={xsmall || small ? "unset" : "0 auto"}
    //   >
       <>
          <WHSEO 
            title={ `Complete Payment`} 
            meta={[
              {
                name: "description",
                content: `Complete Payment`
              },
              {
                name: "keywords",
                content: `payment, workHopper`
              }
            ]}  
          />
        <LayoutView>
        <ScrollToTop />
        {
          paymentData && paymentVerified !== "" ? (
            <VStack w="full" h={"100vh"} >
            <Alert
                status='success'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                height='200px'
              >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                  {paymentVerified}
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                {/* {paymentVerified.includes("couldn't") ? Thanks for completing your payment.} */}
                {t('Thanks for completing your payment')}
                  {/* Thanks for completing your payment. We'll contact you soon! */}
                </AlertDescription>
              </Alert>
             </VStack>
          ) : 
           <>
               <Text fontSize="18px !important" className="h6">
            {bookingData.status === BOOKINGSTATUS.PAID ? 
               "Review payment details of your booked offices":
              "Review and pay for your booked offices"
             
            }
        </Text>
        
          <SimpleGrid width="100%" rowGap={4} columns={xsmall || small ? 1 : 2}>
            {bookingData!.items.map((item) => (
              <VStack
                key={item.id}
                position="relative"
                h="400px"
                w="full"
                paddingX={4}
                paddingTop={4}
                alignItems="flex-start"
              >
                <Box 
                  height="40%"
                  w="full"
                  >
                  <OfficeItemView removeShadow={true} listing={item.listing!} />
                </Box>
                  <ItemPriceDetail 
                     duration={item.duration} 
                     durationType={getDurationCategory(item.duration_type)}
                     date={item.start_date}
                     amount={item.amount}
                     endDate={item.end_date}
                     //endDate={item.end}
                  />
                {/* <PriceDetailsView selected={getCartItemDetail(item)} /> */}
              </VStack>
            ))}
          </SimpleGrid>
           
           
            <VStack 
                p={4} 
                marginTop={6}
               w="full" 
               h={"320px"}
               alignItems="flex-start">
             <Text
               className="h6"
               fontSize={medium || large || xlarge ? "unset" : "18px !important"}
             >
               {t('Total')}
               {/* Total */}
             </Text>
             <HStack>
               <Text className="body1">
                 {t('Number of Offices')}
                 {/* Number of Offices */}
                 </Text>
               <Spacer />
               <Text className="body1">{bookingData!.total_items}</Text>
             </HStack>
             <HStack>
               <Text className="body1">{bookingData.status === BOOKINGSTATUS.PAID ? "Amount Paid" : "Price"}</Text>
               <Spacer />
                {/* <Text className="body1">{bookingData!.currency === "USD" ? "$" : "₦"} {bookingData!.amount}</Text> */}
                <Text className="body1">{formatPrice(Number(bookingData!.amount), bookingData!.currency!, bookingData!.currency === "USD" ? "en-US" : "en-NG")}</Text>
             </HStack>
   
             {
                 bookingData.status === BOOKINGSTATUS.PENDING || !bookingData.items[0].is_paid  ? (
                   <Center w="full" justifyContent={medium || large || xlarge ? "start" : "center"}>
                   <WHPaystackButton
                   isLoadingPaymentData={isLoadingPayment}
                   reference={paymentData ? paymentData.payment_ref : ""}
                   hasBookedOffice={true}
                   email={user?.email ?? ""}
                  // total={Number(bookingData.amount) * currencyConverter.rate}
                   total={Number(bookingData!.amount)}

                   width={medium || large || xlarge || small? "40%" : "100%"}
                   variant="solid"
                   onSuccess={() => {
                     verifyBookingPayment();
                   }}
                   onClose={() => {
                    setIsLoadingPayment(false)
                   }}
                   onPaymentStarted={()=> setIsLoadingPayment(true)}
                 />
                 </Center>
                 ):null
             }
             
           </VStack>
         
           </>
        }
       
        </LayoutView>
        
        {/* <LayoutView>
          {
               paymentData && paymentVerified !== "" ? (
                <VStack 
                p={4} 
                marginTop={6}
               w="full" 
               h={"320px"}
               alignItems="flex-start">
             <Text
               className="h6"
               fontSize={medium || large || xlarge ? "unset" : "18px !important"}
             >
               Total
             </Text>
             <HStack>
               <Text className="body1">Number of Offices</Text>
               <Spacer />
               <Text className="body1">{bookingData!.total_items}</Text>
             </HStack>
             <HStack>
               <Text className="body1">{bookingData.status === BOOKINGSTATUS.PAID ? "Amount Paid" : "Price"}</Text>
               <Spacer />
               <Text className="body1"><WHPrice priceText={bookingData!.amount}/></Text>
             </HStack>
   
             {
                 bookingData.status === BOOKINGSTATUS.PENDING  ? (
                   <Center w="full" justifyContent={medium || large || xlarge ? "start" : "center"}>
                   <WHPaystackButton
                   isLoadingPaymentData={isLoadingPayment}
                   reference={paymentData ? paymentData.payment_ref : ""}
                   hasBookedOffice={true}
                   email={user?.email ?? ""}
                   total={Number(bookingData.amount) * currencyConverter.rate}
                   width={medium || large || xlarge || small? "40%" : "100%"}
                   variant="solid"
                   onSuccess={() => {
                     verifyBookingPayment();
                   }}
                   onClose={() => {}}
                 />
                 </Center>
                 ):null
             }
             
           </VStack>

            ):null
          }

       
        </LayoutView> */}
        </>
     ) : null;
        
    
}

export default PayBooking

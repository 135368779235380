import { VStack, Text, useBreakpointValue } from '@chakra-ui/react';
import { t } from 'i18next';
import React from 'react';

interface WHRequestAnalyticsItem {
    title: string 
    value: number
}

const WHRequestAnalyticsItem = (props: WHRequestAnalyticsItem) => {
    const fontsize = useBreakpointValue({base: "h4", sm: "h4", md: "h3", lg: "h2", xl: "h1"})
    const fontWeight = useBreakpointValue({md: "500 !important", lg: "500 !important", xl: "500 !important"})
    const subtitleFontsize = useBreakpointValue({base: "subtitle2", sm: "h6", md: "h6", lg: "h6", xl: "h4"})

    const statItemColor = (title: string): string => {

        switch (title) {
            case "Pending":
                return "orange"
            
            case "Paid":
                return "green"
            case "Approved":
                return "primary.500"
            default:
                return "primary.400"
        }
    }
    
  return (
    <VStack 
    //opacity="0.86"
    color="white !important"
    bg={statItemColor(props.title)} w="full" h="full" padding={4} rounded="xl"
    justifyContent="center"
    alignItems="flex-start" spacing={"0px"}>
    
    <Text  color="white !important" className={subtitleFontsize}>{t(props.title)}</Text>
    <Text fontWeight={fontWeight} color="white !important" className={fontsize}>{props.value}</Text>
</VStack>
  )
};

export default WHRequestAnalyticsItem;

import { HStack, Image, VStack, Text, Stack, StackDirection, AspectRatio, Box, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { BreakPoint } from '../OfficeDetail/Models/Interfaces'

import styles from './WHHome.module.css'
import WifiIcon from '../../../../Assets/wifi.png'
import Printing from '../../../../Assets/printing.png'
import Photocopy from '../../../../Assets/photocopy.png'
import Event from '../../../../Assets/event.png'
import { useTranslation  } from "react-i18next";


interface BookAvailableSpacesProps {
    breakpoint: BreakPoint
}

const WHBookAvailableSpaces = ({breakpoint}: BookAvailableSpacesProps) => {
    const {t} = useTranslation()
    let titleFontsize = "18px"
    let descFontsize = "14px"
    let subtitleFontsize = titleFontsize
    let width = "full"

    let padding = 4

    if (breakpoint === BreakPoint.M) {
        width = "50%"
        padding = 8
        descFontsize = "14px"
    }
    if (breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL) {
        width = "50%"
        titleFontsize = "57px"
        descFontsize = "20px"
        subtitleFontsize = "40px"
        padding = 8
    }

    let spacing = padding

    //let amenityItemView = 
     let amenitiesImgPaths = [
         {
             title: t("high"),
             img: WifiIcon

             //img: "../images/wifi.png"
         },
         {
            title: t("Printing"),
            img:  Printing
        },
        {
            title: t("Photocopy"),
            img:   Photocopy
        },
        {
            title: t("Events"),
            img:  Event
        }
    
     ]
    let direction = breakpoint === BreakPoint.XS ? "column" : "row"

    return (
        <Stack
        bg="#FCE7ED"
            direction={direction as StackDirection}
            pt={12}
        >
            <Box display="grid" placeItems="center"  w={width} p={8} paddingX={12}>
                <Image
                    w={breakpoint === BreakPoint.XS ? "full" : "72%"}
                    rounded="xl"
                    src={"../images/spaces.png"}
                    objectFit="cover"
                />
            </Box>
            
            <VStack w={width} alignItems="flex-start"
            justifyContent="flex-end"
               //paddingX={{base: 4, md: 5, lg: 6}}
            textTransform="capitalize">
                <VStack 
                alignItems={breakpoint === BreakPoint.M || breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL ? "flex-start" : "unset"} 
                paddingX={breakpoint === BreakPoint.M || breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL ? "0px" : 12} 
                pb={6}>
                    <Text 
                     className={styles.title}
                     >
                         {t("bookavailable")}
                         {/* Book Available Spaces At Your Own Convenience */}
                         </Text>
                    <Text 
                       // className="body2"
                         
                        textTransform="initial"
                        className={styles.subtitle}
                    >
                        {t("bookavailableservice")}
{/*                         
                    We always try to make our customers happy. We provide all
                    Kinds of facilities. Your satisfaction is our main priority */}
                    </Text>
                </VStack>
                
                <VStack 
                    w="full"
                    bg="white"
                    paddingX={8}
                    paddingY={4}
                    alignItems="flex-start">
                <Text className={styles.title} >
                {t("letcreateworkspace")}
                  {/* Let us create a workspace 
                  solution for your team. */}
                </Text>
                 <Text  
                    fontSize={descFontsize}
                    //fontFamily="Nunito" 
                    className={styles.subtitle}

                 >
                      {t("Amenities")}
                     {/* Amenities */}
                     
                     </Text>
                 <SimpleGrid w="full" columns={2} gap={2}>

                     {
                         amenitiesImgPaths.map(item => (
                            <HStack key={item.title} spacing={4}>
                                <img 
                                alt={item.title.toLowerCase()}
                                width={item.title.toLowerCase().includes("high") ? "24px" : "24px"} 
                                height={item.title.toLowerCase().includes("high") ? "24px" : "24px"} 
                                src={item.img} />
                             {/* <Image 
                                   boxSize={8}
                                   src={item.img}
                                   objectFit="cover"
                               /> */}
                               <Text  fontSize={descFontsize} 
                                            className={styles.subtitle}
                                  //fontFamily="Nunito" 
                                  >
                                  {item.title}
                               </Text>
                            </HStack>
                         ))
                     }
                    
                 </SimpleGrid>
                </VStack>
            </VStack>
        </Stack>
    )
}

export default WHBookAvailableSpaces

 import { add, addDays, eachDayOfInterval, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday } from "date-fns"
import { Queries } from "../Components/Screens/WHApp/SearchResuts/SearchResults"
import { AmenityDetails, BookingDuration, CartItem, DurationCategory, ListingCategory, QueryItem, WorkhopperSupportedLanguage } from "../Models/Interfaces"
  

declare global {
  interface Window {
    Tawk_API: any
    Tawk_LoadStart: any
  }
}

export const getPrice = (item: CartItem): number =>{

  if (!item.listing) return 0

  // if (item.listing.title.includes("ly")) {
  //   debugger
  // }

  const found = item.listing.booking_duration.find(durationItem => getDurationCategory(durationItem.minimum_duration).includes(item.bookingDetail.durationType.trim()))
  if (!found) return 0

  return Number(found.amount)
}

export const getItemTotalPrice = (item: CartItem): number => {
  return getPrice(item) * item.bookingDetail.duration
}

export function name(params: string) {
    return
}

export const getDurationTitle = (durationName: string): string=>{

  if (durationName.trim().toLowerCase().includes("hour")) {
    return "hour"
  }
   
  if (durationName.trim().toLowerCase().includes("da")) {
    return "day"
  }

  if (durationName.trim().toLowerCase().includes("month")) {
    return "month"
  }
  
  return "year"

}

export const getDurationCategory = (durationType: string): DurationCategory=>{

  if (durationType.trim().toLowerCase().includes("hour")) {
    return DurationCategory.HOUR
  }
   
  if (durationType.trim().toLowerCase().includes("da")) {
    return DurationCategory.DAY
  }

  if (durationType.trim().toLowerCase().includes("month")) {
    return DurationCategory.MONTH
  }
  
  return DurationCategory.YEAR

}

export const getQueryItems = (queries: Queries): QueryItem[] => {

  let queryItems: QueryItem[] = [] 
  Object.entries(queries).forEach(([key, value]) => {
      if (value) {
          // if (key.includes("amenity") && value as string[]) {
          if (Array.isArray(value)) {
              (value as string[]).forEach((item) => queryItems.push({name: key, value: item}))
          } else {
              queryItems.push({name: key, value})
          }
      }
  })

  return queryItems
}



export const isIncluded = (amenityId: string, selectedAmenities: AmenityDetails[]): boolean => {
      
    let found = selectedAmenities.find((item, _)=> item.id === amenityId) 
        return found ? true : false
   }

   export const isItemIncluded = <T>(selectedItemId: string, items: T[]): boolean => {
      
    let found = items.find((item, _)=> (item as any).id === selectedItemId) 
        return found ? true : false
   }

   export function mapOfficeCategory(isId: boolean, categoryItems: ListingCategory[], id?: string, category?: string): string {
          
    let result = ""

    if (isId) {
        let entryIdx = categoryItems.findIndex((item, idx)=>(
            item.title.includes(category!)
          ))
          result = categoryItems[entryIdx].id

    } else {
           let entryIdx = categoryItems.findIndex((item, idx)=>(
            item.id.includes(id!)
          ))
          result = categoryItems[entryIdx].title
    }
    
    return result
}

export const refreshTokenSetup = (res: any) => {
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
   // console.log('newAuthRes:', newAuthRes);
    // saveUserToken(newAuthRes.access_token);  <-- save new token
    localStorage.setItem('authToken', newAuthRes.id_token);

    // Setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming);
  };

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};

export function validateEmail(mail: string) 
{
 //if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
 if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail))
  {
    return (true)
  }
    
    return (false)
}

export const getLeastPrice = (items: BookingDuration[]): BookingDuration =>  {
  let sorted = items.sort((a, b) => Number(a.amount) - Number(b.amount))
  return sorted[0]
}

// returns total number of close days within days interval (saturday = 6; sunday = 0)
export const getNumClosedDaysOfInterval = (closedDays: number[], daysOfInterval: Date[]): number => {
  
  let totalClosedDays = 0
  // it should select a closed day
  for (let dayNum of closedDays) {
    // if closed day is a sunday
    if (dayNum === 0) {
       // count all sundays within the interval
       totalClosedDays += daysOfInterval.reduce((prev, curr): number =>{
          return prev + ( isSunday(curr) ? 1 : 0)
          
        }, 0)
    } else if (dayNum === 6) {
      totalClosedDays += daysOfInterval.reduce((prev, curr): number =>{
        return prev + ( isSaturday(curr) ? 1 : 0 )
       
      }, 0)
    } else {

    }
  }
  return totalClosedDays
}



//// Returns business days skipping any closed days
// it should return regular day interval if no days are closed
/**
 * 
 * @param openedDays days the office is opened
 * @param closedDays days thee office is closed
 * @param selectedDay day selected by user
 * @param duration number of days for stay
 * @returns // array of dates
 */
export const addOfficeBusinessDays = (
  openedDays: number[],
  closedDays: number[],
  selectedDay: Date,
  duration: number
): Date[] => {

  const selectedInterval = eachDayOfInterval({
    start: selectedDay,
    end: add(selectedDay, {
      days: duration - 1,
    }),
  });

 
  if (closedDays.length === 0 || duration === 1) {return selectedInterval}
   

  const numClosedDays = getNumClosedDaysOfInterval(
    closedDays,
    selectedInterval
  );

  //// exclude closedays count from interval
  // add as many business days as there are closed days
  for (let i = 0; i < numClosedDays; ++i) {
    // get next day
    const lastDay = selectedInterval[selectedInterval.length - 1]
    
    let nextDay = add(lastDay, {
      days: 1
    })

    
    // get correspoindinng day num 
    let dayNum = -1
    
    if (isMonday(nextDay)) {
      dayNum = 1
    } else if (isTuesday(nextDay)) {
      dayNum = 2
    } else if (isWednesday(nextDay)) {
      dayNum = 3
    } else if (isThursday(nextDay)) {
      dayNum = 4
    } else if (isFriday(nextDay)) {
      dayNum = 5
    } else if (isSaturday(nextDay)) {
   
      dayNum = 6
    } else {
      dayNum = 0
    }
    
     
    while (!openedDays.includes(dayNum)) {
       
       nextDay = add(nextDay, {
        days: 1
      })

      if (isMonday(nextDay)) {
        dayNum = 1
      } else if (isTuesday(nextDay)) {
        dayNum = 2
      } else if (isWednesday(nextDay)) {
        dayNum = 3
      } else if (isThursday(nextDay)) {
        dayNum = 4
      } else if (isFriday(nextDay)) {
        dayNum = 5
      } else if (isSaturday(nextDay)) {
     
        dayNum = 6
      } else {
        dayNum = 0
      }

    }

     // add day to selected interval 

     selectedInterval.push(nextDay)
    
    
    
   }

   //console.log(numClosedDays);

   return selectedInterval;
}

export const isNumericInput = (inputValue: string): boolean => {

  if (inputValue === '') return false

  const guests = Number(inputValue)

  if (isNaN(guests)) return false

  return true
}

export const  tawkToLoadScripts = ()=> {
   

      if (!window) {
        throw new Error('DOM is unavailable')
    }
    window.Tawk_API = window.Tawk_API || {}

     window.Tawk_LoadStart = new Date()

     const tawk = document.getElementById('tawkId')
    if (tawk) {
        // Prevent TawkTo to create root script if it already exists
        return window.Tawk_API
    }

    
    const script = document.createElement('script')
    script.id = 'tawkId'
    script.async = true
    script.src = `https://embed.tawk.to/61b8a4a3c82c976b71c16103/1fmsk68oh`
    script.charset = 'UTF-8'
    script.setAttribute('crossorigin', '*')

    const first_script_tag = document.getElementsByTagName('script')[0]
    if (!first_script_tag || !first_script_tag.parentNode) {
        throw new Error('DOM is unavailable')
    }

    first_script_tag.parentNode.insertBefore(script, first_script_tag)

 
  
}

export const truncateTo = (value: number, places: number): string => {
 let result = value.toString().indexOf(".") > -1 ? value.toString().slice(0, (value.toString().indexOf("."))+(places+1)) : value.toString()
 return Number(result) > 0 ? result : ""
}

export const autoCapitalize = (word: string): string => {
  if (word.length === 0) { return word  }
  
  const substring = word.slice(1)
  return word[0].toUpperCase() + substring
} 

export const formatPrice = (priceVal: number, currencyCode: string, locale: string): string => {
   const formattedPrice = Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      maximumFractionDigits: 2
  }).format(priceVal)
  
  return formattedPrice
}







 


   
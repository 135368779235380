import { Text, Box, Button,  FormControl, Icon, Input, InputGroup, InputLeftElement,   VStack, HStack, Grid, GridItem, Stack } from '@chakra-ui/react'
import React, {FC,  ReactChild,    useCallback, useState} from 'react'
import {   MdHome, MdPeopleOutline, MdPublic, MdSearch } from 'react-icons/md'
 
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
 
import {ListingLink} from '../Links/Links';
import { appDataManager } from '../../Screens/WHApp/Models/WHAppDataManager';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

 

interface SearchMenuProps {
  filterBtns?: Array<ReactChild>,
  actionText: String,
  categoryId: string
  
}

const SearchMenu: FC<SearchMenuProps> = ({categoryId, filterBtns, actionText}: SearchMenuProps) => {
    
    const [location, setLocation] = useState<string | undefined>(undefined)
    const [noOfSeats, setNoOfSeats] = useState<string | undefined>(undefined)
    const history = useHistory()
    const {t} = useTranslation()
     
    let categories = appDataManager.getCategoriesData("list-cat")
    let entry = categories.find((categoryItem, idx) => (
        categoryItem.id === categoryId
    )) 
    let showCapacity = entry?.title.toLocaleLowerCase().trim().includes("virtual") ? false : true 

    const onLocationInputChange = (e: any)=> {setLocation(e.target.value)}
    const onNoSeatsChange = (e: any)=> {setNoOfSeats(e.target.value)}

      
    const handleSubmit = useCallback((e: React.KeyboardEvent)=>{
         if (e.key === "Enter") {
          history.push( appDataManager.searchURL(categoryId, location, noOfSeats))
         
        }
    }, [categoryId, location, noOfSeats])
 
    return (
      <VStack  width={{base: "full", md: "full", lg: "full"}} 
      // marginX="auto" 
      rounded="md" color="white" bg="primary.500" padding={{base: 4, md: 12, lg: 12}} spacing={{base: 6, md: 8}} alignItems="left">
         {
            filterBtns && 
              <Stack spacing={{base: 2, md: 8}} direction={{base: "column", md: "row"}} alignItems="left">
              {filterBtns? filterBtns : null}
            </Stack>
         }
        <Box onKeyPress={handleSubmit}>
          <Grid
            templateRows={{ base: "repeat(2, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)"}}
            templateColumns={{base: "repeat(2, 1fr)", md: "repeat(8, 1fr)" }}
            gap={2}
          >
            <GridItem colSpan={{base: 2, md: showCapacity ? 2 : 6}} mb={{base: 2, md: "0px"}}>
              <FormControl isRequired id="text">
                <InputGroup>
                  <InputLeftElement
                    h="48px"
                    pointerEvents="none"
                    children={<Icon as={MdSearch} w={6} h={6} color="gray.300" />}
                  />
                  <Input
                   onChange={onLocationInputChange}
                   focusBorderColor="primary.500"
                    color="black"
                    bg="white"
                    className="body1"
                    size="lg"
                    fontSize="md"
                    type="text"
                    placeholder={t("searchLocation")} //"Search Location or City"
                  />
                </InputGroup>
              </FormControl>
            </GridItem>
            {
                showCapacity ? (
                  <GridItem colSpan={{base: 2, md: 2}}>
                  <FormControl isRequired id="text">
                      <InputGroup>
                        <InputLeftElement
                          h="48px"
                          pointerEvents="none"
                         />
                        <Input
                           focusBorderColor="primary.500"
                          onChange={onNoSeatsChange}
                          color="black"
                          bg="white"
                          className="body1"
                          size="lg"
                          fontSize="md"
                          type="text"
                          placeholder={t("noOfSeats")}//"Number of Seats"
                        />
                      </InputGroup>
                    </FormControl>
                   </GridItem>
                ): null
            }
           
               
           <GridItem mt={{base: 4, md: "0px"}} colSpan={{base: 2, md: 1}}>
              {/* <ListingLink to={`/search?city=${location}&capacity=${noOfSeats}&category=${categoryId}`}> */}
              <ListingLink to={appDataManager.searchURL(categoryId, location, noOfSeats)}>

                 <Button w="full" h="48px" className="button" color="onPrimary" colorScheme="secondary" >{actionText.toUpperCase()}</Button>
              </ListingLink>
           </GridItem>
          </Grid>
        </Box>
        <Stack w={{base: "full", md: "90%", lg: "80%", xl: "80%"}} 
          direction={{base: "column", md: "row", lg: "row", xl: "row"}}
          spacing={{base: 6, md: 8, lg: 8}}
           pr={{base: "10%", md: "0%"}}  >
          <HStack 
             >
            <MdPublic size="24px" />
            <Text className="subtitle2">240 {t("citiesInAfrica")}</Text>
          </HStack>
          <HStack 
               >
            <MdHome size="24px" />
            <Text className="subtitle2">20,000+ {t("coworkSpaces")}</Text>
          </HStack>
          <HStack 
               >
            <MdPeopleOutline size="24px" />
            <Text className="subtitle2">2 {t("millionUsers")}</Text>
          </HStack>
        </Stack>
      </VStack>
    );
}

export default SearchMenu

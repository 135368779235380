import { Box, GridItem, HStack, Text, useBreakpointValue, VStack, Image } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import {  RequestListLink } from '../../../SupportingViews/Links/Links';
import WHLayoutGrid from '../../../SupportingViews/WHLayoutGrid/WHLayoutGrid';
import { BreakPoint } from '../../WHApp/OfficeDetail/Models/Interfaces';
import RequestUserDetail from './RequestUserDetail/RequestUserDetail';
 
import homeImg1 from "../../../../Assets/workspace4.jpg";
import Overview from './Overview/Overview';
import { dataManager } from '../../WHApp/AddASpace/Models/ListSpaceDataManager';
import AllRequests from './AllRequests/AllRequests';
import WHManageRequestsProvider, { CounterContext, SetCounterContext, SetRequestsContext } from './WHManageRequestsProvider';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { format } from 'date-fns';
import { CategoriesContext } from '../../../AppProvider/AppProvider';
import { ResultType } from '../../../../Models/Interfaces';
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner';
import OfflineView from '../../../SupportingViews/OfflineView';


const BookedItemView = ()=> {

    return (
         <HStack w="full">

             <Image rounded="md" boxSize={"60px"}
                src={homeImg1}
             />
             <VStack alignItems="flex-start" w="full">
                <Text className="subtitle2">Beehive</Text>
                <Text className="caption">Private Office</Text>
             </VStack>
         </HStack>

    )
}

const DesktopView =() => {

    return (
        <>
            <div>Grid lists</div>
        </>
    )
}



const MobileView = () => {

   

    

    return (
        <>
            {/* <GridItem colSpan={4} h="100vh"> */}
           <Switch>
               
                  <Route 
                    exact
                    path="/dashboard/manage+requests/:bizId/:requestType/list"
                     render={()=>{
                        return  <AllRequests/>
                    }}
                />
                 <Route 
                   exact
                    path="/dashboard/manage+requests/:bizId/:requestType/:requestItemId/detail"
                    render={()=>{
                        return <RequestUserDetail />
                    }}
                />
                  <Route
                
                    path="/dashboard/manage+requests/:bizId/:requestType/overview"
                    render={()=>{
                        return <Overview />
                    }}
                />
                {/* <Route path="*">
                    <Redirect
                    to={dashboardManager.getSectionURL(
                        DashboardSectionName.WKS,
                        URLDir.curr,
                        "me"
                    )}
                    />
              </Route> */}
            </Switch> 
           {/* </GridItem>    */}
        </>
    )
}

const WHManageRequests = () => {
    
   
    const breakpoint = useBreakpointValue({base: BreakPoint.XS, sm: BreakPoint.S, 
        md: BreakPoint.M, lg: BreakPoint.L, xl: BreakPoint.XL})

        
       
        

    return (
          
      <>
         <Box w="full" pt={12} marginBottom={"20vh"}>
          <WHLayoutGrid height="auto">
           
              <MobileView />
            
          </WHLayoutGrid>
        </Box>
       
      </>
    );
}

export default WHManageRequests

/**
 * Abstract: A view that shows a carousel of offices
 *  
 */

import React, { FC } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {AspectRatio, Box, Image, Text, useMediaQuery, VStack} from '@chakra-ui/react'
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

// import "./styles.css";


// import Swiper core and required modules
import SwiperCore, {
    Autoplay,
  Pagination
} from 'swiper/core';


import styles from "./BannerView.module.css"
import { BreakPoint } from '../../Screens/WHApp/OfficeDetail/Models/Interfaces';
import { ListingLink, OfficeDetailLink } from '../Links/Links';
import { RecommendedItem } from '../../../Models/Interfaces';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

export interface BannerViewProps {
    items: RecommendedItem[]
    title?: string 
    subtitle?: string
    breakpoint?: BreakPoint 
}


const SlidersView: FC<BannerViewProps> = ({items}: BannerViewProps) => {

    //const imgHeight = {base: "35vh", md: "50vh", lg: "67vh"}
    // const imgHeight = {base: "411px", md: "512px", lg: "1072px"}
    let moreItems = items.slice()//.concat(items.slice())
    const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])
    let ratio =  4 / 3
    
    let slidesPerView = 1.05
    if (isMediumScrn) {
        slidesPerView = 1.1
    } 
    if (isLargeScrn) {
        slidesPerView = 1.85
    }

    const [xsmall, small, medium, large, xl] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 1240px) and (max-width: 1439px)",
      "(min-width: 1440px)",
    ]);


    return (
      <Box as="section"  
            // pt={{base: 4, md: 12, lg: 12}}
        >
        <Swiper
          slidesPerView={slidesPerView}
          onSlideChange={() => {}}
          spaceBetween={isMediumScrn || isLargeScrn ? 32 : 16}
          freeMode
          // centeredSlides
          speed={1000}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          className={styles.mySwiper}
        >
          {moreItems.map((item, idx) => (
            <SwiperSlide key={idx}>
               <OfficeDetailLink to={`/office/${item.id}/detail`}> 
              <Box className={styles.slide}>
                <AspectRatio ratio={ratio}>
                  <Image w="full" rounded="md" src={item.imageURL} />
                </AspectRatio>
                <Box
                
                  borderBottomLeftRadius="md"
                  borderBottomRightRadius="md"
                  bg="blackAlpha.500"
                  padding="16px"
                  width="100%"
                  minH={20}//{{ base: 20 }}
                 // height={{ base: 20 }}
                  position="absolute"
                  bottom="0px"
                  right="0px"
                  zIndex="100px"
                >
                  <Text  textTransform="capitalize" 
                 // fontSize="18px !important"
                  color="onPrimary"  className={xsmall || small ? "body1" : "h6"}>
                    {item.listingTitle ?? ""}
                  </Text>
                  <Text
                       fontSize={xsmall || small ? "initial" : "16px !important"}
                       fontWeight="medium !important"
                       textTransform="initial"   
                       color="onPrimary"  className={xsmall || small ? "body2" : "h6"}>
                    {item.spotlightTitle ?? ""}
                  </Text>
                    <Text color="onPrimary" className="body2">
                      Learn More
                    </Text>
                  
                 
                </Box>
              </Box>
              </OfficeDetailLink>
            </SwiperSlide>
          ))}

          
        </Swiper>
      </Box>
    );
}



const BannerView: FC<BannerViewProps> = ({items, title, subtitle}: BannerViewProps)=>{
    return (
              <>
                   <VStack className={styles.mySwiper} spacing={"0px"} mb={{base: 4, md: 5, lg: 4}} alignItems="flex-start">
                        <Text  className={`h5`}>{title}</Text>
                        <Text className={`body2`}>{subtitle}</Text>
                   </VStack>
                  <SlidersView items={items} />
              </>
          
    
    );
}

export default BannerView

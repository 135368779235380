/**
 * Abstract: A view that collects information to setup a personal
 * account if user is not logged in
 */

import { useMediaQuery, VStack, Text, FormControl, FormLabel, Input, Box, Stack, InputGroup, InputLeftAddon, Spacer, Select, Divider, FormErrorMessage, HStack, Button } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React,  {  useEffect, useState, memo, useContext } from 'react'
import { MdFlag, MdLock, MdMail,  MdPerson, MdPhone } from 'react-icons/md'
import AddASpaceLayout from '../../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'

 
//import countryItems from "../../../../../node_modules/world_countries_lists/data/en/countries.json"


import styles from "./Account.module.css"
import { AccountProps } from '../Models/Interfaces'
import { yupSpaceValidation } from '../Schema/YupSpaceValidation'
import { useRouteMatch } from 'react-router-dom'
import {  Country, ResultType } from '../../../../../Models/Interfaces'
import { signUpDataManager } from '../../SignUp/SignUpDataManager'
import { CountriesContext } from '../../../../AppProvider/AppProvider'
import WHSpinner from '../../../../SupportingViews/WHSpinner/WHSpinner'
import OfflineView from '../../../../SupportingViews/OfflineView'
import WHShowPassword from '../../../../SupportingViews/WHShowPassword/WHShowPassword'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next';
 


const Account = ({formRef, formValues, dataManager, updateFormSubmissionStatus, actionAfterSubmit, onLoad, onChange, onCountriesChange}: AccountProps) => {

    const {i18n} = useTranslation()
    const fieldHeight    = 10
    const [countryCode, setCountryCode] = useState("234")
    const [countryItems, setCountryItems] = useState<Country[]>([])
    const [error, setError] = useState<Error | null>(null)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const {t} = useTranslation()

    const [xsmall, small, small2, medium, large] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 1240px) and (max-width: 1439px)",
      "(min-width: 1440px)",
    ]);
    
    const loadData = async ()=> {
     
      setLoading(true)
      let result = await signUpDataManager.fetchCountries()
      if (result.type === ResultType.Success) {
            //console.dir(result.value)
            setCountryItems(result.value)
            if (onCountriesChange) {
              onCountriesChange(result.value)
            }
           
            setError(null)
           
           
       } else {
           setError(new Error("Couldn't load countries data"))
           setCountryItems([])
           if (onCountriesChange) {
            onCountriesChange([])
          }
       }
       setLoading(false)
    }

    useEffect(() => {
      loadData()
      // let didCancel = false
      // if (!didCancel) {
      //   loadData()
      // }
      // return ()=>{
      //   didCancel = true
      // }
    }, [])
                          
 
    const countryItemViews = countryItems.map((item, idx)=> 
                                <option key={item.id}>
                                  {item.name}
                                </option>)
          // countryItemViews.unshift(
          //   <option key={"empty"}>
          //   {""}
          // </option>
          // )    
        
                                 
    
    const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])
    let questionFontSize =  "h5"
    if (isMediumScrn) {
        questionFontSize = "h4"
    } else if(isLargeScrn) {
        questionFontSize = "h3"
    }

    
   
    
    return (
      < >
        <VStack
          //
          //pb={8}
          bg="primary.500"
          w={"full"}
          minH={{ base: "30%", lg: "100%" }}
          justifyContent={{ base: "center", md: "center" }}
          alignItems={{ md: "flex-start" }}
          h="96px"
        >
          <Text
            pl={{ base: 6, md: 8 }}
            w={{ base: "full", md: "75%", lg: "80%" }}
            color="white !important"
            className={questionFontSize}
          >
            {t('introPlease')}  
          </Text>
        </VStack>
        <Box
          bg="white"
          position="relative"
          w="full"
          h={{ base: "70%", md: "100%" }}
          borderTopRightRadius={{ base: 12, md: "unset" }}
          borderTopLeftRadius={{ base: 12, md: "unset" }}
        >
           
          <Formik
             
             //initialValues={{ fname: "", lname: "", email: "", password: "", country: "", phone: ""}}
              // initialStatus={{ isValidating: false }}
            initialValues={formValues}
            validationSchema={yupSpaceValidation}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
               
                //debugger
                
                let entryIdx = countryItems.findIndex((item, idx)=>(
                  item.name.includes(values.country)
                ))
                
                const data = {...values, country: countryItems[entryIdx].id}
                dataManager.setAcctData(data)
                actionAfterSubmit(data)
               // updateFormSubmissionStatus(true)
               
            }}
            innerRef={formRef}
          >
            {(props) => {
                 
               return (
                 <Form className={styles.form}>
                   <VStack alignItems="flex-start">
                     <Text
                       padding={{ base: 6, lg: 8 }}
                       // paddingY={{ base: 6, lg: 8 }}
                       w={{ base: "full", md: "75%", lg: "80%" }}
                       className="h6"
                     >
                       {t('introInfo')}
                     </Text>
                     <Divider />
                   </VStack>
                   <AddASpaceLayout>
                     <Stack direction={{ base: "column", lg: "row" }} w="full">
                       <Field name="fname">
                         {({ field, form }: any) => {
                           return (
                             <FormControl
                               className="caption"
                               isRequired
                               htmlFor="fname"
                               isInvalid={
                                 form.errors.fname
                               }
                             >
                               <FormLabel fontWeight={500}>{t('firstname')}</FormLabel>
                               <InputGroup>
                                 <InputLeftAddon
                                   bg="primary.200"
                                   children={<MdPerson color="#fff" />}
                                 />
                                 <Input
                                   {...field}
                                   id="fname"
                                   name="fname"
                                   type="text"
                                   h={fieldHeight}
                                   onChange={props.handleChange}
                                   //onBlur={props.handleBlur}
                                 />
                               </InputGroup>
                               <FormErrorMessage>
                                 {i18n.language == 'en' ? form.errors.fname : t("firstnameErr")}
                               </FormErrorMessage>
                             </FormControl>
                           );
                         }}
                       </Field>
                       <Spacer />
                       <Field name="lname">
                         {({ field, form }: any) => {
                           return (
                             <FormControl
                               className="caption"
                               isRequired
                               htmlFor="last name"
                               isInvalid={
                                 form.errors.lname
                               }
                             >
                               <FormLabel fontWeight={500}>{t('lastname')}</FormLabel>
                               <InputGroup>
                                 <InputLeftAddon
                                   pointerEvents="none"
                                   bg="primary.200"
                                   children={<MdPerson color="#fff" />}
                                 />
                                 <Input
                                   {...field}
                                   id="lname"
                                   name="lname"
                                   type="text"
                                   h={fieldHeight}
                                   onChange={props.handleChange}
                                   //onBlur={props.handleBlur}
                                 />
                               </InputGroup>
                               <FormErrorMessage>
                               {i18n.language == 'en' ? form.errors.lname : t("lastnameErr")}
                               </FormErrorMessage>
                             </FormControl>
                           );
                         }}
                       </Field>
                     </Stack>
                     <Stack direction={{ base: "column", lg: "row" }} w="full">
                       <Field name="email">
                         {({ field, form }: any) => {
                           return (
                             <FormControl
                               className="caption"
                               isRequired
                               htmlFor="email"
                               isInvalid={
                                 form.errors.email
                               }
                             >
                               <FormLabel fontWeight={500} fontSize={"15px"}>
                               {t('emails')}
                               </FormLabel>
                               <InputGroup>
                                 <InputLeftAddon
                                   pointerEvents="none"
                                   bg="primary.200"
                                   children={<MdMail color="#fff" />}
                                 />
                                 <Input
                                   {...field}
                                   id="email"
                                   name="email"
                                   type="email"
                                   h={fieldHeight}
                                   onChange={props.handleChange}
                                   //onBlur={props.handleBlur}
                                 />
                               </InputGroup>
                               <FormErrorMessage>
                               {i18n.language == 'en' ? form.errors.email : 
                                form.errors.email === 'Please enter your email' ? t('emailErr'): t('emailErrLong')}
                               </FormErrorMessage>
                             </FormControl>
                           );
                         }}
                       </Field>
                       <Spacer />
                       <Field name="password">
                         {({ field, form }: any) => {
                           return (
                             <FormControl
                               className="caption"
                               isRequired
                               id="password"
                               isInvalid={
                                 form.errors.password
                               }
                             >
                               <FormLabel
                                 id="password"
                                 name="password"
                                 fontWeight={500}
                                 fontSize={"15px"}
                               >
                                 {t('password')}
                               </FormLabel>
                               <VStack justifyContent="flex-start" w="full">
                               <InputGroup>
                                 <InputLeftAddon
                                   pointerEvents="none"
                                   bg="primary.200"
                                   children={<MdLock color="#fff" />}
                                 />
                                    <Input
                                      type={showPassword ? "text" : "password"}
                                      onChange={props.handleChange}
                                      {...field}
                                      //onBlur={props.handleBlur}
                                      h={fieldHeight}
                                    />
                                   
                                
                               </InputGroup>
                               <WHShowPassword 
                                      showPassword={showPassword}
                                      onShowPasswordChange={(e: any)=> setShowPassword(e.target.checked)}
                                    />
                               </VStack>

                               <FormErrorMessage>
                                 {i18n.language == 'en' ? form.errors.password : 
                                  form.errors.password === 'Please Enter your password' ? t('passwordErrEmpty'): t('passwordErrLong')}
                               </FormErrorMessage>
                             </FormControl>
                           );
                         }}
                       </Field>
                     </Stack>
                     {loading ? (
                       <WHSpinner />
                     ) : error ? (
                       <OfflineView pt="0px" msg={error.message} onRetry={()=>{
                         loadData()
                       }}  />
                     ): (
                      <Field name="country">
                      {({ field, form }: any) => {
                        return (
                          <FormControl
                            className="caption"
                            isRequired
                            htmlFor="country"
                            isInvalid={
                              form.errors.country
                            }
                          >
                            <FormLabel fontWeight={500} fontSize={"15px"}>
                            {t('country')}
                            </FormLabel>
                            <InputGroup>
                              <InputLeftAddon
                                pointerEvents="none"
                                bg="primary.200"
                                children={<MdFlag color="#fff" />}
                              />

                              <Select
                                {...field}
                                title="country"
                                id="country"
                                name="country"
                                onChange={props.handleChange}
                                placeholder={t("chooseCounty")}
                              >
                                {countryItemViews}
                              </Select>
                            </InputGroup>
                            <FormErrorMessage>
                            {i18n.language == 'en' ? form.errors.country : "Veuillez sélectionner un pays"}
                            </FormErrorMessage>
                          </FormControl>
                        )
                      }}
                      </Field>
                     )}
                  
                     <Field name="phone">
                       {({ field, form }: any) => {
                         return (
                           <FormControl
                             htmlFor="phone"
                             className="caption"
                            // isRequired
                             isInvalid={form.errors.phone}
                           >
                             <FormLabel fontWeight={500} fontSize={"15px"}>
                               {t("phone")}
                             </FormLabel>
                             <InputGroup>
                             <InputLeftAddon
                                   pointerEvents="none"
                                   bg="primary.200"
                                   children={<MdPhone color="#fff" />}
                                 />
                               {/* <InputLeftAddon
                                 pointerEvents="none"
                                 bg="primary.200"
                                 children={
                                   <Text
                                     className="subtitle1"
                                     fontWeight="medium"
                                     color="#fff"
                                   >{`+${countryCode}`}</Text>
                                 }
                               /> */}
                               <Input
                                 {...field}
                                 id="phone"
                                 name="phone"
                                 type="tel"
                                 onChange={props.handleChange}
                                 h={fieldHeight}
                               />
                             </InputGroup>
                           </FormControl>
                         );
                       }}
                     </Field>
                     {/* <HStack w="full">
                       <Spacer/>
                       <Button
                        isLoading={props.isValidating}
                        colorScheme="primary"
                        onClick={()=>{props.handleSubmit()}}
                        //onClick={()=>{props.validateForm().then(()=>{console.log("validated")})}}
                          >Looks Good?
                        </Button>
                    </HStack> */}
                   </AddASpaceLayout>
                 </Form>
               );
            }}
          </Formik>
        </Box>
      </>
    );
}

export default Account

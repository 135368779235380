import { VStack, Text, Box, Divider, FormControl, FormErrorMessage, FormLabel, HStack, Input, InputGroup, InputLeftAddon, Spacer, Switch, useMediaQuery, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Ref, useRef, useState } from 'react';
import { MdPerson, MdMail, MdLink } from 'react-icons/md';
import { RiFacebookFill, RiTwitterFill, RiLinkedinFill } from 'react-icons/ri';
//import styles from '../../../theme/styles';
import { BizAcctForm } from '../../Screens/WHApp/AddASpace/Models/Interfaces';
import { yupBizAcctValidation } from '../../Screens/WHApp/AddASpace/Schema/YupSpaceValidation';
import AddASpaceLayout from '../AddASpaceLayout/AddASpaceLayout';
import styles from "../../Screens/WHApp/AddASpace/AddASpace.module.css"
import TandC from '../TandC/TandC';
import { t } from 'i18next'


interface WHBusinessFormProps {
    bizAcctFormRef: Ref<FormikProps<BizAcctForm>>
}

const WHBusinessForm = ({bizAcctFormRef}: WHBusinessFormProps) => {

  const [bizAcctFormValues, setBizAcctFormValues] = useState<BizAcctForm>({ bemail: "", bname: "", websiteURL: "", fbURL: "", instagramURL: "", twitterURL: "", terms: false })
 // let bizAcctFormRef = useRef<FormikProps<BizAcctForm>>(null)
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [xsmall, small, medium, large, xlarge] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 905px) and (max-width: 1439px)",
    "(min-width: 1440px)"
  ]);
  const fieldHeight = 10  
  const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])

  let questionFontSize =  "h5"
  if (isMediumScrn) {
      questionFontSize = "h4"
  } else if(isLargeScrn) {
      questionFontSize = "h3"
  }
  return (
    <>
    <VStack
      //
      //pb={8}
      bg="primary.500"
      w={"full"}
      minH={{ base: "30%", lg: "100%" }}
      justifyContent={{ base: "center", md: "center" }}
      alignItems={{ md: "flex-start" }}
      h="96px"
    >
      <Text
        pl={{ base: 6, md: 8 }}
        w={{ base: "full", md: "75%", lg: "80%" }}
        color="white !important"
        className={questionFontSize}
      >
        {t("bizPlease")}
      </Text>
    </VStack>
    <Box
      bg="white"
      position="relative"
      w="full"
      h={{ base: "70%", md: "100%" }}
      borderTopRightRadius={{ base: 12, md: "unset" }}
      borderTopLeftRadius={{ base: 12, md: "unset" }}
    >
      <Formik
        innerRef={bizAcctFormRef}
        initialValues={bizAcctFormValues}
        validateOnBlur={false}
        validateOnChange={false}
        //initialValues={{ bemail: "", bname: "", websiteURL: "", fbURL: "", instagramURL: "", twitterURL: "" }}
        validationSchema={yupBizAcctValidation}
         enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          
           

           
        }}
      >
        {(props) => {
          console.dir(props.errors)
          return (
            <Form className={styles.form}>
              <VStack alignItems="flex-start">
                <Text
                  padding={{ base: 6, lg: 8 }}
                  // paddingY={{ base: 6, lg: 8 }}
                  w={{ base: "full", md: "75%", lg: "80%" }}
                  className="h6"
                >
                  {t('introInfo')}
                </Text>
                <Divider />
              </VStack>
              <AddASpaceLayout>
                   <Field name="bname">
                    {({ field, form }: any) => {

                      return (
                        <FormControl
                          className="caption"
                          isRequired
                          htmlFor="bname"
                          isInvalid={form.errors.bname}

                        >
                          <FormLabel fontWeight={500}>
                            Business Name
                          </FormLabel>
                          <InputGroup>
                            <InputLeftAddon
                              bg="primary.200"
                              children={<MdPerson color="#fff" />}
                            />
                            <Input
                              {...field}
                              id="bname"
                              name="bname"
                              type="text"
                              h={fieldHeight}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                          </InputGroup>
                          <FormErrorMessage>{form.errors.bname}</FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>
                  <Field name="bemail">
                    {({ field, form }: any) => {
                      return (
                        <FormControl
                          className="caption"
                          isRequired
                          htmlFor="bemail"
                          isInvalid={form.errors.bemail}
                        >
                          <FormLabel fontWeight={500} fontSize={"15px"}>
                            Business Email
                          </FormLabel>
                          <InputGroup>
                            <InputLeftAddon
                              pointerEvents="none"
                              bg="primary.200"
                              children={<MdMail color="#fff" />}
                            />
                            <Input
                              {...field}
                              id="bemail"
                              name="bemail"
                              type="bemail"
                              h={fieldHeight}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                            />
                          </InputGroup>
                          <FormErrorMessage>{form.errors.bemail}</FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>
                  <Field
           
           name="terms">
           {({field, form}: any) => {
              return (
               <FormControl
               isInvalid={form.errors.terms}
               isRequired
               display="flex"
               flexDirection="column"
               alignItems="flex-start"
              // justifyContent="center"
             >
               <FormLabel htmlFor="terms" mb="0">
                  I agree to the &nbsp;
                 <a 
                  style={{
                    textDecorationLine: "underline"
                  }}
                  onClick={onOpen}            
                  >
                 terms and conditions
                 </a>
               </FormLabel>
               <Switch 
                  onChange={props.handleChange}
                  isChecked={props.values.terms}
                  size="sm" name="terms" 
                 // {...field}   
                  />
               <FormErrorMessage>{form.errors.terms}</FormErrorMessage>

             </FormControl>
              )
           }}
           </Field>
                  <Field name="websiteURL">
                      {({ field, form }: any) => {
                        return (
                          <FormControl
                            className="caption"
                            htmlFor="url"
                            isInvalid={form.errors.websiteURL}

                          >
                            <FormLabel fontWeight={500}>Website URL</FormLabel>
                            <InputGroup>
                              <InputLeftAddon
                                pointerEvents="none"
                                bg="primary.200"
                                children={<MdLink color="#fff" />}
                              />
                              <Input
                                //id="url"
                                {...field}
                                name="websiteURL"
                                type="text"
                                h={fieldHeight}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}

                              />
                            </InputGroup>
                            <FormErrorMessage>{form.errors.websiteURL}</FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <VStack w="full" alignItems="flex-start">
                  <Text
                    //pl={{ base: 6, md: 8 }}
                    w={{ base: "full", md: "75%", lg: "80%" }}
                    className="h6"
                  >
                    Social Media
                  </Text>
                  <Divider />
                </VStack>
              
                {/* <AddASpaceLayout> */}
                     <Field name="fbURL">
                      {({ field, form }: any) => {
                        return (
                          <FormControl
                            className="caption"
                            isInvalid={form.errors.fbURL}
                            //htmlFor="fb"
                          >
                            <FormLabel fontWeight={500}>
                             Facebook
                            </FormLabel>
                            <InputGroup>
                              <InputLeftAddon
                                bg="primary.200"
                                children={<RiFacebookFill color="#fff" />}
                              />
                              <Input
                                //id="fb"
                                {...field}

                                name="fbURL"
                                type="text"
                                h={fieldHeight}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                            </InputGroup>
                            <FormErrorMessage>{form.errors.fbURL}</FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name="twitterURL">
                      {({ field, form }: any) => {
                        return (
                          <FormControl
                            className="caption"
                            //htmlFor="twitter"
                            isInvalid={form.errors.twitterURL}
                          >
                            <FormLabel fontWeight={500} fontSize={"15px"}>
                              Twitter
                            </FormLabel>
                            <InputGroup>
                              <InputLeftAddon
                                pointerEvents="none"
                                bg="primary.200"
                                children={<RiTwitterFill color="#fff" />}
                              />
                              <Input
                                //id="twitter"
                                {...field}

                                name="twitterURL"
                                type="text"
                                h={fieldHeight}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                            </InputGroup>
                            <FormErrorMessage>{form.errors.twitterURL}</FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name="instagramURL">
                      {({ field, form }: any) => {
                        return (
                          <FormControl
                            className="caption"
                            //htmlFor="instagram"
                            isInvalid={form.errors.instagramURL}
                          >
                            <FormLabel fontWeight={500} fontSize={"15px"}>
                              Instagram
                            </FormLabel>
                            <InputGroup>
                              <InputLeftAddon
                                pointerEvents="none"
                                bg="primary.200"
                                children={<RiLinkedinFill color="#fff" />}
                              />
                              <Input
                                {...field}
                                //id="instagram"
                                name="instagramURL"
                                type="text"
                                h={fieldHeight}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                            </InputGroup>
                            <FormErrorMessage>{form.errors.instagramURL}</FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <HStack w="full">
                   <Spacer/>
                   {/* <Button
                    onClick={()=>{props.handleSubmit()}}
                       >Looks Good?
                    </Button> */}
                </HStack>
                {/* </AddASpaceLayout> */}
              
              </AddASpaceLayout>
            </Form>
          );
        }}
      </Formik>
      <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={xsmall ? "md" : "2xl"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            height="100%"
            position={xsmall ? "fixed" : "unset"}
            bottom={xsmall ? "0px" : "unset"}
            mb={xsmall ? "0" : "unset"}
             borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              Privacy Policy
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody>
              <TandC />
            </ModalBody>
            <Divider />
            {/* <ModalFooter>
              <Spacer />
              <Button 

                    isLoading={isLoading}
                    colorScheme="primary"
                    onClick={()=>{
                      onAcceptTerms()
                      //onApplyFilters()
                      onClose()
                    }}
               >
                        Accept
                </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
    </Box>
     
  </>
  )
};

export default WHBusinessForm;

import { Link, VStack, Text, Divider, Button, HStack, Center, Circle } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react'
import { MdAdd, MdShoppingCart } from 'react-icons/md';
import { NavLink as ReactLink } from "react-router-dom";
import { RegistrationResponse } from '../../../Models/Interfaces';
import routes from '../../../routes';
import { CartContext, SetUserContext } from '../../AppProvider/AppProvider';
//import { CartContext } from '../../AppProvider/WHAppProvider';
import PrimaryActions from '../../Screens/WHApp/OfficeDetail/PrimaryActions/PrimaryActions';
import { signUpDataManager } from '../../Screens/WHApp/SignUp/SignUpDataManager';
import OfficeCategoryLinks from '../OfficeCategoryLinks/OfficeCategoryLinks';
import WHSiteActionButtons from '../WHSiteActionButtons/WHSiteActionButtons';

interface WHDrawerBodyProps {
    user: RegistrationResponse | null
    onClose: ()=>void
}

interface NavActionItem {
    title: string 
    url: string 
}

 

/**
 * get actions that will be displayed in Nav drawer based on user type
 */
const getActions = (user: RegistrationResponse | null): NavActionItem[] => {
    
    if (!user) { return []}
    
    
    let actions: NavActionItem[] = []
    if (user.user_type === "partners") {
        actions = [{
            title: "Your Workspaces",
            url: `/dashboard/${user.id}/manage+workspaces`
        }, {
            title: "Your Bookings/Enquiries",
            url: `/dashboard/manage+requests/${user.id}/bookings/overview`
        },
        {
          title: "Booking History",
          url: `/booking+history/${user.id}`
      }
      ] 
    } 
    else {
        actions = [{
            title: "Booking History",
            url: `/booking+history/${user.id}`
        }]
    }
   

    return actions

}

const AuthActions = ({user, onClose}: WHDrawerBodyProps)=> {
    
    let setUserContext = useContext(SetUserContext)
    const {t} = useTranslation()

    return (
        <VStack>
           {
           user ? (
            <Link w="80%"  
                      textDecoration="none !important" 
                      as={ReactLink} 
                      to={`${routes.home.path}`}
                    >
                  <Button
                    mt="16px !Important"
                    mb="16px !Important"
                    className="button"
                    w="full"
                    
                    colorScheme="primary"
                    variant="outline"
                    onClick={()=>{
                        onClose()
                        signUpDataManager.logUserout()
                        setUserContext(null)
                    }}
                  >
                     Log out
                  </Button>
                  </Link>
           ) : (
             <>
            <Link w="80%"  
                      textDecoration="none !important" 
                      as={ReactLink} 
                      to={`${routes.addASpace.path}/intro`}
                    >
                  <Button
                   
                    className="button"
                    w="full"
                    leftIcon={<MdAdd />}
                    colorScheme="primary"
                    variant="outline"
                    onClick={onClose}
                  >
                    {t("ADD YOUR SPACE")}
                  </Button>
                  </Link>
                  <HStack justifyContent="center" spacing={4} width="full">
                    <Link
                      textDecoration="none !important"
                      w="38%"
                      as={ReactLink}
                      to={routes.signup.path}
                    >
                      <Button
                        className="button"
                        width="full"
                      
                       colorScheme="primary"
                        variant="outline"
                        onClick={onClose}
                      >
                        {t(routes.signup.title.toLowerCase())}
                      </Button>
                    </Link>
                    <Link
                      textDecoration="none !important"
                      w="38%"
                      as={ReactLink}
                      to={routes.login.path}
                    >
                      <Button
                        onClick={onClose}
                        className="button"
                        width="full"
                        // bg="brand.secondary"
                        colorScheme="primary"
                         variant="outline"
                      >
                        {t(routes.login.title.toLowerCase())}
                      </Button>
                    </Link>
                  </HStack>
                  </>
           )
           }
            </VStack>
    )

}

const WHDrawerBody = ({user, onClose}: WHDrawerBodyProps) => {

  const {state: cart} = useContext(CartContext) 
    // select user actions
    const actions = getActions(user)

    return (
        <>    
               <VStack w="full" color="primary.500" pb={2}>
                    <Text className="subtitle1">Menu</Text>
                    <OfficeCategoryLinks onClose={onClose} />
                    <Link
                          w="full"
                          textDecoration="none !important"
                          as={ReactLink}
                          display="flex"
                          alignItems="center"
                          h={8}
                          justifyContent="center"
                          className="body2"
                          to={`/${routes.confirmPay.name}`}
                          color="primary.500"
                          onClick={onClose}
                        >
                          <Center position="relative" mr={2}>
                             <MdShoppingCart color="#262949" fontSize="20px"/>
                             <Circle alignItems="center" justifyContent="center" 
                              w={4} h={4} 
                              top="-8px" right="-8px"  
                              position="absolute" bg="secondary.500">
                              <Text className="caption" fontSize="8px !important" color="white">{cart.items.length}</Text>
                          </Circle>
                          </Center>
                           Your Cart
                        </Link>
                    {
                        actions.map(actionItem => (
                            <Link
                            key={actionItem.title}
                            minW="8%"
                            w="full"
                            textDecoration="none !important"
                            as={ReactLink}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            h={8}
                            className="body2"
                            to={actionItem.url}
                            color="primary.500"
                            onClick={onClose}
                          >
                              {actionItem.title}
                          </Link>
                        ))
                    }
                </VStack>
                {
                    user ? (
                        <>
                        <Divider/>
                        <VStack color="primary.500" >
                            <Text className="subtitle1"
                            
                            >Account</Text>
                             <Link
                          minW="8%"
                          textDecoration="none !important"
                          as={ReactLink}
                          display="flex"
                          alignItems="center"
                          h={8}
                          className="body2"
                          to={`/settings`}
                          color="primary.500"
                          onClick={onClose}
                        >
                           Settings
                        </Link>
                         <Divider />
                        </VStack>  
                      </>
                    ): null
                   
                }
               
                <AuthActions user={user} onClose={onClose} />
                <HStack paddingTop={2} justifyContent={"center"} w="full">
                      <WHSiteActionButtons />
                </HStack>
        </>
    )
}

export default WHDrawerBody

import * as Yup from 'yup'
import BizAccount from '../BizAccount/BizAccount'
import { BizAcctForm } from '../Models/Interfaces'

export const yupSpaceValidation = Yup.object().shape({

    fname: Yup.string()
            .required("Firstname must not be empty")
            .min(2, "Firstname must not be below 2 characters")
            .max(50, "Firstname must not exceed 50 characters"),


    lname: Yup.string()
            .required("Lastname must not be empty")
            .min(2, "Lastname must not be below 2 characters")
            .max(50, "Lastname must not exceed 50 characters"),

    email: Yup
            .string()
            .required("Please enter your email")
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Please enter a valid email"
            ),
    password: Yup
                .string()
                .required("Please Enter your password")
                .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number. Can contain one special case Character"
                  ),
    country: Yup.string().required("Please select a country"),
    phone: Yup.string()//.required("Please enter a valid Phone number")

})

export const yupSignUpValidation = Yup.object().shape({

    fname: Yup.string()
            .required("Firstname must not be empty")
            .min(2, "Firstname must not be below 2 characters")
            .max(50, "Firstname must not exceed 50 characters"),


    lname: Yup.string()
            //.required("Lastname must not be empty")
            .min(2, "Lastname must not be below 2 characters")
            .max(50, "Lastname must not exceed 50 characters"),

    email: Yup
            .string()
            .required("Please enter your email")
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Please enter a valid email"
            ),
    password: Yup
                .string()
                .required("Please Enter your password")
                .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                   // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number. Can contain special case Characters"
                  ),
    country: Yup.string()
    .required("Please select a country"),
    phone: Yup.string(),
    //.("Please enter a valid Phone number"),
    terms: Yup.boolean().test('has agreed', 
     "This field is required",
     (value) => value === true
    )
    
    // terms: Yup.boolean().when("terms", {
    //     is: false,
    //     then: Yup.string().required("Acceptance is required"),
    //     otherwise: Yup.string()
    // })
})

export const yupGoogleSignUpValidation = Yup.object().shape({

    // fname: Yup.string()
    //         .required("Firstname must not be empty")
    //         .min(2, "Firstname must not be below 2 characters")
    //         .max(50, "Firstname must not exceed 50 characters"),


    // lname: Yup.string()
    //         .required("Lastname must not be empty")
    //         .min(2, "Lastname must not be below 2 characters")
    //         .max(50, "Lastname must not exceed 50 characters"),

     
    country: Yup.string().required("Please select a country"),
    
    //.("Please enter a valid Phone number"),
    terms: Yup.boolean().test('has agreed', 
     "This field is required",
     (value) => value === true
    )
})

export const yupBizAcctValidation = Yup.object().shape({
    
    bname: Yup.string()
            .required("Businessname must not be empty")
            .min(2, "Businssname must not be below 2 characters")
            .max(255, "Businessname must not exceed 255 characters"),

    bemail: Yup
            .string()
            .required("Please enter your business email")
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Please enter your real business email"
            ),

    websiteURL: Yup
                .string()
                .matches(
                    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                    "Please enter your real business website url"
                ),
    fbURL: Yup
            .string()
            .matches(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                "Please enter a valid  facebook page url"
            ),
    twitterURL: Yup
                .string()
                .matches(
                    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                   // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    "Please enter a valid twitter url"
                ),
    instagramURL: Yup
                  .string()
                  .matches(
                    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
                   // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    "Please enter a valid twitter url"
                ),
      terms: Yup.boolean().test('has agreed', 
                "This field is required",
                (value) => value === true
            )
                  
})
import { Avatar, HStack, MenuButton } from '@chakra-ui/react';
import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RegistrationResponse } from '../../../Models/Interfaces';

interface WHMenuButtonProps {
    registeredUser: RegistrationResponse
}

const WHMenuButton = ({registeredUser}: WHMenuButtonProps) => {
    return (
      <>
        <MenuButton
          h={"42px"}
          ml={4}
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="lg"
          borderWidth="1px"
          _hover={{ bg: "gray.400" }}
          _expanded={{ bg: "blue.400" }}
          _focus={{ boxShadow: "outline" }}
        >
          <HStack>
            <Avatar
              size="sm"
              name={
                registeredUser.user_type === "partners"
                  ? registeredUser.partner_details?.name ?? ""
                  : registeredUser.first_name
              }
              src={registeredUser.avatar ?? ""}
            />

            <MdKeyboardArrowDown color="black" width="48px" height="48px" />
          </HStack>
        </MenuButton>
      </>
    );
}

export default WHMenuButton

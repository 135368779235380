import React from 'react'
 
 import { Swiper, SwiperSlide } from "swiper/react";
import {AspectRatio, Box, Image, Skeleton, Text, useMediaQuery, VStack} from '@chakra-ui/react'
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

import placeholderImg from "../../../../../Assets/banner-icon.png"

// import "./styles.css";


// import Swiper core and required modules
import SwiperCore, {
    Autoplay,
  Pagination
} from 'swiper/core';


//import styles from "./BannerView.module.css"
import { OfficeCarouselProps, OfficePhotoItem } from '../Models/Interfaces';
 
SwiperCore.use([Pagination, Autoplay]);


const SlidersView = ({items}: OfficeCarouselProps) => {

   
     
    let moreItems = items //. slice().concat(items.slice())
    const [isMediumScrn, isLargeScrn] = useMediaQuery(["(min-width: 30em)", "(min-width: 62em)"])
    let ratio =  16 / 9
    
    let slidesPerView = 1.0
    const skeletonView =  <Skeleton  height="full" width="full" />

    // if (isMediumScrn) {
    //     slidesPerView = 1.1
    // } 
    // if (isLargeScrn) {
    //     slidesPerView = 1.85
    // }

    return (
      <Box as="section"  
            // pt={{base: 4, md: 12, lg: 12}}
        >
        <Swiper
          slidesPerView={slidesPerView}
          onSlideChange={() => {}}
          //centeredSlides
          //spaceBetween={isMediumScrn || isLargeScrn ? 32 : 16}
          freeMode
           speed={1000}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
         // className={styles.mySwiper}
        >
          {moreItems.filter(item => item.imageURL).map((item, idx) => (
            <SwiperSlide key={idx}>
              <Box 
                 //className={styles.slide}
                 >
                <AspectRatio ratio={ratio}>
                  <Image 
                    fallback={skeletonView}
                  w="full" 
                  bg={item.imageURL ? "transparent" : "#eee"}
                  //rounded="md" 
                  src={item.imageURL || placeholderImg} />
                </AspectRatio>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    );
}

const OfficeCarousel = ({items}: OfficeCarouselProps) => {
  //console.dir(items)

    return (
        <div>
           <SlidersView items={items} />
        </div>
    )
}

export default OfficeCarousel

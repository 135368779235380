import { Box, Center, Link, LinkBox } from '@chakra-ui/react'
import {  NavLink as ReactLink, useHistory } from "react-router-dom";

import React from 'react'
import { ListingLinkProps, RequestURLProps } from '../../../Models/Interfaces';


export const ListingLink = ({children, to, showLine}: ListingLinkProps) => {
    return (
        <Link
              as={ReactLink}
              textDecorationLine={showLine ? "underline !important" : "none !important"} 
              textDecorationColor="primary.500"
              //className="overline"
              to={to}
            >
                {children}
        </Link>
    )
}

 

export const OfficeDetailLink = ({to, children}: ListingLinkProps) => {

    let history = useHistory()
    return (
        
        <Link
              display="block"
              cursor="pointer"
              h="full"
              w="full"
              activeStyle={{
                  color: "blue !important"
              }}
              as={ReactLink}
              textDecorationLine="none !important"
              textDecorationColor="primary.500"
              //className="overline"
              to={to}
            >
                {children}
        </Link>
    )
}

export const WorkspaceDetailLink = ({children, to}: ListingLinkProps) => {
    return (
        <Link
              w="full"
              display="block"
              as={ReactLink}
              textDecorationLine="none !important"
              textDecorationColor="primary.500"
              //className="overline"
              to={to}
            >
                {children}
        </Link>
    )
}

export const RequestListLink = ({children, to}: RequestURLProps) => {
    return (
        <Link
              //border="1px solid blue"
              
              w="128px"
              bg="white"
              color="primary.500"
              border={"1px solid #262949"}
              shadow="xl"
              //h="48px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={4}
              rounded="md"
              as={ReactLink}
              textDecorationLine="none !important"
              textDecorationColor="primary.500"
              to={to}
            >
                     {children}
         </Link>
    )
}

export const CustomerRequestLink = ({children, to}: RequestURLProps) => {

    return (
        <Link
               w="100%"
              //h="48px"
              display="flex"
              justifyContent="center"
              alignItems="center"
               as={ReactLink}
              textDecorationLine="none !important"
              textDecorationColor="primary.500"
              to={to}
            >
                     {children}
         </Link>
    )
    
}

// export const  BookinkDetailLink = ({children}: ListingLinkProps) => {
//     return (
//         <Link
//               as={ReactLink}
//               textDecorationLine="none !important"
//               textDecorationColor="primary.500"
//               //className="overline"
//               to={"/office/:officeId/detail"}
//             >
//                 {children}
//         </Link>
//     )
// }

 
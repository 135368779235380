 import { Circle, GridItem, HStack, Link, Text, VStack, Image, Spinner, useMediaQuery, InputGroup, Input, InputRightElement, Button, useBreakpointValue, IconButton, Box, Spacer, Skeleton } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { MdSearch,  MdStar } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { Adpage, CityCount, Listing, PublicListing, Result, ResultType, Workspace } from '../../../../Models/Interfaces'
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager'
import OfficeItemView from '../../../SupportingViews/OfficeItemView/OfficeItemView'
 import WHLayoutGrid from '../../../SupportingViews/WHLayoutGrid/WHLayoutGrid'
import { signUpDataManager } from '../SignUp/SignUpDataManager'
import OfficesView from '../../WHDashboard/OfficesView/OfficesView'
import { appDataManager } from '../Models/WHAppDataManager'
 import OfflineView from '../../../SupportingViews/OfflineView'
import coworkImg from "../../../../Assets/banner-icon.png";
import { BreakPoint } from '../OfficeDetail/Models/Interfaces'
import WHSeeMoreList from '../../../SupportingViews/WHSeeMoreList/WHSeeMoreList'
import { OfficeDetailLink } from '../../../SupportingViews/Links/Links'
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView'
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner'
import { Queries } from '../SearchResuts/SearchResults'
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO'
import AdsView from '../../../SupportingViews/AdsView/AdsView'
import { useTranslation  } from "react-i18next";


const CityOfficesView = () => {
    
    let workspaceId = useParams<{id?: string, title?: string, city?: string}>()
    

    const [listings, setListings] = useState<PublicListing[]>([])
    const [error, setError] = useState<Error | null>(null)    // const [result, setResult] = useState<Result<PublicListing> | null>(null)
    const [nextURL, setNextURL] = useState<string | undefined>()

    const [isLoadinng, setisLoadinng] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const breakpoint = useBreakpointValue({base: BreakPoint.XS, sm: BreakPoint.S, 
        md: BreakPoint.M, lg: BreakPoint.L, xl: BreakPoint.XL})
    
    const colspan =  useBreakpointValue({ base: 4, sm: 4, md: 6, lg: 4, xl: 4 })
    const autoColspan =  useBreakpointValue({ base: 4, sm: 8, md: 12, lg: 12, xl: 12 })
    const [query, setQuery] = useState("")
    const imgRowspan =  useBreakpointValue({ base: 8, sm: 9, md: 7, lg: 7, xl: 7 })
    const [queryObj, setQueryObj] = useState<Queries>({city: workspaceId.city})
    const [searchTitle, setSearchTitle] = useState(`Offices available at ${workspaceId.city}`)
    const [stateCounts, setStateCounts] = useState<CityCount[]>([]);
    const skeletonView =  <Skeleton  height="full" width="full" />
    const {t} = useTranslation()
    
   // const [requestStatus, setRequestStatus] = useState<ResultType>(ResultType.Success)
    
    const [xsmall, small, medium, large] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1439px)",
        "(min-width: 1440px)",
      ]);
    
      let bizTitle = "Enterprise Hubs";
    
      let imgHeight = "244px";
      if (small) {
        imgHeight = "320px";
      } else if (medium || large) {
        imgHeight = "320px";
      }

      const loadListingCount = async (city: string) => {
       // setIsLoading(true);
        let result = await appDataManager.getListingCount<CityCount>({
          name: "city",
          value: city,
        });
        if (result.type === ResultType.Success) {
          setStateCounts(result.value);
          setError(null);
        } else {
          setError(result.error);
          setStateCounts([]);
        }
        //setIsLoading(false);
      };
     
    /*
    useEffect(() => {
         
        const loadWorkspace = async () => {
            if (workspaceId.city) {
                setisLoadinng(true)
               
                let result = await appDataManager.getListingsByCity(workspaceId.city)
                setisLoadinng(false)
               
               if (result.type === ResultType.Success) {
                   // setRequestStatus(ResultType.Success)
                    setListings(result.value)
                    setError(null)
                    //setResult({type: result})
                } else {
                    //setRequestStatus(ResultType.Failure)
                    setError(result.error)
                    setListings([])
                }
            }

        }
       
        
       loadWorkspace()
         
    }, [])*/

    useEffect(() => {
      if (workspaceId.city) {
        loadListingCount(workspaceId.city)
      }
      return () => {
        
      }
    }, [])

    useEffect(() => {

        if (!nextURL && listings.length === 0) {
            onSearchListing()
        }
     
        return () => {
        }
    }, [queryObj])

    const onSearchListing = useCallback(async ()=>{
      
        setIsSearching(true)
        let result = await appDataManager.searchOfficeListings(queryObj, nextURL)

         if (result.type === ResultType.Success) {
            setNextURL(result.value.next)

              setListings([...listings, ...result.value.results])
             setError(null)
          } else {
              setError(result.error)
          }
         setIsSearching(false)

 
    },[queryObj, nextURL, listings])

    const updateSearchQuery = (queryObj: Queries, query: string) => {
      setNextURL(undefined);
      setListings([]);
      setQueryObj({ ...queryObj, title: query });
    }

    const onSearchQueryChange = (event: any)=> {
        setQuery(event.target.value)
    }

    const handleSubmit = useCallback((e: React.KeyboardEvent)=>{
        if (e.key === "Enter") {
            updateSearchQuery(queryObj, query)
        }
    }, [query, queryObj])

 
    return (
      <>
        {isLoadinng ? (
          <WHSpinner />
        ) : error && !nextURL ? (
          <OfflineView msg={error.message} pt={"64px"} />
        ) : (
          <>
             <WHSEO 
            title={`${workspaceId.city ?? "City"} Offices`} 
            meta={[
              {
                name: "description",
                content: `Offices in ${workspaceId.city ?? "a city"}`
              },
              {
                name: "keywords",
                content: "City, Offices, WorkHopper"
              }
            ]}  
          />
            {/* <WHLayoutGrid height={isLoadinng || error ? "100vh" : "auto"}> */}
            <WHLayoutGrid height={"auto"}>

              {
                <>
                  <GridItem colSpan={autoColspan} rowSpan={imgRowspan}>
                    <Image
                      fallback={skeletonView}
                       bg={stateCounts.length > 0 ? "transparent" : "#eee"}
                       rounded="xl"
                      w="full"
                      height={imgHeight}
                      
                      src={stateCounts.length > 0 ? stateCounts[0].image : coworkImg}
                      objectFit="cover"
                      aria-label={bizTitle}
                      mb={{ base: 4 }}
                    />
                  </GridItem>
                  <GridItem colSpan={autoColspan} rowSpan={2}>
                    {/* <Text className="h6">Offices available at {workspaceId.city}</Text> */}
                    <Text className="h6">{searchTitle}</Text>
                  </GridItem>
                  <GridItem colSpan={autoColspan} justify="center" rowSpan={2}>
                    <HStack onKeyPress={handleSubmit} w="full" justifyContent="center">
                      <Input
                        onChange={onSearchQueryChange}
                        focusBorderColor="primary.500"
                        w="full"
                        pr="4.5rem"
                        type="text"
                        placeholder={`Search ${workspaceId.city} Offices by Title`}
                      />
                      <Spacer/>
                      {breakpoint === BreakPoint.XS ? (
                        <IconButton
                        isLoading={isSearching && query ? true : false}

                          colorScheme="secondary"
                          onClick={() => {
                            setNextURL(undefined);
                            setListings([]);
                            setQueryObj({ ...queryObj, title: query });
                          }}
                          w="20%"
                          icon={<MdSearch />}
                          aria-label={workspaceId.city || ""}
                        />
                      ) : (
                        <Button
                          w="15%"
                          className="button"
                          isLoading={isSearching && query ? true : false}
                          colorScheme="secondary"
                          h="40px"
                          size="sm"
                          onClick={() => {
                            updateSearchQuery(queryObj, query)
                             
                            
                          }}
                        >
                          {t('Search')}
                          {/* Search */}
                        </Button>
                      )}
                    </HStack>
                  </GridItem>
                </>
              }
            </WHLayoutGrid>
            <LayoutView>
              {listings.length === 0 && queryObj.title && !isSearching ? (
                <VStack w="full" justifySelf="center" h="160px">
                  <Text className="h6">
                    {t("Couldn't find")}
                    {/* Couldn't find  */}
                    {queryObj.title} in {workspaceId.city}
                  </Text>
                </VStack>
              ) : (
                <VStack
                  w="full"
                  h="full"
                  alignItems="flex-start"
                  as="section"
                  mt={8}
                >
                   <WHSeeMoreList
                   useGrid
                    data={listings}
                    isLoading={isSearching}
                    loadData={() => {
                      onSearchListing();
                    }}
                    nextURL={nextURL}
                    error={error}
                    spinnerHeight={"32px"}
                    //loadData={onSearchListing}
                  >
                    {/* <SearchResultsItemViews listings={listings} /> */}

                    {listings.map((item) => (
                      <Box key={item.id} h="160px">
                      <OfficeDetailLink
                       
                        to={`/office/${item.id}/detail`}
                      >
                        <OfficeItemView listing={item} />
                      </OfficeDetailLink>
                      </Box>
                    ))}
                  </WHSeeMoreList>

                  
                </VStack>
                // <SearchResultsItemViews listings={listings} />
              )}

                {/* <AdsView page={Adpage.S} width="100%" height="120px" /> */}
            </LayoutView>
          </>
        )}
      </>
    );
}

export default CityOfficesView

import * as Yup from 'yup'

export const yupBookingValidation = (capacity: number, maxDays?: number)=> {
   return Yup.object().shape({

      durationCategory: Yup.string()
                        .required("Please select a duration type"),
  
      date: Yup.string()
              .required("Start date must not be empty"),
              
              //.max(50, "Firstname must not exceed 50 characters"),
    
    
        duration: Yup.number()
              .required("Duration must not be empty")
              .min(1, `Duration cannot be less than 1`),

        guests: Yup.number()
              .required("Number of guests must not be empty")
              .min(1, `Number of guests cannot be less than 1`)
              .max(capacity, `Number of guests cannot exceed than ${capacity}`),

             // .min(31, `Day must be between ${1} and ${maxDays}`),

            
              //.max(500, "Lastname must not exceed 50 characters"),
    
    })
}
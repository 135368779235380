/**
 * Abstract: A that displays offices
 */

import { GridItem, LinkBox, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import { publicListingDefaultValue } from "../../../Models/DefaultValues";
import { PublicListing } from "../../../Models/Interfaces";
import AdListingView from "../AdListingView/AdListingView";
import { OfficeDetailLink } from "../Links/Links";
import OfficeItemView from "../OfficeItemView/OfficeItemView";

interface SearchResultsItemViewsProps {
  listings: PublicListing[];
}

const SearchResultsItemViews = ({ listings }: SearchResultsItemViewsProps) => {
  const colspan = useBreakpointValue({ base: 4, sm: 4, md: 4, lg: 4, xl: 4 });
  const rowspan = useBreakpointValue({ base: 6, sm: 5, md: 4, lg: 4, xl: 4 });
  

 
  return (
    <>
      {listings.filter((item)=> item.id)
      .map((item, idx) =>
        
          <GridItem key={`item.id${idx}`} rowSpan={rowspan} colSpan={colspan}> 
            {
              item.images.others.length === 0 ? (
                
                <AdListingView listing={item} />
              ): (
                <OfficeDetailLink to={`/office/${item.id}/detail`}>
              <OfficeItemView listing={item} />
               </OfficeDetailLink>
              )
            }
             
          </GridItem>
         
      )}
    </>
  );
};

export default SearchResultsItemViews;

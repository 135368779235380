export interface ExtraDetails {
    first_name: string 
    last_name: string 
    email: string 
    header: string 
    listing_name: string 
    category: string
}

export interface PartnerBooking {
    id: number,
    start_date: string,
    amount: string,
    duration: number,
    duration_type: string,
    status: string,
    date_booked: string,
    booking: number,
    listing: string, 
    extra_details: ExtraDetails
    is_accept_term: boolean
    date_paid: string
    end_date: string
    currency: string
    is_paid?: boolean
}

export enum BOOKINGSTATUS {
    PENDING = "pending",
    APPROVED = "approved",
    CANCELLED = "cancelled",
    BLOCKED = "booked",
    PAID = "paid"
}
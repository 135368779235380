/**
 * Abstract: A view that shows a top nav bar on mobile screens. 
 *  It provides global navigation controls
 */

import React, {FC, useContext, useEffect} from 'react'
import {
    Drawer,
    DrawerBody,
   
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
  
    VStack,
    Link,
    
    Button,
    HStack,
    Image
  } from "@chakra-ui/react"
import workhopper from "../../../Assets/icon_dark.png"

import { MdAdd } from 'react-icons/md';
import {  NavLink as ReactLink } from "react-router-dom";
import routes from '../../../routes';
import OfficeCategoryLinks from '../../SupportingViews/OfficeCategoryLinks/OfficeCategoryLinks';
import WHDrawerBody from '../../SupportingViews/WHDrawerBody/WHDrawerBody';
import { SetUserContext, UserContext } from '../../AppProvider/AppProvider';
import { signUpDataManager } from '../WHApp/SignUp/SignUpDataManager';
 

  interface WHDrawerProps {
      isOpen: boolean
      onClose: ()=>void
  }
  
  const WHDrawer: FC<WHDrawerProps> = ({isOpen, onClose}: WHDrawerProps)=> {

    let registeredUser =  useContext(UserContext)
    let setUserContext = useContext(SetUserContext)

   // let {data: officeCategories, loading, error} = useListingCategories(1) //useContext(CategoriesContext)

   useEffect(() => {
    if (!registeredUser) {
      registeredUser = signUpDataManager.getRegisteredUserData()
      setUserContext(registeredUser)
    }
    // registeredUser = signUpDataManager.getRegisteredUserData()
    // if (registeredUser) {
    //   setUserContext(registeredUser)
    //  }
   
  }, [registeredUser])
      
      //const [placement, setPlacement] = useState<SlideDirection | undefined>("top")

      return (
        <>
          <Drawer  placement={"top"} onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay bg="rgb(56 161 103 / 24%)" />
            <DrawerContent bg="white">
              <DrawerHeader 
                 
                  textAlign="center" 
                  borderBottomWidth="1px"
                  >
                 
                  <Link
                     as={ReactLink}
                     textDecoration="none !important"
                     to={routes.home.path}
                     onClick={onClose}
                  >
                    <Image
                      margin="0 auto"
                      src={workhopper}
                      alt="workhopper"
                    //  height="inherit"
                      boxSize="32px"

                      //width="inherit"
                       objectFit="contain"
                    />
                  </Link>
                 
                 
              </DrawerHeader>
              <DrawerBody  color="white" pb="24px">
                <WHDrawerBody onClose={onClose} user={registeredUser} />
                
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      );
  }
  
  export default WHDrawer
  
export interface AddSpacePath {
    userId: string 
    sectionId: string
}

export interface WHRoute {
    path: string
    name: string 
    title: string
}

const routes = {
    home: {
        path: "/", 
        name: "home",
        title: "Home"
    },
    page404: {
      path: "/page404"
    },
    signup: {
        path: "/signup",
        name: "signup",
        title: "Signup"
    },
    login: {
        path: "/login",
        name: "login",
        title: "Login"
    },
    meetingRooms: {
        path: "/meeting+rooms/:id",
        name: "meeting+rooms",
        title: "Meeting Rooms"
    },
    privateOffices: {
        path: "/private+offices/:id",
        name: "private+offices",
        title: "Private Offices xx"
    },
    virtualRooms: {
        path: "/virtual+offices/:id",
        name: "virtual+offices",
        title: "Virtual Offices"
    },
    sharedOffices: {
        path: "/shared+offices/:id",
        name: "shared+offices",
        title: "Shared Offices"
    },
    sharedBox: {
      path: "/shared+box/:id",
      name: "shared+box",
      title: "Shared Box"
  },
    officeCategory: {
        path: "/office+category/:id",
        name: "office+category",
        title: "Office Category"
    },
    addASpace: {
       // path: "/add+a+space/:userId/:sectionId",
        path: "/add+a+space",
        name: "add+a+space",
        title: "Shared Offices"
    },
    dashboard: {
        // path: "/add+a+space/:userId/:sectionId",
         path: "/dashboard/:bizId",
         name: "dashboard",
         title: "Dashboard"
     },
     confirmPay: {
        // path: "/add+a+space/:userId/:sectionId",
         path: "/confirm+and+pay",
         name: "confirm+and+pay",
         title: "Confirm And Pay"
     },
     officeDetail: {
        path: "/office/:officeId/detail",
        name: "office+detail",
        title: "Office Detail"
     },
     bizProfile: {
        path: "/biz+profile/:partnerId/detail",
        name: "biz+profile",
        title: "Business Profile"
     },
     search: {
        path: "/search",
        name: "search",
        title: "search"
     },
     workspaceDetail: {
        path: "/workspace/:workspaceId/detail",
        name: "workspace+detail",
        title: "Workspace Detail"
     },
     manageWorkspaces: {
        path: "/dashboard/:bizId/manage+workspaces",
        name: "manage+workspaces",
        title: "Manage Workspaces"
     },
     manageBookings: {
        path: "/dashboard/:bizId/manage+bookings",
        name: "manage+bookings",
        title: "Manage Bookings"
     },
     manageRequests: {
        path: "/dashboard/manage+requests/:bizId/:requestId/overview",
        name: "manage+requests",
        title: "Manage Requests"
     },
     cityOffices: {
        path: "/city+offices",
        name: "city+offices",
        title: "City Offices"
     },
     settings: {
        path: "/settings",
        name: "settings",
        title: "Settings"
     },
     bookingHistory: {
        path: "/booking+history/:id?",
        name: "booking+history",
        title: "Booking History"
     },
     payBooking: {
        path: "/payBooking/:bookingId",
        name: "payBooking",
        title: "payBooking"
     },
     privacyPolicy: {
        path: "/privacy",
        name: "privacy+policy",
        title: "Privacy Policy"
     },
     termsConditions: {
      path: "/terms",
      name: "terms+conditions",
      title: "Terms and Conditions"
   },
   contact: {
      path: "/support",
      name: "support",
      title: "Contact"
   },
   claimListing: {
      path: "/claim+listing/:workspaceId/:listingId/:listingTitle",
      name: "claim+listing",
      title: "Claim Listing"
   }

}

export default routes
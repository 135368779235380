import { HStack, VStack, Text, Button, Divider, ButtonGroup, Circle, Stack, useDisclosure } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { MdStar, MdStayPrimaryLandscape } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import routes from '../../../../../routes'
import { SetLoginModalContext, UserContext } from '../../../../AppProvider/AppProvider'
import WHAlert from '../../../../SupportingViews/WHAlert/WHAlert'
import { ModalCategory, ReviewsViewProps } from '../Models/Interfaces'
import { ReviewItemView } from '../UsersReviews'
import { useTranslation  } from "react-i18next";



const Reviews = ({handleModalOpen, reviews, avgRating}: ReviewsViewProps) => {
  const {t} = useTranslation()
    

    let someReviewItemViews = reviews.slice(0, 2).map((item, _)=> (
        <ReviewItemView item={item} key={item.user.toString()} />
    ))
    let history = useHistory()
    const { onClose: onAlertClose, isOpen, onOpen: onAlertOpen } = useDisclosure()
    let user = useContext(UserContext) 
    let setShowLoginModal = useContext(SetLoginModalContext)
   // debugger
    let hasReviewed = false 
    if (user && user.id) {
        let id = Number(user.id)
        //console.log(item.user.toString())
        hasReviewed = reviews.find((item)=>item.user === id ) ? true : false

    }
 

    return (
        <>
         <VStack spacing={4} alignItems="flex-start" w="full">
             <HStack w="full" className="h6" spacing={4}>
             {
              avgRating && avgRating > 0 ? (
                <HStack className="body1" spacing={1}>
                <MdStar fill={"red"} />
                <Text>{Number(avgRating).toFixed(1)}</Text>
                </HStack>
              ):(
                <HStack  className="body1" spacing={1}>
                {/* <MdStar fill={"red"} /> */}
                <Text>
                  {t("No rating yet")}
                  {/* No rating yet */}
                  </Text>
                </HStack>
              )
            }
                  <Circle w="4px" h="4px" bg={"primary.500"}/>
                 <HStack>
                     <Text className="body1">{reviews.length}</Text>
                     
                     <Text className="body1">
                     {t("Reviews")}
                       {/* Reviews */}
                       </Text>
                 </HStack>
             </HStack>
             <Stack direction={{base: "column", md: "row", lg: "row"}}>
                {someReviewItemViews}
             </Stack>
             <ButtonGroup w={{base: "full", md: "40%"}}>
              {
                  reviews.length > 0 ? (
                    <Button 
                    onClick={()=>{handleModalOpen(ModalCategory.REVIEWS)}}
                    alignSelf="center"
                    variant="outline"
                    colorScheme="secondary"
                    minW={{base: "50%"}}
                    className="button">
                      {t("see all reviews")}
                    {/* see all reviews */}
                    </Button>
                  ): null
              }
            
            <Button 
                onClick={()=>{
                    if (user && user.access_token) {
                        handleModalOpen(ModalCategory.PAR)
                    } else {
                        setShowLoginModal(true)
                        //onAlertOpen()
                    }
                }}
                alignSelf="center"
                variant="solid"
                colorScheme="secondary"
                minW={{base: "50%"}}
                className="button">
                {hasReviewed ? "Edit": "Post a"} 
                {t("Review")}
                {/* review */}
            </Button>
             </ButtonGroup>
          </VStack>
          <WHAlert 
              title="Post Review"
              msg="Please log in to post a review. If you dont have an account, sign up!"
              leftButton={{
                label: "Sign Up",
                action: ()=> {
                  history.push(`/${routes.signup.name}`)
                }
              }}
              rightButton={{
                label: "Log In",
                action: ()=> {
                  history.push(`/${routes.login.name}`)
                }
              }}
              isOpen={isOpen}
              onClose={onAlertClose}
            />   
        </>
    )
}

export default Reviews

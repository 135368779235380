import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, chakra, VStack, Box, Text, GridItem, HStack, Spacer, InputLeftElement, InputGroup, Icon, Input, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, Divider, ModalBody, ModalFooter, Button, FormControl, Checkbox, FormLabel, useDisclosure, useBreakpointValue, SimpleGrid, Radio, RadioGroup, Stack } from "@chakra-ui/react"
//import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"
import { useTable, useSortBy, Column } from "react-table"
 import BookingsTable from '../BookingsTable'
import { MdFilterList, MdSearch, MdSort } from 'react-icons/md'
import { Field, Form, Formik } from 'formik'
//import { CompartmentCategory } from '../../../Models/Interfaces'
import { BreakPoint } from '../../../WHApp/OfficeDetail/Models/Interfaces'
import FilterButton from '../../../../SupportingViews/OfficeItemsList/FilterButton'
import { useParams } from 'react-router-dom'
import { RequestsContext, RequestStateContext, SetCounterContext, SetParamsContext } from '../WHManageRequestsProvider'
import EnquiresTable from '../EnquiresTable'
import WHSeeMoreList from '../../../../SupportingViews/WHSeeMoreList/WHSeeMoreList'
import { CompartmentCategory } from '../../../../../Models/Interfaces'

function AllRequestsTable() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isSortOpen, onOpen: onSortOpen, onClose: onSortClose } = useDisclosure();
    const [isFilter, setIsFilter] = useState(false)
    const filterColspan = useBreakpointValue({base: 7, sm: 7, md: 7, lg: 12, xl: 12})
    const params: {bizId: string, requestType: string} = useParams()

    const enquiriesData = useContext(RequestsContext)

    const setParams = useContext(SetParamsContext)
    const {setEnquiriesCounter, setPartnerBookingCounter} = useContext(SetCounterContext) 
    const {error, isLoading, nextURL} = useContext(RequestStateContext)

     

    const officeCategoryItems = [  
        CompartmentCategory.PO,  
        CompartmentCategory.MR, 
        CompartmentCategory.VO, 
        CompartmentCategory.Desks
    ] 

    const statusItems = [
        "Pending",
        "Cancelled",
        "Approved"
    ]

    const sortOrderItems = [
        "Ascending",
        "Descending"
    ]

    

    const breakpoint = useBreakpointValue({base: BreakPoint.XS, sm: BreakPoint.S, 
        md: BreakPoint.M, lg: BreakPoint.L, xl: BreakPoint.XL})

        const officeItemViews = [  
            CompartmentCategory.PO, 
            CompartmentCategory.MR,
            CompartmentCategory.VO,
            CompartmentCategory.Desks
        ].map((item, idx) => (
            <HStack key={item}>
                <Checkbox
                    onChange={()=>{}}
                    size="lg"
                />
                <Text
                    
                >
                    {item}
                </Text>
                
                </HStack>
        ))

    const statusItemViews = statusItems.map((item, idx) => {

        return (
            <HStack key={item}>
            <Checkbox
                onChange={()=>{}}
                size="lg"
            />
            <Text
                
            >
                {item}
            </Text>
            
            </HStack>
        )
       
    })

    const sortItemViews = sortOrderItems.map((item, idx)=> {
        return (
            <Radio value={item}>{item}</Radio>
        )
    })

    const statusSortItemViews = sortOrderItems.map((item, idx)=> {
        return (
            <Radio value={item}>{item}</Radio>
        )
    })

    const filterViewDesktop = () => {

        return (
              <HStack w="full">
              <Text className="subtitle2">FilteredBy: </Text>
                <FilterButton label="Office Category">
                  <SimpleGrid columns={2} columnGap={4} gap={4}>
                    {officeItemViews}
                  </SimpleGrid>
                  <Spacer h={4}/>
                  <Divider/>
                  <Spacer h={4}/>
                  <HStack>
                      <Spacer/>
                      <Button
                            w="30%"
                            ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                        >
                            Apply
                        </Button>
                      </HStack>
                </FilterButton>
                <FilterButton label="Status">
                  <SimpleGrid columns={2} columnGap={4} gap={4}>
                    {statusItemViews}
                  </SimpleGrid>
                  <Spacer h={4}/>
                  <Divider/>
                  <Spacer h={4}/>
                  <HStack>
                      <Spacer/>
                      <Button
                            w="30%"
                            ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                        >
                            Apply
                        </Button>
                      </HStack>
                </FilterButton>
               </HStack>
         )
    }

    const sortViewDesktop = () => {

        return (
            <GridItem colSpan={filterColspan} rowSpan={1}>
              <HStack w="full">
                  <Text className="subtitle2">SortBy:</Text>
                <FilterButton label="Date Created">
                <RadioGroup>
                  <SimpleGrid columns={2} columnGap={4} gap={4}>
                              {sortItemViews}
                   </SimpleGrid>
                  </RadioGroup>
                  <Spacer h={4}/>
                  <Divider/>
                  <Spacer h={4}/>
                  <HStack>
                      <Spacer/>
                      <Button
                            w="30%"
                            ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                        >
                            Apply
                        </Button>
                      </HStack>
                </FilterButton>
                <FilterButton label="Status">
                <RadioGroup>
                  <SimpleGrid columns={2} columnGap={4} gap={4}>
                        {statusSortItemViews}
                  </SimpleGrid>
                  </RadioGroup>
                  <Spacer h={4}/>
                  <Divider/>
                  <Spacer h={4}/>
                  <HStack>
                      <Spacer/>
                      <Button
                            w="30%"
                            ml={breakpoint == BreakPoint.M ? "-64px" : "-24px"}
                            colorScheme="primary"
                        >
                            Apply
                        </Button>
                      </HStack>
                </FilterButton>
               </HStack>
             </GridItem>
        )
    }
    
    
        const filterView = ()=> {
        return (
            <Formik
                initialValues={{
                  isPrivateOffice: false,
                  isMeetingOffice: false,
                  isVirtualOffice: false,
                  isSharedOffice: false,       
                }}
                initialStatus={{ isValidating: false }}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                  console.log("is submitting");
                }}
               // innerRef={amenitiesFormRef}
              >
                {(props) => {
                 // console.log(props.values);
                  return (
                    <Form>
                      <VStack alignItems="flex-start">
                        <Text>Office Category</Text>
                        {officeCategoryItems.map((item, idx) => (
                          <Field key={item} name={item}>
                            {({ field, from }: any) => (
                              <FormControl className="caption">
                                <HStack>
                                  <Checkbox
                                    {...field}
                                    colorScheme="primary"
                                    name={item}
                                    onChange={props.handleChange}
                                    size="lg"
                                  />
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="openedSun"
                                  >
                                    {item}
                                  </FormLabel>
                                </HStack>
                              </FormControl>
                            )}
                          </Field>
                        ))}
                      </VStack>
                      <Spacer h={4} />
                      <Divider/>
                      <Spacer h={4} />
                      <VStack alignItems="flex-start">
                        <Text>Status</Text>
                        {statusItems.map((item, idx) => (
                          <Field key={item} name={item}>
                            {({ field, from }: any) => (
                              <FormControl className="caption">
                                <HStack>
                                  <Checkbox
                                    {...field}
                                    colorScheme="primary"
                                    name={item}
                                    onChange={props.handleChange}
                                    size="lg"
                                  />
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="openedSun"
                                  >
                                    {item}
                                  </FormLabel>
                                </HStack>
                              </FormControl>
                            )}
                          </Field>
                        ))}
                      </VStack>
                     
                    </Form>
                  );
                }}
              </Formik>
        )
    }

    const sortView = ()=> {

        return (
            <Formik
                initialValues={{
                  isPrivateOffice: false,
                  isMeetingOffice: false,
                  isVirtualOffice: false,
                  isSharedOffice: false,       
                }}
                initialStatus={{ isValidating: false }}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                  console.log("is submitting");
                }}
               // innerRef={amenitiesFormRef}
              >
                {(props) => {
                  console.log(props.values);
                  return (
                    <Form>
                      <VStack alignItems="flex-start">
                        <Text>Date Booked</Text>
                        {sortOrderItems.map((item, idx) => (
                          <Field key={item} name={item}>
                            {({ field, from }: any) => (
                              <FormControl className="caption">
                                <HStack>
                                  <Checkbox
                                    {...field}
                                    colorScheme="primary"
                                    name={item}
                                    onChange={props.handleChange}
                                    size="lg"
                                  />
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="openedSun"
                                  >
                                    {item}
                                  </FormLabel>
                                </HStack>
                              </FormControl>
                            )}
                          </Field>
                        ))}
                      </VStack>
                      <Spacer h={4} />
                      <Divider/>
                      <Spacer h={4} />
                      <VStack alignItems="flex-start">
                        <Text>Status</Text>
                        {sortOrderItems.map((item, idx) => (
                          <Field key={item} name={item}>
                            {({ field, from }: any) => (
                              <FormControl className="caption">
                                <HStack>
                                  <Checkbox
                                    {...field}
                                    colorScheme="primary"
                                    name={item}
                                    onChange={props.handleChange}
                                    size="lg"
                                  />
                                  <FormLabel
                                    fontWeight={500}
                                    fontSize={"15px"}
                                    htmlFor="openedSun"
                                  >
                                    {item}
                                  </FormLabel>
                                </HStack>
                              </FormControl>
                            )}
                          </Field>
                        ))}
                      </VStack>
                    </Form>
                  );
                }}
              </Formik>
        )
    }

    const toolbarDesktop = () => {
        return (
            <>
                 <GridItem
                //mt={8}
                colSpan={{base: 4, sm: 8, md: 12}}
                rowSpan={1}
                alignSelf="center"
              >
                <HStack w="full">
                    <Text fontWeight="500 !important" className="h6">10 {params.requestType[0].toUpperCase() + params.requestType.substr(1)}</Text>
                     <Spacer/>
                     <Input
                     w={"70%"}
                    focusBorderColor="primary.500"
                    color="black"
                    bg="white"
                    className="body1"
                    size="md"
                    fontSize="md"
                    type="text"
                    placeholder="Search Bookings"
                  />
                </HStack>
              </GridItem>
              <GridItem colSpan={filterColspan} rowSpan={1}>
                    {filterViewDesktop()}
                </GridItem>
              {/* <GridItem colSpan={12} rowSpan={1}/> */}
              <GridItem colSpan={12} rowSpan={1}>
                 {sortViewDesktop()}
              </GridItem>
            </>
        )
    }

    const toolbarMobile = () => {

        return (
            <>
            <GridItem
                mt={8}
                colSpan={{base: 4, sm: 8, md: 12}}
                rowSpan={3}
                alignSelf="center"
              >
                   <HStack alignItems="center" w="full">
                  <Text fontWeight="500 !important" className="h6">10 {params.requestType[0].toUpperCase() + params.requestType.substr(1)}</Text>
                 <Spacer/>
                    <IconButton
                        variant="outline"
                        colorScheme="secondary"
                        aria-label="Filter Bookings"
                        onClick={()=>{
                            setIsFilter(true)
                            onOpen()
                        }}

                        icon={<MdSort/>}
                    />
                     <IconButton
                        variant="outline"
                        onClick={()=>{
                            setIsFilter(false)
                            onOpen()
                        }}
                        colorScheme="secondary"
                        aria-label="Filter Bookings"
                        icon={<MdFilterList/>}
                    />
                 </HStack>
              </GridItem>
              <GridItem
                colSpan={{base: 4, sm: 8, md: 12}}
                rowSpan={2}
              >
                   <HStack w="full">
                    
                  <Input
                    focusBorderColor="primary.500"
                    color="black"
                    bg="white"
                    className="body1"
                    size="md"
                    fontSize="md"
                    type="text"
                    placeholder="Search Bookings"
                  />
                   <Spacer/>
                  {/* <IconButton
                        variant="outline"
                        colorScheme="secondary"
                        aria-label="Search Bookings"
                        icon={<MdSearch/>}
                    /> */}
                 </HStack>
              </GridItem>
            </>
        )
    }

    const loadMoreRequests = useCallback(()=>{
        //debugger
        console.dir(enquiriesData)
        if (params.requestType === "bookings") {
            setPartnerBookingCounter(prev=>prev + 1)
        } else {
          setEnquiriesCounter(prev=>prev + 1)
        }
    }, [enquiriesData, params])

    useEffect(()=>{
      
      setParams(params)
    }, [])

   
    return (
        <>   
             {/* {breakpoint === BreakPoint.XS || 
               breakpoint === BreakPoint.S  ?
               toolbarMobile() :
                 toolbarDesktop()
              } */}
              <GridItem colSpan={{base: 4, sm: 8, md: 12}} rowSpan={2}>
              <Text fontWeight="500 !important" className="h6">Showing <span>({enquiriesData.length})</span> {params.requestType[0].toUpperCase() + params.requestType.substr(1)} </Text>
              </GridItem>
              <GridItem colSpan={{base: 4, sm: 8, md: 12}} rowSpan={12}>
                    {
                      
                       <WHSeeMoreList
                        data={enquiriesData}
                       isLoading={isLoading}
                       loadData={loadMoreRequests}  
                       error={error} 
                       nextURL={nextURL} 
                       spinnerHeight="64px"         
                   >
                        <EnquiresTable requestType={params.requestType} enquiredItems={enquiriesData} />
                      </WHSeeMoreList>
                    
                      // params.requestType === "enquiries" ? (
                      //     <EnquiresTable requestType={params.requestType} enquiredItems={enquiriesData} />
                      // ):(
                      //   <EnquiresTable  enquiredItems={enquiriesData} />
                       
                      // )
                    }
                     {/* <HStack  justifyContent="center" w="full" mt="6">
                        <Button 
                            colorScheme="primary"
                            disabled
                        >
                            Prev
                        </Button>
                        <Button 
                            colorScheme="primary"
                            
                        >
                            Next
                        </Button>
                     </HStack> */}
                </GridItem>
               
               <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={breakpoint == BreakPoint.XS ? "md" : "lg"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            position={breakpoint === BreakPoint.XS ? "fixed" : "unset"}
            bottom={breakpoint === BreakPoint.XS ? "0px" : "unset"}
            mb={breakpoint === BreakPoint.XS ? "0" : "unset"}
             borderRadius={breakpoint === BreakPoint.XS ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              {isFilter ? "Filter": "Sort"} Options
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody>{isFilter ? filterView() : sortView()}</ModalBody>
            <Divider />
            <ModalFooter>
              <Spacer />
              <Button colorScheme="secondary">Accept</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    )
    
}

export default AllRequestsTable
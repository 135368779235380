import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
  useToast,
  useDisclosure,
  VStack,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
//import WhatsAppWidget from "../node_modules/react-whatsapp-chat-widget/index";
//import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import { Redirect, Route, Switch  } from "react-router-dom";
import "./App.css";
import AppProvider, {
  LoginModalContext,
  SetCountriesContext,
  SetErrorContext,
  SetLoginModalContext,
  UserContext,
} from "./Components/AppProvider/AppProvider";
import CurrencyConverterProvider from "./Components/AppProvider/CurrencyConverterProvider";
import WHAppProvider from "./Components/AppProvider/WHAppProvider";
//import { LoginWithGoogle } from './Components/Screens/WHApp/Login/Login';
import { GoogleForm } from "./Components/Screens/WHApp/SignUp/SignUp";
import { signUpDataManager } from "./Components/Screens/WHApp/SignUp/SignUpDataManager";
import Cart from "./Components/Screens/WHApp/Cart/OfficesCart";
import WHApp from "./Components/Screens/WHApp/WHApp";
import WHDashboard from "./Components/Screens/WHDashboard/WHDashboard";
import CartItemView from "./Components/SupportingViews/CartItemView/CartItemView";
import ErrorView from "./Components/SupportingViews/ErrorView/ErrorView";
import GlobalSearch from "./Components/SupportingViews/GlobalSearch/GlobalSearch";
import OfflineView from "./Components/SupportingViews/OfflineView";
import WHFooter from "./Components/SupportingViews/WHFooter/WHFooter";
import WHSEO from "./Components/SupportingViews/WHSEO/WHSEO";
import WHSpinner from "./Components/SupportingViews/WHSpinner/WHSpinner";
import DashboardRoute from "./DashboardRoute";
import { cartItemDefaultValue } from "./Models/DefaultValues";
import { Country, ResultType } from "./Models/Interfaces";
import routes from "./routes";
import { tawkToLoadScripts } from "./utils/utils";
import { LoginWithGoogle } from "./Components/Screens/WHApp/Login/Login";
import icon from "../src/Assets/banner-icon.png"
import WH404 from "./Components/SupportingViews/WH404/WH404";
import { ScrollToTop } from "./Components/SupportingViews/ScrollToTop/ScrollToTop";

export const appInfo = { title: "WorkHopper" };


function App() {
  const setCountriesContext = useContext(SetCountriesContext);
  const setErrorContext = useContext(SetErrorContext);
  const showLoginModal = useContext(LoginModalContext);
  const setShowLoginModal = useContext(SetLoginModalContext);
  const user = useContext(UserContext);
  const [showGoogleLoginButton, setShowGoogleLoginButton] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [countryItems, setCountryItems] = useState<Country[]>([]);

  const {
    onClose: onLoginModalClose,
    isOpen,
    onOpen: onLoginModalOpen,
  } = useDisclosure();

  const [xsmall, small, medium, large, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  const loadData = async () => {
    setLoading(true);
    setError(null);
    let result = await signUpDataManager.fetchCountries();

    if (result.type === ResultType.Success) {
      setCountryItems(result.value);
    } else {
      setError(new Error(result.error.message));
      setCountryItems([]);
      //setErrorContext({title: result.error.name, msg: result.error.message})
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    if (showLoginModal) {
      onLoginModalOpen();
    }
  }, [showLoginModal]);

  useEffect(() => {
    if (!isOpen) {
      setShowLoginModal(false);
    }
  }, [isOpen]);

  useEffect(() => {
    //debugger
    if (user?.access_token && showLoginModal) {
      onLoginModalClose();
    }
  }, [user?.access_token]);


  return (
    <Box minHeight="100vh" position="relative">


      <ScrollToTop />
      <Switch>
        <DashboardRoute path={routes.dashboard.path}>
          <WHDashboard />
        </DashboardRoute>
        <Route
          path={routes.home.path}
          render={() => (
            <WHAppProvider>
              <WHApp />
            </WHAppProvider>
          )}
        ></Route>
        {/* <Route 
          exact
          path={routes.page404.path}
          render={()=>(
            <WH404 />
          )}
        /> */}
      </Switch>
      <WHFooter />
      <Modal
        isCentered
        onClose={onLoginModalClose}
        isOpen={isOpen}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
        size={xsmall ? "md" : "2xl"}
        // trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          position={xsmall ? "fixed" : undefined}
          bottom={xsmall ? "0px" : "unset"}
          mb={xsmall ? "0" : "unset"}
          borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
          //maxW="lg"
        >
          <ModalHeader textAlign="center" className="h6">
            Sign up or Log in to continue
          </ModalHeader>

          <Divider />
          <ModalCloseButton
          //outline="none"
          //_focus={{ outline: "none" }}
          />
          <ModalBody>
            <VStack h="70vh" pt={16} alignItems={"center"}>
              {/* {
                     loading ? (
                       <WHSpinner pt="64px" h="10vh" />
                     ) : error ? (
                       <OfflineView onRetry={loadData} msg={error.message}  pt="64px" />
                     ) : (
                      <GoogleForm countryItems={countryItems} />
                     )
                   }
                   <Text className="body1">Already have an account?</Text> */}
              <LoginWithGoogle />
            </VStack>
          </ModalBody>
          <Divider />
        </ModalContent>
      </Modal>
     
    </Box>
  );
}

export default App;

 import { Box, Text, useMediaQuery } from "@chakra-ui/react";

interface WHTimepickerItemInterfaceProps {
    timeLabel: string 
    onSelectTime: (timeLabel: string) => void
    w: string
    h: string
    isSelected: boolean
    isDisabled: boolean
    mode: string
}


const WHTimepickerItem = ({timeLabel, onSelectTime, w, h, isSelected, isDisabled, mode}: WHTimepickerItemInterfaceProps) => {
    
    const [xsmall] = useMediaQuery([
        "(max-width: 599px)"
      ]);
    
    let bg = "white"
    let color = "#212121"
    if (isSelected) {
        if (mode === "highlighted") {
            bg = "orange"
        } else {
            bg = "green"
        }
       // bg = "green"
        color = "white"
    }

    if (isDisabled) {
       // bg = "rgba(255, 0, 0, 0.54)"
        //color = "#fff"
    }

    const bookedDayStyle = {
        textDecoration: "line-through",
        textDecorationColor: "#ec0f47",
        textDecorationThickness: "2px",
        color: "#212121"
    }

    return (
        <Box 
            paddingX={2}
            bg={bg}
            color={color}
            role={"button"}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            w={w}
            h={h}
            borderRadius={"10px"}
            className={xsmall ? "body2" : "subtitle1"}
            style={isDisabled ? bookedDayStyle : undefined}
            //border={"2px solid #eee"}
            onClick={!isDisabled ? ()=>{
                onSelectTime(timeLabel)
            } : undefined}>
            <Text>{timeLabel}</Text>
        </Box>
    )
}

export default WHTimepickerItem

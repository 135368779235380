 import { RegistrationResponse, Result, UserData } from "../../../../../Models/Interfaces"
import { WorkhopperAPIManager } from "../../../../../Models/WorkhopperAPIManager"
import routes from "../../../../../routes"
import { AccountForm, BizAcctForm } from "./Interfaces"
 


export enum OnBoardSectionId {
    Intro = "intro",
    Account = "account",
    Setup = "setup",
    Contact = "contact",
    Space = "space",
    BizAccount = "bizAccount",
    Dashboard = "dashboard"
}

export enum URLDir {
    next = "next",
    curr = "current",
    prev = "prev"
}

interface OnBoardSection {
    id: OnBoardSectionId
    nextId?: OnBoardSectionId
    prevId?: OnBoardSectionId
    name: string
}

export class ListSpaceDataManager {

    private selectedSectionId = OnBoardSectionId.Account
     
    private sections: Map<OnBoardSectionId, OnBoardSection> = new Map()

    

    private personalAcctData: AccountForm = { fname: "", lname: "", email: "", password: "", country: "", phone: ""}
    private bizAcctData: BizAcctForm = { bemail: "", bname: "", websiteURL: "", fbURL: "", instagramURL: "", twitterURL: "", terms: false } 
    private bizId: string = ""
    
    private apiManager = WorkhopperAPIManager.shared

    constructor() {
        this.sections.set(OnBoardSectionId.Intro,
            {
                id: OnBoardSectionId.Intro,
                nextId: OnBoardSectionId.Account,
                prevId: undefined,
                name: "intro"
            })
        this.sections.set(OnBoardSectionId.Account,
            {
                id: OnBoardSectionId.Account,
                nextId: OnBoardSectionId.BizAccount,
                prevId: OnBoardSectionId.Intro,
                name: "account"
            })
        this.sections.set(OnBoardSectionId.BizAccount,
                    {
                        id: OnBoardSectionId.BizAccount,
                        nextId: OnBoardSectionId.Dashboard,
                        prevId: OnBoardSectionId.Account,
                        name: "biz+account"
                    })
        this.sections.set(OnBoardSectionId.Space,
                {
                    id: OnBoardSectionId.Space,
                    nextId: OnBoardSectionId.Contact,
                    prevId: OnBoardSectionId.Account,
                    name: "about+space"
                })
        this.sections.set(OnBoardSectionId.Contact,
                    {
                        id: OnBoardSectionId.Contact,
                        nextId: undefined,
                        prevId: OnBoardSectionId.Space,
                        name: "contact+details"
                    })
       
        // this.sections.set(OnBoardSectionId.Setup,
        //      {
        //          id: OnBoardSectionId.Setup,
        //          nextId: OnBoardSectionId.Admin,
        //          prevId: undefined,
        //      })
        // this.sections.set(OnBoardSectionId.Admin,
        //         {
        //             id: OnBoardSectionId.Admin,
        //             nextId: OnBoardSectionId.Space,
        //             prevId: OnBoardSectionId.Setup
        //         })
       
    }

    get activeSection(): OnBoardSection {
        return this.getOnboardSection(this.selectedSectionId)
    }

    // get nextSectionURL(): OnBoardSection {
    //     const section = this.getOnboardSection(this.selectedSectionId)
    // }

    get prevSectionURL(): OnBoardSection {
        return this.getOnboardSection(this.selectedSectionId)
    }

    get acctData(): AccountForm {
        return this.personalAcctData
    }

    get businessAcctData(): BizAcctForm {
        return this.bizAcctData
    }
    
    getOnboardSection(id?: OnBoardSectionId): OnBoardSection {
       return this.sections.get(id || OnBoardSectionId.Account)!
    }

    setSelectedSectionId(sectionId: OnBoardSectionId) {
        this.selectedSectionId = sectionId
    }
    
    /**
     * returns a path to a section which route requires
     * the user to be signed in
     * 
     */
    getAuthenticatedSectionURL(sectionId: OnBoardSectionId, userId: string): string  {
        if (sectionId === OnBoardSectionId.Intro || 
            sectionId === OnBoardSectionId.Account
            ) {
                return `${routes.addASpace.path}/${OnBoardSectionId.Account}`
            }

           return `${routes.addASpace.path}/${userId}/${sectionId}`
    }

    getAuthenticatedSectionPath(sectionId: OnBoardSectionId): string  {
        if (sectionId === OnBoardSectionId.Intro || 
            sectionId === OnBoardSectionId.Account
            ) {
                return `${routes.addASpace.path}/${OnBoardSectionId.Account}`
            }

           const section = this.getOnboardSection(sectionId)
           return `${routes.addASpace.path}/:userId/${section.name}`
    }

    setAcctData(data: AccountForm) {
        this.personalAcctData = data
    }

    setBizAcctData(data: BizAcctForm) {
        this.bizAcctData = data
    }

    postBizAccountOnboardingData() {
        console.dir(this.personalAcctData)
        console.dir(this.bizAcctData)
    }

    getSectionPath(sectionId: OnBoardSectionId): string   {
         
         const section = this.getOnboardSection(sectionId)
         return `${routes.addASpace.path}/${section.name}`
        
    }


    
    getSectionURL(sectionId: OnBoardSectionId, urlDir: URLDir): string  {
         
        /*
        if (sectionId === OnBoardSectionId.BizAccount && 
            urlDir === URLDir.next) {
                return `/${routes.dashboard.name}/me`
            }*/

        let section: OnBoardSection = this.getOnboardSection(sectionId)
         
        if (urlDir === URLDir.next) {
            section = this.getOnboardSection(section.nextId)
        } else if (urlDir === URLDir.prev) {
            section = this.getOnboardSection(section.prevId)
        } else {
            section = this.getOnboardSection(sectionId)
        }

        return `${routes.addASpace.path}/${section.name}`
           
        
    }

    getNextSectionURL(path: string, bizId?: string): string {

        //return `${routes.addASpace.path}${path}`
        
        if (path === "/intro") {
            return `${routes.addASpace.path}/account}`
          } else if (path === "/account") {
            return `${routes.addASpace.path}/biz+account`
          } else if (path === "/biz+account" && bizId) {
             return `/dashboard/${bizId}/manage+workspaces`
          } else {
            return `${routes.addASpace.path}/intro`
          }
    }

    getPrevSectionURL(path: string): string {

        //return `${routes.addASpace.path}${path}`
        if (path === "/account") {
          return`${routes.addASpace.path}/intro`;
        } else if (path === "/biz+account") {
          return `${routes.addASpace.path}/account`;
        } else {
          return "/";
        }
    }

    personalAccountDataValid(): boolean {
        let found = Object.entries(this.personalAcctData).find(([key, value])=> key !== "phone" && value === "")
        return found ? false : true
    }


    async registerPartner(): Promise<Result<RegistrationResponse>> {
        
        const userData = {
                            partnersName: this.bizAcctData.bname, 
                            partnersEmail: this.bizAcctData.bemail,
                            fbURL: this.bizAcctData.fbURL,
                            twitterURL: this.bizAcctData.twitterURL,
                            instagramURL: this.bizAcctData.instagramURL,
                            websiteURL: this.bizAcctData.websiteURL,
                            terms: this.bizAcctData.terms,
                            userType: 'partners',
                            isSocial: false,
                            ...this.personalAcctData}
       // debugger
        let res = await this.apiManager.register(userData)
        
         return res
    }
}

export const dataManager = new ListSpaceDataManager()

 
import {
  VStack,
  Text,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Image,
  HStack,
  Select,
  Button,
  useMediaQuery,
  StackDirection,
  Checkbox,
  SimpleGrid,
  List,
  ListIcon,
  ListItem
  
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { MdHome, MdPeopleOutline, MdPublic, MdSearch } from "react-icons/md";
import { useHistory } from "react-router";
import seat from "../../../Assets/seat.png";
import seatIcon from "../../../Assets/seatIcon.svg";
import { listingCategoryDefaultValue } from "../../../Models/DefaultValues";
import {
  ListingCategory,
  ResultType,
  WHError,
  Listing
} from "../../../Models/Interfaces";
import { WorkhopperAPIManager } from "../../../Models/WorkhopperAPIManager";
import { BreakPoint } from "../../Screens/WHApp/OfficeDetail/Models/Interfaces";
import { appDataManager } from "../../Screens/WHApp/Models/WHAppDataManager";
import { ListingLink } from "../Links/Links";
import OfflineView from "../OfflineView";

import styles from "../../Screens/WHApp/WHHome/WHHome.module.css"
//import styles from "../../Screens/NewHome/NewHome.module.css";
import styles1 from "./GlobalSearch.module.css"
import { Queries } from "../../Screens/WHApp/SearchResuts/SearchResults";
import { getQueryItems } from "../../../utils/utils";
import { WorkhopperPublicAPIManager } from "../../../Models/WorkhopperPublicAPIManager";
import { t } from "i18next";
import { useTranslation } from 'react-i18next';

export interface GlobalSearchProps {
  openedByHeader: boolean;
  onSearchBegin: ()=>void
  //categories: ListingCategory[]
}

const GlobalSearch = ({ openedByHeader, onSearchBegin }: GlobalSearchProps) => {
  const [location, setLocation] = useState<string | undefined>();
  const [noOfSeats, setNoOfSeats] = useState<string | undefined>();
  // const [selectedCategory, setSelectedCategory] = useState<ListingCategory>(
  //   listingCategoryDefaultValue
  // );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const history = useHistory()
  const [categories, setCategories] = useState<ListingCategory[]>([]);
  const [error, setError] = useState<WHError | null>(null);
  const [selectButton, setSelectButton] = useState<boolean>(false);
  const [listSpace, setListSpace] = useState<any>([]);
  const {t:tt} = useTranslation()

  const [mobile, mediumUp, large] = useMediaQuery([
    "(max-width: 904px)",
    "(min-width: 905px)",
    "(min-width: 1440px)"
  ]);
   let titleSize = "h6"
   if (mediumUp) {
       titleSize = "h4"
   } else if (large) {
       titleSize = "h3"
   }

//   const [xsmall, small, medium, large, xl] = useMediaQuery([
//     "(max-width: 599px)",
//     "(min-width: 600px) and (max-width: 904px)",
//     "(min-width: 905px) and (max-width: 1239px)",
//     "(min-width: 1240px) and (max-width: 1439px)",
//     "(min-width: 1440px)",
//   ]);

  let direction = (mobile ? "column" : "row") as StackDirection

  const onLocationInputChange = (e: any) => {
    
    if (e.target.value.length >= 2) {
      
      loadListingSpaces(e.target.value)
      setSelectButton(true);
    }else{
      setListSpace([])
      setSelectButton(false); 
    }
    setLocation(e.target.value);
  };
  const onNoSeatsChange = (e: any) => {
    setNoOfSeats(e.target.value);
  };
  // const officeCategoryItemViews = categories.map((item, idx) => (
  //   <option key={item.id}>{item.title}</option>
  // ));

  const handleCategoryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let selected = categories.find((item) =>
     item.title
       .trim()
       .toLowerCase()
       .includes(e.target.name.trim().toLowerCase())
   );
   if (selected) {
     setSelectedCategories([...selectedCategories, selected.id]);
   } else {
     //setSelectedCategory(listingCategoryDefaultValue);
   }
 }, [selectedCategories, categories])
   
 const officeCategoryItemViews = (
  <>
    {
       categories.map((item, idx) => (
        <Checkbox onChange={handleCategoryChange} size="lg" colorScheme={"primary"} name={item.title} title={item.title} key={item.id}>{tt(item.title.toLowerCase().replaceAll(' ',''))}</Checkbox>
      ))
    }
  </>
)

const mobileCategoryListView = (
  <SimpleGrid spacing={4} columns={mobile ? 2 : 4}>
     {officeCategoryItemViews}
  </SimpleGrid>
)

  const loadListingCategories = useCallback( async () => {
    const result = await WorkhopperAPIManager.shared.listingCategories();
    if (result.type === ResultType.Success) {
      
      // let temp = [listingCategoryDefaultValue, ...result.value]
      setCategories(result.value);
      //setSelectedCategory(listingCategoryDefaultValue);
      
      setError(null)
    } else {
      setError({ title: "", msg: result.error.message });
      setCategories([])
      //setErrorContext({title: result.error.name, msg: result.error.message})
    }
  }, [])

  const loadListingSpaces = useCallback( async (spaceId: string) => {
    const result = await WorkhopperAPIManager.shared.spaceListing(spaceId);

    if (result.type === ResultType.Success) {
     setListSpace(result.value)
      console.log(result.value)
      setError(null)
    } else {
      setError({ title: "", msg: result.error.message });

      setListSpace([])
    }
  }, [])

  const handleSubmit = useCallback((e: any)=>{
    e.preventDefault()

    onSearchBegin()
    let info: Queries = {};
     debugger
      
      //info.category = categories.map(item => item.id).filter(item => item !== "all")
      info.category = selectedCategories.slice() 
      info.capacity = noOfSeats
      info.city = location
      const searchAllURL = WorkhopperPublicAPIManager.shared.appendQueryitems("/search", getQueryItems(info))
      
      history.push(searchAllURL)
    
  }, [location, noOfSeats, selectedCategories])

  const onEnterPress = (e: React.KeyboardEvent<HTMLDivElement>)=> {
      if (e.code === "Enter") {
        handleSubmit(e)
         
      }
  }

  const onItemSelectChange = (e: any) => {
    console.log("selected", e);
    setLocation(e);
    setSelectButton(false); 
  }

  useEffect(() => {
    loadListingCategories();
  }, []);

  return (
    <VStack alignItems={"flex-start"} onKeyPress={onEnterPress}   w="100%" spacing={mobile ? 4 : 6} paddingX={mobile ? 6 : 16} paddingY={16}>
      <VStack w="inherit" alignItems="flex-start">
        <Text className={`${styles.title} ${styles1.title}`} color="primary.500">{tt('reimagine')}</Text>
        <Text className={styles.subtitle}>{tt('reimagineSub')}
        </Text>
      </VStack>
     
      <Stack w="full" spacing={8} direction={direction}>
      {/* <form
        onSubmit={handleSubmit}
        style={{
          width: "100%"
        }}
      > */}
        <FormControl w="full" isRequired id="text">
          <InputGroup>
       
            <InputLeftElement
              h="48px"
              pointerEvents="none"
              children={<Icon as={MdSearch} w={6} h={6} color="gray.300" />}
            />
            <Input
              value={location}
              borderRadius="50px"
              onChange={onLocationInputChange}
              color="black"
              bg="white"
              className="body1"
              size="lg"
              fontSize="md"
              type="text"
              placeholder={tt('gplaceholder')}
            />    
          </InputGroup>
          {/* {selectButton && 
            <List spacing={2}>
              {listSpace.length > 0 && listSpace.map((item: any) => (
                  <ListItem onClick={() => onItemSelectChange(`${item.title} (${item.workspace_details.state})`)} key={item.id}>
                  <ListIcon color='green.500' />
                  <span>{item.title}</span>  ({item.workspace_details.state})
                </ListItem>
              ))
              }           
            </List>
          } */}
  
        </FormControl>
        <FormControl isRequired id="text">
          <InputGroup>
            <InputLeftElement
              pl={3}
              w="24px"
              h="48px"
              //w="12px"
              pointerEvents="none"
              children={<Image opacity={0.54} src={seat} w={6} h={6} />}
              //   children={<Icon as={} w={6} h={6} />}
            />
            <Input
              borderRadius="50px"
              onChange={onNoSeatsChange}
              color="black"
              bg="white"
              className="body1"
              size="lg"
              fontSize="md"
              type="text"
              placeholder={tt('gSeats')}
            />
          </InputGroup>
        </FormControl>
        {/* </form> */}
      </Stack>


       {/* <Stack
        w="full"
        justifyContent="flex-start"
         direction={direction}
        spacing={{ base: 6, md: 8, lg: 8 }}
        pr={{ base: "10%", md: "0%" }}
      >
        <HStack color="primary.500">
          <MdPublic size="22px" />
          <Text className="caption">240 Cites in Africa</Text>
        </HStack>
        <HStack>
          <MdHome size="22px" />
          <Text className="caption">20,000+ Cowork Spaces</Text>
        </HStack>
        <HStack>
          <MdPeopleOutline size="22px" />
          <Text className="caption">2 million Users</Text>
        </HStack>
      </Stack> */}
      <SimpleGrid spacing={4} columns={mobile ? 2 : 3}>
      <HStack color="primary.500">
          <MdPublic size="22px" />
          <Text className="caption">240 {tt('gCites')}</Text>
        </HStack>
        <HStack>
          <MdHome size="22px" />
          <Text className="caption">20,000+ {tt('gSpace')}</Text>
        </HStack>
        <HStack>
          <MdPeopleOutline size="22px" />
          <Text className="caption">2 {tt('mUsers')} </Text>
        </HStack>
      </SimpleGrid>
      {categories.length > 0 && (
         <Stack spacing={4} direction={mobile ? "column" : "row"}  w="100%">
          {/* <Select
                        rounded="xl"

            w={mobile ? "50%" : "160px"}
            value={selectedCategory?.title}
            onChange={handleCategoryChange}
          >
            {officeCategoryItemViews}
          </Select> */}
          {mobileCategoryListView}
          <Button 
            name="done"
            type="submit"
            onClick={handleSubmit}
            rounded="xl"
            w={mobile ? "full" : "160px"}
            colorScheme="secondary"
            >{tt('done')}</Button>
         </Stack>
        
      )}
      {error && (
        <OfflineView
          pt="0px"
          msg={error.msg}
          onRetry={() => {
            loadListingCategories();
          }}
        />
      )}
    </VStack>
  );
};

export default GlobalSearch;

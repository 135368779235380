import {
  VStack,
  Text,
  Divider,
  Box,
  FormControl,
  Input,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Image,
  Button,
  PinInput,
  PinInputField,
  HStack,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useMediaQuery,
  Center,
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { Result } from "neverthrow";
import React, { useCallback, useContext, useRef, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { MdPhotoCamera } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import {
  Country,
  RegistrationResponse,
  ResultType,
} from "../../../../Models/Interfaces";
import { WorkhopperAPIManager } from "../../../../Models/WorkhopperAPIManager";
import routes from "../../../../routes";
import {
  SetErrorContext,
  SetSuccessContext,
} from "../../../AppProvider/AppProvider";
import AddASpaceLayout from "../../../SupportingViews/AddASpaceLayout/AddASpaceLayout";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import { ListingLink } from "../../../SupportingViews/Links/Links";
import WHBusinessForm from "../../../SupportingViews/WHBusinessForm/WHBusinessForm";
import Account from "../AddASpace/Account/Account";
import BizAccount from "../AddASpace/BizAccount/BizAccount";
import { AccountForm, BizAcctForm } from "../AddASpace/Models/Interfaces";
import { dataManager } from "../AddASpace/Models/ListSpaceDataManager";
import { loginDataManager } from "../Login/LoginDataManager";
import {
  DashboardSectionName,
  DescForm,
  UploadedFile,
} from "../../WHDashboard/Models/Interfaces";
import { yupDescriptionValidation } from "../../WHDashboard/Models/Schema/DescriptionValSchema";
import { dashboardManager } from "../../WHDashboard/Models/WHDashboardDataManager";
import { useTranslation  } from "react-i18next";

const ClaimListing = () => {
  const {t} = useTranslation()
  let accountFormRef = useRef<FormikProps<AccountForm>>(null);
  let bizAccountFormRef = useRef<FormikProps<BizAcctForm>>(null);
  let formRef = useRef<FormikProps<DescForm>>(null);
  const params =
    useParams<{
      workspaceId?: string;
      listingId?: string;
      listingTitle?: string;
    }>();
  const setErrorContext = useContext(SetErrorContext);
  const setSuccess = useContext(SetSuccessContext);

  const history = useHistory();

  const [acctFormValues, setAcctFormValues] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    country: "",
    phone: "",
  });
  const [bizAcctFormValues, setBizAcctFormValues] = useState({
    bemail: "",
    bname: "",
    websiteURL: "",
    fbURL: "",
    instagramURL: "",
    twitterURL: "",
    terms: true,
  });
  const [values, setValues] = useState({ name: "", desc: "" });
  const [otp, setOTP] = useState<string>("0");
  const [countryItems, setCountryItems] = useState<Country[]>([]);
  const onOTPChange = (value: any) => setOTP(value);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);


  
  const [xsmall, small, medium, lg, xl] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);

  //  console.dir(params)
  //  debugger
 

  const submitData = useCallback(async () => {
    if (
      !accountFormRef.current ||
      !bizAccountFormRef.current ||
      !formRef.current
    ) {
      return;
    }

    if (!params.workspaceId || !params.listingId || !params.listingTitle) {
      return;
    }

    /* verify pincode
        const verificationResult = await WorkhopperAPIManager.shared.verifyClaimCode(params.listingId, otp)
        if (verificationResult.type === ResultType.Failure) {
          setErrorContext({title: "", msg: verificationResult.error.message})
          return
          
        }  */

    // debugger
    await accountFormRef.current.validateForm();
    const errors = accountFormRef.current.errors;
    if (!accountFormRef.current.isValid) {
      setErrorContext({
        title: "",
        msg: "There's an error in the About You section of the form. Try again",
      });
      return;
    }

   // bizAccountFormRef.current.handleSubmit()
    await bizAccountFormRef.current.validateForm()

    if (!bizAccountFormRef.current.isValid) {
      setErrorContext({
        title: "",
        msg: "There's an error in the Business section of the form. Try again",
      });
      return;
    }

    let entryIdx = countryItems.findIndex((item, idx) =>
      item.name.includes(accountFormRef.current!.values.country)
    );

    const acctData = {
      ...accountFormRef.current.values,
      country: countryItems[entryIdx].id,
    };
    dataManager.setAcctData(acctData);
    dataManager.setBizAcctData(bizAccountFormRef.current.values);

    let data = {
      first_name: acctData.fname,
      last_name: acctData.lname,
      email: acctData.email,
      phone: acctData.phone,
      user_type: "partners",
      country: acctData.country,
      password: acctData.password,
      partner_email: bizAccountFormRef.current.values.bemail,
      partner_name: bizAccountFormRef.current.values.bname,
      is_accept_terms: bizAccountFormRef.current.values.terms,
      fbURL: bizAccountFormRef.current.values.fbURL,
      twitterURL: bizAccountFormRef.current.values.twitterURL,
      instagramURL: bizAccountFormRef.current.values.instagramURL,
      websiteURL: bizAccountFormRef.current.values.websiteURL,
      is_social: false,
    };

    // let fileData = new FormData()

    // fileData.append("file", uploadedFiles[0].file)

    const claimResult = await WorkhopperAPIManager.shared.claimListing({
      ...data,
      listing_id: params.listingId,
      code: otp,
      title: formRef.current.values.name,
      description: formRef.current.values.desc,
    });
    if (claimResult.type === ResultType.Success) {
      //console.dir(claimResult.value)
      //setSuccess({title: "", msg: "Claiming Successful"})
      setShowAlert(true);
    } else {
      setErrorContext({ title: "", msg: claimResult.error.message });
      //console.dir("error")
    }

    //let result = await dataManager.registerPartner()
    /*
        let partnerObj: RegistrationResponse | undefined
        // test if user already exists
        if (result.type === ResultType.Failure && 
          result.error.message !== "("
           //!result.error.message.toLowerCase().includes("duplicate")
           ) {
          setErrorContext({title: "", msg: result.error.message})
          return 
        } else if (result.type === ResultType.Success) {
          partnerObj = result.value
        }

        let data = {
          email: dataManager.acctData.email,
          password: dataManager.acctData.password
        }

        let accessTokenResult = await loginDataManager.loginUser(data)
        if (accessTokenResult.type === ResultType.Failure) {
          setErrorContext({title: "", msg: accessTokenResult.error.message})
          return 
        }

        // is this a registered accournt. 
        if (result.type === ResultType.Failure) {
          let registeredUserResult = await loginDataManager.getLoginUser(accessTokenResult.value.access)
                
          if (registeredUserResult.type === ResultType.Success && registeredUserResult.value.user_type !== "partners") { 
            setErrorContext({title: "", msg: "Register with a partner email"})
            return
          } else if (registeredUserResult.type === ResultType.Failure) {
            setErrorContext({title: "", msg: registeredUserResult.error.message})
            return
          } else if (registeredUserResult.type === ResultType.Success && registeredUserResult.value.user_type === "partners") {
            partnerObj = registeredUserResult.value

          }
        }*/

    /* attach partner to workspace
        const updatedWorkspaceRslt = await dashboardManager.updateWorkspaceSection(params.workspaceId, DashboardSectionName.Partner, 
                                partnerObj, accessTokenResult.value.access)
         if (updatedWorkspaceRslt.type === ResultType.Failure) {
          setErrorContext({title: "", msg: "Couldn't complete operation. Try again later"})
          return
         }*/

    // update workspace
    /*
        const wkspaceRslt = await dashboardManager.postWorkspaceSection(params.workspaceId, DashboardSectionName.Desc, 
                                                formRef.current.values, uploadedFiles[0].file, accessTokenResult.value.access)
        
        if (wkspaceRslt.type === ResultType.Failure) {
          setErrorContext({title: "", msg: wkspaceRslt.error.message})
          return 
        }*/

    /*
        let claimedResult = await WorkhopperAPIManager.shared.patchListingPublish(params.listingId, accessTokenResult.value.access, {is_claimed: true})

        if (claimedResult.type === ResultType.Failure) {
          setErrorContext({title: "", msg: claimedResult.error.message})
          return
        } */
  }, [otp, uploadedFiles, countryItems]);


  const handleDrop = (accFiles: File[], rejFiles: FileRejection[]) => {
    //console.dir(accFiles)
    //console.dir(rejFiles)
    if (rejFiles.length > 0) {
      setErrorContext({
        title: "",
        msg: "File seems to exceed 1mb. Try again",
      });
      return;
      //if (rejFiles.errors[0].code.includes("too-large"))
    }
    setUploadedFiles(
      accFiles.map((accFile: File) => {
        return { file: accFile, preview: URL.createObjectURL(accFile) };
      })
    );
  };

  if (!params.workspaceId || !params.listingId || !params.listingTitle) {
    return null;
  }
  
  const claimListingForm = () => {
    
    return (
      <VStack w="full" alignItems="flex-start">
        <VStack w="full">
          <Text  pl={{ base: 6, md: 8 }} alignSelf={"flex-start"} paddingBottom={6} className="h4">
            Complete the form below to claim 
            {params.listingTitle}
          </Text>
          <Account
            formRef={accountFormRef}
            formValues={acctFormValues}
            onCountriesChange={(countries: Country[]) => {
             // console.dir(countries)
              setCountryItems(countries)
            }}
            onChange={() => {}}
            onLoad={() => {}}
            dataManager={dataManager}
            updateFormSubmissionStatus={() => {}}
            actionAfterSubmit={() => {}}
          />
        </VStack>
        <VStack w="full">
          <WHBusinessForm bizAcctFormRef={bizAccountFormRef} />
          {/* <BizAccount
            formRef={bizAccountFormRef}
            formValues={bizAcctFormValues}
            onLoad={() => {}}
            dataManager={dataManager}
            updateFormSubmissionStatus={() => {}}
            actionAfterSubmit={() => {}}
          /> */}
        </VStack>
        <Box
          // bg="blue"
          position="relative"
          w="full"
          // h="full"
          //h={{ base: "70%", md: "100%" }}
          borderTopRightRadius={{ base: 12, md: "unset" }}
          borderTopLeftRadius={{ base: 12, md: "unset" }}
        >
          <Formik
            initialValues={values}
            // initialStatus={{isValidating: false}}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yupDescriptionValidation}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              let file: File | undefined = undefined;
            }}
            innerRef={formRef}
          >
            {(props) => {
              return (
                <Form
                //className={styles.form}
                // style={{paddingRight: "2.5em"}}
                >
                  <VStack
                    spacing={{ base: 2, md: 2, lg: 2 }}
                    alignItems="flex-start"
                    marginTop="-32px"
                  >
                    <Text
                      padding={{ base: 6, lg: 8 }}
                      paddingTop="0px !important"
                      // paddingY={{ base: 6, lg: 8 }}
                      w={{ base: "full", md: "75%", lg: "80%" }}
                      className="h6"
                    >
                      {t('Highlight')}
                      {/* Highlight what makes your space a unique place to work */}
                    </Text>
                    <Divider />
                  </VStack>
                  <AddASpaceLayout>
                    <Field name="name">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel
                            fontWeight={500}
                            fontSize={"15px"}

                            //htmlFor="name"
                          >
                            {t('Name your workspace')}
                            {/* Name your workspace */}
                          </FormLabel>
                          <Input
                            onChange={props.handleChange}
                            {...field}
                            name="name"
                            type="text"
                            placeholder="Enterprise Hubs"
                          ></Input>
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="desc">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.desc && form.touched.desc}
                          isRequired
                        >
                          <FormLabel
                            htmlFor="desc"
                            fontWeight={500}
                            fontSize={"15px"}
                          >
                            {t('Description')}
                            {/* Description */}
                          </FormLabel>
                          <Textarea
                            {...field}
                            rounded="lg"
                            name="desc"
                            // value={value}
                            onChange={props.handleChange}
                            placeholder="Here is a sample placeholder"
                            size="md"
                          />
                          <FormErrorMessage>
                            {form.errors.desc}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {/* <VStack w="full">
         <Text w="full" textAlign="left" className="subtitle1">Upload Header Photo (Must not exceed 1mb)</Text>

<Dropzone
          onDrop={handleDrop}
          accept="image/*"
          minSize={1024}
          maxSize={1048576}

         >
          {({ getRootProps, getInputProps }) => (
            <Box
              display="grid"
              placeItems="center"
              rounded="lg"
              w={"full"}
              h={"344px"}
              bg="gray.400"
              {...getRootProps({ className: "dropzone" })}
            >
             {uploadedFiles.length === 0 ?  <MdPhotoCamera fontSize="128px" color="gray"/> : null} 
               <input {...getInputProps()} />
               {uploadedFiles.map((uploadedFile) => (
               
                  <Image
                    key={uploadedFile.preview}
                    objectFit="contain"
                    rounded="lg"
                    h="inherit"
                    w="inherit"
                    src={uploadedFile.preview}

                    //src={image === "" ? uploadedFile.preview : image}
                  />

                  
              ))}
            </Box>
          )}
        </Dropzone>
         </VStack> */}
                  </AddASpaceLayout>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <VStack  
        w={{ base: "full", md: "30%", lg: "30%" }}
        spacing={4} 
          padding={{ base: "1.5em", md: "1.5em 2em", lg: "2em" }} paddingTop={"0px !important"} alignItems="flex-start">
          <Text className="subtitle">
            {t('Enter pincode sent to you')}
            {/* Enter pincode sent to you */}
            </Text>

          <HStack>
            <PinInput onChange={onOTPChange} otp>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <Button
          isLoading={isLoading}
          w="full"
          marginTop={8}
          colorScheme="primary"
          width="40%"
          height="48px"
          onClick={ async ()=>{
            setIsLoading(true)
            await submitData()
            setIsLoading(false)
          }}
        >
          {t('Submit')}
          {/* Submit */}
        </Button>
        </VStack>
       
      </VStack>
    );
  };

  const successAlert = () => {
    return (
      // <LayoutView >
        <VStack w="full" h="100vh">
        <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {params.listingTitle} Claimed
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {t('Congratulations!')}
          {/* Congratulations! You have successfully claimed */}
           {params.listingTitle}.
           {t('You can login with your partner')}
          {/* You can login with your partner account to edit this listing */}
        </AlertDescription>
        
        {/* <HStack w="auto" spacing={4}>
          <Button
            variant="outline"
            colorScheme="primary"
            onClick={() => {
              history.push(["/office", params.listingId, "detail"].join("/"));
            }}
          >
            Continue Browsing
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              history.push(routes.login.path);
            }}
          >
            Login
          </Button>
        </HStack> */}
      </Alert>
      <Center w={medium || lg || xl || small ? "30%" : "full"}
                   justifyContent={medium || lg || xl ? "space-between" : "space-between"}>
               
                  <ListingLink  to={routes.login.path}>
                   <Button className="button" 
                          w="160px"
                          variant="outline"
                          colorScheme="primary">
                            {t('login')}
                            {/* Login */}
                            </Button>
               </ListingLink>
                
                {/* <Spacer /> */}
                <Text className="body2">OR</Text>
                {/* <Spacer /> */}

                <ListingLink to={["/office", params.listingId, "detail"].join("/")}>
                    <Button className="button" colorScheme="primary"
                          w="160px"
                          >
                            {t("Continue Browsing")}
                            {/* Continue Browsing */}
                            </Button>
                </ListingLink>
            </Center>
        </VStack>
      // </LayoutView>
      
    );
  };

  return (
    <LayoutView>{showAlert ? successAlert() : claimListingForm()}</LayoutView>
  );
};

export default ClaimListing;

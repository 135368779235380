import React, { useContext, useEffect } from 'react'
import { ReactNode } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { SetUserContext } from './Components/AppProvider/AppProvider'
import { signUpDataManager } from './Components/Screens/WHApp/SignUp/SignUpDataManager'
import routes from './routes'

interface DashboardRouteProps {
    children: ReactNode,
    path: string,
   // exact: boolean
}

const DashboardRoute = ({children, ...rest}: DashboardRouteProps) => {
    
    // let registeredUser = useContext(UserContext)
     let setUserContext = useContext(SetUserContext)
    let registeredUser = signUpDataManager.getRegisteredUserData()
    //console.dir(registeredUser)
    useEffect(() => {
        // if (!registeredUser) {
        //     registeredUser = signUpDataManager.getRegisteredUserData()
        //    if (registeredUser) {
        //     setUserContext(registeredUser)
        //   } 
        // }

      
        //    if (registeredUser) {
        //     setUserContext(registeredUser)
        //   } 
        
        
       
      }, [])

    return (
        <Route 
            {...rest}
            render={({location}) => {
                  //console.dir(registeredUser)
                 return registeredUser && registeredUser.user_type == "partners" ? (
                    children
                ): (
                    <Redirect 
                        to={{
                            pathname: routes.addASpace.path, 
                            state: {from: location}
                        }}
                    />
                )
            }}
        />
    )
}



export default DashboardRoute

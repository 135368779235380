import { Button, Circle, HStack, VStack, Text, Divider, useMediaQuery, Icon, IconButton, Link } from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { AiFillClockCircle } from 'react-icons/ai';
import { MdCheck, MdContacts, MdDescription, MdDesktopMac, MdLocationOn, MdNetworkWifi } from 'react-icons/md';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Workspace, WorkspaceLocation } from '../../../../Models/Interfaces';
import { DashboardSectionName } from '../Models/Interfaces';
import { dashboardManager } from '../Models/WHDashboardDataManager';
import { ListingsStateContext, SetWorkspaceContext, WorkspaceContext } from '../WHManageWorkspaces/ManageWorkspacesProvider';
import {
  NavLink as ReactLink
  
} from "react-router-dom";
import routes from '../../../../routes';

import styles from './WHWorkspaceDetail.module.css'
interface SetupButtonProps {
    onClick: () => void;
    label: string;
    sectionName: DashboardSectionName;
    workspace: Workspace | null
    url?: string
    onNavItemSelected?: (name: DashboardSectionName)=>void
    selectedNavItem?: DashboardSectionName
  }

  interface WHDestopNavlistProps {
      url: string
  }

  const getSectionIcon = (sectionName: DashboardSectionName): ReactNode =>{

    const iconWidth = "48px"
   // let icon: ReactNode = <MdDescription  width={iconWidth} height={iconWidth} />
   if (sectionName === DashboardSectionName.Desc) {
     return <MdDescription  width={iconWidth} height={iconWidth} />
   }

   if (sectionName === DashboardSectionName.Amenities) {
     return <MdNetworkWifi width={iconWidth} height={iconWidth} />
   }

   if (sectionName === DashboardSectionName.HOP) {
     return <AiFillClockCircle width={iconWidth} height={iconWidth} />
   }

   if (sectionName === DashboardSectionName.Location) {
     return <MdLocationOn width={iconWidth} height={iconWidth} />
   }

   if (sectionName === DashboardSectionName.ContactDetails) {
     return <MdContacts width={iconWidth} height={iconWidth} />
   }

   if (sectionName === DashboardSectionName.Offices) {
    return <MdDesktopMac width={iconWidth} height={iconWidth} />
  }
}
  
  export const SetupButton = ({ onClick, label, sectionName, workspace, url, 
                                onNavItemSelected, selectedNavItem }: SetupButtonProps) => {
    
    
  
   const [isMedium] = useMediaQuery(["(max-width: 786px)"])
   const [showTitle, setShowTitle] = useState(false)
   
  
    const isDisabled = (name: DashboardSectionName) => {
      if (name === DashboardSectionName.Desc) return false;
  
      if (workspace) {
        switch (name) {
          case DashboardSectionName.Amenities:
             if (workspace.email) {
              return false;
            }
            return true;
          case DashboardSectionName.HOP:
            //icon = <AiFillClockCircle width={iconWidth} height={iconWidth} />
             let location = workspace.location as WorkspaceLocation
            if (
              workspace.street &&
              location &&
              location.city &&
              location.country &&
               location.state
            ) {
              return false;
            }
            return true;
          case DashboardSectionName.Location:
            if (workspace.amenities && workspace.amenities.amenities && workspace.amenities.amenities.length > 0) {
              return false;
            }
            return true;
  
          case DashboardSectionName.Size:
            let hasDays = false;
            let hasHours = false;
            // if (workspace.extra_days) {
            //   let days = workspace.extra_days.days;
            //   if (days && days.length > 0) {
            //     hasDays = true;
            //   }
            // } else {
            //   return true;
            // }
                
             if (workspace.available_hours) {
              let hours = workspace.available_hours;
              let s = hours.start_time;
              let e = hours.end_time;
              if (s && s.length > 0 && e && e.length > 0) {
                hasHours = false;
              }
            } else {
              return true;
            }
  
            if (hasHours) {
              return false;
            }
            return true;
  
          case DashboardSectionName.ContactDetails:
          
            if (workspace.description && workspace.title && workspace.header) {
              return false;
            }
            return true;
  
          // case DashboardSectionName.Size:
          //   if (workspace.size && workspace.capacity > 0) {
          //     return true
          //   } else {
          //     return true
          //   }
  
          default:
            break;
        }
      } else {
        return true;
      }
    };
    
    
    return !isMedium ? (
      <Button
        disabled={isDisabled(sectionName)}
        onClick={onClick}
        variant={"unstyled"}
      >
        {label}
      </Button>
    ):(
       
      <Link
        minH={14}
        w="full"
        as={ReactLink}
        to={url ?? ""}
        activeClassName={styles.selected}
        textDecorationLine="none !important"
        
        isActive={(match: any, location: any)=>{

          if (!match) {
            return false
          } else if (match && onNavItemSelected) {
            onNavItemSelected(sectionName)
            return true
          } else {
            return false
          }
        }}
      >
          <IconButton 
          w="full"
        variant="unstyled"
        aria-label={label}
        
        icon={
          <VStack w="full" alignItems="center" justifyContent="center">
            {getSectionIcon(sectionName)}
            {
              selectedNavItem === sectionName ? (
                <Text 
            
                className="caption" whiteSpace="pre-line" noOfLines={2}>{label}</Text>
              ):null
            }
          
         </VStack>
        }
      />
      </Link>
     
    )
  };

  interface NavlistHeaderProps {
    label: string
    isMedium?: boolean
  }
  
  export const ManageOfficesButton = ({
    url,
    selectedNavItem,
    onNavItemSelected,
    onClick,
    label,
    sectionName,
    workspace
  }: SetupButtonProps) => {
    
    const [isMedium] = useMediaQuery(["(max-width: 786px)"])

     
  
    return !isMedium? (
      <Button
        disabled={!dashboardManager.isDetailSectionComplete(workspace)}
        onClick={onClick}
        variant={"unstyled"}
      >
        {label}
      </Button>
    ): (
      <Link
      minH={14}
      w="full"
      as={ReactLink}
      to={url ?? ""}
      activeClassName={styles.selected}
      textDecorationLine="none !important"
      
      isActive={(match: any, location: any)=>{

        if (!match) {
          return false
        } else if (match && onNavItemSelected) {
          onNavItemSelected(sectionName)
          return true
        } else {
          return false
        }
      }}
    >
      <IconButton 
      variant="unstyled"
      aria-label={label}
      w="full"
      icon={
        <VStack w="full" alignItems="center" justifyContent="center">
          {getSectionIcon(sectionName)}
        {/* <MdDescription width="48px" height="48px" /> */}
        {
          selectedNavItem === sectionName ? (
            <Text className="caption" whiteSpace="pre-line"  noOfLines={2}>{label}</Text>

          ):null
        }
       </VStack>
      }
    />
    </Link>
    )
  };
  
  export const NavlistHeader = ({ label, isMedium }: NavlistHeaderProps) => {

    const workspace = useContext(WorkspaceContext);
    const {state} = useContext(ListingsStateContext)
    //debugger
    //console.dir(workspace)
    let isComplete = false 
    if (label.toLocaleLowerCase().includes("details")) {
        isComplete = dashboardManager.isDetailSectionComplete(workspace)
    } else {
        isComplete = state.responseData.results.length > 0
    }
    
    return (
      <HStack w="full" 
          justifyContent={isMedium ? "center" : "unset"}
          pb={isMedium ? 4 : "unset"}
          >
        {isComplete ? (
          <Circle border="1px solid green" h={4} w={4} bg="green">
            <MdCheck color="white" />
          </Circle>
        ) : (
          <Circle border="1px solid gray" h={4} w={4} bg="#fafafa"></Circle>
        )}
       
        {
          !isMedium ? (
            <Text mb={4} className="body1">
            {label}
          </Text>
          ) : null
        }
       
      </HStack>
    );
  };

const WHDesktopNavlist = ({url}: WHDestopNavlistProps) => {

  const workspace = useContext(WorkspaceContext);
  const setWorkspace = useContext(SetWorkspaceContext) 
  const {url: pageURL} = useRouteMatch()
 
    const history = useHistory()

    useEffect(() => {
       let id = localStorage.getItem("title");
  
      if (id) {
        let workspace = dashboardManager.getWorkspace(id);
  
        if (workspace) {
            setWorkspace(workspace)
        }
      }
      
    }, []);

    const setupSteps = dashboardManager.allSections.map((item, idx) => {
        
      
        
       return item.name !== DashboardSectionName.Size ? (
                        <SetupButton
                        key={item.name}
                        label={item.title}
                        sectionName={item.name}
                        workspace={workspace}
                        onClick={() => {
                            history.push(`${pageURL}/${item.name}`)
                        }}
                      /> ) : null
    
         
        });

    return (
        
             <VStack
            position="fixed"
            left="0px"
            top={{ base: "64px", md: "64px", lg: "72px" }}

           // top="0px"
            h="full"
            w={{ md: "25%", lg: "25%" }}
            bg="#fafafa"
            paddingY={8}
            spacing={4}
          >

                        <VStack
              paddingLeft={6}
              spacing="0px"
              // h="full"
              w="full"
              //paddingX={6}
              alignItems="flex-start"
            >
              <NavlistHeader label={"Details"}/>

              {setupSteps}
            </VStack>

            <VStack
              spacing="0px"
              // h="full"
              width="full"
              paddingX={6}
              alignItems="flex-start"
            >
              <NavlistHeader label={"Office Management"}/>
              <ManageOfficesButton
                workspace={workspace}
                onClick={() => {
                  history.push(`${url}/${DashboardSectionName.Offices}`);
                }}
                sectionName={DashboardSectionName.Offices}
                label={"Manage Offices"}
              />

              
            </VStack>
            <Divider w="full" color="black" />
          </VStack>
        
    )
}

export default WHDesktopNavlist

import { Checkbox, HStack, Text} from '@chakra-ui/react'
import { t } from 'i18next'
import React, { ChangeEvent, useState } from 'react'

interface WHShowPasswordProps {
    onShowPasswordChange: (event: ChangeEvent<HTMLInputElement>)=>void
    showPassword: boolean
}

const WHShowPassword = (props: WHShowPasswordProps) => {


    return (
        <HStack spacing={4} w="full">
        <Checkbox
            colorScheme={"primary"}
            size="lg"
            isChecked={props.showPassword}
            onChange={props.onShowPasswordChange}
        />
        <Text className="subtitle2">{t('showPword')}</Text>
  
      </HStack>
    )
}

export default WHShowPassword

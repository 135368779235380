import { Text } from '@chakra-ui/react';
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
 import { RegistrationResponse } from '../../../../Models/Interfaces';
import { WHManageWorkspacesProps } from '../Models/Interfaces';
import WHWorkspaceDetail from '../WHWorkspaceDetail/WHWorkspaceDetail';
import Workspaces from '../Workspaces/Workspaces';
import ManageWorkspacesProvider, { WorkspaceContext } from './ManageWorkspacesProvider';



const WHManageWorkspaces = ({partner}: WHManageWorkspacesProps) => {

    const {url, path} = useRouteMatch()
 
    return (
        <>
          <Switch>
                  <Route 
                    //path="/dashboard/:bizId/manage+workspaces/:workspaceId/detail"
                    path={`${path}/:wkspcId/detail`}
                    render={()=>{

                        return (
                              <ManageWorkspacesProvider>
                                        <WHWorkspaceDetail partner={partner} />  
                              </ManageWorkspacesProvider>
                        )
                    }}
                />
                  <Route  
                    //path="/dashboard/:bizId/manage+workspaces"
                    path={path}
                    render={()=>{
                        return <Workspaces />
                    }}
                />
                {/* <Route path="*">
                    <Redirect
                    to={dashboardManager.getSectionURL(
                        DashboardSectionName.WKS,
                        URLDir.curr,
                        "me"
                    )}
                    />
              </Route> */}
            </Switch>   
        </>
    )
}

export default WHManageWorkspaces

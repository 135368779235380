import { HStack, VStack, Text, Image, Circle, SimpleGrid, Divider, AspectRatio, Avatar } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { MdStar } from 'react-icons/md';

import personImg from "../../../Assets/chimamanda1.jpeg";
import { PublicListing, ResultType, Review, UserReview } from '../../../../Models/Interfaces';
import { UserContext } from '../../../AppProvider/AppProvider';
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout';
import OfflineView from '../../../SupportingViews/OfflineView';
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner';
import { appDataManager } from '../Models/WHAppDataManager';
import { useTranslation  } from "react-i18next";

interface ReviewItemViewProps {
    item: UserReview
}
export const ReviewItemView = ({item}: ReviewItemViewProps) => {

    //const name = item.user_details.first_name

    return (
      <HStack alignItems="start" w="full" rounded="md" spacing="0px" minH="144px">
         <Avatar name={item.user_details.first_name} src={item.user_details.image ?? ""} />
             {/* {
               item.user_image ? (
                <Image 
                boxSize={{base: "56px", md: "80px", lg: "96px"}}
               //w="37%" h="" 
                  borderRadius="50%"
                  objectFit="cover"   src={item.user_image} />
               )
               : 
               (
                 <Circle 
                    boxSize={{base: "56px", md: "80px", lg: "96px"}}
                    bg="primary.500"
                 />
               )
             } */}
            
 
        <VStack  h="full" w="full" p={{base: 4}} paddingTop="0px" alignItems="flex-start">
          <Text className="h6">{item.title}</Text>
          <Text className="body2">{item.description}
                      </Text>
              
          <HStack className="subtitle1" spacing={4}>
                 <HStack spacing={1}>
                 <MdStar fill={"red"}/>
                 <Text>{item.rating}/5</Text>
                 </HStack>
             </HStack>
           
        </VStack>
      </HStack>
    );

}

interface UserReviewsProps {
  listing: PublicListing
  reviews: UserReview[]
}

const UsersReviews = ({listing, reviews}: UserReviewsProps) => {

    const [userReviews, setUserReviews] = useState<UserReview[]>([])
    const user = useContext(UserContext)
    const [error, setError] = useState<Error | null>(null)
     const [isLoading, setIsLoading] = useState(false)
     const {t} = useTranslation()

    const reviewItemViews =  reviews.map((item, idx) => (
        <ReviewItemView item={item} key={item.user} />
    ))

    const loadReviews = async ()=>{
      
      //if (!user || !user.access_token || !listing.id) { return }
      if (!listing.id) { return }

      setIsLoading(true)
      let rslt = await appDataManager.getListingReviews({listing: listing.id})
      setIsLoading(false)

      if (rslt.type === ResultType.Success) {
        
        setUserReviews(rslt.value)
        setError(null)
      } else {
        setError(rslt.error)
        setUserReviews([])
      }
    }

    useEffect(() => {
         //loadReviews()
      // return () => {
      //   cleanup
      // }
    }, [])

    return (
      <>
      {
        isLoading && 
          <WHSpinner />
      }
      {
        !error && 
          <>
            <VStack
            alignItems="flex-start"
          spacing="0px"
        >
            
        </VStack>
        <AddASpaceLayout>
          {
            reviews.length > 0 ? (
              <SimpleGrid w="full" columns={{ base: 1, md: 2, lg: 3 }} columnGap={4} gap={4}>
              {reviewItemViews}
            </SimpleGrid>
            ) : (
              <Text className="h6">
                {t('No Review Yet')}
                {/* No Review Yet */}
                </Text>
            )
          }
        </AddASpaceLayout>
        </>
      }
      {
        error &&
          <OfflineView 
            onRetry={loadReviews}
            msg={error.message} 
            pt="64px" />
      }
       
      </>
    );
}

export default UsersReviews

import { Box, Divider,  Image,  VStack, Text, Button, Skeleton } from "@chakra-ui/react"
import { off } from "process"
import React from "react"
import { WorkspaceItemViewProps } from "../../../Models/Interfaces"

const WorkspaceItemView = ({imageURL, title, children, officeCount}: WorkspaceItemViewProps) => {


    return (
        <Box shadow="lg" 
            rounded="md" 
            bg="#fff"
           // h="full"
           h="min-content"
            // bg="#c5cae9"
          >
        <Image
              fallback={
                  <Skeleton
                      w="full" 
                    h={"180px"} 
                  />
              }
              bg="gray.300"
               objectFit="contain"
               borderTopLeftRadius="6px"
               borderTopRightRadius="6px"
               w="full" 
               h={"180px"}  src={imageURL} />
        <VStack spacing="0px">
            <VStack w="full" padding={4} spacing="0px" alignItems="flex-start" p={4}>
                <Text noOfLines={1} className="subtitle2">{title}</Text>
                {
                    officeCount ?(
                        <Text className="caption">{officeCount} Offices</Text>
                    ): (
                        <Text className="caption">0 Offices</Text>
                    )
                }
             
                {/* <Text className="caption">Added On 22/8/21</Text> */}
             </VStack>
             {
                 children && (
                    <Divider/>
                 )
             }
                {
                    children
                }
               
            
        </VStack>
    </Box>
    )
}

export default WorkspaceItemView

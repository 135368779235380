import React, {useCallback, useEffect, useState} from 'react'
import { Box, Text, Tabs, Tab, TabList, TabPanel, TabPanels, SimpleGrid, useMediaQuery } from "@chakra-ui/react";
import WHTimepickerItem from '../WHTimepickerItem/WHTimepickerItem';
import { format, parse, parseISO, getTime, eachHourOfInterval, add } from 'date-fns';
import { ChakraProvider, extendTheme, theme  } from "@chakra-ui/react";
import { SelectedDay, SELECTEDMODE } from '../WHBookingFormView/WHBookingFormView';

interface WHTimepickerProps {
    onTimeSelected: (datetime: SelectedDay) => void
    bookedDates: Date[]
    date: Date
    openedHours: Date[]
}



const isOpenedHour = (openedHours: Date[], meridien: string, timeLabel: string, date: Date): boolean => {

    const startTimeStr = timeLabel.split("-")[0]
    const time = startTimeStr + " " + meridien
    const dateTimeItem = getTime(parse(time, "h:mm a", date))

    return openedHours.find(item => getTime(item) === dateTimeItem ) !== undefined ? true : false

}

const WHTimepicker = ({onTimeSelected, bookedDates, date, openedHours}: WHTimepickerProps) => {

    const [meridien, setMeridien] = useState("AM")
    const [selectedTime, setSelectedTime] = useState("")
    const [mode, setMode] = useState("highlighted")

    const amTimeLabels =  ["12:00-1:00 AM", "1:00-2:00 AM", "2:00-3:00 AM", "3:00-4:00 AM", "4:00-5:00 AM", "5:00-6:00 AM", "6:00-7:00 AM", 
    "7:00-8:00 AM", "8:00-9:00 AM", "9:00-10:00 AM", "10:00-11:00 AM", "11:00-12:00 AM"]
    
    const pmTimeLabels =  ["12:00-1:00 PM", "1:00-2:00 PM", "2:00-3:00 PM", "3:00-4:00 PM", "4:00-5:00 PM", "5:00-6:00 PM", "6:00-7:00 PM", 
    "7:00-8:00 PM", "8:00-9:00 PM", "9:00-10:00 PM", "10:00-11:00 PM", "11:00-12:00 PM"]
     
    const [xsmall] = useMediaQuery([
      "(max-width: 599px)"
    ]);
     

    useEffect(()=>{
        
        
        const startTimeStr = format(date, "h:mm a").split(" ")[0]
        const endTimeStr = format(add(date, {hours: 1}), "h:mm a").split(" ")[0]
  
        const meridienStr = format(date, "h:mm a").trim().split(" ")[1]
        
        const selectedTimeIntervalStr = `${startTimeStr}-${endTimeStr} ${meridienStr}`
        setSelectedTime(selectedTimeIntervalStr)
        setMeridien(meridienStr)
        //[startTimeStr, endTimeStr, meridienStr]
  
      }, [date])

    const onSelectTime = useCallback(
        (timelabel)=> {
            
           if (isDisabled(timelabel)) {return}
           let tempMode = "selected"
           const timeLabels = meridien === "AM" ? amTimeLabels : pmTimeLabels
           
          if (timelabel === selectedTime.split(" ")[0].trim()) {
            if (mode === "highlighted") {
              tempMode = "selected"
             // setMode("selected")
            } else if (mode === "selected") {
              tempMode = "highlighted"
    
              //setMode("highlighted")
            }
          }

          
          const found = timeLabels.find(amTimelabelItem => amTimelabelItem.includes(timelabel))
          if (found) {
             setSelectedTime(found)
             setMode(tempMode)
          }
      },
        [selectedTime, mode, meridien],
      )
     
    // convert selected timeLabel to dateTime object in workspace time zone
    useEffect(()=>{

        if (selectedTime.length === 0) {return}

        const startTimeStr = selectedTime.split("-")[0]
        const time = startTimeStr + " " + meridien
        const selectedDate = parse(time, "h:mm aaaa", date)
        
        onTimeSelected({date: selectedDate, selectedMode: mode as SELECTEDMODE})
    }, [selectedTime, mode])

     
   
     

    const isDisabled = (timeLabel: string)=> {

        if (bookedDates === undefined || bookedDates.length === 0) { return false}
        const startTimeStr = timeLabel.split("-")[0]
        const time = startTimeStr + " " + meridien
        const dateTimeItem = getTime(parse(time, "h:mm a", date))
        
        const bookedDateTimeItems =  bookedDates.map(bookedDateItem => getTime(bookedDateItem))

        //let found = bookedDateTimeItems.find(item => item === dateTimeItem) !== undefined ? true : false
    
        return bookedDateTimeItems.find(item => item === dateTimeItem) !== undefined ? true : false


    }
    
    return (
        // <ChakraProvider  >
        <Tabs w="full" 
              index={meridien === "AM" ? 0 : 1}
              variant="soft-rounded" onChange={(index)=> setMeridien(index === 0 ? "AM" : "PM")} 
              defaultIndex={meridien === "AM" ? 0 : 1} 
              colorScheme='primary'>
        <TabList >
          <Tab>AM</Tab>
          <Tab>PM</Tab>
        </TabList>
        <TabPanels  >
          <TabPanel paddingX="0px" >
            <SimpleGrid   spacing={xsmall ? 1 : 4} columns={3}>
             {
               amTimeLabels.map( timeItem => <WHTimepickerItem
                                 mode={mode}
                                w="full"
                                h="32px"
                                isDisabled={isDisabled(timeItem) 
                                    //|| !isOpenedHour(openedHours, meridien, timeItem, date)
                                }
                                isSelected={timeItem === selectedTime && meridien === "AM"} 
                                onSelectTime={onSelectTime}
                                key={timeItem}
                                timeLabel={timeItem.split(" ")[0].trim()} 
                                />)
              }
            </SimpleGrid>
            
          </TabPanel>
          <TabPanel paddingX="0px">
           <SimpleGrid spacing={xsmall ? 1 : 4} columns={3}>
             {
               pmTimeLabels.map(timeItem => <WHTimepickerItem 
                mode={mode}
                w="full"
                h="32px"
                isDisabled={isDisabled(timeItem) 
                   // || !isOpenedHour(openedHours, meridien, timeItem, date)
                }
                isSelected={timeItem === selectedTime && meridien === "PM"} 
                onSelectTime={onSelectTime}
                key={timeItem}
                timeLabel={timeItem.split(" ")[0].trim()} 
                />)
              }
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    //   </ChakraProvider>
    )
}

export default WHTimepicker

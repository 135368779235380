import { Divider, Link, StackDivider, useMediaQuery } from '@chakra-ui/react'
import React, { FC, ReactNode, useContext } from 'react'
import routes from '../../../routes'
import { useListingCategories } from '../../../utils/Hooks/useListingCategories'
import { NavLink as ReactLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface OfficeCategoryLinksProps {
    onClose?: ()=>void
}

const OfficeCategoryLinks = ({onClose}: OfficeCategoryLinksProps) => {

    let {data: officeCategories, loading, error} = useListingCategories(1) //useContext(CategoriesContext)
    const [isMedium] = useMediaQuery(["(min-width: 1239px)"])
    const {t} = useTranslation()

    const officeLinks = officeCategories.map((item, _) => {

        // let name = ""
        //  if (item.title.toLocaleLowerCase().includes("meeting")) {
        //    name = routes.meetingRooms.name
        //  } else if (item.title.toLocaleLowerCase().includes("virtual")) {
        //    name = routes.virtualRooms.name
        //  } else if (item.title.toLocaleLowerCase().includes("private")) {
        //    name = routes.privateOffices.name
        //  } else  {
        //    name = routes.sharedOffices.name
        //  }
        //item.title.toLowerCase().replaceAll(' ','')
            return (
                
                <Link
                w="full"
                onClick={onClose}
                key={item.id}
                color="primary.500"
                as={ReactLink}
                textDecoration="none !important"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontWeight="400 !important"
                h={8}
                className={isMedium ? "subtitle1" : "body2"}
                to={`/${routes.officeCategory.name}/${item.id}`}
                
            >
                {t(item.title.toLowerCase().replaceAll(' ',''))} 
            </Link>
            
            
        )
    })

    return (
        <>
         {officeLinks}
        </>
    )

     //return officeLinks
}

export default OfficeCategoryLinks

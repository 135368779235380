import {
  VStack,
  Text,
  HStack,
  Divider,
  Box,
  Image,
  Circle,
  Spacer,
  AspectRatio,
  GridItem,
  useBreakpointValue,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import OfficeItemView from "../../../../SupportingViews/OfficeItemView/OfficeItemView";
import homeImg1 from "../../../../../Assets/workspace4.jpg";
import { WorkhopperAPIManager } from "../../../../../Models/WorkhopperAPIManager";
import { useParams } from "react-router-dom";
import { DurationCategory, EnquiredItem, ResultType } from "../../../../../Models/Interfaces";
import WHSpinner from "../../../../SupportingViews/WHSpinner/WHSpinner";
import OfflineView from "../../../../SupportingViews/OfflineView";
import { add, format, sub } from "date-fns";
import { getEnddate } from "../../../WHApp/ConfirmPay/BookingFormView";
import { BOOKINGSTATUS, PartnerBooking } from "../../Models";
import { getDurationCategory, getDurationTitle } from "../../../../../utils/utils";
import { SetErrorContext, SetSuccessContext, UserContext } from "../../../../AppProvider/AppProvider";
import { BookedItem, UserBooking } from "../Models/Booking";
import ItemPriceDetail from "../../../../SupportingViews/ItemPriceDetail/ItemPriceDetail";
import { RequestsContext, SetRequestsContext } from "../WHManageRequestsProvider";

interface BookedItemViewProps {
  bookedItem: UserBooking
}

const BookedItemView = (props: BookedItemViewProps) => {
 
    

}

const RequestUserDetail = () => {
  const isEnquiry = false;
  const params: { bizId?: string, requestType?: string, requestItemId?: string } = useParams();

  const colspan = useBreakpointValue({ base: 4, sm: 4, md: 6, lg: 6, xl: 6 });
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusUpdating, setIsStatusUpdating] = useState<{updating: boolean, status?: BOOKINGSTATUS}>({updating: false});

  const [error, setError] = useState<Error | null>(null);
  const [item, setItem] = useState<EnquiredItem | null>();
  const [bookedItem, setBookedItem] = useState<PartnerBooking | null>();

  const [fetchCount, setFetchCount] = useState(0);
  const user = useContext(UserContext) 
  const setErrorContext = useContext(SetErrorContext)
  const setSuccessContext = useContext(SetSuccessContext)

  const enquiriesData = useContext(RequestsContext)
  const setEnquiresData = useContext(SetRequestsContext)
  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = () => setIsOpen(false)
  

  const loadingEnquiry = useCallback(async (requestItemId: string, token: string)=>{
    setIsLoading(true)
     const result = await WorkhopperAPIManager.shared.getEnquiry(
      requestItemId, token
    );
    if (result.type === ResultType.Success) {
      setItem(result.value);
      setBookedItem(null)
    } else {
      setError(null);
    }
    setIsLoading(false);
  },[])

  const loadingBooking = useCallback(async (requestItemId: string, token: string)=>{
    setIsLoading(true)
    const result = await WorkhopperAPIManager.shared.getBooking(
      requestItemId, 
      token
    );
    if (result.type === ResultType.Success) {
      //console.dir(result)
   
      setBookedItem(result.value);
      setItem(null)
    } else {
      setError(null);
    }
    setIsLoading(false);
  },[bookedItem, item])

  const updateStatus = useCallback(async (bookedItemId: string, token: string, status: BOOKINGSTATUS)=>{
   // setIsStatusUpdating({...isStatusUpdating, updating: true})
    //debugger
    let result = await WorkhopperAPIManager.shared.updateBookingStatus(bookedItemId, token, status)
    if (result.type === ResultType.Success) {
      const updatedBooking = result.value
      setBookedItem(result.value);
      let bookedItemIndex = enquiriesData.findIndex(enquiryItem => enquiryItem.itemId === updatedBooking.id.toString())
      if (bookedItemIndex > -1) {
        let item = {...enquiriesData[bookedItemIndex], status: updatedBooking.status}
        setEnquiresData([...enquiriesData.slice(0, bookedItemIndex), item, ...enquiriesData.splice(bookedItemIndex+1)])
      }
      setItem(null)
      setSuccessContext({title: "", msg: `Booking ${status} successfully`})
    } else {
      setErrorContext({title: "", msg: result.error.message});
    }
    
   // setIsStatusUpdating({...isStatusUpdating, updating: false})

  }, [bookedItem, isStatusUpdating.updating])

  useEffect(() => {
 
      if (!params.bizId || !params.requestItemId || !params.requestType) {
        return;
      }
     
      if (!user || !user.access_token) return

      if (params.requestType === "bookings") {
          loadingBooking(params.requestItemId, user.access_token)
      } else {
          loadingEnquiry(params.requestItemId, user.access_token)
      }
      
     
   

    return () => {};
  }, [user?.access_token]);

  const requestedItem = (image: string, category: string, title: string) => {
    return (
      <Box border="1px solid #eee" rounded="xl" w="inherit" minH="240px">
        <AspectRatio ratio={1.07 / 0.7}>
          <Image
            borderTopRadius="10px"
            w="full"
            //h="244px"
            src={image}
          />
        </AspectRatio>
        <VStack alignItems="flex-start" spacing={2} p={4}>
          <Text className="h6">{title}</Text>
          <Text className="subtitle1">{category}</Text>
        </VStack>
      </Box>
    );
  };

  const paymentDetailsView = () => {
    //console.dir(bookedItem)
    
    return bookedItem ? (
      <VStack alignItems="flex-start" w="inherit">
        <ItemPriceDetail 
          currency={bookedItem.currency}
          date={bookedItem.start_date}
          amount={bookedItem.amount}
          duration={bookedItem.duration}
          durationType={getDurationCategory(bookedItem.duration_type)}
          endDate={bookedItem.end_date}
        />
         <Text className="body1">Total</Text>
        <HStack>
          <Text className="subtitle2" fontSize="18px !important">
            {bookedItem.currency === "NGN" ? "₦" : "$"} {(Number(bookedItem.amount).toFixed(2))}
          </Text>
          </HStack>
        {/* <Text className="body2">Payment Details</Text>
        <Text className="body1">Office Price</Text>
        <HStack>
          <Text  className="subtitle2" fontSize="18px !important">
            $ {bookedItem.amount}
          </Text>
        </HStack>
        <Text className="body1">Total</Text>
        <HStack>
          <Text className="subtitle2" fontSize="18px !important">
            $ {Number(bookedItem.amount) * bookedItem.duration}
          </Text>
          <Circle h="4px" bg="primary.500" w="4px" />
          <Text> { bookedItem.amount } x {bookedItem.duration} &nbsp;
          {getDurationTitle(bookedItem.duration_type).toLowerCase()}(s)
          </Text>
        </HStack> */}
        <Divider />
        <Text className="body2">Booking Status</Text>
        <Text textTransform="capitalize" className="subtitle2" fontSize="18px !important">
            {bookedItem.status === BOOKINGSTATUS.PAID ? BOOKINGSTATUS.APPROVED : bookedItem.status}
          </Text>
        <Divider />
        <Text className="body2">Payment Status</Text>
        <Text textTransform="capitalize" className="subtitle2" fontSize="18px !important">
            {bookedItem.is_paid ? "Paid" : "Not Paid"}
          </Text>
        <Divider />
        {
          bookedItem.is_paid ? (
            <>
            <Text className="body2">Date Paid</Text>
            <Text className="subtitle2" fontSize="18px !important">{format(new Date(bookedItem.date_paid), "PPpp")}</Text>
            </>
          ):null
        }
      </VStack>
    ):null
  };

  const enquiryDetailView = (type: string) => {
    return (
      <VStack alignItems="flex-start" w="inherit">
        <Text className="body2">{type === "enquiries" ? "Enquiry" : "Booking"}</Text>
        <Text className="subtitle1">
           {item?.description ?? "N/A"}
        </Text>
        <Divider />
      </VStack>
    );
  };

  const contactDetailView = (type: string) => {
    // debugger
     const email = type === "bookings" ? bookedItem?.extra_details.email : item?.company_email
     return (
      <HStack className="subtitle2" fontSize="18px !important">
        <Text>Email</Text> <Circle h="4px" bg="primary.500" w="4px" />
        <Text className="body2">{email ?? "N/A"}</Text>
      </HStack>
     )
     
  }

  const durationView = (type: string) => {

      return (
        <VStack alignItems="flex-start" w="inherit">
        <Text className="body2">Duration</Text>
         

         {
             item && item.start_date && item.duration ?  (
              <HStack>
              <Text className="subtitle2" fontSize="18px !important">
                {format(new Date(item.start_date), "PP")} - {format(getEnddate(item.duration_type[0].toUpperCase() + item.duration_type.substr(1)  as DurationCategory, new Date(item.start_date), item.duration), "PP")}
              </Text>
              
            </HStack>
           ):  bookedItem ? (
            <HStack>
            <Text className="subtitle2" fontSize="18px !important">
            {format(new Date(bookedItem.start_date), "Pp")} - {format(new Date(bookedItem.end_date), "Pp")}

              {/* {format(new Date(bookedItem.start_date), "PP")} - {format(getEnddate(bookedItem.duration_type[0].toUpperCase() + bookedItem.duration_type.substr(1) as DurationCategory, new Date(bookedItem.start_date), bookedItem.duration), "PP")} */}
            </Text>
            
          </HStack>
           ) : (
            <Text className="body2">"N/A"</Text>
           )
         }
        
        <Divider />
      </VStack>
      )
  }

  return (
    // <VStack w="full" paddingY={4}>
    <>
      {
      isLoading ? (
         <VStack h="50vh" w="90vw" alignItems={"center"} >
             <WHSpinner w="full" pt="64px" />
          </VStack>
        //
      ) : error ? (
        <OfflineView
          msg={error.message}
          pt="64px"
          onRetry={() => {
            setFetchCount((prev) => prev + 1);
          }}
        />
      ):  (
        <>
          <GridItem colSpan={colspan} rowSpan={12}>
            <Text
              alignSelf="flex-start"
              className="subtitle2"
              fontSize="18px !important"
              textTransform="capitalize"
            >
              {params.requestType === "enquiries" ? `Enquiry by ${item?.first_name} ${item?.last_name}` : `Booking Information of ${bookedItem?.extra_details.first_name} ${bookedItem?.extra_details.last_name}`}
            </Text>
            <Spacer h={4} />
             {
               bookedItem ? (
                requestedItem(bookedItem.extra_details.header, bookedItem.extra_details.category, bookedItem.extra_details.listing_name)
               ): item ? (
                requestedItem(item.extra_details.header, item.extra_details.category, item.extra_details.listing_name)

               ): null
             }
             {/* {requestedItem()} */}
          </GridItem>
         
          <GridItem colSpan={colspan} rowSpan={18}>
            <VStack alignItems="flex-start" w="inherit">
                
              <Text className="body2">
                {params.requestType === "enquiries"? "Enquired On" : "Booked On"}
              </Text>
              <HStack>
                 {
                   item ? (
                    <Text className="subtitle2" fontSize="18px !important">
                  
                    { format(new Date(item.date_created), "PP")}
                  </Text>
                   ): bookedItem ? (
                    <Text className="subtitle2" fontSize="18px !important">
                  
                    { format(new Date(bookedItem.date_booked), "PP")}
                  </Text>
                   ):null
                 }
              </HStack>
              <Divider />
            </VStack>
            {
                params.requestType === "enquiries" ? (
                     durationView(params.requestType)
                ):null
            }
           
            {/* <VStack alignItems="flex-start" w="inherit">
              <Text className="body2">Number of Guests</Text>
              <Text className="subtitle2" fontSize="18px !important">
                2
              </Text>
              <Divider />
            </VStack> */}
            {params.requestType === "enquiries" ? enquiryDetailView(params.requestType) : paymentDetailsView()}
            <VStack alignItems="flex-start" w="inherit" h="full">
              <Text className="body2">Contact Details</Text>
                {
                   params.requestType ? (
                    contactDetailView(params.requestType)
                  ): null
                }
              
              {
                 item && params.requestType === "enquiries" && item.phone ? (
                    <HStack className="subtitle2" fontSize="18px !important">
                    <Text>Phone Number: {item.phone}</Text>
                   
                  </HStack>
                  ):  null

              }
              <Divider />
              {   
                  bookedItem && !bookedItem.is_paid ? (
                      <HStack>
                      <Button  
                          isLoading={isStatusUpdating.status === BOOKINGSTATUS.APPROVED ? isStatusUpdating.updating: false}
                          disabled={bookedItem.status === BOOKINGSTATUS.APPROVED}
                          colorScheme="whatsapp" 
                          className="button" 
                          onClick={()=>{
                            setIsStatusUpdating({...isStatusUpdating, status: BOOKINGSTATUS.APPROVED})
                            setIsOpen(true)
                          }
                      //       async ()=>{
                      //   if (!user?.access_token) {return}
                         
                      //  updateStatus(bookedItem.id.toString(), user.access_token, BOOKINGSTATUS.APPROVED)
                      // }
                      }>
                         {bookedItem.status ===  BOOKINGSTATUS.APPROVED ?  "Approved" : "Approve"} 
                      </Button>

                      <Button  
                          isLoading={isStatusUpdating.status === BOOKINGSTATUS.CANCELLED ? isStatusUpdating.updating : false}
                          disabled={bookedItem.status === BOOKINGSTATUS.CANCELLED}
                          colorScheme="secondary" 
                          className="button" 
                          onClick={()=>{
                            setIsStatusUpdating({...isStatusUpdating, status: BOOKINGSTATUS.CANCELLED})
                            setIsOpen(true)
                          }}
                      //     onClick={async ()=>{
                      //   if (!user?.access_token) {return}
                      //    updateStatus(bookedItem.id.toString(), user.access_token, BOOKINGSTATUS.CANCELLED)
                      // }}
                      >
                          {bookedItem.status ===  BOOKINGSTATUS.CANCELLED ? "Cancelled" : "Cancel"  }  
                      </Button>
                    </HStack>
                  ):null
                  
              }
              <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={undefined}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {isStatusUpdating.status === BOOKINGSTATUS.APPROVED ? "Approve Booking" : "Cancel Booking"}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="primary" onClick={async ()=>{
                        if (!user?.access_token || !bookedItem?.id || !isStatusUpdating.status) {return}
                         //const statusToUpdate = isStatusUpdating.status === BOOKINGSTATUS.CANCELLED ? BOOKINGSTATUS.CA
                         onClose()
                         setIsStatusUpdating({...isStatusUpdating, updating: true})

                         await updateStatus(bookedItem.id.toString(), user.access_token, isStatusUpdating.status)
                         setIsStatusUpdating({...isStatusUpdating, updating: false})

                        
                     }}>
                Yes
              </Button>
              <Button colorScheme='secondary' onClick={()=>{
                  //setIsStatusUpdating({...isStatusUpdating, updating: true, status: BOOKINGSTATUS.CANCELLED})
                  setIsStatusUpdating({...isStatusUpdating, updating: false})
                  onClose()
              }} ml={3}>
                No
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
            </VStack>
          </GridItem>
        </>
      ) 
    }
    </>
    // </VStack>
  );
};

export default RequestUserDetail;

import { Box, Link, Table, Tbody, Td, Th, Thead, Tr, Text, HStack, Spacer, Button, Modal, ModalOverlay,   ModalHeader, ModalFooter, ModalContent, ModalBody, useMediaQuery, ModalCloseButton, Divider, useDisclosure } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {  NavLink as ReactLink, useHistory, useParams, useRouteMatch} from "react-router-dom";
import { RequestItem, ResultType, Result, EnquiredItem } from '../../../../Models/Interfaces';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { ModalContext, SetErrorContext, UserContext } from '../../../AppProvider/AppProvider';
import TandC from '../../../SupportingViews/TandC/TandC';
import { BreakPoint } from '../../WHApp/OfficeDetail/Models/Interfaces';
import { BOOKINGSTATUS, PartnerBooking } from '../Models';
import { RequestsContext, SetRequestsContext } from './WHManageRequestsProvider';

interface EnquiryTableProps {
    enquiredItems: RequestItem[]
    requestType: string
}

interface CellLinkProps {
    content: string
    url: string
}

const CellLink = ({content, url}: CellLinkProps)=> {
    return (
        <Link as={ReactLink} to={url}>
          <Td>{content}</Td>
        </Link>
    )
}

const EnquiresTable = ({enquiredItems, requestType}: EnquiryTableProps) => {
  
    const history = useHistory()
    const user = useContext(UserContext) 
    //const {modal, setModal} = useContext(ModalContext)
    const setError = useContext(SetErrorContext)
    const setEnquiredItems = useContext(SetRequestsContext)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState<RequestItem | undefined>()
    const enquiriesData = useContext(RequestsContext)


    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 905px) and (max-width: 1439px)",
        "(min-width: 1440px)"
      ]);

      const onAcceptTerms = useCallback(async ()=>{
                if (!user?.access_token || !selectedRow) {return }
         setIsLoading(true)
        
         let result:  Result<EnquiredItem | PartnerBooking> //<EnquiredItem | PartnerBooking>
         if (requestType === "enquiries") {
            result = await WorkhopperAPIManager.shared.updateEnquiredItem(
                selectedRow.itemId,
                user.access_token,
                { is_accept_term: true }
              );
         } else {
            result = await WorkhopperAPIManager.shared.updateBookedItem(
                selectedRow.itemId,
                user.access_token,
                { is_accept_term: true }
              );
         }
          
       
       
        if (result.type === ResultType.Success) {
        
           let item = {...selectedRow, is_accept_term: result.value.is_accept_term}
           const idx = enquiriesData.findIndex((searchItem)=> searchItem.itemId === item.itemId)
           if (idx < 0) {
             return
           }

           const updatedItems = [...enquiriesData.slice(0, idx), item, ...enquiriesData.slice(idx + 1)]
           setEnquiredItems(updatedItems)
           setIsLoading(false)
           history.push(selectedRow.detailURL)
           
        }else {
             setError({title: "", msg: result.error.message})
        }
        setIsLoading(false)

      }, [enquiredItems, selectedRow])

    // const closeModal = useCallback(()=>{
    //     if (!modal) {return}
    //     setModal({...modal, isModalOpen: false })
    // }, [modal])

    const policyView = ()=>{
        return (
            <Box w="full" h="inherit">
                <Text className="body2">
                    Sharing a workspace  encourages the need for collaboration.
                     It saves money and reduces boredom and isolation. Gig jobs are exceedingly popular these days as many youngsters, techpreneurs and freelancers like to take their work where they can find others like themselves just for the fun of working together. Success via collaboration has borne the need for your various preferred shared workspace amidst limitations of available alternatives caused by the pandemic. 
                     Sharing a workspace  encourages the need for collaboration.
                     It saves money and reduces boredom and isolation. Gig jobs are exceedingly popular these days as many youngsters, techpreneurs and freelancers like to take their work where they can find others like themselves just for the fun of working together. Success via collaboration has borne the need for your various preferred shared workspace amidst limitations of available alternatives caused by the pandemic.
                     Sharing a workspace  encourages the need for collaboration.
                     It saves money and reduces boredom and isolation. Gig jobs are exceedingly popular these days as many youngsters, techpreneurs and freelancers like to take their work where they can find others like themselves just for the fun of working together. Success via collaboration has borne the need for your various preferred shared workspace amidst limitations of available alternatives caused by the pandemic.
                </Text>
                {/* <HStack>
                    <Spacer />
                    <Button colorScheme="secondary">
                        Decline
                    </Button>
                    <Button colorScheme="whatsapp">
                        Accept
                    </Button>
                </HStack> */}
            </Box>
        )
    }

    const navigeteToDetail = (item: RequestItem) => {

    }

   

    const rowItemViews = enquiredItems.map((row, idx)=> (
        
        <Tr key={row.id} onClick={
                             ()=>{
                            if (row.is_accept_term) {
                                history.push(row.detailURL)
                                return
                            }
                            setSelectedRow(row)
                            onOpen()
                        
                        }
                    //()=>{history.push(row.detailURL)}
                    }>
         <Td>{idx + 1}</Td>
          {/* <Td>{row.id}</Td> */}
            <Td textTransform="capitalize">{row.name}</Td>
             <Td style={{textTransform: "capitalize"}}>{row.is_accept_term ? row.userName : "######"}</Td>
             <Td>{row.category}</Td>
             <Td>{row.is_accept_term ? row.dateCreated : "######"}</Td>
             {
                 requestType === "bookings" ? (
                    <Td textTransform="capitalize">{row.status === BOOKINGSTATUS.PAID ? BOOKINGSTATUS.APPROVED : row.status}</Td>
                 ):null
             }
             {
                 requestType === "bookings" ? (
                    <Td textTransform="capitalize">{row.payStatus}</Td>
                 ):null
             }
        </Tr>
))

        return (
                    
            <Box w="full" h="full" overflowY="scroll" overflowX="scroll" paddingBottom={8}>
                <Table  variant="simple">
                    <Thead>
                        <Tr>
                            <Th>{t('Id')}</Th>
                            <Th>{t('Office')}</Th>
                            <Th>{t('User')}</Th>
                            <Th>{t('Category')}</Th>
                            <Th>{requestType === "bookings" ? t("Date Booked") : t("Date Enquired")}</Th>
                            {
                                requestType === "bookings" ? (
                                    <Th>{t('Booking Status')}</Th>
                                ):null
                            }
                            {
                                requestType === "bookings" ? (
                                    <Th>{t('Payment Status')}</Th>
                                ):null
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rowItemViews}
                    </Tbody>
                </Table>
                <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={xsmall ? "md" : "2xl"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            position={xsmall ? "fixed" : "unset"}
            bottom={xsmall ? "0px" : "unset"}
            mb={xsmall ? "0" : "unset"}
             borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              {t("Terms and Conditions")}
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody> 
                <TandC />
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Spacer />
              <Button 

                    isLoading={isLoading}
                    colorScheme="primary"
                    onClick={()=>{
                      onAcceptTerms()
                      //onApplyFilters()
                      onClose()
                    }}
               >
                        {t("Accept")}
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
            </Box>
        )
}

export default EnquiresTable

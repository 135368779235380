import { Spinner, VStack } from '@chakra-ui/react'
import React from 'react'

interface WHSpinnerProps {
    w?: string
    h?: string
    pt?: string 
}

const WHSpinner = ({w, h, pt}: WHSpinnerProps) => {
    return (
        <VStack pt={pt ?? "0px"} alignItems="center" 
              // justifyContent="center" 
               w={w ?? "100vw"} h={h ?? "100vh"}>
             <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary.500"
        size="lg"
        />
        </VStack>
       
    )
}

export default WHSpinner

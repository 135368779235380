import {
  Box,
  Checkbox,
  Center,
  Text,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  VStack,
  Textarea,
  Image,
  Spacer,
  FormErrorMessage,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikConfig, FormikProps, useFormikContext } from "formik";
import { access } from "fs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { MdClose, MdFileUpload, MdPhotoCamera } from "react-icons/md";
import { TypeOf } from "yup";
import { BookingDuration, ListingImage1, ListingImage2,  ListingImage3, ListingImage4, ResultType, WorkHopperCurrency } from "../../../../../Models/Interfaces";
import { WorkhopperAPIManager } from "../../../../../Models/WorkhopperAPIManager";
import { mapOfficeCategory, truncateTo } from "../../../../../utils/utils";
import { CategoriesContext, CurrenciesContext, SetErrorContext } from "../../../../AppProvider/AppProvider";
import AddASpaceLayout from "../../../../SupportingViews/AddASpaceLayout/AddASpaceLayout";
import { appDataManager } from "../../../WHApp/Models/WHAppDataManager";
import {
  AdminStateActions,
  PrivateOfficeForm,
  PrivateOfficeFormViewProps,
  UploadedFile,
} from "../../Models/Interfaces";
import { yupOfficeValidation } from "../../Models/Schema/OfficeFormSchema";
import { dashboardManager } from "../../Models/WHDashboardDataManager";
import { ListingsStateContext } from "../../WHManageWorkspaces/ManageWorkspacesProvider";
import { PrivateOfficeFormRefContext } from "../../WHWorkspaceDetail/OfficesViewProvider";
import bannerPlaceholder from "../../../../../Assets/banner-icon.png"
import { WorkhopperPublicAPIManager } from "../../../../../Models/WorkhopperPublicAPIManager";
import { number, string } from "yup/lib/locale";
import WHSpinner from "../../../../SupportingViews/WHSpinner/WHSpinner";
import OfflineView from "../../../../SupportingViews/OfflineView";
import ErrorFocus from "../../../../SupportingViews/ErrorFocus/ErrorFocus";
import { t } from "i18next";

interface PricingProps {
  props: FormikProps<PrivateOfficeForm>
  rate: number
  savedBookingAmount: {daily: string, monthly: string, hourly: string, yearly: string} | undefined
}

const Pricing = ({props, rate, savedBookingAmount}: PricingProps) => {
   

  const [convertedHourRate, setConvertedHourRate] = useState(0)
  const [convertedDailyRate, setConvertedDailyRate] = useState(0)
  const [convertedMonthlyRate, setConvertedMonthlyRate] = useState(0)
  const [convertedYearlyRate, setConvertedYearlyRate] = useState(0)

  const convertAmount = (value: string): void => {
    if (!isNaN(Number(value))) {
      setConvertedHourRate(rate * Number(value))
    }
  }


  useEffect(()=>{
    if (props.values.startingPriceByHour !== "") {
      setConvertedHourRate(rate * Number(props.values.startingPriceByHour))
    } 
    // else if (savedBookingAmount && !isNaN(Number(savedBookingAmount.hourly))) {
    //  console.log(Number(savedBookingAmount.hourly))
    //   setConvertedHourRate(Number(savedBookingAmount.hourly))
    // }

    if (props.values.startingPriceByDay !== "") {
      setConvertedDailyRate(rate * Number(props.values.startingPriceByDay))
    } 
    // else if (savedBookingAmount && !isNaN(Number(savedBookingAmount.daily))) {
    //   setConvertedHourRate(Number(savedBookingAmount.daily))
    // }

    if (props.values.startingPriceByMonth !== "") {
      setConvertedMonthlyRate(rate * Number(props.values.startingPriceByMonth))
    }
    //  else if (savedBookingAmount && !isNaN(Number(savedBookingAmount.monthly))) {
    //   setConvertedHourRate(Number(savedBookingAmount.monthly))
    // }

    if (props.values.startingPriceByYear !== "") {
      setConvertedYearlyRate(rate * Number(props.values.startingPriceByYear))
    } 
    // else if (savedBookingAmount && !isNaN(Number(savedBookingAmount.yearly))) {
    //   setConvertedHourRate(Number(savedBookingAmount.yearly))
    // }
    
  }, [rate, props.values])
  
  useEffect(()=>{
    if (savedBookingAmount && !isNaN(Number(savedBookingAmount.hourly))) {
    //  console.log(Number(savedBookingAmount.hourly))
      setConvertedHourRate(Number(savedBookingAmount.hourly))
    }

    if (savedBookingAmount && !isNaN(Number(savedBookingAmount.daily))) {
      setConvertedDailyRate(Number(savedBookingAmount.daily))
    }

    if (savedBookingAmount && !isNaN(Number(savedBookingAmount.monthly))) {
      setConvertedMonthlyRate(Number(savedBookingAmount.monthly))
    }

    if (savedBookingAmount && !isNaN(Number(savedBookingAmount.yearly))) {
      setConvertedYearlyRate(Number(savedBookingAmount.yearly))
    }

  }, [savedBookingAmount])
  
  return (
    <>
      <VStack spacing={6} alignItems="flex-start" w="full">
        <Text fontWeight={500} fontSize={"x"}>
          {t("Pricing")}
        </Text>
        <HStack alignItems="center" w="full" spacing={4}>
          <Text width="18%">{t("Hourly")}</Text>
          <Field name="startingPriceByHour">
            {({ field, form }: any) => {
             //console.dir(form.errors)
             //console.dir(props.errors)
             return <Stack direction={{base: "column", md: "row", lg: "row"}} w="full">
                      <FormControl 
                isInvalid={form.errors.startingPriceByHour && form.touched.startingPriceByHour}
                      w={{base: "100%", md: "50%", lg: "50%"}}
                  >
                  <FormLabel fontWeight={500} fontSize={"15px"}>
                    {t("Price Per Hour")}
                  </FormLabel>
                  <InputGroup>
                  <InputLeftAddon
                    bg="primary.200"
                    children={<Text color="#fff" >{props.values.currency}</Text>}
                   />
                    <Input
                      {...field}
                      onChange={(e)=>{
                        //console.dir(e)
                        //console.log(e.target.value)
                        if (!isNaN(Number(e.target.value))) {
                          setConvertedHourRate(rate * Number(e.target.value))
                        }
                        props.handleChange(e)
                      }}
                      name="startingPriceByHour"
                      min="1"
                      type="number"
                      //placeholder=""
                    ></Input>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.startingPriceByHour}</FormErrorMessage>
                </FormControl>
                <FormControl 
                //isInvalid={form.errors.startingPriceByHour && form.touched.startingPriceByHour}
                      w={{base: "100%", md: "50%", lg: "50%"}}

                  >
                  <FormLabel fontWeight={500} fontSize={"15px"}>
                    {t("Displayed Price")} 
                  </FormLabel>
                  <InputGroup>
                  <InputLeftAddon
                    bg="primary.200"
                    color="#fff"
                    children={"USD"}
                   />
                    <Input
                      value={truncateTo(convertedHourRate, 2)}
                      min="1"
                      type="number"
                      disabled
                      //placeholder=""
                    ></Input>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.startingPriceByHour}</FormErrorMessage>
                </FormControl>
              </Stack>
              
            }}
          </Field>
           
        </HStack>
        <HStack alignItems="center" w="full" spacing={4}>
          <Text w="18%">{t("Daily")}</Text>
          <Field name="startingPriceByDay">
            {({ field, form }: any) => (
            <Stack direction={{base: "column", md: "row", lg: "row"}} w="full">
              <FormControl 
                  isInvalid={form.errors.startingPriceByDay && form.touched.startingPriceByDay}
                  w={{base: "100%", md: "50%", lg: "50%"}}
                >
                <FormLabel
                  fontWeight={500}
                  fontSize={"15px"}
                  // htmlFor="name"
                >
                  {t("Price Per Day")}
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon 
                                      bg="primary.200"
                  color="#fff"
                  children={props.values.currency} />
                  <Input
                    {...field}
                    onChange={(e)=>{
                      props.handleChange(e)
                      if (!isNaN(Number(e.target.value))) {
                        setConvertedDailyRate(rate * Number(e.target.value))
                      }
                    }}
                    min="1"
                    name="startingPriceByDay"
                    type="number"
                    //placeholder=""
                  ></Input>
                </InputGroup>
                <FormErrorMessage>{form.errors.startingPriceByDay}</FormErrorMessage>
              </FormControl>
              <FormControl 
                 // isInvalid={form.errors.startingPriceByDay && form.touched.startingPriceByDay}
                  w={{base: "100%", md: "50%", lg: "50%"}}
                >
                <FormLabel
                  fontWeight={500}
                  fontSize={"15px"}
                  // htmlFor="name"
                >
                  {t("Displayed Price")}
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon
                  color="#fff"                   
                  bg="primary.200"
                   children={"USD"} />
                  <Input
                    value={truncateTo(convertedDailyRate, 2)}
                    min="1"
                    disabled
                    type="number"
                    
                    //placeholder=""
                  ></Input>
                </InputGroup>
               </FormControl>
              </Stack>
            )}
          </Field>
          {/* <Field name="officeAvailableByDay">
            {({ field, form }: any) => (
              <FormControl w="23%">
                <FormLabel fontWeight={500} fontSize={"15px"} htmlFor="office">
                  Available
                </FormLabel>
                <Checkbox
                  size="lg"
                  name="officeAvailableByDay"
                  onChange={props.handleChange}
                />
              </FormControl>
            )}
          </Field> */}
        </HStack>

        <HStack alignItems="center" w="full" spacing={4}>
          <Text w="18%">{t("Monthly")}</Text>
          <Field name="startingPriceByMonth">
            {({ field, form }: any) => (
             <Stack direction={{base: "column", md: "row", lg: "row"}} w="full">
              <FormControl 
                  isInvalid={form.errors.startingPriceByMonth && form.touched.startingPriceByMonth}
                  w={{base: "100%", md: "50%", lg: "50%"}}
                >
                <FormLabel fontWeight={500} fontSize={"15px"} htmlFor="name">
                  {t("Price Per Month")}
                </FormLabel>
                <InputGroup>
                <InputLeftAddon
                  color="#fff"                   
                   bg="primary.200"
                   children={props.values.currency} />
                  {/* <InputLeftAddon children="USD" /> */}
                  <Input
                    {...field}
                    onChange={(e)=>{
                      props.handleChange(e)
                      if (!isNaN(Number(e.target.value))) {
                        setConvertedMonthlyRate(rate * Number(e.target.value))
                      }
                    }}
                    name="startingPriceByMonth"
                    type="number"
                    min="1"

                    //placeholder=""
                  ></Input>
                </InputGroup>
                <FormErrorMessage>{form.errors.startingPriceByMonth}</FormErrorMessage>
              </FormControl>
              <FormControl 
                 // isInvalid={form.errors.startingPriceByDay && form.touched.startingPriceByDay}
                  w={{base: "100%", md: "50%", lg: "50%"}}
                >
                <FormLabel
                  fontWeight={500}
                  fontSize={"15px"}
                  // htmlFor="name"
                >
                  {t("Displayed Price")}
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon
                  color="#fff"                   
                  bg="primary.200"
                   children="USD" />
                  <Input
                    value={truncateTo(convertedMonthlyRate, 2)}
                    min="1"
                    disabled
                    type="number"
                    
                    //placeholder=""
                  ></Input>
                </InputGroup>
               </FormControl>
              </Stack>
            )}
          </Field>
          {/* <Field w="200px" name="officeAvailableByMonth">
            {({ field, form }: any) => (
              <FormControl w="23%">
                <FormLabel fontWeight={500} fontSize={"15px"} htmlFor="office">
                  Available
                </FormLabel>
                <Checkbox
                  size="lg"
                  name="officeAvailableByMonth"
                  onChange={props.handleChange}
                />
              </FormControl>
            )}
          </Field> */}
        </HStack>
        <HStack alignItems="center" w="full" spacing={4}>
          <Text w="18%">{t("Yearly")}</Text>  
          <Field name="startingPriceByYear">
            {({ field, form }: any) => (
            <Stack direction={{base: "column", md: "row", lg: "row"}} w="full">
              <FormControl
              isInvalid={form.errors.startingPriceByYear && form.touched.startingPriceByYear} 
              w={{base: "100%", md: "50%", lg: "50%"}}
                >
                <FormLabel
                  fontWeight={500}
                  fontSize={"15px"}
                  //htmlFor="name"
                >
                  {t("Price Per Year")}
                </FormLabel>
                <InputGroup>
                <InputLeftAddon
                  color="#fff"                   
                  bg="primary.200"
                   children={props.values.currency} />
                  {/* <InputLeftAddon children="USD" /> */}
                  <Input
                    {...field}
                    onChange={(e)=>{
                      props.handleChange(e)
                      if (!isNaN(Number(e.target.value))) {
                        setConvertedYearlyRate(rate * Number(e.target.value))
                      }
                    }}
                    name="startingPriceByYear"
                    type="number"
                    min="1"
                    //placeholder=""
                  ></Input>
                </InputGroup>
                <FormErrorMessage>{form.errors.startingPriceByYear}</FormErrorMessage>
              </FormControl>
              <FormControl 
                 // isInvalid={form.errors.startingPriceByDay && form.touched.startingPriceByDay}
                  w={{base: "100%", md: "50%", lg: "50%"}}
                >
                <FormLabel
                  fontWeight={500}
                  fontSize={"15px"}
                  // htmlFor="name"
                >
                  {t("Displayed Price")}
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon
                  color="#fff"                   
                  bg="primary.200"
                   children={"USD"} />
                  <Input
                    value={truncateTo(convertedYearlyRate, 2)}
                    min="1"
                    disabled
                    type="number"
                    
                    //placeholder=""
                  ></Input>
                </InputGroup>
               </FormControl>
              </Stack>
            )}
          </Field>
           
        </HStack>
      </VStack>
    </>
  );
};

const getImagePos = (pos: number): string[] =>{

  if (pos === 0) {
    return ["0px", "unset", "unset", "0px"]
  } else if (pos === 1) {
    return ["0px", "0px", "unset", "unset"]
  } else if (pos === 2) {
    return ["unset", "0px", "0px", "unset"]
  } else {
    return ["unset", "unset", "0px", "0px"]
  }
}

function getBookingAmount(booking: BookingDuration[], type: string): string {
    const found = booking.find(item => item.minimum_duration.includes(type))
    return found ? found.amount : ""
}

// function getBookingCurrency(booking: BookingDuration[]): string {
//   const found = booking.find(item => item.minimum_duration )
//   return found ? found.amount : ""
// }

const PrivateOfficeFormView = ({
  uploadedFiles,
  uploadedOtherFiles,
  setUploadedFiles,
  setUploadedOtherFiles,
  formRef,
  manager,
  updateFormSubmissionStatus,
  actionAfterSubmit,
  selectedCategory
}: PrivateOfficeFormViewProps) => {
  

  

  const listingsStateContext = useContext(ListingsStateContext);
  //debugger
  const isVirtualOffice = selectedCategory?.toLowerCase().includes("virtual") ?? false

  
  const maxOtherFiles = 4
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const defaultCurrenyCode = "NGN"
  const [formValues, setFormValues] = 
    useState<PrivateOfficeForm>({name: "", capacity: "", 
    price: "", hasWhiteboard: false, description: "", 
    startingPriceByDay: "", startingPriceByHour: "", 
    startingPriceByMonth: "", startingPriceByYear: "",
     accessByDay: "", accessByHour: "", accessByMonth: "",
     isRecieveMails: false, useOfficeAddress: false,
     currency: ""
    })
   
   const currencies = useContext(CurrenciesContext)
   const uploadedOtherFilesRef = useRef<UploadedFile[]>([])
   const [exchangeRate, setExchangeRate] = useState<{base: string, target: string, rate: number}>({base: "", target: "", rate: 1})
   const [isLoadingRate, setIsLoadingRate] = useState(false)
   const [error, setError] = useState<Error>()
   const [savedBookingAmount, setSavedBookingAmount] = useState<{daily: string, monthly: string, hourly: string, yearly: string}>()
  // const {values} = useFormikContext()
   //  const currencyCodeItemViews = currencies.filter(item => item.currency === "USD").map((item, idx) => (
  //   <option key={item.id}>{item.currency}</option>
  // ));

  const currencyCodeItemViews = currencies.map((item, idx) => (
    <option key={item.id}>{item.currency}</option>
  ));
   
  const setErrorContext = useContext(SetErrorContext);

   const handleDrop = (accFiles: File[], rejFiles: FileRejection[]) => {
    if (rejFiles.length > 0) {
      setErrorContext({title: "", msg: "File seems to exceed 1mb. Try again"})
      return
      //if (rejFiles.errors[0].code.includes("too-large"))
    }
     setUploadedFiles(
      accFiles.map((accFile: File) => {
        return { file: accFile, preview: URL.createObjectURL(accFile) };
      })
    );
  };

  const fetchExchangeRate = useCallback(async (base: string, target: string) => {
     
    let result = await WorkhopperPublicAPIManager.shared.getExchangeRate(base, target)
       if (result.type === ResultType.Success) {
        // console.dir(result)
          //const newBase = currencyConverter.currencies.find(item => item.code === base)
          setExchangeRate({...exchangeRate, base, rate: result.value.rate, target})
          setError(undefined)
        //  setCurrencyConverter({...currencyConverter, target: newTarget!, rate: result.value.rate})
       } else {
          setError(result.error)
          setExchangeRate({...exchangeRate, base: "", rate: 1})
       }
   },
[exchangeRate])


  /*
  const handleAnotherDrop = (accFiles: File[], rejFiles: FileRejection[]) => {
    const numUploadedFiles = uploadedOtherFiles.filter((item)=> item.preview !== "invalid")
    //if (uploadedOtherFiles.length === maxOtherFiles) { return }
    if (numUploadedFiles.length === maxOtherFiles) { return }

    let tempUploadedFiles = accFiles.map((accFile: File) => {
      return { file: accFile, preview: URL.createObjectURL(accFile) };
    });
     
    //debugger
    
    for (let acceptedFile of tempUploadedFiles) {
      let idx = uploadedOtherFiles.findIndex((item) => item.preview === "invalid")
      if (idx > -1) {
        console.dir([...uploadedOtherFiles.slice(0, idx), acceptedFile, ...uploadedOtherFiles.slice(idx+1)])
        setUploadedOtherFiles([...uploadedOtherFiles.slice(0, idx), acceptedFile, ...uploadedOtherFiles.slice(idx+1)])
      }
    }

    // let temp = uploadedOtherFiles.slice()
    // for (let acceptedFile of tempUploadedFiles) {
    //   let idx = temp.findIndex((item) => item.preview === "invalid")
    //   if (idx > -1) {
    //     console.dir([...uploadedOtherFiles.slice(0, idx), acceptedFile, ...uploadedOtherFiles.slice(idx+1)])
    //     setUploadedOtherFiles([...uploadedOtherFiles.slice(0, idx), acceptedFile, ...uploadedOtherFiles.slice(idx+1)])
    //   }
    // }
 
    
  };*/

  const handleAnotherDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const numUploadedFiles = uploadedOtherFiles.filter((item)=> item.preview !== "invalid")

    if (rejFiles.length > 0) {
      setErrorContext({title: "", msg: "Files seem to exceed 5mb. Try again"})
      return
      //if (rejFiles.errors[0].code.includes("too-large"))
    }
    //if (uploadedOtherFiles.length === maxOtherFiles) { return }
    if (numUploadedFiles.length === maxOtherFiles) { return }

    let tempUploadedFiles = accFiles.map((accFile: File) => {
      return { file: accFile, preview: URL.createObjectURL(accFile) };
    });

   
     
    //debugger
    
    // for (let acceptedFile of tempUploadedFiles) {
    //   let idx = uploadedOtherFiles.findIndex((item) => item.preview === "invalid")
    //   if (idx > -1) {
    //     console.dir([...uploadedOtherFiles.slice(0, idx), acceptedFile, ...uploadedOtherFiles.slice(idx+1)])
    //     setUploadedOtherFiles([...uploadedOtherFiles.slice(0, idx), acceptedFile, ...uploadedOtherFiles.slice(idx+1)])
    //   }
    // }

    uploadedOtherFilesRef.current = uploadedOtherFiles.slice()
    for (let acceptedFile of tempUploadedFiles) {
      let idx = uploadedOtherFilesRef.current.findIndex((item) => item.preview === "invalid")
      if (idx > -1) {
        console.dir([...uploadedOtherFilesRef.current.slice(0, idx), acceptedFile, ...uploadedOtherFilesRef.current.slice(idx+1)])
        uploadedOtherFilesRef.current = [...uploadedOtherFilesRef.current.slice(0, idx), acceptedFile, ...uploadedOtherFilesRef.current.slice(idx+1)]
      }
    }
    setUploadedOtherFiles(uploadedOtherFilesRef.current)
    
  }, [uploadedOtherFiles])

  const onBaseCurrencySelected = useCallback(async (selected: string)=>{
    const target = currencies.find(item => item.currency === "USD")
     setIsLoadingRate(true)
     if (target && selected) {
        
      // setFormValues()
      //setFormValues({...formValues, startingPriceByDay: "2000", startingPriceByHour: "", startingPriceByMonth: "", startingPriceByYear: ""})
      await fetchExchangeRate(selected, target.currency)
    }
    setIsLoadingRate(false)

 
},[formValues])

 
  

  const officeDropzone = (
    files: UploadedFile[],
    width: string,
    height: string,
    onDrop: (accFiles: File[], rejFiles: FileRejection[]) => void
  ) => {
    return (
      <Dropzone
        onDrop={onDrop}
        accept="image/*"
        minSize={1024}
        maxSize={1048576 * 5}
        //maxSize={3072000}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            rounded="lg"
            display="grid"
            placeItems="center"
            w={width}
            h={height}
            bg="gray.400"
            {...getRootProps({ className: "dropzone" })}
          >
            {uploadedFiles.length === 0 ? (
              <MdPhotoCamera fontSize="128px" color="gray" />
            ) : null}
            <input title="" {...getInputProps()} />
            {/* <p>Drag'n'drop files, or click to select files</p> */}
            {files.map((uploadedFile) => (
              <>
                {/* <Box
                  // bg="gray.500"
                  w="full"
                  h="full"
                > */}
                <Image
                  key={uploadedFile.preview}
                  objectFit="contain"
                  rounded="lg"
                  h="inherit"
                  w="inherit"
                  src={image === "" ? uploadedFile.preview : image}
                />

                {/* </Box> */}
              </>
            ))}
          </Box>
        )}
      </Dropzone>
    );
  };

  useEffect(() => {
    let id = localStorage.getItem("title");

    if (id) {
      let workspace = manager.getWorkspace(id);
      if (workspace) {
      }
      setId(id);
    }

    
    
     
     let selectedId = listingsStateContext.state.selectedListingId

     if (!listingsStateContext.state.responseData?.results) { return }
     //debugger
    if (selectedId) {
     // let selectedIdx = listingsStateContext.state.listings.findIndex((item, _)=> item.id === selectedId)
      let selectedIdx = listingsStateContext.state.responseData.results.findIndex((item, _)=> item.id === selectedId)

      if (selectedIdx >= 0) {
        let selected = listingsStateContext.state.responseData.results[selectedIdx]
        //console.dir(selected)
        setFormValues({...formValues, name: selected.title, capacity: selected.capacity.toString(),
                       hasWhiteboard: selected.is_whiteboard, 
                       description: selected.description, 
                       isRecieveMails: selected.is_receive_mails,
                       useOfficeAddress: selected.use_office_address,
                       startingPriceByDay: "", //getBookingAmount(selected.booking_duration, "day"), //selected.booking_duration[0].amount,
                       startingPriceByHour: "", //getBookingAmount(selected.booking_duration, "hour"), //selected.booking_duration[1].amount,
                       startingPriceByMonth: "", //getBookingAmount(selected.booking_duration, "month"),  //selected.booking_duration[2].amount,
                       startingPriceByYear:  "", //getBookingAmount(selected.booking_duration, "year"), //selected.booking_duration[3].amount
                       currency: selected.booking_duration.length > 0? selected.booking_duration[0].currency : formValues.currency
          })

          setSavedBookingAmount({
            daily: getBookingAmount(selected.booking_duration, "day"),
            hourly: getBookingAmount(selected.booking_duration, "hour"),
            monthly: getBookingAmount(selected.booking_duration, "month"),
            yearly: getBookingAmount(selected.booking_duration, "year")
          })

        if (selected.images?.header) {
          setUploadedFiles([
            { file: new File([], ""), preview: selected.images?.header ?? "" },
          ]);
        }
       
        //debugger
        let otherImages = selected.images?.others
        let temp: UploadedFile[] = []
        //console.dir(otherImages)
        if (otherImages) {
          let img1 = (otherImages[0] as ListingImage1).image1
          let img2 = (otherImages[1] as ListingImage2).image2
          let img3 = (otherImages[2] as ListingImage3).image3
          let img4 = (otherImages[3] as ListingImage4).image4
          if (img1) {
            temp.push({ file: new File([], ""), preview: img1 })
          } else {
            temp.push({ file: new File([], ""), preview: "invalid" })

          }

          if (img2) {
            temp.push({ file: new File([], ""), preview: img2 })

          }else {
            temp.push({ file: new File([], ""), preview: "invalid" })
          }

          if (img3) {
            temp.push({ file: new File([], ""), preview: img3 })
          }else {
            temp.push({ file: new File([], ""), preview: "invalid" })
          }

          if (img4) {
            temp.push({ file: new File([], ""), preview: img4 })
          }  else {
            temp.push({ file: new File([], ""), preview: "invalid" })
          }
          // else {
          //   temp.push({ file: new File([], ""), preview: bannerPlaceholder })
          // }
          
          
        }
        //console.dir(temp)
        uploadedOtherFilesRef.current = temp.slice()
        setUploadedOtherFiles(temp)
        manager.setSavedUploadedFiles(temp)
        
      }


    } else {
      //let temp: UploadedFile[] = []
      //setUploadedOtherFiles(temp.fill({ file: new File([], ""), preview: "invalid" }, 4))
    }

    
    //console.dir(li)

    return ()=>{
      listingsStateContext.dispatch({
        type: AdminStateActions.SELECTLISTING,
        payload: null,
      });
    }

   }, []);

    

   useEffect(()=>{
    
     if (currencies.length === 0) {return}
      
     const found = currencies.find(item => item.currency === "USD")
     //fetchExchangeRate()
     if (found) {
       
       setExchangeRate({...exchangeRate, target: found.currency, base: "", rate: 1})
       let selectedId = listingsStateContext.state.selectedListingId
       if (!selectedId) {
        setFormValues({...formValues, currency: found.currency})
       }

     }


   }, [currencies])

   useEffect(()=>{

    
    if (formValues.currency) {
     // debugger

      onBaseCurrencySelected(formValues.currency)
     
   }
   }, [formValues.currency])

  
 
  return (
    <>
      <Formik
        initialValues={{
         
          name: formValues.name,
          capacity: formValues.capacity,
          price: formValues.price,
          hasWhiteboard: formValues.hasWhiteboard,
          isRecieveMails: formValues.isRecieveMails,
          useOfficeAddress: formValues.useOfficeAddress,
          description: formValues.description,
          // officeAvailableByDay: false,
          // officeAvailableByHour: false,
          // officeAvailableByMonth: false,
          // officeAvailableByYear: false,
          accessByDay: formValues.accessByDay,
          accessByHour: formValues.accessByHour,
          accessByMonth: formValues.accessByMonth,
          startingPriceByDay: formValues.startingPriceByDay,
          startingPriceByHour: formValues.startingPriceByHour,
          startingPriceByMonth: formValues.startingPriceByMonth,
          startingPriceByYear: formValues.startingPriceByYear,
          currency: formValues.currency
        }}
        validationSchema={yupOfficeValidation}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialStatus={{ isValidating: false }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
           
          updateFormSubmissionStatus(true);
          setTimeout(() => {
            updateFormSubmissionStatus(false);
            actionAfterSubmit();
          }, 2000);
        }}
        innerRef={formRef}
      >
        {(props) => {
          //console.dir(props.values)
          return (
            <Form style={{ width: "100%", height: "100%" }}>
              <AddASpaceLayout>
                <Field name="name">
                  {({ field, form }: any) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="name"
                      >
                        {t("Name")}
                      </FormLabel>
                      <Input
                        {...field}
                        onChange={(e) => {
                            props.handleChange(e)
                        }
                         
                        }
                        name="name"
                        type="text"
                        placeholder=""
                      />
                       <FormErrorMessage>{t(form.errors.name)}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="description">
                  {({ field, form }: any) => (
                    <FormControl
                    isRequired
                    isInvalid={form.errors.description && form.touched.description}
                    >
                      <FormLabel
                        htmlFor="description"
                        fontWeight={500}
                        fontSize={"15px"}
                      >
                        {t("Say a few things about this office")}
                      </FormLabel>
                      <Textarea
                        {...field}
                        rounded="lg"
                        name="description"
                        // value={value}
                        onChange={props.handleChange}
                        placeholder=""
                        size="md"
                      />
                     <FormErrorMessage>{t(form.errors.description)}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="hasWhiteboard">
                  {({ field, form }: any) => (
                    <FormControl>
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="hasWhiteboard"
                      >
                        {t("Does it have a whiteboard?")}
                      </FormLabel>
                      <Checkbox
                        isChecked={props.values.hasWhiteboard}
                        name="hasWhiteboard"
                        onChange={props.handleChange}
                      />
                    </FormControl>
                  )}
                </Field>
                {
                  isVirtualOffice ? (
                    <>
                       <Field name="isRecieveMails">
                  {({ field, form }: any) => (
                    <FormControl>
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        
                      >
                        {t("Does this office recieve mails?")}
                      </FormLabel>
                      <Checkbox
                        isChecked={props.values.isRecieveMails}
                        name="isRecieveMails"
                        onChange={props.handleChange}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="useOfficeAddress">
                  {({ field, form }: any) => (
                    <FormControl>
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        
                      >
                        {t("Will this office be used as a physical address?")}
                      </FormLabel>
                      <Checkbox
                        isChecked={props.values.useOfficeAddress}
                        name="useOfficeAddress"
                        onChange={props.handleChange}
                      />
                    </FormControl>
                  )}
                </Field>
                    </>
                  ): null
                }
                <Field name="capacity">
                  {({ field, form }: any) => (
                    <FormControl
                    isRequired
                    isInvalid={form.errors.capacity && form.touched.capacity}
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="capacity"
                      >
                        {t("Number of People this office can contain")}
                      </FormLabel>
                      
                      <Input
                        onChange={props.handleChange}
                        name="capacity"
                        type="number"
                        min="0"
                        {...field}
                        //placeholder="500 people"
                      />
                      <FormErrorMessage>{t(form.errors.capacity)}</FormErrorMessage>

                    </FormControl>
                  )}
                </Field>
                <Field name="currency">
                  {({ field, form }: any) => {
              
                   // console.dir(props.values.currency)
                   return  <FormControl
                    isRequired
                    isInvalid={form.errors.currency && form.touched.currency}
                    >
                      <FormLabel
                        fontWeight={500}
                        fontSize={"15px"}
                        htmlFor="currency"
                      >
                        {t("Currency")}
                      </FormLabel>
                      <Select  
                        
                         onChange={(e: any)=>{ 
                            //console.dir(props)
                            props.setValues({...props.values, startingPriceByYear: "", startingPriceByMonth: "", startingPriceByHour: "", startingPriceByDay: ""})
                            props.handleChange(e)
                             
                            onBaseCurrencySelected(e.target.value)
                         }}
                         name="currency"
                         type="text"
                         //min="0"
                         placeholder='Select option'
                         {...field}
                         >
                           
                          {currencyCodeItemViews}
                      </Select>
                      
                      <FormErrorMessage>{form.errors.currency}</FormErrorMessage>

                    </FormControl>
        }}
                </Field>
                {
                  isLoadingRate ? (
                    <WHSpinner pt="32px" />
                  ): error ? (
                    <OfflineView 
                     pt="32px"
                      msg={error.message}
                      onRetry={()=>{
                        onBaseCurrencySelected(props.values.currency)

                        //fetchExchangeRate(exchangeRate.base, exchangeRate.target)
                      }}
                    />
                  ): (
                    <VStack w="full" alignItems="flex-start">
                     <Text className="body2">{t("Enter one or more price options for this office")}</Text>
                    {/* {Pricing(props, exchangeRate.rate)} */}
                      <Pricing key={exchangeRate.base} props={props} rate={exchangeRate.rate} savedBookingAmount={savedBookingAmount} />
                   </VStack>
                  )
                }
               
                <Field name="headerPhoto">
                  {({ field, form }: any) => (
                    <FormControl>
                      <FormLabel fontWeight={500} fontSize={"15px"}>
                        {t("Header Photo (must not exceed 5mb)")}
                      </FormLabel>
                      <Box position="relative">
                        {officeDropzone(
                          uploadedFiles,
                          "full",
                          "208px",
                          handleDrop
                        )}
                        <IconButton
                          rounded="2xl"
                          //boxSize={8}
                          onClick={(e) => {
                            e.stopPropagation();
                            setUploadedFiles([])
                          }}
                          position="absolute"
                          top={0}
                          left={0}
                          aria-label="remove image"
                          icon={<MdClose />}
                        />
                      </Box>
                    </FormControl>
                  )}
                </Field>
                <Field name="photos">
                  {({ field, form }: any) => (
                    <FormControl>
                      <FormLabel fontWeight={500} fontSize={"15px"}>
                        {t("Upload 4 Other Photos (all must not exceed 20mb)")}
                      </FormLabel>

                      {/* {
                        uploadedOtherFiles.map((file, idx)=>{
                           return 
                        })
                      } */}

                      <Dropzone
                        onDrop={handleAnotherDrop}
                        accept="image/*"
                        minSize={1024}
                        maxSize={1048576 * 20}
                        multiple
                        //maxSize={3072000}
                        maxFiles={4}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <Box
                            rounded="lg"
                            w="full"
                            height={"344px"}
                            bg="gray.500"
                            {...getRootProps({ className: "dropzone" })}
                          >
                            <input title="photos" {...getInputProps()} />
                            <SimpleGrid 
                              columns={2}
                              gap={"0px"}
                              columnGap={"0px"}
                              w="full" h="full">
                              {uploadedOtherFiles.filter((file)=> file.preview !== "invalid").map((uploadedFile, idx) => (
                                <>
                                  <Box 
                                     key={`${uploadedFile.preview}+${idx}`}
                                     w="full"
                                      height={"172px"}
                                    position="relative">
                                    <Image
                                      //fallbackSrc={bannerPlaceholder}
                                      width="inherit"
                                      rounded="lg"
                                      height={"full"}
                                      //w="23%"
                                      src={
                                        uploadedFile.preview 
                                        // image == "" ? uploadedFile.preview : image
                                      }
                                    />
                                    <IconButton
                                      rounded="2xl"
                                      //boxSize={8}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        let filtered =
                                          uploadedOtherFiles.filter(
                                            (item, filterIdx) =>
                                              filterIdx !== idx
                                          );
                                         
                                        manager.addSavedFileRemoved(uploadedFile)
                                        // find index of removed file in first instance
                                        let removedIdx = uploadedOtherFilesRef.current.findIndex((file)=> file.preview === uploadedFile.preview)
                                         let r = [...uploadedOtherFilesRef.current.slice(0, removedIdx), 
                                          { file: new File([], ""), preview: "invalid" }, 
                                          ...uploadedOtherFilesRef.current.slice(removedIdx+1)
                                        ]

                                        uploadedOtherFilesRef.current = r
                                        //console.dir(uploadedOtherFilesRef.current)
                                        setUploadedOtherFiles(uploadedOtherFilesRef.current);
                                        //setUploadedOtherFiles(filtered);
                                      }}
                                      position="absolute"
                                      top={0}
                                      left={0}
                                      aria-label="remove image"
                                      icon={<MdClose />}
                                    />
                                  </Box>
                                </>
                              ))}
                            </SimpleGrid>
                          </Box>
                        )}
                      </Dropzone>
                    </FormControl>
                  )}
                </Field>
               
              </AddASpaceLayout>
              <ErrorFocus />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PrivateOfficeFormView;

import * as Yup from 'yup'

export const yupOfficeValidation = Yup.object().shape({
    
//       officeCategory: Yup.string().when(["Virtual Office", "Private Office", "Meeting Room", "Shared Office"], {
//                 is: (v: any, p: any, m: any, s: any) => !v && !p && !m && !s,
//                 then: Yup.string().required("Please select an office category")
//         }),
    name: Yup.string()
            .required("Name must not be empty")
            .min(2, "Name must not be below 2 characters"),
            //.max(50, "Firstname must not exceed 50 characters"),
     

      description: Yup.string()
            .required("Description must not be empty")
            .min(20, "Description must not be below 20 characters"),
            //.max(500, "Lastname must not exceed 50 characters"),

     hasWhiteboard: Yup.boolean(),

     capacity: Yup.number()
                .required("Please enter capacity of this office")
                .min(0, "Capacity should not be less than 0"),
      currency: Yup.string().when(["USD", "NGN"], {
              is: (usd: any, ngn: any) => !usd && !ngn,
              then: Yup.string().required("Please select a currency")
      }),
                 
     isRecieveMails: Yup.boolean(),
     useOfficeAddress: Yup.boolean(),

//      startingPrices: Yup.object( {
//         startingPriceByHour  : Yup.number().min(1, "Hourly price should not be less than 1"),
//         startingPriceByDay  : Yup.number().min(1, "Daily price should not be less than 1"),
//         startingPriceByMonth  : Yup.number().min(1, "Month price should not be less than 1"),
//         startingPriceByYear  : Yup.number().min(1, "Yearly price should not be less than 1")
//       }
        
//      )
            

     startingPriceByHour: Yup.number()
                .min(1, "Hourly price should not be less than 1"),

        startingPriceByDay: Yup.number()
                .min(1, "Daily price should not be less than 1"),
    startingPriceByMonth: Yup.number()
                .min(1, "Monthly price should not be less than 1"),
    startingPriceByYear: Yup.number()
                .min(1, "Yearly price should not be less than 1")
})

import { useEffect, useState } from "react"
import { dashboardManager } from "../../Components/Screens/WHDashboard/Models/WHDashboardDataManager"
import { ListingCategory, ResultType } from "../../Models/Interfaces"


export const useListingCategories = (count: number) => {
    const [data, setData] = useState<ListingCategory[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        
        let didCancel = false
         const temp = dashboardManager.getCategoriesData("list-cat")
        if (temp.length > 0) {
            setData(temp)
            return //{data, loading, error}

        }
 
        ;(async ()=>{
            setLoading(true)
            const result = await dashboardManager.fetchListingCategories()
       
            if (result.type === ResultType.Success) {
                  dashboardManager.saveListingCategoriesData("list-cat", result.value)
                  if (!didCancel){
                    setData(result.value)
                  }
                  setError(null)
                  
             } else {
                 setError(new Error(result.error.message))
                 setData([])
             // setErrorContext({title: result.error.name, msg: result.error.message})
           }
           setLoading(false)
        })()
        return () => {
            didCancel = true
        }
    }, [count])

    return {data, loading, error}
}
import { VStack, Text, Input, Button, Stack, useMediaQuery, Box  } from '@chakra-ui/react'
import React, {FC, useContext, useState} from 'react'

import bannerImg2 from "../../../Assets/workspace2.jpg";
import { ResultType } from '../../../Models/Interfaces';
import { WorkhopperAPIManager } from '../../../Models/WorkhopperAPIManager';
import { validateEmail } from '../../../utils/utils';
import { SetErrorContext, SetSuccessContext } from '../../AppProvider/AppProvider';
import { useTranslation  } from "react-i18next";

interface RegisterViewProps {
    rounded?:  string
     /**  @default false 
    */
    isModalOpen?: boolean
}

const RegisterView: FC<RegisterViewProps> = ({rounded, isModalOpen}: RegisterViewProps) => {
  const {t} = useTranslation()

    const [isLargeScrn] = useMediaQuery(["(min-width: 62em)"])
    const [email, setEmail] = useState("")
    const onEmailChange = (event: any) => setEmail(event.target.value)
    const setSuccess = useContext(SetSuccessContext)
    const setError = useContext(SetErrorContext)

    const subToNewsletter = async ()=> {
        if (!validateEmail(email)) {
          setError({title: "", msg: "Please enter a valid email"})
          return
        }
        
        const result = await WorkhopperAPIManager.shared.subscribeToNewsletter({email})
        if (result.type === ResultType.Success) {
          console.dir(result.value)
          setSuccess({title: "", msg: "Your subscription was successfull.Thank you"})
          setEmail("")
        } else {
          setError({title: "", msg: result.error.message})
        }

    }

    return (
      <Box
      w="full"
        rounded={rounded}
        as="section"
        backgroundImage={`url(${bannerImg2})`}
        backgroundRepeat="none"
        backgroundSize="cover"
        className="layout"
      >
        <VStack
          rounded={rounded}
          bg="blackAlpha.500"
          className="padding-lg"
          paddingY={{ base: 8 , lg: "172px" }}
          spacing={{ base: 2, lg: 4 }}
         
          // mb={12}
        >
          <Text color="white !important" className={isLargeScrn ? "h4" : "h6"}>
          {t("coworkingUpdate")}
            {/* Never Miss a Coworking Update */}
          </Text>
          <Text
            color="whiteAlpha.900"
            width={{ base: "90%" }}
            textAlign="center"
            className="body"
          >
             {t("newsLetterRegister")}
            {/* Register to recieve exclusive offers, special member pricing and
            updates on shared office space near you. */}
          </Text>
          <Stack
            alignItems={{ base: "center", md: "unset" }}
            spacing={{ base: 4 }}
            direction={{ base: "column", md: "row" }}
            width={{ base: "90%", lg: "85%" }}
          >
            <Input
              value={email}
              onChange={onEmailChange}
              bg="whiteAlpha.900"
              className="body1"
              size="lg"
              fontSize="md"
              type="text"
              placeholder={t("email")}
            />
            <Button
              onClick={subToNewsletter}
              width={{ base: "35%", lg: isModalOpen ? "30%" : "20%" }}
              colorScheme="secondary"
              className="button"
              size="lg"
            >
              {t("signup")}
              {/* sign me up! */}
            </Button>
          </Stack>
        </VStack>
      </Box>
    );
}

export default RegisterView
 


import { Box, SimpleGrid, useMediaQuery, Image } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Adpage, Advert, ResultType } from '../../../Models/Interfaces'
import { WorkhopperPublicAPIManager } from '../../../Models/WorkhopperPublicAPIManager'

interface AdsViewProps {
    width: string 
    height: string
    numAds: number 
    page: Adpage
    adIndex?: number
    borderRadius?: string
    pt?: string
    showAds?: (isShowing: boolean)=>void
 }

const AdsView = (props: AdsViewProps) => {

    const [adverts, setAdverts] = useState<Advert[]>([])

    const [xsmall, small, medium, large, xlarge] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 1240px) and (max-width: 1439px)",
        "(min-width: 1440px)",
    ]);

    useEffect(()=>{
        (async ()=>{
         // debugger
          const result = await WorkhopperPublicAPIManager.shared.getAdverts(props.page)
          if (result.type === ResultType.Success) {
            //console.dir(result.value)
            setAdverts(result.value.slice(0, props.numAds))
            if (result.value.length > 0 && props.showAds) {
              props.showAds(true)
               //setAdverts(result.value.slice(0, 2))
           } 
        } 
          
        })()
       
      }, [])

    return (
        <Box 
           pt={props.pt ?? "0px"} 
           // h={props.height || "96px"}
            h="full"
            w="full" as="section">
        
            {
                props.adIndex === undefined ? (
                    <SimpleGrid
                    w="full"
                    columns={xsmall ? 1 : 3}
                    spacing={xsmall ? 4 : 10}
                >
                {
                    adverts.map(advertItem => (
                        <a key={advertItem.id} style={{
                            display: "block",
                            width: props.width,
                            height: props.height || "96px",
                            margin: "0 auto",
                            borderRadius: props.borderRadius ?? "0px"
                            //objectFit: "cover"
                           // marginTop: "16px"
            
                            }} href={advertItem.url} target="_blank">
                          <Image 
                                borderRadius={props.borderRadius}
                                objectFit="cover"  
                                w="100%"  h={props.height || "96px"} src={advertItem.image} />
                        </a>
                    ))
                }
                 </SimpleGrid>
                ): props.adIndex < adverts.length ? (
                    <a key={adverts[props.adIndex].id} style={{
                        display: "block",
                        width: props.width,
                        height: props.height || "96px",
                        margin: "0 auto",
                        borderRadius: props.borderRadius ?? "0px"
                        //objectFit: "cover"
                       // marginTop: "16px"
        
                        }} href={adverts[props.adIndex].url} target="_blank">
                      <Image 
                            borderRadius={props.borderRadius}
                            objectFit="cover"  
                            w="100%"  h={props.height || "96px"} src={adverts[props.adIndex].image} />
                    </a>
                ):null
            }
           
        </Box>
    )
}

export default AdsView

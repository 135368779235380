import {
  Text,
  VStack,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  Input,
  Textarea,
  useMediaQuery,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";

import * as Yup from "yup";
import { MdMail, MdQuestionAnswer } from "react-icons/md";
import { SetSuccessContext } from "../../../AppProvider/AppProvider";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import { useTranslation  } from "react-i18next";

export const yupSupportValidation = Yup.object().shape({
  email: Yup.string()
    .required("Email must not be empty")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    ),
  //.max(50, "Firstname must not exceed 50 characters"),

  msg: Yup.string()
    .required("Message must not be empty")
    .min(20, "Message must not be below 20 characters"),
  //.max(500, "Lastname must not exceed 50 characters"),
});

const Contact = () => {
  const fieldHeight = 10;
  const setSuccess = useContext(SetSuccessContext);

  const [xsmall, small, small2, medium, large] = useMediaQuery([
    "(max-width: 599px)",
    "(min-width: 600px) and (max-width: 904px)",
    "(min-width: 905px) and (max-width: 1239px)",
    "(min-width: 1240px) and (max-width: 1439px)",
    "(min-width: 1440px)",
  ]);
  const {t} = useTranslation()

  return (
    <LayoutView>
      <WhatsAppWidget
			phoneNo="+2348122244752"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt=""
			iconSize="40"
			iconColor="white"
			iconBgColor="#262949"
      headerIcon=""
     // headerIcon={icon}
			//headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
			headerIconColor="white"
			headerTxtColor="white"
			headerBgColor="#262949"
			headerTitle="WorkHopper"
			headerCaption="Online"
			bodyBgColor="#262949"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#fff"
			btnBgColor="#262949"
			btnTxtColor="white"
			btnTxt="Start Chat"
		/>
      <VStack w="full" h="100vh" p={4} alignItems="flex-start">
        {/* <VStack
          width="full"
          justifyContent="flex-start"
          padding={4}
          bgGradient="linear(to-l, primary.500,#22c35e)"
          height={xsmall ? "unset" : "128px"}
        >
          <Text 
                 
                className={xsmall ? "subtitle" : "h5"} 
                color="white !important">
            Contact
            </Text>
           
        </VStack> */}
        <Text 
                 className={"h5"} 
                 color="primary.500">
                   {t('Contact Us')}
             {/* Contact Us */}
             </Text>
        <Text className="h6">
          {t('Got any questions')}
          {/* Got any questions or feedback? */}
          </Text>

        <Formik
          initialValues={{
            email: "",
            msg: "",
          }}
          validationSchema={yupSupportValidation}
          // validateOnBlur={false}
          //validateOnChange={false}
          enableReinitialize={true}
          onSubmit={async (
            values,
            { setSubmitting, setStatus, setValues, resetForm }
          ) => {
            setSubmitting(true);
            setTimeout(() => {
              setSuccess({ title: "", msg: "Thank you for reaching out" });
              setSubmitting(false);
              resetForm();
              //setValues({email: "", msg: ""})
            }, 3000);
          }}
        >
          {(props) => {
            return (
              <Form style={{ width: "100%" }}>
                <VStack
                  alignItems={small || xsmall ? "center" : "flex-start"}
                  spacing={6}
                >
                  <Field name="email">
                    {({ field, form }: any) => {
                      return (
                        <FormControl
                          className="caption"
                          isRequired
                          id="email"
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel fontWeight={500} fontSize={"15px"}>
                            {t('emails')}
                            {/* Email */}
                          </FormLabel>
                          <InputGroup>
                            <InputLeftAddon
                              pointerEvents="none"
                              bg="primary.200"
                              children={<MdMail color="#fff" />}
                            />
                            <Input
                              {...field}
                              id="email"
                              name="email"
                              type="email"
                              onChange={props.handleChange}
                              h={fieldHeight}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>
                  <Field name="msg">
                    {({ field, form }: any) => {
                      return (
                        <FormControl
                          className="caption"
                          isRequired
                          isInvalid={form.errors.msg && form.touched.msg}
                        >
                          <FormLabel fontWeight={500}>
                            {t('Message')}
                            {/* Message */}
                            </FormLabel>
                          <Textarea
                            {...field}
                            onChange={props.handleChange}
                            placeholder="Type your message here"
                            size="lg"
                            name="msg"
                          />

                          <FormErrorMessage>{form.errors.msg}</FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>

                  <Button
                    isLoading={props.isSubmitting}
                    colorScheme={"primary"}
                    w="80%"
                    onClick={() => {
                      props.submitForm();
                    }}
                    className="button"
                  >
                    {t('Submit')}
                    {/* Submit */}
                  </Button>
                </VStack>
              </Form>
            );
          }}
        </Formik>
      </VStack>
    </LayoutView>
  );
};

export default Contact;

import { Country, Enquiry, Listing, ListingCategory, ListResponseData, PublicListing, QueryItem, Result, ResultType, Review, ReviewQueryParams, StateCount, UserReview, Workspace } from "../../../../Models/Interfaces";
import { WorkhopperPublicAPIManager } from "../../../../Models/WorkhopperPublicAPIManager";
import routes from "../../../../routes";
import { EnquiryItem, ReviewItem } from "../OfficeDetail/Models/Interfaces";
import { WHDashboardDataManager } from "../../WHDashboard/Models/WHDashboardDataManager";
import { Queries } from "../SearchResuts/SearchResults";

class WHAppDataManager extends WHDashboardDataManager {

    private publicApiManager = WorkhopperPublicAPIManager.shared
    
    searchURL(categoryId: string, location?: string, capacity?: string): string {
        let url = `/search?category=${categoryId}`
        if (location) {
            url = `${url}&city=${location}`
        }

        if (capacity) {
            url = `${url}&capacity=${capacity}`
           //`/search?city=${location}&capacity=${noOfSeats}&category=${categoryId}`
        }

        if (capacity && location) {
            url = `${url}&city=${location}&capacity=${capacity}`
           //`/search?city=${location}&capacity=${noOfSeats}&category=${categoryId}`
        }

        return url
    }
    
    async getListingsByCity(city: string): Promise<Result<PublicListing[]>>  {
         
       let queryItems = [{name: "city", value: city}]
       let rslt = await this.publicApiManager.getListings(queryItems)
       return rslt
    }

    async getListingsByCategory(categoryId: string): Promise<Result<PublicListing[]>>  {
         
        let queryItems = [{name: "category", value: categoryId}]
        let rslt = await this.publicApiManager.getListings(queryItems)
        return rslt
     }

     async getListingsByTitle(title: string): Promise<Result<PublicListing[]>>  {
         
        let queryItems = [{name: "title", value: title}]
        let rslt = await this.publicApiManager.getListings(queryItems)
        return rslt
     }

     async getListingsById(listingId: string): Promise<Result<PublicListing[]>>  {
         
        let queryItems = [{name: "id", value: listingId}]
        let rslt = await this.publicApiManager.getListings(queryItems)
        return rslt
     }

     async getListingById(listingId: string): Promise<Result<PublicListing>>  {
         
       // let queryItems = [{name: "id", value: listingId}]
        let rslt = await this.publicApiManager.getListingById(listingId)
        return rslt
     }



    async getListingsByWorkspace(workspaceId: string): Promise<Result<PublicListing[]>>  {
         
        let queryItems = [{name: "workspace", value: workspaceId}]
        let rslt = await this.publicApiManager.getListings(queryItems)
        return rslt
     }
 

    getBusinessProfileURL(partnerId: number): string {

        if (!partnerId) return '/'

       return `/${routes.bizProfile.name}/${partnerId.toString()}/detail`
    }

    saveCountriesData(key: string, countries: Country[]) {
        localStorage.setItem(key, JSON.stringify(countries))
    }

    getCountriesData(key: string): Country[] {
        let val = localStorage.getItem(key)
        if (!val) return []
        return JSON.parse(val) 
    }

   

    async getPartnerWorkspaces(partnerId: string, nextURL?: string): Promise<Result<ListResponseData<Workspace>>> {
        let queryItems = [{name: "partner_id", value: partnerId}]
        let rslt = await this.publicApiManager.fetchWorkspaces(queryItems, nextURL)
        return rslt
    }

    async searchOffices(queries: Queries): Promise<Result<PublicListing[]>> {
        let rslt: Result<PublicListing[]> = {type: ResultType.Failure, error: new Error("Couldn't load results") }
         
        let queryItems: QueryItem[] = [] 
        Object.entries(queries).forEach(([key, value]) => {
            if (value) {
                // if (key.includes("amenity") && value as string[]) {
                if (Array.isArray(value)) {
                    (value as string[]).map((item) => queryItems.push({name: key, value: item}))
                } else {
                    queryItems.push({name: key, value})
                }
            }
        })
        
        rslt = await this.publicApiManager.getListings(queryItems)

        return rslt
    }

    async searchOfficeListings(queries: Queries, nextPageURL?: string): Promise<Result<ListResponseData<PublicListing>>> {
        let rslt: Result<ListResponseData<PublicListing>> = {type: ResultType.Failure, error: new Error("Couldn't load results") }
         
        let queryItems: QueryItem[] = [] 
        Object.entries(queries).forEach(([key, value]) => {
            if (value) {
                // if (key.includes("amenity") && value as string[]) {
                if (Array.isArray(value)) {
                    (value as string[]).map((item) => queryItems.push({name: key, value: item}))
                } else {
                    queryItems.push({name: key, value})
                }
            }
        })
        
        rslt = await this.publicApiManager.getOfficeListings(queryItems, nextPageURL)

        return rslt
    }

     formatDate(d: Date) {
       // var d = new Date(date),
          let  month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    async sendEnquiry(data: EnquiryItem,  listingCategory: string, listingId: string, token?: string): Promise<Result<Enquiry>> {

        let rslt: Result<Enquiry> = {type: ResultType.Failure, error: new Error("Couldn't submit enquiry") }
        let enquiryData: Enquiry = {
            first_name: data.fname,
            last_name: data.lname,
            company_email: data.cemail,
            company_name: data.cname,
            phone: data.phoneNo,
            total_people: data.persons.toString(),
            duration: Number(data.duration),
            duration_type: data.durationType,
            description: data.desc,
            start_date: this.formatDate(new Date),
            list_category: listingCategory,
            listing: listingId
        }
        rslt = await this.publicApiManager.postEnquiry(enquiryData)

        return rslt
    }

    async postReview(item: ReviewItem, listingId: string, token: string): Promise<Result<Review>> {
        let rslt: Result<Review> = {type: ResultType.Failure, error: new Error("Couldn't post review") }
         
        
        let data: Review = {
            title: item.title,
            description: item.desc,
            rating: item.rating,
            listing: listingId
        }

        rslt = await this.publicApiManager.postReview(data, token)
        return rslt
    }

    async updateReview(item: ReviewItem, reviewId: string, token: string): Promise<Result<Review>> {
        let rslt: Result<Review> = {type: ResultType.Failure, error: new Error("Couldn't update review") }
         
        
        let data: unknown = {
            title: item.title,
            description: item.desc,
            rating: item.rating
        }

        rslt = await this.publicApiManager.updateReview(data, reviewId, token)
        return rslt
    }
    
    /**
     * 
     * @param queries query parametters to the review endpoint
     * @param token 
     * @returns an array of user reviews
     */
    async getListingReviews(queries: ReviewQueryParams): Promise<Result<UserReview[]>> {
        let rslt: Result<UserReview[]> = {type: ResultType.Failure, error: new Error("Couldn't load reviews") }
        
        let queryItems: QueryItem[] = [] 
        Object.entries(queries).forEach(([key, value]) => {
            if (value) {
                queryItems.push({name: key, value})
            }
        })

        rslt = await this.publicApiManager.getReviews(queryItems)

        return rslt
    }

    async getListingCount<T>(queryItem: QueryItem): Promise<Result<T[]>> {

        let rslt: Result<T[]> = {type: ResultType.Failure, error: new Error("Couldn't load location data") }
        

        let queryItems: QueryItem[] = [queryItem] 
        // Object.entries(queries).forEach(([key, value]) => {
        //     if (value) {
        //         queryItems.push({name: key, value})
        //     }
        // })

        rslt = await this.publicApiManager.getLocationData<T>(queryItems)

        return rslt
    }

}

export const appDataManager = new WHAppDataManager()
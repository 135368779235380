import { Center, Flex, Button, IconButton, Image, Link, useMediaQuery, Text, Circle, MenuButton, Menu, MenuList, MenuItem, HStack } from '@chakra-ui/react'
import workhopper from "../../../../Assets/logo_dark.png"
import workhopperMobileLogo from "../../../../Assets/icon_dark.png"
import { MdAdd, MdArrowDownward, MdKeyboardArrowDown, MdMenu, MdPerson } from 'react-icons/md';
import {  NavLink as ReactLink, useHistory, useRouteMatch } from "react-router-dom";
import React, { FC, useContext } from 'react'
import routes from '../../../../routes';
import { AppBarProps, DashboardSectionName, WHNavbarProps } from '../Models/Interfaces';
import { dashboardManager } from '../Models/WHDashboardDataManager';
import { UserContext } from '../../../AppProvider/AppProvider';
import WHMenuButton from '../../../SupportingViews/WHMenuButton/WHMenuButton';
import WHAvatarMenuButton from '../../../SupportingViews/WHAvatarMenuButton/WHAvatarMenuButton';
import WHShoppingCart from '../../../SupportingViews/WHShoppingCart/WHShoppingCart';
import PartnerMenuContols from '../../../SupportingViews/PartnerMenuControls/PartnerMenuContols';
import { t } from 'i18next';


const Mobile= ({onOpen, onLogout, registeredUser}: WHNavbarProps)=> {

   let bname = "" 

  if (registeredUser) {
    bname = registeredUser.business_name ?? ""
  }
    
    return (
      <Flex
        top={0}
        position="fixed"
        zIndex="104"
        shadow="md"
        w="full"
        bg="white"
        paddingX={{ base: 4, md: 5 }}
        justifyContent="flexStart"
        alignItems="center"
        h={{ base: "56px", md: "64px"}}
      >
       
       
         
        <Center w="full">
          <Link mr={"-72px"} as={ReactLink} to={routes.home.path}>
            <Image
              boxSize="48px"
              src={workhopper}
              alt="workhopper logo"
              h={16}
              objectFit="contain"
            />
          </Link>
        </Center>
         
         <WHAvatarMenuButton 
            onOpen={onOpen}
            registeredUser={registeredUser}
         />
        {/* <IconButton 
             borderWidth="1px"
             width={"72px"}
             //paddingX={8}
             colorScheme="white"
             aria-label="Open Menu"
             onClick={onOpen}
             icon={ <HStack>
              
              <Circle bg="primary.500" width="24px" height="24px">
                <Text className="h6" color="white">
                  {registeredUser?.partner_details ? registeredUser.partner_details.name[0].toLocaleUpperCase() : ""}
                </Text>
              </Circle>
              <MdKeyboardArrowDown color="black" width="48px" height="48px" />
            </HStack>}
        /> */}
      </Flex>
    );
}

const Large = ({onLogout, registeredUser}: AppBarProps)=>{

   const history = useHistory()

    let bizId = ""
    
    let abbrev = "" 

    if (registeredUser) {
      abbrev = registeredUser.business_name ?? ""
      bizId = registeredUser.id
    }  

     return (
      <Flex
        className="navbar-lg"
        position="fixed"
        zIndex="104"
        shadow="md"
        top={0}
        bg="white"
        w="full"
        pr={20}
        pl={20}
        //justifyContent="space-between"
        alignItems="center"
        h={{ base: "56px", md: "64px", lg: "72px" }}
        color="black"
      >
        <Link 
          textDecoration="none !important"
          display="flex" mr="auto" 
          alignItems="center" 
          ml={"-24px"} 
          as={ReactLink} to={routes.home.path}>
           <Image
         
             src={workhopper}
            alt="workhopper logo"
            h={12}
            objectFit="contain"
           />
           
        </Link>
        <Flex
          ml={2}
          alignItems="center"
         // w="50%"
          justifyContent="space-between"
        >
          {/* <PartnerMenuContols partner={registeredUser} onLogout={onLogout} /> */}
          <Menu>
            <MenuButton
              px={4}
              py={2}
             // w={"30%"}
              transition="all 0.2s"
              borderRadius="lg"
              borderWidth="1px"
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400" }}
              _focus={{ boxShadow: "outline" }}
            >
              <HStack>
                <Text className="subtitle1">{t("Menu")}</Text>
                <MdKeyboardArrowDown width="48px" height="48px" />
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem color="primary.500" minH="48px">
                <Link
                  minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={`/dashboard/${bizId}/manage+workspaces`}
                  color="primary.500"
                >
                    {t("Your Workspaces")}
                   
                </Link>
              </MenuItem>
              <MenuItem minH="48px">
                <Link
                  color="primary.500"
                  minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={`/dashboard/manage+requests/${bizId}/bookings/overview`}
                >
                     {t("Your Bookings/Enquiries")}
                </Link>
              </MenuItem>
              <MenuItem 
           onClick={()=>{
            history.push(`/${routes.bookingHistory.name}`)
          }}
      minH="48px">
          <Text color="primary.500" className="body2">{t("Booking History")}</Text>
        </MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <WHMenuButton registeredUser={registeredUser} />
            
            <MenuList>
              <MenuItem minH="48px">
                <Link
                  color="primary.500"
                  minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={`/settings`}
                >
                    {t("Manage Account")}
                </Link>
              </MenuItem>
              <MenuItem onClick={onLogout} minH="48px">
                <Text className="body2">Sign Out</Text>
              </MenuItem>
              <MenuItem minH="48px">
              <Link
                  color="primary.500 !important"
                  minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={"/"}
                  activeStyle={{
                    color: "black !!important"
                  }}
                >
                   {t("Switch To Workhopper")}
                </Link>
                {/* <Text className="body2">Switch to Workhopper</Text> */}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <WHShoppingCart />
      </Flex>
    );
}

const WHDashboardNavbar = ({onOpen, onLogout, registeredUser}: WHNavbarProps) => {

    const [isLargeScrn] = useMediaQuery(["(min-width: 1024px)"])


    //  dashboard/manage+requests/6/bookings/overview
    
    return (
        
        <>
         {
           registeredUser ? (
                <>
              
                {
                  isLargeScrn ? (
                    <Large
                      onLogout={onLogout} 
                      registeredUser={registeredUser} 
                    />
                ) : (
                    <Mobile 
                      onLogout={onLogout} 
                      registeredUser={registeredUser} 
                      onOpen={onOpen} />
                )  
                }
                
              </>
           ):null
         }
        </>
        
    )
}

export default WHDashboardNavbar

import {
  Divider,
  VStack,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Icon,
  InputLeftElement,
  Select,
  Textarea,
  InputLeftAddon,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { stringify } from "querystring";
import React, { RefObject, useContext } from "react";
import { MdDateRange } from "react-icons/md";
import DatePicker, {
  DayValue,
  RenderInputProps,
} from "react-modern-calendar-datepicker";
import {
  CompartmentCategory,
  DurationCategory,
  PublicListing,
  ResultType,
} from "../../../../Models/Interfaces";
import AddASpaceLayout from "../../../SupportingViews/AddASpaceLayout/AddASpaceLayout";
import { EnquiryItem, ListingDetailFormProps } from "./Models/Interfaces";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import styles from "../../SupportingViews/SearchMenu/SearchMenu.module.css";
import { appDataManager } from "../Models/WHAppDataManager";
import { SetErrorContext, UserContext } from "../../../AppProvider/AppProvider";
import { useTranslation  } from "react-i18next";

function getDate(selectedDay: DayValue): string {
  if (selectedDay) {
    return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`;
  }
  return "";
}

const EnquiryForm = ({ listing, onSubmitEnded }: ListingDetailFormProps) => {
  const user = useContext(UserContext);
  const setErrorContext = useContext(SetErrorContext);

  const [day, setDay] = React.useState<DayValue>(null);
  const categories = appDataManager.getCategoriesData("list-cat");
  const toast = useToast();
  const {t} = useTranslation()
  // if (listing.id) {
  //   let entry = categories.find((item)=> item.id === listing.id)

  // }

  let category = [
    CompartmentCategory.PO,
    CompartmentCategory.MR,
    CompartmentCategory.VO,
    CompartmentCategory.Desks,
  ].find((item) =>
    (item as String).toLocaleLowerCase().includes(listing.category_details)
  );

  let enquiryItem: EnquiryItem = {
    fname: user?.first_name ?? "",
    lname: user?.last_name ?? "",
    cemail: "",
    cname: "",
    phoneNo: "",
    duration: "1",
    durationType: DurationCategory.HOUR,
    spaceType: category ?? CompartmentCategory.PO,
    date: null,
    persons: "1",
    desc: "",
  };

  const officeItemViews = [
    CompartmentCategory.PO,
    CompartmentCategory.MR,
    CompartmentCategory.VO,
    CompartmentCategory.Desks,
  ].map((item, idx) => <option key={item}>{item}</option>);

  const personItemViews = [1, 2, 3, 4, 5].map((item, idx) => (
    <option key={item}>{item}</option>
  ));

  const durationItemViews = [1, 2, 3, 4, 5].map((item, idx) => (
    <option key={item}>{item}</option>
  ));

  const durationTypeItemViews = [
    DurationCategory.HOUR,
    DurationCategory.DAY,
    DurationCategory.MONTH,
    DurationCategory.YEAR,
  ].map((item, idx) => <option key={item}>{item}</option>);

  const renderCustomInput = ({ ref }: RenderInputProps) => (
    <InputGroup w="full" size="lg">
      <InputLeftElement
        h="48px"
        color="gray.300"
        pointerEvents="none"
        children={<Icon as={MdDateRange} w={6} h={6} />}
      />
      <Input
        inputclassname="body1"
        name="date"
        bg="white"
        color="black"
        size="lg"
        fontSize="md"
        ref={ref as RefObject<HTMLInputElement>}
        //value={getDate(day)}
        type="date"
      />
    </InputGroup>
  );

  return (
    <>
      <Formik
        initialValues={enquiryItem}
        initialStatus={{ isValidating: false }}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          if (!listing.id) return;

          let result = await appDataManager.sendEnquiry(
            values,
            listing.category,
            listing.id
          );
          if (result.type === ResultType.Success) {
            onSubmitEnded();
            toast({
              title: "Enquiry Submitted.",
              description: "Your Enquiry has been submitted.",
              status: "success",
              duration: 3500,
              isClosable: true,
            });
          } else {
            setErrorContext({ title: "", msg: result.error.message });
          }

          //setStatus({isSubmitting: true})
        }}
      >
        {(props) => {
          return (
            <Form>
              {/* <VStack
                           spacing={{ base: 4, md: 6, lg: 6 }}
                           alignItems="flex-start"
                         >
                           <Text
                             padding={{ base: 6, lg: 8 }}
                             // paddingY={{ base: 6, lg: 8 }}
                             w={{ base: "full", md: "75%", lg: "80%" }}
                             className="h6"
                           >
                             Got Any Questions?
                           </Text>
                           <Divider />
                         </VStack> */}
              <AddASpaceLayout>
                <Stack w="full" direction={{ base: "column", md: "row" }}>
                  <Field name="fname">
                    {({ field, form }: any) => (
                      <FormControl isRequired>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="fname"
                        >
                          {t('firstname')}
                          {/* First Name */}
                        </FormLabel>
                        <Input
                          rounded="lg"
                          name="fname"
                          type="text"
                          {...field}
                          // value={value}
                          onChange={props.handleChange}
                          placeholder="First Name"
                          size="md"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="lname">
                    {({ field, form }: any) => (
                      <FormControl isRequired>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="lname"
                        >
                          {t('lastname')}
                          {/* Last Name */}
                        </FormLabel>
                        <Input
                          rounded="lg"
                          name="lname"
                          type="text"
                          {...field}
                          // value={value}
                          onChange={props.handleChange}
                          placeholder="Last Name"
                          size="md"
                        />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Stack w="full" direction={{ base: "column", md: "row" }}>
                  <Field name="cname">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="cname"
                        >
                          {t('Company Name')}
                          {/* Company Name */}
                        </FormLabel>
                        <Input
                          rounded="lg"
                          name="cname"
                          type="text"
                          {...field}
                          // value={value}
                          onChange={props.handleChange}
                          //placeholder="First Name"
                          size="md"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="cemail">
                    {({ field, form }: any) => (
                      <FormControl>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="cemail"
                        >
                          {t('Company Email')}
                          {/* Company Email */}
                        </FormLabel>
                        <Input
                          rounded="lg"
                          name="cemail"
                          type="email"
                          {...field}
                          // value={value}
                          onChange={props.handleChange}
                          // placeholder="Last Name"
                          size="md"
                        />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Stack w="full" direction={{ base: "column", md: "row" }}>
                  <Field name="phoneNo">
                    {({ field, form }: any) => (
                      <FormControl isRequired>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="phoneNo"
                        >
                          {t('phone')}
                          {/* Phone Number */}
                        </FormLabel>
                        <InputGroup>
                          {/* <InputLeftAddon
                                       pointerEvents="none"
                                       bg="primary.200"
                                       children={
                                         <Text
                                           className="subtitle1"
                                           fontWeight="medium"
                                           color="#fff"
                                         >{`+${234}`}</Text>
                                       }
                                     /> */}
                          <Input
                            id="phonenumber"
                            name="phoneNo"
                            type="tel"
                            size="md"
                            {...field}
                          />
                        </InputGroup>
                      </FormControl>
                    )}
                  </Field>
                  {/* <Field name="spaceType">
                               {({ field, form }: any) => (
                                 <FormControl 
                                    isRequired
                                    isReadOnly
                                    >
                                   <FormLabel
                                     fontWeight={500}
                                     fontSize={"15px"}
                                     htmlFor="spaceType"
                                   >
                                     Space Type
                                   </FormLabel>
                                   <Select
                                     isReadOnly
                                     disabled
                                     rounded="lg"
                                     name="spaceType"
                                     {...field}

                                     // value={value}
                                     onChange={props.handleChange}
                                     //placeholder="First Name"
                                     size="md"
                                   >
                                     {officeItemViews}
                                   </Select>
                                 </FormControl>
                               )}
                             </Field> */}
                </Stack>
                <Stack w="full" direction={{ base: "column", md: "row" }}>
                  <Field name="person">
                    {({ field, form }: any) => (
                      <FormControl isRequired>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="person"
                        >
                          {t('No of Persons')}
                          {/* No of Persons */}
                        </FormLabel>
                        <Input
                          rounded="lg"
                          name="person"
                          {...field}
                          // value={value}
                          onChange={props.handleChange}
                          //placeholder="First Name"
                          size="md"
                        />
                      </FormControl>
                    )}
                  </Field>
                  {/* <Field name="date">
                               {({ field, form }: any) => (
                                 <FormControl isRequired>
                                   <FormLabel
                                     fontWeight={500}
                                     fontSize={"15px"}
                                     htmlFor="date"
                                   >
                                     Start Date
                                   </FormLabel>
                                   <DatePicker
                                     wrapperClassName={styles.datePicker}
                                     //value={day}
                                     //onChange={setDay}
                                     onChange={(dayValue) => {
                                       props.setValues({
                                         ...props.values,
                                         date: dayValue,
                                       });
                                     }}
                                     //onChange={props.handleChange}
                                     locale="en"
                                     renderInput={renderCustomInput}
                                   />
                                 </FormControl>
                               )}
                             </Field> */}
                </Stack>
                <Stack w="full" direction={{ base: "column", md: "row" }}>
                  <Field name="durationType">
                    {({ field, form }: any) => (
                      <FormControl isRequired>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="durationType"
                        >
                          {t('Duration Type')}
                          {/* Duration Type */}
                        </FormLabel>
                        <Select
                          title="durationType"
                          rounded="lg"
                          {...field}
                          name="durationType"
                          onChange={props.handleChange}
                          //placeholder="First Name"
                          size="md"
                        >
                          {durationTypeItemViews}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="duration">
                    {({ field, form }: any) => (
                      <FormControl isRequired>
                        <FormLabel
                          fontWeight={500}
                          fontSize={"15px"}
                          htmlFor="duration"
                        >
                          {t('Duration')}
                          {/* Duration */}
                        </FormLabel>
                        <Input
                          {...field}
                          rounded="lg"
                          name="duration"
                          onChange={props.handleChange}
                          //placeholder="First Name"
                          size="md"
                        />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Field name="desc">
                  {({ field, form }: any) => (
                    <FormControl isRequired>
                      <FormLabel
                        htmlFor="desc"
                        fontWeight={500}
                        fontSize={"15px"}
                      >
                        {t('Description')}
                        {/* Description */}
                      </FormLabel>
                      <Textarea
                        rounded="lg"
                        name="desc"
                        {...field}
                        // value={value}
                        onChange={props.handleChange}
                        placeholder="Additional Requirements"
                        size="md"
                      />
                    </FormControl>
                  )}
                </Field>
                <Button
                  onClick={props.submitForm}
                  className="button"
                  colorScheme="secondary"
                  w="80%"
                  isLoading={props.isSubmitting}
                >
                  {t('send enquiry')}
                  {/* send enquiry */}
                </Button>
                <Text className="caption">
                  {t('By completing,')}
                  {/* By completing, you accept the WorkHopper Terms of Use and
                  Privacy Policy */}
                </Text>
              </AddASpaceLayout>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EnquiryForm;

import {
  VStack,
  Image,
  Text,
  SimpleGrid,
  Skeleton,
  Link,
  Breakpoint,
  Box,
} from "@chakra-ui/react";
import { BreakPoint } from "../OfficeDetail/Models/Interfaces";
import WHPartnerGrid from "./WHPartnerGrid";
import WHReason from "./WHReason";
import WHWiw from "./WHWiw";
import { NavLink as ReactLink } from "react-router-dom";
import TopCityCard from "../../../SupportingViews/TopCityCard/TopCityCard";
import WHBookAvailableSpaces from "./WHBookAvailableSpaces";
import WHDownloadApp from "./WHDownloadApp";
import WHGetStarted from "./WHGettingStarted";
import manSitting from "../../../../Assets/man-sitting.png";
import styles from "./WHHome.module.css";
import { WHHomeProps } from "./WHHome";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import routes from "../../../../routes";
import p3 from "../../../../Assets/partner3.png";
import p4 from "../../../../Assets/partner4.png";
import { useTranslation  } from "react-i18next";

const WHHomeMobile = (props: WHHomeProps) => {
  let introTitle = "h6";
  let introBody = "body2";
  const {t} = useTranslation()
   
  const introView = () => {
    return (
      <VStack>
        <Image
          fallback={<Skeleton rounded="md" w="90%" h="full" />}
          objectFit="cover"
          w="90%"
          h="full"
          src={manSitting}
        />
        <WHWiw breakpoint={BreakPoint.XS} />
      </VStack>
    );
  };

  const comfortableSpacesView = () => {
    return (
      <VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <Text className={styles.title}>
          {t("comfortable")}
            {/* Comfortable Spaces To Work In */}
            </Text>
          <Text className={styles.subtitle}>
          {t("discover")}
            {/* Discover spaces that defines a new dimension of comfortability and
            professionalism. An essential aspect of creativity is not being
            cooped in a boring space. */}
          </Text>
        </VStack>
        <SimpleGrid spacing={4} columns={2} w="full">
          {props.comfortItems.map((imageItem, _) => {
            return (
              <Link
                key={imageItem.id}
                h="auto"
                w="full"
                href={imageItem.adURL || void 0}
                isExternal
                //to={`${routes.cityOffices.path}/${item.city}`}
                textDecoration={"none !important"}
              >
                <Image
                  fallback={<Skeleton rounded="xl" w="full" h="full" />}
                  w="full"
                  rounded="xl"
                  height={"208px"}
                  // h="100%"
                  objectFit="cover"
                  src={imageItem.imageURL}
                />
              </Link>
            );
          })}
        </SimpleGrid>
      </VStack>
    );
  };

  const useWorkhopper = () => {
    return (
      <VStack alignItems="flex-start" w="full">
        <VStack spacing={4} alignItems="flex-start" w="full">
          <Text className={styles.title}>
          {t("whyworkhopper")}
            {/* Why Use WorkHopper */}
            </Text>

          {props.homedata.adverts.length >= 2 ? (
            <Link
              h="auto"
              w="full"
              href={props.homedata.adverts[1].url}
              isExternal
              //to={`${routes.cityOffices.path}/${item.city}`}
              textDecoration={"none !important"}
            >
              <Image
                w="full"
                height={14}
                // h="100%"
                objectFit="cover"
                //key={item.id}
                src={props.homedata.adverts[1].image}
              />
            </Link>
          ) : null}
        </VStack>

        <SimpleGrid spacing={4} columns={1} w="full">
          {props.reasonItems.map((reasonItem) => (
            <Box key={reasonItem.title} w="full" h="152px">
              <WHReason
                key={reasonItem.title}
                title={t(reasonItem.title)}
                breakpoint={props.breakpoint}
                image={reasonItem.image}
                description={t(reasonItem.description)}
              />
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    );
  };

  const exploreCitiesView = () => {
    return (
      <VStack spacing={4}>
        <VStack>
          <Text className={styles.title}>
          {t("explorecities")}
            {/* Explore Coworking Cities */}
            </Text>
          <Text className={styles.subtitle}>
          {t("searchworkhopper")}
            {/* Search for workspaces in the most popular cities */}
          </Text>
        </VStack>
        {props.homedata.adverts.length >= 3 ? (
          <Link
            h="auto"
            w="full"
            href={props.homedata.adverts[2].url}
            key={props.homedata.adverts[2].id}
            //to={`${routes.cityOffices.path}/${item.city}`}
            textDecoration={"none !important"}
          >
            <Image
              w="full"
              height={14}
              // h="100%"
              objectFit="cover"
              //key={item.id}
              src={props.homedata.adverts[2].image}
            />
          </Link>
        ) : null}

        <SimpleGrid spacing={4} columns={2} w="full">
          {props.homedata.topcities.map((cityItem) => (
            <Link
              h="240px"
              as={ReactLink}
              key={cityItem.id}
              to={`${routes.cityOffices.path}/${cityItem.city}`}
              textDecoration={"none !important"}
            >
              <TopCityCard
                item={{
                  id: cityItem.id,
                  city: cityItem.city,
                  count: cityItem.count,
                  image: cityItem.image,
                }}
                breakpoint={BreakPoint.XS}
              />
            </Link>
          ))}
        </SimpleGrid>
        <SimpleGrid spacing={4} columns={1} w="full">
          {props.homedata.adverts.length >= 4
            ? props.homedata.adverts.slice(3, 6).map((advertItem) => (
                <Link
                  h="auto"
                  w="full"
                  href={advertItem.url}
                  key={advertItem.id}
                  //to={`${routes.cityOffices.path}/${item.city}`}
                  textDecoration={"none !important"}
                >
                  <Image
                    w="full"
                    height={14}
                    // h="100%"
                    objectFit="cover"
                    //key={item.id}
                    src={advertItem.image}
                  />
                </Link>
              ))
            : null}
        </SimpleGrid>
      </VStack>
    );
  };

  return (
    <VStack w="full" spacing={4}>
 
      <LayoutView>
         
        {introView()}
        {comfortableSpacesView()}
        <Box w="100vw">
          <WHPartnerGrid partners={props.homedata.partners} breakpoint={props.breakpoint} />

        </Box>
         

        {useWorkhopper()}

        {exploreCitiesView()}
      </LayoutView>
      <WHBookAvailableSpaces breakpoint={props.breakpoint} />
      <LayoutView>
        <WHDownloadApp breakpoint={props.breakpoint} />
      </LayoutView>
      <WHGetStarted breakpoint={props.breakpoint} />
    </VStack>
  );
};

export default WHHomeMobile;

import { HStack, Image, VStack, Text, Link, Circle, LinkOverlay, Spacer, Skeleton, Box,  AspectRatio } from "@chakra-ui/react";
import { useState } from "react";
import { MdStar } from "react-icons/md";
import {  NavLink as ReactLink } from "react-router-dom";

import coworkImg from "../../../Assets/workspace2.jpg";
import { BookingDuration, PublicListing } from "../../../Models/Interfaces";
import { getDurationTitle, getLeastPrice } from "../../../utils/utils";
import { appDataManager } from "../../Screens/WHApp/Models/WHAppDataManager";
import WHPrice from "../WHPrice/WHPrice";

export interface OfficeItemViewProps {
  listing: PublicListing
  removeShadow?: boolean 
 }



 

const OfficeItemView = ({listing, removeShadow}: OfficeItemViewProps) => {

    const {title, images, workspace_details, category_details, id, booking_duration, avg_rating, rating} = listing
    
    let lowestBooking = getLeastPrice(booking_duration)
    const [imgLoading, setImgLoading] = useState(true)

    // compute reveiws
    
  
    return id ? (
       <HStack shadow={removeShadow ? "": "md"}  w="full" h="full"  alignItems="flex-start" rounded="md" 
        spacing="0px" 
       // h="180px"
         >
           
           <>
           <Image
              
              fallback={
                <Skeleton 
                //w="200px"
                w="45%"  
                minW={"45%"}
                h="full"
                />
              }
            
               position="relative"
               w="45%"
               minW={"45%"}
              h="full"
              borderTopRightRadius="unset !important"
              borderBottomRightRadius="unset !important"
              objectFit="cover"
              rounded="md"
              src={images.header}
              />
 
         <VStack w="full" h="full" spacing={"4px"} color="black !important" p={{ base: 2 }} alignItems="flex-start">
            
            <VStack alignItems="flex-start" spacing={"0px"}>
            {/* <Text   className="h6" fontSize="16px !important" noOfLines={1}>{title}</Text> */}
            <Text   className="h6" fontSize="16px !important" noOfLines={1}>{workspace_details.partner_name}</Text>
            <VStack alignItems={"flex-start"} spacing={1}>
              <Text   className="caption" opacity={0.4} fontWeight="semibold"  noOfLines={1}>{workspace_details.name}</Text>
              <Text   className="caption" opacity={0.4} fontWeight="semibold"  noOfLines={1}>{title} Office</Text>
            </VStack>
           
             {/* <HStack w="full" spacing="2px">
                <Text w={"80%"}  className="caption" opacity={0.4} fontWeight="semibold" isTruncated
                noOfLines={1}
                >{workspace_details.name}</Text>
                <Circle h="2px" w="2px" bg="primary.500"/>
                <Text   className="caption" opacity={0.4} fontWeight="semibold"  noOfLines={1}>{title} Office</Text>

             </HStack> */}
            </VStack>
           <HStack spacing="8px" fontSize="14px !important"  className="subtitle1" >
             {
               avg_rating > 0 ? (
                 <HStack spacing="0px">
                 <MdStar fill={"red"} />
                 <Text>{Number(avg_rating).toFixed(1)}</Text>
                 </HStack>
               ): (
                <Text className="subtitle1" fontSize="14px !important">No Rating</Text>
               )
               
             }
            
             <HStack spacing={1}>
               <Text isTruncated maxW="24px">{rating?.total_reviews ?? 0}</Text>
               <Circle w="2px" h="2px" bg={"primary.500"} />
               <Text>Reviews</Text>
             </HStack>
           </HStack>
           <VStack alignItems="flex-start" className="caption">
              
             {/* <Text>Ranging from <Text className="subtitle2" as="span">{`$${lowestBooking.amount}`}</Text> / {getDurationTitle(lowestBooking.minimum_duration)}</Text> */}
             <Text noOfLines={1}>Ranging from <Text className="subtitle2" as="span"><WHPrice priceText={lowestBooking.amount} /> </Text> / {getDurationTitle(lowestBooking.minimum_duration)}</Text>

             <Text className="caption">{category_details}</Text>   
 
            </VStack>
         </VStack>
           </>
           
      </HStack>
     ) : null

}

export default OfficeItemView
import {  useToast,  VStack, Skeleton, Image, useMediaQuery } from '@chakra-ui/react'
import { errorMonitor } from 'events'
import React, { Dispatch, SetStateAction, useEffect, useReducer, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { AppProviderProps, Cart, CartActionType,  CartItem, Country, ListingCategory, RegistrationResponse, ResultType,  WHAlertDialog, WHAlertProps, WHError, WHSuccess, WorkHopperCurrency } from '../../Models/Interfaces'
import { WorkhopperAPIManager } from '../../Models/WorkhopperAPIManager'
import { signUpDataManager } from '../Screens/WHApp/SignUp/SignUpDataManager'
import { appDataManager } from '../Screens/WHApp/Models/WHAppDataManager'
import OfflineView from '../SupportingViews/OfflineView'
import WHAlert from '../SupportingViews/WHAlert/WHAlert'
import WHModal, { WHModalProps } from '../SupportingViews/WHModal/WHModal'
import WHSpinner from '../SupportingViews/WHSpinner/WHSpinner'
import { useGoogleLogout } from 'react-google-login'
import workhopperDarkicon from "../../Assets/icon_dark.png"

interface CartAction {
  type: CartActionType
  payload: CartItem | string
}

const cart: Cart = {
  selectedId: "",
  items: []
  //items: [cartItemDefaultValue, cartItemDefaultValue]
}


export const ErrorContext = React.createContext<WHError | null>(null)
export const UserContext  = React.createContext<RegistrationResponse | null>(null)
export const LoginModalContext = React.createContext<boolean>(false)
export const CountriesContext  = React.createContext<Country[]>([])
export const CategoriesContext  = React.createContext<ListingCategory[]>([])
export const AlertContext = React.createContext<WHAlertDialog | null>(null)
export const CurrenciesContext = React.createContext<WorkHopperCurrency[]>([])

//export const ModalContext = React.createContext<WHModalProps | null>(null)

//export const SetModalContext = React.createContext<Dispatch<SetStateAction<WHModalProps | null>>>(()=>{})
export const SetErrorContext = React.createContext<Dispatch<SetStateAction<WHError | null>>>(()=>{})
export const SetSuccessContext = React.createContext<Dispatch<SetStateAction<WHSuccess | undefined>>>(()=>{})
export const SetUserContext = React.createContext<Dispatch<SetStateAction<RegistrationResponse | null>>>(()=>{})
export const SetCountriesContext  = React.createContext<Dispatch<SetStateAction<Country[]>>>(()=>{})
export const SetAlertContext  = React.createContext<Dispatch<SetStateAction<WHAlertDialog | null>>>(()=>{})
export const CartContext = React.createContext<{state: Cart, 
  dispatch: React.Dispatch<any>}>({state: cart, dispatch: ()=>null})
export const ModalContext = React.createContext<{modal: WHModalProps | null, setModal: Dispatch<SetStateAction<WHModalProps | null>>}>({modal: null, setModal: ()=>null})
export const SetLoginModalContext = React.createContext<Dispatch<SetStateAction<boolean>>>(()=>{})
export const SetCurrenciesContext  = React.createContext<Dispatch<SetStateAction<WorkHopperCurrency[]>>>(()=>{})



let items: CartItem[] = []
  let cartStore = sessionStorage
  let temp = cartStore.getItem("cart-items")

  if (!temp) {
      cartStore.setItem("cart-items", JSON.stringify(cart.items))
  } else {
      
      // items = (JSON.parse(temp) as CartItem[]).map(item => (
      //     {...item, bookingDetail: {...item.bookingDetail, startDate: new Date(item.bookingDetail.startDate!)}}
      // ))
      items = (JSON.parse(temp) as CartItem[]).map(item => (
        {...item, bookingDetail: {...item.bookingDetail, startDate: item.bookingDetail.startDate ? new Date(item.bookingDetail.startDate) : undefined, endDate: item.bookingDetail.endDate ? new Date(item.bookingDetail.endDate) : undefined}}
    ))
      
      cart.items = items

  }

  const cartReducer = (state: Cart, action: CartAction): Cart =>{
     
    switch (action.type) {
        
        case CartActionType.UPDATE:
             const cartItem = action.payload as CartItem
            const itemIdx = state.items.findIndex(item => item.id === cartItem.id)
            
            const updatedItems = [...state.items.slice(0, itemIdx), action.payload as CartItem, ...state.items.slice(itemIdx + 1)]
            let temp0 = cartStore.getItem("cart-items") 
              if (!temp0) {return state}
                try {
                    cartStore.setItem("cart-items", JSON.stringify(updatedItems))
                    return {...state, items: updatedItems}
                } catch {
                     return state
                }
           // return {...state, items: updatedItems }
         case CartActionType.ADD:
             
              let temp = cartStore.getItem("cart-items") 
              if (!temp) {return state}

               const found = state.items.find(item => item.id === (action.payload as CartItem).id )
               if (found) { return state }
             
                //let storedItems = JSON.parse(temp)
                const items = [action.payload as CartItem, ...state.items]

                //const items = [action.payload as CartItem, ...storedItems]
                try {
                    cartStore.setItem("cart-items", JSON.stringify(items))
                    return {...state, items}
                } catch {
                     return state
                }
                
            case CartActionType.REMOVE:
                    const id = action.payload as string 
                    const filteredItems = state.items.filter(item => item.id !== id)
                    let temp2 = cartStore.getItem("cart-items") 
                    if (!temp2) {return state}
                      try {
                          cartStore.setItem("cart-items", JSON.stringify(filteredItems))
                          return {...state, items: filteredItems}
                      } catch {
                           return state
                      }
              case CartActionType.CLEAR:
                 
                //localStorage.removeItem("cart-items")
                cartStore.setItem("cart-items", JSON.stringify([]))
                return {...state, items: []}
                  
           default:
            return state
    }
}

const WHStartupLoader = ()=> {

  const [showSpinner, setShowSpinner] = useState(false)
  
  setTimeout(()=> {
      setShowSpinner(true)
  }, 2000)

  return (
      <VStack 
      alignItems="center"
      justifyContent="center"  w="full" h="100vh">
        {
          showSpinner ? (
            <WHSpinner h="auto" />
          ):(
            <></>
          //   <Image
          //   //  fallback={<Skeleton/>}
          //    boxSize="48px"
          //    src="../../images/icon_dark.png"
          //   //  src={workhopperDarkicon}
          //    alt="workhopper"
          //    //h={{base: 12}}
          //    objectFit="contain"
          // />
          )
        }
      
    </VStack>
  )
}



const AppProvider = ({children}: AppProviderProps) => {

    const [user, setUser] = useState<RegistrationResponse | null>(null)
    const [error, setError] = useState<WHError | null>(null)
    const [success, setSuccess] = useState<WHSuccess | undefined>()
    const [alert, setAlert] = useState<WHAlertDialog | null>(null) 
    const history = useHistory()
    const [loading, setLoading] = useState(false)
     const [categories, setCategories] = useState<ListingCategory[]>([])
    const [appLoadError, setAppLoadError] = useState<WHError | null>(null)
    const [modal, setModal] = useState<WHModalProps | null>(null)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [currencies, setCurrencies] = useState<WorkHopperCurrency[]>([])
 

    const [state, dispatch] = useReducer(cartReducer, cart)
    const location = useLocation()

    const toast = useToast()
    const successToast = useToast()
    const clientId = "836248753025-f5co9ukm0v9fuqrs4u0bmvfojt3pp9sj.apps.googleusercontent.com"

    const [xsmall, small, medium, large, xl] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 1240px) and (max-width: 1439px)",
      "(min-width: 1440px)",
    ]);
    
    const onLogoutSuccess = ()=> {
      //console.log("logout successful")
    }
    
    const onFailure = ()=> {
      //setError({title: "", msg: "Couldn't logout. Maybe WorkHopper is offline. Try again"})
    
    }

    const {signOut} = useGoogleLogout({
      clientId,
      onLogoutSuccess,
      onFailure
    })

    async function loadListingCategories() {
      setLoading(true)
       localStorage.removeItem("list-cat")
      
       const result1 = await WorkhopperAPIManager.shared.getCurrencies()
       if (result1.type === ResultType.Failure) {
        setLoading(false)
        //setAppLoadError({title: "", msg: "Couldn't load WorkHopper"})
        setAppLoadError({title: "", msg: result1.error.message })

        setCurrencies([])
        return
       } else {
        setCurrencies(result1.value)
       }

       const result = await WorkhopperAPIManager.shared.listingCategories()
       if (result.type === ResultType.Success) {
            let categories = result.value.map(item => ({id: item.id, title: item.title}))
           // appDataManager.saveListingCategoriesData("list-cat", result.value)
            appDataManager.saveListingCategoriesData("list-cat", categories)

            setCategories(categories)
            setAppLoadError(null)
            
       } else {
         setAppLoadError({title: "", msg: result.error.message })
         setCategories([])
       // setErrorContext({title: result.error.name, msg: result.error.message})
     }
     setLoading(false)
    }

    


    
 
    useEffect(() => {
       
          loadListingCategories()
          //loadData()
      }, [])
    
    useEffect(() => {
         if (error) {
          if (error.msg.includes("session has expired")) {
            signUpDataManager.logUserout()
            setUser(null)
            history.push('/')
          }

          toast({
            duration: 3500,
            isClosable: true,
            status: "error",
            position: xsmall ? "bottom-left" : "top-right",
          //  position: toastPos as ToastPosition,
            description: error.msg
            
          })
        }

        
         
         
      }, [error])

      useEffect(() => {
        if (success) {
          toast({
            duration: 3500,
            isClosable: true,
            status: "success",
            position: xsmall ? "bottom-left" : "top-right",
          //  position: toastPos as ToastPosition,
            description: success.msg
            
          })
        }
       
      }, [toast, success])

      

      // useEffect(() => {
      //    if (user) {
      //     signUpDataManager.saveRegisteredUserData(user)
      //    }
      //   return () => {
      //    }
      // }, [user])

      useEffect(()=> {
        //console.dir(location)
        //  debugger
        // test whether user is been updated  
        if (user) {
          signUpDataManager.saveRegisteredUserData(user)
          return
        }

        // test whether user had logged in before
        let result = signUpDataManager.getRegisteredUserData()
        if (result) {
           setUser(result)
        }

        if (!user) {
          
          signOut()
        }
      }, [user])

      //debugger
      const alertView = alert ? (
          <WHAlert 
          title={alert.title} 
          msg={alert.msg}
          isOpen={alert.isOpen}
          onClose={alert.onClose}
          leftButton={alert.leftButton}
          rightButton={alert.rightButton}

          />
      ): null

      const modalView = ()=> {
        return modal ? (
            <WHModal 
              isLoading={modal.isLoading}
              isModalOpen={modal.isModalOpen}
              header={modal.header}
              breakpoint={modal.breakpoint}
              onClick={modal.onClick}
              onClose={modal.onClose}
              >
                {modal.children}
            </WHModal>
        ): null
      }

    return (
     
      <LoginModalContext.Provider value={showLoginModal}> 
      <SetLoginModalContext.Provider value={setShowLoginModal}>
      <UserContext.Provider value={user}>
        <SetUserContext.Provider value={setUser}>
          <SetSuccessContext.Provider value={setSuccess}>
            {/* <CountriesContext.Provider value={countryItems}> */}
            {/* <SetCountriesContext.Provider value={setCountryItems}> */}
            <CartContext.Provider value={{ state, dispatch }}>
              <CategoriesContext.Provider value={categories}>
                <ErrorContext.Provider value={error}>
                  <SetErrorContext.Provider value={setError}>
                    <AlertContext.Provider value={alert}>
                      <SetAlertContext.Provider value={setAlert}>
                        <ModalContext.Provider value={{ modal, setModal }}>
                            <CurrenciesContext.Provider value={currencies}>
                          {/* <SetModalContext.Provider value={setModal}> */}
                          {appLoadError && (
                            <OfflineView
                              pt={"72px"}
                              msg={appLoadError.msg}
                            />
                          )}
                          {loading && (
                            <WHStartupLoader />
                            // <VStack 
                            //   alignItems="center"
                            //   justifyContent="center"  w="full" h="100vh">
                            //   <Image
                            //     boxSize="40px"
                            //     src={workhopperDarkicon}
                            //     alt="workhopper"
                            //     //h={{base: 12}}
                            //     objectFit="contain"
                            //   />

                            //   {/* <Skeleton isLoaded={!loading} w="full" h={{base: "56px", md: "64px", lg: "72px"}}>
                            //     </Skeleton> */}
                            //   <WHSpinner />
                            // </VStack>
                          )}

                          {categories.length > 0 && !appLoadError && (
                            <>
                              {children}
                              {alertView}
                              {modalView()}
                            </>
                          )}
                          </CurrenciesContext.Provider>
                          {/* </SetModalContext.Provider> */}
                        </ModalContext.Provider>
                      </SetAlertContext.Provider>
                    </AlertContext.Provider>
                  </SetErrorContext.Provider>
                </ErrorContext.Provider>
              </CategoriesContext.Provider>
            </CartContext.Provider>
          </SetSuccessContext.Provider>
          {/* </SetCountriesContext.Provider> */}
          {/* </CountriesContext.Provider> */}
        </SetUserContext.Provider>
      </UserContext.Provider>
      </SetLoginModalContext.Provider>
      </LoginModalContext.Provider>
    );
}

export default AppProvider

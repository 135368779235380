import { Avatar, Box, Circle, HStack, Link, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'

import { NavLink as ReactLink, useHistory } from "react-router-dom";
import { RegistrationResponse } from '../../../Models/Interfaces';
import routes from '../../../routes';
import {t} from 'i18next'

interface PartnerMenuContolsProps {
    partner: RegistrationResponse
    onLogout: ()=>void
}

export const PartnerMenuContols = ({partner, onLogout}: PartnerMenuContolsProps) => {

    const history = useHistory()
    return (
        <>
            <Menu>
            <MenuButton
              px={4}
              py={2}
             // w={"30%"}
              transition="all 0.2s"
              borderRadius="lg"
              borderWidth="1px"
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400" }}
              _focus={{ boxShadow: "outline" }}
            >
              <HStack color="primary.500">
                <Text className="subtitle1">{t("Menu")}</Text>
                <MdKeyboardArrowDown width="48px" height="48px" />
              </HStack>
            </MenuButton>
            <MenuList>
            <MenuItem 
           onClick={()=>{
            history.push(`/${routes.bookingHistory.name}`)
          }}
      minH="48px">
          <Text color="primary.500" className="body2">{t("Booking History")}</Text>
        </MenuItem>
              <MenuItem color="primary.500" minH="48px">
                <Link
                  minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={`/dashboard/${partner.id}/manage+workspaces`}
                  color="primary.500"
                >
                    {t("Your Workspaces")}
                   
                </Link>
              </MenuItem>
              <MenuItem minH="48px">
                <Link
                  color="primary.500"
                  minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={`/dashboard/manage+requests/${partner.id}/bookings/overview`}
                >
                    {t("Your Bookings/Enquiries")}
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              h={"42px"}
              ml={4}
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="lg"
              borderWidth="1px"
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400" }}
              _focus={{ boxShadow: "outline" }}
            >
              <HStack color="primary.500">
              <Avatar 
              size="sm"
            name={partner.partner_details?.name ?? ""} 
            src={partner.avatar ?? ""}
            />
                {/* <Circle bg="primary.500" width="32px" height="32px">
                  <Text className="h6" color="white">
                    {partner.first_name[0].toLocaleUpperCase()}
                  </Text>
                </Circle> */}
                <MdKeyboardArrowDown width="48px" height="48px" />
              </HStack>
            </MenuButton>
            <MenuList color="primary.500">
            <MenuItem minH="48px">
                <Link
                  color="primary.500"
                  w="100%"
                 // minW="8%"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={`/settings`}
                >
                    {t("Manage Account")}
                </Link>
              </MenuItem>
              <MenuItem onClick={onLogout} minH="48px">
                <Text className="body2">{t("Sign Out")}</Text>
              </MenuItem>
              {/* <MenuItem minH="48px">
                <Text className="body2">Switch to Workhopper</Text>
              </MenuItem> */}
            </MenuList>
          </Menu>
    
        </>
    )
}

export default PartnerMenuContols

import { Button, HStack, Spinner, useBreakpointValue, VStack, Text, Image, Spacer } from "@chakra-ui/react";
import { info } from "console";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { publicListingDefaultValue } from "../../../../Models/DefaultValues";
import { Adpage, Advert, PublicListing, QueryItem, ResultType } from "../../../../Models/Interfaces";
import { useQuery, WorkhopperPublicAPIManager } from "../../../../Models/WorkhopperPublicAPIManager";
import { autoCapitalize } from "../../../../utils/utils";
import AdsView from "../../../SupportingViews/AdsView/AdsView";
import LayoutView from "../../../SupportingViews/LayoutView/LayoutView";
import { OfficeDetailLink } from "../../../SupportingViews/Links/Links";
import OfficeItemsList from "../../../SupportingViews/OfficeItemsList/OfficeItemsList";
import OfficeItemsListUpdated from "../../../SupportingViews/OfficeItemsList/OfficeItemsListUpdated";
import OfficeItemView from "../../../SupportingViews/OfficeItemView/OfficeItemView";
import OfflineView from "../../../SupportingViews/OfflineView";
import SearchResultsItemViews from "../../../SupportingViews/SearchResultsItemViews/SearchResultsItemViews";
import WHLoadMore from "../../../SupportingViews/WHLoadMore/WHLoadMore";
import WHSeeMoreList from "../../../SupportingViews/WHSeeMoreList/WHSeeMoreList";
import WHSpinner from "../../../SupportingViews/WHSpinner/WHSpinner";
import { OfficePhotoItem } from "../OfficeDetail/Models/Interfaces";
import OfficeCarousel from "../OfficeDetail/OfficeCarousel/OfficeCarousel";
import { appDataManager } from "../Models/WHAppDataManager";

export interface Queries {
  city?: string;
  capacity?: string;
  category?: string[];
  avg_rating?: string
  amenity?: string[] 
  price?: string
  title?: string
  from?: string
  to?: string
  workspace?: string
  location?: string

}

//returns an empty array if searchString doesn't contain category
// it converts a categoryQueryItemString to a queryItem
const getCategoryQueryItems = (searchString: string): string[] => {
  
  let items = searchString.split('&')
  
  let categoryQueryItemStringList = items.filter(item => item.includes("category"))
  if (categoryQueryItemStringList.length === 0) { return []}
  // convert queryItem string to a queryItem
  const queryItems = categoryQueryItemStringList.map(item => {
    let temp = item.split('=')
    return temp[1]
  })

  return queryItems
}



const SearchResults = () => {

  const location = useLocation()
  //console.dir(location)
   
  const query = useQuery();

  console.log(query);
  
  const city = query.get("city");
  const capacity = query.get("capacity");
  const category = getCategoryQueryItems(location.search.substr(1)) //query.get("category");
  const amenity = query.getAll("amenity")
  const avgRating =  query.get("avg_rating");
  const from =  query.get("from");
  const to =  query.get("to");


  // if (category) {
  //   debugger
  //   let temp = getCategoryQueryItems(location.search.substr(1))
  //   console.dir(temp)
  // }
  let obj: Queries = {
    city: city ?? undefined,
    capacity: capacity ?? undefined,
    category: category.length > 0 ? category : undefined,
    amenity: amenity.length > 0 ? amenity: undefined,
    avg_rating:  avgRating ?? undefined,
    from: from ?? undefined, 
    to: to ?? undefined
  };
  const [listings, setListings] = useState<PublicListing[]>([]);
  const [error, setError] = useState<Error | null>(null); // const [result, setResult] = useState<Result<PublicListing> | null>(null)

  const [isLoading, setisLoading] = useState(false);
 
  const [total, setTotal] = useState<number>(0)
  const [nextURL, setNextURL] = useState<string | undefined>()
  const [resultsDisplayed, setResultsDisplayed] = useState(0)
  const [queriesObj, setQueriesObj] = useState<Queries>(obj) 
  const [isFilteredSearch, setSetIsFilteredSearch] = useState(false) 
  const [advert, setAdvert] = useState<Advert[]>([])
  const [carouselAds, setCarouselAds] = useState<OfficePhotoItem[]>([])
  const history = useHistory()
   
 
  const processSearchQuery = useCallback(async (
    info: Queries
  ) => {
   
     // debugger
     setisLoading(true) 
    let result = await appDataManager.searchOfficeListings(info, nextURL);
     if (result.type === ResultType.Success) {
      let temp = result.value.results
      
      setListings((prev)=> [...prev, ...temp].sort((a, b):number => {
        let aval = a.is_premium ? 0 : 1
        let bval = b.is_premium ? 0 : 1
        return aval - bval
         
      }))

      
     
      setTotal(result.value.count)
      console.log("next", result.value.next)
      setNextURL(result.value.next)
      setError(null);

    } else {
      setError(result.error);
      //setListings([]);
      //setTotal(0)

    }
    setisLoading(false) 
    //setisLoading(false);
  }, [nextURL, listings, total, advert])

  const title = `Found ${total} matching Offices. Showing ${listings.length}`

  useEffect(() => {

        // if (resultsDisplayed === 0) {return}
        
        // if (resultsDisplayed > 1) {
        //   processSearchQuery(queriesObj)
        //   return
        // }
        
        // let queryItems: QueryItem[] = [] 
        // Object.entries(queriesObj).forEach(([key, value]) => {
        //     if (value) {
               
        //         if (Array.isArray(value)) {
        //             (value as string[]).map((item) => queryItems.push({name: key, value: item}))
        //         } else {
        //             queryItems.push({name: key, value})
        //         }
        //     }
        // })

        // const url = WorkhopperPublicAPIManager.shared.appendQueryitems("/search/?", queryItems)
        // history.push(url)
       
        
        if (resultsDisplayed > 0) {
          processSearchQuery(queriesObj);
        }
      
  }, [resultsDisplayed]);
 
  /*
  useEffect(()=>{
    (async ()=>{
     // debugger
      const result = await WorkhopperPublicAPIManager.shared.getAdverts()
      if (result.type === ResultType.Success) {
          //const adImages = result.value.map((item): OfficePhotoItem => ({id: item.id.toString(), imageURL: item.image}) )
          setAdvert(result.value.filter(adItem => adItem.ad_type === Adpage.SEARCH).slice(0, 4))
         // setCarouselAds(adImages)
      } 
     
    })()
   
  }, [])*/

  useEffect(()=>{
    // reset all search 
    setListings([])
    setNextURL(undefined)
    if (resultsDisplayed === 0) {
      setResultsDisplayed((prev)=> prev + 1)
    }
   
    // if (resultsDisplayed > 0) {return}
    // let queryItems: QueryItem[] = [] 
    // Object.entries(queriesObj).forEach(([key, value]) => {
    //     if (value) {
           
    //         if (Array.isArray(value)) {
    //             (value as string[]).map((item) => queryItems.push({name: key, value: item}))
    //         } else {
    //             queryItems.push({name: key, value})
    //         }
    //     }
    // })
    // setResultsDisplayed((prev)=> prev + 1)
    // const url = WorkhopperPublicAPIManager.shared.appendQueryitems("/search/?", queryItems)
    // history.push(url)
   
  }, [queriesObj])

  /*
  useEffect(()=>{
    
    

    setListings([])
    setNextURL(undefined)
    setTotal(0)
    if (total === 0 && listings.length === 0 && !nextURL) {
      processSearchQuery(queriesObj)
    }

  }, [queriesObj, listings, nextURL, total])*/

//  let temp = listings.sort((a, b):number => {
//     let aval = a.is_premium ? 0 : 1
//     let bval = b.is_premium ? 0 : 1
//     return aval - bval
    
//   })

   //console.dir(listings)

  return  (
    <>
      
      {(isLoading && listings.length === 0 && resultsDisplayed === 0) && 
         <WHSpinner h={"64px"}  />
      }
      { (resultsDisplayed === 0 && listings.length === 0 && !isLoading && !error) &&
          <VStack justifyContent="center" alignItems="center" w="100vw" h="100vh">
                 <Text 
                className={"h6"}>
                    {title}
                </Text>
          </VStack>

      }
      {
        (error && !isLoading && listings.length === 0) &&
        <OfflineView 
            onRetry={()=>{
             // setNextURL(undefined)
              setResultsDisplayed((prev)=> prev + 1)
            }}
            pt={"64px"} 
            msg={error.message} />
      }
      {((!error && !isLoading) || (isLoading && listings.length > 0) 
      || (error && listings.length > 0) 
      || (isLoading && listings.length === 0 && resultsDisplayed > 0)
      ) && 
      
        <VStack w="full" spacing={8} paddingY={8}>
        
        <OfficeItemsListUpdated
         listings={listings}
        //  loadData={async (queriesObj)=> {
        //    setSetIsFilteredSearch(true)
        //    setNextURL(undefined)
        //     setTotal(0)
          
            
        //  }}
         setQueries={setQueriesObj}
       // listings={[]}
        isLoading={isLoading}
        title={isLoading ? "Searching for offices" : `Found ${total} matching Offices ${obj.city ? `in ${autoCapitalize(obj.city)}` : ""}. ${listings.length === 0 ? `Try another location` : `Showing ${listings.length}` } `}
        city={obj.city}
        capacity={obj.capacity}
        category={obj.category}
        queryObj={obj}
        setListings={(listings: PublicListing[])=>{setListings(listings)}}
      />
      <Spacer h={4} />
      
      <LayoutView>
        <AdsView page={Adpage.SEARCH} numAds={3} width="100%" height="72px" />
      </LayoutView>
       {/* {
         advert.length > 0 ? (
           
         
           <a style={{
                display: "block",
                width: "90%",
                margin: "0 auto",
              

                }} href={advert[0].url} target="_blank">
            <Image objectFit="cover"  w="100%"  h={"96px"} src={advert[0].image} />
            </a>
         ):null
       } */}
       <WHLoadMore 
        w="144px"
        spinnerHeight={"64px"}
        colorScheme="primary"
        nextURL={nextURL}
        error={error}
        isLoading={isLoading}
        listings={listings}
        counterUpdater={()=>setResultsDisplayed((prev)=> prev + 1)}
       />
        
      </VStack>
        
      }
    </>
  );
};

export default SearchResults;

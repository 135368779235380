import { Drawer, DrawerBody, Link, Image, Text, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Input, useDisclosure, VStack, Divider } from "@chakra-ui/react"
import React, { useContext } from "react"
import { WHDashboardDrawerProps } from "../Models/Interfaces"
import workhopper from "../../../../Assets/icon_dark.png"
import {  NavLink as ReactLink } from "react-router-dom";
import routes from "../../../../routes";
import { UserContext } from "../../../AppProvider/AppProvider";
import WHDrawerBody from "../../../SupportingViews/WHDrawerBody/WHDrawerBody";




const WHDashboardDrawer = ({isOpen, onOpen, onClose}: WHDashboardDrawerProps)=> {
    //const btnRef = React.useRef()
    
    
    const registeredUser = useContext(UserContext)
    // let bname = "" 

    // if (registeredUser) {
    //   bname = registeredUser.business_name ?? ""
    // }

    return (
      <>
        <Drawer
          isOpen={isOpen}
          placement="top"
          onClose={onClose}
        //   finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            
          <DrawerHeader 
                 
                 textAlign="center" 
                 borderBottomWidth="1px"
                 border={"1px solid primary.500"}
                 >
                
                 <Link
                    as={ReactLink}
                    textDecoration="none !important"
                    to={routes.home.path}
                    onClick={onClose}
                 >
                   <Image
                     margin="0 auto"
                     src={workhopper}
                     alt="workhopper"
                   //  height="inherit"
                     boxSize="32px"

                     //width="inherit"
                      objectFit="contain"
                   />
                 </Link>
                
                
             </DrawerHeader>
             <WHDrawerBody 
                user={registeredUser} 
                onClose={onClose}
              />
             
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  export default WHDashboardDrawer
import { VStack, Image, Text, SimpleGrid, Skeleton, Link,   HStack, Box } from '@chakra-ui/react';
import { BreakPoint } from '../OfficeDetail/Models/Interfaces';
import WHPartnerGrid from './WHPartnerGrid';
import WHReason from './WHReason';
import WHWiw from './WHWiw';
import { NavLink as ReactLink } from "react-router-dom";
import TopCityCard from '../../../SupportingViews/TopCityCard/TopCityCard';
import WHBookAvailableSpaces from './WHBookAvailableSpaces';
import WHDownloadApp from './WHDownloadApp';
import WHGetStarted from './WHGettingStarted';
import manSitting from "../../../../Assets/man-sitting.png";
import styles from "./WHHome.module.css"
import { WHHomeProps } from './WHHome';
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView';
import routes from '../../../../routes';
import p4 from "../../../../Assets/partner4.png";
import p3 from "../../../../Assets/partner3.png";
import { useTranslation  } from "react-i18next";

interface WHHomeDesktopProps extends WHHomeProps {

}

const WHHomeDesktop = (props: WHHomeDesktopProps) => {

  console.log(props.homedata)
  const {t} = useTranslation()
    const introView = ()=> {
        return (
          <HStack alignItems="flex-start" spacing={8}>
            <Image
              fallback={<Skeleton rounded="md" w="50%" h="full" />}
              objectFit="cover"
              w="50%"
              h="full"
              src={manSitting}
            />
            <Box w="50%">
              <WHWiw breakpoint={props.breakpoint} />
            </Box>
          </HStack>
        );
    }
 
    const comfortableSpacesView = () => {
      return (
        <VStack spacing={8}>
          <HStack alignItems="flex-start" spacing={8}>
            <Text w="50%" className={styles.title}>
            {t("comfortable")}
              {/* Comfortable Spaces To Work In */}
              </Text>
            <Text w="50%"  className={styles.subtitle}>
              {" "}
              {t("discover")}
              {/* Discover spaces that defines a new dimension of comfortability and
              professionalism. An essential aspect of creativity is not being
              cooped in a boring space. */}
            </Text>
          </HStack>
          <SimpleGrid spacing={4} columns={4} w="full">
            {props.comfortItems.map((imageItem, _) => {
              return (
                <Link
                  key={imageItem.id}
                  h="auto"
                  w="full"
                  href={imageItem.adURL || void 0}
                  isExternal
                  //to={`${routes.cityOffices.path}/${item.city}`}
                  textDecoration={"none !important"}
                >
                  <Image
                    fallback={<Skeleton rounded="xl" w="full" h="full" />}
                    w="full"
                    rounded="xl"
                    height={"240px"}
                    // h="100%"
                    objectFit="cover"
                    src={imageItem.imageURL}
                  />
                </Link>
              );
            })}
          </SimpleGrid>
        </VStack>
      )
    };
 
    const useWorkhopper = ()=> {
 
         return (
             <VStack spacing={8} alignItems="flex-start" w="full">
             <HStack spacing={8} alignItems="flex-start" w="full">
                <Text w="50%" className={styles.title}>
                {t("whyworkhopper")}
                  {/* Why Use WorkHopper */}
                  </Text>
  
                {
                    props.homedata.adverts.length >= 2 ? (
                      <Link
                      h="auto"
                      w="50%"
                       href={props.homedata.adverts[1].url}
                      isExternal
                       //to={`${routes.cityOffices.path}/${item.city}`}
                      textDecoration={"none !important"}
                    >
                         <Image
                           
                           w="full" 
                         
                           height={14}
                          // h="100%" 
                           objectFit="cover" 
                           //key={item.id} 
                           src={props.homedata.adverts[1].image}
                           /> 
                    </Link>
                    ) : null
                     
                }
                </HStack>
                
                 <SimpleGrid spacing={4} columns={2} w="full">
                      {
                          // props.reasonItems.map(reasonItem => (
                          //     <Box h="172px"> 
                          //        <WHReason 
                          //         title={reasonItem.title}
                          //         breakpoint={props.breakpoint}
                          //         image={reasonItem.image}
                          //         description={reasonItem.description}
                          //     />
                          //     </Box>
                             
                          // ))
                          
                        <>
                        <Box h="172px"> 
                        <WHReason 
                            title={t("flexibleworkspaces")}
                            breakpoint={props.breakpoint}
                            image="../images/UseWorkhopperIcons/flexible.png"
                            description={t("flexibleworkspacesD")}
                        />
                        </Box>
                        <Box h="172px"> 
                        <WHReason 
                            title={t("happyusers")}
                            breakpoint={props.breakpoint}
                            image="../images/UseWorkhopperIcons/happy.png"
                            description={t("happyusersdesc")}
                        />
                        </Box>
                        <Box h="172px"> 
                        <WHReason 
                            title={t("simpleinterface")}
                            breakpoint={props.breakpoint}
                            image="../images/UseWorkhopperIcons/interface.png"
                            description={t("simpleinterfacedesc")}
                        />
                        </Box>
                        <Box h="172px"> 
                        <WHReason 
                            title={t("topworking")}
                            breakpoint={props.breakpoint}
                            image="../images/UseWorkhopperIcons/cities.png"
                            description={t("topworkingdesc")}
                        />
                        </Box>
                        </>
                       
                       
                      }
                  </SimpleGrid> 
            </VStack>
         )
    }

    const exploreCitiesView = () => {

        return (
            <VStack w="full" spacing={4}>
            <VStack w="full"> 
                <Text className={styles.title}>
                {t("explorecities")}
                  {/* Explore Coworking Cities */}
                  </Text>
                <Text className={styles.subtitle}>
                {t("searchworkhopper")}
                  {/* Search for workspaces in the most popular cities */}
                  </Text>
            </VStack>
            {
                props.homedata.adverts.length >= 3 ? (
                  <Link
                  h="auto"
                  w="full"
                  href={props.homedata.adverts[2].url}
                  key={props.homedata.adverts[2].id}
                  //to={`${routes.cityOffices.path}/${item.city}`}
                  textDecoration={"none !important"}
                >
                     <Image
                  
                  w="full" 
                
                  height={14}
                 // h="100%" 
                  objectFit="cover" 
                  //key={item.id} 
                  src={props.homedata.adverts[2].image}
                  />  
                 </Link>
                ):null
            }
             
            <SimpleGrid spacing={8} columns={4} w="full">
                {
                    props.homedata.topcities.map(cityItem => (
                      <Link
                      h="272px"
                      as={ReactLink}
                     
                      to={`${routes.cityOffices.path}/${cityItem.city}`}
                      textDecoration={"none !important"}
                    >
                      <TopCityCard item={{id: cityItem.id, city: cityItem.city, count: cityItem.count, image: cityItem.image}} breakpoint={BreakPoint.XS} />
                    </Link>
                    ))
                }
            </SimpleGrid>
            <SimpleGrid spacing={4} columns={1} w="full">
                {
                   props.homedata.adverts.length >=4 ?  (
                      props.homedata.adverts.slice(3, 6).map(advertItem => (
                      <Link
                      h="auto"
                      w="full"
                      href={advertItem.url}
                      key={advertItem.id}
                      //to={`${routes.cityOffices.path}/${item.city}`}
                      textDecoration={"none !important"}
                    >
                         <Image
                      
                      w="full" 
                    
                      height={14}
                     // h="100%" 
                      objectFit="cover" 
                      //key={item.id} 
                      src={advertItem.image}
                      />  
                     </Link>
                    ))
                   ): null
                }
            </SimpleGrid>
        </VStack>
        )
   }
      
  return (
    <VStack w="full" spacing={8}>
    <LayoutView > 
     
    {introView()}
    {comfortableSpacesView()}
    <Box w="100vw">
          <WHPartnerGrid partners={props.homedata.partners} breakpoint={props.breakpoint} />

        </Box>
    {useWorkhopper()}
     {exploreCitiesView()}
    </LayoutView>
    <WHBookAvailableSpaces breakpoint={props.breakpoint} />
    <LayoutView>
        <WHDownloadApp breakpoint={props.breakpoint}/>
    </LayoutView>
    <Box w="full">
         <WHGetStarted breakpoint={props.breakpoint} />
    </Box>
</VStack>
  )
};

export default WHHomeDesktop;

import { Box, VStack, Text, GridItem, Stack, useBreakpointValue, HStack, Link, Spacer, LinkBox, Divider, useMediaQuery, Button } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import WHLayoutGrid from '../../../../SupportingViews/WHLayoutGrid/WHLayoutGrid'
import AllRequestsTable from '../AllRequests/AllRequests'
import RecentBookingsTable from '../RecentBookingsTable'

import {  NavLink as ReactLink, useParams, useRouteMatch} from "react-router-dom";
import { MdChevronRight } from 'react-icons/md'
import { RequestListLink } from '../../../../SupportingViews/Links/Links'
import { BreakPoint } from '../../../WHApp/OfficeDetail/Models/Interfaces'
import { WorkhopperAPIManager } from '../../../../../Models/WorkhopperAPIManager'
import { EnquiredItem, RequestItem, ResultType } from '../../../../../Models/Interfaces'
import { compareDesc, format } from 'date-fns'
import WHSpinner from '../../../../SupportingViews/WHSpinner/WHSpinner'
import OfflineView from '../../../../SupportingViews/OfflineView'
import { CategoriesContext, UserContext } from '../../../../AppProvider/AppProvider'
import { mapOfficeCategory } from '../../../../../utils/utils'
import EnquiresTable from '../EnquiresTable'
import { CounterContext, RequestsContext, SetCounterContext, SetParamsContext, SetRequestsContext } from '../WHManageRequestsProvider'
import BookingsTable from '../BookingsTable'
import { BOOKINGSTATUS } from '../../Models'
import WHRequestAnalyticsItem from '../../../../SupportingViews/WHRequestAnalyticsItem/WHRequestAnalyticsItem'
import { t } from 'i18next'

const getStatedata = (requestType: string, enquiriesData: RequestItem[]) => {

    if (requestType === "bookings") {
        
        return [
            {
                title: "Paid",
                value: enquiriesData.reduce((prev, curr)=> prev + (curr.status === BOOKINGSTATUS.PAID ? 1 : 0) , 0)
            },
            {
                title: "Pending",
                value: enquiriesData.reduce((prev, curr)=> prev + (curr.status === BOOKINGSTATUS.PENDING ? 1 : 0) , 0)
            },
            {
                title: "Cancelled",
                value: enquiriesData.reduce((prev, curr)=> prev + (curr.status === BOOKINGSTATUS.CANCELLED ? 1 : 0) , 0)
                // title: "New",
                // value: enquiriesData.map(item=>new Date(item.dateCreated)).sort(compareDesc).slice(0, 5).length
    
               // value: enquiriesData.map(item=>new Date(item.dateCreated)).sort(compareDesc).slice(0, 5).length
            },
            {
                title: "Approved",
                value: enquiriesData.reduce((prev, curr)=> prev + (curr.status === BOOKINGSTATUS.APPROVED ? 1 : 0) , 0)

                //value: enquiriesData.length
            }
            
            
        ]
    } else {
        return [
            // {
            //     title: "New",
            //     value: enquiriesData.map(item=>new Date(item.dateCreated)).sort(compareDesc).slice(0, 5).length
    
            //  },
            {
                title: "All",
                value: enquiriesData.length
            },
            // {
            //     title: "Pending",
            //     value: enquiriesData.reduce((prev, curr)=> prev + (curr.status === BOOKINGSTATUS.PENDING ? 1 : 0), 0)
            // },
            // {
            //     title: "Viewed",
            //     value: enquiriesData.reduce((prev, curr)=> prev + (curr.is_accept_term ? 1 : 0) , 0)
            // },
        ]
    }
}

const Overview = () => {

    const colspan = useBreakpointValue({base: 2, sm: 2, md: 3, lg: 3, xl: 3})
    const rowspan = useBreakpointValue({base: 4, sm: 4, md: 4, lg: 4, xl: 5})

    const fontsize = useBreakpointValue({base: "h4", sm: "h4", md: "h3", lg: "h2", xl: "h1"})
    const fontWeight = useBreakpointValue({md: "500 !important", lg: "500 !important", xl: "500 !important"})
    const subtitleFontsize = useBreakpointValue({base: "subtitle2", sm: "h6", md: "h6", lg: "h6", xl: "h4"})
    const requestItemsColspan = useBreakpointValue({base: 4, sm: 8, md: 12, lg: 12, xl: 12})
    const breakpoint = useBreakpointValue({base: BreakPoint.XS, sm: BreakPoint.S,  md: BreakPoint.M, lg: BreakPoint.L, xl: BreakPoint.XL})
    const partner = useContext(UserContext)
    const [summaryData, setSummaryData] = useState<{title: string, value: number}[]>([])

    const [xsmall, small, small2, medium, large] = useMediaQuery([
        "(max-width: 599px)",
        "(min-width: 600px) and (max-width: 904px)",
        "(min-width: 905px) and (max-width: 1239px)",
        "(min-width: 1240px) and (max-width: 1439px)",
        "(min-width: 1440px)",
      ]);
    
    let includesOverview = false
    let listURL = ""
    const {url} = useRouteMatch()
    const params: {bizId: string, requestType: string} = useParams()
    const prevParams = useRef<{bizId: string, requestType: string}>()
    const enquiriesData = useContext(RequestsContext)
    const bookingsData = useContext(RequestsContext)

    const setEnquiresData = useContext(SetRequestsContext)
    const setParams = useContext(SetParamsContext)

    const loadCount = useContext(CounterContext)

    if (url.includes("overview")) {
        listURL = url.replace("overview", "list")
        includesOverview = true
    }

    //const [enquiryData, setEnquiryData] = useState<RequestItem[]>([])
    // const [isLoading, setIsLoading] = useState(false)
    // const [error, setError] = useState<Error | null>()
    const categories = useContext(CategoriesContext)
   // const [loadCount, setLoadCount] = useState(0)
    
    const {setNextURL} = useContext(SetCounterContext)
    
    //console.log(loadCount)
 
     
    const statItemColor = (title: string): string => {

        switch (title) {
            case "New":
                return "orange"
            case "Pending":
                return "orange"
            case "Cancelled":
               return "secondary.500"
            case "Paid":
                return "green"
            case "Approved":
                return "primary.500"
            default:
                return "primary.500"
        }
    }

    const requestButtons = () => {
       
      

        return (
            <HStack 
            //    onClick={()=>{
            //        debugger
            //        if (prevParams.current?.requestType !== params.requestType) {
            //         setNextURL(undefined)
            //         setEnquiresData([])
            //        }
                  
            //     }}
                w="100%"
                 
            margin="0 auto" justifyContent={xsmall ? "center" : "flex-start"}>
            <Box 
                    onClick={()=>{
                  // debugger
                   if ("bookings" !== params.requestType) {
                    setNextURL(undefined)
                    setEnquiresData([])
                   }
                  
                }}
                w="auto">
            <RequestListLink 
             
            to={`/dashboard/manage+requests/${params.bizId}/bookings/overview`}>
                <Text 
                //  onClick={()=>{
                //         setParams({...params, requestType: params.requestType})
                //   }}
                    className="caption" >{t("Bookings")}</Text>
            </RequestListLink>
            </Box>

            <Box
             onClick={()=>{
                // debugger
                 if ("enquiries" !== params.requestType) {
                  setNextURL(undefined)
                  setEnquiresData([])
                 }
                
              }}
              w="auto"
            >
             <RequestListLink to={`/dashboard/manage+requests/${params.bizId}/enquiries/overview`}>
                <Text 
                //  onClick={()=>{
                //         setParams({...params, requestType: params.requestType})
                //   }}
                   className="caption">
                    
                    {t("Enquiries")}
                </Text>
            </RequestListLink>
            </Box>
         </HStack>
        )
    }

    

    const statItemViews = summaryData.map((item, idx)=>(
        <GridItem key={item.title}  colSpan={colspan} rowSpan={rowspan}>
                     <WHRequestAnalyticsItem title={item.title} value={item.value} />
         </GridItem>
       
    ))

    const loadBookingStats = useCallback(async (token: string)=>{
        let result = await WorkhopperAPIManager.shared.getPartnerAnalytics(token)
        if (result.type === ResultType.Success) {
            let data: { title: string; value: any }[] = []
           // console.dir(Object.entries(result.value))
            Object.entries(result.value).forEach(([key, value])=>{
                if (key === "total_approved_booking") {
                    data.push({title: "Approved", value})
                    //setSummaryData([...summaryData, {title: "Approved", value}])
                } else if (key === "total_pending_booking") {
                    data.push({title: "Pending", value})
                   // setSummaryData([...summaryData, {title: "Pending", value}])
                } else if (key === "total_paid_booking") {
                    data.push({title: "Paid", value})

                   // setSummaryData([...summaryData, {title: "Paid", value}])
                } else {
                    data.push({title: "All", value})
                }
            })
            setSummaryData([...data])
        } else {
           setSummaryData([])
        }
    }, [])

    const loadEnquiryStats = useCallback(async (token: string, id: string)=>{
        let result = await WorkhopperAPIManager.shared.getPartnerEnquiryAnalytics(token, id)
        if (result.type === ResultType.Success) {
            let data: { title: string; value: any }[] = []
            console.dir(Object.entries(result.value))
            Object.entries(result.value).forEach(([key, value])=>{
                if (key === "total_approved_booking") {
                    data.push({title: "Approved", value})
                 } else if (key === "total_pending_booking") {
                    data.push({title: "Pending", value})
                 } else if (key === "total_paid_booking") {
                    data.push({title: "Paid", value})

                   // setSummaryData([...summaryData, {title: "Paid", value}])
                } else {
                    data.push({title: "All", value})
                }
            })
            setSummaryData([...data])
        } else {
           setSummaryData([])
        }
    }, [])
    

    useEffect(()=>{
        prevParams.current = params
        setParams(params)
        
        
    }, [params.requestType])

    useEffect(()=>{
       // console.dir(partner)
        if (!partner?.access_token) {return}
         if (params.requestType === "bookings") {
            loadBookingStats(partner.access_token)
         } else if (partner.partner_details) {
            loadEnquiryStats(partner.access_token, partner.partner_details.id)
         }
       
    }, [partner?.access_token])

   // console.dir(enquiriesData)

    return (
        <>  
        {
           
               <>
                {/* <Button onClick={()=>{setCounter(prev=>prev + 1)}}>update</Button> */}
                <GridItem colSpan={requestItemsColspan} 
                mb="-16px" rowSpan={breakpoint === BreakPoint.S || breakpoint === BreakPoint.XS ? 2 : 1}>
                   {/* <RequestButtons /> */}
                   {requestButtons()}
               </GridItem>
            
                 {statItemViews}
            
             <GridItem
               colSpan={{base: 4, sm: 8, md: 12}}
               rowSpan={1}
             >
                  <HStack w="full">
               <Text fontWeight="500 !important" className="subtitle1">{t("Recent "+params.requestType[0].toUpperCase() + params.requestType.substr(1))}</Text>
                <Spacer/>
                <Link display="block"  as={ReactLink} to={listURL}>             
                   <HStack>
                     <Text 
                       className="subtitle2" 
                       fontSize="20px">{t("See All")}</Text>
                     <MdChevronRight fontSize="24px"/>
                   </HStack>
                 
               </Link>
                </HStack>
             </GridItem>
             <GridItem colSpan={{base: 4, sm: 8, md: 12}} rowSpan={xsmall || small ? 12 : 10}>
                    {
                       
                                <EnquiresTable  requestType={params.requestType} enquiredItems={enquiriesData.slice(0, 5)} />
                         
                    }
                   
              </GridItem>
              </>
            
           // )  
        }      
        </>
    )
}

export default Overview


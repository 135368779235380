 
import {Box, FormControl, FormLabel, Text, Icon, Input, InputGroup, InputLeftElement, Select, Stack, VStack, PopoverTrigger, Popover, PopoverContent, FormErrorMessage, Spacer, HStack, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Button } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { MdDateRange } from 'react-icons/md'
 
import { BookedDates, BookingDuration, CartActionType, CartItem, DurationCategory, DurationTime, ResultType } from '../../../Models/Interfaces'

import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import {format, add, eachDayOfInterval, sub, intervalToDuration, lastDayOfYear, isAfter, compareAsc, differenceInHours, differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarYears, toDate, parse, eachHourOfInterval, parseISO, formatISO, getYear, getMonth, getDate, getTime, isSaturday, isSunday} from 'date-fns'
import 'react-day-picker/lib/style.css';

//import { yupBookingValidation } from './Models/Schema/BookingValidation'
import { addOfficeBusinessDays, getDurationCategory, getDurationTitle, getLeastPrice, getNumClosedDaysOfInterval, isNumericInput } from '../../../utils/utils'
// import { CartContext, SetErrorContext, UserContext } from '../../../AppProvider/AppProvider'
// import { getDurationLabel } from './ConfirmPay'
// import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager'
import { yupBookingValidation } from '../../Screens/WHApp/ConfirmPay/Models/Schema/BookingValidation'
import { CartContext, SetErrorContext, UserContext } from '../../AppProvider/AppProvider'
import { WorkhopperAPIManager } from '../../../Models/WorkhopperAPIManager'
import { getDurationLabel } from '../../Screens/WHApp/ConfirmPay/ConfirmPay'
import WHTimepicker from '../WHTimepicker/WHTimepicker'
import WHSpinner from '../WHSpinner/WHSpinner'
import OfflineView from '../OfflineView'
 
// function getDate(selectedDay: DayValue): string {
//     if (selectedDay) {
//         return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`
//     }
//     return ""
// }

 

  interface BookingFormItem {
    date: string
    duration: number 
    guests: number
    durationCategory: DurationCategory
}





interface BookingFormViewProps {
  item: CartItem
}



export const getEnddate = (durationType: DurationCategory, selectedDay: Date, duration: number): Date => {
 
  let endDate = new Date()
  if (durationType.toLowerCase().includes("da")) {
   endDate = add(selectedDay, {days: duration})
  } else if (durationType === DurationCategory.HOUR) {
    endDate = add(selectedDay, {hours: duration})
  } else if (durationType === DurationCategory.MONTH) {
    endDate = add(selectedDay, {months: duration})
  } else {
    endDate = add(selectedDay, {years: duration})
  }

  return endDate
}

const getDateOnly = (date: Date) => {

  let day = getDate(date)
  let month = getMonth(date)
  let year = getYear(date)
  return new Date(year, month, day)
}

const isDisabledDateHourlyBooking = (disabledDate: Date, hourlyBookedDates: Date[]): boolean =>{

   let isHourly = false
   let found = hourlyBookedDates.find(item => {
     return getTime(getDateOnly(item)) === getTime(disabledDate)
   })
   if (found) {
     isHourly = true
   }

   return isHourly
}

const isAvailableForHourlyBooking = (bookedDatetimes: Date[], openedHour: Date): boolean => {

  let isAvailable = false 
  
   let found = bookedDatetimes.find(item => getTime(item) === getTime(openedHour))
   if (found === undefined) {
     isAvailable = true
   }

  return isAvailable
}

const getBookedDatetimesForDay = (day: Date, bookedDatetimes: Date[]): Date[] => {

  
  let result = bookedDatetimes.filter(item => getTime(getDateOnly(item)) === getTime(day))
  return result
}



// const datesToBlock = (bookedDates: BookedDates): Date[] =>  {

//   let durationIntervalList: {start_date: Date, end_date: Date}[] = []
//    let dates: Date[] = []
//   // it should return a list of arrival date time pairs(duration interals)
//   Object.entries(bookedDates).forEach(([key, durationTimeItems], idx)=> {
//     for (let item of durationTimeItems) {
//       let temp = {start_date: new Date(item.start_date), end_date: new Date(item.end_date)}
//         let result = eachDayOfInterval({
//           start: temp.start_date, 
//           end: temp.end_date
//         })
        
//         dates = dates.concat(result).sort(compareAsc)
//       //durationIntervalList.push(temp)
//     }
//   })
   
//   return dates
   
// }

const datesToBlock = (durationTimeItems:  DurationTime[]): Date[] =>  {

    let durationIntervalList: {start_date: Date, end_date: Date}[] = []
     let dates: Date[] = []
    // it should return a list of arrival date time pairs(duration interals)
    for (let bookedDate of durationTimeItems) {
        let result = eachDayOfInterval({
            start: new Date(bookedDate.start_date), 
            end: new Date(bookedDate.end_date)
          })

          dates = dates.concat(result) 
    }
   
      
      dates = dates.sort(compareAsc)
     
    return dates
     
  }

// const getMaxDuration = (bookingDurationItems: BookingDuration[], selectedDuration: DurationCategory) {

// }

const isBookingPossibleForDurationCategory = (durationCategory: DurationCategory, duration: Duration | undefined): boolean => {

  //if (bookingDurationItems.length === 0) { return false}

  if (!duration) { return true}

  const categoryTitle = getDurationTitle(durationCategory)

  if (duration.hours !== undefined && categoryTitle.includes("hour")) {
      if (duration.hours > 0 || duration.days && duration.days > 0) {
        return true
      }
  }

  if (duration.days  !== undefined && categoryTitle.includes("day")) {
    if (duration.days > 0 || duration.months && duration.months > 0) {
      return true
    }
}

if (duration.months  !== undefined && categoryTitle.includes("month")) {
  if (duration.months > 0 || duration.years && duration.years > 0) {
    return true
  }
}

if (duration.years !== undefined && duration.years > 0 && categoryTitle.includes("year")) {
  return true
}

   return false

  // if ( duration.hours && duration.hours > 0 || (duration.days && duration.days > 0)  && categoryTitle.includes("hour")) {
  //     return true
  // }



  // if (duration.days && duration.days > 0 && categoryTitle.includes("day")) {
  //   return true
  // }   

// if (duration.months && duration.months > 0 && categoryTitle.includes("month")) {
//   return true
// }



   
}


// selected mode for seleced day
export enum SELECTEDMODE {

  HIGHLIGHTED = "highlighted",
  SELECTED = "selected"

}

export interface SelectedDay {
  date: Date | undefined
  selectedMode: SELECTEDMODE
}

/*
const getOpenedAndClosedTimes = (item: CartItem, selectedDay: SelectedDay): Date[] => {
    let hours: Date[] = []
    if (!item.listing || selectedDay.date === undefined) {return hours}

    if (!item.listing.workspace_details.available_hours.start_time) { return hours }

    if (!item.listing.workspace_details.available_hours.end_time
            ) { return hours }

    const openedDatetime = parse(item.listing.workspace_details.available_hours.start_time, "h:mm a", selectedDay.date)
    const closedDatetime = parse(item.listing.workspace_details.available_hours.end_time, "h:mm a", selectedDay.date)

    return [openedDatetime, closedDatetime]
}*/

const getOpenedAndClosedTimes = (item: CartItem, selectedDay: SelectedDay): Date[] => {
  let hours: Date[] = []
  if (!item.listing) {return hours}

  if (!item.listing.workspace_details.available_hours.start_time) { return hours }

  if (!item.listing.workspace_details.available_hours.end_time
          ) { return hours }

  if (!selectedDay.date) { return hours}

  let openedDatetime = parse(item.listing.workspace_details.available_hours.start_time, "h:mm a", selectedDay.date)
  let closedDatetime = parse(item.listing.workspace_details.available_hours.end_time, "h:mm a", selectedDay.date)
  
  let extraDays = item.listing.workspace_details.extra_days.days ?? []
  if (extraDays.length > 0 && isSaturday(selectedDay.date) && extraDays.includes("Sat") 
     && item.listing.workspace_details.available_hours.sat_start_time && item.listing.workspace_details.available_hours.sat_end_time) {
    openedDatetime = parse(item.listing.workspace_details.available_hours.sat_start_time, "h:mm a", selectedDay.date)
    closedDatetime = parse(item.listing.workspace_details.available_hours.sat_end_time, "h:mm a", selectedDay.date)
  }

  if (extraDays.length > 0 && isSunday(selectedDay.date) && extraDays.includes("Sun") 
  && item.listing.workspace_details.available_hours.sun_start_time && item.listing.workspace_details.available_hours.sun_end_time) {
 openedDatetime = parse(item.listing.workspace_details.available_hours.sun_start_time, "h:mm a", selectedDay.date)
 closedDatetime = parse(item.listing.workspace_details.available_hours.sun_end_time, "h:mm a", selectedDay.date)
}
  return [openedDatetime, closedDatetime]
}

const maxHourDuration = (disabledDates: Date[], selectedDatetime: Date): {hoursAvailable: number, date: Date | undefined} =>{
    
    let hoursAvailable = 0
    const disabledDateAfterSelected = disabledDates.find(disabledDateItem => isAfter(disabledDateItem, selectedDatetime))
    const endDatetime = add(selectedDatetime, {
        hours: 1
    })
    
     
    if (!disabledDateAfterSelected) {
         return {hoursAvailable, date: undefined}
    }

    hoursAvailable = differenceInHours(disabledDateAfterSelected, selectedDatetime)
    if (hoursAvailable === 0) {
        return {hoursAvailable: 1, date: disabledDateAfterSelected}
    }

    return {hoursAvailable, date: disabledDateAfterSelected}
}


const WHBookingFormView = ({item}: BookingFormViewProps) => { 
  
  //const [selectedDay, setSelectedDay] = useState<Date | undefined>(isValid(item.bookingDetail.startDate) ? item.bookingDetail.startDate : undefined)
  const  [selectedDay, setSelectedDay] = useState<SelectedDay>({
        date: item.bookingDetail.startDate ? item.bookingDetail.startDate : undefined,
        selectedMode: SELECTEDMODE.HIGHLIGHTED
    })

  //const [bookedDates, setBookedDates] = useState<BookedDates>(bookedDatesDefaultValue)
  const [bookedDates, setBookedDates] = useState<BookedDates>()

 //const [datesToDisable, setDatesToDisable] = useState<(Date)[]>([...datesToBlock(bookedDates)])
  const [datesToDisable, setDatesToDisable] = useState<Date[]>([])

  const [maxDuration, setMaxDuration] = useState(0)
  const [bookingDuration, setBookingDuration] = useState<BookingDuration[]>(item.listing?.booking_duration ?? [])
  const [durationInOffice, setDurationInOffice] = useState(0)
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  // states for hourly bookings
  const [datetime, setDatetime] = useState<SelectedDay>({
    date: undefined,
    selectedMode: SELECTEDMODE.HIGHLIGHTED
})

const [disabledDatetimes, setDisabledDatetimes] = useState<Date[]>([])
const bookedDatetimesRef = useRef<Date[]>([])


const onDatetimeSelected = ((selected: SelectedDay) =>  setDatetime({...datetime, date: selected.date, selectedMode: selected.selectedMode}))
//const openedAndClosedTimes = getOpenedAndClosedTimes(item, selectedDay)
const bookedDatetimes = [
    {
      start: "2026-01-28T11:00",
      end: "2026-01-28T12:00",
    },
    {
      start: "2026-01-28T12:00",
      end: "2026-01-28T13:00",
    },
    
  ]; 
  let durationRef = useRef<Duration>()
  let numClosedDaysRef = useRef<number>(0)
  let datesToBlockRef = useRef<Date[]>([])
  let removeDatesBlockedBySelectedRef = useRef(false)
  let intervalAvailableForBookingRef = useRef<{start: Date, end: Date}>()
  

    
  let form: BookingFormItem  = {
    date: item.bookingDetail.startDate  ? format(item.bookingDetail.startDate, "PP") : "",
    duration: item.bookingDetail.duration,
    guests: item.bookingDetail.guests,
    durationCategory: getDurationCategory(item.bookingDetail.durationType) 
}

 
const user = useContext(UserContext)

const bookedDayStyle = `.DayPicker-Day--disabled:not(:empty) {
  text-decoration: line-through;
  text-decoration-color: #ec0f47;
  text-decoration-thickness: 2px;
  color: #212121;
  // background-color: rgba(255, 0, 0, 0.54);
  // color: white;
  // width: 40px;
  // height: 40px;
   
}`

const modifiers = selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED ? {
  highlighted: selectedDay.date!
} : {
  selected: selectedDay.date!
}

 

const modifiersStyles = selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED ? {
  highlighted: {
    color: "#fff",
    backgroundColor: "orange",
    width: "40px",
    height: "40px"
  },
  
} : {
   
  selected: {
    color: "#fff",
    backgroundColor: "green",
    width: "40px",
    height: "40px"
  }
}


const setErrorContext = useContext(SetErrorContext)
 
const closedDays = (): number[] => {
  
  let defaultValues: number[] = [0, 6]
  if (!item.listing) {return defaultValues}
  let extraDays = item.listing.workspace_details.extra_days.days ?? []
  if (extraDays.length === 0) {return defaultValues}
  defaultValues = []
  if (!extraDays.includes("Sun")) {
    defaultValues.push(0)
  }
  if (!extraDays.includes("Sat")) {
    defaultValues.push(6)
  }

  return defaultValues
}

const openedHours = (): Date[] => {

    let hours: Date[] = []
    if (!item.listing || selectedDay.date === undefined) {return hours}

    if (!item.listing.workspace_details.available_hours.start_time) { return hours }

    if (!item.listing.workspace_details.available_hours.end_time
            ) { return hours }

   
    let openedDatetime = parse(item.listing.workspace_details.available_hours.start_time, "h:mm a", selectedDay.date)
    let closedDatetime = parse(item.listing.workspace_details.available_hours.end_time, "h:mm a", selectedDay.date)
    
     
    let extraDays = item.listing.workspace_details.extra_days.days ?? []
    if (extraDays.length > 0 && isSaturday(selectedDay.date) && extraDays.includes("Sat") 
       && item.listing.workspace_details.available_hours.sat_start_time && item.listing.workspace_details.available_hours.sat_end_time) {
      openedDatetime = parse(item.listing.workspace_details.available_hours.sat_start_time, "h:mm a", selectedDay.date)
      closedDatetime = parse(item.listing.workspace_details.available_hours.sat_end_time, "h:mm a", selectedDay.date)
    }

    if (extraDays.length > 0 && isSunday(selectedDay.date) && extraDays.includes("Sun") 
    && item.listing.workspace_details.available_hours.sun_start_time && item.listing.workspace_details.available_hours.sun_end_time) {
   openedDatetime = parse(item.listing.workspace_details.available_hours.sun_start_time, "h:mm a", selectedDay.date)
   closedDatetime = parse(item.listing.workspace_details.available_hours.sun_end_time, "h:mm a", selectedDay.date)
 }
    
    const openedHoursInterval = eachHourOfInterval({
        start: openedDatetime,
        end:  sub(closedDatetime, {
            hours: 1
        })
    })
     
    
    return openedHoursInterval
}


const {dispatch} = useContext(CartContext)

    const personItemViews = [1,2,3,4,5].map((item, idx)=> 
                    <option key={item}>
                    {item}
                    </option>)
        
        
                     
      
    
    // const durationTypeItemViews = item.listing?.booking_duration.filter(item => (getDurationCategory(item.minimum_duration) as DurationCategory) !== DurationCategory.HOUR) .map((item,idx)=>(
    //   <option disabled={!isBookingPossibleForDurationCategory(getDurationCategory(item.minimum_duration), durationRef.current) || getDurationCategory(item.minimum_duration) as DurationCategory === DurationCategory.HOUR} key={idx}>
    //        {getDurationCategory(item.minimum_duration) as DurationCategory}
    //   </option>
    // ))

    const durationTypeItemViews = item.listing?.booking_duration.map((item,idx)=>(
      <option disabled={!isBookingPossibleForDurationCategory(getDurationCategory(item.minimum_duration), durationRef.current)} key={idx}>
           {getDurationCategory(item.minimum_duration) as DurationCategory}
      </option>
    ))
  
  

    // variables for hourly bookings
    const earliestDatetime = (): Date | undefined => {
        if (selectedDay.date) {
            return  parse("12:00 AM", "h:mm a", selectedDay.date)
        }
    }

    const latestDatetime = (): Date | undefined => {
        if (selectedDay.date) {
            return  parse("11:00 PM", "h:mm a", selectedDay.date)
        }
    }
   
    const hoursBeforeOpenedHour = useCallback((): Date[] => {
         const result = getOpenedAndClosedTimes(item, selectedDay)
        const earliestTimeOfDay = earliestDatetime()
        if (result.length === 0 || earliestTimeOfDay === undefined) { return []}
         
        const openedDatetime = result[0]
        return eachHourOfInterval({
            start: earliestTimeOfDay,
            end:  sub(openedDatetime, {
                 hours: 1
            })
        })
    }, [selectedDay])

 

    const hoursAfterClosedHour = useCallback((): Date[] => {
        const result = getOpenedAndClosedTimes(item, selectedDay)
        const latestTimeOfDay = latestDatetime()
        if (result.length === 0 || latestTimeOfDay === undefined) { return []}
         
        const closedDatetime = result[1]
        return eachHourOfInterval({
            start: closedDatetime,
            end: latestTimeOfDay
        })
    },[selectedDay])

     //hoursAfterClosedHour()
     //hoursBeforeOpenedHour()

   
    const onDaySelect = useCallback((day, {selected, disabled}) => {
        let mode = SELECTEDMODE.SELECTED
         if (disabled) {
          return
        }
        if (selected) {
          //setSelectedDay(undefined)

          if (selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED) {
             mode = SELECTEDMODE.SELECTED
             //setDatetime({date: undefined, selectedMode: SELECTEDMODE.HIGHLIGHTED})
              //setSelectedDay({...selectedDay, selectedMode: SELECTEDMODE.SELECTED})
          } else {
            mode = SELECTEDMODE.HIGHLIGHTED
              //setSelectedDay({...selectedDay, selectedMode: SELECTEDMODE.HIGHLIGHTED})

          }
         //  return
        }  else {
          // selected a new date so set temp mode to all selecteed useEffect to run
          //setSelectedDay({...selectedDay, date: day, selectedMode: SELECTEDMODE.HIGHLIGHTED})
        }
        
         
        removeDatesBlockedBySelectedRef.current = false
        setDatetime({date: undefined, selectedMode: SELECTEDMODE.HIGHLIGHTED})
        setSelectedDay({...selectedDay, date: day, selectedMode: mode})
         

        let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, startDate: day}}
        dispatch({type: CartActionType.UPDATE, payload: temp})
      },
      [selectedDay],
    )

    const onDayChange = useCallback((day) => {
       setSelectedDay(day)
      },
      [selectedDay],
    )

    const range = { 
      
      //before: new Date(2021, 10, 5)
      before: new Date()

    }

    const loadBookedDates = useCallback(
      async () => {
         // if (!user || !user.access_token) return 
      if (!item.listing || !item.listing.id) return
          
      setIsLoadingBookings(true)
      const result1 = await WorkhopperAPIManager.shared.getBookedDatesForDuration(item.listing.id, "hourly")
      //console.dir(result1)
      if (result1.type === ResultType.Failure) {
        setError(result1.error)
        setIsLoadingBookings(false)
         return
      }
       
         // console.dir(result)
         //let hourlyBookedDates: Date[] = []
         Object.entries(result1.value).forEach(([key, value], idx) => {
          const durationTimes = value as DurationTime[]
          const hourlyBookedDates = durationTimes.map(durationTimeItem => (
            eachHourOfInterval({
              start: parseISO(durationTimeItem.start_date),
              end: parseISO(durationTimeItem.end_date)
            })
          )).flat()
          bookedDatetimesRef.current = hourlyBookedDates.slice() 

          //setDisabledDatetimes([...hoursBeforeOpenedHour(), ...hourlyBookedDates, ...hoursAfterClosedHour()] )

       })
      
          const result = await WorkhopperAPIManager.shared.getBookedDatesForDuration(item.listing.id, "all")
          if (result.type === ResultType.Success) {
               
              
              Object.entries(result.value).forEach(([key, value], idx) => {
                  
                    const result = value as DurationTime[]
                    const bookingItem: DurationTime = {
                        // test case after booked dates
                          start_date: "2027-11-10",
                          end_date: "2022-11-11"
                        
                        /* test case after booked dates
                          start_date: "2021-11-08",
                          end_date: "2021-11-23"
                        */
                        /// test case in between booked dates
                        //   start_date:  "2022-02-23",
                        //   end_date: "2022-02-28"
                        
                        
                        /* test case #1
                        // start_date: "2024-11-06",
                        // end_date: "2027-11-07"
                        */
                        /* test case 2
                        start_date: "2027-11-06",
                        end_date: "2027-11-10"
                        */
                        /* test case 3
                        start_date: "2025-11-06",
                        end_date: "2027-11-06"
                        */
                       // testing bookedItem start date for clashing
                       /* test case 1 
                       start_date: "2024-09-18",
                       end_date: "2025-01-06"
                       */
                        /* test case 2
                        start_date: "2024-11-06",
                        end_date: "2025-01-06"*/
                         /* test case 3
                         start_date: "2022-11-06",
                         end_date: "2024-11-06"
                         */
                        
                    }

                    
                     
                    /* for each booked date
                    for (let bookedItem of result) {
                        
                        // convert dates to time
                        const bookedItemEnddateTime = new Date(bookedItem.end_date).getTime()
                        const bookedItemStartdateTime = new Date(bookedItem.start_date + 'T00:00').getTime()
                        const bookingItemStartdateTime = new Date(bookingItem.start_date + 'T00:00').getTime()
                        const bookingItemEnddateTime = new Date(bookingItem.end_date + 'T00:00').getTime()
                        
                         
                        
                        // Check if bookedItem end date clashes with booking item 

                        if (bookedItemEnddateTime > bookingItemStartdateTime && bookedItemEnddateTime < bookingItemEnddateTime) {
                           // console.log("Date clash")
                           // console.dir(new Date(bookedItem.end_date))
                        }
                        

                        // for daily and hourly
                        if (bookedItemEnddateTime === bookingItemStartdateTime || 
                            bookedItemEnddateTime === bookingItemEnddateTime) {
                                //console.log("Date clash by equality of bookedItem end date")
                                //console.dir(new Date(bookedItem.end_date))
                            }

                        // Check if bookedItem start date clashes with booking item 
                        if (bookedItemStartdateTime > bookingItemStartdateTime && bookedItemStartdateTime < bookingItemEnddateTime) {
                           // console.log("Date clash by bookedItem start date")
                            //console.dir(new Date(bookedItem.start_date))
                        }

                        // for daily and hourly
                        if (bookedItemStartdateTime === bookingItemStartdateTime || 
                            bookedItemStartdateTime === bookingItemEnddateTime) {
                                //console.log("Date clash by equality of bookedItem start date")
                               // console.dir(new Date(bookedItem.start_date))
                        }
                    }*/

                    datesToBlockRef.current = datesToBlock(value as DurationTime[])
                    
                    
                   setDatesToDisable(datesToBlockRef.current)
              })
              
              setDisabledDatetimes([...hoursBeforeOpenedHour(), ...bookedDatetimesRef.current, ...hoursAfterClosedHour()] )
              setError(null)
              
          } else {
            setError(result.error)
          }

          setIsLoadingBookings(false)

      },
      [user?.access_token],
    )

    const loadHourlyBookedDates = useCallback(async ()=>{
       
      // const hoursBefore = hoursBeforeOpenedHour()
      // const hoursAfter = hoursAfterClosedHour()
      // if (!item.listing || !item.listing.id || hoursBefore.length === 0 || hoursAfter.length === 0) return
      if (!item.listing || !item.listing.id) {return}
      if (disabledDatetimes.length > 0) {
        //setDatetime({date: undefined, selectedMode: SELECTEDMODE.HIGHLIGHTED})
        return
      }

      setIsLoadingBookings(true)
      const result = await WorkhopperAPIManager.shared.getBookedDatesForDuration(item.listing.id, "hourly")
      if (result.type === ResultType.Success) {
          //console.dir(result)
          Object.entries(result.value).forEach(([key, value], idx) => {
            const durationTimes = value as DurationTime[]
            const hourlyBookedDates = durationTimes.map(durationTimeItem => (
              eachHourOfInterval({
                start: parseISO(durationTimeItem.start_date),
                end: parseISO(durationTimeItem.end_date)
              })
            )).flat()
            
             //bookedDatetimesRef.current = [...hoursBeforeOpenedHour(), ...hourlyBookedDates, ...hoursAfterClosedHour()] 
             bookedDatetimesRef.current = hourlyBookedDates.slice() 

             setDisabledDatetimes([...hoursBeforeOpenedHour(), ...hourlyBookedDates, ...hoursAfterClosedHour()] )
             //setDatetime({date: undefined, selectedMode: SELECTEDMODE.HIGHLIGHTED})
          })
         
      } else {
         setError(result.error)
      }
      setIsLoadingBookings(false)

    },[selectedDay])

    const updateMaxDuration = useCallback(
      (
        intervalAvailableForBooking: { start: Date; end: Date },
        duration: Duration,
        numClosedDays: number,
        durationType: DurationCategory
      ) => {
        const durationItem = {...duration, days: duration.days !== undefined ? duration.days - numClosedDays : duration.days}
        if (
          durationType === DurationCategory.HOUR &&
          isBookingPossibleForDurationCategory(DurationCategory.HOUR, durationItem)
        ) {
         
          // setMaxDuration(
          //   differenceInHours(
          //     intervalAvailableForBooking.end,
          //     intervalAvailableForBooking.start
          //   )
          // );
          ///setMaxDuration(differenceInHours(interval.end, interval.start));
        } else if (
           durationType === DurationCategory.DAY &&
          isBookingPossibleForDurationCategory(DurationCategory.DAY, durationItem)
        ) {
          setMaxDuration(
            differenceInCalendarDays(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            ) - numClosedDays
          );
        } else if (
           durationType === DurationCategory.MONTH &&
          isBookingPossibleForDurationCategory(DurationCategory.MONTH, durationItem)
        ) {
          // setMaxDuration(duration.months)
          setMaxDuration(
            differenceInCalendarMonths(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            )
          );
          //setMaxDuration(differenceInMonths(interval.end, interval.start));
        } else if (
          durationType === DurationCategory.YEAR &&
          isBookingPossibleForDurationCategory(DurationCategory.YEAR, durationItem)
        ) {
          //setMaxDuration(duration.years)
          setMaxDuration(
            differenceInCalendarYears(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            )
          );
        } else {
          setMaxDuration(0);
        }
      },
      []
    );

    // useEffect(()=>{

    //   if (item.bookingDetail.durationType === DurationCategory.HOUR) {
    //       loadHourlyBookedDates()
    //   }

    // }, [item.bookingDetail.durationType])



    const onResetForm = useCallback(()=> {

      if (!item.listing) { return }
      
      durationRef.current = undefined
      if (datesToBlockRef.current.length > 0) {
        setDatesToDisable([...datesToBlockRef.current])
      }
      const leastDuration = getLeastPrice(item.listing.booking_duration)
      let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration: 1, durationType: getDurationCategory(leastDuration.minimum_duration), startDate: undefined, guests: 1}}
      dispatch({type: CartActionType.UPDATE, payload: temp})

    }, [])

    const getAvailableDuration = (intervalAvailableForBooking: {start: Date, end: Date}): BookingDuration[] => {
      const duration = intervalToDuration(intervalAvailableForBooking);
  
      durationRef.current = duration;
      intervalAvailableForBookingRef.current = intervalAvailableForBooking


      const durationItems = bookingDuration.filter(
       (bookingDurationItem) => {
         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.HOUR &&
             isBookingPossibleForDurationCategory(DurationCategory.HOUR, duration)
         ) {
          // setMaxDuration(differenceInHours(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
            return bookingDurationItem;
         }

         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.DAY &&
             isBookingPossibleForDurationCategory(DurationCategory.DAY, duration)
         ) {
           //setMaxDuration(differenceInDays(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
           return bookingDurationItem;
         }

         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.MONTH &&
             isBookingPossibleForDurationCategory(DurationCategory.MONTH, duration)
         ) {
          // setMaxDuration(differenceInMonths(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
           return bookingDurationItem;
         }

         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.YEAR &&
             isBookingPossibleForDurationCategory(DurationCategory.YEAR, duration)
         ) {
          // setMaxDuration(differenceInYears(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
           return bookingDurationItem;
         }
       }

       
     );

     return durationItems
    }

    
    // disables booked and closed hours
    useEffect(() => {
       //onResetForm()
       loadBookedDates()
       
      
       removeDatesBlockedBySelectedRef.current = true
       
       
      return () => {
        //cleanup
      }
    }, [])

    // compute closed hours
    useEffect(()=>{
      
      if (selectedDay.date === undefined || bookedDatetimes.length === 0) {return}
      const hoursBefore = hoursBeforeOpenedHour()
       const hoursAfter = hoursAfterClosedHour()
       if (!item.listing || !item.listing.id || hoursBefore.length === 0 || hoursAfter.length === 0 || item.bookingDetail.durationType !== DurationCategory.HOUR) return
      loadHourlyBookedDates()
    
    },[selectedDay])

    useEffect(()=>{

      const officeHours = openedHours()
      /*
       if (disabledDatetimes.length === 0 || 
        datesToDisable.length === 0) { return }
        let someDates: Date[] = []
        if (selectedDay.date !== undefined && item.bookingDetail.durationType === DurationCategory.HOUR) {
            // unblock hourly bookings with at least 1 openhed hour
            for (let date of datesToDisable) {
              let temp = isDisabledDateHourlyBooking(getDateOnly(date), disabledDatetimes)
              //console.log(temp)
              let dateTimes = getBookedDatetimesForDay(getDateOnly(date), disabledDatetimes)
             // let hours = openedHours()
              if ((temp && (dateTimes.length === officeHours.length)) || !temp) {
                  someDates.push(date)
              }
            }
           
           setDatesToDisable(someDates)
         }*/

         if (bookedDatetimesRef.current.length === 0 || 
          datesToBlockRef.current.length === 0) { return }
          let someDates: Date[] = []
          if ( item.bookingDetail.durationType === DurationCategory.HOUR) {

         // if (selectedDay.date !== undefined && item.bookingDetail.durationType === DurationCategory.HOUR) {
              // unblock hourly bookings with at least 1 openhed hour
              for (let date of datesToBlockRef.current) {
                let temp = isDisabledDateHourlyBooking(getDateOnly(date), bookedDatetimesRef.current)
                //console.log(temp)
                let dateTimes = getBookedDatetimesForDay(getDateOnly(date), bookedDatetimesRef.current)
               // let hours = openedHours()
                if ((temp && (dateTimes.length === (officeHours.length + 1) )) || !temp) {
                    someDates.push(date)
                }
              }
             
             setDatesToDisable(someDates)
           } else {
             setDatesToDisable(datesToBlockRef.current)
           }
       

    }, [selectedDay.selectedMode, selectedDay.date, item.bookingDetail.durationType, disabledDatetimes])

    // update disabled datetimes
    useEffect(()=>{

      if (selectedDay.date === undefined ||
        item.bookingDetail.durationType !== DurationCategory.HOUR) {return}

        let dateTimes = getBookedDatetimesForDay(getDateOnly(selectedDay.date), bookedDatetimesRef.current)
        setDisabledDatetimes([...hoursBeforeOpenedHour(), ...dateTimes, ...hoursAfterClosedHour()])

    }, [selectedDay.date, item.bookingDetail.durationType])

    useEffect(() => {

       
      setSelectedDay({...selectedDay, 
                        date: item.bookingDetail.startDate, 
                        selectedMode: item.bookingDetail.startDate ? selectedDay.selectedMode : SELECTEDMODE.HIGHLIGHTED
                      })
      
      return () => {
      }
    }, [item.bookingDetail.startDate])
    
    // compute endate when startdate or duration or durationtype channges
    useEffect(() => {

        //debugger
      if (!item.bookingDetail.startDate || 
          item.bookingDetail.duration === 0 
          //|| item.bookingDetail.durationType !== DurationCategory.DAY
          ) 
          { return}
      
     const disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
     isAfter(disabledDate, item.bookingDetail.startDate!)
   );

   let tempEndDate: Date 
   //let tempStartdate: Date = item.bookingDetail.startDate

  // if (disabledDayAfterIdx > -1) { return }
  
   if (item.bookingDetail.durationType !== DurationCategory.DAY) {
       if (item.bookingDetail.durationType === DurationCategory.MONTH) {
        tempEndDate = add(item.bookingDetail.startDate, {
            months: item.bookingDetail.duration
        })
       } else if (item.bookingDetail.durationType === DurationCategory.YEAR) {
        tempEndDate = add(item.bookingDetail.startDate, {
            years: item.bookingDetail.duration
        })
       } else {
        tempEndDate = add(item.bookingDetail.startDate, {
            hours: item.bookingDetail.duration
        })
         //tempStartdate = datetime.date
       } 

       let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail,  endDate: tempEndDate}}
       dispatch({type: CartActionType.UPDATE, payload: temp})
       
    //    console.dir(temp)
    //    console.log(formatISO(tempEndDate))
    //    let end = formatISO(tempEndDate) 
       //format(tempEndDate, "yyyy-MM-dd'T'HH:mm:ss")
       //console.log(end)

       //console.dir(parseISO(end))
       //console.dir(parse(end, "yyyy-MM-dd'T'HH:mm:ss", tempEndDate))
        
       return
   }
   
    const openedDays = [0, 1, 2, 3, 4, 5, 6].filter(weekDayNum => !closedDays().includes(weekDayNum))
     
    const interval = addOfficeBusinessDays( 
                                              
                                                openedDays,
                                                closedDays(), 
                                                item.bookingDetail.startDate,
                                                 item.bookingDetail.duration
                                                )
    
    
    // console.dir(interval[interval.length - 1])
    // console.dir(item.bookingDetail)
    let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail,  endDate: interval[interval.length - 1]}}
    dispatch({type: CartActionType.UPDATE, payload: temp})
    
    }, [item.bookingDetail.duration, item.bookingDetail.durationType, item.bookingDetail.startDate])


    
    
    useEffect(()=>{

      

      //if (!bookedDates || !item.listing?.booking_duration) { return }
      if (!datesToDisable || !item.listing?.booking_duration) { return }
      if (!selectedDay.date) {
        return;
      }
      
    
      const day = selectedDay.date;
      const bookingDuration = item.listing.booking_duration
      let  disabledDayAfterIdx = -1

      /*
      if (datesToDisable.length > 0) {
  
        disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
        isAfter(disabledDate, day)
      );
  
      const intervalAvailableForBooking = {
        start: day as Date,
        end: sub(datesToDisable[disabledDayAfterIdx], {
          days: 1
        })  
      }
  
      // const intervalAvailableForBooking = {
      //   start: day as Date,
      //   end: sub(datesToDisable[disabledDayAfterIdx], {
      //     days: 1
      //   })  
      // }
  
      

      setBookingDuration(getAvailableDuration(intervalAvailableForBooking));
      } */

 
      if (selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED) {
        //setDatesToDisable(datesToBlockRef.current)

       
         // block only booked dates
      } else if (selectedDay.selectedMode === SELECTEDMODE.SELECTED) {
        // bloack booked dates and dates based on selected day

        

       

        // case 1: block dates before selected date
        const todayDate = new Date()
        const daysBeforeSelectedDate = eachDayOfInterval({
          start: new Date(getYear(todayDate), getMonth(todayDate), 1),
          end: sub(day, { days: 1 }),
        });

       

        // case 2: block dates after selected date
        const disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
          isAfter(disabledDate, day)
        );


        // disable dates before selected date
        if (disabledDayAfterIdx <= -1) {
          durationRef.current = undefined
          setMaxDuration(0)
          // setDatesToDisable([
          //   ...datesToBlockRef.current,
            
          // ]);
          // setDatesToDisable([
          //   ...datesToBlockRef.current,
          //   ...daysBeforeSelectedDate,
          // ]);
           return;
        }

        // get duration of time available for booking
        
        // const intervalAvailableForBooking = {
        //   start: day as Date,
        //   end: sub(datesToDisable[disabledDayAfterIdx], {
        //     days: 1
        //   })  
        // }

        const intervalAvailableForBooking = {
            start: day as Date,
            end: datesToDisable[disabledDayAfterIdx]
          }


    // disable all dates after selected date if there's at least 1 disabled date after it
        // const tempDuration = intervalToDuration(intervalAvailableForBooking)
        // let duration = {...intervalToDuration(intervalAvailableForBooking), days:  tempDuration.days ? tempDuration.days + 1 : tempDuration.days} ;
        
        let duration = intervalToDuration(intervalAvailableForBooking)  


        // update duration days based on closed days
        if (closedDays().length > 0) {
           // compute no of closed days w/i interval 
          const daysOfInterval = eachDayOfInterval(intervalAvailableForBooking)
          let numClosedDays = getNumClosedDaysOfInterval(closedDays(), daysOfInterval)
          numClosedDaysRef.current = numClosedDays
          //console.log(numClosedDays)
        }
         
      
        durationRef.current = duration;
        intervalAvailableForBookingRef.current = intervalAvailableForBooking
        
 
        const firstBookedDateAfterSelectedDay =
          datesToDisable[disabledDayAfterIdx];
        const daysAfterBookedDate = eachDayOfInterval({
          start: firstBookedDateAfterSelectedDay,
          end: lastDayOfYear(firstBookedDateAfterSelectedDay),
        });

        // get available duration category based on booking duration available
        const availableDuration = getAvailableDuration(intervalAvailableForBooking)

        if (availableDuration.length > 0) {
          setBookingDuration(availableDuration);
          //const leastDuration = getLeastPrice(item.listing.booking_duration)
          const leastDuration = getLeastPrice(availableDuration)
          let temp: CartItem = {
            ...item,
            bookingDetail: {
              ...item.bookingDetail,
              duration: 1,
              durationType: getDurationCategory(leastDuration.minimum_duration),
            },
          };

          dispatch({ type: CartActionType.UPDATE, payload: temp });
        }

         // set max duration base selected duration category
         if (item.bookingDetail.durationType !== DurationCategory.HOUR) {
            updateMaxDuration(intervalAvailableForBooking, duration, numClosedDaysRef.current, item.bookingDetail.durationType)
         }

        // setDatesToDisable([
        //   ...datesToBlockRef.current,
        //   ...daysBeforeSelectedDate,
        //   ...daysAfterBookedDate,
        // ]);
        // setDatesToDisable([
        //   ...datesToBlockRef.current,
           
        // ]);
      }


    }, 
    //[selectedDay, bookedDates]
    [selectedDay]
    )

    

   
    
    // hourly bookings useEffects

   // disable datetimes before selected 
   useEffect(() => {
    //debugger
    if (datetime.date === undefined || item.bookingDetail.durationType !== DurationCategory.HOUR) {return }
    const earliest = earliestDatetime()
    const latest = latestDatetime()

    if (earliest === undefined || latest === undefined) {return}
    /*
    const hoursBeforeSelectedDatetime = eachHourOfInterval({
     start: earliest,
     end:  sub(datetime.date, {
          hours: 1
     })
 })*/
    
    if (datetime.selectedMode === SELECTEDMODE.SELECTED) {
     // let datetimes: Date[] =  [...hoursBeforeSelectedDatetime, ...disabledDatetimes].sort(compareAsc)
      let datetimes: Date[] =  [...disabledDatetimes].sort(compareAsc)

      const maxObj = maxHourDuration(datetimes, datetime.date)
       if (maxObj.date) {
         /*
          const datetimeInFront = add(maxObj.date, {
              hours: 1
          })
          const disabledDatetimesAhead = eachHourOfInterval({
              start: datetimeInFront, 
              end: latest
          })*/
         // datetimes = [...datetimes, ...disabledDatetimesAhead].sort(compareAsc)
          datetimes = [...datetimes, ...disabledDatetimes].sort(compareAsc)

      }
     // console.dir(maxObj.hoursAvailable)
      setMaxDuration(maxObj.hoursAvailable)
      setDisabledDatetimes(datetimes)       
    } else {
     setDisabledDatetimes([...hoursBeforeOpenedHour(), ...bookedDatetimesRef.current, ...hoursAfterClosedHour()])       

    }

    let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration: 1, startDate: datetime.date}}
    dispatch({type: CartActionType.UPDATE, payload: temp})
    return () => {
        
    }
}, [datetime.date, maxDuration])
    
    // useEffect(()=>{
        
    //     if (datetime.date && datetime.selectedMode === )
    //     const max = maxHourDuration(disabledDatetimes, datetime.date)
    // }, [disabledDatetimes, datetime.selectedMode])
  

    return (
        <>
          {
            isLoadingBookings ? (
              <WHSpinner pt="64px" />
            ): error ? (
            //  <Box w="full" h={"302px"}>
                 <OfflineView
                                       pt="32px"
                                      msg={"Couldn't load times"}
                                      onRetry={loadBookedDates}
                                    />
            //  </Box>
             
            ): (
              <Formik
              initialValues={form} 
              initialStatus={{isValidating: false}}
              validationSchema={yupBookingValidation(400)}
              //validateOnChange={false}
              //validateOnBlur={false}
              enableReinitialize={true}
              
              onSubmit={(values, {setSubmitting, setStatus}) => {
                 //  console.log("is submitting")
                 //  console.dir(values)
                 //   setTimeout(()=>{                    
                 //  }, 2000)
                
                  //setStatus({isSubmitting: true})
             }}
              >
                 {(props)=> {
                     return (
                         <Form>
                             <style>{bookedDayStyle}</style>
                             <VStack alignItems="flex-start" w="full">
                             <HStack w="full">
                                <Text className="h6" fontSize="16px !important">Booking Details</Text>
                                 <Spacer />
                                 <Button 
                                   onClick={()=>{
                                     //console.log("resetting")
                                     onResetForm()
                                   }}
                                   variant="outline" 
                                   colorScheme="primary">Reset Dates</Button>
                             </HStack>
                             <Stack
                              w="full"
                              direction={{ base: "column", md: "row" }}
                            >
                                <Field name="date">
                                {({ field, form }: any) => (
                                  <FormControl 
                                  isInvalid={form.errors.date && form.touched.date}
                                  isRequired>
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                      htmlFor="date"
                                      marginRight="0px"
                                    >
                                      When are you coming?
                                    </FormLabel>
                                    <Popover>
                                       <PopoverTrigger>
                                         <Input
                                             {...field}
                                             value={selectedDay.date ? format((selectedDay.date as unknown as Date), "PP") : ""}
                                             //value={selectedDay ?? ""}
                                            
                                             name="date"
                                             bg="white" color="black" 
                                             size="md" fontSize="md" 
                                            // type="date"
                                            // onChange={props.handleChange}
                                          // {...input.fieldProps}
                                           placeholder="CHECK-IN DATE"
                                         />
                                       </PopoverTrigger>
                                       <PopoverContent>
                                         <DayPicker 
                                             modifiers={modifiers}
                                             modifiersStyles={modifiersStyles}
                                             onFocus={(e: any)=>{
                                              // setSelectedDay(item.bookingDetail.startDate)
                                              // console.dir(e)
                                             }}
                                             //  onDayFocus={(e: any)=>{
 
                                             //  }}
                                         
                                              fromMonth={new Date()}
                                              //initialMonth={new Date("2025-11-06")}
                                              initialMonth={selectedDay.date}
                                              onDayClick={onDaySelect}
                                              selectedDays={selectedDay.date}
                                              disabledDays={[...datesToDisable, {daysOfWeek: closedDays()}, range]}
                                              //disabledDays={datesToDisable}
                                             // fromMonth={new Date()}
                                               //disabledDays={datesToBlock(bookedDatesDefaultValue)}
                                              //disabledDays={[{daysOfWeek: disabledDays()}, range]}
                                              //fromMonth={selectedDay === undefined ? new Date() : selectedDay}
 
                                             />
                                       </PopoverContent>
                                     </Popover>
                                    
                                     <FormErrorMessage>{form.errors.date}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                                 <Field name="durationCategory">
                                {({ field, form }: any) => (
                                  <FormControl 
                                  isInvalid={form.errors.durationCategory && form.touched.durationCategory}
                                  isRequired>
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                      htmlFor="person"
                                    >
                                      Duration Type
                                    </FormLabel>
                                    <Select
                                      {...field}
                                      rounded="lg"
                                      name="durationCategory"
                                      disabled={selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED}
                                      // value={value}
                                      onChange={(e)=>{
                                        props.handleChange(e)
                                        if (e.currentTarget.value === '') {return}
 
                                        const selectedCategory = e.currentTarget.value as DurationCategory
                                        if (selectedDay.selectedMode === SELECTEDMODE.SELECTED &&
                                         intervalAvailableForBookingRef.current && durationRef.current
                                         ) {
                                            updateMaxDuration(intervalAvailableForBookingRef.current, durationRef.current, numClosedDaysRef.current, selectedCategory)
                                         } else {
                                           setMaxDuration(0)
                                         }
                                        
                                        
                                        let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, durationType: e.currentTarget.value as DurationCategory, duration: 1}}
                                        dispatch({type: CartActionType.UPDATE, payload: temp})
                                         
                                      }}
 
                                      placeholder="Choose a duration type"
                                      size="md"
                                    >
                                      {durationTypeItemViews}
                                    </Select>
                                    <FormErrorMessage>{form.errors.durationCategory}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                             
                            </Stack>
                            {
                                 selectedDay.date && 
                                 selectedDay.selectedMode === SELECTEDMODE.SELECTED && 
                                 item.bookingDetail.durationType === DurationCategory.HOUR ? (
                                     <WHTimepicker 
                                     onTimeSelected={onDatetimeSelected}
                                     
                                     bookedDates={disabledDatetimes}
                                     date={selectedDay.date}
                                     openedHours={openedHours()}
                                 />
                                 ): null
                             }
                            <Stack
                              w="full"
                              direction={{ base: "column", md: "row" }}
                            >
                              <Field name="duration">
                                {({ field, form }: any) => (
                                  <FormControl 
                                  isInvalid={form.errors.duration && form.touched.duration}
                                  isRequired
                                  >
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                      htmlFor="person"
                                    >
                                      How many {getDurationLabel(props.values.durationCategory)} would you stay in this office?
                                    </FormLabel>
                                    <NumberInput
                                       {...field}
                                      // disabled={selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED || (item.bookingDetail.durationType === DurationCategory.HOUR && datetime.selectedMode === SELECTEDMODE.HIGHLIGHTED)}
                                       disabled={selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED || (item.bookingDetail.durationType === DurationCategory.HOUR && datetime.date  === undefined)}

  
                                       //defaultValue={15}
                                      // value={durationInOffice.toString()}
                                       max={maxDuration === 0 ? Infinity : maxDuration}
                                       keepWithinRange={true}
                                       clampValueOnBlur={true}
                                       onChange={(e)=> {
                                        
                                         props.handleChange(e)
                                          
                                          if (e === "") {
                                             props.setFieldValue("duration", "")
                                         }
 
                                         if (!isNumericInput(e)) return
                                         if (isNumericInput(e) &&  Number(e) < 1) { return }
                                        // if (!selectedDay) return 
                                         
                                         let duration = Number(e)
 
                                         if (maxDuration > 0 && duration > maxDuration ) {
                                           duration = maxDuration
                                         }
                                         props.setFieldValue("duration", duration)
                                          //let endDate = getEnddate(item.bookingDetail.durationType, selectedDay, duration)
                                         let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration}}
                                         dispatch({type: CartActionType.UPDATE, payload: temp})
                                         
                                        }}
                                     >
                                       <NumberInputField />
                                       <NumberInputStepper>
                                         <NumberIncrementStepper />
                                         <NumberDecrementStepper />
                                       </NumberInputStepper>
                                     </NumberInput>
                                     
                                    <FormErrorMessage>{form.errors.duration}</FormErrorMessage>
                                      {/* {dayItemViews}
                                    </Select> */}
                                  </FormControl>
                                )}
                              </Field>
                               
                                 {/* <Field name="guests">
                                {({ field, form }: any) => (
                                  <FormControl 
                                  isInvalid={form.errors.guests && form.touched.guests}
                                  isRequired
                                    >
                                    <FormLabel
                                      fontWeight={500}
                                      fontSize={"15px"}
                                      htmlFor="person"
                                    >
                                      No of Guests<span>&nbsp;(should be less than {item.listing?.capacity})</span>
                                    </FormLabel>
                                    <Input
                                      min="1"
                                      max={item.listing?.capacity ?? ""}
                                      {...field}
                                      rounded="lg"
                                      name="guests"
                                      //value="1"
                                      type="number" 
                                     
                                      
                                      onChange={ (e)=>{
                                        props.handleChange(e)
 
                                        
                                       if (!isNumericInput(e.currentTarget.value)) return
 
                                       let guests = Number(e.currentTarget.value)
                                       if (!item.listing || guests > item.listing.capacity) {
                                             props.setFieldValue("guests", 5)
                                             setErrorContext({title: "", msg: `Your number of guests cannot exceed ${item.listing?.capacity}`})
                                             return
                                       }
                                       
                                       let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, guests: Number(e.currentTarget.value)}}
                                       dispatch({type: CartActionType.UPDATE, payload: temp})
                                       
                                       
                                      }}
                                       size="md"
                                    />
                                    <FormErrorMessage>{form.errors.guests}</FormErrorMessage>
                                      
                                  </FormControl>
                                )}
                              </Field> */}
                             </Stack>
                             
                            
                             </VStack>
                             
                         </Form>
                     )
                 }}
              </Formik>
            )
          }
         
        </>
    )
}

export default WHBookingFormView
 


import { Box, SimpleGrid } from '@chakra-ui/react'
import React, {FC, ReactNode} from 'react'
interface LearnMoreItemViewProps {
  children?: ReactNode[]
}

const LearnMoreView: FC<LearnMoreItemViewProps> = ({children}: LearnMoreItemViewProps) => {

   

    return (
      <Box as="section" mb={{md: "24px !important"}}>
        <SimpleGrid spacing={{base: "20px", md: "5%", lg: "5%"}} columns={{ base: 1, md: 2, lg: 3 }}>
          {children}
        </SimpleGrid>
      </Box>
    );
}

export default LearnMoreView




import { AspectRatio, HStack, VStack, Image, Text } from "@chakra-ui/react"
import { BreakPoint } from "../OfficeDetail/Models/Interfaces"
import styles from "./WHHome.module.css"

interface ReasonViewProps {
    title: string 
    image: string 
    description: string
    breakpoint: BreakPoint
}

const WHReason = ({title, image, description, breakpoint}: ReasonViewProps)=> {
     
    let titleFontsize = "18px"
    let descFontsize = "14px"
    let imgSize = "20%"

    let padding = 4

    if (breakpoint === BreakPoint.M) {
        padding = 8
    }
    if (breakpoint === BreakPoint.L || breakpoint === BreakPoint.XL) {
        titleFontsize = "24px"
        descFontsize = "16px"
        padding = 8
    }

    if (breakpoint === BreakPoint.L
        || breakpoint === BreakPoint.M || breakpoint === BreakPoint.XL) {
            imgSize="10%"
        }

    let spacing = padding
   // console.log(breakpoint)
    return (
        <HStack 
       // h="208px"
        spacing={spacing}
        //justifyContent="space-between" 
        rounded="xl"
        p={padding} shadow="lg" w="full" 
        h="full"
        >
            <AspectRatio w={breakpoint === BreakPoint.XS ? "10%" : "11.5%"} ratio={1 / 1} >
            <Image 
                   // w="18%"
                   // h="46%"
                     //src={whyImage}
                    src={image} 
                    objectFit="cover"
                     />
            </AspectRatio>
           
             
            <VStack alignItems="flex-start">
                <Text 
                   // className="h6" 
                     //fontFamily="Inter"
                     className={styles.title}
                     //  style={{
                    //      fontFamily: "Inter"
                    //  }}
                    fontSize={`${titleFontsize} !important`} 
                textTransform="capitalize"
                fontWeight="500 !important">{title}</Text>
                <Text 
                  // className={styles.subtitle}
                      className={styles.title}

                        //fontFamily="Inter" 
                         fontWeight="400 !important"
                      fontSize={`${descFontsize} !important`}
                      >
                          {description}</Text>
            </VStack>
        </HStack>
    )
}

export default WHReason
import { DayValue } from "react-modern-calendar-datepicker";
import { AmenityDetails, BookingDuration, CompartmentCategory, DurationCategory,  PublicListing, UserReview } from "../../../../../Models/Interfaces";
 
export enum BreakPoint {
    XS = "xsmall",
    S = "small",
    M = "medium",
    L = "large",
    XL = "xlarge"
}

export interface OfficePhotoItem {
    imageURL: string
    id: string
}

export enum ModalCategory {
    PAR = "post a review",
    AMENITIES = "amenities",
    REVIEWS = "reviews",
    CARD = "add a card",
    ENQUIRY = "make an enquiry",
    CLAIMLISTING = "claim listing"
}

export interface OfficeCarouselProps {
    items: OfficePhotoItem[]
}

export interface AmenityItem {
    categoryId: string 
    name: string 
    title: string 
    id: string
}

export interface ReviewItem {
    isRated: boolean
    title: string 
    desc: string
    rating: number
}

export interface EnquiryItem {
    fname: string 
    lname: string 
    cname: string
    cemail: string
    phoneNo: string
    persons: string
    spaceType: CompartmentCategory
    duration: string
    durationType: DurationCategory
    date: DayValue
    desc: string

}

export interface CardDetailsItem {
    cardNo: string
    expiration: string
    cvv: string
    address: string
    suiteNo: string
    city: string 
    state: string
    code: string
}

export interface DetailFormProps {
    handleModalOpen: (category: ModalCategory) => void
}
export interface ReviewsViewProps {
    handleModalOpen: (category: ModalCategory) => void
    reviews: UserReview[]
    avgRating?: number 
}

export interface AmenitiesViewProps  {
    handleModalOpen: (category: ModalCategory) => void
    amenities: AmenityDetails[]

}

export interface PrimaryActionsViewProps extends DetailFormProps {
    duration: BookingDuration[]
    listing: PublicListing
}

export interface ListingDetailFormProps {
    listing: PublicListing
    onSubmitEnded: ()=>void
    userReview?: UserReview | undefined
  }


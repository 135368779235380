import { add, children } from 'dom7'
import React, { Dispatch, ReactNode, SetStateAction, useReducer, useState } from 'react'
import { Listing, ListResponseData, Workspace } from '../../../../Models/Interfaces'
import { AdminStateActions } from '../Models/Interfaces'



interface ManageWorkspacesProviderProps {
    children: ReactNode
}

interface ListingsState {
    selectedWorkspace: string 
    listings: Listing[]
    selectedListingId: string | null
    //responseData: ListResponseData<Listing> | undefined
    responseData: ListResponseData<Listing> 

}

interface WorkspacePayload<T> {
    id: string, 
    listings: Listing[]
    data: ListResponseData<T>
}

interface ListingsAction {
    type: AdminStateActions
    payload: Listing | WorkspacePayload<Listing> | Listing[] | string
}

const listingsState: ListingsState = {
    selectedWorkspace: "",
    listings: [],
    selectedListingId: null,
    responseData: {
        next: undefined,
        previous: undefined,
        results: [],
        count: 0

    }

}

const listingsStateReducer = (state: ListingsState, action: ListingsAction): ListingsState => {

    const {type, payload} = action
 
    switch (type) {
        case AdminStateActions.CHANGEWORKSPACE: 
            let workspace = payload as WorkspacePayload<Listing>
            if (workspace.id) {
               // return {...state, selectedWorkspace: workspace.id, listings: (payload as WorkspacePayload).listings}
                return {...state, selectedWorkspace: workspace.id, listings: workspace.listings, 
                            responseData: {...state.responseData, count: workspace.data.count, next: workspace.data.next, results: workspace.data.results}}

            } else {
                return state
            }
        case AdminStateActions.ADDLISTING:
             let addedListing = payload as Listing[]
            
             if (!state.responseData) return state
            //return {...state, listings: [...state.listings, ...addedListing]}
            return {...state,  
                responseData: {...state.responseData, 
                                count: state.responseData.count ?? 0, 
                                next: state.responseData.next,
                    results: [...addedListing, ...state.responseData.results]}
                }

            case AdminStateActions.ADDLISTINGS: 

            let updatedWorkspace = payload as WorkspacePayload<Listing>
            if (updatedWorkspace.id) {
               // return {...state, selectedWorkspace: workspace.id, listings: (payload as WorkspacePayload).listings}
                return {...state,  
                            responseData: {...state.responseData, count: updatedWorkspace.data.count, next: updatedWorkspace.data.next, 
                                results: [...state.responseData!.results, ...updatedWorkspace.data.results]}}

            } else {
                return state
            }

        case AdminStateActions.REPLACELISTINGS:
            let newListings = payload as Listing[]
            return {...state, listings: newListings}
        case AdminStateActions.SELECTLISTING: 
            let id = payload as string
            return {...state, selectedListingId: id}
            
         case AdminStateActions.REMOVELISTING: 
            let removeId = payload as string
            if (!state.responseData) return state
            let filteredListings = state.responseData.results.filter(item => item.id !== removeId)
            return {...state,  
                responseData: {...state.responseData, 
                                count: state.responseData.count, 
                                next: state.responseData.next,
                    results: filteredListings}}
                    
         case AdminStateActions.UPDATELISTING: 
         
            let listing = payload as Listing
            if (!state.responseData) return state

            let idx = state.responseData.results.findIndex(item => item.id === listing.id)
            if (idx < 0) return state 

           
              
                let updatedListings = [...state.responseData.results.slice(0, idx), listing, ...state.responseData.results.slice(idx+1)]
                //return {...state, listings: updatedListings}
                return {...state,  
                    responseData: {...state.responseData, 
                                    count: state.responseData?.count ?? 0, 
                                    next: state.responseData?.next,
                        results: updatedListings}}

           
        default:
            return state
    }
}

export const WorkspaceContext = React.createContext<Workspace | null>(null)
export const SetWorkspaceContext = React.createContext<Dispatch<SetStateAction<Workspace | null>>>(()=>{})
export const ListingsStateContext = React.createContext<{state: ListingsState, 
                                                            dispatch: React.Dispatch<any>}>({state: listingsState, dispatch: ()=>null})


const ManageWorkspacesProvider = ({children}: ManageWorkspacesProviderProps) => {

    const [workspace, setWorkspace] = useState<Workspace | null>({listing_count: 0})
    const [state, dispatch] = useReducer(listingsStateReducer, listingsState)

    return (
        <WorkspaceContext.Provider value={workspace}>
            <SetWorkspaceContext.Provider value={setWorkspace}>
                <ListingsStateContext.Provider value={{state, dispatch}}>
                 {children}
                </ListingsStateContext.Provider>
            </SetWorkspaceContext.Provider>
        </WorkspaceContext.Provider>
    )
}

export default ManageWorkspacesProvider

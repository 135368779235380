import { Box, Divider, useMediaQuery, VStack, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react'
import { AiFillClockCircle } from 'react-icons/ai';
import { MdContacts, MdDescription, MdLocationOn, MdNetworkWifi } from 'react-icons/md';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DashboardSectionName } from '../Models/Interfaces';
import { dashboardManager } from '../Models/WHDashboardDataManager';
import { SetWorkspaceContext, WorkspaceContext } from '../WHManageWorkspaces/ManageWorkspacesProvider';
import { ManageOfficesButton, NavlistHeader, SetupButton } from './WHDesktopNavlist';

interface WHDesktopRailSidebarProps {
    url: string
    
}

const WHDesktopRailSidebar = ({url}: WHDesktopRailSidebarProps) => {
    const workspace = useContext(WorkspaceContext);
    const setWorkspace = useContext(SetWorkspaceContext) 
    const {url: pageURL} = useRouteMatch()
    const [selectedNavItem, setSelectedNavItem] = useState<DashboardSectionName>(DashboardSectionName.Desc)

    const [isMedium] = useMediaQuery(["(max-width: 786px)"])

   
      const history = useHistory()
  
      useEffect(() => {
         let id = localStorage.getItem("title");
    
        if (id) {
          let workspace = dashboardManager.getWorkspace(id);
    
          if (workspace) {
              setWorkspace(workspace)
          }
        }
        
      }, []);
  
      const setupSteps = dashboardManager.allSections.map((item, idx) => {
          
        
          
         return item.name !== DashboardSectionName.Size ? (
                          <SetupButton
                          onNavItemSelected={(selectedItem)=>setSelectedNavItem(selectedItem) }
                          selectedNavItem={selectedNavItem}
                          key={item.name}
                          label={t(item.title)}
                          sectionName={item.name}
                          workspace={workspace}
                          url={`${pageURL}/${item.name}`}
                          onClick={() => {
                              history.push(`${pageURL}/${item.name}`)
                          }}
                        /> ) : null
      
           
          });
  
      return (
          
               <VStack
              position="fixed"
              left="0px"
              top={{ base: "64px", md: "64px", lg: "72px" }}
  
               h="full"
              w={isMedium ? "72px" : "25%"}
             paddingLeft={isMedium ? "unset" : 8}
              bg="#fafafa"
              paddingY={8}
              spacing={4}
            >
               <Box w="100%">
                   <Text className="h6">{workspace?.title ?? ""}</Text>
                   {/* <Divider /> */}
                </Box>
  
                          <VStack
               // paddingLeft={6}
                spacing="0px"
                // h="full"
                w="full"
                //paddingX={6}
                alignItems="flex-start"
              >
               
                <NavlistHeader 
                 isMedium={isMedium}
                label={t("Detail")}/>
  
                {setupSteps}
              </VStack>
  
              <VStack
                spacing="0px"
                // h="full"
                width="full"
                //paddingX={6}
                alignItems="flex-start"
              >
                <NavlistHeader                  isMedium={isMedium }
 label={t("Office Management")}/>
                <ManageOfficesButton
                   onNavItemSelected={(selectedItem)=>setSelectedNavItem(selectedItem) }
                   selectedNavItem={selectedNavItem}
                  workspace={workspace}
                  url={`${url}/${DashboardSectionName.Offices}`}
                  onClick={() => {
                    history.push(`${url}/${DashboardSectionName.Offices}`);
                  }}
                  sectionName={DashboardSectionName.Offices}
                  label={t("Manage Offices")}
                />
  
                
              </VStack>
              <Divider w="full" color="black" />
            </VStack>
          
      )
}

export default WHDesktopRailSidebar

import { Button, HStack, Link, Spacer, Stack } from '@chakra-ui/react'
import  { Dispatch, SetStateAction, useEffect, useState, memo, useCallback, useContext } from 'react'
import { NavLink as ReactLink,  Redirect,  Route,  Switch,  useHistory, useLocation, useRouteMatch  } from "react-router-dom";
import AboutSpace from '../../WHDashboard/AboutSpace/AboutSpace';
import { dataManager, OnBoardSectionId, URLDir } from './Models/ListSpaceDataManager';
//import FloorPlan from './FloorPlan/FloorPlan';
 import { useRef } from 'react';
import { FormikProps } from 'formik';
import Account from './Account/Account';
import { AccountForm, BizAcctForm } from './Models/Interfaces';
import ContactDetails from '../../WHDashboard/ContactDetails/ContactDetails';
import Intro from './Intro/Intro';
import BizAccount from './BizAccount/BizAccount';
import { UserContext } from '../../../AppProvider/AppProvider';
import { signUpDataManager } from '../SignUp/SignUpDataManager';
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO';
import { t } from 'i18next';

const AddASpace = (props: any) => {
    
   
    let accountFormRef = useRef<FormikProps<AccountForm>>(null)
    let bizAcctFormRef = useRef<FormikProps<BizAcctForm>>(null)
    
    let history = useHistory()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [acctFormValues, setAcctFormValues] = useState({ fname: "", lname: "", email: "", password: "", country: "", phone: ""})
    const [bizAcctFormValues, setBizAcctFormValues] = useState({ bemail: "", bname: "", websiteURL: "", fbURL: "", instagramURL: "", twitterURL: "", terms: false })
    
    const [isValid, setIsValid] = useState(false)
    const [isBizAccountValid, setBizAccountValid] = useState(false)
    const user = useContext(UserContext)
    
    const location = useLocation()
     let index = location.pathname.lastIndexOf('/')
    let path = location.pathname.substr(index)
     
    const [currentSectionId, setCurrentSectionId] = useState(OnBoardSectionId.Intro)
    
      
    const updateFormStatus = (value: boolean)=> {
        //setIsSubmitting(value)
        setIsValid(value)
    }

    const setContinuBtn = (value: boolean) => {
      setIsSubmitting(value)
    }

    useEffect(() => {
     // debugger
      let nextSectionURL = ""
      if (path === "/biz+account" && user) {
        nextSectionURL = dataManager.getNextSectionURL(path, user.id)
        history.push(nextSectionURL)

      }
     
      
      // return () => {
      //   cleanup
      // }
    }, [user, location])

    
    const handleAfterSubmit = useCallback(()=>{

     // if (!user || !user.id) return 
        
      //   let bizId =  undefined
         
      //  let user = signUpDataManager.getRegisteredUserData()
      //  if (user) {
      //    bizId = user.id
      //  }
         let nextSectionURL = "/intro"
         if (path === "/account") {
           nextSectionURL = dataManager.getNextSectionURL(path)
           history.push(nextSectionURL)


         } 
        //  else if (path === "/biz+account" && user) {
        //    nextSectionURL = dataManager.getNextSectionURL(path, user.id)
        //  }
        

       
     }, [location])



    const nextButton = ()=> {
      
      return path === "/biz+account" ? (
        //return currentSectionId === OnBoardSectionId.BizAccount ? (
          <Button
            w={{ base: "25%", lg: "20%" }}
            onClick={() => {
                if (bizAcctFormRef.current) {
                    setBizAcctFormValues(bizAcctFormRef.current.values)
                    bizAcctFormRef.current.handleSubmit()
                  
                }
            }}
            width="full"
            className="button"
            colorScheme="green"
           
            isLoading={isSubmitting}
          >
            {t('continue')}
          </Button>
        ) : (
          <Button
            w={{ base: "25%", lg: "20%" }}
            onClick={() => {
               
               if (
                path === "/account" &&
                accountFormRef.current
              ) {
               
               // console.dir(accountFormRef.current.isValid)
               setAcctFormValues(accountFormRef.current.values)
                accountFormRef.current.handleSubmit();
              
                
              }
            }}
            width="full"
            className="button"
            colorScheme="primary"
           
            variant="solid"
            isLoading={isSubmitting}
          >
            {t('next')}
          </Button>
        );
    }

    useEffect(() => {
         dataManager.setSelectedSectionId(currentSectionId)
        
     }, [currentSectionId])

 
    return (
      // <Portal>
      <>
        <WHSEO
          title={`Add A Space`}
          meta={[
            {
              name: "description",
              content: `Add A Space`,
            },
            {
              name: "keywords",
              content: `workspace, add a space`,
            },
          ]}
        />
        <Stack
          spacing="0px"
          // bg="blue.300"
          position="absolute"
          w="100vw"
          h="100vh"
          zIndex="108"
          top={0}
          left={0}
          direction={{ base: "column", md: "row", lg: "row" }}
        >
          <Switch>
            <Route
              // exact
              path={dataManager.getSectionPath(OnBoardSectionId.Intro)}
              render={() => (
                <Intro
                  onLoad={() => {
                    setCurrentSectionId(OnBoardSectionId.Intro);
                  }}
                  actionAfterSubmit={handleAfterSubmit}
                  dataManager={dataManager}
                  updateFormSubmissionStatus={updateFormStatus}
                />
              )}
            />
            <Route
              // exact
              path={`${dataManager.getSectionPath(OnBoardSectionId.Account)}`}
              render={() => (
                <Account
                  formValues={acctFormValues}
                  onChange={(value) => {
                    setIsValid(value);
                  }}
                  onLoad={() => {
                    setCurrentSectionId(OnBoardSectionId.Account);
                  }}
                  actionAfterSubmit={(formValues: AccountForm) => {
                    //setAcctFormValues(formValues)

                    handleAfterSubmit();
                  }}
                  dataManager={dataManager}
                  updateFormSubmissionStatus={updateFormStatus}
                  formRef={accountFormRef}
                />
              )}
            />
            <Route
              // exact
              // path="/account"
              path={`${dataManager.getSectionPath(
                OnBoardSectionId.BizAccount
              )}`}
              render={() => (
                <BizAccount
                  formValues={bizAcctFormValues}
                  onLoad={() => {
                    setCurrentSectionId(OnBoardSectionId.BizAccount);
                  }}
                  validatePersonalAcct
                  //onLoad={()=>{}}
                  //actionAfterSubmit={handleAfterSubmit}
                  actionAfterSubmit={() => {}}
                  dataManager={dataManager}
                  updateFormSubmissionStatus={setContinuBtn}
                  formRef={bizAcctFormRef}
                />
              )}
            />
            <Route path="*">
              <Redirect
                to={dataManager.getSectionURL(
                  OnBoardSectionId.Intro,
                  URLDir.curr
                )}
              />
            </Route>
          </Switch>
          <HStack
            bg="white"
            px={6}
            w={{ base: "full", md: "50%" }}
            h={{ base: "64px", lg: "80px" }}
            justifyContent={{ lg: "center" }}
            borderTop="2px solid black"
            position="absolute"
            bottom="0px"
            right="0px"
          >
            {/* {currentSectionId === OnBoardSectionId.Intro ?  */}
            {path === "/intro" ? (
              <>
                <Link
                  w={{ base: "25%", lg: "20%" }}
                  // w="full"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={"/"}
                >
                  <Button
                    w="full"
                    className="button"
                    colorScheme="primary"
                    variant="outline"
                    //onClick={() => {}}
                  >
                    {t('exit')}
                  </Button>
                </Link>
                <Spacer />
                <Link
                  w={{ base: "25%", lg: "20%" }}
                  // w="full"
                  textDecoration="none !important"
                  as={ReactLink}
                  to={dataManager.getSectionPath(OnBoardSectionId.Account)}
                >
                  <Button
                    w="full"
                    className="button"
                    colorScheme="secondary"
                    //onClick={() => {}}
                  >
                    {t('letsgo')}
                  </Button>
                </Link>
              </>
            ) : (
              <>
                {/* <Link 
                 w={{ base: "25%", lg: "20%" }}
                textDecoration="none !important" 
                as={ReactLink} 
                to={dataManager.getPrevSectionURL(path)}
              > */}
                <Button
                  w={{ base: "25%", lg: "20%" }}
                  onClick={() => {
                    if (path === "/biz+account" && bizAcctFormRef.current) {
                      // console.dir(accountFormRef.current.isValid)

                      setBizAcctFormValues(bizAcctFormRef.current.values);
                    } else if (path === "/account" && accountFormRef.current) {
                      setAcctFormValues(accountFormRef.current.values);
                    }
                    history.push(dataManager.getPrevSectionURL(path));
                  }}
                  // w={"full"}
                  className="button"
                  colorScheme="primary"
                  // color="white"
                  variant="outline"
                >
                  {t('back')}
                </Button>
                {/* </Link> */}
                <Spacer />
                {nextButton()}
              </>
            )}
          </HStack>
        </Stack>
      </>
      //   </Portal>
    );
}

export default AddASpace

 
import { FormControl, FormLabel, Text, GridItem, Icon, Input, InputGroup, InputLeftElement, Select, Stack, VStack, PopoverTrigger, Popover, PopoverContent, Button, FormErrorMessage, Spacer, HStack, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { MdDateRange } from 'react-icons/md'
import DatePicker, { DayValue, RenderInputProps } from 'react-modern-calendar-datepicker'
import { BookedDates, BookingDuration, CartActionType, CartItem, DurationCategory, DurationTime as DurationInterval, ResultType } from '../../../../Models/Interfaces'
import AddASpaceLayout from '../../../SupportingViews/AddASpaceLayout/AddASpaceLayout'

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import styles from '../../../SupportingViews/SearchMenu/SearchMenu.module.css'

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import {format, add, eachDayOfInterval, sub, intervalToDuration, lastDayOfYear, isBefore, isAfter, compareAsc, isDate, isExists, isValid, differenceInHours, differenceInDays, differenceInMonths, differenceInYears, differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarYears, isSaturday, isSunday, addBusinessDays} from 'date-fns'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import dateStyles from './ConfirmPay.module.css'
import { yupBookingValidation } from './Models/Schema/BookingValidation'
import { addOfficeBusinessDays, getDurationCategory, getDurationTitle, getLeastPrice, getNumClosedDaysOfInterval } from '../../../../utils/utils'
import { CartContext, SetErrorContext, UserContext } from '../../../AppProvider/AppProvider'
import { getDurationLabel } from './ConfirmPay'
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager'
import { bookedDatesDefaultValue } from '../../../../Models/DefaultValues'
 
function getDate(selectedDay: DayValue): string {
    if (selectedDay) {
        return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`
    }
    return ""
}

const renderCustomInput = ({ ref }: RenderInputProps) => (
    <InputGroup  w="full" size="lg">
      <InputLeftElement
      h="48px"
      color="gray.300"
      pointerEvents="none"
      children={ <Icon as={MdDateRange} w={6} h={6} />}
      />
      <Input inputclassname="body1" 
            name="date"
            bg="white" color="black" 
            size="lg" fontSize="md" 
            ref={ref as RefObject<HTMLInputElement>} 
            //value={getDate(day)} 
            type="date" />
    </InputGroup>
  )

  interface BookingFormItem {
    date: string
    duration: number 
    guests: number
    durationCategory: DurationCategory
}





interface BookingFormViewProps {
  item: CartItem
}

const isNumericInput = (inputValue: string): boolean => {

  if (inputValue === '') return false

  const guests = Number(inputValue)

  if (isNaN(guests)) return false

  return true
}

export const getEnddate = (durationType: DurationCategory, selectedDay: Date, duration: number): Date => {
 
  let endDate = new Date()
  if (durationType.toLowerCase().includes("da")) {
   endDate = add(selectedDay, {days: duration})
  } else if (durationType === DurationCategory.HOUR) {
    endDate = add(selectedDay, {hours: duration})
  } else if (durationType === DurationCategory.MONTH) {
    endDate = add(selectedDay, {months: duration})
  } else {
    endDate = add(selectedDay, {years: duration})
  }

  return endDate
}

const datesToBlock = (bookedDates: BookedDates): Date[] =>  {

  let durationIntervalList: {start_date: Date, end_date: Date}[] = []
   let dates: Date[] = []
  // it should return a list of arrival date time pairs(duration interals)
  Object.entries(bookedDates).forEach(([key, durationTimeItems], idx)=> {
    for (let item of durationTimeItems) {
      let temp = {start_date: new Date(item.start_date), end_date: new Date(item.end_date)}
        let result = eachDayOfInterval({
          start: temp.start_date, 
          end: temp.end_date
        })
        
        dates = dates.concat(result).sort(compareAsc)
      //durationIntervalList.push(temp)
    }
  })
   
  return dates
  //console.dir(dates)

  // let result = eachDayOfInterval({
  //   start: arrivalTime, 
  //   end: departureTime
  // })

  //return [new Date(), new Date(), {before: new Date()}]
}

// const getMaxDuration = (bookingDurationItems: BookingDuration[], selectedDuration: DurationCategory) {

// }

const isBookingPossibleForDurationCategory = (durationCategory: DurationCategory, duration: Duration | undefined): boolean => {

  //if (bookingDurationItems.length === 0) { return false}

  if (!duration) { return true}

  const categoryTitle = getDurationTitle(durationCategory)

  if (duration.hours !== undefined && categoryTitle.includes("hour")) {
      if (duration.hours > 0 || duration.days && duration.days > 0) {
        return true
      }
  }

  if (duration.days  !== undefined && categoryTitle.includes("day")) {
    if (duration.days > 0 || duration.months && duration.months > 0) {
      return true
    }
}

if (duration.months  !== undefined && categoryTitle.includes("month")) {
  if (duration.months > 0 || duration.years && duration.years > 0) {
    return true
  }
}

if (duration.years !== undefined && duration.years > 0 && categoryTitle.includes("year")) {
  return true
}

   return false

  // if ( duration.hours && duration.hours > 0 || (duration.days && duration.days > 0)  && categoryTitle.includes("hour")) {
  //     return true
  // }



  // if (duration.days && duration.days > 0 && categoryTitle.includes("day")) {
  //   return true
  // }   

// if (duration.months && duration.months > 0 && categoryTitle.includes("month")) {
//   return true
// }



   
}


// selected mode for seleced day
enum SELECTEDMODE {

  HIGHLIGHTED = "highlighted",
  SELECTED = "selected"

}

interface SelectedDay {
  date: Date | undefined
  selectedMode: SELECTEDMODE
}



const BookingFormView = ({item}: BookingFormViewProps) => { 
  
  //const [selectedDay, setSelectedDay] = useState<Date | undefined>(isValid(item.bookingDetail.startDate) ? item.bookingDetail.startDate : undefined)
  const  [selectedDay, setSelectedDay] = useState<SelectedDay>({
        date: item.bookingDetail.startDate ? item.bookingDetail.startDate : undefined,
        selectedMode: SELECTEDMODE.HIGHLIGHTED
    })

  //const [bookedDates, setBookedDates] = useState<BookedDates>(bookedDatesDefaultValue)
  const [bookedDates, setBookedDates] = useState<BookedDates>()

 //const [datesToDisable, setDatesToDisable] = useState<(Date)[]>([...datesToBlock(bookedDates)])
  const [datesToDisable, setDatesToDisable] = useState<Date[]>([])

  const [maxDuration, setMaxDuration] = useState(0)
  const [bookingDuration, setBookingDuration] = useState<BookingDuration[]>(item.listing?.booking_duration ?? [])
  const [durationInOffice, setDurationInOffice] = useState(0)
  let durationRef = useRef<Duration>()
  let numClosedDaysRef = useRef<number>(0)
  let datesToBlockRef = useRef<Date[]>([])
  let removeDatesBlockedBySelectedRef = useRef(false)
  let intervalAvailableForBookingRef = useRef<{start: Date, end: Date}>()
  // duratin available based on selected date
  //const [availableDuration, setAvailableDuration] = useState<Duration>()
  //{before: new Date()}
   //debugger

    
  let form: BookingFormItem  = {
    date: item.bookingDetail.startDate  ? format(item.bookingDetail.startDate, "PP") : "",
    duration: item.bookingDetail.duration,
    guests: item.bookingDetail.guests,
    durationCategory: getDurationCategory(item.bookingDetail.durationType) 
}

 
const user = useContext(UserContext)

const bookedDayStyle = `.DayPicker-Day--disabled:not(:empty) {
  background-color: red;
  color: white;
  width: 40px;
  height: 40px;
}`

const modifiers = selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED ? {
  highlighted: selectedDay.date!
} : {
  selected: selectedDay.date!
}

// const modifiers =   {
//   highlighted: selectedDay.date!,
//   selected:  add(selectedDay.date!, {
//     days: 1
//   })
// }  

 

/*
const modifiersStyles = {
  highlighted: {
    color: "#fff",
    backgroundColor: "orange",
    width: "40px",
    height: "40px"
  },
  selected: {
    color: "#fff",
    backgroundColor: "green",
    width: "40px",
    height: "40px"
  }
}*/

const modifiersStyles = selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED ? {
  highlighted: {
    color: "#fff",
    backgroundColor: "orange",
    width: "40px",
    height: "40px"
  },
  
} : {
   
  selected: {
    color: "#fff",
    backgroundColor: "green",
    width: "40px",
    height: "40px"
  }
}


const setErrorContext = useContext(SetErrorContext)
 
const closedDays = (): number[] => {
  
  let defaultValues: number[] = [0, 6]
  if (!item.listing) {return defaultValues}
  let extraDays = item.listing.workspace_details.extra_days.days ?? []
  if (extraDays.length === 0) {return defaultValues}
  defaultValues = []
  if (!extraDays.includes("Sun")) {
    defaultValues.push(0)
  }
  if (!extraDays.includes("Sat")) {
    defaultValues.push(6)
  }

  return defaultValues
}




const {dispatch} = useContext(CartContext)

    const personItemViews = [1,2,3,4,5].map((item, idx)=> 
                    <option key={item}>
                    {item}
                    </option>)
        
        
                     
      // const durationTypeItemViews = bookingDuration.map((item,idx)=>(
      //     <option  key={idx}>
      //          {getDurationCategory(item.minimum_duration) as DurationCategory}
      //     </option>
      //   ))
    
    const durationTypeItemViews = item.listing?.booking_duration.map((item,idx)=>(
      <option disabled={!isBookingPossibleForDurationCategory(getDurationCategory(item.minimum_duration), durationRef.current)} key={idx}>
           {getDurationCategory(item.minimum_duration) as DurationCategory}
      </option>
    ))
  

   
    const onDaySelect = useCallback((day, {selected, disabled}) => {
        let mode = SELECTEDMODE.SELECTED
         if (disabled) {
          return
        }
        if (selected) {
          //setSelectedDay(undefined)
          if (selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED) {
             mode = SELECTEDMODE.SELECTED
              //setSelectedDay({...selectedDay, selectedMode: SELECTEDMODE.SELECTED})
          } else {
            mode = SELECTEDMODE.HIGHLIGHTED
              //setSelectedDay({...selectedDay, selectedMode: SELECTEDMODE.HIGHLIGHTED})

          }
         //  return
        }  else {
          // selected a new date so set temp mode to all selecteed useEffect to run
          //setSelectedDay({...selectedDay, date: day, selectedMode: SELECTEDMODE.HIGHLIGHTED})
        }
        
         
        removeDatesBlockedBySelectedRef.current = false
        setSelectedDay({...selectedDay, date: day, selectedMode: mode})
        //setSelectedDay(day)

        let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, startDate: day}}
        dispatch({type: CartActionType.UPDATE, payload: temp})
      },
      [selectedDay],
    )

    const onDayChange = useCallback((day) => {
       setSelectedDay(day)
      },
      [selectedDay],
    )

    const range = { 
      
      //before: new Date(2021, 10, 5)
      before: new Date()

    }

    const loadBookedDates = useCallback(
      async () => {
          if (!user || !user.access_token) return 
          if (!item.listing || !item.listing.id) return
          const result = await WorkhopperAPIManager.shared.getBookedDates(item.listing.id, "all")
          if (result.type === ResultType.Success) {
              console.dir(result)
            // setBookedDates(result.value)
            //  datesToBlockRef.current = datesToBlock(result.value)

        
            // setDatesToDisable(datesToBlockRef.current)
              
          } else {

          }

      },
      [user?.access_token],
    )

    const updateMaxDuration = useCallback(
      (
        intervalAvailableForBooking: { start: Date; end: Date },
        duration: Duration,
        numClosedDays: number,
        durationType: DurationCategory
      ) => {
        const durationItem = {...duration, days: duration.days !== undefined ? duration.days - numClosedDays : duration.days}
        if (
          durationType === DurationCategory.HOUR &&
          isBookingPossibleForDurationCategory(DurationCategory.HOUR, durationItem)
        ) {
          console.dir(duration)
          console.dir(durationItem)
          // console.log( differenceInHours(
          //   intervalAvailableForBooking.end,
          //   intervalAvailableForBooking.start
          // ))
          // console.log( differenceInCalendarDays(
          //   intervalAvailableForBooking.end,
          //   intervalAvailableForBooking.start
          // ))
          setMaxDuration(
            differenceInHours(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            )
          );
          ///setMaxDuration(differenceInHours(interval.end, interval.start));
        } else if (
           durationType === DurationCategory.DAY &&
          isBookingPossibleForDurationCategory(DurationCategory.DAY, durationItem)
        ) {
          setMaxDuration(
            differenceInCalendarDays(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            ) - numClosedDays
          );
        } else if (
           durationType === DurationCategory.MONTH &&
          isBookingPossibleForDurationCategory(DurationCategory.MONTH, durationItem)
        ) {
          // setMaxDuration(duration.months)
          setMaxDuration(
            differenceInCalendarMonths(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            )
          );
          //setMaxDuration(differenceInMonths(interval.end, interval.start));
        } else if (
          durationType === DurationCategory.YEAR &&
          isBookingPossibleForDurationCategory(DurationCategory.YEAR, durationItem)
        ) {
          //setMaxDuration(duration.years)
          setMaxDuration(
            differenceInCalendarYears(
              intervalAvailableForBooking.end,
              intervalAvailableForBooking.start
            )
          );
        } else {
          setMaxDuration(0);
        }
      },
      []
    );

    const onResetForm = useCallback(()=> {

      if (!item.listing) { return }
      
      durationRef.current = undefined
      if (datesToBlockRef.current.length > 0) {
        setDatesToDisable([...datesToBlockRef.current])
      }
      const leastDuration = getLeastPrice(item.listing.booking_duration)
      let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration: 1, durationType: getDurationCategory(leastDuration.minimum_duration), startDate: undefined, guests: 1}}
      dispatch({type: CartActionType.UPDATE, payload: temp})

    }, [])

    const getAvailableDuration = (intervalAvailableForBooking: {start: Date, end: Date}): BookingDuration[] => {
      const duration = intervalToDuration(intervalAvailableForBooking);
  
      durationRef.current = duration;
      intervalAvailableForBookingRef.current = intervalAvailableForBooking


      const durationItems = bookingDuration.filter(
       (bookingDurationItem) => {
         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.HOUR &&
             isBookingPossibleForDurationCategory(DurationCategory.HOUR, duration)
         ) {
          // setMaxDuration(differenceInHours(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
            return bookingDurationItem;
         }

         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.DAY &&
             isBookingPossibleForDurationCategory(DurationCategory.DAY, duration)
         ) {
           //setMaxDuration(differenceInDays(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
           return bookingDurationItem;
         }

         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.MONTH &&
             isBookingPossibleForDurationCategory(DurationCategory.MONTH, duration)
         ) {
          // setMaxDuration(differenceInMonths(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
           return bookingDurationItem;
         }

         if (
           getDurationCategory(bookingDurationItem.minimum_duration) ===
             DurationCategory.YEAR &&
             isBookingPossibleForDurationCategory(DurationCategory.YEAR, duration)
         ) {
          // setMaxDuration(differenceInYears(intervalAvailableForBooking.end, intervalAvailableForBooking.start));
           return bookingDurationItem;
         }
       }

       
     );

     return durationItems
    }

    

    useEffect(() => {
       loadBookedDates()

       removeDatesBlockedBySelectedRef.current = true
      //if (!item.listing) { return }

      // const leastDuration = getLeastPrice(item.listing.booking_duration)
      // let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration: 1, durationType: getDurationCategory(leastDuration.minimum_duration), startDate: undefined, guests: 1}}
      // dispatch({type: CartActionType.UPDATE, payload: temp})
      return () => {
        //cleanup
      }
    }, [])


    useEffect(() => {

       
      setSelectedDay({...selectedDay, 
                        date: item.bookingDetail.startDate, 
                        selectedMode: item.bookingDetail.startDate ? selectedDay.selectedMode : SELECTEDMODE.HIGHLIGHTED
                      })
      
      return () => {
      }
    }, [item.bookingDetail.startDate])

    useEffect(() => {

      if (!item.bookingDetail.startDate || 
          item.bookingDetail.duration === 0 || 
          item.bookingDetail.durationType !== DurationCategory.DAY) { return}
     // if (disabledWeekends().length === 0) { return }
     
     const disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
     isAfter(disabledDate, item.bookingDetail.startDate!)
   );

   if (disabledDayAfterIdx > -1) { return }
      
     const openedDays = [0, 1, 2, 3, 4, 5, 6].filter(weekDayNum => !closedDays().includes(weekDayNum))
     //console.dir(openedDays)
     const interval = addOfficeBusinessDays( 
                                              //[0,1,2,3,4,5,7], 
                                              openedDays,
                                              closedDays(), 
                                              item.bookingDetail.startDate,
                                              item.bookingDetail.duration - 1
                                              )
    
    
    console.dir(interval[interval.length - 1])
    //console.dir(interval)

    }, [item.bookingDetail])


    /*
    useEffect(()=>{

      const day = selectedDay.date;
     // const bookingDuration = item.listing.booking_duration
      let  disabledDayAfterIdx = -1

      if (datesToDisable.length > 0 && day) {
  
        disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
        isAfter(disabledDate, day)
      );
  
      const intervalAvailableForBooking = {
        start: day as Date,
        end: sub(datesToDisable[disabledDayAfterIdx], {
          days: 1
        })  
      }

       } 

    }, [datesToDisable, selectedDay])*/

    useEffect(()=>{

      // if ( bookedDates) {
      //   datesToBlockRef.current = datesToBlock(bookedDates)
      //   setDatesToDisable(datesToBlockRef.current)
      // }

      //if (!bookedDates || !item.listing?.booking_duration) { return }
      if (!datesToDisable || !item.listing?.booking_duration) { return }
      if (!selectedDay.date) {
        return;
      }
      
    
      const day = selectedDay.date;
      const bookingDuration = item.listing.booking_duration
      let  disabledDayAfterIdx = -1

      /*
      if (datesToDisable.length > 0) {
  
        disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
        isAfter(disabledDate, day)
      );
  
      const intervalAvailableForBooking = {
        start: day as Date,
        end: sub(datesToDisable[disabledDayAfterIdx], {
          days: 1
        })  
      }
  
      // const intervalAvailableForBooking = {
      //   start: day as Date,
      //   end: sub(datesToDisable[disabledDayAfterIdx], {
      //     days: 1
      //   })  
      // }
  
      

      setBookingDuration(getAvailableDuration(intervalAvailableForBooking));
      } */

 
      if (selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED) {

      //if (selectedDay.date && selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED) {
       // datesToBlockRef.current = datesToBlock(bookedDates)

        
        // setDatesToDisable(datesToBlockRef.current)
         // block only booked dates
      } else if (selectedDay.selectedMode === SELECTEDMODE.SELECTED) {
        // bloack booked dates and dates based on selected day

        // if (!item.bookingDetail.startDate || !item.listing) {
        //   return;
        // }

       

        // case 1: block dates before selected date
        const daysBeforeSelectedDate = eachDayOfInterval({
          start: new Date(),
          end: sub(day, { days: 1 }),
        });

       

        // case 2: block dates after selected date
        const disabledDayAfterIdx = datesToDisable.findIndex((disabledDate) =>
          isAfter(disabledDate, day)
        );

        // disable all dates after selected date if there's at least 1 disabled date after it

        if (disabledDayAfterIdx <= -1) {
          durationRef.current = undefined
          setDatesToDisable([
            ...datesToBlockRef.current,
            ...daysBeforeSelectedDate,
          ]);
          return;
        }

        // get duration of time available for booking
        
        // const intervalAvailableForBooking = {
        //   start: day as Date,
        //   end: sub(datesToDisable[disabledDayAfterIdx], {
        //     days: 1
        //   })  
        // }

        const intervalAvailableForBooking = {
          start: day as Date,
          end:  datesToDisable[disabledDayAfterIdx]
        }

        let duration = intervalToDuration(intervalAvailableForBooking);

        // update duration days based on closed days
        if (closedDays().length > 0) {
           // compute no of closed days w/i interval 
          const daysOfInterval = eachDayOfInterval(intervalAvailableForBooking)
          let numClosedDays = getNumClosedDaysOfInterval(closedDays(), daysOfInterval)
          numClosedDaysRef.current = numClosedDays
          //console.log(numClosedDays)
        }
         
      
        durationRef.current = duration;
        intervalAvailableForBookingRef.current = intervalAvailableForBooking
        
        //console.dir(intervalAvailableForBooking)

        const firstBookedDateAfterSelectedDay =
          datesToDisable[disabledDayAfterIdx];
        const daysAfterBookedDate = eachDayOfInterval({
          start: firstBookedDateAfterSelectedDay,
          end: lastDayOfYear(firstBookedDateAfterSelectedDay),
        });

        // get available duration category based on booking duration available
        const availableDuration = getAvailableDuration(intervalAvailableForBooking)

        if (availableDuration.length > 0) {
          setBookingDuration(availableDuration);
          let temp: CartItem = {
            ...item,
            bookingDetail: {
              ...item.bookingDetail,
              duration: 1,
              durationType: DurationCategory.HOUR,
            },
          };

          dispatch({ type: CartActionType.UPDATE, payload: temp });
        }

         // set max duration base selected duration category
         updateMaxDuration(intervalAvailableForBooking, duration, numClosedDaysRef.current, item.bookingDetail.durationType)
        
        

        setDatesToDisable([
          ...datesToBlockRef.current,
          ...daysBeforeSelectedDate,
          ...daysAfterBookedDate,
        ]);
      }


    }, 
    [selectedDay, bookedDates]
   // [selectedDay.selectedMode, bookedDates]
    )

    


    
    

    return (
        <>
           
          <Formik 
             initialValues={form} 
             initialStatus={{isValidating: false}}
             validationSchema={yupBookingValidation(400)}
             //validateOnChange={false}
             //validateOnBlur={false}
             enableReinitialize={true}
             
             onSubmit={(values, {setSubmitting, setStatus}) => {
                //  console.log("is submitting")
                //  console.dir(values)
                //   setTimeout(()=>{                    
                //  }, 2000)
               
                 //setStatus({isSubmitting: true})
            }}
             >
                {(props)=> {
                    return (
                        <Form>
                            <style>{bookedDayStyle}</style>
                            <VStack alignItems="flex-start" w="full">
                            <HStack>
                               <Text className="h6" fontSize="16px !important">Booking Details</Text>
                                <Spacer />
                                {/* <Button 
                                  onClick={()=>{
                                    console.log("resetting")
                                    onResetForm()
                                  }}
                                  variant="ghost" 
                                  colorScheme="primarg">Reset Dates</Button> */}
                            </HStack>
                            <Stack
                             w="full"
                             direction={{ base: "column", md: "row" }}
                           >
                               <Field name="date">
                               {({ field, form }: any) => (
                                 <FormControl 
                                 isInvalid={form.errors.date && form.touched.date}
                                 isRequired>
                                   <FormLabel
                                     fontWeight={500}
                                     fontSize={"15px"}
                                     htmlFor="date"
                                     marginRight="0px"
                                   >
                                     When are you coming?
                                   </FormLabel>
                                   <Popover>
                                      <PopoverTrigger>
                                        <Input
                                            {...field}
                                            value={selectedDay.date ? format((selectedDay.date as unknown as Date), "PP") : ""}
                                            //value={selectedDay ?? ""}
                                           
                                            name="date"
                                            bg="white" color="black" 
                                            size="md" fontSize="md" 
                                           // type="date"
                                           // onChange={props.handleChange}
                                         // {...input.fieldProps}
                                          placeholder="CHECK-IN DATE"
                                        />
                                      </PopoverTrigger>
                                      <PopoverContent>
                                        <DayPicker 
                                            modifiers={modifiers}
                                            modifiersStyles={modifiersStyles}
                                            onFocus={(e: any)=>{
                                             // setSelectedDay(item.bookingDetail.startDate)
                                             // console.dir(e)
                                            }}
                                            //  onDayFocus={(e: any)=>{

                                            //  }}
                                             initialMonth={selectedDay.date}
                                             onDayClick={onDaySelect}
                                             selectedDays={selectedDay.date}
                                             disabledDays={[...datesToDisable, {daysOfWeek: closedDays()}, range]}
                                             //disabledDays={datesToDisable}
                                            // fromMonth={new Date()}
                                              //disabledDays={datesToBlock(bookedDatesDefaultValue)}
                                             //disabledDays={[{daysOfWeek: disabledDays()}, range]}
                                             //fromMonth={selectedDay === undefined ? new Date() : selectedDay}

                                            />
                                      </PopoverContent>
                                    </Popover>
                                   {/* <DayPickerInput 
                                         dayPickerProps={{
                                          disabledDays:[{daysOfWeek: [0,6]}, range],
                                          fromMonth: new Date()
                                      
                                        }}
                                        onDayChange={onDayChange} 
                                        /> */}
                                   {/* <Input inputclassname="body1" 
                                    name="date"
                                    bg="white" color="black" 
                                    size="lg" fontSize="md" 
                                     
                                    type="date" /> */}
                                   {/* <DatePicker
                                     wrapperClassName={styles.datePicker}
                                     
                                     onChange={(dayValue) => {
                                       props.setValues({
                                         ...props.values,
                                         date: dayValue,
                                       });
                                     }}
                                      locale="en"
                                     renderInput={renderCustomInput}
                                   /> */}
                                    <FormErrorMessage>{form.errors.date}</FormErrorMessage>
                                 </FormControl>
                               )}
                             </Field>
                                <Field name="durationCategory">
                               {({ field, form }: any) => (
                                 <FormControl 
                                 isInvalid={form.errors.durationCategory && form.touched.durationCategory}
                                 isRequired>
                                   <FormLabel
                                     fontWeight={500}
                                     fontSize={"15px"}
                                     htmlFor="person"
                                   >
                                     Duration Type
                                   </FormLabel>
                                   <Select
                                     {...field}
                                     rounded="lg"
                                     name="durationCategory"
                                     disabled={selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED}
                                     // value={value}
                                     onChange={(e)=>{
                                       props.handleChange(e)
                                       if (e.currentTarget.value === '') {return}

                                       const selectedCategory = e.currentTarget.value as DurationCategory
                                       if (selectedDay.selectedMode === SELECTEDMODE.SELECTED &&
                                        intervalAvailableForBookingRef.current && durationRef.current
                                        ) {
                                          updateMaxDuration(intervalAvailableForBookingRef.current, durationRef.current, numClosedDaysRef.current, selectedCategory)
                                        } else {
                                          setMaxDuration(0)
                                        }
                                       /*
                                       if (
                                         selectedCategory ===
                                           DurationCategory.HOUR && 
                                          
                                         durationRef?.current?.hours && durationRef?.current?.hours > 0
                                       ) {
                                         setMaxDuration(durationRef?.current?.hours);
                                       } else if (
                                        selectedCategory ===
                                           DurationCategory.DAY &&
                                           durationRef?.current?.days && durationRef?.current?.days > 0
                                       ) {
                                         setMaxDuration(durationRef?.current?.days);
                                       } else if (
                                        selectedCategory ===
                                           DurationCategory.MONTH &&
                                           durationRef?.current?.months && durationRef?.current?.months > 0
                                       ) {
                                         setMaxDuration(durationRef?.current?.months);
                                       } else if (
                                        selectedCategory ===
                                           DurationCategory.YEAR &&
                                           durationRef?.current?.years && durationRef?.current?.years > 0
                                       ) {
                                         setMaxDuration(durationRef?.current?.years);
                                       } else {
                                         setMaxDuration(0);
                                       }*/
                                       
                                       let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, durationType: e.currentTarget.value as DurationCategory, duration: 1}}
                                       dispatch({type: CartActionType.UPDATE, payload: temp})
                                        
                                     }}

                                     placeholder="Choose a duration type"
                                     size="md"
                                   >
                                     {durationTypeItemViews}
                                   </Select>
                                   <FormErrorMessage>{form.errors.durationCategory}</FormErrorMessage>
                                 </FormControl>
                               )}
                             </Field>
                            
                           </Stack>
                           <Stack
                             w="full"
                             direction={{ base: "column", md: "row" }}
                           >
                             <Field name="duration">
                               {({ field, form }: any) => (
                                 <FormControl 
                                 isInvalid={form.errors.duration && form.touched.duration}
                                 isRequired
                                 >
                                   <FormLabel
                                     fontWeight={500}
                                     fontSize={"15px"}
                                     htmlFor="person"
                                   >
                                     How many {getDurationLabel(props.values.durationCategory)} would you stay in this office?
                                   </FormLabel>
                                   <NumberInput
                                      {...field}
                                      disabled={selectedDay.selectedMode === SELECTEDMODE.HIGHLIGHTED}

                                      //defaultValue={15}
                                     // value={durationInOffice.toString()}
                                      max={maxDuration === 0 ? Infinity : maxDuration}
                                      keepWithinRange={true}
                                      clampValueOnBlur={true}
                                      onChange={(e)=> {
                                       
                                        props.handleChange(e)
                                        
                                         if (e === "") {
                                            props.setFieldValue("duration", "")
                                        }

                                        if (!isNumericInput(e)) return
                                        if (isNumericInput(e) &&  Number(e) < 1) { return }
                                       // if (!selectedDay) return 
                                        
                                        let duration = Number(e)

                                        if (maxDuration > 0 && duration > maxDuration ) {
                                          duration = maxDuration
                                        }
                                         
                                         //let endDate = getEnddate(item.bookingDetail.durationType, selectedDay, duration)
                                        let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration}}
                                        dispatch({type: CartActionType.UPDATE, payload: temp})
                                        
                                       }}
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                   {/* <Input
                                     {...field}
                                     rounded="lg"
                                     name="duration"
                                     type="number"
                                     min="1"
                                     max={maxDuration === 0 ? "" : maxDuration.toString()}
                                   
                                      onChange={(e)=>{
                                       
                                      props.handleChange(e)

                                      if (!isNumericInput(e.currentTarget.value)) return
                                      if (isNumericInput(e.currentTarget.value) &&  Number(e.currentTarget.value) < 1) { return }
                                      if (!selectedDay) return 
                                      
                                      let duration = Number(e.currentTarget.value)
                                       
                                       let endDate = getEnddate(item.bookingDetail.durationType, selectedDay, duration)
                                      let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, duration}}
                                      dispatch({type: CartActionType.UPDATE, payload: temp})
                                      
                                     }}
                                      size="md"
                                   /> */}
                                   <FormErrorMessage>{form.errors.duration}</FormErrorMessage>
                                     {/* {dayItemViews}
                                   </Select> */}
                                 </FormControl>
                               )}
                             </Field>
                                {/* <Field name="guests">
                               {({ field, form }: any) => (
                                 <FormControl 
                                 isInvalid={form.errors.guests && form.touched.guests}
                                 isRequired
                                   >
                                   <FormLabel
                                     fontWeight={500}
                                     fontSize={"15px"}
                                     htmlFor="person"
                                   >
                                     No of Guests<span>&nbsp;(should be less than {item.listing?.capacity})</span>
                                   </FormLabel>
                                   <Input
                                     min="1"
                                     max={item.listing?.capacity ?? ""}
                                     {...field}
                                     rounded="lg"
                                     name="guests"
                                     //value="1"
                                     type="number" 
                                    
                                     
                                     onChange={ (e)=>{
                                       props.handleChange(e)

                                       
                                      if (!isNumericInput(e.currentTarget.value)) return

                                      let guests = Number(e.currentTarget.value)
                                      if (!item.listing || guests > item.listing.capacity) {
                                            props.setFieldValue("guests", 5)
                                            setErrorContext({title: "", msg: `Your number of guests cannot exceed ${item.listing?.capacity}`})
                                            return
                                      }
                                      
                                      let temp: CartItem = {...item, bookingDetail: {...item.bookingDetail, guests: Number(e.currentTarget.value)}}
                                      dispatch({type: CartActionType.UPDATE, payload: temp})
                                      
                                      
                                     }}
                                      size="md"
                                   />
                                   <FormErrorMessage>{form.errors.guests}</FormErrorMessage>
                                     
                                 </FormControl>
                               )}
                             </Field> */}
                            </Stack>
                          
                            </VStack>
                            
                        </Form>
                    )
                }}
             </Formik>
        </>
    )
}

export default BookingFormView
 


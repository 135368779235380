import { HStack, AspectRatio, Box, Button, ButtonGroup, Checkbox, Divider, Heading, Icon, Image, Input, InputGroup, InputLeftAddon, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Switch, Text, useDisclosure, useMediaQuery, VStack } from '@chakra-ui/react';
import {   MdFlag, MdLock, MdMail, MdPerson, MdPhone  } from "react-icons/md";
import React, { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import myImg from "../../../../Assets/workspaceBackdrop.jpg"
import { useGoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login'
import { FcGoogle  } from 'react-icons/fc';

import styles from "./SignUp.module.css"
import '../../../../App.css'

import {
  
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from "@chakra-ui/react"
 
//import countryItems from "../../../../node_modules/world_countries_lists/data/en/countries.json"
import routes from '../../../../routes';

import { NavLink as ReactLink, useHistory, useLocation } from "react-router-dom";
import { appInfo } from '../../../../App';
import { Field, Form, Formik, FormikProps, useFormikContext } from 'formik';
import { yupGoogleSignUpValidation, yupSignUpValidation, yupSpaceValidation } from '../AddASpace/Schema/YupSpaceValidation';
import { Country, FedAccountType, FederatedForm, ResultType } from '../../../../Models/Interfaces';
import { signUpDataManager } from './SignUpDataManager';
import { SetErrorContext, SetUserContext } from '../../../AppProvider/AppProvider';
import WHSpinner from '../../../SupportingViews/WHSpinner/WHSpinner';
import WHGoogleLogin from '../../../SupportingViews/WHGoogleLogin/WHGoogleLogin';
import { refreshTokenSetup } from '../../../../utils/utils';
import { WorkhopperAPIManager } from '../../../../Models/WorkhopperAPIManager';
import { loginDataManager } from '../Login/LoginDataManager';
import { ListingLink } from '../../../SupportingViews/Links/Links';
import LayoutView from '../../../SupportingViews/LayoutView/LayoutView';
import WHSEO from '../../../SupportingViews/WHSEO/WHSEO';
import TandC from '../../../SupportingViews/TandC/TandC';
import WHShowPassword from '../../../SupportingViews/WHShowPassword/WHShowPassword';
import { useTranslation  } from "react-i18next";





interface GoogleFormProps {
  countryItems: Country[]
}

export const GoogleForm = ({countryItems}: GoogleFormProps) => {

  const [googleData, setGoogleData] = useState<{gmail: string, gname: string, imageUrl: string, fname: string, lname: string}>({gmail: "", gname: "", imageUrl: "", fname: "", lname: ""})
  let formRef = useRef<FormikProps<FederatedForm>>(null)
  const setErrorContext = useContext(SetErrorContext)
  const setUserContext = useContext(SetUserContext)
  const fieldHeight = 10
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false)
  //const { isOpen, onOpen, onClose } = useDisclosure();



  const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ((res as GoogleLoginResponse).profileObj) {
        const response = res as GoogleLoginResponse
        //console.dir(response)
        //console.log(response.profileObj)
         refreshTokenSetup(res)
        setGoogleData({gmail: response.profileObj.email, gname: response.profileObj.name, imageUrl: response.profileObj.imageUrl, fname: response.profileObj.givenName, lname: response.profileObj.familyName})
        
    } else {
  
    }
   
    //refreshTokenSetup(res);
  };

  const onFailure = (res: any) => {
        
    //setErrorContext({title: "", msg: "Couldn't signup. Maybe WorkHopper is offline. Try again"})

  };

  
  const countryItemViews = countryItems.map((item, idx)=> 
  <option key={item.id}>
    {item.name}
  </option>)

  const history = useHistory()
  

  //const clientId = "133655547006-5u3cr3msgirqisgq0qd8coqm0p8ben8m.apps.googleusercontent.com"
  const clientId = "836248753025-f5co9ukm0v9fuqrs4u0bmvfojt3pp9sj.apps.googleusercontent.com"

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    //isSignedIn: true,
    accessType: 'offline',
    cookiePolicy:"http://localhost:3000/"
   // cookiePolicy: 'single_host_origin'

    // responseType: 'code',
    // prompt: 'consent',
  });

  //const {values} = useFormikContext<FederatedForm>()

  useEffect(() => {
    
   

    ;(async ()=>{
      //debugger
      if (googleData.gmail === "" || googleData.gname === "") {return}
      if (formRef.current === null || !formRef.current.dirty) {return}

       let ref = formRef.current
      let entryIdx = countryItems.findIndex((item, idx) =>
      item.name.includes(ref.values.country)

        //item.name.includes(values.country)
      );
      if (entryIdx < 0) {return }
      setIsLoading(true);
      const data = { ...formRef.current.values, country: countryItems[entryIdx].id, fname: googleData.fname, lname: googleData.lname, email: googleData.gmail };
      
      let result = await WorkhopperAPIManager.shared.registerWithFederatedAccount(data)

      if (result.type === ResultType.Failure) {
        setIsLoading(false);
        setErrorContext({
          title: result.error.name,
          msg: result.error.message,
        });
        return;
      }

      let loginResult = await loginDataManager.loginUser({
        email: data.email,
        password: data.email,
      });
      
      if (loginResult.type === ResultType.Success) {
        result.value.access_token = loginResult.value.access;
        result.value.avatar = googleData.imageUrl
        setIsLoading(false);
        setUserContext(result.value);
        //signUpDataManager.saveRegisteredUserData(result.value);
        if (location.pathname === routes.signup.path) {
          history.push("/");
        } else {}
       
      } else {
        setErrorContext({
          title: loginResult.error.name,
          msg: loginResult.error.message,
        });
      }
      setIsLoading(false);
    })()
    
    
    return () => {
      
    }
  }, [googleData])

   //console.dir(googleData)

  return (
     <Formik
     initialValues={{
      // fname: "",
      // lname: "",
      country: "",
      terms: false,
    }}
    // initialStatus={{ isValidating: false }}
    validationSchema={yupGoogleSignUpValidation}
    onSubmit={async (values, { setSubmitting, setStatus }) => {
       
      signIn()
      
    }}
    // validateOnBlur={false}
    //validateOnChange={false}
    enableReinitialize={true}
    innerRef={formRef}
     >
       {(props)=> {
         
         return (
           <Form  style={{
             display: "flex",
             flexDirection: "column"
           }}>
              <VStack  alignItems={"center"}>
              {/* <Stack direction={{ base: "column", md: "row" }} w="full">
                        <Field name="fname">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isRequired
                                htmlFor="fname"
                                isInvalid={
                                  form.errors.fname && form.touched.fname
                                }
                              >
                                <FormLabel fontWeight={500}>
                                  First name
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    bg="primary.200"
                                    children={<MdPerson color="#fff" />}
                                  />
                                  <Input
                                    {...field}
                                    id="fname"
                                    name="fname"
                                    onChange={props.handleChange}
                                    type="text"
                                    h={fieldHeight}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.fname}
                                </FormErrorMessage>
                               </FormControl>
                            );
                          }}
                        </Field>

                        <Spacer />
                        <Field name="lname">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isRequired
                                id="lname"
                                isInvalid={
                                  form.errors.lname && form.touched.lname
                                }
                              >
                                <FormLabel fontWeight={500}>
                                  Last name
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdPerson color="#fff" />}
                                  />
                                  <Input
                                    {...field}
                                    id="lname"
                                    name="lname"
                                    onChange={props.handleChange}
                                    type="text"
                                    h={fieldHeight}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.lname}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                      </Stack> */}
                      <Field name="country">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isRequired
                                htmlFor="country"
                                isInvalid={
                                  form.errors.country && form.touched.country
                                }
                              >
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                  Country
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdFlag color="#fff" />}
                                  />

                                  <Select
                                    {...field}
                                    id="country"
                                    name="country"
                                    onChange={props.handleChange}
                                    placeholder="Choose a country"
                                  >
                                    {countryItemViews}
                                  </Select>
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.country}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <Field name="terms">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                isInvalid={
                                  form.errors.terms && form.touched.terms
                                }
                                isRequired
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                // justifyContent="center"
                              >
                                <FormLabel htmlFor="terms" mb="0">
                                  I agree to the 
                                  &nbsp;
                                  <Button variant="link" 
                                  onClick={()=>{
                                    history.push(routes.termsConditions.path)
                                  }
                                   
                                  }
                                  showLine
                                   >
                                       terms and conditions
                                  </Button>
                                </FormLabel>
                                <Switch
                                  onChange={props.handleChange}
                                  isChecked={props.values.terms}
                                  size="sm"
                                  name="terms"
                                  {...field}
                                />
                                <FormErrorMessage>
                                  {form.errors.terms}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
              </VStack>
             
              <Button
                  mt={8}
                  isLoading={isLoading}
                  alignSelf={"center"}
                  onClick={()=>{
                    props.handleSubmit()
                  }}
                  rounded="3xl"
                  height={12}
                  width="100%"
                  background="#f7f7f7"
                  shadow="md"
                >
                    <Icon 
                  fontSize="24px"
                  mr="16px"
                  as={FcGoogle} />
                  Sign up with Google
                </Button>
           </Form>
         )
       }}
     </Formik>
  )
}

const SignUp: FC = ()=>{
    const {t} = useTranslation()
     const [countryCode, setCountryCode] = useState("234")
    const [countryItems, setCountryItems] = useState<Country[]>([])
    const [error, setError] = useState<Error | null>(null)
    const setErrorContext = useContext(SetErrorContext)
    const setUserContext = useContext(SetUserContext)
    const [loading, setLoading] = useState(false)
    const [isSocial, setIsSocial] = useState(true)
    const history = useHistory()
     const fieldHeight = 10
    const [country, setCountry] = useState("")
    const [selectedAccountType, setSelectedAccountType] = useState<FedAccountType>(FedAccountType.CUSTOM)
    const [xsmall, small, medium, large, xl] = useMediaQuery([
      "(max-width: 599px)",
      "(min-width: 600px) and (max-width: 904px)",
      "(min-width: 905px) and (max-width: 1239px)",
      "(min-width: 1240px) and (max-width: 1439px)",
      "(min-width: 1440px)",
    ]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showPassword, setShowPassword] = useState(false)

  
    const countryItemViews = countryItems.map((item, idx)=> 
                                <option key={item.id}>
                                  {item.name}
                                </option>)
     

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>)=>{
        setCountry(event.target.value)
        //setCountryCode("234")
    }

    const loadData = async ()=> {
      setLoading(true)
      setError(null)
      let result = await signUpDataManager.fetchCountries()
      
      if (result.type === ResultType.Success) {
          setCountryItems(result.value)
       } else {
         setError(new Error(result.error.message))
         setCountryItems([])
         //setErrorContext({title: result.error.name, msg: result.error.message})
      }
      setLoading(false)
    }

    useEffect(() => {

     

      loadData()
     
      
    }, [])

    //console.log(selectedAccountType)
    
    return (
      <Box className="layout" paddingX={{ base: "24px", md: 5, lg: "5vw" }}>
        <WHSEO 
                    title={`Sign up`} 
                    meta={[
                    {
                        name: "description",
                        content: `Sign up`
                    },
                    {
                        name: "keywords",
                        content: `sign up, workHopper`
                    }
                    ]}  
                />
         {/* <LayoutView> */}
        <Stack
          width="100%"
          margin={"0 auto"}
          direction={xsmall || small ? "column" : "row"}
          //direction={{ base: "column", md: "row", lg: "row" }}
          spacing={10}
        >
          <Box
            marginX={{ md: "auto" }}
            w={small || xsmall ? "full" : "43%"}
            //w={{ base: "full", md: "87.5%", lg: "43%" }}
            h={{ lg: "calc(100vh - 144px)" }}
            overflowY={{ md: "scroll" }}
            className={`${styles.scrollbar} ${styles.scrollbarFox}`}
            // padding={{ base: "24px", md: 10, lg: 10 }}
            pt="0px !important"
          >
            <Box alignItems="flex-start" mb={8}>
              <Heading
                className="h3"
                color="brand.secondary"
                mb={4}
                fontSize={"28px"}
              >
                {/* Welcome To  */}
                {t("welcometo")} {appInfo.title}
              </Heading>
              <Text variant="subtitle" fontSize={"18px"}>
              {t("join")}
                {/* Join the largest workspace community in Africa. Register for an
                account */}
              </Text>
            </Box>
            {selectedAccountType === FedAccountType.GOOGLE ? (
              <GoogleForm countryItems={countryItems} />
            ) : selectedAccountType === FedAccountType.FB ? (
              <div>Login With Facebook</div>
            ) : (
              <Formik
                initialValues={{
                  fname: "",
                  lname: "",
                  email: "",
                  password: "",
                  country: "",
                  phone: "",
                  terms: false,
                }}
                // initialStatus={{ isValidating: false }}
                validationSchema={yupSignUpValidation}
                // validateOnBlur={false}
                //validateOnChange={false}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting, setStatus }) => {
                  let entryIdx = countryItems.findIndex((item, idx) =>
                    item.name.includes(values.country)
                  );

                  if (isSocial) {
                    // login with google
                    // update form values accordingly
                  }

                  const data = {
                    ...values,
                    country: countryItems[entryIdx].id,
                  };
                  let result = await signUpDataManager.registerNonPartners(
                    data,
                    false
                  );

                  if (result.type === ResultType.Failure) {
                    setErrorContext({
                      title: result.error.name,
                      msg: result.error.message,
                    });
                    return;
                  }
                  let loginResult = await loginDataManager.loginUser({
                    email: result.value.email,
                    password: data.password,
                  });
                  if (loginResult.type === ResultType.Success) {
                    result.value.access_token = loginResult.value.access;

                    setUserContext(result.value);
                    history.push("/");
                  } else {
                    setErrorContext({
                      title: "",
                      msg: loginResult.error.message,
                    });
                  }
                }}
              >
                {(props) => {
                  return (
                    <Form>
                      <VStack spacing={6}>
                        <Stack
                          direction={{ base: "column", md: "row" }}
                          w="full"
                        >
                          <Field name="fname">
                            {({ field, form }: any) => {
                              return (
                                <FormControl
                                  className="caption"
                                  isRequired
                                  htmlFor="fname"
                                  isInvalid={
                                    form.errors.fname && form.touched.fname
                                  }
                                >
                                  <FormLabel fontWeight={500}>
                                  {t("firstname")}
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftAddon
                                      bg="primary.200"
                                      children={<MdPerson color="#fff" />}
                                    />
                                    <Input
                                      {...field}
                                      id="fname"
                                      name="fname"
                                      onChange={props.handleChange}
                                      type="text"
                                      h={fieldHeight}
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.fname}
                                  </FormErrorMessage>
                                  {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
                                </FormControl>
                              );
                            }}
                          </Field>

                          <Spacer />
                          <Field name="lname">
                            {({ field, form }: any) => {
                              return (
                                <FormControl
                                  className="caption"
                                  isRequired
                                  id="lname"
                                  isInvalid={
                                    form.errors.lname && form.touched.lname
                                  }
                                >
                                  <FormLabel fontWeight={500}>
                                  {t("lastname")}
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftAddon
                                      pointerEvents="none"
                                      bg="primary.200"
                                      children={<MdPerson color="#fff" />}
                                    />
                                    <Input
                                      {...field}
                                      id="lname"
                                      name="lname"
                                      onChange={props.handleChange}
                                      type="text"
                                      h={fieldHeight}
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.lname}
                                  </FormErrorMessage>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Stack>
                        <Field name="email">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                className="caption"
                                isRequired
                                id="email"
                                isInvalid={
                                  form.errors.email && form.touched.email
                                }
                              >
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                {t("emails")}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdMail color="#fff" />}
                                  />
                                  <Input
                                    {...field}
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={props.handleChange}
                                    h={fieldHeight}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.email}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <VStack w="full" alignItems="flex-start">
                          <Field name="password">
                            {({ field, form }: any) => {
                              return (
                                <FormControl
                                  className="caption"
                                  isRequired
                                  id="password"
                                  isInvalid={
                                    form.errors.password && form.touched.password
                                  }
                                >
                                  <FormLabel
                                    id="password"
                                    name="password"
                                    fontWeight={500}
                                    fontSize={"15px"}
                                  >
                                   {t("password")}
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftAddon
                                      pointerEvents="none"
                                      bg="primary.200"
                                      children={<MdLock color="#fff" />}
                                    />
                                    <Input
                                      {...field}
                                      onChange={props.handleChange}
                                      type={showPassword ? "text" : "password"}
                                      name="password"
                                      h={fieldHeight}
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.password}
                                  </FormErrorMessage>
                                </FormControl>
                              );
                            }}
                          </Field>
                          <WHShowPassword 
                              showPassword={showPassword}
                              onShowPasswordChange={(event: ChangeEvent<HTMLInputElement>)=> {
                                setShowPassword(event.target.checked)
                              }}
                          />
                         
                        </VStack>
                       
                        {loading && <WHSpinner />}
                        {error && (
                          <VStack>
                            <Text className="subtitle2">{error.message}</Text>
                            <Button colorScheme="primary" onClick={loadData}>
                              Try Again
                            </Button>
                          </VStack>
                        )}
                        {!error && countryItems.length > 0 && (
                          <Field name="country">
                            {({ field, form }: any) => {
                              return (
                                <FormControl
                                  className="caption"
                                  isRequired
                                  htmlFor="country"
                                  isInvalid={
                                    form.errors.country && form.touched.country
                                  }
                                >
                                  <FormLabel fontWeight={500} fontSize={"15px"}>
                                  {t("country")}
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftAddon
                                      pointerEvents="none"
                                      bg="primary.200"
                                      children={<MdFlag color="#fff" />}
                                    />

                                    <Select
                                      {...field}
                                      id="country"
                                      name="country"
                                      onChange={props.handleChange}
                                      placeholder="Choose a country"
                                    >
                                      {countryItemViews}
                                    </Select>
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.country}
                                  </FormErrorMessage>
                                </FormControl>
                              );
                            }}
                          </Field>
                        )}

                        <Field name="phone">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                isInvalid={
                                  form.errors.phone && form.touched.phone
                                }
                                 
                                className="caption"
                                id="phone"
                              >
                                <FormLabel fontWeight={500} fontSize={"15px"}>
                                {t("phone")}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftAddon
                                    pointerEvents="none"
                                    bg="primary.200"
                                    children={<MdPhone color="#fff" />}
                                  />
                                  <Input
                                    {...field}
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    h={fieldHeight}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.phone}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <Field name="terms">
                          {({ field, form }: any) => {
                            return (
                              <FormControl
                                isInvalid={
                                  form.errors.terms && form.touched.terms
                                }
                                isRequired
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                // justifyContent="center"
                              >
                                <FormLabel htmlFor="terms" mb="0">
                                {t("terms")}
                                  {/* I agree to the  */}
                                  &nbsp;
                                  <Button variant="link" 
                                  onClick={onOpen}
                                  showLine
                                   >
                                      {t("terms1")}
                                       {/* terms and conditions */}
                                  </Button>
                                </FormLabel>
                                <Switch
                                  onChange={props.handleChange}
                                  isChecked={props.values.terms}
                                  size="sm"
                                  name="terms"
                                  {...field}
                                />
                                <FormErrorMessage>
                                  {form.errors.terms}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                        {/* <FormControl
                        
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FormLabel htmlFor="terms" mb="0">
                          I agree to the terms and conditions
                        </FormLabel>
                        <Switch size="sm"   id="terms" />
                      </FormControl>
                    </VStack>
                    <VStack width="full" mt={4}> */}
                        <Button
                          height={12}
                          color="white"
                          width="100%"
                          rounded="md"
                          isLoading={props.isSubmitting}
                          onClick={() => {
                            props.handleSubmit();
                          }}
                          colorScheme={"secondary"}
                          // colorScheme="facebook"
                        >
                          {t("register")}
                          {/* Register */}
                        </Button>
                      </VStack>
                    </Form>
                  );
                }}
              </Formik>
            )}
            <VStack width="full" mt={4}
              alignItems="center"
            >
              <Text className="h6" w="full" textAlign="center">OR</Text>
              {
                selectedAccountType !== FedAccountType.CUSTOM ? (
                  <Box 
                  onClick={()=>{
                  setSelectedAccountType(FedAccountType.CUSTOM)
                }}
               // bg="#ccc"
                bg={ "primary.500"}
                color="white"
                alignItems="center"
                justifyContent="center"
                display="flex"
                paddingX={4}
                paddingY={2}
                w="full"

                  rounded="3xl"   >
                    {t("signupemail")}
              {/* Sign up with Email */}
            </Box>
                ) : (
                  <Box
                  alignItems="center"
                  justifyContent="center"
                  w="full"
                 display="flex"
                 color="white"
              // bg="#ccc"
                 onClick={()=>{
                   setSelectedAccountType(FedAccountType.GOOGLE)
                 }}
                 rounded="3xl"
                 paddingX={4}
                 paddingY={2}

                 //rounded="lg"
                 //height={8}
                // width="auto"
                bg={"primary.500"}
                >
                   {t("signgoogle")}
                           {/* Sign up with Google */}
               </Box>
                )

              }
              
              {/* <Stack dir={ xsmall || small ? "column" : "row"}>
                <Box 
                      onClick={()=>{
                      setSelectedAccountType(FedAccountType.CUSTOM)
                    }}
                   // bg="#ccc"
                    bg={selectedAccountType === FedAccountType.CUSTOM ? "primary.500" : "#ccc"}
                    color="white"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    paddingX={4}
                    paddingY={2}

                      rounded="3xl" width="auto"  >
                  Sign up with Email
                </Box>
                <Box
                   alignItems="center"
                   justifyContent="center"

                  display="flex"
                  color="white"
               // bg="#ccc"
                  onClick={()=>{
                    setSelectedAccountType(FedAccountType.GOOGLE)
                  }}
                  rounded="3xl"
                  paddingX={4}
                  paddingY={2}

                  //rounded="lg"
                  //height={8}
                  width="auto"
                 bg={selectedAccountType === FedAccountType.GOOGLE ? "primary.500" : "#ccc"}
                 >
                            Sign up with Google
                </Box>
                
              </Stack> */}

              <Text paddingTop={3}>
              {t("alreadyhaveaccount")}
                {/* I already have an account! */}
                {" "}
                <Link
                  as={ReactLink}
                  textDecoration="none !important"
                  activeClassName="active"
                  fontWeight="600 !important"
                  h={12}
                  className="subtitle1"
                  to={routes.login.path}
                >
                  {routes.login.title}
                </Link>
              </Text>
            </VStack>
          </Box>
          <Box
            mt={1.2}
            display={xsmall || small ? "none" : "block"}
            //display={{ base: "none", lg: "block" }}
            w={medium ? "50%" : "57%"}
           // w={{ base: "50%", md: "57%", lg: "57%" }}
            // h="calc(100vh - 144px)"
          >
            <AspectRatio ratio={4 / 3}>
              <Image objectFit="cover" rounded="lg" src={myImg} />
            </AspectRatio>
          </Box>
        </Stack>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          scrollBehavior="inside"
          motionPreset="slideInBottom"
          size={xsmall ? "md" : "2xl"}
          // trapFocus={false}
        >
          <ModalOverlay height="100vh" />
          <ModalContent
            position={xsmall ? "fixed" : "unset"}
            bottom={xsmall ? "0px" : "unset"}
            mb={xsmall ? "0" : "unset"}
             borderRadius={xsmall ? "1.75rem 1.75rem 0px 0px" : "initial"}
            //maxW="lg"
          >
            <ModalHeader textAlign="center" className="h6">
              Terms and Conditions
            </ModalHeader>
            <ModalCloseButton outline="none" _focus={{ outline: "none" }} />
            <Divider />
            <ModalBody> 
                <TandC />
            </ModalBody>
            <Divider />
            <ModalFooter>
              <Spacer />
              <Button 

                    //isLoading={isLoading}
                    colorScheme="primary"
                    onClick={()=>{
                      //onAcceptTerms()
                      //onApplyFilters()
                      onClose()
                    }}
               >
                        Accept
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </Box>
      );
}

export default SignUp
 

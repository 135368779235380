import { Box, Text } from "@chakra-ui/react";
 import {ReactNode } from "react";
 
interface Dim {
    base: string
    md: string
    lg: string
  }
  interface ILearnMoreItemViewProps {
      label: string
      width: Dim
      actionItem?: ReactNode
      children?: ReactNode
  }
  
  export const LearnMoreItemView = ({label, width, children, actionItem}: ILearnMoreItemViewProps) => {
  
      return (
         <Box width={{base: width.base, md: width.md, lg: width.lg}}>
          <Text mb={4} className="h6">
            {label}
          </Text>
          {children}
          {actionItem}
        </Box>
      );
      
      
  }